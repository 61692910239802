import React from 'react'
import PropTypes from 'prop-types'
import styles from './Invoice.module.css'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import AddBoxIcon from '@material-ui/icons/AddBox'
import SearchIcon from '@material-ui/icons/Search'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from "@material-ui/core/TableContainer";
import ButtonGroup from '@material-ui/core/ButtonGroup'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import {MenuItem} from '@material-ui/core'
import InputLabel from "@material-ui/core/InputLabel";

class InvoiceForm extends React.Component {

  render() {
    return (
      <>
        <Grid container spacing={1}>
          {this.props.biller !== undefined ? (
            <>
              <Grid container className={styles.mainBody}>
                <Grid item xs={12} sm={6} className={styles.invoiceListLeft}>
                  <div>
                    <label>
                      Biller :
                    </label>
                    <div>
                      <h3>{this.props.biller.name ? (this.props.biller.name) : ('n/a')}</h3>
                    </div>
                  </div>

                  <div>
                    <label>
                      Clasification :
                    </label>
                    <div>
                      <h3>{this.props.biller.is_company ? (<div>Company</div>) : (<div>Individual</div>)}</h3>
                    </div>
                  </div>

                  <div>
                    <label>
                      Group :
                    </label>
                    <div>
                      <h4>{this.props.biller.group ? (this.props.biller.group.name) : ('n/a')}</h4>
                    </div>
                  </div>
                  <div>
                    <label>
                      Tax-Exempt :
                    </label>
                    <div>
                      <b>{this.props.biller.name ? (this.props.biller.is_taxable ? ('Yes') : ('No')) : ('n/a')}</b>
                    </div>
                  </div>

                </Grid>

                <Grid item xs={12} sm={6} className={styles.invoiceListright}>
                  <Grid container >
                    <Grid item xs={6}>
                      <label><b>Invoice No. :</b></label>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="reference_no"
                        className={styles.invoiceListrightInput}
                        fullWidth="true"
                        autoComplete="off"
                        onChange={this.props.handleInputChange}
                        value={this.props.invoice.reference_no}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={6}>
                    <label><b>Invoice Date :</b></label>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        className={styles.invoiceListrightInput}
                        type="date"
                        format="yyyy-MM-dd"
                        onChange={this.props.handleInputChange}
                        value={this.props.invoice.filed_at}
                        name="filed_at"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <label><b>Due Date :</b></label>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        className={styles.invoiceListrightInput}
                        variant="outlined"
                        type="date"
                        format="yyyy-MM-dd"
                        onChange={this.props.handleInputChange}
                        value={this.props.invoice.due_date}
                        name="due_date"
                        size="small"
                      />
                    </Grid>
                  </Grid> 
                </Grid>
              </Grid>
            </>
          ) : (null)
          }
        </Grid>
        <hr/>
        <div className={styles.filterbyBody}>
          <div className={styles.labelFilter}>Filter By :</div>
          <div className={styles.bodyDaterage}>
            <div>
              <TextField
                label="Start at"
                className={styles.filterField}
                variant="outlined"
                size="small"
                type="date"
                value={this.props.beforeDateFilter}
                onChange={this.props.setBeforeDateFilter}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </div>
            <label>
              to
            </label>
            <div>
              <TextField
                label="Ends at"
                className={styles.filterField}
                variant="outlined"
                size="small"
                type="date"
                fullWidth
                value={this.props.endDateFilter}
                onChange={this.props.setEndDateFilter}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </div>
          </div>
          <FormControl fullWidth="true" className={styles.filterField} size="small" variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">User name</InputLabel>
            <Select
              fullWidth
              label="User name"
              variant="outlined"
              value={this.props.userFilter}
              onChange={this.props.setUserFilter}
              name="setUserFilter"
              inputProps={{
                id: 'outlined-age-native-simple'
              }}
            >
              {
                this.props.members.length > 0 ? (
                  this.props.members.map((user) => (
                    this.props.users.map((userId) => (
                      user.uid === userId ? (
                        <MenuItem key={user.uid}
                                  value={user.uid}>{user.first_name} {user.last_name}</MenuItem>

                      ) : (null)
                    ))
                  ))
                ) : (null)
              }
            </Select>
          </FormControl>

          <FormControl fullWidth="true" className={styles.filterField} size="small" variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Type</InputLabel>
            <Select
              variant="outlined"
              label="Type"
              value={this.props.typeFilter}
              onChange={this.props.setTypeFilter}
              name="setTypeFilter"
            >
              <MenuItem value={false}>Expenses</MenuItem>
              <MenuItem value={true}>Deductions</MenuItem>
            </Select>
          </FormControl>

          <ButtonGroup>
            <IconButton
              color="primary"
              onClick={this.props.applyFilter}
              name="applyFilter"
            >
              <SearchIcon/>
            </IconButton>
            <IconButton
              color="secondary"
              onClick={this.props.removeFilter}
              name="removeFilter"
            >
              <RotateLeftIcon/>
            </IconButton>
          </ButtonGroup>
        </div>

        <hr/>
        <div>
          <div className="flex-align-center">
            <h4 className="set-label-width">Invoice Details</h4>
            <IconButton
              color="primary"
              variant="addTransaction"
              onClick={this.props.addTransaction}
              name="addTransaction"
              >
              <AddBoxIcon/>
            </IconButton>
          </div>

          <TableContainer style={{maxHeight: '400px'}} className={styles.invoiceFormbodyTable}>
            <Table size="small" stickyHeader aria-label="customized sticky table">
              <TableHead className={styles.tableHeadBody}>
                <TableRow>
                  <TableCell className={styles.cell}><b>Description</b></TableCell>
                  <TableCell className={styles.cell}><b>User</b></TableCell>
                  <TableCell className={styles.cell}><b>Transaction Category</b></TableCell>
                  <TableCell className={styles.cell}><b>Date Filed</b></TableCell>
                  <TableCell className={styles.cell}><b>User Rate</b></TableCell>
                  <TableCell className={styles.cell}><b>Duration</b></TableCell>
                  <TableCell className={styles.cell}><b>Discount</b></TableCell>
                  <TableCell className={styles.cell}><b>Amount</b></TableCell>
                  <TableCell className={styles.cell}><b>Actions</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  this.props.transactions.map((val, idx) => (
                    <>
                      <TableRow>
                        <TableCell className={styles.desc}>
                          {
                            this.props.transactions[idx].is_deduction ? (
                              <Typography variant="body1" className={styles.rebate}>
                                <strong>{this.props.transactions[idx].name}</strong>
                              </Typography>
                            ) : (
                              <Typography variant="body1" className={styles.desc}>
                                {this.props.transactions[idx].name}
                              </Typography>
                            )
                          }

                        </TableCell>
                        <TableCell className={styles.cell}>
                          {

                            this.props.members.map((apiUser) => (
                              this.props.transactions[idx].user_id === apiUser.uid ? (
                                <Typography variant="body1" className={styles.cell}>
                                  {apiUser.first_name}
                                </Typography>
                              ) : (null)
                            ))

                          }
                        </TableCell>
                        <TableCell className={styles.cell}>
                          <Typography>{this.props.transactions[idx].transaction_type?(this.props.transactions[idx].transaction_type.name):("n/a")}</Typography>
                        </TableCell>
                        <TableCell className={styles.cell}>
                          <Typography>{new Date(this.props.transactions[idx].filed_at).toLocaleDateString('en-us', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit'
                          })}</Typography>
                        </TableCell>
                        <TableCell className={styles.cell}>
                          <Typography
                            className={styles.amt}>{this.props.transactions[idx].rate > 0 ? parseFloat(this.props.transactions[idx].rate).toLocaleString(navigator.language, {minimumFractionDigits: 2}) : ("-")}</Typography>
                        </TableCell>
                        <TableCell className={styles.cell}>
                          <Typography
                            className={styles.amt}>{this.props.transactions[idx].duration > 0 ? this.props.transactions[idx].duration : ("-")}</Typography>
                        </TableCell>
                        <TableCell className={styles.discount}>
                          <Typography
                            className={styles.discount}>{this.props.transactions[idx].discount > 0 ? (parseFloat(this.props.transactions[idx].discount).toLocaleString() + '%') : ("0")}</Typography>
                        </TableCell>
                        <TableCell className={styles.cell}>
                          <Typography
                            className={styles.amt}>{this.props.transactions[idx].amount > 0 ? parseFloat(this.props.transactions[idx].amount).toLocaleString(navigator.language, {minimumFractionDigits: 2}) : ("-")}</Typography>
                        </TableCell>
                        <TableCell className={styles.cell}>
                          <ButtonGroup size="small" style={{marginRight: 5}}>
                            <IconButton
                              color="primary"
                              onClick={() => this.props.editTransaction(this.props.transactions[idx])}
                              name={`editTransaction-${idx}`}
                            >
                              <EditIcon/>
                            </IconButton>
                            <IconButton
                              color="secondary"
                              onClick={() => this.props.removeTransaction(idx)}
                              name={`removeTransaction-${idx}`}
                            >
                              <DeleteForeverIcon/>
                            </IconButton>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    </>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <TableContainer>
          <Table size="small" className={styles.grandTotalBody}>
            <TableBody>
              <TableRow>
                <TableCell className={styles.celldesc}><i>Remarks/Payments Intructions</i></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.toltaLabel}>Total Expenses :</TableCell>
                <TableCell className={styles.totalValue}>{this.props.getTotal()}</TableCell>
                <TableCell className={styles.cellLast}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell className={styles.celldesc}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.toltaLabel}>Total Deductions :</TableCell>
                <TableCell className={styles.totalValue}>{this.props.getTotalDiscount()}</TableCell>
                <TableCell className={styles.cellLast}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell className={styles.celldesc}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.toltaLabel}> Sub Total :</TableCell>
                <TableCell className={styles.totalValue}>{this.props.getTotalAmount()}</TableCell>
                <TableCell className={styles.cellLast}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell className={styles.celldesc}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.toltaVat}>V.A.T :</TableCell>
                <TableCell className={styles.totalValueVat}>{this.props.getVat()}</TableCell>
                <TableCell className={styles.cellLast}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell className={styles.celldesc}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.cell}></TableCell>
                <TableCell className={styles.totalLabelGrand}>Grand Total :</TableCell>
                <TableCell className={styles.totalValueGrand}>{this.props.getGrandTotal()}</TableCell>
                <TableCell className={styles.cellLast}></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div className={styles.actionButton}>
          <Button variant="outlined" disabled={!this.props.invoice.reference_no || !this.props.transactions.length>0}  color="primary" onClick={this.props.handleSubmit} name="submit-invoice">Save</Button>
        </div>
      </>
    )
  }
}

export default InvoiceForm

InvoiceForm.propTypes = {
  invoice: PropTypes.object,
  transactions: PropTypes.array,
  transactionMenu: PropTypes.array,
  transMenuPopUp: PropTypes.array,
  beforeDateFilter: PropTypes.string,
  endDateFilter: PropTypes.string,
  expenseTypes: PropTypes.array,
  rebates: PropTypes.array,
  users: PropTypes.array,
  members: PropTypes.array,
  typeFilter: PropTypes.string,
  userFilter: PropTypes.string,
  setUserFilter: PropTypes.func,
  applyFilter: PropTypes.func,
  removeFilter: PropTypes.func,
  getTotalDiscount: PropTypes.func,
  getGrandTotal: PropTypes.func,
  getVat: PropTypes.func,
  getTotal: PropTypes.func,
  getTotalAmount: PropTypes.func,
  removeTransaction: PropTypes.func,
  editTransaction: PropTypes.func,
  handleInputChange: PropTypes.func,
  addTransaction: PropTypes.func,
  setBeforeDateFilter: PropTypes.func,
  setTypeFilter: PropTypes.func,
  setEndDateFilter: PropTypes.func,
  handleSubmit: PropTypes.func,
  biller: PropTypes.object

}
