import React from 'react'
import axios from 'axios'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Link,
  Redirect
} from 'react-router-dom'
import clsx from 'clsx'
import styles from './App.css'
import style from './App.module.css'

import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Badge from '@material-ui/core/Badge'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import HomeIcon from '@material-ui/icons/Home'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import FolderIcon from '@material-ui/icons/Folder'
import ListIcon from '@material-ui/icons/List'
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode'
import HelpIcon from '@material-ui/icons/Help'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import GavelIcon from '@material-ui/icons/Gavel'
import WorkIcon from '@material-ui/icons/Work'
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import ReceiptIcon from '@material-ui/icons/Receipt'
import FilterNoneIcon from '@material-ui/icons/FilterNone'
import FlagIcon from '@material-ui/icons/Flag'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { Box, Button } from '@material-ui/core'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import LinearProgress from '@material-ui/core/LinearProgress'

import Home from './pages/Home/Home'
import Contact from './pages/Contact/Contact'
import Groups from './pages/Group/Groups'
import Matters from './pages/Matter/Matters'
import Login from '../components/shared/sessions/SignIn/Login'
import Areas from './pages/PracticeArea/Areas'
import DataGridDemo from './pages/PracticeArea/Datagrid'
import Departments from './pages/Department/Departments'
import Positions from './pages/Position/Positions'
import Documents from './pages/Document/Documents'
import ExpenseTypes from './pages/TransactionType/ExpenseTypes'
import Income from './pages/TransactionType/Income'
import Invoices from './pages/Invoice/Invoices'
import Clients from './pages/Client/Clients'
import Client from './pages/Client/Client'
import CaseForm from './pages/LegalCases/NewAndEditForm'
import LegalCase from './pages/LegalCases/LegalCase'
import Tasks from './pages/Tasks/Tasks'
import Events from './pages/Event/Events'
import Workflows from './pages/Workflow/Workflows'
import Workflow from './pages/Workflow/Workflow'
import CustomFieldsClient from './pages/CustomField/Client/ClientCustom'
import Invoice from './pages/LegalCases/Invoice/Invoice'
import LegalCaseShow from './pages/LegalCases/LegalCaseShow'
import Customfields from './pages/CustomField/LegalCase/Customfields'
import Members from './pages/User/Users'
import Profile from './pages/User/Profile'
import Permissions from './pages/Permissions/Permissions'
import PermissionNewUpdate from './pages/Permissions/PermissionNewUpdate'
import ForgotPassword from './shared/sessions/SignIn/ForgotPassword'
import UnlockInstruction from './shared/sessions/SignIn/UnlockInstruction'
import OnBoarding from './shared/Onboarding/Onboarding'
import Priority from './pages/Priority/Priorities'
import SimplePopUp from './shared/SimplePopUp/SimplePopUp'
import ErrorPage from './shared/ErrorPage/ErrorPage'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      todayEvents: [],
      currentUser: '',
      unreadTasks: [],
      token: '',
      CurrentUser: '',
      signin: false,
      taskBadge: null,
      showLogoutModal: false
    }
    this.toggleDrawer = this.toggleDrawer.bind(this)
    this.logOut = this.logOut.bind(this)
    this.setToken = this.setToken.bind(this)
    this.setLoad = this.setLoad.bind(this)
    this.countTasks = this.countTasks.bind(this)
    this.toggleLogoutModal = this.toggleLogoutModal.bind(this)
  }
  componentDidMount() {
    if (localStorage.getItem('api_key') !== null) {
      this.loadAttributes()
    } else {
      this.setState({ load: false })
      this.setState({ signin: true })
    }
  }
  toggleLogoutModal() {
    this.setState({
      showLogoutModal: !this.state.showLogoutModal
    })
  }
  toggleDrawer() {
    this.setState({ open: !this.state.open })
  }
  logOut() {
    this.setState({ load: false })
    this.setState({
      showLogoutModal: false
    })
    localStorage.clear()
  }
  setToken(token) {
    this.setState({ token: token })
  }
  setLoad(load) {
    this.setState({ load: load })
  }
  loadAttributes() {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/users/me/tasks',
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then((resp) => {
      var taskCount = 0
      if (resp.data.data.length > 0) {
        resp.data.data.map((task) => {
          if (
            task.state === 'ongoing' &&
            new Date(task.ends_at) <= new Date()
          ) {
            taskCount = taskCount + 1
          }
        })
      }
      this.setState({ taskBadge: taskCount })
    })
    this.setState({ currentUser: localStorage.getItem('current_user') })
    this.setState({ load: true })
  }
  countTasks() {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/users/me/tasks',
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then((resp) => {
      var taskCount = 0
      if (resp.data.data.length > 0) {
        resp.data.data.map((task) => {
          if (
            task.state === 'ongoing' &&
            new Date(task.ends_at) <= new Date()
          ) {
            taskCount = taskCount + 1
          }
        })
      }
      this.setState({ taskBadge: taskCount })
    })
  }
  render() {
    if (!localStorage.getItem('api_key')) {
      if (location.pathname === '/login' || location.pathname === '/') {
        return <Login setToken={this.setToken} setLoad={this.setLoad} />
      } else if (location.pathname === '/forgot_password') {
        return <ForgotPassword />
      } else if (location.pathname === '/unlock_account') {
        return <UnlockInstruction />
      } else {
        return (location.href = '/login')
      }
    }

    const MENUS = [
      { label: 'Home', icon: <HomeIcon />, link: '/' },
      { label: 'Clients', icon: <AssignmentIndIcon />, link: '/clients' },
      { label: 'Cases', icon: <MenuBookIcon />, link: '/legal_cases' },
      { label: 'Events', icon: <CalendarTodayIcon />, link: '/events' },
      { label: 'Documents', icon: <FolderIcon />, link: '/documents' }
    ]
    const CustomField = [
      {
        label: 'Clients',
        icon: <AssignmentIndIcon />,
        link: '/clients/custom_fields'
      },
      {
        label: 'Legal Case',
        icon: <MenuBookIcon />,
        link: '/custom_fields_legal_cases'
      }
    ]
    const transactions = [
      { label: 'Expenditure', icon: <ReceiptIcon />, link: '/expense_types' },
      {
        label: 'Rebate',
        icon: <AccountBalanceWalletIcon />,
        link: '/income_types'
      }
    ]
    const Settings = [
      { label: 'Workflows', icon: <AccountTreeIcon />, link: '/workflows' },
      { label: 'Invoices', icon: <ReceiptIcon />, link: '/invoices' },
      { label: 'Groups', icon: <PeopleAltIcon />, link: '/groups' },
      { label: 'Members', icon: <PeopleOutlineIcon />, link: '/members' },
      { label: 'Reports', icon: <ChromeReaderModeIcon />, link: '/reports' },
      { label: 'Practice Areas', icon: <GavelIcon />, link: '/practice-areas' },
      { label: 'Departments', icon: <HomeWorkIcon />, link: '/departments' },
      { label: 'Positions', icon: <WorkIcon />, link: '/positions' },
      { label: 'Priority Levels', icon: <FlagIcon />, link: '/priorities' },
      { label: 'Help center', icon: <HelpIcon />, link: '/help' },
      { label: 'Permissions', icon: <VerifiedUserIcon />, link: '/permissions' }
    ]
    return (
      <>
        {this.state.load ? (
          <Router>
            <div className={style.root}>
              <CssBaseline />
              <AppBar
                position="fixed"
                className={clsx(style.appBar, {
                  [style.appBarShift]: this.state.open
                })}
              >
                <Toolbar className={style.toolBarBody}>
                  <div className={style.groupButton}>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={this.toggleDrawer}
                      edge="start"
                      className={clsx(style.menuButton, {
                        [style.hide]: this.state.open
                      })}
                    >
                      <MenuIcon />
                    </IconButton>

                    <Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
                      {process.env.REACT_APP_NAME}
                    </Typography>
                  </div>

                  <div className={style.groupButton2}>
                    <div
                      className={
                        this.state.open
                          ? style.noDisplay
                          : style.headerCurrentUser
                      }
                    >
                      <h3>
                        Welcome
                        {localStorage.getItem('current_user')
                          ? ', ' + localStorage.getItem('current_user')
                          : ''}
                      </h3>
                      <Link to={'/profile'}>
                        <Tooltip title="Go to Profie">
                          <AccountCircleIcon />
                        </Tooltip>
                      </Link>
                    </div>
                    <IconButton
                      onClick={this.toggleLogoutModal}
                      style={{ color: '#fff' }}
                      aria-label="logout"
                    >
                      <ExitToAppIcon />
                    </IconButton>
                  </div>
                </Toolbar>
              </AppBar>
              <Drawer
                variant="permanent"
                className={clsx(style.drawer, {
                  [style.drawerOpen]: this.state.open,
                  [style.drawerClose]: !this.state.open
                })}
                classes={{
                  paper: clsx({
                    [style.drawerOpen1]: this.state.open,
                    [style.drawerClose2]: !this.state.open
                  })
                }}
              >
                <div className={style.toolbar}>
                  <div className={style.companyLogo}></div>
                  <IconButton onClick={this.toggleDrawer}>
                    {styles.direction === 'rtl' ? (
                      <ChevronRightIcon />
                    ) : (
                      <ChevronLeftIcon />
                    )}
                  </IconButton>
                </div>
                <Divider />
                <Box
                  style={{
                    overflowY: 'auto',
                    overflowX: 'hidden'
                  }}
                >
                  <List
                    name="sidenav"
                    className={
                      this.state.open === true
                        ? style.openSidenav
                        : style.closeSidenavList
                    }
                    style={{
                      paddingRight: '5px'
                    }}
                  >
                    {MENUS.map((menu) => (
                      <ListItem
                        button
                        key={menu.label}
                        name={menu.link}
                        component={NavLink}
                        activeClassName="active"
                        exact
                        to={menu.link}
                        style={{
                          borderTopRightRadius: '20px',
                          borderBottomRightRadius: '20px',
                          paddingBlock: '3px'
                        }}
                      >
                        <Tooltip title={menu.label}>
                          <ListItemIcon>{menu.icon}</ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={menu.label} />
                      </ListItem>
                    ))}

                    <ListItem
                      button
                      component={NavLink}
                      to={'/tasks'}
                      name="/tasks"
                      style={{
                        borderTopRightRadius: '20px',
                        borderBottomRightRadius: '20px',
                        paddingBlock: '4px'
                      }}
                    >
                      <Tooltip title="Tasks">
                        <ListItemIcon>
                          <Badge
                            color="secondary"
                            badgeContent={this.state.taskBadge}
                          >
                            <ListIcon />
                          </Badge>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary="Tasks" />
                    </ListItem>
                    <ListItem style={{ padding: '0' }}>
                      <Accordion
                        style={{
                          marginBlock: '0',
                          borderRadius: 'unset',
                          boxShadow: 'inherit',
                          paddingBottom: '0'
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <ListItemIcon>
                            <SettingsOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary="Settings" />
                        </AccordionSummary>
                        <AccordionDetails
                          style={{ paddingTop: '0', paddingBottom: '0', paddingLeft: '0' }}
                        >
                          <List style={{ paddingTop: '0', paddingBottom: '0' }}>
                            {Settings.map((field) => (
                              <ListItem
                                className={
                                  this.state.open === true
                                    ? style.openSidenav
                                    : style.closeSidenavSettings
                                }
                                button
                                key={field.label}
                                component={NavLink}
                                to={field.link}
                                style={{
                                  borderTopRightRadius: '20px',
                                  borderBottomRightRadius: '20px',
                                  paddingBlock: '3px',
                                  paddingLeft: '30px'
                                }}
                              >
                                <Tooltip title={field.label}>
                                  <ListItemIcon>{field.icon}</ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                  primary={field.label}
                                  fontSize="14px"
                                />
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                        <ListItem style={{ padding: '0' }}>
                          <Accordion
                            className={
                              this.state.open === true
                                ? style.openSidenav
                                : style.closeSidenav
                            }
                            style={{
                              borderRadius: 'unset',
                              boxShadow: 'inherit',
                              padding: 0
                            }}
                          >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={
                              this.state.open === true ?
                              style.openSidenavSettingsNested : ''
                            }>
                              <ListItemIcon>
                                <AddToPhotosIcon />
                              </ListItemIcon>
                              <ListItemText primary="Transaction Types" />
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: '0' }}>
                              <List
                              >
                                {transactions.map((field) => (
                                  <ListItem
                                  className={
                                    this.state.open === true
                                      ? style.openSidenavSettingsNested
                                      : style.closeSidenav
                                  }
                                    button
                                    key={field.label}
                                    component={NavLink}
                                    to={field.link}
                                    style={{
                                      borderTopRightRadius: '20px',
                                      borderBottomRightRadius: '20px',
                                      paddingBlock: '3px'
                                    }}
                                  >
                                    <Tooltip title={field.label} className={
                                      this.state.open === true
                                      ? style.openSidenavTrans
                                      : style.closeSidenavSettings
                                    }>
                                      <ListItemIcon>{field.icon}</ListItemIcon>
                                    </Tooltip>
                                    <ListItemText primary={field.label} />
                                  </ListItem>
                                ))}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </ListItem>

                        <ListItem style={{ padding: '0' }}>
                          <Accordion
                            style={{
                              padding: 0,
                              borderRadius: 'unset',
                              boxShadow: 'inherit'
                            }}
                          >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={
                              this.state.open === true ?
                              style.openSidenavSettingsNested : ''
                            }>
                              <ListItemIcon>
                                <FilterNoneIcon />
                              </ListItemIcon>
                              <ListItemText primary="Custom Fields" />
                            </AccordionSummary>
                            <AccordionDetails style={{ paddingTop: '0', paddingLeft: 0 }}>
                              <List
                                className={
                                  this.state.open === true
                                        ? style.openSidenav
                                        : style.closeSidenav
                                }
                              >
                                {CustomField.map((field) => (
                                  <ListItem
                                    className={
                                      this.state.open === true
                                        ? style.openSidenavSettingsNested
                                        : style.closeSidenav
                                    }
                                    button
                                    key={field.label}
                                    component={NavLink}
                                    to={field.link}
                                    style={{
                                      borderTopRightRadius: '20px',
                                      borderBottomRightRadius: '20px',
                                      paddingBlock: '4px'
                                    }}
                                  >
                                    <Tooltip title={field.label} className={
                                      this.state.open === true
                                      ? style.openSidenavTrans
                                      : style.closeSidenavSettings
                                    }>
                                      <ListItemIcon>{field.icon}</ListItemIcon>
                                    </Tooltip>
                                    <ListItemText primary={field.label} />
                                  </ListItem>
                                ))}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </ListItem>
                      </Accordion>
                    </ListItem>
                  </List>
                </Box>
              </Drawer>
              <Container className={style.body}>
                <Grid
                  container
                  style={{
                    height: '100%',
                    flexWrap: 'inherit'
                  }}
                >
                  <Grid item xs={12}>
                    <main className={style.content}>
                      <Switch>
                        <Route exact path="/">
                          <Home countTasks={this.countTasks} />
                        </Route>
                        <Route exact path="/contact">
                          <Contact />
                        </Route>
                        <Route exact path="/groups">
                          <Groups />
                        </Route>
                        <Route exact path="/matters">
                          <Matters />
                        </Route>
                        <Route
                          exact
                          path="/legal_cases/new"
                          component={CaseForm}
                        />
                        <Route
                          exact
                          path="/cases/:uid"
                          component={LegalCaseShow}
                        />
                        <Route
                          path="/dashboard"
                          render={() => (
                            <Typography paragraph>DASHBOARD</Typography>
                          )}
                        />
                        <Route exact path="/practice-areas">
                          <Areas />
                        </Route>
                        <Route exact path="/workflows">
                          <Workflows />
                        </Route>
                        <Route
                          exact
                          path="/workflows/:uid"
                          component={Workflow}
                        />
                        <Route exact path="/departments">
                          <Departments />
                        </Route>
                        <Route exact path="/positions">
                          <Positions />
                        </Route>
                        <Route exact path="/tasks">
                          <Tasks countTasks={this.countTasks} />
                        </Route>
                        <Route exact path="/documents">
                          <Documents />
                        </Route>
                        <Route exact path="/practice-areas/datagrid">
                          <DataGridDemo />
                        </Route>
                        <Route exact path="/events">
                          <Events setTodayEvents={this.setTodayEvents} />
                        </Route>
                        <Route exact path="/clients/custom_fields">
                          <CustomFieldsClient />
                        </Route>
                        <Route exact path="/clients">
                          <Clients />
                        </Route>
                        <Route exact path="/custom_fields_legal_cases">
                          <Customfields />
                        </Route>
                        <Route exact path="/workflows">
                          <Workflows />
                        </Route>
                        <Route exact path="/legal_cases">
                          <LegalCase />
                        </Route>
                        <Route exact path="/expense_types">
                          <ExpenseTypes />
                        </Route>
                        <Route exact path="/members">
                          <Members />
                        </Route>
                        <Route exact path="/income_types">
                          <Income />
                        </Route>
                        <Route exact path="/invoices">
                          <Invoices />
                        </Route>
                        <Route exact path="/profile">
                          <Profile />
                        </Route>
                        <Route exact path="/permissions">
                          <Permissions />
                        </Route>
                        <Route exact path="/onboarding">
                          <OnBoarding />
                        </Route>
                        <Route exact path="/priorities">
                          <Priority />
                        </Route>
                        <Route
                          exact
                          path="/permissions/new"
                          component={PermissionNewUpdate}
                        />
                        <Route
                          exact
                          path="/permissions/:permission_id"
                          component={PermissionNewUpdate}
                        />
                        <Route
                          exact
                          path="/invoices/:uid"
                          component={Invoice}
                        />
                        <Route
                          exact
                          path="/workflows/:uid"
                          component={Workflow}
                        />
                        <Route
                          exact
                          path="/login"
                          render={() =>
                            localStorage.getItem('api_key') ? (
                              <Redirect to="/" />
                            ) : (
                              <Login
                                token={this.state.token}
                                setToken={this.setToken}
                                setLoad={this.setLoad}
                              />
                            )
                          }
                        />
                        <Route exact path="/clients/:uid" component={Client} />
                        <Route exact path="*">
                          <ErrorPage type='maintenance' />
                        </Route>
                      </Switch>
                    </main>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </Router>
        ) : (
          <LinearProgress />
        )}
        <SimplePopUp
          openPopup={this.state.showLogoutModal}
          maxWidth="xs"
          title="Sign out"
          handleClose={this.toggleLogoutModal}
        >
          <p>Are you sure you want to sign out?</p>
          <div className="modalCtaContainer">
            <Button variant="outlined" onClick={this.toggleLogoutModal}>
              Cancel
            </Button>
            <Button variant="outlined" color="secondary" onClick={this.logOut}>
              Confirm
            </Button>
          </div>
        </SimplePopUp>
      </>
    )
  }
}

export default App
