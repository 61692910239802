import React from 'react'
import axios from 'axios'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import PositionForm from './PositionForm'
import AlertMessage from '../../shared/Notify/AlertMessage'
import ProgressBar from '../../shared/ProgressBar/ProgressBar'
import ErrorPage from '../../shared/ErrorPage/ErrorPage'

class Positions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Position',
      positions: [],
      openPopup: false,
      openDeletePopup: false,
      position: {},
      initialPosition: { uid: '', name: '' },
      title: '',
      load: false,
      tableHead: ['Name'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      isOpen: false,
      message: '',
      type: '',
      loadPositionsError: null
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
  }

  componentDidMount() {
    this.loadPositions(process.env.REACT_APP_API_DOMAIN + '/v1/positions')
  }

  loadPositions(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        this.setState({ positions: resp.data.data })
        this.setState({ load: true })
      })
      .catch((error) =>
        this.setState({ loadPositionsError: error.response.status })
      )
  }

  handleSubmit() {
    const item = this.state.position
    var method = ''
    var url = ''
    if (item.uid === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/positions/'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/positions/' + item.uid
    }
    axios({
      method: method,
      url: url,
      data: item,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        const newData = resp.data
        this.setState({
          positions: this.state.positions.filter(
            (position) => position.uid !== item.uid
          )
        })
        this.setState({ positions: [...this.state.positions, newData] })
        this.setState({ openPopup: false })
        this.setState({
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success'
        })
      })
      .catch((error) => {
        if (error.response.status === 422) {
          this.setState({
            error_messages: 'Name ' + error.response.data.errors.name[0]
          })
        } else {
          this.setState({
            isOpen: true,
            message:
              error.response.status.toString() +
              ' Unexpected Error Problem Occurred',
            type: 'error'
          })
          this.setState({ openPopup: false })
        }
      })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.position.uid
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/positions/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then(() => {
        this.setState({
          positions: this.state.positions.filter(
            (item) => item.uid !== DeleteItemId
          )
        })
        this.setState({ openDeletePopup: false })
        this.setState({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'error'
        })
      })
      .catch((error) => console.log(error.response))
  }

  handleClose() {
    this.setState({ openPopup: false })
    this.setState({ openDeletePopup: false })
    this.setState({ error_messages: '' })
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({ openPopup: true })
    this.setState({ position: item })
    this.setState({ title: textTitle })
  }

  handleInputChange(e) {
    console.log(e.target.name)
    console.log(e.target.value)

    this.setState({
      position: {
        ...this.state.position,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ position: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    this.setState({
      isOpen: false
    })
  }

  render() {
    const { load, loadPositionsError } = this.state

    return (
      <>
        {load && !loadPositionsError ? (
          <div>
            <SimpleTable
              handleClick={this.handleCreateorUpdateItem}
              initialItem={this.state.initialPosition}
              items={this.state.positions}
              model={this.state.model}
              onOpenDeletePopup={this.handleOpenDeletePopup}
              headers={this.state.tableHead}
              withShow={this.state.withShow}
              withEdit={this.state.withEdit}
            />
            <SimplePopUp
              openPopup={this.state.openPopup}
              title={this.state.title}
              items={this.state.positions}
              handleClose={this.handleClose}
              maxWidth={this.state.maxWidth}
            >
              <PositionForm
                error={this.state.error_messages}
                item={this.state.position}
                submit={this.handleSubmit}
                onchange={this.handleInputChange}
              />
            </SimplePopUp>

            <SimpleDeletePopUp
              openDeletePopup={this.state.openDeletePopup}
              item={this.state.position}
              delete={this.handleDeleteItem}
              handleDeleteClose={this.handleClose}
              model={this.state.model}
            />
            <AlertMessage
              notify={this.state.notify}
              handleCloseAlert={this.handleCloseAlert}
              isOpen={this.state.isOpen}
              type={this.state.type}
              message={this.state.message}
            />
          </div>
        ) : loadPositionsError ? (
          <ErrorPage type={loadPositionsError} />
        ) : (
          <ProgressBar model={this.state.model} />
        )}
      </>
    )
  }
}

export default Positions
