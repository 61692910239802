import React from 'react'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,Grid
} from '@material-ui/core'
import styles from "./Member.module.css";

class DocumentForm extends React.Component {
  render () {
    return (
      <div>
        <form>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="off"
                id="standard-basic"
                variant="outlined"
                className={styles.textFields}
                size="small"
                name="username"
                fullWidth
                required
                label="Username"
                onChange={this.props.onchange}
                value={this.props.item.username}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="off"
                id="standard-basic"
                variant="outlined"
                className={styles.textFields}
                size="small"
                name="email"
                required
                fullWidth
                label="Email"
                onChange={this.props.onchange}
                value={this.props.item.email}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                autoComplete="off"
                id="standard-basic"
                variant="outlined"
                className={styles.textFields}
                size="small"
                name="first_name"
                required
                fullWidth
                label="First name"
                onChange={this.props.onchange}
                value={this.props.item.first_name}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                autoComplete="off"
                id="standard-basic"
                variant="outlined"
                className={styles.textFields}
                size="small"
                name="middle_name"
                fullWidth
                label="Middle Name"
                onChange={this.props.onchange}
                value={this.props.item.middle_name}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                autoComplete="off"
                id="standard-basic"
                variant="outlined"
                className={styles.textFields}
                size="small"
                required
                name="last_name"
                fullWidth
                label="Last Name"
                onChange={this.props.onchange}
                value={this.props.item.last_name}
              />
            </Grid>
        </Grid>
        </form>
        <div className={styles.actionButton}>
          <Button variant="outlined" disabled={!this.props.item.email || !this.props.item.first_name || !this.props.item.last_name || !this.props.item.username} color="primary" onClick={() => this.props.submit()} >Save</Button>
        </div>
      </div>
    )
  }
}
export default DocumentForm

DocumentForm.propTypes = {
  onchange: PropTypes.func,
  onchanges: PropTypes.func,
  item: PropTypes.object,
  children: PropTypes.object,
  submit: PropTypes.func
}
