import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography, IconButton, ButtonGroup, TableContainer, Table, TableBody, TableRow
} from '@material-ui/core'
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import styles from '../Client.module.css'
class EmailAddresses extends React.Component {
  render() {
    return (
      <div className={styles.Parent}>
        {this.props.isCompany ? (
          <div className={styles.contactBody}>
            <TableContainer style={{ maxHeight: '575px' }}>
              <Table>
                <TableBody>
                  <TableRow>
                    {this.props.emailAddresses.length > 0 ? (
                      this.props.emailAddresses
                        .map((emailAddress) => (
                          <li key={emailAddress.uid} className={styles.cell} >
                            {emailAddress.content}
                          </li>
                        ))) : null}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : <div className="email-body" name="email-addresses">
          <Typography className="flex-justify">
            <div className={styles.parentTitle}><MailOutlineIcon fontSize="large" />Email Address :</div>
            {
              this.props.noAction ? null :

                <IconButton color="inherit" aria-label="menu"
                  onClick={() => this.props.handleClick('Add Email Address', 'Email Address', this.props.initialEmailAddress)}
                  name="create-emailAddress"
                >
                  <AddCircleOutlinedIcon />
                </IconButton>
            }
          </Typography>
          {this.props.emailAddresses.length > 0 ? (
            this.props.emailAddresses
              .map((emailAddress) => (
                <div key={emailAddress.uid} className={styles.emailListBody}>
                  <div className={styles.emailList}>
                    <div className={styles.emailListValue}>
                      {emailAddress.content}

                    </div>
                    &nbsp; - &nbsp;
                    <div className={styles.emailListLabel}>
                      <i>{emailAddress.category_name}</i>
                    </div>
                  </div>
                  {
                    this.props.noAction ? null :

                      <div className={styles.emailButtonHover}>
                        <ButtonGroup aria-label="outlined primary button group">
                          <IconButton
                            variant="contained"
                            color="primary"
                            onClick={() => this.props.handleClick('Edit Email Address', 'Email Address', emailAddress)}
                            name={`emailAddress-edit`}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            variant="contained"
                            color="secondary"
                            onClick={() => this.props.handleOpenDelete('email address', emailAddress)}
                            name={`emailAddress-delete`}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </ButtonGroup>
                      </div>
                  }
                </div>
              ))
          ) : (
            <div>
              <Typography colSpan={3}>No Saved Email Address</Typography>
            </div>
          )}
        </div>
        }
      </div>
    )
  }
}
export default EmailAddresses
EmailAddresses.propTypes = {
  emailAddresses: PropTypes.array,
  initialEmailAddress: PropTypes.object,
  handleClick: PropTypes.func,
  isCompany: PropTypes.bool,
  handleOpenDelete: PropTypes.func,
  noAction: PropTypes.bool
}