import { Container } from '@material-ui/core'
import React, { Component } from 'react'
import styles from './Error401.module.css'
import Error401Image from '../../assets/images/401_error.svg'

class Error401 extends Component {
  render() {
    return (
      <Container className={styles.mainContainer}>
        <Container className={styles.errorContentContainer}>
          <img
            src={Error401Image}
            alt="Error 401"
            className={styles.errorImage}
          />
          <div className={styles.errorMessageContainer}>
            <h1 className={styles.errorTitle}>Unauthorized Access</h1>
            <h2 className={styles.errorSubtitle}>401</h2>
          </div>
        </Container>
      </Container>
    )
  }
}

export default Error401
