import React from 'react'
import axios from 'axios'
import GroupIcon from '@material-ui/icons/Group'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import MemberForm from './MemberForm'
import AlertMessage from '../../shared/Notify/AlertMessage'
import ProgressBar from '../../shared/ProgressBar/ProgressBar'
import ErrorPage from '../../shared/ErrorPage/ErrorPage'

class Members extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Member',
      members: [],
      openPopup: false,
      openDeletePopup: false,
      member: {},
      initialDocument: {
        uid: '',
        email: '',
        username: '',
        profile_attributes: { first_name: '', middle_name: '', last_name: '' }
      },
      title: '',
      load: false,
      tableHead: ['first_name', 'middle_name', 'last_name', 'email'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: '',
      loadMembersError: null
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
  }

  componentDidMount() {
    this.loadMembers(process.env.REACT_APP_API_DOMAIN + '/v1/members')
  }

  loadMembers(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        this.setState({ members: resp.data })
        this.setState({ load: true })
      })
      .catch((error) =>
        this.setState({ loadMembersError: error.response.status })
      )
  }

  handleSubmit() {
    const item = this.state.member
    var method = ''
    var url = ''
    if (item.uid === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/users/'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/users/' + item.uid
    }
    axios({
      method: method,
      url: url,
      data: item,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        const newData = resp.data
        this.setState({
          members: this.state.members.filter(
            (member) => member.uid !== item.uid
          )
        })
        this.setState({
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success'
        })
        this.setState({ members: [...this.state.members, newData] })
        this.setState({ openPopup: false })
      })
      .catch((error) => {
        if (error.response.status === 422) {
          const errorObjectKeys = Object.keys(error.response.data.errors)
          const errorMessage = `${errorObjectKeys[0]}: ${
            error.response.data.errors[errorObjectKeys[0]]
          }`

          this.setState({
            error_messages: errorMessage,
            message: errorMessage.toString(),
            isOpen: true,
            type: 'error'
          })
        } else {
          this.setState({
            isOpen: true,
            message:
              error.response.status.toString() +
              ' Unexpected Error Problem Occurred',
            type: 'error'
          })
        }
        this.setState({ openPopup: false })
      })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.member.uid
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/members/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then(() => {
        this.setState({
          members: this.state.members.filter(
            (item) => item.uid !== DeleteItemId
          )
        })
        this.setState({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'error'
        })
        this.setState({ openDeletePopup: false })
      })
      .catch((error) => console.log(error.response))
  }

  handleClose() {
    this.setState({ openPopup: false })
    this.setState({ openDeletePopup: false })
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    console.log(item)
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({ openPopup: true })
    this.setState({ member: item })
    this.setState({ title: textTitle })
  }

  handleInputChange(e) {
    this.setState({
      member: {
        ...this.state.member,
        [e.target.name]: e.target.value,

        profile_attributes: {
          ...this.state.member.profile_attributes,
          [e.target.name]: e.target.value
        }
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ member: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    this.setState({
      isOpen: false
    })
  }

  render() {
    const { load, loadMembersError } = this.state

    return (
      <>
        {load && !loadMembersError ? (
          <div>
            <SimpleTable
              handleClick={this.handleCreateorUpdateItem}
              initialItem={this.state.initialDocument}
              items={this.state.members}
              model={this.state.model}
              onOpenDeletePopup={this.handleOpenDeletePopup}
              headers={this.state.tableHead}
              withShow={this.state.withShow}
              withEdit={this.state.withEdit}
              icon={<GroupIcon fontSize="large" />}
            />
            <SimplePopUp
              openPopup={this.state.openPopup}
              title={this.state.title}
              items={this.state.members}
              handleClose={this.handleClose}
              maxWidth={this.state.maxWidth}
            >
              <MemberForm
                item={this.state.member}
                submit={this.handleSubmit}
                onchange={this.handleInputChange}
              />
            </SimplePopUp>

            <SimpleDeletePopUp
              openDeletePopup={this.state.openDeletePopup}
              item={this.state.member}
              delete={this.handleDeleteItem}
              handleDeleteClose={this.handleClose}
              model={this.state.model}
            />
            <AlertMessage
              notify={this.state.notify}
              handleCloseAlert={this.handleCloseAlert}
              isOpen={this.state.isOpen}
              type={this.state.type}
              message={this.state.message}
            />
          </div>
        ) : loadMembersError ? (
          <ErrorPage type={loadMembersError} />
        ) : (
          <ProgressBar />
        )}
      </>
    )
  }
}

export default Members
