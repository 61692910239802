import React from 'react'

import PropTypes from 'prop-types'

import {
	TextField,
	Button,
	Grid
} from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete'

import styles from './Client.module.css'
import sortArray from 'sort-array'
class SearchForm extends React.Component {
	render() {
		return (
			<div>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={12}>
						<TextField
							fullWidth="true"
							name="name"
							variant="outlined"
							size="small"
							label="Name"
							value={this.props.searchClient.name}
							onChange={this.props.handleInputChange}
							InputLabelProps={{
								shrink: true
							}}
						/>
					</Grid>
					<Grid item xs={12}  sm={6}>
						<Autocomplete
							name="legal_case"
							size="small"
							options={sortArray(this.props.groups,{by: 'name'})}
							// options={this.props.groups}
							getOptionLabel={(option) => option.name || ''}
							getOptionSelected={(option, value) => option.name === value.name}
							onChange={this.props.handleAutoComplete}
							fullWidth="true"
							value={this.props.searchClient.group}
							renderInput={(params) => <TextField {...params} label="Group" variant="outlined" />}

						/></Grid>
					<Grid item xs={12}  sm={6}>
						<TextField
							fullWidth="true"
							name="contact_person_name"
							variant="outlined"
							size="small"
							label="Contact Person Name"
							value={this.props.searchClient.contact_person_name}
							onChange={this.props.handleInputChange}
							InputLabelProps={{
								shrink: true
							}}
						/>
					</Grid>
					<Grid item xs={12}  sm={6}>

						<TextField
							fullWidth="true"
							name="email_address_content"
							variant="outlined"
							size="small"
							label="Email Address"
							value={this.props.searchClient.email_address_content}
							onChange={this.props.handleInputChange}
							InputLabelProps={{
								shrink: true
							}}
						/>
					</Grid>
					<Grid item xs={12}  sm={6}>

						<TextField
							fullWidth="true"
							name="contact_number_content"
							variant="outlined"
							size="small"
							label="Contact Number"
							value={this.props.searchClient.contact_number_content}
							onChange={this.props.handleInputChange}
							InputLabelProps={{
								shrink: true
							}}
						/>
					</Grid>
				</Grid>
				<br />
				<Button onClick={this.props.clientSearch} className={styles.searchButton} color="primary" variant="outlined">Search</Button>


			</div>
		)
	}
}
export default SearchForm

SearchForm.propTypes = {

	groups: PropTypes.array,
	handleAutoComplete: PropTypes.func,
	transactions: PropTypes.array,
	clientSearch: PropTypes.func,
	handleInputChange: PropTypes.func,
	searchClient: PropTypes.object,

}
