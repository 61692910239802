// eslint-disable-next-line
import atributeStyle from './Event.css'

import React from 'react'
import PropTypes from 'prop-types'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import styles from "./Events.module.css"
import Grid from '@material-ui/core/Grid'

class Calendar extends React.Component {

  render() {
    return (
      <div className={styles.calendarBody}>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={10}>
            <FullCalendar
              initialView="dayGridMonth"
              defaultView="timeGridDay"
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              eventColor='#80cbc4'
              customButtons={{
                myCustomButton: {

                  text: 'Add Event',
                  click: () => this.props.handleCreateOrUpdateEvent(this.props.initialEvent)
                },
                toggleButton: {
                  text: 'Table View',
                  click: this.props.toggleView
                }
              }}
              headerToolbar={{
                left: 'prev,next,today,myCustomButton',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,toggleButton'
              }}
              eventSources={this.props.eventSources}
              eventClick={this.props.handleEventClick}
              nowIndicator='true'
              editable={true}
              eventChange={this.props.handleDragEvent}
              timeZone='local'
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <div>
              <div className={styles.titleBody}>
                <h3>Legend :</h3>
              </div>
              <div className={styles.legendBody}>
                <div className={styles.event}>
                  <div className={styles.boxEvent}>
                  </div>
                  <div>
                    <p> <span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>Events</span> </p>
                  </div>
                </div>
                  {
                    this.props.priorityLevels.map((priorityLevel, index) => {
                      return (
                        <div key={index} className={styles.none}>
                          <div className={styles.boxNone} style={{ backgroundColor: priorityLevel.color }}>
                          </div>
                          <div>
                            <p><span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>{priorityLevel.name} Priority</span> </p>
                          </div>
                        </div>
                      )
                    })
                  }
              </div>
            </div>
          </Grid>

        </Grid>
      </div>
    )
  }
}

export default Calendar

Calendar.propTypes = {
  eventSources: PropTypes.array,
  handleEventClick: PropTypes.func,
  handleCreateOrUpdateEvent: PropTypes.func,
  initialEvent: PropTypes.object,
  toggleView: PropTypes.func,
  tableView: PropTypes.bool,
  priorityLevels: PropTypes.array,
  handleDragEvent: PropTypes.func
}
