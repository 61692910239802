import React from 'react'

import PropTypes from 'prop-types'

import {
  Button
} from '@material-ui/core'
import styles from "../LegalCase.module.css";
import style from "../LegalCase.module.css";
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

class DocumentForm extends React.Component {
  render() {

    const { file } = this.props.document;
    let filename = null;
    filename = file
      ? ( <span><b>File Selected</b> - {file.name}</span>)
      : ( <span>Drag your files here or click in this area.</span> );

    return (
      <div>
        <form>
        <Autocomplete

              multiple
              size="small"
              options={this.props.users}
              getOptionLabel={(option) => option.last_name+ ", "+option.first_name || ''}
              getOptionSelected={(option, value) => option.username === value.username}
              onChange={this.props.handleUsersAutocomplete}
              value={this.props.selectedUsers}
              fullWidth
              name="case"
              renderInput={(params) => <TextField {...params} label="Users" name="user" variant="outlined" />}
            />
            <br />
          <div className={style.formBody}>

            <input
              id="file"
              type="file"
              name="file"
              className={style.input}
              onChange={(e) => this.props.onFileChange(e)}
            />
            <label
              className={style.filelabel}
              htmlFor="file">
              <VerticalAlignBottomIcon fontSize="large"/>
              <br/>
              {filename}
              {/*{this.state.document.file}*/}
            </label>
            <p className={styles.fileError}><b>{this.props.error}</b></p>
          </div>
        </form>
        <div className={styles.actionButton}>
          <Button variant="outlined" disabled={!this.props.selectedUsers.length>0} color="primary" onClick={() => this.props.handleSubmitDocuments()}>Save</Button>
        </div>
      </div>
    )
  }
}

export default DocumentForm

DocumentForm.propTypes = {
  onFileChange: PropTypes.func,
  document: PropTypes.object,
  children: PropTypes.object,
  handleSubmitDocuments: PropTypes.func,
  error: PropTypes.func,
  handleFileInputChange: PropTypes.func,
  handleUsersAutocomplete: PropTypes.func,
  users: PropTypes.array,
  selectedUsers: PropTypes.array
}
