import React from 'react'
import PropTypes from 'prop-types'
import OnBoardingForm from './OnboardingForm'
import axios from 'axios'
class OnBoarding extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			activeStep: 0,
			canProceedToFinish: false,
			steps: [
				'Company Information',
				'Users',
				'Groups',
				'Practice Areas',
				'Client Custom Fields',
				'Legal Case Custom Fields',
				'Priorities'
			],
			groups: [
				{ name: 'Judge' }, { name: 'Expert' }, { name: 'Client' }, { name: 'Co-counsel' }, { name: 'Defendant' }, { name: 'Insurance Specialists' }, { name: 'Leads (Consultations)' }, { name: 'Opposing Party' }, { name: 'Police Officers' }, { name: 'Unassigned' },
			],
			practiceAreas: [
				{ name: 'Information Technology Law' },
				{ name: 'Administrative, Criminal & Civil Litigation' },
				{ name: 'Intellectual Property Law' },
				{ name: 'Entertainment Law' },
				{ name: 'Family Law' },
				{ name: 'Estate Planning Law' },
				{ name: 'Corporate Law Services' },
				{ name: 'Data Privacy Law' },
				{ name: 'Commercial Law' },
				{ name: 'Labor Law' },
				{ name: 'Competition & Antitrust Law' },
				{ name: 'Local and International Taxation' },
				{ name: 'Immigration and Citizenship' },
				{ name: 'Admiralty and Maritime Law' },
				{ name: 'Local Government' }
			],
			clientCustomFields: [],
			caseCustomFields: [],
			company: {},
			priorities: [
				{ name: "High", color: '#FF0000', rank: 1 },
				{ name: "Medium", color: '#FFA500', rank: 2 },
				{ name: "Low", color: '#00FF00', rank: 3 },

			],
			priority: {},
			openPopup: false,
			members: [],
		}
		this.handleBack = this.handleBack.bind(this)
		this.handleNext = this.handleNext.bind(this)
		this.handleReset = this.handleReset.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
		this.handleRedirectTo = this.handleRedirectTo.bind(this)
		this.addgroup = this.addgroup.bind(this)
		this.removeGroup = this.removeGroup.bind(this)
		this.handleGroupChange = this.handleGroupChange.bind(this)
		this.handlePracticeAreaChange = this.handlePracticeAreaChange.bind(this)
		this.removePracticeArea = this.removePracticeArea.bind(this)
		this.addPracticeArea = this.addPracticeArea.bind(this)
		this.addClientCustomField = this.addClientCustomField.bind(this)
		this.addLegalCaseCustomField = this.addLegalCaseCustomField.bind(this)
		this.handleClientCustomFieldChange = this.handleClientCustomFieldChange.bind(this)
		this.handleLegalCaseCustomFieldChange = this.handleLegalCaseCustomFieldChange.bind(this)
		this.removeClientCustomField = this.removeClientCustomField.bind(this)
		this.removeLegalCaseCustomField = this.removeLegalCaseCustomField.bind(this)

		this.handleInputChange = this.handleInputChange.bind(this)
		this.handleDepartmentChange = this.handleDepartmentChange.bind(this)
		this.addDepartment = this.addDepartment.bind(this)
		this.removeDepartment = this.removeDepartment.bind(this)
		this.addPriority = this.addPriority.bind(this)
		this.handlePriorityChange = this.handlePriorityChange.bind(this)
		this.removePriority = this.removePriority.bind(this)
		this.handleCreateOrUpdate = this.handleCreateOrUpdate.bind(this)
		this.validateForm = this.validateForm.bind(this)
		this.addMember = this.addMember.bind(this)
		this.removeMember = this.removeMember.bind(this)
		this.handleMemberChange = this.handleMemberChange.bind(this)
		
	}

	handleBack() {
		this.setState(state => ({
			activeStep: state.activeStep - 1,
			canProceedToFinish: false,
		}))
	}

	handleReset() {
		this.setState({
			activeStep: 0,
			canProceedToFinish: false,
		})
	}

	handleNext() {
		this.setState(state => ({
			activeStep: state.activeStep + 1,
			canProceedToFinish: false,
		}))
	}

	handleInputChange(e) {
		this.setState({
			company: { ...this.state.company, [e.target.name]: e.target.value }
		})
	}



	handleSubmit() {
		const method = 'POST'
		var count = 0
		var modelCount = 0
		if (this.state.practiceAreas.length > 0) {
			modelCount++
			this.state.practiceAreas.map((item,index) => {
				axios({
					method: method,
					url: process.env.REACT_APP_API_DOMAIN + '/v1/practice_areas',
					data: (item),
					headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
				})
				if(index === this.state.practiceAreas.length - 1){
					count++
				}

			},300)
		}
		if (this.state.members.length > 0) {
			modelCount++
			this.state.members.map((item,index) => {
				axios({
					method: method,
					url: process.env.REACT_APP_API_DOMAIN + '/v1/members',
					data: (item),
					headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
				})
				if(index === this.state.members.length - 1){
					count++
				}

			},300)
		}


		if (this.state.clientCustomFields.length > 0) {
			modelCount++
			this.state.clientCustomFields.map((item,index) => {
				var newItem = {
					attr_type: item.type,
					attr_name: item.name,
					klass: 0
				}
				axios({
					method: method,
					url: process.env.REACT_APP_API_DOMAIN + '/v1/custom_fields',
					data: (newItem),
					headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
				})
				if(index === this.state.clientCustomFields.length - 1){
					count++
				}
			},300)
		}
		if (this.state.caseCustomFields.length > 0) {
			modelCount++
			this.state.caseCustomFields.map((item,index) => {
				var newItem = {
					attr_type: item.type,
					attr_name: item.name,
					klass: 1
				}
				axios({
					method: method,
					url: process.env.REACT_APP_API_DOMAIN + '/v1/custom_fields',
					data: (newItem),
					headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
				})
				if(index === this.state.caseCustomFields.length - 1){
					count++
				}
			},300)
		}
		if (this.state.groups.length > 0) {
			modelCount++
			this.state.groups.map((item,index) => {
				axios({
					method: method,
					url: process.env.REACT_APP_API_DOMAIN + '/v1/groups',
					data: (item),
					headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
				})
				if(index === this.state.groups.length - 1){
					count++
				}
			},300)
		}
		if (this.state.priorities.length > 0) {
			modelCount++
			this.state.priorities.map((item,index) => {
				axios({
					method: method,
					url: process.env.REACT_APP_API_DOMAIN + '/v1/priorities',
					data: (item),
					headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
				})
				if(index === this.state.priorities.length - 1){
					count++
				}
			})
		}
		if (count === modelCount) {
			return location.href = '/'
		}
		
	}


	addgroup() {
		this.setState({ groups: [...this.state.groups, { name: '' }] })
	}

	handleGroupChange(e, index) {
		const groups = [...this.state.groups]
		groups[index].name = e.target.value
		this.setState({ groups: groups })

	}


	removeGroup(index) {
		const groups = [...this.state.groups]
		groups.splice(index, 1)
		this.setState({ groups: groups })

	}


	addPracticeArea() {
		this.setState({ practiceAreas: [...this.state.practiceAreas, { name: '' }] })
	}

	handlePracticeAreaChange(e, index) {
		const practiceAreas = [...this.state.practiceAreas]
		practiceAreas[index].name = e.target.value
		this.setState({ practiceAreas: practiceAreas })
	}

	removePracticeArea(index) {
		const practiceAreas = [...this.state.practiceAreas]
		practiceAreas.splice(index, 1)
		this.setState({ practiceAreas: practiceAreas })
	}


	addClientCustomField() {
		this.setState({ clientCustomFields: [...this.state.clientCustomFields, { name: '' }] })
	}

	handleClientCustomFieldChange(e, index) {
		const clientCustomFields = [...this.state.clientCustomFields]
		clientCustomFields[index][e.target.name] = e.target.value
		this.setState({ clientCustomFields: clientCustomFields })
	}

	removeClientCustomField(index) {
		const clientCustomFields = [...this.state.clientCustomFields]
		clientCustomFields.splice(index, 1)
		this.setState({ clientCustomFields: clientCustomFields })
	}


	addLegalCaseCustomField() {
		this.setState({ caseCustomFields: [...this.state.caseCustomFields, { name: '' }] })
	}

	handleLegalCaseCustomFieldChange(e, index) {
		const caseCustomFields = [...this.state.caseCustomFields]
		caseCustomFields[index][e.target.name] = e.target.value
		this.setState({ caseCustomFields: caseCustomFields })
	}

	removeLegalCaseCustomField(index) {
		const caseCustomFields = [...this.state.caseCustomFields]
		caseCustomFields.splice(index, 1)
		this.setState({ caseCustomFields: caseCustomFields })
	}

	handleDepartmentChange(e, index) {
		const departments = [...this.state.departments]
		departments[index][e.target.name] = e.target.value
		this.setState({ departments: departments })
	}

	addDepartment() {
		this.setState({ departments: [...this.state.departments, { name: '' }] })
	}

	removeDepartment(index) {
		const departments = [...this.state.departments]
		departments.splice(index, 1)
		this.setState({ departments: departments })
	}

	addPriority() {
		this.setState({ priorities: [...this.state.priorities, { name: '',color:'#fff',rank:this.state.priorities.length+1  }] })
	}

	handleCreateOrUpdate(e, index) {
		this.setState({ priority: this.state.priorities[index], openPopup: true })

	}

	handlePriorityChange(e, index) {
		const priorities = [...this.state.priorities]
		priorities[index][e.target.name] = e.target.value
		this.setState({ priorities: priorities })
	}



	removePriority(index) {
		const priorities = [...this.state.priorities]
		priorities.splice(index, 1)
		this.setState({ priorities: priorities })
	}

	handlePrioritySubmit(e) {
		e.preventDefault()
		const priorities = [...this.state.priorities]
		priorities.push(this.state.priority)
		this.setState({ priorities: priorities, openPopup: false })
	}

	handlePriorityClose() {
		this.setState({ openPopup: false })
	}

	validateForm(x) {
		if (x === 0) {
			if (!this.state.company.name || !this.state.company.trade_name || !this.state.company.address
				|| !this.state.company.firstname || !this.state.company.cellphone || !this.state.company.email) {
				return true
			}
		}
		
		if (x === 1) {
			var disabled = false
			if (this.state.members.length > 0) {
				this.state.members.map((member) => {
					if (!member.username || !member.first_name || !member.last_name || !member.email) {
						disabled = true
					}
				})
			} else {
				disabled = true
			}
			return disabled
		}
		if (x === 2) {
			
			if (this.state.groups.length > 0) {
				this.state.groups.map((group) => {
					if (!group.name) {
						disabled = true
					}
				})
			} else {
				disabled = true
			}
			return disabled
		}
		if (x === 3) {
			var finished = false
			if (this.state.practiceAreas.length > 0) {
				this.state.practiceAreas.map((practiceArea) => {
					if (!practiceArea.name) {
						finished = true
					}
				})
			} else {
				finished = true
			}
			return finished
		}
	}

	addMember() {
		this.setState({ members: [...this.state.members, { username: '',email: '',first_name: '',last_name: '',middle_name:'' }] })
	}

	handleMemberChange(e, index) {
		const members = [...this.state.members]
		members[index][e.target.name] = e.target.value
		this.setState({ members: members })
	}

	removeMember(index) {
		const members = [...this.state.members]
		members.splice(index, 1)
		this.setState({ members: members })
	}


	handleRedirectTo(i){
		this.setState({ activeStep: i })
	}






	render() {
		return (
			<>
				<OnBoardingForm
					activeStep={this.state.activeStep}
					canProceedToFinish={this.state.canProceedToFinish}
					handleRedirectTo={this.handleRedirectTo}
					steps={this.state.steps}
					handleNext={this.handleNext}
					handleBack={this.handleBack}
					handleReset={this.handleReset}
					handleSubmit={this.handleSubmit}
					addgroup={this.addgroup}
					handleGroupChange={this.handleGroupChange}
					removegroup={this.removeGroup}
					groups={this.state.groups}
					practiceAreas={this.state.practiceAreas}
					addPracticeArea={this.addPracticeArea}
					handlePracticeAreaChange={this.handlePracticeAreaChange}
					removePracticeArea={this.removePracticeArea}
					caseCustomFields={this.state.caseCustomFields}
					clientCustomFields={this.state.clientCustomFields}
					addClientCustomField={this.addClientCustomField}
					addLegalCaseCustomField={this.addLegalCaseCustomField}
					handleClientCustomFieldChange={this.handleClientCustomFieldChange}
					handleLegalCaseCustomFieldChange={this.handleLegalCaseCustomFieldChange}
					removeClientCustomField={this.removeClientCustomField}
					removeLegalCaseCustomField={this.removeLegalCaseCustomField}
					saveData={this.handleSubmit}
					handleChange={this.handleInputChange}
					departments={this.state.departments}
					addDepartment={this.addDepartment}
					handleDepartmentChange={this.handleDepartmentChange}
					removeDepartment={this.removeDepartment}
					item={this.state.company}
					priorities={this.state.priorities}
					handlePriorityChange={this.handlePriorityChange}
					removePriority={this.removePriority}
					addPriority={this.addPriority}
					validateForm={this.validateForm}
					addMember={this.addMember}
					handleMemberChange={this.handleMemberChange}
					removeMember={this.removeMember}
					members={this.state.members}
					
				/>
			</>
		)


	}
}
export default OnBoarding

OnBoarding.propTypes = {

	history: PropTypes.object,
}



