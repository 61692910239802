import React from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import DocumentForm from './DocumentForm'
import AlertMessage from '../../shared/Notify/AlertMessage'
import ProgressBar from '../../shared/ProgressBar/ProgressBar'
import ErrorPage from '../../shared/ErrorPage/ErrorPage'
import { Box } from '@material-ui/core'
import styles from './Document.module.css'

export const Component = withRouter(() => {})

class Documents extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Document',
      documents: [],
      legalCaseDocuments: [],
      legalCase: [],
      folderDocuments: [],
      openPopup: false,
      legalCaseId: '',
      folderId: '',
      openDeletePopup: false,
      openPopupSearch: false,
      document: {},
      initialDocument: { uid: '', title: '', file: '', user_ids: [] },
      title: '',
      load: false,
      tableHead: ['Title', 'Case', 'Folder', 'user_uids'],
      withShow: false,
      withEdit: true,
      error_messages: '',
      isOpen: false,
      q: '',
      message: '',
      type: '',
      withPagination: false,
      withSearch: true,
      documentsPage: {},
      totalPages: 0,
      currentPage: 0,
      maxWidth: 'sm',
      users: [],
      caseUsers: [],
      members: [],
      searchFormField: {},
      searchUrl: process.env.REACT_APP_API_DOMAIN + '/v1/documents',
      loadDocumentsError: null,
      isUpdateForm: false,
      legalCaseDefaultValue: '',
      caseUsersDefaultValue: '',
      folders: [],
      legalCases: [],
      folderDocumentsDefaultValue: '',
      fileHasChanged: false
    }
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCasesAutoComplete = this.handleCasesAutoComplete.bind(this)
    this.handleFolderAutoComplete = this.handleFolderAutoComplete.bind(this)
    this.handleSubmitDocuments = this.handleSubmitDocuments.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this._handleKeyPress = this._handleKeyPress.bind(this)
    this.handleChanges = this.handleChanges.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleUsersAutocomplete = this.handleUsersAutocomplete.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
  }

  componentDidMount() {
    var searchUrl = this.state.searchUrl + this.props.location.search
    var searchParams = {}
    var perPage = ''
    const params = new URLSearchParams(this.props.location.search)
    params.forEach(function (value, key) {
      if (key !== 'page') {
        searchParams[key] = value.replace(/\*/g, '')
      }
      if (key === 'per_page') {
        perPage = value
      }
    })
    if (perPage) {
      this.setState({ perPage: perPage })
    }
    this.setState({ searchFormField: searchParams })
    this.loadDocuments(searchUrl)
  }

  loadDocuments(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        this.setState({ documents: resp.data.data })
        this.setState({ documentsPage: resp.data.meta })
        this.setState({ totalPages: resp.data.meta.total_pages })
        this.setState({ currentPage: resp.data.meta.current_page })
        axios({
          method: 'get',
          url:
            process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases?per_page=1000',
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        })
          .then((resp) => {
            this.setState({ legalCase: resp.data.data })
            axios({
              method: 'get',
              url: process.env.REACT_APP_API_DOMAIN + '/v1/members',
              headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
            })
              .then((resp) => {
                this.setState({ members: resp.data })
                this.setState({ users: resp.data })
                this.setState({ load: true })
              })
              .catch((error) =>
                this.setState({ loadDocumentsError: error.response.status })
              )
            axios({
              method: 'get',
              url: process.env.REACT_APP_API_DOMAIN + '/v1/folders',
              headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
            })
              .then((resp) => {
                this.setState({ folders: resp.data })
              })
            axios({
              method: 'get',
              url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases',
              headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
            })
              .then((resp) => {
                this.setState({ legalCases: resp.data.data })
              })
          })
          .catch((error) => {
            this.setState({ loadDocumentsError: error.response.status })
          })
      })
      .catch((error) => {
        this.setState({ loadDocumentsError: error.response.status })
      })
  }

  handleSubmitDocuments() {
    const item = this.state.document
    const legalCaseId = this.state.legalCaseId
    const folderId = this.state.folderId
    const API_KEY = localStorage.getItem('api_key')
    const file = item.file
    const title = item.file.name
    const formdata = new FormData()
    formdata.append('file', file)
    formdata.append('title', title)
    var method = ''
    var url = ''
    if(this.state.fileHasChanged){
      item.file_content_type = file.size,
      item.file_name = file.name,
      item.file_size = file.size,
      item.file_updated_at = new Date(file.lastModified).toDateString()
    }
    if (item.uid === '') {
      if (item.user_ids.length > 0) {
        item.user_ids.map((userId) => {
          formdata.append('user_ids[]', userId)
        })
      }
      if (this.state.legalCaseId !== '') {
        if (this.state.folderId !== '') {
          method = 'post'
          url =
            process.env.REACT_APP_API_DOMAIN +
            '/v1/folders/' +
            folderId +
            '/documents/'
        } else {
          method = 'post'
          url =
            process.env.REACT_APP_API_DOMAIN +
            '/v1/legal_cases/' +
            legalCaseId +
            '/documents/'
        }
      } else {
        method = 'post'
        url = process.env.REACT_APP_API_DOMAIN + '/v1/documents/'
      }
    } else {
      method = 'put'

      if (legalCaseId !== undefined && !this.state.isUpdateForm) {
        url =
          process.env.REACT_APP_API_DOMAIN +
          '/v1/legal_cases/' +
          legalCaseId +
          '/documents/' +
          item.uid
      } else if (folderId !== undefined && !this.state.isUpdateForm) {
        url =
          process.env.REACT_APP_API_DOMAIN +
          '/v1/folders/' +
          folderId +
          '/documents/' +
          item.uid
      } else {
        url = process.env.REACT_APP_API_DOMAIN + '/v1/documents/' + item.uid
      }
    }
    axios({
      method: method,
      url: url,
      headers: { 'X-APP-API-KEY': API_KEY },
      data: this.state.fileHasChanged ? formdata : item
    })
      .then((resp) => {
        const newData = resp.data
        this.setState({
          documents: this.state.documents.filter(
            (document) => document.uid !== item.uid
          )
        })
        this.setState({ documents: [...this.state.documents, newData] })
        this.setState({
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success'
        })
        this.setState({ openPopup: false })
      })
      .catch((error) => {
        if (error.response.status === 422) {
          this.setState({
            error_messages: 'File ' + error.response.data.errors.title[0]
          })
        } else {
          this.setState({
            isOpen: true,
            message:
              error.response.status.toString() +
              ' Unexpected Error Problem Occurred',
            type: 'error'
          })
          this.setState({ openPopup: false })
        }
      })
    this.setState({ fileHasChanged: false })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.document.uid
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/documents/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then(() => {
        this.setState({
          documents: this.state.documents.filter(
            (item) => item.uid !== DeleteItemId
          )
        })
        this.setState({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'error'
        })
        this.setState({ openDeletePopup: false })
      })
      .catch((error) => console.log(error.response))
  }

  handleClose() {
    this.setState({ openPopup: false })
    this.setState({ openDeletePopup: false })
    this.setState({ error_messages: '' })
    this.setState({ legalCaseId: '' })
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
      this.setState({ isUpdateForm: false })
    } else {
      textTitle = 'Edit ' + model
      const legalCase = item.folder ? item.folder.legal_case: item.legal_case
      const legalCaseId = legalCase ? legalCase.uid : null
      const folderId = item.folder ? item.folder.uid : null
      const selectedCase = Array.from(this.state.legalCases).find((legalCase) => legalCase.uid === legalCaseId )
      this.setState({ legalCaseId: legalCaseId })
      this.setState({ isUpdateForm: true })
      this.setState({ legalCaseDefaultValue: item.folder ? item.folder.legal_case : item.legal_case })
      this.setState({ caseUsersDefaultValue: Array.from(this.state.users).filter((user) => item.user_uids.includes(user.uid) ) })
      this.setState({ caseUsers: Array.from(this.state.users).filter((user) => selectedCase && selectedCase.user_uids.includes(user.uid) ) })
      this.setState({ folderDocuments: this.state.folders.filter((folder) => folder.legal_case.uid === legalCaseId ) })
      this.setState({ folderDocumentsDefaultValue: this.state.folders.find((folder) => folder.uid === folderId )})
    }
    this.setState({ openPopup: true })
    this.setState({ document: item })
    this.setState({ title: textTitle })
  }

  handleInputChange(e) {
    this.setState({
      document: {
        ...this.state.document,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ document: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  handleCasesAutoComplete(event, values) {
    if( values === null ) {
      this.setState({
        document: {
          ...this.state.document,
          legal_case: null,
          folder: null
        }
      })
    } else {
      axios({
        method: 'get',
        url:
          process.env.REACT_APP_API_DOMAIN +
          '/v1/legal_cases/' +
          values.uid +
          '/folders',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      })
        .then((resp) => {
          this.setState({ folderDocuments: resp.data })
        })
        .catch((error) => console.log(error.response))
      this.setState({ legalCaseId: values.uid })
      const selectedCase = this.state.legalCases.find((legalCase) => legalCase.uid === values.uid )
      this.setState({
        document: {
          ...this.state.document,
          ...(this.state.document.folder && {folder: { ...this.state.document.folder, legal_case: selectedCase }}),
          ...(this.state.document.legal_case && { legal_case: selectedCase }),
          ...((!this.state.document.folder && !this.state.document.legal_case) ? { legal_case: selectedCase } : null )
        }
      })
      this.setState({ caseUsers: this.state.users.filter((user) => selectedCase.user_uids.includes(user.uid)) })
      this.setState({ caseUsersDefaultValue: '' })
      this.setState({ folderDocuments: this.state.folders.filter((folder) => folder.legal_case.uid === selectedCase.uid ) })
      this.setState({ folderDocumentsDefaultValue: '' })
    }
  }

  handleFolderAutoComplete(event, values) {
    if (values !== null) {
      this.setState({ folderId: values.uid })
      this.setState({
        document: {
          ...this.state.document,
          folder: values,
          legal_case: null
        }
      })
    } else {
      this.setState({
        document: {
          ...this.state.document,
          legal_case: this.state.document.folder ? this.state.document.folder.legal_case : this.state.document.legal_case,
          folder: null
        }
      })
    }
  }

  handleUsersAutocomplete(event, values) {
    const user_ids = values.map((value) => value.uid )
      this.setState({
        document: {
          ...this.state.document,
          user_ids: user_ids
        }
      })
  }

  onFileChange(e) {
    e.stopPropagation()
    e.preventDefault()
    this.setState({ error_messages: '' })
    const file = e.target.files[0]
    this.setState({
      document: {
        ...this.state.document,
        file: file
      },
      fileHasChanged: true
    })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    this.setState({
      isOpen: false
    })
  }

  _handleKeyPress(e) {
    if (e.key === 'Enter') {
      const { history } = this.props
      var searchParams = this.getSearchParams().join('&')
      const params = new URLSearchParams()
      params.append('/search', searchParams)
      history.push({ search: searchParams })
      this.loadDocuments([this.state.searchUrl, searchParams].join('?'), false)
    }
  }

  handleChanges(event) {
    this.setState({ q: event.target.value })
  }

  getSearchParams() {
    var searchParams = []
    if (this.state.q) {
      searchParams.push(['q', this.state.q].join('='))
    }
    return searchParams
  }

  handlePageChange(event, value) {
    var searchParams = this.getSearchParams()
    searchParams.push(['page', value].join('='))
    searchParams = searchParams.join('&')
    const { history } = this.props
    const params = new URLSearchParams()
    params.append('/search', searchParams)
    history.push({ search: searchParams })
    this.setState({ currentPage: value })
    this.loadDocuments([this.state.searchUrl, searchParams].join('?'))
  }

  clearSearch() {
    const { history } = this.props
    history.push({ search: '' })
    this.setState({ searchFormField: {} })
    this.setState({ q: '' })
    this.loadDocuments(this.state.searchUrl)
  }

  render() {
    const { load, loadDocumentsError } = this.state

    return (
      <>
        {load && !loadDocumentsError ? (
          <>
            <Box className={styles.boxMain}>
              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialDocument}
                items={this.state.documents}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShow={this.state.withShow}
                withEdit={this.state.withEdit}
                q={this.state.q}
                documentsPage={this.state.documentsPage}
                currentPage={this.state.currentPage}
                totalPages={this.state.totalPages}
                handlePageChange={this.handlePageChange}
                withPagination={this.state.withPagination}
                _handleKeyPress={this._handleKeyPress}
                withSearch={this.state.withSearch}
                handleChanges={this.handleChanges}
                users={this.state.users}
                clearSearch={this.clearSearch}
              />
            </Box> 
            <SimplePopUp
              openPopup={this.state.openPopup}
              title={this.state.title}
              items={this.state.documents}
              handleClose={this.handleClose}
              maxWidth={this.state.maxWidth}
            >
              <DocumentForm
                error={this.state.error_messages}
                onFileChange={this.onFileChange}
                handleFileInputChange={this.handleFileInputChange}
                document={this.state.document}
                folderDocuments={this.state.folderDocuments}
                handleCasesAutoComplete={this.handleCasesAutoComplete}
                handleFolderAutoComplete={this.handleFolderAutoComplete}
                legalCase={this.state.legalCase}
                item={this.state.document}
                submit={this.handleSubmitDocuments}
                legalCaseId={this.state.legalCaseId}
                onchange={this.handleInputChange}
                handleUsersAutocomplete={this.handleUsersAutocomplete}
                users={this.state.users}
                caseUsers={this.state.caseUsers}
                isUpdateForm={this.state.isUpdateForm}
                legalCaseDefaultValue={this.state.legalCaseDefaultValue}
                caseUsersDefaultValue={this.state.caseUsersDefaultValue}
                folderDocumentsDefaultValue={this.state.folderDocumentsDefaultValue}
              />
            </SimplePopUp>

            <SimplePopUp
              openPopup={this.state.openPopupSearch}
              handleClose={this.handleCloseSearch}
              title="Advanced Search"
            ></SimplePopUp>

            <SimpleDeletePopUp
              openDeletePopup={this.state.openDeletePopup}
              item={this.state.document}
              delete={this.handleDeleteItem}
              handleDeleteClose={this.handleClose}
              model={this.state.model}
            />
            <AlertMessage
              notify={this.state.notify}
              handleCloseAlert={this.handleCloseAlert}
              isOpen={this.state.isOpen}
              type={this.state.type}
              message={this.state.message}
            />
          </>
        ) : loadDocumentsError ? (
          <ErrorPage type={loadDocumentsError} />
        ) : (
          <div>
            <ProgressBar model={this.state.model} />
          </div>
        )}
      </>
    )
  }
}

export default withRouter(Documents)
Documents.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
}
