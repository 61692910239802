import React from 'react'
import axios from 'axios'
import { Redirect } from 'react-router'
import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import WorkflowForm from './WorkflowForm'
import AlertMessage from '../../shared/Notify/AlertMessage'
import ProgressBar from '../../shared/ProgressBar/ProgressBar'
import ErrorPage from '../../shared/ErrorPage/ErrorPage'

class Workflows extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      workflows: [],
      load: false,
      model: 'Workflow',
      openPopup: false,
      openDeletePopup: false,
      workflow: {},
      initialWorkflow: { uid: '', name: '' },
      title: '',
      tableHead: ['Name', 'Actions'],
      trigger: { uid: '', name: '' },
      triggers: [],
      withShow: true,
      errors: '',
      subs: [],
      redirect: false,
      notify: {},
      isOpen: false,
      message: '',
      type: '',
      draftWorkflows: [],
      queryString: '',
      loadWorkflowError: null,
      createOrUpdateWorkflow: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.addTrigger = this.addTrigger.bind(this)
    this.handleTriggers = this.handleTriggers.bind(this)
    this.removeTrigger = this.removeTrigger.bind(this)
    this.handleSubmitTriggers = this.handleSubmitTriggers.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleChangeQueryField = this.handleChangeQueryField.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
  }

  componentDidMount() {
    this.loadWorkflow(process.env.REACT_APP_API_DOMAIN + '/v1/workflows')
  }

  loadWorkflow(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        var newData = []
        var subData = []
        resp.data.data.map((item) => {
          if (item.parent_uid === null) {
            return newData.push(item)
          } else {
            return subData.push(item)
          }
        })
        this.setState({ workflows: newData })
        this.setState({ subs: subData })
        this.setState({ load: true })
        this.setState({ draftWorkflows: newData })
      })
      .catch((error) =>
        this.setState({ loadWorkflowError: error.response.status })
      )
  }

  handleSubmit() {
    const item = this.state.workflow
    var method = ''
    var url = ''
    if (item.uid === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/workflows/'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/workflows/' + item.uid
    }
    axios({
      method: method,
      url: url,
      data: item,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        const newData = resp.data
        this.setState({
          workflows: this.state.workflows.filter(
            (workflow) => workflow.uid !== item.uid
          )
        })
        this.setState({ workflows: [...this.state.workflows, newData] })
        this.setState({ openPopup: false })
        this.state.triggers.map((trigger) => {
          trigger.parent_id = resp.data.uid
          return null
        })
        this.handleSubmitTriggers()
        this.setState({
          redirect: true,
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success'
        })
        this.setState({ workflow: resp.data })
      })
      .catch((error) => {
        if (error.response.status === 422) {
          this.setState({
            error_messages: 'Name ' + error.response.data.errors.name[0]
          })
        } else {
          this.setState({
            isOpen: true,
            message:
              error.response.status.toString() +
              ' Unexpected Error Problem Occurred',
            type: 'error'
          })
          this.setState({ openPopup: false })
        }
      })
  }

  handleSubmitTriggers() {
    if (this.state.triggers.length > 0) {
      this.state.triggers.map(async (trigger) => {
        var method = ''
        var url = ''
        if (trigger.uid === '') {
          method = 'post'
          url = process.env.REACT_APP_API_DOMAIN + '/v1/workflows/'
        } else {
          method = 'put'
          url =
            process.env.REACT_APP_API_DOMAIN + '/v1/workflows/' + trigger.uid
        }
        try {
          const resp = await axios({
            method: method,
            url: url,
            data: trigger,
            headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
          })

          const newData = resp.data
          this.setState({
            subs: this.state.subs.filter((sub) => sub.uid !== trigger.uid)
          })
          this.setState({ subs: [...this.state.subs, newData] })
          this.setState({
            isOpen: true,
            message: 'Submitted Successfully',
            type: 'success'
          })
        } catch (error) {
          return console.log(error.response)
        }
      })
    }
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.workflow.uid
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/workflows/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then(() => {
        this.setState({
          workflows: this.state.workflows.filter(
            (item) => item.uid !== DeleteItemId
          )
        })
        this.setState({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'error'
        })
        this.setState({ openDeletePopup: false })
        this.setState({ triggers: [] })
      })
      .catch((error) => console.log(error.response))
  }

  handleClose() {
    this.setState({ openPopup: false })
    this.setState({ openDeletePopup: false })
    this.setState({ trigger: { uid: '', name: '' } })
    this.setState({ triggers: [{ uid: '', name: '' }] })
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var subData = []
    if (this.state.subs.length > 0) {
      this.state.subs.map((sub) => {
        if (sub.parent_uid === item.uid) {
          subData.push(sub)
        }
        return null
      })
    }

    if (subData.length > 0) {
      this.setState({ triggers: subData })
    } else {
      this.setState({ triggers: [this.state.trigger] })
    }
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({ openPopup: true })
    this.setState({ workflow: item })
    this.setState({ title: textTitle })
    this.setState({ createOrUpdateWorkflow: isAdd ? 'create' : 'update' })
  }

  handleInputChange(e) {
    this.setState({
      workflow: {
        ...this.state.workflow,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ workflow: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  addTrigger() {
    this.setState({ triggers: [...this.state.triggers, { uid: '', name: '' }] })
    console.log('Add Triggers', this.state.triggers)
  }

  handleTriggers(e) {
    const updatedTriggers = [...this.state.triggers]
    updatedTriggers[e.target.dataset.id][e.target.dataset.fieldType] =
      e.target.value
    this.setState({ triggers: updatedTriggers })
  }

  removeTrigger(idx) {
    var uid = this.state.triggers[idx].uid
    if(uid){
      axios({
        method: 'delete',
        url:
          process.env.REACT_APP_API_DOMAIN +
          '/v1/workflows/' +
          this.state.triggers[idx].uid,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then(() => {
        this.setState({
          subs: this.state.subs.filter((sub) => sub.uid !== uid)
        })
      })
    }
    
    this.state.triggers.splice(idx, 1)
    this.setState({ triggers: [...this.state.triggers] })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    this.setState({
      isOpen: false
    })
  }

  handleChangeQueryField(event) {
    this.setState({ queryString: event.target.value })
  }

  handleKeyPress(event) {
    const { queryString, draftWorkflows } = this.state

    if (event.key !== 'Enter') return

    if (queryString === '') {
      this.setState({ workflows: draftWorkflows })
    } else {
      axios({
        method: 'get',
        url:
          process.env.REACT_APP_API_DOMAIN + `/v1/workflows?q=*${queryString}*`,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        this.setState({ workflows: resp.data.data })
      })
    }
  }

  clearSearch() {
    this.setState({ queryString: '' })
  }

  render() {
    const { redirect, load, loadWorkflowError } = this.state

    if (redirect)
      return (
        <Redirect
          to={{
            pathname: '/workflows/' + this.state.workflow.uid,
            state: { item: this.state.workflow }
          }}
        />
      )

    return (
      <>
        {load && !loadWorkflowError ? (
          <div>
            <SimpleTable
              handleClick={this.handleCreateorUpdateItem}
              initialItem={this.state.initialWorkflow}
              items={this.state.workflows}
              model={this.state.model}
              onOpenDeletePopup={this.handleOpenDeletePopup}
              headers={this.state.tableHead}
              withShow={this.state.withShow}
              withSearch={true}
              _handleKeyPress={this.handleKeyPress}
              handleChanges={this.handleChangeQueryField}
              clearSearch={this.clearSearch}
              q={this.state.queryString}
            />

            <SimplePopUp
              openPopup={this.state.openPopup}
              title={this.state.title}
              items={this.state.workflows}
              handleClose={this.handleClose}
            >
              <WorkflowForm
                error={this.state.error_messages}
                handleInputChange={this.handleInputChange}
                workflow={this.state.workflow}
                triggers={this.state.triggers}
                addTrigger={this.addTrigger}
                handleTriggers={this.handleTriggers}
                removeTrigger={this.removeTrigger}
                handleSubmit={this.handleSubmit}
                createOrUpdateWorkflow={this.state.createOrUpdateWorkflow}
              />
            </SimplePopUp>

            <SimpleDeletePopUp
              openDeletePopup={this.state.openDeletePopup}
              item={this.state.workflow}
              delete={this.handleDeleteItem}
              handleDeleteClose={this.handleClose}
              model={this.state.model}
            />
            <AlertMessage
              notify={this.state.notify}
              handleCloseAlert={this.handleCloseAlert}
              isOpen={this.state.isOpen}
              type={this.state.type}
              message={this.state.message}
            />
          </div>
        ) : loadWorkflowError ? (
          <ErrorPage type={loadWorkflowError} />
        ) : (
          <ProgressBar model={this.state.model} />
        )}
      </>
    )
  }
}

export default Workflows
