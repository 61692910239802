import React from 'react'

import PropTypes from 'prop-types'

import styles from './Tasks.module.css'

import Autocomplete from '@material-ui/lab/Autocomplete'

import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'

import AddBoxIcon from '@material-ui/icons/AddBox'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import FlagIcon from '@material-ui/icons/Flag';
class TaskForm extends React.Component {

  render() {
    return (
      <>
        <form>
          <div className={styles.switch}>
            Time Based <Switch name="task-switch" checked={this.props.task.is_time_base !== undefined ? (this.props.task.is_time_base) : (false)} onClick={this.props.handleSwitch} />
          </div>
          {
            this.props.withCaseLink ? (
              <div>
                <Autocomplete
                  value={this.props.legalCase}
                  id="legal_case"
                  size="small"
                  options={this.props.cases.sort(function (a, b) {
                    if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
                    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                    return 0;
                  })}
                  getOptionLabel={(option) => option.title}
                  getOptionSelected={(option, value) => option.title === value.title}
                  onChange={this.props.handleAutoComplete}
                  fullWidth
                  name="case"
                  renderInput={(params) => <TextField {...params} name="case" label="Case" variant="outlined" />}
                  disabled={this.props.show}
                />

                <Typography variant="caption" gutterBottom>
                  <Checkbox onClick={this.props.handleCheck} name="checkbox-caselink" checked={!this.props.show} />This task is linked to a case</Typography>
              </div>
            ) : (null)
          }
          <TextField
            autoComplete="off"
            autoFocus={true}
            className={styles.formTextfield}
            variant="outlined"
            size="small"
            fullWidth
            required
            name="name"
            value={this.props.task.name}
            onChange={this.props.handleTaskChange}
            label="Name"
            InputLabelProps={{
              classes: {
                asterisk: styles.asterisk
              }
            }}
          />
          <p className={styles.error}>{this.props.error}</p>
          {
            this.props.subTasks !== undefined ? (
              <>
                {
                  this.props.subTasks.map((val, idx) => (
                    <form style={{ display: 'flex' }} key={`name-${idx}`}>
                      <>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          variant="outlined"
                          name={`name-${idx}`}
                          id={`name-${idx}`}
                          className={styles.formTextfield}
                          size="small"
                          data-idx={idx}
                          value={this.props.subTasks[idx].name}
                          onChange={this.props.handleSubTasks}
                          label={`Sub Task ${idx + 1}`}
                          inputProps={{ 'data-id': idx, 'data-field-type': 'name' }}
                          required
                        />
                        <TextField
                          type="datetime-local"
                          format="yyyy-MM-ddThh:mm"
                          name="ends_at"
                          noValidate
                          className={styles.formTextfield}
                          label="Due Date"
                          size="small"
                          fullWidth
                          variant="outlined"
                          data-idx={idx}
                          value={this.props.subTasks[idx].ends_at}
                          onChange={this.props.handleSubTasks}
                          inputProps={{ 'data-id': idx, 'data-field-type': 'ends_at' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <IconButton
                          color="secondary"
                          onClick={() => this.props.removeSubTask(idx)}
                          style={{ marginRight: 12 }}
                          name={`removeSubtask-${idx}`}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </>
                    </form>
                  ))
                }
                <div className={styles.addTaskBody}>
                  <Typography><b>Add Sub Task</b></Typography>
                  <IconButton
                    color="primary"
                    variant="addSubTask"
                    onClick={this.props.addSubTask}
                    name="addSubTask"
                  >
                    <AddBoxIcon />
                  </IconButton>
                </div>
              </>
            ) : (null)
          }
          <Autocomplete
            value={this.props.selectedValues}
            id="user_ids"
            name="user_ids"
            multiple
            size="small"
            options={(this.props.caseUsers.length > 0 ? (this.props.caseUsers) : (this.props.users)).sort(function (a, b) {
              if (a.username.toLowerCase() < b.username.toLowerCase()) return -1;
              if (a.username.toLowerCase() > b.username.toLowerCase()) return 1;
              return 0;
            })}
            getOptionLabel={(option) => option.username || ''}
            getOptionSelected={(option, value) => option.username === value.username}
            onChange={this.props.handleUsersAutocomplete}

            renderOption={(option, { selected }) => {
              return (
                <React.Fragment>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.username}
                </React.Fragment>
              )
            }}
            renderInput={(params) => (
              <TextField {...params} name="user_ids" variant="outlined" label="Users" />
            )}
          />
          <div style={{ color: 'red', marginLeft: '25px' }}>{this.props.errors}</div>
          <TextField
            autoComplete="off"
            multiline={true}
            rows={10}
            className={styles.formTextfield}
            variant="outlined"
            required
            fullWidth
            style={{ margin: 8 }}
            name="description"
            value={this.props.task.description}
            onChange={this.props.handleTaskChange}
            label="Task Description"
            InputLabelProps={{
              classes: {
                asterisk: styles.asterisk
              }
            }}

          />
          <div className={styles.taskFormGroup}>
            <TextField
              select
              size="small"
              variant="outlined"
              className={styles.formTextfield}
              fullWidth
              value={this.props.task.priority_id || ''}
              onChange={this.props.handleTaskChange}
              name="priority_id"
              label="Priority Level"
            >
              {
                this.props.priorities.length > 0 ? (
                this.props.priorities.map((val, idx) => (
                  <MenuItem key={`priority-${idx}`} value={val.uid} style={{textTransform: 'capitalize'}}><FlagIcon style={{color: val.color? val.color: 'black'}} />&nbsp;{val.name}</MenuItem>
                ))
                ):null
              }

            </TextField>
            <TextField
              type="date"
              format="yyyy-MM-dd"
              name="ends_at"
              className={styles.formTextfield}
              label="Due Date"
              size="small"
              fullWidth
              variant="outlined"
              margin="normal"
              value={this.props.task.ends_at}
              noValidate
              onChange={this.props.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <IconButton color="dark" position="end" size="small" onClick={this.props.clearDate}>
                    <b>X</b>
                  </IconButton>
                )
              }}
            />
          </div>
        </form>
        <div className={styles.actionButton}>
          <Button variant="outlined" name="submit-task" color="primary" disabled={!this.props.task.name || !this.props.selectedValues.length > 0} onClick={() => this.props.submit()} >Save</Button>
        </div>
      </>
    )
  }
}
export default TaskForm

TaskForm.propTypes = {
  task: PropTypes.object,
  addTask: PropTypes.func,
  editTask: PropTypes.func,
  handleCaseChange: PropTypes.func,
  show: PropTypes.bool,
  handleTaskChange: PropTypes.func,
  isAdd: PropTypes.bool,
  legalCase: PropTypes.object,
  handleChange: PropTypes.func,
  cases: PropTypes.array,
  errors: PropTypes.string,
  handleCheck: PropTypes.func,
  addSubTask: PropTypes.func,
  removeSubTask: PropTypes.func,
  handleSubTasks: PropTypes.func,
  subTasks: PropTypes.array,
  users: PropTypes.array,
  handleAutoComplete: PropTypes.func,
  getDefaultValue: PropTypes.func,
  defaultCase: PropTypes.object,
  submit: PropTypes.func,
  caseUsers: PropTypes.array,
  handleUsersAutocomplete: PropTypes.func,
  selectedValues: PropTypes.array,
  withCaseLink: PropTypes.bool,
  clearDate: PropTypes.func,
  error: PropTypes.func,
  handleSwitch: PropTypes.func,
  priorities: PropTypes.array
}
