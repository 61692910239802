// eslint-disable-next-line

import React from 'react'

import SimpleTable from '../../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import NewAndEditForm from '../../LegalCases/NewAndEditForm'
import WorkOutlineIcon from '@material-ui/icons/WorkOutline'

import axios from 'axios'
import PracticeAreaForm from "../../LegalCases/PracticeAreaForm";
import SearchForm from "../../LegalCases/SearchForm";
import PropTypes from "prop-types";
import AlertMessage from "../../../shared/Notify/AlertMessage";
import ClientForm from '../ClientForm'
class LegalCases extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Case',
      legalCases: [],
      openPopup: false,
      openDeletePopup: false,
      legalCase: {},
      users: [],
      practiceAreas: [],
      defaultBiller: [this.props.client],
      clients: [],
      cfield: {},
      practice: {},
      fields: [],
      openPracticeArea: false,
      expanded: false,
      openPopupSearch: false,
      selectedArea: '',
      initialLegalCase: {
        uid: '',
        reference_no: '',
        title: '',
        date_filed: new Date().toISOString().substring(0, 10),
        practice_area_id: '',
        user_ids: [localStorage.getItem('current_user_id')],
        biller_id: this.props.uid,
        biller_uid: this.props.uid,
        description: '',
        client_ids: [this.props.uid],
        client_uids: [this.props.uid],
        fields: {}
      },
      removeAllSearchField: [],
      searchFormField: {
        reference_no: '',
        title: '',
        date_on_to: '',
        date_on_from: '',
        practice_area_uid: '',
        user_uids: '',
        client_uids: '',
        biller_id: ''
      },
      q: '',
      title: '',
      defaultArea: {},
      load: false,
      tableHead: ['Reference_no', 'Title', 'user_uids', 'client_uids', 'practice_area_uid', 'Date_Filed'],
      withShow: true,
      withEdit: true,
      withSearch: true,
      error_messages: '',
      isOpen: false,
      message: '',
      type: '',
      maxWidth: 'md',
      opposingCounsel: [],
      selectedCounsel: [],
      errorMessages: {},
      openClientform: false,
      // client: {},
      addresses: [{ content: '', category: 0 }],
      address: { content: '', category: 0 },
      emailAddress: { content: '', category: 0 },
      contactNumber: { content: '', category: 0 },
      contactNumbers: [{ content: '', category: 0 }],
      emailAddresses: [{ content: '', category: 0 }],
      contactPerson: { name: '', designation: '', department: '', remarks: '' },
      contactPersons: [{ name: '', designation: '', department: '', remarks: '' }],
      groups: [],
      client: {},
      defaultGroup: {},
      clientcfield: {},
      clientfields: [],
      clientExpanded: false,

      clientErrorMessages: {},
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleAutoComplete = this.handleAutoComplete.bind(this)
    this.handleExpandClick = this.handleExpandClick.bind(this)
    this.handleField = this.handleField.bind(this)
    this.handleClosePracticeArea = this.handleClosePracticeArea.bind(this)
    this.handlePracticeAreaChange = this.handlePracticeAreaChange.bind(this)
    this.addPracticeArea = this.addPracticeArea.bind(this)
    this.openPracticeAreaForm = this.openPracticeAreaForm.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this._handleKeyPress = this._handleKeyPress.bind(this)
    this.handleChanges = this.handleChanges.bind(this)
    this.handleAdvanced = this.handleAdvanced.bind(this)
    this.handleFormReset = this.handleFormReset.bind(this)
    this.handleChangeSearch = this.handleChangeSearch.bind(this)
    this.handleCloseSearch = this.handleCloseSearch.bind(this)
    this.handleSearchAutoComplete = this.handleSearchAutoComplete.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.isFormValid = this.isFormValid.bind(this)
    this.handleOpposingCounsel = this.handleOpposingCounsel.bind(this)
    this.generateRefNo = this.generateRefNo.bind(this)
    this.handleClientPopup = this.handleClientPopup.bind(this)
    this.handleClientChange = this.handleClientChange.bind(this)
    this.handleSubmitClient = this.handleSubmitClient.bind(this)
    this.handleAddressChange = this.handleAddressChange.bind(this)
    this.handleEmailAddressChange = this.handleEmailAddressChange.bind(this)
    this.handleContactNumberChange = this.handleContactNumberChange.bind(this)
    this.addAddress = this.addAddress.bind(this)
    this.addEmailAddress = this.addEmailAddress.bind(this)
    this.addContactNumber = this.addContactNumber.bind(this)
    this.removeAddress = this.removeAddress.bind(this)
    this.removeEmail = this.removeEmail.bind(this)
    this.removeContactNumber = this.removeContactNumber.bind(this)
    this.handleNumberChange = this.handleNumberChange.bind(this)
    this.handleClientAutoComplete = this.handleClientAutoComplete.bind(this)
    this.handleContactPersonChange = this.handleContactPersonChange.bind(this)
    this.addContactPerson = this.addContactPerson.bind(this)
    this.removeContactPerson = this.removeContactPerson.bind(this)
    
    this.handleClientField = this.handleClientField.bind(this)
    this.handleClientSwitch = this.handleClientSwitch.bind(this)
    this.handleClientClose = this.handleClientClose.bind(this)
    this.handleClientExpanded = this.handleClientExpanded.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
  }

  componentDidMount() {
    this.loadLegalCases(process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.props.uid + '/legal_cases')

  }


  loadLegalCases(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ legalCases: resp.data })
      this.setState({ username: resp.data.user_uids })
      this.setState({ removeAllSearchField: resp.data.data })
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/members',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ users: resp.data })
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/practice_areas?per_page=10000',
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then(resp => {
          this.setState({ practiceAreas: resp.data.data })
          axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/custom_fields',
            headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
          }).then(resp => {
            this.setState({ fields: resp.data })
            axios({
              method: 'get',
              url: process.env.REACT_APP_API_DOMAIN + '/v1/clients',
              headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
            }).then(resp => {
              this.setState({ clients: resp.data.data })
              // this.setState({
              //   initialLegalCase: {
              //     client_uids: [this.props.uid]
              //   }
              // })
              this.setState({ load: true })
            }).catch(error => console.log(error.response))
          }).catch(error => console.log(error.response))
        }).catch(error => console.log(error.response))
      }).catch(error => console.log(error.response))
    }).catch(error => console.log(error.response))
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/clients',
      headers: {'X-APP-API-KEY': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({clients: resp.data.data})
      var counsel = resp.data.data.filter(item => item.group.name === 'Opposing Party')
      this.setState({ opposingCounsel: counsel })
      this.setState({load: true})
    }).catch(error => console.log(error.response))
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/groups',
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      if( resp.data.data.filter(item => item.name === 'Opposing Party').length > 0 ){
        this.setState({defaultGroup: resp.data.data.filter(item => item.name === 'Opposing Party')[0]})
      }
      this.setState({ groups: resp.data.data })
    })

    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/custom_fields',
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ clientfields: resp.data })
    })
  }
  handleClientExpanded(){
    this.setState({clientExpanded: !this.state.clientExpanded})
  }


  handleSubmit() {
    const item = {
      uid: this.state.legalCase.uid,
      reference_no: this.state.legalCase.reference_no,
      title: this.state.legalCase.title,
      date_filed: this.state.legalCase.date_filed,
      practice_area_id: this.state.legalCase.practice_area_id,
      user_ids: this.state.legalCase.user_ids,
      biller_id: this.state.legalCase.biller_id,
      description: this.state.legalCase.description,
      client_ids: this.state.legalCase.client_ids,
      fields: {}
    }
    if(this.state.selectedCounsel.length>0){
      var ids = []
      this.state.selectedCounsel.map((item)=>{
        ids.push(item.uid)
      })
      item['counsel_ids'] = ids
    }
    var method = ''
    var url = ''
    if (item.uid === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + item.uid
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: {'X-APP-API-KEY': localStorage.getItem('api_key')}
    }).then(resp => {
      const newData = resp.data
      this.setState({
        legalCases: this.state.legalCases.filter(legalCase => legalCase.uid !== item.uid)
      })
      this.setState({legalCases: [...this.state.legalCases, newData]})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({error_messages: ''})
      this.setState({openPopup: false})
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({error_messages: 'Reference Number ' + error.response.data.errors.reference_no[0]})
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({openPopup: false})
      }
    })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.legalCase.uid
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.props.uid + '/legal_cases/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        legalCases: this.state.legalCases.filter(item => item.uid !== DeleteItemId)
      })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
      this.setState({ openDeletePopup: false })
    }).catch(error => console.log(error.response))
  }

  handleClose() {
    this.setState({ openPopup: false })
    this.setState({ openDeletePopup: false })
    this.setState({ error_messages: '' })
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'New ' + model
      this.setState({legalCase: item})
      this.generateRefNo()
    } else {
      textTitle = 'Edit ' + model
      this.setState({legalCase: item})
    }
    this.setState({openPopup: true})
    this.setState({title: textTitle})
  }

  handleInputChange(e) {
    this.setState({
      legalCase: {
        ...this.state.legalCase,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ legalCase: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  handleAutoComplete(event, values) {
    if (event.target.id.includes("user_name")) {
      this.setState(values)
      const user_ids = []
      values.map((value) => {
        user_ids.push(value.uid)

      }
      )
      this.setState({
        legalCase: {
          ...this.state.legalCase,
          user_ids: user_ids
        }
      })
    } else if (event.target.id.includes("client_name")) {
      const client_ids = []
      values.map((value) => {
        client_ids.push(value.uid)
      }
      )
      this.setState({ defaultBiller: values })
      this.setState({
        legalCase: {
          ...this.state.legalCase,
          client_ids: client_ids
        }
      })
    } else if (event.target.id.includes("biller_name")) {

      this.setState({
        legalCase: {
          ...this.state.legalCase,
          biller_id: values.uid
        }
      })
    } else {
      if (values === null) {
        return this.state.practiceAreas
      } else {
        this.setState({ defaultArea: values })
        this.setState({
          legalCase: {
            ...this.state.legalCase,
            practice_area_id: values.uid
          }
        })
      }
    }
  }



  handlePracticeAreaChange(e) {
    this.setState({
      practice: {
        ...this.state.practice,
        [e.target.name]: e.target.value
      }
    })

  }

  handleExpandClick() {
    this.setState({ expanded: !this.state.expanded })
  }

  handleField(e) {
    const value = e.target.value
    this.setState({
      cfield: {
        ...this.state.cfield,
        [e.target.name]: value
      }
    })
    this.setState({
      legalCase: {
        ...this.state.legalCase,
        fields: JSON.stringify({
          ...this.state.cfield,
          [e.target.name]: value
        })
      }
    })
  }

  handleClosePracticeArea() {
    this.setState({ openPracticeArea: false })
  }

  addPracticeArea() {
    const item = this.state.practice

    axios({
      method: 'post',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/practice_areas/',
      data: (item),
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then(resp => {
        var newData = resp.data
        this.setState({
          practiceAreas: this.state.practiceAreas.filter(practice => practice.uid !== item.uid)
        })
        this.setState({ practiceAreas: [...this.state.practiceAreas, newData] })
        this.setState({
          legalCase: {
            ...this.state.legalCase,
            practice_area_id: resp.data.uid
          }
        })
        this.setState({ defaultArea: resp.data })

        this.setState({ openPracticeArea: false })
      })
      .catch(error => console.log(error.response))
  }

  openPracticeAreaForm() {
    this.setState({ practice: { uid: '', name: '' } })
    this.setState({ openPracticeArea: true })
  }

  handleAdvanced() {
    this.setState({ openPopupSearch: true })
  }


  handleChanges(event) {
    this.setState({ q: event.target.value });
  }

  _handleKeyPress(e) {
    if (e.key === 'Enter') {
      if (this.props.location.search === '') {
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases',
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then(resp => {
          this.setState({ legalCases: resp.data.data })
        })
      } else {
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases' + "?q=" + this.state.q,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then(resp => {
          this.setState({ legalCases: resp.data.data })
        })
      }
    }
    const { history } = this.props
    const params = new URLSearchParams()
    if (this.state.q !== '') {
      params.append("q", this.state.q)
      history.push({ search: params.toString() })
    }
    else {
      history.push({ search: '' })
    }

  }

  handleSearch() {
    var title = ''
    var reference = ''
    var practice_area = ''
    var user = ''
    var client = ''


    if (this.state.searchFormField.title !== '') {
      title = "?title=" + this.state.searchFormField.title
    }
    if (this.state.searchFormField.reference_no !== '') {
      reference = "?reference_no=" + this.state.searchFormField.reference_no
    }
    if (this.state.searchFormField.user_uids !== '') {
      user = "?user_uids=" + JSON.stringify(this.state.searchFormField.user_uids)
    }
    if (this.state.searchFormField.client_uids !== '') {
      client = "?client_uids=" + JSON.stringify(this.state.searchFormField.client_uids)
    }
    if (this.state.searchFormField.practice_area_uid !== '') {
      practice_area = "?practice_area_uid=" + this.state.searchFormField.practice_area_uid
    }

    var url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases' + title + reference + user + client + practice_area

    if (this.props.location.search === '') {
      axios({
        method: 'get',
        url: url,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ legalCases: resp.data.data })
        this.setState({ openPopupSearch: false })
      })
    }
    else {
      axios({
        method: 'get',
        url: url,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ legalCases: resp.data.data })
        this.setState({ openPopupSearch: false })
      })
    }

    const { history } = this.props
    const params = new URLSearchParams()
    if (this.state.searchFormField.title !== '') {
      params.append("title", this.state.searchFormField.title)
    }
    if (this.state.searchFormField.reference_no !== '') {
      params.append("reference_no", this.state.searchFormField.reference_no)
    }
    if (this.state.searchFormField.practice_area_uid !== '') {
      params.append("practice_area_id", this.state.searchFormField.practice_area_uid)
    }
    if (this.state.searchFormField.biller_id !== '') {
      params.append("biller_id", this.state.searchFormField.biller_id)
    }
    if (this.state.searchFormField.user_uids !== '') {
      params.append("user_uids", this.state.searchFormField.user_uids)
    }
    if (this.state.searchFormField.client_uids !== '') {
      params.append("client_uids", JSON.stringify(this.state.searchFormField.client_uids))
    }
    history.push({ search: params.toString() })
  }

  handleCloseSearch() {
    this.setState({ openPopupSearch: false })
  }

  handleFormReset() {
    this.setState({
      searchFormField: {
        ...this.state.searchFormField,
        title: '',
        reference_no: '',
        practice_area_uid: '',
        starts_on_from: '',
        starts_on_to: '',
        user_uids: '',
      }
    })
  }

  handleChangeSearch(e) {
    this.setState({
      searchFormField: {
        ...this.state.searchFormField,
        [e.target.name]: e.target.value
      }
    })
  }

  handleSearchAutoComplete(event, values) {
    if (event.target.id.includes("user_ids")) {
      this.setState(values)
      this.setState({
        searchFormField: {
          ...this.state.searchFormField,
          user_uids: values.uid
        }
      })
    } else if (event.target.id.includes("client_ids")) {
      const client_ids = []
      values.map((value) => {
        client_ids.push(value.uid)
        this.state.clients.map(item => {
          client_ids.map(current => {
            if (current === item.uid) {
              this.setState({ defaultBiller: [...this.state.defaultBiller, item] })
            }
          })
        })
      }
      )
      this.setState({
        searchFormField: {
          ...this.state.searchFormField,
          client_ids: client_ids
        }
      })
    } else if (event.target.id.includes("biller_id")) {
      this.setState({
        searchFormField: {
          ...this.state.searchFormField,
          client_uids: values.uid
        }
      })
    } else {
      if (values === null) {
        return this.state.practiceAreas
      } else {
        this.setState({
          searchFormField: {
            ...this.state.searchFormField,
            practice_area_uid: values.uid
          }
        })
      }
    }
  }
  clearSearch() {
    const { history } = this.props

    history.push({ search: '' })
    this.setState({ legalCases: this.state.removeAllSearchField })
    this.setState({ q: '' })

  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      isOpen: false
    })
  }

  isFormValid() {
    const {user_ids, client_ids} = this.state.legalCase
    if (user_ids.length > 0 && client_ids.length > 0) {
      return user_ids && client_ids
    }
  }

  handleOpposingCounsel(event, value) {
    this.setState({ selectedCounsel: value })
  }

  generateRefNo() {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/generate_ref_no',
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({
        legalCase: {
          ...this.state.legalCase,
          reference_no: resp.data.reference_no
        }
      })
    })
  }

  handleClientClose() {
    this.setState({ openClientform: false })
  }

  handleClientPopup(){
    this.setState({ openClientform: true,
      client:{
        uid: '',
      name: '',
      group_id: '',
      is_company: false,
      is_active: true,
      is_taxable: false,
      fields: {}}
     })
  }

  handleClientSwitch(event) {
    const value = event.target.checked
    this.setState({
      client: {
        ...this.state.client,
        [event.target.name]: value
      }
    })
  }
  removeAddress(idx) {
    this.state.addresses.splice(idx, 1)
    this.setState({ addresses: [...this.state.addresses] })

  }

  handleAddressChange(e) {
    const updatedAddress = [...this.state.addresses]
    updatedAddress[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
    this.setState({ addresses: updatedAddress })
  }

  addAddress() {
    this.setState({ addresses: [...this.state.addresses, { ...this.state.address }] })
  }

  removeEmail(idx) {
    this.state.emailAddresses.splice(idx, 1)
    this.setState({ emailAddresses: [...this.state.emailAddresses] })

  }

  handleEmailAddressChange(e) {
    const updatedEmailAddress = [...this.state.emailAddresses]
    updatedEmailAddress[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
    this.setState({ emailAddresses: updatedEmailAddress })

  }

  addEmailAddress() {
    this.setState({ emailAddresses: [...this.state.emailAddresses, { ...this.state.emailAddress }] })
  }

  removeContactNumber(idx) {
    this.state.contactNumbers.splice(idx, 1)
    this.setState({ contactNumbers: [...this.state.contactNumbers] })

  }

  handleNumberChange(e) {
    const re = /^[0-9\b]+$/
    if (e.target.value === '' || re.test(e.target.value)) {
      const updatedContactNumbers = [...this.state.contactNumbers]
      updatedContactNumbers[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
      this.setState({ contactNumbers: updatedContactNumbers })

    }
  }

  handleContactNumberChange(e) {
    const updatedContactNumbers = [...this.state.contactNumbers]
    updatedContactNumbers[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
    this.setState({ contactNumbers: updatedContactNumbers })

  }

  addContactNumber() {
    this.setState({ contactNumbers: [...this.state.contactNumbers, { ...this.state.contactNumber }] })
  }

  removeContactPerson(idx) {
    this.state.contactPersons.splice(idx, 1)
    this.setState({ contactPersons: [...this.state.contactPersons] })

  }

  handleContactPersonChange(e) {
    const updatedContactPersons = [...this.state.contactPersons]
    updatedContactPersons[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
    this.setState({ contactPersons: updatedContactPersons })
  }

  addContactPerson() {
    this.setState({ contactPersons: [...this.state.contactPersons, { ...this.state.contactPerson }] })
  }

  handleSubmitClient() {
    var item = {}
    item['name'] = this.state.client.name
    item['uid'] = this.state.client.uid
    item['group_id'] = this.state.defaultGroup.uid
    item['is_active'] = this.state.client.is_active
    item['is_company'] = this.state.client.is_company
    item['is_taxable'] = this.state.client.is_company
    item['fields'] = this.state.client.fields
    if (this.state.client.is_company) {
      item['contact_persons_attributes'] = this.state.contactPersons
      item['addresses_attributes'] = this.state.addresses
    }
    else {
      item['addresses_attributes'] = this.state.addresses
      item['email_addresses_attributes'] = this.state.emailAddresses
      item['contact_numbers_attributes'] = this.state.contactNumbers
    }
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/clients',
      data: (item),
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = resp.data
      
      this.setState({ opposingCounsel: [...this.state.opposingCounsel, newData],selectedCounsel: [...this.state.selectedCounsel, newData] })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })

      this.setState({ clientErrorMessages: {} })
      this.setState({ errorMessages: {} })
      this.setState({ openClientform: false })
    }).catch(error => {
      if (error.response.status === 422) {
				var errors = {}
				Object.keys(error.response.data.errors).map((field) => {
					errors[field] = error.response.data.errors[field][0]
					if (field.includes('.')) {
						errors[field.split(".")[1]] = error.response.data.errors[field][0]
					} else {
						errors[field] = error.response.data.errors[field][0]
					}
				})
				this.setState({ clientErrorMessages: errors })
			} else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
  }

  handleClientChange(e) {
    this.setState({
      client: {
        ...this.state.client,
        [e.target.name]: e.target.value
      }
    })
  }

  handleSwitch(event) {
    const value = event.target.checked
    this.setState({
      client: {
        ...this.state.client,
        [event.target.name]: value
      }
    })
  }

  handleClientAutoComplete(event, values) {
    this.setState({ defaultGroup: values })
  }

  handleClientField(e) {
    const value = e.target.value
    this.setState({
      cfield: {
        ...this.state.cfield,
        [e.target.name]: value
      }
    })
    this.setState({
      client: {
        ...this.state.client,
        fields: JSON.stringify({
          ...this.state.cfield,
          [e.target.name]: value
        })
      }
    })
  }


  render() {
    return (
      <>
        {
          this.state.load ? (
            <div>
              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialLegalCase}
                items={this.state.legalCases}
                model={this.state.model}
                history={history}
                clearSearch={this.clearSearch}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                users={this.state.users}
                clients={this.state.clients}
                withSearch={false}
                practiceAreas={this.state.practiceAreas}
                withShow={this.state.withShow}
                withEdit={false}
                q={this.state.q}

                icon={<WorkOutlineIcon fontSize="large" />}
                _handleKeyPress={this._handleKeyPress}
                handleChanges={this.handleChanges}
                handleAdvanced={this.handleAdvanced}
                removeLabel={true}
              />
              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.title}
                items={this.state.legalCases}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <NewAndEditForm
                  item={this.state.legalCase}
                  setCurrentUser={this.state.setCurrentUser}
                  handleAutoComplete={this.handleAutoComplete}
                  error={this.state.error_messages}
                  clients={this.state.clients}
                  isFormValid={this.isFormValid}
                  defaultBiller={this.state.defaultBiller}
                  practiceAreas={this.state.practiceAreas}
                  users={this.state.users}
                  submit={this.handleSubmit}
                  fields={this.state.fields}
                  defaultArea={this.state.defaultArea}
                  openPracticeAreaForm={this.openPracticeAreaForm}
                  cfield={this.state.cfield}
                  expanded={this.state.expanded}
                  handleExpandClick={this.handleExpandClick}
                  handleField={this.handleField}
                  onchange={this.handleInputChange}
                  handleOpposingCounsel={this.handleOpposingCounsel}
                  opposingCounsel={this.state.opposingCounsel}
                  selectedCounsel = {this.state.selectedCounsel}
                  handleClientPopup={this.handleClientPopup}
                />

              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.openClientform}
                handleClose={this.handleClientClose}
                title="Create Opposing Party"
                maxWidth="md"
              >
               <ClientForm
                error={this.state.clientErrorMessages} 
                groups={this.state.groups} 
                client={this.state.client}
                addresses={this.state.addresses} 
                emailAddresses={this.state.emailAddresses} 
                contactNumbers={this.state.contactNumbers} 
                contactPersons={this.state.contactPersons}
                defaultGroup={this.state.defaultGroup} 
                cfield={this.state.clientcfield}
                fields={this.state.clientfields} 
                
                expanded={this.state.clientExpanded} 
    
                submit={this.handleSubmitClient} 
                  
                handleChange={this.handleClientChange} 
                handleSwitch={this.handleSwitch}
                
                handleAddressChange={this.handleAddressChange} 
                removeAddress={this.removeAddress}
                addAddress={this.addAddress}
                
                handleEmailAddressChange={this.handleEmailAddressChange} 
                removeEmail={this.removeEmail}
                addEmailAddress={this.addEmailAddress}
                
                handleContactNumberChange={this.handleContactNumberChange} 
                removeContactNumber={this.removeContactNumber}
                addContactNumber={this.addContactNumber}
                handleNumberChange={this.handleNumberChange}
                
                handleContactPersonChange={this.handleContactPersonChange} 
                removeContactPerson={this.removeContactPerson} 
                addContactPerson={this.addContactPerson}
                // openGroupForm={this.openGroupForm}
                handleAutoComplete={this.handleClientAutoComplete} 
                
                handleExpandClick={this.handleClientExpanded}
                handleField={this.handleClientField} 
               />
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.openPopupSearch}
                handleClose={this.handleCloseSearch}
                title="Advanced Search"
              >
                <SearchForm
                  handleSearch={this.handleSearch}
                  handleFormReset={this.handleFormReset}
                  clients={this.state.clients}
                  users={this.state.users}
                  practiceAreas={this.state.practiceAreas}
                  handleAutoComplete={this.handleSearchAutoComplete}
                  handleChangeSearch={this.handleChangeSearch}
                  item={this.state.searchFormField}
                />

              </SimplePopUp>


              <SimplePopUp
                openPopup={this.state.openPracticeArea}
                title="Add Practice Area"
                handleClose={this.handleClosePracticeArea}
                maxWidth='xs'
              >
                <PracticeAreaForm practice={this.state.practice} handleChange={this.handlePracticeAreaChange}
                  addPracticeArea={this.addPracticeArea} />
              </SimplePopUp>


              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.legalCase}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
           null
          )}
      </>
    )
  }
}
export default LegalCases
LegalCases.propTypes = {
  history: PropTypes.string,
  location: PropTypes.string,
  uid: PropTypes.string,
  client: PropTypes.object
}
