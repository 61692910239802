import React from 'react'

import PropTypes from 'prop-types'

import { TextField, IconButton, TableCell, TableRow, MenuItem } from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import styles from './Onboarding.module.css'

class CustomFieldForms extends React.Component {

    render() {

        const deleteButton = `delete_item${this.props.idx}`
        return (
            <>
                <TableRow key={`group-${this.props.idx}`}>
                    <TableCell className={styles.cell}>
                        <TextField
                            required
                            autoComplete="off"
                            className={styles.textfield}
                            variant="outlined"
                            size="small"
                            name="name"
                            fullWidth={true}
                            onChange={(event) => this.props.handleChange(event, this.props.idx)}
                            value={this.props.items[this.props.idx].name}
                        />
                    </TableCell>
                 <TableCell className={styles.cell}>
                        <TextField
                            select
                            required
                            autoComplete="off"
                            className={styles.textfield}
                            variant="outlined"
                            size="small"
                            name="type"
                            fullWidth={true}
                            onChange={(event) => this.props.handleChange(event, this.props.idx)}
                            value={this.props.items[this.props.idx].type}
                        >
                            <MenuItem value=""></MenuItem>
                            <MenuItem value="text">Text</MenuItem>
                            <MenuItem value="number">Number</MenuItem>
                            <MenuItem value="date">Date</MenuItem>
                        </TextField>
                    </TableCell>
                    <TableCell className={styles.cell} align="right">
                        <IconButton
                            color="secondary"
                            name={deleteButton}
                            onClick={() => this.props.removeItem(this.props.idx)}>
                            <DeleteForeverIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>

            </>
        )
    }
}

export default CustomFieldForms

CustomFieldForms.propTypes = {
    idx: PropTypes.number,
    items: PropTypes.array,
    handleChange: PropTypes.func,
    removeItem: PropTypes.func,
    

}
