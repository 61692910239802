import React from 'react'
import styles from './ExpenseType.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  MenuItem,
  Grid
} from '@material-ui/core'

class ExpenseTypeForm extends React.Component {
  render () {
    return (
      <div>
        <form>
          <Grid container spacing={1}>
          <Grid item xs={12}  sm={6}>
          <TextField
            autoComplete="off"
            id="standard-basic"
            className={styles.textFields}
            variant="outlined"
            size="small"
            name="name"
            fullWidth
            label="Name"
            onChange={this.props.onchange}
            value={this.props.item.name}
          />
            <p className={styles.error}>{this.props.error}</p>
          </Grid>
          <Grid item xs={12}  sm={6}>
          <TextField
            select
            autoComplete="off"
            className={styles.textFields}
            variant="outlined"
            size="small"
            id="transaction_type_parent_uid"
            name="parent_uid"
            fullWidth
            label="Group"
            onChange={this.props.onchange}
            value={this.props.item.parent_uid}
            disabled={this.props.item.parent_uid === null}
          >
            {
              this.props.items.length>0?(
                this.props.items.map((expense)=>(
                  expense.parent_uid===null && expense.is_deduction === this.props.item.is_deduction &&
                  <MenuItem key={expense.uid} name={expense.name} value={expense.uid}>{expense.name}</MenuItem>

                ))
              ):(null)
            }
          </TextField>
          </Grid>
          </Grid>
        </form>
        {this.props.children}
        <div className={styles.actionButton}>
          <Button variant="outlined" id="submit" name="submit" disabled={!this.props.item.name} color="primary" onClick={() => this.props.submit()} >Save</Button>
        </div>
      </div>
    )
  }
}
export default ExpenseTypeForm

ExpenseTypeForm.propTypes = {
  classes: PropTypes.object,
  onchange: PropTypes.func,
  item: PropTypes.object,
  children: PropTypes.object,
  error: PropTypes.object,
  submit: PropTypes.func,
  items: PropTypes.array
}
