import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography, IconButton, ButtonGroup, Table, TableBody, TableRow, TableContainer
} from '@material-ui/core'
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import CallIcon from '@material-ui/icons/Call'
import styles from '../Client.module.css'

class ContactNumbers extends React.Component {
  render() {
    return (
      <div className={styles.Parent}>
        {this.props.isCompany ? (
          <div className={styles.contactBody}>
            <TableContainer style={{ maxHeight: '575px' }}>
              <Table>
                <TableBody>
                  <TableRow>
                    {this.props.contactNumbers.length > 0 ? (
                      this.props.contactNumbers
                        .map((contactNumber) => (
                          <li key={contactNumber.uid} className={styles.cell} >
                            {contactNumber.content}
                          </li>
                        ))) : null}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )
          : <div className="contact-body " name="contact-numbers">
            <Typography className="flex-justify">
              <div className={styles.parentTitle}><CallIcon fontSize="large" />Contact Numbers :</div>
              {
                this.props.noAction ? null :

                  <IconButton className="padding-none" color="inherit" aria-label="menu"
                    onClick={() => this.props.handleClick('Add Contact Number', 'contact number', this.props.initialContactNumber)}
                    name="add-contactNumber"
                  >
                    <AddCircleOutlinedIcon />
                  </IconButton>
              }
            </Typography>
            {this.props.contactNumbers.length > 0 ? (
              this.props.contactNumbers
                .map((contactNumber) => (
                  <div key={contactNumber.uid} className={styles.contactListBody}>
                    <div className={styles.contactLists}>
                      <div className={styles.contactListLabel}>
                        {contactNumber.content}

                      </div>
                      &nbsp; - &nbsp;
                      <div className={styles.contactListLabel}>
                        <i>{contactNumber.category_name}</i>
                      </div>
                    </div>
                    {
                      this.props.noAction ? null :

                        <div className={styles.buttonNumberHover}>
                          <ButtonGroup aria-label="outlined primary button group">
                            <IconButton
                              variant="contained"
                              color="primary"
                              onClick={() => this.props.handleClick('Edit Contact Number', 'Contact Number', contactNumber)}
                              name={`contactNumber-edit`}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              variant="contained"
                              color="secondary"
                              onClick={() => this.props.handleOpenDelete('contact number', contactNumber)}
                              name={`contactNumber-delete`}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </ButtonGroup>
                        </div>
                    }
                  </div>
                ))
            ) : (
              <div>
                <Typography colSpan={3}>No Saved Contact Numbers</Typography>
              </div>
            )}
          </div>}
      </div>
    )
  }
}
export default ContactNumbers
ContactNumbers.propTypes = {
  contactNumbers: PropTypes.array,
  initialContactNumber: PropTypes.object,
  handleClick: PropTypes.func,
  isCompany: PropTypes.bool,
  handleOpenDelete: PropTypes.func,
  noAction: PropTypes.bool
}