import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, TextField } from "@material-ui/core";
import axios from "axios";
import styles from "./Workflow.module.css";

class TriggerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      triggerNameValue: null,
    };
    this.handleTriggerNameChange = this.handleTriggerNameChange.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
  }

  handleTriggerNameChange(event) {
    this.setState({
      triggerNameValue: event.target.value,
    });
  }

  handleSubmitForm(event) {
    event.preventDefault();
    const { createOrUpdateTrigger, selectedTrigger, workflowId, setTriggers } =
      this.props;
    const { triggerNameValue } = this.state;
    const forTriggerCreation =
      createOrUpdateTrigger === "create" ? true : false;

    let method = "";
    let url = "";

    if (forTriggerCreation) {
      method = "post";
      url = `${process.env.REACT_APP_API_DOMAIN}/v1/workflows/`;
    } else {
      method = "put";
      url = `${process.env.REACT_APP_API_DOMAIN}/v1/workflows/${selectedTrigger.uid}`;
    }

    axios({
      method: method,
      url: url,
      data: forTriggerCreation
        ? {
            uid: "",
            parent_id: workflowId,
            name: triggerNameValue,
          }
        : {
            ...selectedTrigger,
            name: triggerNameValue,
          },
      headers: { "X-APP-API-KEY": localStorage.getItem("api_key") },
    })
      .then((res) => setTriggers(selectedTrigger, res.data))
      .catch((error) => console.log(error));
  }

  render() {
    const { createOrUpdateTrigger, selectedTrigger } = this.props;
    const forTriggerCreation =
      createOrUpdateTrigger === "create" ? true : false;
    const { triggerNameValue } = this.state;

    return (
      <form onSubmit={this.handleSubmitForm} className={styles.triggerForm}>
        <TextField
          variant="outlined"
          required
          value={
            forTriggerCreation
              ? triggerNameValue
              : triggerNameValue === null
              ? selectedTrigger.name
              : triggerNameValue
          }
          onChange={this.handleTriggerNameChange}
          label="Name"
        />
        <Button
          type="submit"
          disabled={!triggerNameValue}
          variant="outlined"
          color="primary"
          className={styles.triggerFormSubmitButton}
        >
          {forTriggerCreation ? "Create" : "Save"}
        </Button>
      </form>
    );
  }
}

export default TriggerForm;

TriggerForm.propTypes = {
  createOrUpdateTrigger: PropTypes.string,
  selectedTrigger: PropTypes.object,
  workflowId: PropTypes.string,
  setTriggers: PropTypes.func,
};
