import React from 'react'
import axios from 'axios'
import styles from './Member.module.css'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { Box, Button, Paper } from '@material-ui/core'

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      profile: {}
    }
  }

  componentDidMount() {
    this.loadGroups(process.env.REACT_APP_API_DOMAIN + '/v1/users/me')
  }

  loadGroups(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        this.setState({ profile: resp.data })
        this.setState({ load: true })
      })
      .catch((error) => console.log(error.response))
  }

  render() {
    const { username, first_name, middle_name, last_name, email } = this.state.profile
    const fullname = [
      {
        name: first_name,
        label: 'First Name'
      },
      {
        name: middle_name,
        label: 'Middle Name'
      },
      {
        name: last_name,
        label: 'Last Name'
      }]

    return (
      <Box>
        <div className={styles.coverphotoContainer}>
          <div className={styles.profilePictureContainer}>
            <AccountCircleIcon fontSize="large" />
          </div>
          <span>{username}</span>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap'
        }}>
          <Button variant='outlined' color='primary' style={{
            alignSelf: 'flex-end',
            marginBottom: '10px',
            paddingBlock: 0,
            fontSize: '.7rem'
          }}>Edit</Button>
          <Paper className={styles.paperContainer}>
            <ul className={styles.ul}>
              {
                fullname.map((item, idx) => {
                  return (
                    <li key={idx} className={styles.listItem}>
                      <span className={styles.listItemText}>{item.label}</span>
                      <span className={styles.listItemText} style={{ textAlign: 'center' }}>:</span>
                      <span className={styles.listItemText} style={{ textAlign: 'right', fontWeight: 900 }}>{item.name}</span>
                    </li>
                  )
                })
              }
            </ul>
            <ul className={styles.ul}>
              <li className={styles.listItem}>
                <span className={styles.listItemText}>Email</span>
                <span className={styles.listItemText} style={{ textAlign: 'center' }}>:</span>
                <span className={styles.listItemText} style={{ textAlign: 'right', fontWeight: 900 }}>{email}</span>
              </li>
            </ul>
          </Paper>
        </div>
      </Box>
    )
  }
}

export default Profile
