import React from 'react'

import PropTypes from 'prop-types'

import styles from './ExpenseType.module.css'

import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import ButtonGroup from '@material-ui/core/ButtonGroup'

import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";

class TransactionType extends React.Component {


  render() {

    return (
      <>
        <TableContainer style={{maxHeight: '575px', position: 'relative'}}>
          <Table name="transaction_type_subs-table">
            <TableHead className={styles.tableHeadBody}>
              <TableRow>
                <TableCell>
                  <b>Name</b>
                </TableCell>
              </TableRow>
            </TableHead>
            {
              this.props.items.length > 0 ? (
                this.props.items.map((item) => (
                  item.parent_uid !== null &&
                  <TableBody className={styles.tableBody} key={item.uid}>
                    <TableRow>
                      <TableCell className={styles.cellBody}>
                        <div>{item.name}</div>
                      </TableCell>

                      <TableCell className={styles.actionTransButtonHoverable}>
                        <ButtonGroup edge="end">
                          <IconButton
                            color="primary"
                            size="small"
                            name={`transaction_type-edit-child`}
                            onClick={() => this.props.handleClick(item, true, this.props.model)}
                          >
                            <EditIcon/>
                          </IconButton>

                          <IconButton
                            color="secondary"
                            size="small"
                            name={`transaction_type-delete-child`}
                            onClick={() => this.props.onOpenDeletePopup(item)} className="button muted-button"
                          >
                            <DeleteIcon/>
                          </IconButton>
                        </ButtonGroup></TableCell>
                    </TableRow>

                  </TableBody>
                ))
              ) : (null)
            }
          </Table>
        </TableContainer>
      </>
    )
  }
}

export default TransactionType

TransactionType.propTypes = {
  items: PropTypes.array,
  onOpenDeletePopup: PropTypes.func,
  model: PropTypes.model,
  handleClick: PropTypes.func


}
