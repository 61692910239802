import React from 'react'
import styles from '../LegalCase.module.css'

import PropTypes from 'prop-types'

import {
    TextField,
    Button,
    MenuItem,
    Grid, Checkbox
} from '@material-ui/core'

class ExpenseTypeForm extends React.Component {
    render() {
        return (
            <div>
                <form>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                select
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                id="is_deduction"
                                name="is_deduction"
                                fullWidth
                                label="Category"
                                onChange={this.props.onchange}
                                value={this.props.item.is_deduction}

                            >
                                <MenuItem value={true}>Expense</MenuItem>
                                <MenuItem value={false}>Rebate</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                this.props.newGroup ? (
                                    <TextField
                                        autoComplete="off"
                                        id="standard-basic"
                                        className={styles.textFields}
                                        variant="outlined"
                                        size="small"
                                        name="parent_name"
                                        fullWidth
                                        label="Group Name"
                                        onChange={this.props.onchange}
                                        value={this.props.item.parent_name}
                                    />
                                ) : (
                                    <TextField
                                        select
                                        autoComplete="off"
                                        className={styles.textFields}
                                        variant="outlined"
                                        size="small"
                                        id="transaction_type_parent_uid"
                                        name="parent_uid"
                                        fullWidth
                                        label="Group Name"
                                        onChange={this.props.onchange}
                                        value={this.props.item.parent_uid}
                                        disabled={this.props.item.parent_uid === null}
                                    >
                                        {
                                            this.props.items.length > 0 ? (
                                                this.props.items.map((expense) => (
                                                    expense.parent_uid === null && expense.is_deduction === this.props.item.is_deduction &&
                                                    <MenuItem key={expense.uid} name={expense.name} value={expense.uid}>{expense.name}</MenuItem>

                                                ))
                                            ) : (null)
                                        }
                                    </TextField>
                                )
                            }

                            <div>
                                <Checkbox
                                    onChange={this.props.handleCheck}
                                    checked={this.props.newGroup}
                                /> New Transaction Group
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="off"
                                id="standard-basic"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="name"
                                fullWidth
                                label="Name"
                                onChange={this.props.onchange}
                                value={this.props.item.name}
                            />
                        </Grid>
                    </Grid>
                </form>
                {this.props.children}
                <div className={styles.actionButton}>
                    <Button variant="outlined" id="submit" name="submit" disabled={!this.props.item.name || (this.props.newGroup? !this.props.item.parent_name : !this.props.item.transaction_type_parent_uid)} color="primary" onClick={() => this.props.submit()} >Save</Button>
                </div>
            </div>
        )
    }
}
export default ExpenseTypeForm

ExpenseTypeForm.propTypes = {
    classes: PropTypes.object,
    onchange: PropTypes.func,
    item: PropTypes.object,
    children: PropTypes.object,
    error: PropTypes.object,
    submit: PropTypes.func,
    items: PropTypes.array,
    newGroup: PropTypes.bool,
    handleCheck: PropTypes.func
}
