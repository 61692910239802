// eslint-disable-next-line

import React from 'react'

import SimpleTable from '../../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import EventForm from '../../Event/EventForm'
import moment from 'moment'
import axios from 'axios'
import PropTypes from "prop-types";

class Events extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Event',
      events: [],
      users: [],
      defaultUser: [],
      legalCase: this.props.legalCaseId,
      currentLegalUser: this.props.legalcaseUserId,
      openPopup: false,
      openDeletePopup: false,
      event: {},
      initialEvent: {
        uid: '', name: '', user_uids: [localStorage.getItem('current_user_id')], starts_at: moment().format('YYYY-MM-DDTHH:mm'),
        ends_at: moment().format('YYYY-MM-DDTHH:mm')
      },
      title: '',
      load: false,
      tableHead: ['Name', 'Description', 'user_uids', 'starts_at', 'ends_at'],
      withEdit: true,
      smWidth: 'sm',
      maxWidth: 'md',
      eventCaseUsers: [],
      checkedUsers: [localStorage.getItem('current_user_id')],
      usersAutocomplete: []
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleAutoComplete = this.handleAutoComplete.bind(this)
    this.getDefaultValue = this.getDefaultValue.bind(this)
  }

  componentDidMount() {

    this.loadEvents(process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.legalCaseId + '/events')
  }

  loadEvents(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ events: resp.data })
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/members',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ users: resp.data })
        resp.data.map((item) => {
          this.state.currentLegalUser.map((current) => {
            if (current === item.uid) {
              this.setState({ defaultUser: [...this.state.defaultUser, item] })
            }
          })
        })
        var newData = []
        this.props.legalCase.user_uids.map((userId) => {
          resp.data.map((user) => {
            if (userId === user.uid) {
              newData.push(user)
            }
          })
        })
        this.setState({ eventCaseUsers: newData })
        this.setState({ legalCase: this.props.legalCaseId })
        this.setState({ load: true })
      }).catch(error => console.log(error.response))
    }).catch(error => console.log(error.response))
  }

  handleSubmit() {
    const item = this.state.event
    const id = this.state.legalCase
    const newItem = {
      uid: item.uid,
      user_ids: item.user_uids,
      name: item.name,
      description: item.description,
      starts_at: item.starts_at,
      ends_at: item.ends_at
    }
    var method = ''
    var url = ''
    if (item.uid === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + id + '/events/'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + id + '/events/' + item.uid
    }
    axios({
      method: method,
      url: url,
      data: (newItem),
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = resp.data
      this.setState({
        events: this.state.events.filter(event => event.uid !== item.uid)
      })
      this.setState({ events: [...this.state.events, newData] })
      this.setState({ openPopup: false })
    }).catch(error => console.log(error.response))
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.event.uid
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/events/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        events: this.state.events.filter(item => item.uid !== DeleteItemId)
      })
      this.setState({ openDeletePopup: false })
    }).catch(error => console.log(error.response))
  }

  handleClose() {
    this.setState({ openPopup: false })
    this.setState({ openDeletePopup: false })
  }

  handleClick() {
    this.setState({ openPopup: true })
    this.setState({ openDeletePopup: false })
  }

  handleCreateorUpdateItem(item) {
    var textTitle = ''
    var eventUsers = []
    var newItem = {
      uid: item.uid,
      can_delete: item.can_delete,
      can_edit: item.can_edit,
      legal_case: item.legal_case,
      user_uids: item.user_uids,
      name: item.name,
      description: item.description,
      from_workflow: item.from_workflow,
      created_at: item.created_at,
      updated_at: item.updated_at
    }
    if (this.state.users.length > 0) {
      this.state.users.map((user) => {
        if (item.user_uids !== null) {
          item.user_uids.map((userId) => {
            if (user.uid === userId) {
              eventUsers.push(user)
            }
          })
        }

      })
    }
    this.setState({ eventUsersAutocomplete: eventUsers})
    if (item.uid === "") {
      textTitle = " Create Event "
      newItem["starts_at"] = moment().format('YYYY-MM-DDTHH:mm')
      newItem["ends_at"] = moment().format('YYYY-MM-DDTHH:mm')

    } else {
      newItem["ends_at"] = item.ends_at ? (moment(item.ends_at).format('YYYY-MM-DDTHH:mm')) : null
      newItem["starts_at"] = item.starts_at ? (new Date(item.starts_at).toLocaleString("sv-Se", { timeZone: "Asia/Manila" }).replace(' ', 'T')) : null
      textTitle = " Edit Event "
    }
    this.setState({ checkedUsers: item.user_uids })
    this.setState({ openPopup: true })
    this.setState({ event: newItem })
    this.setState({ title: textTitle })
  }

  handleInputChange(e) {
    this.setState({
      event: {
        ...this.state.event,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ event: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  handleAutoComplete(event, values) {

    if (event.target.id.includes("user_ids")) {

      this.setState({eventUsersAutocomplete: values})
      const user_ids = []

      values.map((value) => {
        user_ids.push(value.uid)
      })
      this.setState({
        event: {
          ...this.state.event,
          user_ids: user_ids
        }
      })
    }
  }
  getDefaultValue() {
    var newArray = []
    if (this.state.users.length > 0) {
      if (this.state.checkedUsers !== null) {
        this.state.users.map((user) => {
          this.state.checkedUsers.map((current) => {
            if (current === user.uid) {
              newArray.push(user)
            }
          })
        })
      }
    }
    return newArray
  }

  render() {
    return (
      <>
        {
          this.state.load ? (
            <div>
              <SimpleTable
                removeLabel={true}
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialEvent}
                items={this.state.events}
                model={this.state.model}
                withEdit={this.state.withEdit}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                users={this.state.users}
              />
              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.title}
                items={this.state.events}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}

              >
                <EventForm
                  event={this.state.event}
                  users={this.state.users}
                  getDefaultValue={this.getDefaultValue}
                  submit={this.handleSubmit}
                  handleEventChange={this.handleInputChange}
                  eventCaseUsers={this.state.eventCaseUsers}
                  handleEventUsersAutocomplete={this.handleAutoComplete}
                  withCase={false}
                  selectedValues={this.state.eventUsersAutocomplete}
                />

              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.event}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}

              />
            </div>
          ) : (
           null
          )}

      </>
    )
  }
}

export default Events

Events.propTypes = {
  legalCase: PropTypes.object,
  legalCaseId: PropTypes.string,
  legalcaseUserId: PropTypes.array
}
