import React from 'react'

import PropTypes from 'prop-types'
import styles from './Tasks.module.css'

import {
  TextField,
  Button, InputLabel, Grid
} from '@material-ui/core'
import FormControl from "@material-ui/core/FormControl"
import Select from '@material-ui/core/Select'

class SearchForm extends React.Component {
  render() {
    return (
      <div>
        <form>
        <Grid  container spacing={1}>
          <Grid item xs={12} sm={12}>
            <TextField
              autoComplete="off"
              id="standard-basic"
              className={styles.formTextfield}
              variant="outlined"
              size="small"
              name="name"
              fullWidth
              label="Name"
              value={this.props.item.name}
              onChange={this.props.handleChangeSearch}

            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth className={styles.formTextfield} size="small" variant="outlined" >
              <InputLabel htmlFor="outlined-age-native-simple">State</InputLabel>
              <Select
                native
                label="State"
                fullWidth="true"
                size="small"
                value={this.props.item.state || ''}
                onChange={this.props.handleChangeSearch}
                inputProps={{
                  name: 'state',
                  id: 'State',
                }}
              >
                <option value=""></option>
                <option value="ongoing">Ongoing</option>
                <option value="done">Done</option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              autoComplete="off"
              id="standard-basic"
              type="date"
              className={styles.formTextfield}
              variant="outlined"
              size="small"
              name="due_on_from"
              fullWidth
              label="Date Start from"
              value={this.props.item.due_on_from}
              onChange={this.props.handleChangeSearch}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              autoComplete="off"
              id="standard-basic"
              type="date"
              className={styles.formTextfield}
              variant="outlined"
              size="small"
              name="due_on_to"
              fullWidth
              label="Date Start to"
              value={this.props.item.due_on_to}
              onChange={this.props.handleChangeSearch}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid> 
        </form>
        <div className={styles.actionSearchButton}>
          <Button
            className={styles.search}
            variant="outlined"
            color="primary"
            onClick={(e) => this.props.handleSearch(e)}
          >
            Search
          </Button>
        </div>

      </div>
    )
  }
}

export default SearchForm

SearchForm.propTypes = {
  handleSearch: PropTypes.func,
  handleFormReset: PropTypes.func,
  handleChangeSearch: PropTypes.func,
  handleAutoComplete: PropTypes.func,
  item: PropTypes.object,
  users: PropTypes.array,
  clients: PropTypes.array,
  practiceAreas: PropTypes.object,
}
