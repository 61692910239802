import React from 'react'
import axios from 'axios'
import styles from './Workflow.module.css'
import PropTypes from 'prop-types'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import TaskForm from './TaskForm'
import EventForm from './EventForm'
import TaskTable from './TaskTable'
import EventTable from './EventTable'
import WorkflowForm from './WorkflowForm'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import TriggerTable from './TriggerTable'
import { Box } from '@material-ui/core'
import TriggerForm from './TriggerForm'

class Workflow extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      workflow: {},
      load: false,
      model: 'Workflow',
      openPopUp: false,
      openDeletePopup: false,
      initialWorkflow: { uid: '', name: '' },
      title: '',
      tableHead: ['Name', 'Actions'],
      trigger: { uid: '', name: '' },
      triggers: [],
      withShow: true,
      count: 0,
      taskPopup: false,
      initialTask:{uid: '',name:'',description:'',interval:0,interval_event:'before',interval_type:'weekdays',subs:[""],tag_list:'',workflow_uid:''},
      task: {uid: ''},
      tasks: [],
      subTask: '',
      subs: [""],
      labelUid: '',
      events:[],
      eventPopUp: false,
      initialEvent:{uid: '',name:'',description:'',interval:0,interval_event:'before',interval_type:'weekdays',duration:'',duration_type:'',duration_start_time:'',workflow_uid:''},
      event: {},
      openTaskDelete: false,
      priorities: [],
      openTriggerFormPopup: false,
      openTriggerFormDeletePopup: false,
      selectedTrigger: null,
      createOrUpdateTrigger: '',
      createOrUpdateWorkflow: 'create'
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.addTrigger = this.addTrigger.bind(this)
    this.handleTriggers = this.handleTriggers.bind(this)
    this.removeTrigger = this.removeTrigger.bind(this)
    this.handleSubmitTriggers = this.handleSubmitTriggers.bind(this)
    this.handleCreateorUpdateTask = this.handleCreateorUpdateTask.bind(this)
    this.addSubs = this.addSubs.bind(this)
    this.handleSubs = this.handleSubs.bind(this)
    this.removeSubs=this.removeSubs.bind(this)
    this.submitTask= this.submitTask.bind(this)
    this.handleDeleteTaskItem=this.handleDeleteTaskItem.bind(this)
    this.handleOpenDeleteTask=this.handleOpenDeleteTask.bind(this)
    this.handleTaskChange=this.handleTaskChange.bind(this)
    this.handleChangeName=this.handleChangeName.bind(this)
    this.handleEventChange=this.handleEventChange.bind(this)
    this.handleCreateorUpdateEvent=this.handleCreateorUpdateEvent.bind(this)
    this.submitEvent=this.submitEvent.bind(this)
    this.handleDeleteEvent=this.handleDeleteEvent.bind(this)
    this.handleOpenDeleteEvent=this.handleOpenDeleteEvent.bind(this)
    this.handleCreateorUpdateTrigger = this.handleCreateorUpdateTrigger.bind(this)
    this.handleOpenDeleteTrigger = this.handleOpenDeleteTrigger.bind(this)
    this.handleRemoveTrigger = this.handleRemoveTrigger.bind(this)
    this.setTriggers = this.setTriggers.bind(this)
  }

  componentDidMount () {
    if (typeof this.props.location.state.item!=="undefined")
   this.setState({workflow: this.props.location.state.item})
   this.setState({load:true})
   this.loadWorkflow(process.env.REACT_APP_API_DOMAIN + '/v1/workflows/' + this.props.location.state.item.uid)
  }

  loadWorkflow (uid) {
    axios({
      method: 'get',
      url: uid + '/subs',
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ triggers: resp.data })
        resp.data.map((workflow)=>{
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/workflows/'+workflow.uid +'/task_templates',
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then(resp => {
          if(resp.data.length>0){
            resp.data.map((item)=>{
              this.setState({ tasks: [...this.state.tasks, {...item}] })
            })
          }
          })

          axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/workflows/'+workflow.uid +'/event_templates',
            headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
          }).then(resp => {
            if(resp.data.length>0){
              resp.data.map((item)=>{
                this.setState({ events: [...this.state.events, {...item}] })
              })
            }
            })
      })
      
      this.setState({ load: true })
    })
      .catch(error => console.log(error.response))
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/priorities/',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ priorities: resp.data })
      })
  }

  handleChangeName(e){
    this.setState({ workflow:
     {
       ...this.state.workflow,
      name: e.target.value}
    })
  }

  handleSubmit () {
    const item = this.state.workflow
    var method = ''
    var url = ''
    if (item.uid === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/workflows/'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/workflows/' + item.uid
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      
      this.state.triggers.map((trigger) => {
        trigger["parent_id"] = resp.data.uid
        
        return null
      }
      )
      if(this.state.createOrUpdateWorkflow === 'create') this.handleSubmitTriggers()
      this.handleClose()
      
    }).catch(error => console.log(error.response))
  }

  handleSubmitTriggers () {
    if (this.state.triggers.length > 0) {
      this.state.triggers.map(async (trigger) => {
        var method = ''
        var url = ''
        if (trigger.uid === '') {
          method = 'post'
          url = process.env.REACT_APP_API_DOMAIN + '/v1/workflows/'
        } else {
          method = 'put'
          url = process.env.REACT_APP_API_DOMAIN + '/v1/workflows/' + trigger.uid
        }
        try {
          const resp = await axios({
            method: method,
            url: url,
            data: (trigger),
            headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
          })
          
          const newData = resp.data
          this.setState({
            triggers: this.state.triggers.filter(subTask => subTask.uid !== trigger.uid)
          })
          this.setState({ triggers: [...this.state.triggers, newData] })
        } catch (error) {
          return console.log(error.response)
        }
      })
    }
  }

  handleDeleteItem () {
    const DeleteItemId = this.state.workflow.uid
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/workflows/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        workflows: this.state.workflows.filter(item => item.uid !== DeleteItemId)
      })
      this.setState({ openDeletePopup: false })
      this.setState({ triggers: [] })
    }).catch(error => console.log(error.response))
  }

  handleDeleteTaskItem () {
    const DeleteItemId = this.state.task.uid
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/workflows/' + this.state.task.workflow_uid + '/task_templates/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        tasks: this.state.tasks.filter(item => item.uid !== DeleteItemId)
      })
      this.setState({ openDeletePopup: false })
      this.setState({openTaskDelete: false})
    }).catch(error => console.log(error.response))
  }

  handleDeleteEvent () {
    const DeleteItemId = this.state.event.uid
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/workflows/' + this.state.event.workflow_uid + '/event_templates/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        events: this.state.events.filter(item => item.uid !== DeleteItemId)
      })
      this.setState({ openDeletePopup: false })
    }).catch(error => console.log(error.response))
  }


  handleClose () {
    this.setState({ openPopUp: false })
    this.setState({ openDeletePopup: false })
    this.setState({ taskPopup: false })
    this.setState({eventPopUp: false})
    this.setState({openTaskDelete: false})
    this.setState({ openTriggerFormPopup: false })
    this.setState({ openTriggerFormDeletePopup: false })
  }

  handleCreateorUpdateItem (item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    
    this.setState({ openPopUp: true })
    this.setState({ workflow: item })
    this.setState({ title: textTitle })
    this.setState({ createOrUpdateWorkflow: isAdd ? 'create' : 'update' })
  }

  handleChange (e) {
    this.setState({
      workflow: {
        ...this.state.workflow,
        [e.target.name]: e.target.value
      }
    })
  }


  handleTaskChange (e) {
    this.setState({
      task: {
        ...this.state.task,
        [e.target.name]: e.target.value
      }
    })
  }

  handleEventChange (e) {
    this.setState({
      event: {
        ...this.state.event,
        [e.target.name]: e.target.value
      }
    })
  }

  submitEvent(){
    const item = this.state.event
    
    var method = ''

    var url = ''
    if(this.state.labelUid===""){
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/workflows/'+item.workflow_uid+'/event_templates'
      axios({
        method: method,
        url: url,
        data: (item),
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then(resp => {
        
        const newData = resp.data
        this.setState({
          events: this.state.events.filter(event => event.uid !== item.uid)
        })
        this.setState({ events: [...this.state.events, newData] })
        this.setState({ eventPopUp: false })
      }).catch(error => console.log(error.response))
    }else{
      if(this.state.labelUid===this.state.event.workflow_uid){
        method = 'put'
        url = process.env.REACT_APP_API_DOMAIN + '/v1/workflows/' +item.workflow_uid+'/event_templates/'+ item.uid
        axios({
          method: method,
          url: url,
          data: (item),
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then(resp => {
          
          const newData = resp.data
          this.setState({
            events: this.state.events.filter(task => task.uid !== item.uid)
          })
          this.setState({ events: [...this.state.events, newData] })
          this.setState({ eventPopUp: false })
        }).catch(error => console.log(error.response))
      }
      else{
        axios({
          method: "delete",
          url: process.env.REACT_APP_API_DOMAIN + '/v1/workflows/' +this.state.labelUid+'/event_templates/'+ item.uid,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then(() => {
          
          this.setState({
            events: this.state.events.filter(event => event.uid !== item.uid)
          })
          return axios({
            method: 'post',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/workflows/' +item.workflow_uid+'/event_templates/',
            data: (item),
            headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
          }).then(resp => {
            
            const newData = resp.data
            this.setState({
              events: this.state.events.filter(event => event.uid !== item.uid)
            })
            this.setState({ events: [...this.state.events, newData] })
            this.setState({ eventPopUp: false })
          }).catch(error => console.log(error.response))
        }).catch(error => console.log(error.response))

      }
    }
  }

  submitTask(){
    const item = this.state.task
    var method = ''
    var url = ''
    if(this.state.labelUid===""){
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/workflows/'+item.workflow_uid+'/task_templates'
      axios({
        method: method,
        url: url,
        data: (item),
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then(resp => {
        
        const newData = resp.data
        this.setState({
          tasks: this.state.tasks.filter(task => task.uid !== item.uid)
        })
        this.setState({ tasks: [...this.state.tasks, newData] })
        this.setState({ taskPopup: false })
      }).catch(error => console.log(error.response))
    }else{
      if(this.state.labelUid===this.state.task.workflow_uid){
        method = 'put'
        url = process.env.REACT_APP_API_DOMAIN + '/v1/workflows/' +item.workflow_uid+'/task_templates/'+ item.uid
        axios({
          method: method,
          url: url,
          data: (item),
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then(resp => {
          
          const newData = resp.data
          this.setState({
            tasks: this.state.tasks.filter(task => task.uid !== item.uid)
          })
          this.setState({ tasks: [...this.state.tasks, newData] })
          this.setState({ taskPopup: false })
        }).catch(error => console.log(error.response))
      }
      else{
        axios({
          method: "delete",
          url: process.env.REACT_APP_API_DOMAIN + '/v1/workflows/' +this.state.labelUid+'/task_templates/'+ item.uid,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then(() => {
          
          this.setState({
            tasks: this.state.tasks.filter(task => task.uid !== item.uid)
          })
          return axios({
            method: 'post',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/workflows/' +item.workflow_uid+'/task_templates/',
            data: (item),
            headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
          }).then(resp => {
            
            const newData = resp.data
            this.setState({
              tasks: this.state.tasks.filter(task => task.uid !== item.uid)
            })
            this.setState({ tasks: [...this.state.tasks, newData] })
            this.setState({ taskPopup: false })
          }).catch(error => console.log(error.response))
        }).catch(error => console.log(error.response))

      }
    }
  }

  handleCreateorUpdateTask (item, isAdd) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create Task Template'
    } else {
      textTitle = 'Edit Task Template'
    }
    this.setState({ taskPopup: true })
    this.setState({ task: item })
    this.setState({ title: textTitle })
    this.setState({subs: item.subs})
    this.setState({labelUid: item.workflow_uid})
  }

  handleCreateorUpdateEvent (item, isAdd) {
    
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create Event Template'
    } else {
      textTitle = 'Edit Event Template'
    }
    this.setState({ eventPopUp: true })
    this.setState({ event: item })
    this.setState({ title: textTitle })
    this.setState({labelUid: item.workflow_uid})
  }

  handleOpenDeletePopup (item, model) {
    this.setState({ model: model })
    this.setState({ workflow: item })
    this.setState({ openDeletePopup: true })
  }

  handleOpenDeleteTask (item) {
    this.setState({ model: "Task Template" })
    this.setState({ task: item })
    this.setState({ openTaskDelete: true })
  }

  handleOpenDeleteEvent (item) {
    this.setState({ model: "Event Template" })
    this.setState({ event: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose () {
    this.setState({ openDeletePopup: false })
  }

  addTrigger () {
    this.setState({ triggers: [...this.state.triggers, { ...this.state.trigger }] })
  }

  handleTriggers (e) {
    const updatedTriggers = [...this.state.triggers]
    updatedTriggers[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
    this.setState({ triggers: updatedTriggers })
  }

  removeTrigger (idx) {
    var uid = this.state.triggers[idx].uid
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/workflows/' + this.state.triggers[idx].uid,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        triggers: this.state.triggers.filter(subTask => subTask.uid !== uid)
      })
    })
    this.state.triggers.splice(idx, 1)
    this.setState({ triggers: [...this.state.triggers] })
  }

  addSubs () {
    this.setState({ subs: [...this.state.subs, this.state.subTask ] })
  }

  handleSubs (e) {
    const updatedTriggers = [...this.state.subs]
    updatedTriggers[e.target.dataset.id]= e.target.value
    this.setState({ subs: updatedTriggers })
    this.setState({
      task: {
        ...this.state.task,
        subs: updatedTriggers
      }
    })

  }

  removeSubs (idx) {
    this.state.subs.splice(idx, 1)
    this.setState({ subs: [...this.state.subs] })
  }

  handleCreateorUpdateTrigger(item, action) {
    this.setState({ openTriggerFormPopup: true })
    this.setState({ title: action === 'create' ? 'Create Event Trigger' : 'Edit Event Trigger' })
    this.setState({ createOrUpdateTrigger: action })
    this.setState({ selectedTrigger: item })
  }

  handleOpenDeleteTrigger(item) {
    this.setState({ model: "Event Trigger" })
    this.setState({ selectedTrigger: item })
    this.setState({ openTriggerFormDeletePopup: true })
  }

  handleRemoveTrigger(triggerUid) {
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/workflows/' + triggerUid,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        triggers: this.state.triggers.filter(subTask => subTask.uid !== triggerUid)
      })
      this.setState({ openTriggerFormDeletePopup: false })
    }).catch(error => console.log(error.response))
  }

  setTriggers(selectedTrigger, newOrUpdatedTrigger) {
    if(selectedTrigger) {
      this.setState({ triggers: this.state.triggers.map(subTask => {
        if(subTask.uid === selectedTrigger.uid){
          return {
            ...subTask,
            name: newOrUpdatedTrigger.name
          }
        } 
          
        return subTask
      }) })
    }
    else this.setState({ triggers: [...this.state.triggers, newOrUpdatedTrigger] })
    this.setState({ openTriggerFormPopup: false })
  }

  render () {
    return (
      <>
        {this.state.load ?
          (
          <Box className={styles.mainBoxWorkflow}>
            <Box className={styles.mainBoxHeader}>
              <Typography variant='h1' className={styles.mainBoxHeaderTitle}>{this.state.workflow.name}</Typography>
              <IconButton id="workflow-edit" onClick={() => this.handleCreateorUpdateItem(this.state.workflow, false, this.state.model)}>
                <EditIcon fontSize='small' />
              </IconButton>
            </Box>

            <TriggerTable 
              triggers={this.state.triggers} 
              handleCreateorUpdateTrigger={this.handleCreateorUpdateTrigger}
              handleOpenDeleteTrigger={this.handleOpenDeleteTrigger}
            />
              
            <TaskTable
              onclick={this.handleCreateorUpdateTask}
              initialItem={this.state.initialTask}
              items={this.state.tasks}
              model="Tasks"
              onOpenDeletePopup={this.handleOpenDeleteTask}
              headers={['Name','Due Date','Priority Level','Actions']}
              withShow={false}
              withEdit={true}
              triggers={this.state.triggers}
            />

            <EventTable
              onclick={this.handleCreateorUpdateEvent}
              initialItem={this.state.initialEvent}
              items={this.state.events}
              model="Events"
              onOpenDeletePopup={this.handleOpenDeleteEvent}
              headers={['Name',"Event Date",'Duration','Actions']}
              withShow={false}
              withEdit={true}
              triggers={this.state.triggers}
            />

            <SimplePopUp
              openPopup={this.state.openPopUp}
              title={this.state.title}
              items={this.state.workflows}
              handleClose={this.handleClose}
              popupsize="sm"
            >
              <WorkflowForm
                handleInputChange={this.handleChangeName}
                workflow={this.state.workflow}
                triggers={this.state.triggers}
                addTrigger={this.addTrigger}
                handleTriggers={this.handleTriggers}
                removeTrigger={this.removeTrigger}
                handleSubmit={this.handleSubmit}
                createOrUpdateWorkflow={this.state.createOrUpdateWorkflow}
              />
            </SimplePopUp>

            <SimplePopUp
              openPopup={this.state.taskPopup}
              title={this.state.title}
              items={this.state.workflows}
              handleClose={this.handleClose}
              maxWidth="md"
            >
              <TaskForm addSubs={this.addSubs} items={this.state.subs}
              handleTaskChange={this.handleTaskChange} submit={this.submitTask}
              triggers={this.state.triggers} task={this.state.task} tasks={this.state.tasks}
              handleSubs={this.handleSubs} removeSub={this.removeSubs}
              priorities={this.state.priorities}
              />
            </SimplePopUp>

            <SimpleDeletePopUp
              openDeletePopup={this.state.openTaskDelete}
              item={this.state.task}
              delete={this.handleDeleteTaskItem}
              handleDeleteClose={this.handleClose}
              model="Task Template"
            />

            <SimplePopUp
              openPopup={this.state.eventPopUp}
              title={this.state.title}
              items={this.state.events}
              handleClose={this.handleClose}
              maxWidth="md"
            >
              <EventForm
              handleEventChange={this.handleEventChange} submit={this.submitEvent}
              triggers={this.state.triggers} event={this.state.event} events={this.state.events}
              />
            </SimplePopUp>

            <SimplePopUp
              openPopup={this.state.openTriggerFormPopup}
              title={this.state.title}
              handleClose={this.handleClose}
              maxWidth="xs"
            >
              <TriggerForm 
                createOrUpdateTrigger={this.state.createOrUpdateTrigger}
                selectedTrigger={this.state.selectedTrigger}
                workflowId={this.props.location.state.item.uid}
                setTriggers={this.setTriggers}
                />
            </SimplePopUp>

            <SimpleDeletePopUp
              openDeletePopup={this.state.openTriggerFormDeletePopup}
              delete={() => this.handleRemoveTrigger(this.state.selectedTrigger.uid)}
              handleDeleteClose={this.handleClose}
              model="Event Trigger"
            />

            <SimpleDeletePopUp
              openDeletePopup={this.state.openDeletePopup}
              item={this.state.event}
              delete={this.handleDeleteEvent}
              handleDeleteClose={this.handleClose}
              model="Event Template"
            />
          </Box>
        ) : (<div>Workflow</div>)
        }
      </>
    )
  }
}
export default Workflow

Workflow.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object.isRequired
  }).isRequired
}
