import React from 'react'

import PropTypes from 'prop-types'

import styles from './Events.module.css'

import Autocomplete from '@material-ui/lab/Autocomplete'

import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

class EventForm extends React.Component {
  render() {
    return (
      <div>
        <form className={styles.formBody} noValidate>
          <>
            {
              this.props.withCase ? (
                <>
                  <Autocomplete
                    value={this.props.legalCase}
                    className={styles.formTextfield}
                    id="legal_case"
                    size="small"
                    name="case"
                    options={this.props.cases.sort(function (a, b) {
                      if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
                      if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                      return 0;
                    })}
                    getOptionLabel={(option) => option.title || ''}
                    getOptionSelected={(option, value) => option.title === value.title}
                    onChange={this.props.handleEventAutoComplete}
                    renderInput={(params) => <TextField {...params} name="case" label="Case Link" variant="outlined" />}
                    disabled={this.props.show}
                  />

                  <Typography variant="caption" display="block" gutterBottom>
                    <Checkbox name="casebox" onClick={this.props.handleCheck} checked={!this.props.show} /> This event is linked to a case</Typography>
                </>
              ) : (null)
            }
          </>
          <TextField
            autoComplete="off"
            autoFocus={true}
            variant="outlined"
            size="small"
            fullWidth
            className={styles.formTextfield}
            required
            name="name"
            value={this.props.event.name}
            onChange={this.props.handleEventChange}
            label="Name" />

          <Autocomplete
            value={this.props.selectedValues}
            id="user_ids"
            name="user_ids"
            multiple
            size="small"
            options={(this.props.eventCaseUsers.length > 0 ? (this.props.eventCaseUsers) : (this.props.users)).sort(function (a, b) {
              if (a.username.toLowerCase() < b.username.toLowerCase()) return -1;
              if (a.username.toLowerCase() > b.username.toLowerCase()) return 1;
              return 0;
            })}
            getOptionLabel={(option) => option.username || ''}
            getOptionSelected={(option, value) => option.username === value.username}
            onChange={this.props.handleEventUsersAutocomplete}

            renderOption={(option, { selected }) => {
              return (
                <React.Fragment>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.username}
                </React.Fragment>
              )
            }}
            renderInput={(params) => (
              <TextField {...params} name="user_ids" variant="outlined" label="Users" />
            )}
          />
          <TextField
            autoComplete="off"
            multiline={true}
            rows={10}
            className={styles.formTextfield}
            variant="outlined"
            fullWidth
            name="description"
            value={this.props.event.description}
            onChange={this.props.handleEventChange}
            label="Description" />

          <div className={styles.dateBody}>
            <TextField
              id="event_starts_at"
              className={styles.dateRight}
              fullWidth
              label="Start Date"
              type="datetime-local"
              name="starts_at"
              size="small"
              variant="outlined"
              value={this.props.event.starts_at}
              onChange={this.props.handleEventChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="event_ends_at"
              // className={styles.formTextfield}
              fullWidth
              label="End Date"
              type="datetime-local"
              name="ends_at"
              size="small"
              variant="outlined"
              value={this.props.event.ends_at}
              onChange={this.props.handleEventChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </form>
        <div className={styles.actionButton}>
          <Button
            variant="outlined"
            disabled={!this.props.event.name || !this.props.selectedValues.length>0}
            name="submit"
            color="primary"
            onClick={() => this.props.submit()} >
            Save
          </Button>
        </div>
      </div>
    )
  }
}
export default EventForm

EventForm.propTypes = {
  event: PropTypes.object,
  handleCaseChange: PropTypes.func,
  show: PropTypes.bool,
  handleEventChange: PropTypes.func,
  isAdd: PropTypes.bool,
  legalCase: PropTypes.object,
  handleChange: PropTypes.func,
  cases: PropTypes.array,
  errors: PropTypes.string,
  handleCheck: PropTypes.func,
  addSubTask: PropTypes.func,
  removeSubTask: PropTypes.func,
  handleSubTasks: PropTypes.func,
  users: PropTypes.array,
  handleEventAutoComplete: PropTypes.func,
  getDefaultValue: PropTypes.func,
  defaultCase: PropTypes.object,
  submit: PropTypes.func,
  handleStartChange: PropTypes.func,
  handleEndChange: PropTypes.func,
  eventCaseUsers: PropTypes.array,
  handleEventUsersAutocomplete: PropTypes.func,
  isFormValid: PropTypes.func,
  withCase: PropTypes.bool,
  selectedValues: PropTypes.array
}
