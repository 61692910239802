import React from 'react'

import PropTypes from 'prop-types'

import axios from 'axios'

import styles from './Client.module.css'

import { Redirect } from 'react-router'

import {
	Button, ButtonGroup
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'

import ContactPersons from './ContactPerson/ContactPersons'
import Addresses from './Address/Addresses'
import ContactNumbers from './ContactNumber/ContactNumbers'
import EmailAddresses from './EmailAddress/EmailAddresses'
import NestedForm from './NestedForm'
import Events from './Events/Events'
import LegalCases from './LegalCases/LegalCases'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import ClientForm from './ClientForm'
import GroupForm from './GroupForm'
import ContactPersonForm from './ContactPerson/ContactPersonForm'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'

import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import ViewListIcon from '@material-ui/icons/ViewList'
import MenuBookIcon from '@material-ui/icons/MenuBook'

import ListIcon from '@material-ui/icons/List'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import Tasks from "./Tasks/Tasks";
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import AlertMessage from "../../shared/Notify/AlertMessage";
import BusinessIcon from '@material-ui/icons/Business';
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	}
}

class Client extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			groups: [],
			value: 0,
			model: 'Client',
			clients: [],
			nestedTitle: '',
			options: [],
			nestedModel: '',
			openPopup: false,
			openNestedForm: false,
			openDeletePopup: false,
			addButtonNone: true,
			defaultGroup: {},
			client: {},
			initialNested: { uid: '', content: '', category: 0 },
			addresses: [],
			address: { content: '', category: 0 },
			emailAddress: { content: '', category: 0 },
			contactNumber: { content: '', category: 0 },
			contactNumbers: [],
			emailAddresses: [],
			initialContactPerson: { uid: '', name: '', designation: '', department: '', remarks: '' },
			contactPerson: {},
			nestedContactNumbers: [{ content: '', category: 0 }],
			nestedEmailAddresses: [{ content: '', category: 0 }],
			contactPersons: [],
			legalCases: [],
			openGroup: false,
			initialClient: {
				name: '',
				group_id: '',
				is_company: false,
				is_active: true,
				is_taxable: false,
				fields: {},
			},
			cfield: {},
			expanded: false,
			title: '',
			load: false,
			tableHead: ['name', 'group.name', 'is_company', 'is_active'],
			group: {},
			withShow: true,
			maxWidth: 'md',
			fields: [],
			events: [],
			nestedItem: {},
			openContactPerson: false,
			contactItem: {},
			addContact: false,
			contactPersonNumbers: [],
			contactPersonEmails: [],
			openClientDeletePopUp: false,
			redirectToIndex: false,
			errorMessages: {}
		}
		this.loadClientAttributes = this.loadClientAttributes.bind(this)
		this.handleChange = this.handleChange.bind(this)
		this.handleNestedChange = this.handleNestedChange.bind(this)
		this.submitNested = this.submitNested.bind(this)
		this.handleCreateOrUpdateNested = this.handleCreateOrUpdateNested.bind(this)
		this.handleClose = this.handleClose.bind(this)

		this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
		// this.handleClose = this.handleClose.bind(this)
		this.editClient = this.editClient.bind(this)
		this.handleInputChange = this.handleInputChange.bind(this)
		this.handleSwitch = this.handleSwitch.bind(this)
		this.addGroup = this.addGroup.bind(this)
		this.handleGroupChange = this.handleGroupChange.bind(this)
		this.openGroupForm = this.openGroupForm.bind(this)
		this.handleExpandClick = this.handleExpandClick.bind(this)
		this.handleField = this.handleField.bind(this)
		this.handleCloseGroup = this.handleCloseGroup.bind(this)
		this.handleDeleteItem = this.handleDeleteItem.bind(this)
		this.handleOpenDelete = this.handleOpenDelete.bind(this)
		this.handleContactPerson = this.handleContactPerson.bind(this)
		this.removeEmail = this.removeEmail.bind(this)
		this.addEmailAddress = this.addEmailAddress.bind(this)
		this.handleEmailAddressChange = this.handleEmailAddressChange.bind(this)
		this.removeContactNumber = this.removeContactNumber.bind(this)
		this.addContactNumber = this.addContactNumber.bind(this)
		this.handleContactNumberChange = this.handleContactNumberChange.bind(this)
		this.handleNumberChange = this.handleNumberChange.bind(this)
		this.submitContact = this.submitContact.bind(this)
		this.handleContactPersonChange = this.handleContactPersonChange.bind(this)
		this.handleAutoComplete = this.handleAutoComplete.bind(this)
		this.capitalize = this.capitalize.bind(this)
		this.setVal = this.setVal.bind(this)
		this.handleCloseAlert = this.handleCloseAlert.bind(this)
		this.onOpenDeletePopUp = this.onOpenDeletePopUp.bind(this)
		this.handleClientDelete = this.handleClientDelete.bind(this)
	}

	componentDidMount() {

		if (this.props.location.state !== undefined) {
			if (localStorage.getItem('newClient') !== null && this.props.match.params.uid === JSON.parse(localStorage.getItem('newClient')).uid) {
				this.setState({ client: JSON.parse(localStorage.getItem('newClient')) })
				this.setState({ cfield: JSON.parse(localStorage.getItem('newClient')).fields })
				this.loadClientAttributes(JSON.parse(localStorage.getItem('newClient')).uid)

			} else {
				this.setState({ client: this.props.location.state.item })
				this.setState({ cfield: this.props.location.state.item.fields })
				this.loadClientAttributes(this.props.location.state.item.uid)
			}
		}

		else {
			axios({
				method: 'get',
				url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.props.match.params.uid,
				headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
			}).then(resp => {

				this.setState({ client: resp.data })
				this.setState({ cfield: resp.data.fields })
				this.loadClientAttributes(resp.data.uid)
				this.setState({ load: true })
			})
				.catch(resp => console.log(resp))
		}

	}

	handleCreateorUpdateItem(client) {
		this.setState({ client: client })
		this.setState({ openPopup: true })
	}

	handleCloseGroup() {
		this.setState({ openGroup: false })
	}

	handleExpandClick() {
		this.setState({ expanded: !this.state.expanded })
	}

	editClient() {
		var item = {}
		item["name"] = this.state.client.name
		item["uid"] = this.state.client.uid
		item["fields"] = this.state.client.fields
		item["group_id"] = this.state.defaultGroup.uid
		item["is_active"] = this.state.client.is_active
		item["is_company"] = this.state.client.is_company
		item["is_taxable"] = this.state.client.is_taxable
		axios({
			method: 'put',
			url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.state.client.uid,
			data: (item),
			headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
		}).then(resp => {

			this.setState({ client: resp.data })
			localStorage.setItem('newClient', JSON.stringify(resp.data))
			this.setState({
				isOpen: true,
				message: 'Submitted Successfully',
				type: 'success'
			})
			this.setState({ openPopup: false })
		}).catch(error => {
			if (error.response.status === 422) {
				var errors = {}
				Object.keys(error.response.data.errors).map((field) => {
					errors[field] = error.response.data.errors[field][0]
					if (field.includes('.')) {
						errors[field.split(".")[1]] = error.response.data.errors[field][0]
					} else {
						errors[field] = error.response.data.errors[field][0]
					}
				})
				this.setState({ errorMessages: errors })
			} else {
				this.setState({
					isOpen: true,
					message: error.response.status.toString() + " Unexpected Error Problem Occurred",
					type: 'error'
				})
				this.setState({ openPopup: false })
			}
		})

	}

	handleInputChange(e) {
		this.setState({
			client: {
				...this.state.client,
				[e.target.name]: e.target.value
			}
		})
	}

	handleSwitch(event) {
		const value = event.target.checked
		this.setState({
			client: {
				...this.state.client,
				[event.target.name]: value
			}
		})
	}
	handleGroupChange(e) {
		this.setState({
			group: {
				...this.state.group,
				[e.target.name]: e.target.value
			}
		})
	}
	addGroup() {
		const item = this.state.group
		axios({
			method: 'post',
			url: process.env.REACT_APP_API_DOMAIN + '/v1/groups/',
			data: (item),
			headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
		})
			.then(resp => {
				var newData = resp.data

				this.setState({ defaultGroup: resp.data })
				this.setState({
					groups: this.state.groups.filter(group => group.uid !== item.uid)
				})
				this.setState({ groups: [...this.state.groups, newData] })
				this.setState({
					client: {
						...this.state.client,
						group_id: resp.data.uid
					}
				})
				this.setState({
					isOpen: true,
					message: 'Submitted Successfully',
					type: 'success'
				})
				this.setState({ openGroup: false })
			})
			.catch(error => console.log(error.response))
	}
	openGroupForm() {
		this.setState({ group: { uid: '', name: '' } })
		this.setState({ openGroup: true })
	}

	handleField(e) {
		const value = e.target.value
		this.setState({
			cfield: {
				...this.state.cfield,
				[e.target.name]: value
			}
		})
		this.setState({
			client: {
				...this.state.client,
				fields: JSON.stringify({
					...this.state.cfield,
					[e.target.name]: value
				})
			}
		})
	}

	loadClientAttributes(uid) {
		axios({
			method: 'get',
			url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/custom_fields',
			headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
		}).then(resp => {
			this.setState({ fields: resp.data })
			axios({
				method: 'get',
				url: process.env.REACT_APP_API_DOMAIN + '/v1/groups',
				headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
			}).then(resp => {
				this.setState({ groups: resp.data.data })
				resp.data.data.map((item) => {
					if (this.state.client.group_uid === item.uid) {
						this.setState({ defaultGroup: item })
					}
				})
				axios({
					method: 'get',
					url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + uid + '/legal_cases',
					headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
				}).then(resp => {

					this.setState({ legalCases: resp.data })
					if (this.state.client.is_company) {
						axios({
							method: 'get',
							url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + uid + '/contact_persons',
							headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
						}).then(resp => {

							this.setState({ contactPersons: resp.data })
							axios({
								method: 'get',
								url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + uid + '/addresses',
								headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
							}).then(resp => {

								this.setState({ addresses: resp.data })
								this.setState({ load: true })
							}).catch(resp => console.log(resp))
						}).catch(resp => console.log(resp))
					}
					else {
						axios({
							method: 'get',
							url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + uid + '/addresses',
							headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
						}).then(resp => {

							this.setState({ addresses: resp.data })
							axios({
								method: 'get',
								url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + uid + '/contact_numbers',
								headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
							}).then(resp => {

								this.setState({ contactNumbers: resp.data })
								axios({
									method: 'get',
									url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + uid + '/email_addresses',
									headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
								}).then(resp => {

									this.setState({ emailAddresses: resp.data })
									this.setState({ load: true })
								}).catch(resp => console.log(resp))
							}).catch(resp => console.log(resp))
						}).catch(resp => console.log(resp))
					}
				}).catch(resp => console.log(resp))
			}).catch(resp => console.log(resp))
		}).catch(error => console.log(error.response))

	}

	handleChange(event, newValue) {
		this.setState({ value: newValue })
	}

	handleCreateOrUpdateNested(title, model, item) {
		this.setState({ nestedTitle: title })
		this.setState({ nestedItem: item })
		this.setState({ nestedModel: model })
		this.setState({ openNestedForm: true })
		if (model.toLowerCase() === 'address') {
			this.setState({ options: ["Permanent Address", "Current Address"] })
		} else if (model.toLowerCase() === 'contact number') {
			this.setState({ options: ["Primary", "Home Phone Number", "Phone Number", "Mobile Number", "Fax Number", "Others"] })
		} else {
			this.setState({ options: ["Personal", "Work", "Others"] })
		}

	}

	handleNestedChange(e) {
		this.setState({
			nestedItem: {
				...this.state.nestedItem,
				[e.target.name]: e.target.value
			}
		})
	}

	handleContactPersonChange(e) {

		this.setState({
			contactItem: {
				...this.state.contactItem,
				[e.target.name]: e.target.value
			}
		})
	}

	submitNested() {
		var method = ''
		var url = ''
		var item = this.state.nestedItem
		if (this.state.nestedModel.toLowerCase() === 'address') {
			if (item.uid === '') {
				method = 'post'
				url = process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.state.client.uid + '/addresses'
			} else {
				method = 'put'
				url = process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.state.client.uid + '/addresses/' + item.uid
			}
			axios({
				method: method,
				url: url,
				data: (item),
				headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
			}).then(resp => {

				const newData = resp.data
				this.setState({
					addresses: this.state.addresses.filter(address => address.uid !== item.uid)
				})
				this.setState({
					isOpen: true,
					message: 'Submitted Successfully',
					type: 'success'
				})
				this.setState({ addresses: [...this.state.addresses, newData] })
				this.setState({ openNestedForm: false })
			}).catch(error => console.log(error.response))
		} else if (this.state.nestedModel.toLowerCase() === 'contact number') {
			if (item.uid === '') {
				method = 'post'
				url = process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.state.client.uid + '/contact_numbers'
			} else {
				method = 'put'
				url = process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.state.client.uid + '/contact_numbers/' + item.uid
			}
			axios({
				method: method,
				url: url,
				data: (item),
				headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
			}).then(resp => {

				const newData = resp.data
				this.setState({
					contactNumbers: this.state.contactNumbers.filter(address => address.uid !== item.uid)
				})
				this.setState({ contactNumbers: [...this.state.contactNumbers, newData] })
				this.setState({
					isOpen: true,
					message: 'Submitted Successfully',
					type: 'success'
				})
				this.setState({ openNestedForm: false })
			}).catch(error => console.log(error.response))
		} else {
			if (item.uid === '') {
				method = 'post'
				url = process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.state.client.uid + '/email_addresses'
			} else {
				method = 'put'
				url = process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.state.client.uid + '/email_addresses/' + item.uid
			}
			axios({
				method: method,
				url: url,
				data: (item),
				headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
			}).then(resp => {

				const newData = resp.data
				this.setState({
					emailAddresses: this.state.emailAddresses.filter(address => address.uid !== item.uid)
				})
				this.setState({ emailAddresses: [...this.state.emailAddresses, newData] })
				this.setState({
					isOpen: true,
					message: 'Submitted Successfully',
					type: 'success'
				})
				this.setState({ openNestedForm: false })
			}).catch(error => console.log(error.response))
		}

	}

	handleClose() {
		this.setState({ openNestedForm: false })
		this.setState({ openPopup: false })
		this.setState({ openDeletePopup: false })
		this.setState({ openContactPerson: false })
		this.setState({ expanded: false })
		this.setState({ openClientDeletePopUp: false })

	}

	handleOpenDelete(model, item) {
		this.setState({ nestedItem: item })
		this.setState({ nestedModel: model })
		this.setState({ openDeletePopup: true })

	}


	handleDeleteItem() {
		const DeleteItemId = this.state.nestedItem.uid
		if (this.state.nestedModel.toLowerCase() === 'address') {
			axios({
				method: 'delete',
				url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.state.client.uid + '/addresses/' + DeleteItemId,
				headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
			}).then(() => {
				this.setState({
					addresses: this.state.addresses.filter(item => item.uid !== DeleteItemId)
				})
				this.setState({
					isOpen: true,
					message: 'Deleted Successfully',
					type: 'error'
				})
				this.setState({ openDeletePopup: false })
			}).catch(error => console.log(error.response))

		} else if (this.state.nestedModel.toLowerCase() === 'contact number') {

			axios({
				method: 'delete',
				url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.state.client.uid + '/contact_numbers/' + DeleteItemId,
				headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
			}).then(() => {
				this.setState({
					contactNumbers: this.state.contactNumbers.filter(item => item.uid !== DeleteItemId)
				})
				this.setState({
					isOpen: true,
					message: 'Deleted Successfully',
					type: 'error'
				})
				this.setState({ openDeletePopup: false })
			}).catch(error => console.log(error.response))

		} else if (this.state.nestedModel.toLowerCase() === 'email address') {
			axios({
				method: 'delete',
				url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.state.client.uid + '/email_addresses/' + DeleteItemId,
				headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
			}).then(() => {
				this.setState({
					emailAddresses: this.state.emailAddresses.filter(item => item.uid !== DeleteItemId)
				})
				this.setState({ openDeletePopup: false })
			}).catch(error => console.log(error.response))
		} else {
			axios({
				method: 'delete',
				url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.state.client.uid + '/contact_persons/' + DeleteItemId,
				headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
			}).then(() => {
				this.setState({
					contactPersons: this.state.contactPersons.filter(item => item.uid !== DeleteItemId)
				})
				this.setState({
					isOpen: true,
					message: 'Deleted Successfully',
					type: 'error'
				})
				this.setState({ openDeletePopup: false })
			}).catch(error => console.log(error.response))
		}
	}

	handleContactPerson(title, item, addState) {
		var nestedContacts = []
		var nestedEmails = []
		if (addState === false) {
			axios({
				method: 'get',
				url: process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + item.uid + '/contact_numbers',
				headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
			}).then(resp => {
				console.log(resp.data)
				nestedContacts = resp.data
				axios({
					method: 'get',
					url: process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + item.uid + '/email_addresses',
					headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
				}).then(resp => {
					console.log(resp.data)
					nestedEmails = resp.data
					this.setState({ nestedContactNumbers: nestedContacts })
					this.setState({ nestedEmailAddresses: nestedEmails })
					this.setState({ openContactPerson: true })
					this.setState({ title: title })
					this.setState({ contactItem: item })
					this.setState({ contactState: true })
				}).catch(resp => console.log(resp))
			}).catch(resp => console.log(resp))
		} else {
			this.setState({ nestedContactNumbers: nestedContacts })
			this.setState({ nestedEmailAddresses: nestedEmails })
			this.setState({ openContactPerson: true })
			this.setState({ title: title })
			this.setState({ contactItem: item })
			this.setState({ contactState: true })
		}



	}

	removeEmail(idx) {
		if (this.state.nestedEmailAddresses[idx].uid !== undefined) {
			const DeleteItemId = this.state.nestedEmailAddresses[idx].uid
			axios({
				method: 'delete',
				url: process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + this.state.contactItem.uid + '/email_addresses/' + DeleteItemId,
				headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
			}).then(() => {

			}).catch(error => console.log(error.response))
		}


		this.state.nestedEmailAddresses.splice(idx, 1)
		this.setState({ nestedEmailAddresses: [...this.state.nestedEmailAddresses] })

	}

	handleEmailAddressChange(e) {
		const updatedEmailAddress = [...this.state.nestedEmailAddresses]
		updatedEmailAddress[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
		this.setState({ nestedEmailAddresses: updatedEmailAddress })

	}

	addEmailAddress() {
		this.setState({ nestedEmailAddresses: [...this.state.nestedEmailAddresses, { ...this.state.emailAddress }] })
	}

	removeContactNumber(idx) {
		if (this.state.nestedContactNumbers[idx].uid !== undefined) {
			const DeleteItemId = this.state.nestedContactNumbers[idx].uid
			axios({
				method: 'delete',
				url: process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + this.state.contactItem.uid + '/contact_numbers/' + DeleteItemId,
				headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
			}).then(() => {

			}).catch(error => console.log(error.response))
		}

		this.state.nestedContactNumbers.splice(idx, 1)
		this.setState({ nestedContactNumbers: [...this.state.nestedContactNumbers] })

	}

	handleNumberChange(e) {
		const re = /^[0-9\b]+$/
		if (e.target.value === '' || re.test(e.target.value)) {
			const updatedContactNumbers = [...this.state.nestedContactNumbers]
			updatedContactNumbers[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
			this.setState({ nestedContactNumbers: updatedContactNumbers })

		}
	}

	handleContactNumberChange(e) {
		const updatedContactNumbers = [...this.state.nestedContactNumbers]
		updatedContactNumbers[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
		this.setState({ nestedContactNumbers: updatedContactNumbers })

	}

	addContactNumber() {
		this.setState({ nestedContactNumbers: [...this.state.nestedContactNumbers, { ...this.state.contactNumber }] })
	}

	submitContact() {
		var item = this.state.contactItem
		var method = ''
		var url = ''
		if (item.uid === '') {
			method = "post"
			url = process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.state.client.uid + '/contact_persons'
		} else {
			method = "put"
			url = process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.state.client.uid + '/contact_persons/' + item.uid
		}
		axios({
			method: method,
			url: url,
			data: (item),
			headers: { 'X-APP-API-KEY': localStorage.getItem("api_key") }
		}).then(resp => {
			const newData = resp.data
			const uid = resp.data.uid
			var method = ''
			var url = ''

			if (this.state.nestedContactNumbers.length > 0) {
				this.state.nestedContactNumbers.map((contact) => {
					if (contact.uid === undefined) {
						method = "post"
						url = process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + uid + '/contact_numbers'
					} else {
						method = "put"
						url = process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + uid + '/contact_numbers/' + contact.uid
					}
					axios({
						method: method,
						url: url,
						data: (contact),
						headers: { 'X-APP-API-KEY': localStorage.getItem("api_key") }
					}).then(() => {
						if (this.state.nestedEmailAddresses.length > 0) {
							this.state.nestedEmailAddresses.map((emailAddress) => {
								if (emailAddress.uid === undefined) {
									method = "post"
									url = process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + uid + '/email_addresses'
								} else {
									method = "put"
									url = process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + uid + '/email_addresses/' + emailAddress.uid
								}
								axios({
									method: method,
									url: url,
									data: (emailAddress),
									headers: { 'X-APP-API-KEY': localStorage.getItem("api_key") }
								}).then(() => {
									this.setState({
										contactPersons: this.state.contactPersons.filter(contactPerson => contactPerson.uid !== item.uid)
									})
									this.setState({ contactPersons: [...this.state.contactPersons, newData] })
									this.setState({ openContactPerson: false })

								})
							})

						} else {
							this.setState({
								contactPersons: this.state.contactPersons.filter(contactPerson => contactPerson.uid !== item.uid)
							})
							this.setState({ contactPersons: [...this.state.contactPersons, newData] })
							this.setState({ openContactPerson: false })
						}
					}).catch(error => console.log(error.response.data))
				})
			}

			else if (this.state.nestedEmailAddresses.length > 0) {
				this.state.nestedEmailAddresses.map((emailAddress) => {
					if (emailAddress.uid === undefined) {
						method = "post"
						url = process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + uid + '/email_addresses'
					} else {
						method = "put"
						url = process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + uid + '/email_addresses/' + emailAddress.uid
					}
					axios({
						method: method,
						url: url,
						data: (emailAddress),
						headers: { 'X-APP-API-KEY': localStorage.getItem("api_key") }
					}).then(() => {
						if (this.state.nestedContactNumbers.length > 0) {
							this.state.nestedContactNumbers.map((contact) => {
								if (contact.uid === undefined) {
									method = "post"
									url = process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + uid + '/contact_numbers'
								} else {
									method = "put"
									url = process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + uid + '/contact_numbers/' + contact.uid
								}
								axios({
									method: method,
									url: url,
									data: (contact),
									headers: { 'X-APP-API-KEY': localStorage.getItem("api_key") }
								}).then(() => {
									this.setState({
										contactPersons: this.state.contactPersons.filter(contactPerson => contactPerson.uid !== item.uid)
									})
									this.setState({ contactPersons: [...this.state.contactPersons, newData] })
									this.setState({ openContactPerson: false })
								}).catch(error => console.log(error.response.data))
							})
						}
						else {
							this.setState({
								contactPersons: this.state.contactPersons.filter(contactPerson => contactPerson.uid !== item.uid)
							})
							this.setState({ contactPersons: [...this.state.contactPersons, newData] })
							this.setState({ openContactPerson: false })
						}

					})
				})

			}
			else {
				this.setState({
					contactPersons: this.state.contactPersons.filter(contactPerson => contactPerson.uid !== item.uid)
				})
				this.setState({ contactPersons: [...this.state.contactPersons, newData] })
				this.setState({ openContactPerson: false })
			}





		}).catch(error => console.log(error.response.data))

	}

	handleAutoComplete(event, values) {
		this.setState({ defaultGroup: values })
		// if (values !== null) {

		// 	this.setState({
		// 		client: {
		// 			...this.state.client,
		// 			group_id: values.uid
		// 		}
		// 	})
		// }
	}

	capitalize(str) {
		const splitStr = str.toLowerCase().split(' ')
		for (var i = 0; i < splitStr.length; i++) {
			splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
		}
		return splitStr.join(' ')
	}

	setVal(name) {
		const attrName = name.toLowerCase().replace(' ', '_')
		const value = 'N/A'
		if (this.state.cfield[attrName] === undefined) {
			return value
		} else if (this.state.cfield[attrName] === null) {
			return value
		} else if (this.state.cfield[attrName] === '') {
			return value
		} else {
			return this.state.cfield[attrName]
		}
	}

	handleCloseAlert(event, reason) {
		if (reason === 'clickaway') {
			return;
		}
		this.setState({
			isOpen: false
		})
	}

	onOpenDeletePopUp() {
		this.setState({ model: this.state.model })
		this.setState({ client: this.state.client })
		this.setState({ openClientDeletePopUp: true })
	}

	handleClientDelete() {
		const DeleteItemId = this.state.client.uid
		axios({
			method: 'delete',
			url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + DeleteItemId,
			headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
		}).then(() => {
			this.setState({
				isOpen: true,
				message: 'Deleted Successfully',
				type: 'error'
			})
			this.setState({ openClientDeletePopUp: false })
			this.setState({ redirectToIndex: true })

		}).catch(error => console.log(error.response))
	}


	render() {
		const { redirectToIndex } = this.state

		if (redirectToIndex)
			return (<Redirect to={'/clients'} />)
		return (
			<>
				{
					this.state.load ? (
						<div>
							<Grid container spacing={1} className={styles.headerTitleBody}>
								<Grid item xs={12} sm={8}>
									<div>
										<div className={styles.flex}>
											<PersonOutlineIcon fontSize="large" />
											<div className={styles.title} name="client_title">
												{this.state.client.name}
											</div>
										</div>
										<div>
											<Addresses addresses={this.state.addresses} initialAddress={this.state.initialNested} handleClick={this.handleCreateOrUpdateNested} handleOpenDelete={this.handleOpenDelete} />
										</div>
										<div className={styles.clientDetails}>
											<div>
												{
													this.state.client.is_active ? (
														<span className={styles.subName}> Active </span>
													) : (
														<span className={styles.subName}> Inactive </span>
													)
												}
											</div>
											<div className={styles.groupName}>
												<PeopleOutlineIcon />{this.state.client.group.name}
											</div>
											<div >
												{
													this.state.client.is_company ? (
														<div className={styles.groupName}> <BusinessIcon />Company </div>
													) : (
														<div className={styles.groupName}><PersonOutlineIcon /> Individual </div>
													)
												}
											</div>
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={4} className={styles.buttonGroup}>
									<ButtonGroup>
										<Tooltip title="Edit Client">
											<Button
												variant="outlined"
												color="primary"
												name="edit_client_button"
												onClick={() => this.handleCreateorUpdateItem(this.state.client, false, 'Client')}
											>
												<EditIcon />Edit
											</Button>
										</Tooltip>
										<Tooltip title="Delete Client">
											<Button
												variant="outlined"
												color="secondary"
												name="delete_client_button"
												onClick={() => this.onOpenDeletePopUp()}
											>
												<DeleteIcon />Delete
											</Button>
										</Tooltip>
									</ButtonGroup>

								</Grid>
							</Grid>

							<div className={styles.contentBody}>
								<div className={styles.LeftBody}>
									<Tabs value={this.state.value} onChange={this.handleChange}
										textColor="primary"
										variant="scrollable"
										scrollButtons="auto"
										aria-label="scrollable auto tabs example"
										indicatorColor="primary" className={styles.tabBody}>
										<Tab className={styles.tabList} label={<div className={styles.tabListTitle}><PersonOutlineIcon /> <span className={styles.tabLabel}>Profile</span></div>}{...a11yProps(0)} />
										<Tab className={styles.tabList} label={<div className={styles.tabListTitle}><MenuBookIcon /> <span className={styles.tabLabel}>Cases</span></div>}{...a11yProps(1)} />
										<Tab className={styles.tabList} label={<div className={styles.tabListTitle}><CalendarTodayIcon /> <span className={styles.tabLabel}>Events</span></div>} {...a11yProps(2)} />
										<Tab className={styles.tabList} label={<div className={styles.tabListTitle}><ListIcon /> <span className={styles.tabLabel}>Tasks</span></div>} {...a11yProps(3)} />
									</Tabs>
									<TabPanel value={this.state.value} index={0}>
										<>
											<>
												{
													this.state.client.is_company ? (
														<div className={styles.tesss} >
															<ContactPersons contactPersons={this.state.contactPersons} test={this.state.client.is_company} initialContactPerson={this.state.initialContactPerson} handleClick={this.handleContactPerson} handleOpenDelete={this.handleOpenDelete} />
														</div>
													) : (
														<div className={styles.individualBody}>

															<Grid container spacing={1}>
																<Grid item xs={12} sm={6}>
																	<ContactNumbers contactNumbers={this.state.contactNumbers} initialContactNumber={this.state.initialNested} handleClick={this.handleCreateOrUpdateNested} handleOpenDelete={this.handleOpenDelete} />
																</Grid>
																<Grid item xs={12} sm={6}>
																	<EmailAddresses emailAddresses={this.state.emailAddresses} initialEmailAddress={this.state.initialNested} handleClick={this.handleCreateOrUpdateNested} handleOpenDelete={this.handleOpenDelete} />
																</Grid>
															</Grid>
														</div>
													)
												}
											</>
											<div className={styles.customFieldBody}>
												<div className={styles.customFieldName}>
													<div>
														<ViewListIcon fontSize="large" />
													</div>
													<div>
														<div className={styles.customFieldtitle}>Custom Fields :</div>
													</div>
												</div>
												{
													this.state.cfield === null ? (<div style={{ margin: '14px' }}>No Saved Data</div>) : (
														this.state.fields.length > 0 ? (
															this.state.fields.map((field) => (
																<div key={field.uid} className={styles.customFieldList}>
																	<div className={styles.customValue}>
																		<div className={styles.customLabelFied}>{this.capitalize(field.attr_name)} :&nbsp;&nbsp;</div> <div className={styles.customValueFied}>{this.setVal(field.attr_name)}</div>
																	</div>
																</div>
															))
														) : (
															null
														)
													)
												}
											</div>
										</>
									</TabPanel>
									<TabPanel value={this.state.value} index={1}>
										<LegalCases uid={this.state.client.uid} client={this.state.client} />
									</TabPanel>
									<TabPanel value={this.state.value} index={2}>
										<Events uid={this.state.client.uid} addButtonNone={true} />
									</TabPanel>
									<TabPanel value={this.state.value} index={3}>
										<Tasks uid={this.state.client.uid} addButtonNone={true} />
									</TabPanel>
								</div>

							</div>
							<SimplePopUp
								openPopup={this.state.openNestedForm}
								title={this.state.nestedTitle}
								handleClose={this.handleClose}
								maxWidth='sm'
							>
								<NestedForm handleSubmit={this.submitNested} handleChange={this.handleNestedChange} item={this.state.nestedItem} options={this.state.options} nestedModel={this.state.nestedModel} />
							</SimplePopUp>
							<SimplePopUp
								openPopup={this.state.openPopup}
								title="Edit Client"
								handleClose={this.handleClose}
								maxWidth={this.state.maxWidth}
							>
								<ClientForm submit={this.editClient} groups={this.state.groups} client={this.state.client} handleChange={this.handleInputChange}
									handleSwitch={this.handleSwitch} openGroupForm={this.openGroupForm} handleAutoComplete={this.handleAutoComplete} getGroup={this.getGroup}
									fields={this.state.fields} handleField={this.handleField} expanded={this.state.expanded} handleExpandClick={this.handleExpandClick}
									defaultGroup={this.state.defaultGroup} cfield={this.state.cfield} error={this.state.errorMessages}
								/>
							</SimplePopUp>

							<SimplePopUp
								openPopup={this.state.openGroup}
								title="Add Group"
								handleClose={this.handleCloseGroup}
								maxWidth='xs'
							>
								<GroupForm group={this.state.group} handleChange={this.handleGroupChange} addGroup={this.addGroup} />
							</SimplePopUp>

							<SimpleDeletePopUp
								openDeletePopup={this.state.openDeletePopup}
								item={this.state.nestedItem}
								delete={this.handleDeleteItem}
								handleDeleteClose={this.handleClose}
								model={this.state.nestedModel}
							/>

							<SimplePopUp
								openPopup={this.state.openContactPerson}
								title={this.state.title}
								handleClose={this.handleClose}
								maxWidth='md'
							>
								<ContactPersonForm contactPerson={this.state.contactItem} handleChange={this.handleContactPersonChange}
									contactNumbers={this.state.nestedContactNumbers} handleContactNumberChange={this.handleContactNumberChange}
									removeContactNumber={this.removeContactNumber}
									addContactNumber={this.addContactNumber}
									handleNumberChange={this.handleNumberChange}
									emailAddresses={this.state.nestedEmailAddresses} handleEmailAddressChange={this.handleEmailAddressChange}
									removeEmail={this.removeEmail} addEmailAddress={this.addEmailAddress}
									submitContact={this.submitContact} contactState={this.state.contactState}
								/>

							</SimplePopUp>
							<AlertMessage
								notify={this.state.notify}
								handleCloseAlert={this.handleCloseAlert}
								isOpen={this.state.isOpen}
								type={this.state.type}
								message={this.state.message}
							/>

							<SimpleDeletePopUp
								openDeletePopup={this.state.openClientDeletePopUp}
								item={this.state.client}
								delete={this.handleClientDelete}
								handleDeleteClose={this.handleClose}
								model={this.state.model}
							/>
						</div>

					) : (null)
				}
			</>
		)
	}
}
export default Client

Client.propTypes = {
	location: PropTypes.shape({
		state: PropTypes.object.isRequired
	}).isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			uid: PropTypes.string.isRequired
		})
	})

}
