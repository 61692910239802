import React from 'react'
import PropTypes from 'prop-types'
import {
  TableCell,
  Tooltip
} from '@material-ui/core'
import styles from '../../shared/SimpleTable/SimpleTable.module.css'
import ButtonGroup from "@material-ui/core/ButtonGroup";
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import VisibilityIcon from '@material-ui/icons/Visibility'
import IconButton from '@material-ui/core/IconButton'

class ActionButtons extends React.Component {
  
  render() {
    return (
      <>
          <TableCell onClick={this.props.onclick} size="small" className={this.props.model.toLowerCase() === 'client' ||
          this.props.model.toLowerCase() === 'case' ||
          this.props.model.toLowerCase() === 'workflow' ||
          this.props.model.toLowerCase() === 'document' ||
          this.props.model.toLowerCase() === 'invoice' ? styles.actionButtonHoverable : styles.fullWitdActionHoverable}
          align="right">
            <ButtonGroup size="small">
              {
                this.props.withShowPopUp ? (
                  <Tooltip title="Add Group" arrow>
                    <IconButton
                      id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_showpopup`}
                      name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_showpopup`}
                      size="small"
                      color="primary"
                      onClick={() => this.props.handleShowPopUp(this.props.item)}>
                      <VisibilityIcon color="primary"/>
                    </IconButton>
                  </Tooltip>
                ) : (null)
              }
              {this.props.item.can_edit === true ? (
                this.props.withEdit ? (
                  <Tooltip title="Edit" arrow>
                    <IconButton
                      size="small"
                      id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_edit`}
                      name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_edit`}
                      color="primary"
                      onClick={() => this.props.handleClick(this.props.item, false, this.props.model)}>
                      <EditIcon/>
                    </IconButton>
                  </Tooltip>
                ) : (null)) : null
              }
              {this.props.item.can_delete === true ? (
                this.props.model.toLowerCase() !== "client" && this.props.model.toLowerCase() !== "case" ? (
                  <Tooltip title="Delete" arrow>
                    <IconButton
                      id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_delete`}
                      name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_delete`}
                      size="small"
                      color="secondary"
                      onClick={() => this.props.onOpenDeletePopup(this.props.item, this.props.model)}
                    >
                      <DeleteIcon/>
                    </IconButton>
                  </Tooltip>
                ) : null
              ) : null}
            </ButtonGroup>
          </TableCell>
      </>
    )
  }
}

export default ActionButtons

ActionButtons.propTypes = {
  onchange: PropTypes.func,
  item: PropTypes.object,
  onOpenDeletePopup: PropTypes.func,
  withEdit: PropTypes.func,
  withShowPopUp: PropTypes.func,
  handleShowPopUp: PropTypes.func,
  handleClick: PropTypes.func,
  onclick: PropTypes.func,
  model: PropTypes.string,
}
