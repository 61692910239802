import React from 'react';
import axios from 'axios'
import SessionsForm from './SessionsForm';


class UnlockInstruction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unlock: {username: '', email: ''},
      formLabel: " Unlock your Account",
      message: "",
      type: "",
      isOpen: false,
    }
    this._handleKeyPress = this._handleKeyPress.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
  }

  componentDidMount () {
    const query = new URLSearchParams(location.search);
    if(query.get('unlock_token')) {
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/users/unlocks?unlock_token=' + query.get('unlock_token'),
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(() => {
        setTimeout(() => {
          location.href="/login"
        }, 1000, this.setState({ isOpen: true, message: 'Successfully account unlock', type: 'success' }))
      }).catch(() => {
        setTimeout(() => {
          location.href="/login"
        }, 1000, this.setState({ isOpen: true, message: 'Account is already unlock', type: 'error' }))
      })
    }
  }

  handleChange(e) {
    e.preventDefault()
    this.setState({
      unlock: {...this.state.unlock,[e.target.name]: e.target.value}
    })
  }

  validateForm() {
    return (
      this.state.unlock.username.length > 0 &&
      this.state.unlock.email.length > 0 
    );
  }

  _handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSubmit()
    }
  }

  handleSubmit () { 
    const item = this.state.unlock
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/users/unlocks',
      data: (item),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      setTimeout(() => {
        location.href="/login"
      }, 1500, this.setState({ isOpen: true, message: 'Verification email sent to your email', type: 'success' }))
    }).catch(error => {
      if (error.response.status === 404) { 
        this.setState({isOpen: true,message: "Invalid Username and/or Password",type: 'error'})
      }
    })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ isOpen: false })
  }

  render() {
    return (
      <>  
        <SessionsForm
          formLabel={this.state.formLabel}
          item={this.state.unlock}
          errors={this.state.errors}
          linkButtonOne="/login"
          linkButtonTwo="/forgot_password"
          linkLabelOne="Login"
          linkLabelTwo="Forgot Password?"
          labelForSubmit="Send unlock instructions"
          handleChange={this.handleChange}
          // handleSubmit={this.handleSubmit}
          validateForm={this.validateForm()}
          _handleKeyPress={this._handleKeyPress}
          handleCloseAlert={this.handleCloseAlert}
          isOpen={this.state.isOpen}
          type={this.state.type}
          message={this.state.message}
        />
      </>
    )
  }
}

export default UnlockInstruction
