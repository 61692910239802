import React from 'react'

import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Typography from '@material-ui/core/Typography'

import LayersIcon from '@material-ui/icons/Layers'
import LinearProgress from '@material-ui/core/LinearProgress'


class Checklist extends React.Component {

    render() {
        return (
            <>
                {
                    this.props.taskChildren.length > 0 ? (
                        <>
                            <Grid container spacing={1}>
                                <Grid xs={10}>
                                    <LinearProgress variant="determinate" value={this.props.getProgressValue()} style={{ height: 40, borderRadius: 5, width: '100%', marginLeft: 2 }} />
                                </Grid><Grid xs={2}><h2 style={{ marginLeft: 12 }}>{this.props.checkedItems.length}/{this.props.taskChildren.length}</h2></Grid></Grid>
                            <List>

                                {this.props.taskChildren.map((child) =>
                                (
                                    <>
                                        <ListItem key={child.uid} role={undefined} dense>
                                            <ListItemAvatar>
                                                <Avatar style={{ backgroundColor: 'white' }}><LayersIcon /></Avatar>
                                            </ListItemAvatar>
                                            <ListItemText id={`checkbox-list-label-${child.uid}`} style={{ fontSize: '16px', fontWeight: 'bold' }} primary={child.name} />
                                            <ListItemSecondaryAction>
                                                <Checkbox
                                                    style={{ paddingRight: '40px' }}
                                                    edge="start"
                                                    onClick={() => this.props.handleToggle(child.uid)}
                                                    checked={this.props.checkedItems.indexOf(child.uid) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': `checkbox-list-label-${child.uid}` }}
                                                    name={`checkbox`}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </>
                                )

                                )
                                }

                            </List>


                        </>
                    ) : (

                        this.props.doneTask.state === "ongoing" ? (<Typography>Do you want to mark this task as done?</Typography>
                        ) : (
                            <Typography>Do you want to mark this task as ongoing?</Typography>
                        )

                    )
                }
                <Button variant="outlined" color="primary" style={{ float: 'right', borderRadius: '20px' }}
                    onClick={() => this.props.markAsDone()}
                    name="task-done"
                >Done</Button>

                <br /><br />

            </>
        )
    }

}

export default Checklist

Checklist.propTypes = {
    taskChildren: PropTypes.array,
    checkedItems: PropTypes.array,
    getProgressValue: PropTypes.func,
    handleToggle: PropTypes.func,
    markAsDone: PropTypes.func,
    doneTask: PropTypes.object
}