import React from 'react'

import PropTypes from 'prop-types'
import styles from './LegalCase.module.css'

import {
  TextField,
  Button,Grid,MenuItem
} from '@material-ui/core'
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import sortArray from 'sort-array';
class SearchForm extends React.Component {



  render() {
    const {item} = this.props

    return (
      <div>
        <form>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <TextField
              autoComplete="off"
              id="standard-basic"
              variant="outlined"
              size="small"
              name="title"
              fullWidth
              label="Title"
              value={item.title}
              onChange={this.props.handleChangeSearch}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              select
              autoComplete="off"
              variant="outlined"
              size="small"
              name="state"
              fullWidth={true}
              value={item.state}
              onChange={this.props.handleChangeSearch}
              label="Status"
            >
             {['active', 'done', 'pending'].map((name) => (
                 <MenuItem key={name} value={name}>
                   {name}
                 </MenuItem>
            ))}
          </TextField>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              autoComplete="off"
              id="standard-basic"

              variant="outlined"
              size="small"
              name="reference_no"
              fullWidth
              label="Reference No."
              value={item.reference_no}
              onChange={this.props.handleChangeSearch}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              autoComplete="off"
              id="practice_area_uid"
              fullWidth
              value={this.props.item.practice_area_uid}
              size="small"
              options={sortArray(this.props.practiceAreas,{by: 'name'})}
              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={this.props.handleSearchPracticeArea}

              renderInput={(params) => <TextField {...params} label="Practice Area" variant="outlined" />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              autoComplete="off"
              id="user_uids"
              value={this.props.item.user_uids}
              size="small"
              fullWidth
              options={this.props.users}
              getOptionLabel={(option) => option.username || ''}
							getOptionSelected={(option, value) => option.username === value.username}
              onChange={this.props.handleSearchUser}

              renderInput={(params) => <TextField {...params} label="User Name" variant="outlined" />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="client_uids"

              value={this.props.item.client_uids}
              autoComplete="off"
              size="small"
              fullWidth
              options={this.props.clients}
              getOptionLabel={(option) => option.name}
              onChange={this.props.handleSearchClient}
              renderInput={(params) => <TextField {...params} label="Client name" variant="outlined" />}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              id="standard-basic"
              type="date"

              variant="outlined"
              size="small"
              name="starts_on_from"
              fullWidth
              label="Date Start from"
              value={item.starts_on_from}
              onChange={this.props.handleChangeSearch}
              InputLabelProps={{
                shrink: true
              }}
            />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="off"
                id="standard-basic"
                type="date"

                variant="outlined"
                size="small"
                name="starts_on_to"
                fullWidth
                label="Date Start to"
                value={item.starts_on_to}
                onChange={this.props.handleChangeSearch}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

          </Grid>
        </form>
        <div className={styles.actionSearchButton}>
          <Button
            className={styles.search}
            variant="outlined"
            color="primary"
            onClick={() => this.props.handleSearch()}
          >
            Search
          </Button>
        </div>

      </div>
    )
  }
}

export default SearchForm

SearchForm.propTypes = {
  handleSearch: PropTypes.func,
  handleFormReset: PropTypes.func,
  handleChangeSearch: PropTypes.func,
  handleAutoComplete: PropTypes.func,
  item: PropTypes.object,
  users: PropTypes.array,
  clients: PropTypes.array,
  practiceAreas: PropTypes.object,
  handleSearchPracticeArea: PropTypes.func,
  handleSearchClient: PropTypes.func,
  handleSearchUser: PropTypes.func
}
