import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, Checkbox, Table, TableBody, TableCell, TableRow,
} from '@material-ui/core'
import styles from "../LegalCase.module.css";
import { PeopleAlt } from '@material-ui/icons';

class DocumentAccessForm extends React.Component {

  checkIfinArray(userId){
    var selectedUserIds = []
    this.props.selectedUsers.length > 0 ?
      this.props.selectedUsers.map(user => {
        selectedUserIds.push(user.id)
      }) : null
    return selectedUserIds.length>0 ? selectedUserIds.includes(userId) : false
  }

  render() {

    return (
      <div>
        <form>
          {
            <Table>
              <TableBody>
                {
                  this.props.users.length > 0 ?
                  this.props.users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell className={styles.cell}><PeopleAlt /></TableCell>
                      <TableCell className={styles.cell}>{user.last_name}, {user.first_name}</TableCell>
                      <TableCell className={styles.cell}>
                        <Checkbox
                          //checked={this.props.selectedUsers.find(selectedUser => selectedUser.uid === user.uid) ? true : false}
                          checked={this.props.document.user_uids.includes(user.uid) ? true : false}
                          onChange={(e) => {
                            this.props.handleChange(e, user)
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )) : null
                }
              </TableBody>
            </Table>
          }
        </form>
        <div className={styles.actionButton}>
          <Button variant="outlined"
            color="primary"
            onClick={() => this.props.handleSubmitDocuments()}
          >Save</Button>
        </div>
      </div>
    )
  }
}

export default DocumentAccessForm

DocumentAccessForm.propTypes = {
  onFileChange: PropTypes.func,
  document: PropTypes.object,
  children: PropTypes.object,
  handleSubmitDocuments: PropTypes.func,
  error: PropTypes.func,
  handleFileInputChange: PropTypes.func,
  handleUsersAutocomplete: PropTypes.func,
  users: PropTypes.array,
  selectedUsers: PropTypes.array,
  handleChange: PropTypes.func,
  selectedUserIds: PropTypes.array,
}
