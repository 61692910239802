import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Box,
  ButtonGroup,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import styles from "./Workflow.module.css";

class TriggerTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  loadTriggers() {}

  render() {
    const { triggers, handleCreateorUpdateTrigger, handleOpenDeleteTrigger } =
      this.props;

    return (
      <Paper>
        <Box className={styles.tableHeader}>
          <Typography className={styles.tableHeaderTitle} variant="h2">
            Triggers
          </Typography>
          <IconButton
            onClick={() => handleCreateorUpdateTrigger(null, "create")}
          >
            <AddCircleOutlineIcon fontSize="large" color="inherit" />
          </IconButton>
        </Box>
        <Table>
          <TableHead className={styles.tableColumnTitle}>
            <TableRow>
              <TableCell width="10%">No.</TableCell>
              <TableCell width="80%">Name</TableCell>
              <TableCell width="10%" align="right">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={styles.tableBody}>
            {triggers && triggers.length ? (
              triggers.map((trigger, idx) => {
                const { uid, name } = trigger;

                return (
                  <TableRow key={uid}>
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell align="right">
                      <ButtonGroup className={styles.actionButtonGroup}>
                        <IconButton
                          onClick={() =>
                            handleCreateorUpdateTrigger(trigger, "edit")
                          }
                        >
                          <EditIcon fontSize="small" color="primary" />
                        </IconButton>
                        <IconButton
                          onClick={() => handleOpenDeleteTrigger(trigger)}
                        >
                          <DeleteIcon fontSize="small" color="secondary" />
                        </IconButton>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell>No Records.</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default TriggerTable;

TriggerTable.propTypes = {
  triggers: PropTypes.array || null,
  handleCreateorUpdateTrigger: PropTypes.func,
  handleOpenDeleteTrigger: PropTypes.func,
};
