import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import WorkOutlineIcon from '@material-ui/icons/WorkOutline'
import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import NewAndEditForm from './NewAndEditForm'
import ProgressBar from '../../shared/ProgressBar/ProgressBar'
import PracticeAreaForm from './PracticeAreaForm'
import SearchForm from './SearchForm'
import AlertMessage from '../../shared/Notify/AlertMessage'
import ClientForm from '../Client/ClientForm'
import ErrorPage from '../../shared/ErrorPage/ErrorPage'
import GroupForm from '../Group/GroupForm'
import { Box } from '@material-ui/core'
import styles from './LegalCase.module.css'

export const Component = withRouter(() => {})

class LegalCases extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Case',
      legalCases: [],
      draftLegalCases: [],
      openPopup: false,
      openDeletePopup: false,
      legalCase: {},
      users: [],
      practiceAreas: [],
      defaultBiller: [],
      clients: [],
      cfield: {},
      practice: {},
      fields: [],
      openPracticeArea: false,
      expanded: false,

      openPopupSearch: false,
      selectedArea: '',
      checkedUsers: [localStorage.getItem('current_user_id')],
      initialLegalCase: {
        uid: '',
        reference_no: '',
        title: '',
        date_filed: new Date().toISOString().substring(0, 10),
        practice_area_id: '',
        user_ids: [localStorage.getItem('current_user_id')],
        biller_id: '',
        description: '',
        client_ids: [],
        fields: {}
      },
      removeAllSearchField: [],
      searchFormField: {
        reference_no: '',
        title: '',
        date_on_to: '',
        date_on_from: '',
        practice_area_uid: '',
        user_uids: '',
        client_uids: '',
        biller_id: ''
      },
      q: '',
      title: '',
      defaultArea: {},
      load: false,
      tableHead: [
        'Reference_no',
        'Title',
        'user_uids',
        'client_uids',
        'practice_area_uid',
        'state',
        'date_filed'
      ],
      withShow: true,
      withEdit: true,
      withSearch: true,
      error_messages: '',
      isOpen: false,
      message: '',
      type: '',
      withPagination: false,
      totalPages: '',
      currentPage: null,
      maxWidth: 'md',
      opposingCounsel: [],
      selectedCounsel: [],
      searchUrl: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases',
      errorMessages: {},
      openClientform: false,
      // client: {},
      addresses: [{ content: '', category: 0 }],
      address: { content: '', category: 0 },
      emailAddress: { content: '', category: 0 },
      contactNumber: { content: '', category: 0 },
      contactNumbers: [{ content: '', category: 0 }],
      emailAddresses: [{ content: '', category: 0 }],
      contactPerson: { name: '', designation: '', department: '', remarks: '' },
      contactPersons: [
        { name: '', designation: '', department: '', remarks: '' }
      ],
      groups: [],
      client: {},
      defaultGroup: {},
      clientcfield: {},
      clientfields: [],
      clientExpanded: false,
      clientErrorMessages: {},
      loadLegalCasesError: null,
      openGroupsForm: false,
      group: {}
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleAutoComplete = this.handleAutoComplete.bind(this)
    this.handleExpandClick = this.handleExpandClick.bind(this)
    this.handleField = this.handleField.bind(this)
    this.handleClosePracticeArea = this.handleClosePracticeArea.bind(this)
    this.handlePracticeAreaChange = this.handlePracticeAreaChange.bind(this)
    this.addPracticeArea = this.addPracticeArea.bind(this)
    this.openPracticeAreaForm = this.openPracticeAreaForm.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleChangeQ = this.handleChangeQ.bind(this)
    this.handleAdvanced = this.handleAdvanced.bind(this)
    this.handleFormReset = this.handleFormReset.bind(this)
    this.handleChangeSearch = this.handleChangeSearch.bind(this)
    this.handleCloseSearch = this.handleCloseSearch.bind(this)
    this.handleSearchAutoComplete = this.handleSearchAutoComplete.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.isFormValid = this.isFormValid.bind(this)
    this.handleOpposingCounsel = this.handleOpposingCounsel.bind(this)
    this.generateRefNo = this.generateRefNo.bind(this)
    this.handleClientPopup = this.handleClientPopup.bind(this)
    this.handleClientChange = this.handleClientChange.bind(this)
    this.handleSubmitClient = this.handleSubmitClient.bind(this)
    this.handleAddressChange = this.handleAddressChange.bind(this)
    this.handleEmailAddressChange = this.handleEmailAddressChange.bind(this)
    this.handleContactNumberChange = this.handleContactNumberChange.bind(this)
    this.addAddress = this.addAddress.bind(this)
    this.addEmailAddress = this.addEmailAddress.bind(this)
    this.addContactNumber = this.addContactNumber.bind(this)
    this.removeAddress = this.removeAddress.bind(this)
    this.removeEmail = this.removeEmail.bind(this)
    this.removeContactNumber = this.removeContactNumber.bind(this)
    this.handleNumberChange = this.handleNumberChange.bind(this)
    this.handleClientAutoComplete = this.handleClientAutoComplete.bind(this)
    this.handleContactPersonChange = this.handleContactPersonChange.bind(this)
    this.addContactPerson = this.addContactPerson.bind(this)
    this.removeContactPerson = this.removeContactPerson.bind(this)

    this.handleClientField = this.handleClientField.bind(this)
    this.handleClientSwitch = this.handleClientSwitch.bind(this)
    this.handleClientClose = this.handleClientClose.bind(this)
    this.handleClientExpanded = this.handleClientExpanded.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.handleSearchPracticeArea = this.handleSearchPracticeArea.bind(this)
    this.handleSearchUser = this.handleSearchUser.bind(this)
    this.handleSearchClient = this.handleSearchClient.bind(this)
    this.handleOpenGroupForm = this.handleOpenGroupForm.bind(this)
    this.handleCloseGroups = this.handleCloseGroups.bind(this)
    this.handleGroupInputChange = this.handleGroupInputChange.bind(this)
    this.handleGroupSubmit = this.handleGroupSubmit.bind(this)
  }

  handleClientClose() {
    this.setState({ openClientform: false })
  }

  handleClientPopup() {
    this.setState({
      openClientform: true,
      client: {
        uid: '',
        name: '',
        group_id: '',
        is_company: false,
        is_active: true,
        is_taxable: false,
        fields: {}
      }
    })
  }

  handleClientSwitch(event) {
    const value = event.target.checked
    this.setState({
      client: {
        ...this.state.client,
        [event.target.name]: value
      }
    })
  }
  removeAddress(idx) {
    this.state.addresses.splice(idx, 1)
    this.setState({ addresses: [...this.state.addresses] })
  }

  handleAddressChange(e) {
    const updatedAddress = [...this.state.addresses]
    updatedAddress[e.target.dataset.id][e.target.dataset.fieldType] =
      e.target.value
    this.setState({ addresses: updatedAddress })
  }

  addAddress() {
    this.setState({
      addresses: [...this.state.addresses, { ...this.state.address }]
    })
  }

  removeEmail(idx) {
    this.state.emailAddresses.splice(idx, 1)
    this.setState({ emailAddresses: [...this.state.emailAddresses] })
  }

  handleEmailAddressChange(e) {
    const updatedEmailAddress = [...this.state.emailAddresses]
    updatedEmailAddress[e.target.dataset.id][e.target.dataset.fieldType] =
      e.target.value
    this.setState({ emailAddresses: updatedEmailAddress })
  }

  addEmailAddress() {
    this.setState({
      emailAddresses: [
        ...this.state.emailAddresses,
        { ...this.state.emailAddress }
      ]
    })
  }

  removeContactNumber(idx) {
    this.state.contactNumbers.splice(idx, 1)
    this.setState({ contactNumbers: [...this.state.contactNumbers] })
  }

  handleNumberChange(e) {
    const re = /^[0-9\b]+$/
    if (e.target.value === '' || re.test(e.target.value)) {
      const updatedContactNumbers = [...this.state.contactNumbers]
      updatedContactNumbers[e.target.dataset.id][e.target.dataset.fieldType] =
        e.target.value
      this.setState({ contactNumbers: updatedContactNumbers })
    }
  }

  handleContactNumberChange(e) {
    const updatedContactNumbers = [...this.state.contactNumbers]
    updatedContactNumbers[e.target.dataset.id][e.target.dataset.fieldType] =
      e.target.value
    this.setState({ contactNumbers: updatedContactNumbers })
  }

  addContactNumber() {
    this.setState({
      contactNumbers: [
        ...this.state.contactNumbers,
        { ...this.state.contactNumber }
      ]
    })
  }

  removeContactPerson(idx) {
    this.state.contactPersons.splice(idx, 1)
    this.setState({ contactPersons: [...this.state.contactPersons] })
  }

  handleContactPersonChange(e) {
    const updatedContactPersons = [...this.state.contactPersons]
    updatedContactPersons[e.target.dataset.id][e.target.dataset.fieldType] =
      e.target.value
    this.setState({ contactPersons: updatedContactPersons })
  }

  addContactPerson() {
    this.setState({
      contactPersons: [
        ...this.state.contactPersons,
        { ...this.state.contactPerson }
      ]
    })
  }

  handleSubmitClient() {
    var item = {}
    item['name'] = this.state.client.name
    item['uid'] = this.state.client.uid
    item['group_id'] = this.state.defaultGroup.uid
    item['is_active'] = this.state.client.is_active
    item['is_company'] = this.state.client.is_company
    item['is_taxable'] = this.state.client.is_company
    item['fields'] = this.state.client.fields
    if (this.state.client.is_company) {
      item['contact_persons_attributes'] = this.state.contactPersons
      item['addresses_attributes'] = this.state.addresses
    } else {
      item['addresses_attributes'] = this.state.addresses
      item['email_addresses_attributes'] = this.state.emailAddresses
      item['contact_numbers_attributes'] = this.state.contactNumbers
    }
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/clients',
      data: item,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        const newData = resp.data

        if(newData.group.name === 'Opposing Party') {
          this.setState({
            opposingCounsel: [...this.state.opposingCounsel, newData],
            selectedCounsel: [...this.state.selectedCounsel, newData]
          })
        }
        
        this.setState({
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success'
        })

        this.setState({ clientErrorMessages: {} })
        this.setState({ errorMessages: {} })
        this.setState({ openClientform: false })
      })
      .catch((error) => {
        if (error.response.status === 422) {
          var errors = {}
          Object.keys(error.response.data.errors).map((field) => {
            errors[field] = error.response.data.errors[field][0]
            if (field.includes('.')) {
              errors[field.split('.')[1]] = error.response.data.errors[field][0]
            } else {
              errors[field] = error.response.data.errors[field][0]
            }
          })
          this.setState({ clientErrorMessages: errors })
        } else {
          this.setState({
            isOpen: true,
            message:
              error.response.status.toString() +
              ' Unexpected Error Problem Occurred',
            type: 'error'
          })
          this.setState({ openPopup: false })
        }
      })
  }

  handleClientChange(e) {
    this.setState({
      client: {
        ...this.state.client,
        [e.target.name]: e.target.value
      }
    })
  }

  handleSwitch(event) {
    const value = event.target.checked
    this.setState({
      client: {
        ...this.state.client,
        [event.target.name]: value
      }
    })
  }

  handleClientAutoComplete(event, values) {
    this.setState({ defaultGroup: values })
  }

  handleClientField(e) {
    const value = e.target.value
    this.setState({
      cfield: {
        ...this.state.cfield,
        [e.target.name]: value
      }
    })
    this.setState({
      client: {
        ...this.state.client,
        fields: JSON.stringify({
          ...this.state.cfield,
          [e.target.name]: value
        })
      }
    })
  }

  componentDidMount() {
    var searchUrl = this.state.searchUrl + this.props.location.search
    var searchParams = {}
    var perPage = ''
    const params = new URLSearchParams(this.props.location.search)

    params.forEach(function (value, key) {
      if (
        key !== 'page' &&
        key !== 'user_uids' &&
        key !== 'client_uids' &&
        key !== 'practice_area_uid'
      ) {
        searchParams[key] = value.replace(/\*/g, '')
      } else if (key === 'per_page') {
        perPage = value
      }
      if (key === 'user_uids' && value !== null) {
        axios({
          method: 'get',
          url:
            process.env.REACT_APP_API_DOMAIN +
            '/v1/users/' +
            JSON.parse(value.replace(/\*/g, '')),
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then((resp) => {
          searchParams['user_uids'] = resp.data
        })
      }
      if (key === 'client_uids' && value !== null) {
        axios({
          method: 'get',
          url:
            process.env.REACT_APP_API_DOMAIN +
            '/v1/client/' +
            JSON.parse(value.replace(/\*/g, '')),
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then((resp) => {
          searchParams['client_uids'] = resp.data
        })
      }
      if (key === 'practice_area_uid' && value !== null) {
        axios({
          method: 'get',
          url:
            process.env.REACT_APP_API_DOMAIN +
            '/v1/practice_areas/' +
            JSON.parse(value.replace(/\*/g, '')),
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then((resp) => {
          searchParams['practice_area_uid'] = resp.data
        })
      }
    })

    if (perPage) {
      this.setState({ perPage: perPage })
    }
    this.setState({ searchFormField: searchParams })
    this.loadLegalCases(searchUrl)
  }

  async loadLegalCases(url) {
    var requestUrl = url
    if (!url.includes('state=')) {
      if (url.includes('?')) {
        requestUrl = url + '&state=active'
      } else {
        requestUrl = url + '?state=active'
      }
      this.setState({
        searchFormField: { ...this.state.searchFormField, state: 'active' }
      })
    }
    await axios({
      method: 'get',
      url: requestUrl,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        this.setState({ legalCases: resp.data.data, openPopupSearch: false })
        this.setState({ username: resp.data.user_uids })
        this.setState({ removeAllSearchField: resp.data.data })
        this.setState({ totalPages: resp.data.meta.total_pages })
        this.setState({ currentPage: resp.data.meta.current_page })
      })
      .catch((error) =>
        this.setState({ loadLegalCasesError: error.response.status })
      )

    Promise.all([
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/members',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        this.setState({ users: resp.data })
      }),
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/practice_areas?per_page=10000',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        this.setState({ practiceAreas: resp.data.data })
      }),
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/custom_fields',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        this.setState({ fields: resp.data })
      }),
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/clients',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        this.setState({ clients: resp.data.data })
        var counsel = resp.data.data.filter(
          (item) => item.group.name === 'Opposing Party'
        )
        this.setState({ opposingCounsel: counsel })
      }),
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        this.setState({ draftLegalCases: resp.data.data })
        this.setState({ load: true })
      }),
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/groups',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        if (
          resp.data.data.filter((item) => item.name === 'Opposing Party')
            .length > 0
        ) {
          this.setState({
            defaultGroup: resp.data.data.filter(
              (item) => item.name === 'Opposing Party'
            )[0]
          })
        }
        this.setState({ groups: resp.data.data.filter((item) => item.name === 'Opposing Party') })
      }),
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/custom_fields',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        this.setState({ clientfields: resp.data })
      })
    ]).catch((error) =>
      this.setState({ loadLegalCasesError: error.response ? error.response.status : 'Error Occured!' })
    )
  }

  handleClientExpanded() {
    this.setState({ clientExpanded: !this.state.clientExpanded })
  }

  handleSubmit() {
    const item = this.state.legalCase
    console.log(item)
    if (this.state.selectedCounsel.length > 0) {
      var ids = []
      this.state.selectedCounsel.map((item) => {
        ids.push(item.uid)
      })
      item['counsel_ids'] = ids
    }
    var method = ''
    var url = ''
    if (item.uid === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + item.uid
    }
    axios({
      method: method,
      url: url,
      data: item,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        const newData = resp.data
        this.setState({
          legalCases: this.state.legalCases.filter(
            (legalCase) => legalCase.uid !== item.uid
          )
        })
        this.setState({ legalCases: [...this.state.legalCases, newData] })
        this.setState({
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success'
        })
        this.setState({
          openPopup: false,
          error_messages: '',
          errorMessages: {}
        })
      })
      .catch((error) => {
        if (error.response.status === 422) {
          var errors = {}
          Object.keys(error.response.data.errors).map((field) => {
            errors[field] = error.response.data.errors[field][0]
            if (field.includes('.')) {
              errors[field.split('.')[1]] = error.response.data.errors[field][0]
            } else {
              errors[field] = error.response.data.errors[field][0]
            }
          })
          this.setState({ errorMessages: errors })
        } else {
          this.setState({
            isOpen: true,
            message:
              error.response.status.toString() +
              ' Unexpected Error Problem Occurred',
            type: 'error'
          })
          this.setState({ openPopup: false })
        }
      })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.legalCase.uid
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then(() => {
        this.setState({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'error'
        })
        this.setState({ openDeletePopup: false })
      })
      .catch((error) => console.log(error.response))
  }

  handleClose() {
    this.setState({ openPopup: false })
    this.setState({ openDeletePopup: false })
    this.setState({ error_messages: '', errorMessages: {} })
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'New ' + model
      this.setState({ legalCase: item })
      this.generateRefNo()
    } else {
      textTitle = 'Edit ' + model
      this.setState({ legalCase: item })
    }
    this.setState({ openPopup: true })
    this.setState({ title: textTitle })
  }

  handleInputChange(e) {
    this.setState({
      legalCase: {
        ...this.state.legalCase,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ legalCase: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  isFormValid() {
    const { user_ids, client_ids } = this.state.legalCase
    if (user_ids.length > 0 && client_ids.length > 0) {
      return user_ids && client_ids
    }
  }

  handleAutoComplete(event, values) {
    if (event.target.id.includes('user_name')) {
      this.setState(values)
      const user_ids = []
      values.map((value) => {
        user_ids.push(value.uid)
      })

      this.setState({
        legalCase: {
          ...this.state.legalCase,
          user_ids: user_ids
        }
      })
    } else if (event.target.id.includes('client_name')) {
      const client_ids = []
      values.map((value) => {
        client_ids.push(value.uid)
      })
      this.setState({ defaultBiller: values })
      this.setState({
        legalCase: {
          ...this.state.legalCase,
          client_ids: client_ids
        }
      })
    } else if (event.target.id.includes('biller_name')) {
      if (values) {
        this.setState({
          legalCase: {
            ...this.state.legalCase,
            biller_id: values.uid
          }
        })
      }
    } else {
      if (values === null) {
        return this.state.practiceAreas
      } else {
        this.setState({ defaultArea: values })
        this.setState({
          legalCase: {
            ...this.state.legalCase,
            practice_area_id: values.uid
          }
        })
      }
    }
  }

  handlePracticeAreaChange(e) {
    this.setState({
      practice: {
        ...this.state.practice,
        [e.target.name]: e.target.value
      }
    })
  }

  handleExpandClick() {
    this.setState({ expanded: !this.state.expanded })
  }

  handleField(e) {
    const value = e.target.value
    this.setState({
      cfield: {
        ...this.state.cfield,
        [e.target.name]: value
      }
    })
    this.setState({
      legalCase: {
        ...this.state.legalCase,
        fields: JSON.stringify({
          ...this.state.cfield,
          [e.target.name]: value
        })
      }
    })
  }

  handleClosePracticeArea() {
    this.setState({ openPracticeArea: false })
  }

  addPracticeArea() {
    const item = this.state.practice

    axios({
      method: 'post',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/practice_areas/',
      data: item,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        var newData = resp.data
        this.setState({
          practiceAreas: this.state.practiceAreas.filter(
            (practice) => practice.uid !== item.uid
          )
        })
        this.setState({ practiceAreas: [...this.state.practiceAreas, newData] })
        this.setState({
          legalCase: {
            ...this.state.legalCase,
            practice_area_id: resp.data.uid
          }
        })
        this.setState({ defaultArea: resp.data })

        this.setState({ openPracticeArea: false })
      })
      .catch((error) => console.log(error.response))
  }

  openPracticeAreaForm() {
    this.setState({ practice: { uid: '', name: '' } })
    this.setState({ openPracticeArea: true })
  }

  handleAdvanced() {
    this.setState({ openPopupSearch: true })
  }

  handleChangeQ(event) {
    this.setState({ q: event.target.value })
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      if (this.state.q === '') {
        console.log(this.state.q)
        this.setState({ legalCases: this.state.draftLegalCases })
      } else {
        axios({
          method: 'get',
          url:
            process.env.REACT_APP_API_DOMAIN +
            '/v1/legal_cases?q=' +
            this.state.q,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then((resp) => {
          this.setState({ legalCases: resp.data.data })
        })
      }
      const params = new URLSearchParams()
      if (this.state.q === '') {
        this.props.history.push({ search: '' })
      } else {
        params.append('q', this.state.q)
        this.props.history.push({ search: params.toString() })
      }
    }
  }

  handleSearch() {
    const { history } = this.props
    var searchParams = this.getSearchParams().join('&')
    const params = new URLSearchParams()
    params.append('/search', searchParams)
    history.push({ search: searchParams })
    this.loadLegalCases([this.state.searchUrl, searchParams].join('?'), false)
  }

  getSearchParams() {
    var searchParams = []
    Object.entries(this.state.searchFormField).map(([key, value]) => {
      if (
        value != '' &&
        value != undefined &&
        value != null &&
        key !== 'user_uids' &&
        key !== 'practice_area_uid' &&
        key !== 'client_uids'
      ) {
        searchParams.push(
          [key, key === 'title' ? '*' + value + '*' : value].join('=')
        )
      }
      if (
        key === 'user_uids' &&
        this.state.searchFormField.user_uids &&
        value != null
      ) {
        searchParams.push(
          'user_uids=' +
            JSON.stringify(this.state.searchFormField.user_uids.uid)
        )
      }
      if (
        key === 'practice_area_uid' &&
        this.state.searchFormField.practice_area_uid &&
        value != null
      ) {
        searchParams.push(
          'practice_area_uid=' +
            JSON.stringify(this.state.searchFormField.practice_area_uid.uid)
        )
      }
      if (
        key === 'client_uids' &&
        this.state.searchFormField.client_uids &&
        value != null
      ) {
        searchParams.push(
          'client_uids=' +
            JSON.stringify(this.state.searchFormField.client_uids.uid)
        )
      }
    })

    return searchParams
  }

  handleCloseSearch() {
    this.setState({ openPopupSearch: false })
  }

  handleFormReset() {
    this.setState({
      searchFormField: {
        title: '',
        reference_no: '',
        practice_area_uid: '',
        starts_on_from: '',
        starts_on_to: '',
        user_uids: '',
        client_uids: ''
      }
    })
  }

  handleChangeSearch(e) {
    this.setState({
      searchFormField: {
        ...this.state.searchFormField,
        [e.target.name]: e.target.value
      }
    })
  }

  handleSearchAutoComplete(event, values) {
    if (event.target.id.includes('user_uids')) {
      this.setState({
        searchFormField: {
          ...this.state.searchFormField,
          user_uids: values
        }
      })
    } else if (event.target.id.includes('client_uids')) {
      this.setState({
        searchFormField: {
          ...this.state.searchFormField,
          client_uids: values
        }
      })
    } else if (event.target.id.includes('practice_area_uid')) {
      this.setState({
        searchFormField: {
          ...this.state.searchFormField,
          practice_area_uid: values
        }
      })
    }
  }
  handleSearchPracticeArea(event, values) {
    this.setState({
      searchFormField: {
        ...this.state.searchFormField,
        practice_area_uid: values
      }
    })
  }
  handleSearchClient(event, values) {
    this.setState({
      searchFormField: {
        ...this.state.searchFormField,
        client_uids: values
      }
    })
  }
  handleSearchUser(event, values) {
    this.setState({
      searchFormField: {
        ...this.state.searchFormField,
        user_uids: values
      }
    })
  }

  clearSearch() {
    const { history } = this.props
    history.push({ search: '' })
    this.setState({ searchFormField: {} })
    this.setState({ q: '' })
    this.setState({ legalCases: this.state.draftLegalCases })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    this.setState({
      isOpen: false
    })
  }

  handlePageChange(event, value) {
    var searchParams = this.getSearchParams()
    searchParams.push(['page', value].join('='))
    searchParams = searchParams.join('&')
    const { history } = this.props
    const params = new URLSearchParams()
    params.append('/search', searchParams)
    history.push({ search: searchParams })
    this.setState({ currentPage: value })
    this.loadLegalCases([this.state.searchUrl, searchParams].join('?'))
  }

  handleOpposingCounsel(event, value) {
    this.setState({ selectedCounsel: value })
  }

  generateRefNo() {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/generate_ref_no',
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then((resp) => {
      this.setState({
        legalCase: {
          ...this.state.legalCase,
          reference_no: this.padFix(resp.data.reference_no)[0]
        }
      })
    })
  }

  padFix(n) {
    return ('000' + n.toString()).match(/\S{4}$/)
  }

  handleOpenGroupForm() {
    this.setState({
      openGroupsForm: true
    })
  }

  handleCloseGroups() {
    this.setState({
      openGroupsForm: false
    })
  }

  handleGroupInputChange(e) {
    this.setState({
      group: {
        ...this.state.group,
        [e.target.name]: e.target.value
      }
    })
  }

  handleGroupSubmit() {
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_DOMAIN}/v1/groups/`,
      data: this.state.group,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        const newData = resp.data
        this.setState({ groups: [...this.state.groups, newData] })
        this.setState({ openPopup: false, openGroupsForm: false })
        this.setState({
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success'
        })
      }).catch((error) => {
        if (error.response.status === 422) {
          this.setState({
            error_messages: 'Name ' + error.response.data.errors.name[0]
          })
        } else {
          this.setState({
            isOpen: true,
            message:
              error.response.status.toString() +
              ' Unexpected Error Problem Occurred',
            type: 'error'
          })
          this.setState({ openPopup: false, openGroupsForm: false })
        }
      })
  }

  render() {
    const { load, loadLegalCasesError } = this.state

    return (
      <>
        {load && !loadLegalCasesError ? (
          <>
            <Box className={styles.boxMain}>
              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialLegalCase}
                items={this.state.legalCases}
                model={this.state.model}
                history={history}
                clearSearch={this.clearSearch}
                headers={this.state.tableHead}
                users={this.state.users}
                clients={this.state.clients}
                withSearch={this.state.withSearch}
                practiceAreas={this.state.practiceAreas}
                withShow={this.state.withShow}
                q={this.state.q}
                withPagination={this.state.withPagination}
                currentPage={this.state.currentPage}
                totalPages={this.state.totalPages}
                handlePageChange={this.handlePageChange}
                icon={<WorkOutlineIcon fontSize="large" />}
                _handleKeyPress={this.handleKeyPress}
                handleChanges={this.handleChangeQ}
                handleAdvanced={this.handleAdvanced}
              />
            </Box>
            
            <SimplePopUp
              openPopup={this.state.openPopup}
              title={this.state.title}
              items={this.state.legalCases}
              handleClose={this.handleClose}
              maxWidth={this.state.maxWidth}
            >
              <NewAndEditForm
                item={this.state.legalCase}
                setCurrentUser={this.state.setCurrentUser}
                handleAutoComplete={this.handleAutoComplete}
                getDefaultValue={this.getDefaultValue}
                error={this.state.errorMessages}
                isFormValid={this.isFormValid}
                clients={this.state.clients}
                defaultBiller={this.state.defaultBiller}
                practiceAreas={this.state.practiceAreas}
                users={this.state.users}
                submit={this.handleSubmit}
                fields={this.state.fields}
                defaultArea={this.state.defaultArea}
                openPracticeAreaForm={this.openPracticeAreaForm}
                cfield={this.state.cfield}
                expanded={this.state.expanded}
                handleExpandClick={this.handleExpandClick}
                handleField={this.handleField}
                onchange={this.handleInputChange}
                handleOpposingCounsel={this.handleOpposingCounsel}
                opposingCounsel={this.state.opposingCounsel}
                selectedCounsel={this.state.selectedCounsel}
                handleClientPopup={this.handleClientPopup}
              />
            </SimplePopUp>

            <SimplePopUp
              openPopup={this.state.openPopupSearch}
              handleClose={this.handleCloseSearch}
              title="Advanced Search"
            >
              <SearchForm
                handleSearch={this.handleSearch}
                handleFormReset={this.handleFormReset}
                clients={this.state.clients}
                users={this.state.users}
                practiceAreas={this.state.practiceAreas}
                handleAutoComplete={this.handleSearchAutoComplete}
                handleChangeSearch={this.handleChangeSearch}
                item={this.state.searchFormField}
                handleSearchPracticeArea={this.handleSearchPracticeArea}
                handleSearchClient={this.handleSearchClient}
                handleSearchUser={this.handleSearchUser}
              />
            </SimplePopUp>

            <SimplePopUp
              openPopup={this.state.openClientform}
              handleClose={this.handleClientClose}
              title="Create Opposing Party"
              maxWidth="md"
            >
              <ClientForm
                error={this.state.clientErrorMessages}
                groups={this.state.groups}
                client={this.state.client}
                addresses={this.state.addresses}
                emailAddresses={this.state.emailAddresses}
                contactNumbers={this.state.contactNumbers}
                contactPersons={this.state.contactPersons}
                defaultGroup={this.state.defaultGroup}
                cfield={this.state.clientcfield}
                fields={this.state.clientfields}
                expanded={this.state.clientExpanded}
                submit={this.handleSubmitClient}
                handleChange={this.handleClientChange}
                handleSwitch={this.handleClientSwitch}
                handleAddressChange={this.handleAddressChange}
                removeAddress={this.removeAddress}
                addAddress={this.addAddress}
                handleEmailAddressChange={this.handleEmailAddressChange}
                removeEmail={this.removeEmail}
                addEmailAddress={this.addEmailAddress}
                handleContactNumberChange={this.handleContactNumberChange}
                removeContactNumber={this.removeContactNumber}
                addContactNumber={this.addContactNumber}
                handleNumberChange={this.handleNumberChange}
                handleContactPersonChange={this.handleContactPersonChange}
                removeContactPerson={this.removeContactPerson}
                addContactPerson={this.addContactPerson}
                openGroupForm={this.handleOpenGroupForm}
                handleAutoComplete={this.handleClientAutoComplete}
                handleExpandClick={this.handleClientExpanded}
                handleField={this.handleClientField}
              />
            </SimplePopUp>

            <SimplePopUp
              openPopup={this.state.openPracticeArea}
              title="Add Practice Area"
              handleClose={this.handleClosePracticeArea}
              maxWidth="xs"
            >
              <PracticeAreaForm
                practice={this.state.practice}
                handleChange={this.handlePracticeAreaChange}
                addPracticeArea={this.addPracticeArea}
              />
            </SimplePopUp>

            <SimplePopUp
              openPopup={this.state.openGroupsForm}
              title={'Create Group'}
              handleClose={this.handleCloseGroups}
            >
              <GroupForm
                error={this.state.error_messages}
                item={this.state.group}
                submit={this.handleGroupSubmit}
                onchange={this.handleGroupInputChange}
              />
            </SimplePopUp>

            <SimpleDeletePopUp
              openDeletePopup={this.state.openDeletePopup}
              item={this.state.legalCase}
              delete={this.handleDeleteItem}
              handleDeleteClose={this.handleClose}
              model={this.state.model}
            />
            <AlertMessage
              notify={this.state.notify}
              handleCloseAlert={this.handleCloseAlert}
              isOpen={this.state.isOpen}
              type={this.state.type}
              message={this.state.message}
            />
          </>
        ) : loadLegalCasesError ? (
          <ErrorPage type={loadLegalCasesError} />
        ) : (
          <ProgressBar />
        )}
      </>
    )
  }
}
export default withRouter(LegalCases)
LegalCases.propTypes = {
  history: PropTypes.string,
  location: PropTypes.string
}
