
import React from 'react'

import PropTypes from 'prop-types'

import styles from './Invoice.module.css'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from "@material-ui/core/TableContainer";
import Checkbox from '@material-ui/core/Checkbox'
import { MenuItem } from '@material-ui/core'

class SelectExistingTransactionForm extends React.Component {

	render() {
		return (
			<>
				{
					this.props.transMenuPopUp.length > 0 ? (
						<>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Grid container spacing={3}>
									<Grid item xs={2}>
										<Typography>Filter Selection by : </Typography>
									</Grid>
									<Grid item xs={2}>
										<TextField
											select
											size="small"
											fullWidth
											onChange={this.props.selectFilter}
											value={this.props.filterField}
											variant="outlined"
											name="filterField"
										>
											<MenuItem value="user_id">Users</MenuItem>
											<MenuItem value="name">Description</MenuItem>
										</TextField>
									</Grid>
									<Grid item xs={8}>
										{
											this.props.filterField === "name" ? (
												<div>
													<TextField
														size="small"
														name="filterSelection"
														onChange={this.props.handleSearch}
														value={this.props.filterSelection}
														fullWidth
														variant="outlined"
													/>
												</div>
											) : (
												<div>
													<TextField
														select
														size="small"
														name="filterSelection"
														onChange={this.props.handleSearch}
														value={this.props.filterSelection}
														fullWidth
														variant="outlined"
													>
														{
															this.props.members.length > 0 ? (
																this.props.members.map((user) => (
																	this.props.users.length > 0 ? (
																		this.props.users.map((userId) => (
																			userId === user.uid ? (

																				<MenuItem key={user.uid} value={user.uid}>{user.first_name}</MenuItem>
																			) : (null)
																		))
																	) : (null)

																))
															) : (null)
														}

													</TextField>
												</div>
											)
										}

									</Grid>
								</Grid>
							</div>
							<TableContainer style={{ maxHeight: '575px' }}>
								<Table size="small" stickyHeader aria-label="customized sticky table">
									<TableHead>
										<TableRow>
											<TableCell className={styles.cell} >Description</TableCell>
											<TableCell className={styles.cell} >User</TableCell>
											<TableCell className={styles.cell} >Transaction Type</TableCell>
											<TableCell className={styles.cell} >Date Filed</TableCell>
											<TableCell className={styles.cell} >Rate</TableCell>
											<TableCell className={styles.cell} >Duration</TableCell>
											<TableCell className={styles.cell} >Discount</TableCell>
											<TableCell className={styles.cell} >Amount</TableCell>
											<TableCell className={styles.cell} >
												<Checkbox
													onClick={(event) => this.props.selectAllExisting(event)}
													checked={this.props.selectedExisting.length === this.props.transMenuPopUp.length}
													name="selectAll"
												/>
											</TableCell>

										</TableRow>
									</TableHead>
									<TableBody>
										<>

											{this.props.transMenuPopUp.sort(
												(a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
											).reverse()
												.map((item, idx) => (
													item[this.props.filterField].toLowerCase().includes(this.props.filterSelection) &&
													<TableRow key={idx}>
														<TableCell className={styles.cell} ><strong>{item.name}</strong></TableCell>
														<TableCell className={styles.cell} >
															{
																this.props.members.length > 0 ? (
																	this.props.members.map((user) => (
																		user.uid === item.user_id ? (
																			<>{user.first_name}</>
																		) : (null)
																	))
																) : (null)
															}
														</TableCell>
														<TableCell className={styles.cell} >
															{
																this.props.expenseTypes.length > 0 ? (
																	this.props.expenseTypes.map((expense) => (
																		expense.uid === item.transaction_type_id ? (
																			<>{expense.name}</>
																		) : (null)
																	))
																) : (null)

															}
															{
																this.props.rebates.length > 0 ? (
																	this.props.rebates.map((expense) => (
																		expense.uid === item.transaction_type_id ? (
																			<>{expense.name}</>
																		) : (null)
																	))
																) : (null)

															}
														</TableCell>
														<TableCell className={styles.numberCell} ><Typography>{new Date(item.filed_at).toLocaleDateString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' })}</Typography></TableCell>
														<TableCell className={styles.numberCell} >{item.rate > 0 ? (parseFloat(item.rate).toLocaleString(navigator.language, { minimumFractionDigits: 2 })) : ("-")}</TableCell>
														<TableCell className={styles.numberCell} >{item.duration > 0 ? (item.duration) : ("-")}</TableCell>
														<TableCell className={styles.numberCell} >{item.discount > 0 ? (parseFloat(item.discount).toLocaleString() + '%') : ("0")}</TableCell>
														<TableCell className={styles.numberCell} >{parseFloat(item.amount).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell>

														<TableCell className={styles.cell} >
															<Checkbox
																checked={this.props.selectedExisting.indexOf(item.transaction_record_id) > -1}
																onClick={(event) => this.props.handleCheckExisting(event, item)}
																name={`selectExisting-${idx}`}
															/></TableCell>
													</TableRow>
												))

											}
										</>

									</TableBody>
								</Table>
							</TableContainer>
							<br />
							<Button name="submit-selected" variant="contained" color="primary" style={{ float: 'right' }} onClick={this.props.submitChecked}>SUBMIT</Button>
						</>
					) : ("All transactions are added to invoice details")
				}

			</>
		)
	}
}
export default SelectExistingTransactionForm

SelectExistingTransactionForm.propTypes = {
	submitChecked: PropTypes.func,
	handleCheckExisting: PropTypes.func,
	transMenuPopUp: PropTypes.array,
	selectFilter: PropTypes.func,
	filterField: PropTypes.string,
	filterSelection: PropTypes.string,
	handleSearch: PropTypes.func,
	users: PropTypes.array,
	members: PropTypes.array,
	expenseTypes: PropTypes.array,
	rebates: PropTypes.array,
	selectedExisting: PropTypes.array,
	selectAllExisting: PropTypes.func,
	selectExistingArray: PropTypes.array
}