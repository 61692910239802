import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import styles from "./Tasks.module.css";

class TaskInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      doneTasks: [],
      isTasksLoading: true,
      queryTasksHasError: false,
      invoices: [],
      isInvoicesLoading: true,
      queryInvoicesHasError: "",
      filterFrom: moment(new Date().setDate(1)).format("YYYY-MM-DD"),
      filterTo: moment(
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      ).format("YYYY-MM-DD"),
      taskIds: [],
      invoiceDate: moment(new Date()).format("YYYY-MM-DD"),
      linkedCasesTransactions: [],
      filteredTasks: [],
    };

    this.loadDoneTasks = this.loadDoneTasks.bind(this);
    this.loadInvoices = this.loadInvoices.bind(this);
    this.handleChangeFilterDates = this.handleChangeFilterDates.bind(this);
    this.getTaskTransactions = this.getTaskTransactions.bind(this);
    this.handleSubmitCreateInvoice = this.handleSubmitCreateInvoice.bind(this);
    this.handleTableRowClick = this.handleTableRowClick.bind(this);
    this.handleSubmitFilterTasks = this.handleSubmitFilterTasks.bind(this);
    this.filterTaskOnMount = this.filterTaskOnMount.bind(this);
  }

  componentDidMount() {
    this.loadDoneTasks();
  }

  loadDoneTasks() {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_DOMAIN}/v1/users/me/tasks?per_page=10000`,
      headers: { "X-APP-API-KEY": localStorage.getItem("api_key") },
    })
      .then((resp) => {
        this.setState({
          isTasksLoading: false,
          doneTasks: resp.data.data.filter(
            (task) => task.state === "done" && task.legal_case
          ),
          queryTasksHasError: false,
        });
        this.loadInvoices();
        this.filterTaskOnMount();
        this.getTaskTransactions();
      })
      .catch(() => {
        this.setState({
          isTasksLoading: false,
          doneTasks: [],
          queryTasksHasError: true,
        });
      });
  }

  loadInvoices() {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_DOMAIN}/v1/invoices?per_page=10000`,
      headers: { "X-APP-API-KEY": localStorage.getItem("api_key") },
    })
      .then((resp) => {
        this.setState({
          isInvoicesLoading: false,
          invoices: resp.data.data,
          queryInvoicesHasError: false,
        });
      })
      .catch(() => {
        this.setState({
          isInvoicesLoading: false,
          invoices: [],
          queryInvoicesHasError: true,
        });
      });
  }

  filterTaskOnMount() {
    const { doneTasks, filterFrom, filterTo } = this.state;
    this.setState({
      filteredTasks: doneTasks.filter(
        (task) =>
          new Date(task.ends_at) > new Date(filterFrom) &&
          new Date(task.ends_at) < new Date(filterTo)
      ),
    });
  }

  getTaskTransactions() {
    const { doneTasks } = this.state;
    const caseIds = doneTasks.reduce((accumulator, initialValue) => {
      if (!accumulator.includes(initialValue.legal_case.uid)) {
        return [...accumulator, initialValue.legal_case.uid];
      }
      return accumulator;
    }, []);
    const transactions = [];

    Promise.all(
      caseIds.map((caseId) => {
        return axios({
          method: "get",
          url: `${process.env.REACT_APP_API_DOMAIN}/v1/legal_cases/${caseId}/transactions`,
          headers: { "X-APP-API-KEY": localStorage.getItem("api_key") },
        }).then((resp) => {
          transactions.push(...resp.data);
        });
      })
    ).then(() => {
      this.setState({
        linkedCasesTransactions: transactions.filter(
          (transaction) => transaction.task_uid
        ),
      });
    });
  }

  handleChangeFilterDates(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleTableRowClick(uid) {
    this.setState((prevState) => ({
      taskIds: prevState.taskIds.includes(uid)
        ? prevState.taskIds.filter((id) => id !== uid)
        : [...prevState.taskIds, uid],
    }));
  }

  handleSubmitCreateInvoice(event) {
    event.preventDefault();
    const {
      doneTasks,
      invoices,
      taskIds,
      invoiceDate,
      linkedCasesTransactions,
    } = this.state;

    const lastInvoice = invoices.reduce((accumulator, initialValue) => {
      if (!accumulator.reference_no) return initialValue;

      return Number(accumulator.reference_no) >
        Number(initialValue.reference_no)
        ? accumulator
        : initialValue;
    }, {});

    const selectedTasks = doneTasks.filter((task) =>
      taskIds.includes(task.uid)
    );

    Promise.all(
      selectedTasks.map((task, idx) => {
        const taskTransaction = linkedCasesTransactions.find(
          (transaction) => transaction.task_uid === task.uid
        );
        const transaction = {
          transaction_record_id: taskTransaction.uid,
          transaction_type_id: taskTransaction.transaction_type_uid,
          amount: parseFloat(taskTransaction.amount),
          user_id: taskTransaction.user_uid,
          rate_type: taskTransaction.rate_type,
          discount: taskTransaction.discount,
          rate:
            taskTransaction.rate_type === "hourly" ? taskTransaction.rate : 0,
        };

        const invoice = {
          uid: "",
          reference_no: String(Number(lastInvoice.reference_no) + idx + 1),
          discount: 0,
          due_date: moment(
            new Date(new Date().setDate(new Date(invoiceDate).getDate() + 30))
          ).format("YYYY-MM-DD"),
          filed_at: moment(invoiceDate).format("YYYY-MM-DD"),
          paid_at: moment(invoiceDate).format("YYYY-MM-DD"),
        };

        return axios({
          method: "post",
          url: `${process.env.REACT_APP_API_DOMAIN}/v1/legal_cases/${task.legal_case.uid}/invoices`,
          data: invoice,
          headers: { "X-APP-API-KEY": localStorage.getItem("api_key") },
        }).then((resp) => {
          return axios({
            data: transaction,
            method: "post",
            url:
              process.env.REACT_APP_API_DOMAIN +
              "/v1/invoices/" +
              resp.data.uid +
              "/invoice_details",
            headers: {
              "X-APP-API-KEY": localStorage.getItem("api_key"),
            },
          })
            .then(() => "success")
            .catch(() => "fail");
        });
      })
    ).then((resp) => {
      const result = resp.every((string) => string === "success");

      this.props.handleOpenAlert(
        result ? "success" : "warning",
        result
          ? "Invoices created!"
          : "An error occured while creating invoice.."
      );

      this.props.togglePopupTaskInvoice();
    });
  }

  handleSubmitFilterTasks(event) {
    event.preventDefault();
    const { doneTasks, filterFrom, filterTo } = this.state;

    this.setState({
      filteredTasks: doneTasks.filter(
        (task) =>
          new Date(task.ends_at) > new Date(filterFrom) &&
          new Date(task.ends_at) < new Date(filterTo)
      ),
    });
  }

  render() {
    const {
      isTasksLoading,
      queryTasksHasError,
      isInvoicesLoading,
      queryInvoicesHasError,
      filterFrom,
      filterTo,
      linkedCasesTransactions,
      taskIds,
      invoiceDate,
      filteredTasks,
    } = this.state;
    const { users } = this.props;
    const taskIdsWithTransaction = linkedCasesTransactions.map(
      (transaction) => transaction.task_uid
    );
    const tasksWithTransaction = filteredTasks.filter((task) =>
      taskIdsWithTransaction.includes(task.uid)
    );

    return (
      <>
        {isInvoicesLoading || isTasksLoading ? (
          <LinearProgress />
        ) : queryInvoicesHasError || queryTasksHasError ? (
          "Error occured while fetching data.."
        ) : (
          <Box className={styles.taskInvoiceMainBox}>
            <Box className={styles.taskInvoiceFilterBox}>
              <Typography variant="body1">Filter by due date:</Typography>
              <form
                style={{ marginBottom: 0 }}
                onSubmit={this.handleSubmitFilterTasks}
              >
                <Box className={styles.taskInvoiceForm}>
                  <TextField
                    type="date"
                    variant="standard"
                    size="small"
                    format="yyyy-MM-dd"
                    label=""
                    name="filterFrom"
                    value={filterFrom}
                    onChange={this.handleChangeFilterDates}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <span>-</span>
                  <TextField
                    type="date"
                    variant="standard"
                    size="small"
                    format="yyyy-MM-dd"
                    label=""
                    name="filterTo"
                    value={filterTo}
                    onChange={this.handleChangeFilterDates}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <IconButton
                    aria-label="search"
                    type="submit"
                    size="small"
                    disabled={!filterTo || !filterFrom}
                  >
                    <SearchIcon
                      style={{
                        color: `${
                          !filterTo || !filterFrom ? "#e0e0e0" : "#ef8354"
                        }`,
                      }}
                    />
                  </IconButton>
                </Box>
              </form>
            </Box>
            <form
              onSubmit={this.handleSubmitCreateInvoice}
              className={styles.formContainer}
            >
              <Box className={styles.boxInvoiceDate}>
                <Typography variant="body1">Invoice Date:</Typography>
                <TextField
                  type="date"
                  variant="standard"
                  size="small"
                  format="yyyy-MM-dd"
                  label=""
                  name="invoiceDate"
                  value={invoiceDate}
                  onChange={(event) => {
                    if (event.target.value === "") {
                      return this.setState({
                        invoiceDate: moment(new Date()).format("YYYY-MM-DD"),
                      });
                    }
                    this.handleChangeFilterDates(event);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Typography variant="h1" className={styles.taskInvoiceTableTitle}>
                Tasks with Transaction
              </Typography>
              <Box className={styles.taskInvoiceTable}>
                <Table stickyHeader={true}>
                  <TableHead>
                    <TableRow className={styles.tableRowHead}>
                      <TableCell width={50}>
                        <Checkbox
                          checked={
                            taskIds.length === taskIdsWithTransaction.length
                          }
                          onChange={() => {
                            this.setState((prevState) => ({
                              taskIds:
                                prevState.taskIds.length ===
                                taskIdsWithTransaction.length
                                  ? []
                                  : taskIdsWithTransaction,
                            }));
                          }}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Assigned to</TableCell>
                      <TableCell>Due Date</TableCell>
                      <TableCell width={400}>Description</TableCell>
                      <TableCell>Linked Case</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tasksWithTransaction.length ? (
                      tasksWithTransaction.map((task, idx) => {
                        const {
                          uid,
                          name,
                          ends_at,
                          legal_case,
                          user_uids,
                          description,
                        } = task;

                        return (
                          <TableRow
                            key={idx}
                            className={styles.tableRowBody}
                            onClick={() => this.handleTableRowClick(uid)}
                          >
                            <TableCell>
                              <Checkbox
                                checked={this.state.taskIds.includes(uid)}
                                onChange={() => this.handleTableRowClick(uid)}
                                onClick={(event) => event.stopPropagation()}
                                color="primary"
                              />
                            </TableCell>
                            <TableCell>{name}</TableCell>
                            <TableCell>
                              {users
                                .filter((user) => user_uids.includes(user.uid))
                                .map(
                                  (user) =>
                                    `${user.first_name} ${user.last_name}`
                                )}
                            </TableCell>
                            <TableCell>
                              {moment(ends_at).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </TableCell>
                            <TableCell
                              style={{
                                maxWidth: "300px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {description}
                            </TableCell>
                            <TableCell>
                              {legal_case
                                ? legal_case.title
                                : "No linked case."}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5}>
                          All transactions are added to invoice details
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
              <Box style={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!this.state.taskIds.length}
                  type="submit"
                >
                  Create Invoice
                </Button>
              </Box>
            </form>
          </Box>
        )}
      </>
    );
  }
}

export default TaskInvoice;

TaskInvoice.propTypes = {
  users: PropTypes.array,
  togglePopupTaskInvoice: PropTypes.func,
  handleOpenAlert: PropTypes.func,
};
