import React from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import ReceiptIcon from '@material-ui/icons/Receipt'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import InvForm from './InvForm'
import InvoiceForm from './../LegalCases/Invoice/InvoiceForm'
import SelectExistingTransactionForm from './../LegalCases/Invoice/SelectExistingTransactionForm'
import TransactionForm from './../LegalCases/Transaction/TransactionForm'
import styles from './Invoices.module.css'
import SearchForm from './SearchForm'
import BulkInvoiceForm from './BulkInvoiceForm'
import AlertMessage from '../../shared/Notify/AlertMessage'
import ProgressBar from '../../shared/ProgressBar/ProgressBar'
import ExpenseTypeForm from '../LegalCases/Transaction/ExpenseTypeForm'
import ErrorPage from '../../shared/ErrorPage/ErrorPage'
import { Box } from '@material-ui/core'

export const Component = withRouter(() => {})

class Invoices extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Invoice',
      members: [],
      legalCases: [],
      bulkLegalCases: [],
      legalCase: {},
      defaultTransactionType: {},
      transactions: [],
      transaction: {},
      transactionMenu: [],
      expenseTypes: [],
      rebates: [],
      invoices: [],
      draftInvoices: [],
      selectExistingArray: [],
      openPopup: false,
      openDeletePopup: false,
      invoice: {},
      initialTransaction: {
        uid: '',
        name: '',
        rate: 0,
        rate_type: 'hourly',
        initialAmount: 0,
        amount: 0,
        discount: 0,
        transaction_type_id: '',
        user_id: '',
        remarks: '',
        duration: 0,
        is_deduction: false,
        filed_at: new Date().toLocaleDateString('fr-CA')
      },
      initialInvoice: {
        uid: '',
        reference_no: '',
        discount: 0,
        due_date: new Date(
          new Date().setDate(new Date().getDate() + 30)
        ).toLocaleDateString('fr-CA'),
        filed_at: new Date().toLocaleDateString('fr-CA'),
        paid_at: new Date().toLocaleDateString('fr-CA')
      },
      title: '',
      load: false,
      tableHead: [
        'reference_no',
        'legal_case.biller_uid',
        'legal_case.title',
        'filed_at',
        'due_date',
        'grand_total'
      ],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      transMenuPopUp: [],
      beforeDateFilter: '',
      endDateFilter: '',
      users: '',
      typeFilter: '',
      userFilter: '',
      filterSelection: '',
      showState: false,
      transactionTypePopUp: false,
      openTransactionForm: false,
      openSelectExistingTransaction: false,
      filterField: 'name',
      redirect: false,
      doneDetails: false,
      transactionTitle: '',
      openSearchPopUp: false,
      searchCase: {},
      q: '',
      redirectSearch: false,
      searchPath: '/invoices',
      openBulkInvoiceForm: false,
      biller: {},
      clients: [],
      caseFilter: {},
      selectedCases: [],
      selectedExisting: [],
      notify: {},
      isOpen: false,
      message: '',
      type: '',
      offset: 0,
      perPage: 1,
      currentPage: 0,
      bulkInvoiceDate: '',
      transactionTypeFormPopUp: false,
      expenseType: { is_deduction: false },
      newGroup: true,
      rebateParents: [],
      expenseParents: [],
      promiseAllError: null
    }
    this.changeIndex = this.changeIndex.bind(this)
    this.handleSubmitsub = this.handleSubmitsub.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleAutoComplete = this.handleAutoComplete.bind(this)
    this.setEndDateFilter = this.setEndDateFilter.bind(this)
    this.setTypeFilter = this.setTypeFilter.bind(this)
    this.setBeforeDateFilter = this.setBeforeDateFilter.bind(this)
    this.addTransaction = this.addTransaction.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.editTransaction = this.editTransaction.bind(this)
    this.removeTransaction = this.removeTransaction.bind(this)
    this.getTotalAmount = this.getTotalAmount.bind(this)
    this.getTotal = this.getTotal.bind(this)
    this.getTotalDiscount = this.getTotalDiscount.bind(this)
    this.getVat = this.getVat.bind(this)
    this.getGrandTotal = this.getGrandTotal.bind(this)
    this.removeFilter = this.removeFilter.bind(this)
    this.applyFilter = this.applyFilter.bind(this)
    this.setUserFilter = this.setUserFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.submitInvoiceDetails = this.submitInvoiceDetails.bind(this)
    this.handleCloseTransactionForms =
      this.handleCloseTransactionForms.bind(this)
    this.existingTransaction = this.existingTransaction.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.submitChecked = this.submitChecked.bind(this)
    this.handleCheckExisting = this.handleCheckExisting.bind(this)
    this.newTransaction = this.newTransaction.bind(this)
    this.handleTransactionType = this.handleTransactionType.bind(this)
    this.handleSubmitTransaction = this.handleSubmitTransaction.bind(this)
    this.transactionTestNumber = this.transactionTestNumber.bind(this)
    this.handleInputTransactionChange =
      this.handleInputTransactionChange.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.handleUserChange = this.handleUserChange.bind(this)
    this.handleDuration = this.handleDuration.bind(this)
    this.userRateChange = this.userRateChange.bind(this)
    this.discountChange = this.discountChange.bind(this)
    this.initialAmount = this.initialAmount.bind(this)
    this.selectFilter = this.selectFilter.bind(this)
    this.handleOpenAdvanceSearch = this.handleOpenAdvanceSearch.bind(this)
    this.searchInvoice = this.searchInvoice.bind(this)
    this.handleSearchAutocomplete = this.handleSearchAutocomplete.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this._handleKeyPress = this._handleKeyPress.bind(this)
    this.handleChangeQ = this.handleChangeQ.bind(this)
    this.getDiscount = this.getDiscount.bind(this)
    this.handleCreateBulkInvoice = this.handleCreateBulkInvoice.bind(this)
    this.filterCases = this.filterCases.bind(this)
    this.removeFilterCases = this.removeFilterCases.bind(this)
    this.handleCaseFilterChange = this.handleCaseFilterChange.bind(this)
    this.handleCaseFilterAutocomplete =
      this.handleCaseFilterAutocomplete.bind(this)
    this.createBulkInvoice = this.createBulkInvoice.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.selectAll = this.selectAll.bind(this)
    this.selectAllExisting = this.selectAllExisting.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
    this.handleBulkInvoiceDate = this.handleBulkInvoiceDate.bind(this)
    this.openCreateTransactionType = this.openCreateTransactionType.bind(this)
    this.closeTransactionType = this.closeTransactionType.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.handleExpenseTypeChange = this.handleExpenseTypeChange.bind(this)
    this.expenseTypeSubmit = this.expenseTypeSubmit.bind(this)
  }
  handlePageClick(e) {
    const selectedPage = e.selected
    const offset = selectedPage * this.state.perPage

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset
      },
      () => {
        this.loadInvoices(process.env.REACT_APP_API_DOMAIN + '/v1/invoices')
      }
    )
  }

  componentDidMount() {
    var url = ''
    if (this.props.location.search === '') {
      url = process.env.REACT_APP_API_DOMAIN + '/v1/invoices'
    } else {
      var searchItem = {}
      var textQ = ''
      const query = new URLSearchParams(this.props.location.search)
      if (query.get('legal_case_uid') !== null) {
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases',
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then((resp) => {
          resp.data.data.map((legalCase) => {
            if (legalCase.uid === query.get('legal_case_uid')) {
              searchItem['legalCase'] = legalCase
            }
          })
        })
      }

      if (query.get('starts_on_from') !== null) {
        searchItem['starts_on_from'] = query.get('starts_on_from')
      }
      if (query.get('starts_on_to') !== null) {
        searchItem['starts_on_to'] = query.get('starts_on_to')
      }

      if (query.get('q') !== null) {
        textQ = query.get('q')

        this.setState({ q: textQ.toString() })
        url =
          process.env.REACT_APP_API_DOMAIN +
          '/v1/invoices?q=' +
          JSON.stringify(query.get('q'))
      }

      if (
        query.get('legal_case_uid') !== null &&
        query.get('starts_on_from') !== null &&
        query.get('starts_on_to') !== null
      ) {
        url =
          process.env.REACT_APP_API_DOMAIN +
          '/v1/invoices?legal_case_uid=' +
          JSON.stringify(query.get('legal_case_uid')) +
          '&starts_on_from=' +
          JSON.stringify(query.get('starts_on_from')) +
          '&starts_on_to=' +
          JSON.stringify(query.get('starts_on_to'))
      } else if (
        query.get('legal_case_uid') !== null &&
        query.get('starts_on_from') === null &&
        query.get('starts_on_to') === null
      ) {
        url =
          process.env.REACT_APP_API_DOMAIN +
          '/v1/invoices?legal_case_uid=' +
          JSON.stringify(query.get('legal_case_uid'))
      } else if (
        query.get('legal_case_uid') === null &&
        query.get('starts_on_from') !== null &&
        query.get('starts_on_to') !== null
      ) {
        url =
          process.env.REACT_APP_API_DOMAIN +
          '/v1/invoices?&starts_on_from=' +
          JSON.stringify(query.get('starts_on_from')) +
          '&starts_on_to=' +
          JSON.stringify(query.get('starts_on_to'))
      }
      this.setState({ searchCase: searchItem })
    }
    this.loadInvoices(url)
  }

  handleChangeQ(e) {
    this.setState({ q: e.target.value })
  }

  _handleKeyPress(e) {
    if (e.key === 'Enter') {
      if (this.state.q === '') {
        this.setState({ invoices: this.state.draftInvoices })
      } else {
        axios({
          method: 'get',
          url:
            process.env.REACT_APP_API_DOMAIN +
            '/v1/invoices?q=' +
            JSON.stringify(this.state.q),
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then((resp) => {
          this.setState({ invoices: resp.data.data })
        })
      }

      const params = new URLSearchParams()
      if (this.state.q === '') {
        this.props.history.push({ search: '' })
      } else {
        params.append('q', this.state.q)
        this.props.history.push({ search: params.toString() })
      }
    }
  }

  loadInvoices(url) {
    Promise.all([
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        this.setState({ legalCases: resp.data.data })
      }),
      axios({
        method: 'get',
        url: url,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        // const slice = resp.data.data.slice(this.state.offset, this.state.offset + this.state.perPage)
        this.setState({ invoices: resp.data.data })
        this.setState({ draftInvoices: resp.data.data })
        this.setState({
          pageCount: Math.ceil(resp.data.data.length / this.state.perPage)
        })
      }),
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/members',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        const newData = resp.data.map((item, idx) => ({
          uid: item.uid,
          username: item.username,
          first_name: item.first_name,
          email: item.email,
          middle_name: item.middle_name,
          last_name: item.last_name,
          rate: parseFloat(1000 + 1000 * idx)
        }))
        this.setState({ members: newData })
      }),
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/transaction_types',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        var newSetItem = []
        var rebateItems = []
        var rebateParents = []
        var expenseParents = []
        resp.data.data.map((parent) => {
          if (parent.parent_uid === null && parent.is_deduction === false) {
            expenseParents.push(parent)
            resp.data.data.map((child) => {
              var newItem = {}
              if (parent.uid === child.parent_uid) {
                newItem['uid'] = child.uid
                newItem['name'] = child.name
                newItem['parent'] = parent.name
                newItem['is_deduction'] = child.is_deduction
                newSetItem.push(newItem)
              }
            })
          }
          if (parent.parent_uid === null && parent.is_deduction === true) {
            rebateParents.push(parent)
            resp.data.data.map((child) => {
              var rebateItem = {}
              if (parent.uid === child.parent_uid) {
                rebateItem['uid'] = child.uid
                rebateItem['name'] = child.name
                rebateItem['parent'] = parent.name
                rebateItem['is_deduction'] = child.is_deduction
                rebateItems.push(rebateItem)
              }
            })
          }
        })
        this.setState({
          expenseTypes: newSetItem,
          rebates: rebateItems,
          rebateParents: rebateParents,
          expenseParents: expenseParents
        })
      }),
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/clients',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        this.setState({ clients: resp.data.data })
      }),
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        this.setState({ draftInvoices: resp.data.data })
        this.setState({ load: true })
      })
    ]).catch((error) =>
      this.setState({ promiseAllError: error.response.status })
    )
  }

  handleSubmitsub() {
    const item = this.state.invoice
    var method = ''
    var url = ''
    axios({
      method: method,
      url: url,
      data: item,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        const newData = resp.data
        this.setState({
          invoices: this.state.invoices.filter(
            (invoice) => invoice.uid !== item.uid
          )
        })
        this.setState({ invoices: [...this.state.invoices, newData] })
        this.setState({
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success'
        })
        this.setState({ openPopup: false })
      })
      .catch((error) => console.log(error.response))
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.invoice.uid
    axios({
      method: 'delete',
      url:
        process.env.REACT_APP_API_DOMAIN +
        '/v1/legal_cases/' +
        this.state.invoice.legal_case_uid +
        '/invoices/' +
        DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then(() => {
        this.setState({
          invoices: this.state.invoices.filter(
            (item) => item.uid !== DeleteItemId
          )
        })
        this.setState({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'error'
        })
        this.setState({ openDeletePopup: false })
      })
      .catch((error) => console.log(error.response))
  }

  handleClose() {
    this.setState({ openPopup: false, maxWidth: 'sm' })
    this.setState({ openDeletePopup: false })
    this.setState({ legalCase: {} })

    this.setState({ openSearchPopUp: false })
    this.setState({ openBulkInvoiceForm: false })
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({ openPopup: true })
    this.setState({ invoice: item })
    this.setState({ title: textTitle })
  }

  handleInputChange(e) {
    this.setState({
      invoice: {
        ...this.state.invoice,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ invoice: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  handleAutoComplete(event, values) {
    if (values !== null) {
      this.setState({ legalCase: values })
      this.setState({ users: values.user_uids })
      if (values.biller_uid !== null) {
        if (
          this.state.clients.length !== null ||
          this.state.clients.length > 0
        ) {
          var clientBiller = {}
          this.state.clients.map((client) => {
            if (client.uid === values.biller_uid) {
              clientBiller = client
            }
          })
          this.setState({ biller: clientBiller })
        }
      }
      axios({
        method: 'get',
        url:
          process.env.REACT_APP_API_DOMAIN +
          '/v1/legal_cases/' +
          values.uid +
          '/transactions',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      })
        .then((resp) => {
          const getRate = (amt, discount, rate_type, rate, duration) => {
            var rateAmt = 0
            if (rate_type === 'fixed') {
              rateAmt = rate
            } else {
              rateAmt =
                (parseFloat(amt) + parseFloat(discount)) / parseFloat(duration)
            }
            return rateAmt
          }
          const newData = resp.data.map((item) => ({
            uid: '',
            transaction_record_id: item.uid,
            name: item.name,
            amount: item.amount,
            user_id: item.user_uid,
            rate: getRate(
              item.amount,
              item.discount,
              item.rate_type,
              item.rate,
              item.duration
            ),
            filed_at: item.filed_at,
            transaction_type_id: item.transaction_type_uid,
            is_deduction: item.transaction_type.is_deduction,
            duration: item.duration,
            rate_type: item.rate_type,
            remarks: item.remarks,
            discount: (item.discount / item.amount) * 100,
            initialAmount: item.amount / (1 - item.discount / item.amount),
            transaction_type: item.transaction_type
          }))
          var a = []
          var b = []
          newData.map((newItem) => {
            if (newItem.is_deduction === false) {
              a.push(newItem)
            } else {
              b.push(newItem)
            }
          })
          this.setState({ transactions: [...a, ...b] })
          this.setState({ transactionMenu: [...a, ...b] })
          this.setState({ maxWidth: 'xl' })
          this.setState({
            title: this.state.title + ' for ' + this.state.legalCase.title
          })
        })
        .catch((error) => console.log(error.response))
    }
  }
  handleSearchChange(e) {
    this.setState({
      searchCase: {
        ...this.state.searchCase,
        [e.target.name]: e.target.value
      }
    })
  }
  handleSearchAutocomplete(event, values) {
    if (values !== null) {
      this.setState({
        searchCase: {
          legalCase: values
        }
      })
    }
  }
  handleCaseFilterChange(e) {
    this.setState({
      caseFilter: {
        ...this.state.caseFilter,
        [e.target.name]: e.target.value
      }
    })
  }
  handleCaseFilterAutocomplete(event, values) {
    if (values !== null) {
      this.setState({ caseFilter: values })
    }
  }

  searchInvoice() {
    var url = ''
    var searchUrl = ''
    if (
      this.state.searchCase.legalCase !== undefined &&
      this.state.searchCase.starts_on_from !== undefined &&
      this.state.searchCase.starts_on_from !== undefined
    ) {
      url =
        process.env.REACT_APP_API_DOMAIN +
        '/v1/invoices?legal_case_uid=' +
        JSON.stringify(this.state.searchCase.legalCase.uid) +
        '&starts_on_from=' +
        JSON.stringify(this.state.searchCase.starts_on_from) +
        '&starts_on_to=' +
        JSON.stringify(this.state.searchCase.starts_on_to)
      searchUrl =
        'legal_case_uid=' +
        this.state.searchCase.legalCase.uid +
        '&starts_on_from=' +
        this.state.searchCase.starts_on_from +
        '&starts_on_to=' +
        this.state.searchCase.starts_on_to

      axios({
        method: 'get',
        url: url,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      })
        .then((resp) => {
          this.setState({ invoices: resp.data.data })
          this.setState({ openSearchPopUp: false })
        })
        .catch((error) => console.log(error.response))
    } else if (
      this.state.searchCase.legalCase.uid === undefined &&
      this.state.searchCase.starts_on_from !== undefined &&
      this.state.searchCase.starts_on_from !== undefined
    ) {
      url =
        process.env.REACT_APP_API_DOMAIN +
        '/v1/invoices?starts_on_from=' +
        JSON.stringify(this.state.searchCase.starts_on_from) +
        '&starts_on_to=' +
        JSON.stringify(this.state.searchCase.starts_on_to)
      searchUrl =
        'starts_on_from=' +
        this.state.searchCase.starts_on_from +
        '&starts_on_to=' +
        this.state.searchCase.starts_on_to

      axios({
        method: 'get',
        url: url,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      })
        .then((resp) => {
          this.setState({ invoices: resp.data.data })
          this.setState({ openSearchPopUp: false })
        })
        .catch((error) => console.log(error.response))
    } else if (
      this.state.searchCase.legalCase.uid !== undefined &&
      this.state.searchCase.starts_on_from === undefined &&
      this.state.searchCase.starts_on_from === undefined
    ) {
      url =
        process.env.REACT_APP_API_DOMAIN +
        '/v1/invoices?legal_case_uid=' +
        JSON.stringify(this.state.searchCase.legalCase.uid)
      searchUrl = 'legal_case_uid=' + this.state.searchCase.legalCase.uid

      axios({
        method: 'get',
        url: url,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      })
        .then((resp) => {
          this.setState({ invoices: resp.data.data })
          this.setState({ openSearchPopUp: false })
        })
        .catch((error) => console.log(error.response))
    } else {
      this.setState({ openSearchPopUp: false })
    }
    this.setState({ searchPath: searchUrl })
    this.setState({ redirectSearch: true })

    const { history } = this.props
    const params = new URLSearchParams()
    params.append('/search', searchUrl)
    history.push({ search: searchUrl })
  }

  selectFilter(e) {
    this.setState({ filterField: e.target.value })
  }

  setUserFilter(e) {
    this.setState({ userFilter: e.target.value })
  }
  setEndDateFilter(e) {
    this.setState({ endDateFilter: e.target.value })
  }
  setBeforeDateFilter(e) {
    this.setState({ beforeDateFilter: e.target.value })
  }
  setTransMenu() {
    this.setState({ transactions: this.state.transactionMenu })
  }

  applyDateFilter() {
    if (this.state.endDateFilter !== '' && this.state.beforeDateFilter !== '') {
      this.setState({
        transactions: this.state.transactions.filter(
          (item) =>
            item.filed_at >= this.state.beforeDateFilter &&
            item.filed_at <= this.state.endDateFilter
        )
      })
    } else if (
      this.state.endDateFilter === '' &&
      this.state.beforeDateFilter !== ''
    ) {
      this.setState({
        transactions: this.state.transactions.filter(
          (item) => item.filed_at >= this.state.beforeDateFilter
        )
      })
    } else if (
      this.state.endDateFilter !== '' &&
      this.state.beforeDateFilter === ''
    ) {
      this.setState({
        transactions: this.state.transactions.filter(
          (item) => item.filed_at <= this.state.endDateFilter
        )
      })
    } else {
      this.setState({
        transactions: [...this.state.transactions]
      })
    }
  }
  removeDateFilter() {
    this.setState({ transactions: this.state.transactionMenu })
    this.setState({ beforeDateFilter: '' })
    this.setState({ endDateFilter: '' })
  }

  removeUserFilter() {
    this.setState({ transactions: this.state.transactionMenu })
    this.setState({ userFilter: '' })
  }

  setTypeFilter(e) {
    this.setState({ typeFilter: e.target.value })
  }

  applyTypeFilter() {
    this.setState({
      transactions: this.state.transactions.filter(
        (item) => item.is_deduction === this.state.typeFilter
      )
    })
  }

  removeTypeFilter() {
    this.setState({ transactions: this.state.transactionMenu })
    this.setState({ typeFilter: '' })
  }

  handleTransactionType(event, values) {
    if (values !== null) {
      this.setState({
        transaction: {
          ...this.state.transaction,
          transaction_type_id: values.uid
        }
      })
    }
  }

  applyFilter() {
    var updatedTransactions = this.state.transactions
    if (this.state.endDateFilter !== '' && this.state.beforeDateFilter !== '') {
      updatedTransactions = updatedTransactions.filter(
        (item) =>
          item.filed_at >= this.state.beforeDateFilter &&
          item.filed_at <= this.state.endDateFilter
      )
    } else if (
      this.state.endDateFilter === '' &&
      this.state.beforeDateFilter !== ''
    ) {
      updatedTransactions = updatedTransactions.filter(
        (item) => item.filed_at >= this.state.beforeDateFilter
      )
    } else if (
      this.state.endDateFilter !== '' &&
      this.state.beforeDateFilter === ''
    ) {
      updatedTransactions = updatedTransactions.filter(
        (item) => item.filed_at <= this.state.endDateFilter
      )
    } else {
      updatedTransactions = [...this.state.transactions]
    }
    if (this.state.typeFilter !== '') {
      updatedTransactions = updatedTransactions.filter(
        (item) => item.is_deduction === this.state.typeFilter
      )
    }
    if (this.state.userFilter !== '') {
      updatedTransactions = updatedTransactions.filter(
        (item) => item.user_id === this.state.userFilter
      )
    }
    this.setState({ transactions: updatedTransactions })
  }

  handleSubmit() {
    const item = this.state.invoice
    var method = ''
    var url = ''
    if (item.uid === '') {
      method = 'post'
      url =
        process.env.REACT_APP_API_DOMAIN +
        '/v1/legal_cases/' +
        this.state.legalCase.uid +
        '/invoices'
    } else {
      method = 'put'
      url =
        process.env.REACT_APP_API_DOMAIN +
        '/v1/legal_cases/' +
        this.state.legalCase.uid +
        '/invoices/' +
        item.uid
    }
    axios({
      method: method,
      url: url,
      data: item,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        this.setState({ invoice: resp.data })
        const newData = resp.data
        this.setState({
          invoices: this.state.invoices.filter(
            (invoice) => invoice.uid !== item.uid
          )
        })
        this.setState({ invoices: [...this.state.invoices, newData] })
        this.submitInvoiceDetails(resp.data.uid)
      })
      .catch((error) => console.log(error.response))
  }

  submitInvoiceDetails(id) {
    var item = {}
    var respArray = []
    if (this.state.transactions.length > 0) {
      this.state.transactions.map((transaction) => {
        item['uid'] = transaction.uid
        item['transaction_record_id'] = transaction.transaction_record_id
        item['transaction_type_id'] = transaction.transaction_type_id
        item['amount'] = parseFloat(transaction.amount)

        item['user_id'] = transaction.user_id
        item['rate_type'] = transaction.rate_type
        item['discount'] = (transaction.amount * transaction.discount) / 100
        if (item.rate_type === 'hourly') {
          item['rate'] = transaction.rate
        } else {
          item['rate'] = 0
        }
        var method = ''
        var url = ''
        if (item.uid === '') {
          method = 'post'
          url =
            process.env.REACT_APP_API_DOMAIN +
            '/v1/invoices/' +
            id +
            '/invoice_details'
        } else {
          method = 'put'
          url =
            process.env.REACT_APP_API_DOMAIN +
            '/v1/invoices/' +
            id +
            '/invoice_details/' +
            item.uid
        }
        axios({
          method: method,
          url: url,
          data: item,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        })
          .then((resp) => {
            var newData = resp.data
            respArray.push(newData)
            if (respArray.length === this.state.transactions.length) {
              this.setState({ invoiceDetails: respArray })
              this.setState({ doneDetails: true })
              this.setState({ openPopUp: false })

              this.setState({ redirect: true })
            }
          })
          .catch((error) => console.log(error.response))
      })
    } else {
      this.setState({ invoiceDetails: [] })
      this.setState({ doneDetails: true })
      this.setState({ openPopUp: false })

      this.setState({ redirect: true })
    }
  }

  removeFilter() {
    this.setState({ userFilter: '' })
    this.setState({ typeFilter: '' })
    this.setState({ beforeDateFilter: '' })
    this.setState({ endDateFilter: '' })
    this.setState({ transactions: this.state.transactionMenu })
  }
  newTransaction() {
    this.setState({ transactionTitle: 'Create Transaction' })
    this.setState({ transaction: this.state.initialTransaction })
    this.setState({ transactionTypePopUp: false })
    this.setState({ openTransactionForm: true })
  }
  getTotalDiscount() {
    var total = 0
    if (this.state.transactions.length > 0) {
      this.state.transactions.map((transaction) => {
        if (transaction.is_deduction === true) {
          total = total + parseFloat(transaction.amount)
        }
      })
    }
    return total.toLocaleString(navigator.language, {
      minimumFractionDigits: 2
    })
  }
  getTotalAmount() {
    var total = 0
    var discount = 0
    if (this.state.transactions.length > 0) {
      this.state.transactions.map((transaction) => {
        if (transaction.is_deduction === false) {
          total = total + parseFloat(transaction.amount)
        } else {
          discount = discount + parseFloat(transaction.amount)
        }
      })
    }

    return (total - discount).toLocaleString(navigator.language, {
      minimumFractionDigits: 2
    })
  }

  getVat() {
    var total = 0
    var discount = 0
    var withTax = 0
    if (this.state.biller.is_taxable === false) {
      if (this.state.transactions.length > 0) {
        this.state.transactions.map((transaction) => {
          if (transaction.is_deduction === false) {
            total = total + parseFloat(transaction.amount)
          } else {
            discount = discount + parseFloat(transaction.amount)
          }
        })
      }
      withTax = (total - discount) * 0.12
    } else {
      withTax = 0
    }

    return withTax.toLocaleString(navigator.language, {
      minimumFractionDigits: 2
    })
  }
  getGrandTotal() {
    var total = 0
    var discount = 0
    var grandTotal = 0
    if (this.state.biller !== undefined) {
      if (this.state.biller.is_taxable === false) {
        if (this.state.transactions.length > 0) {
          this.state.transactions.map((transaction) => {
            if (transaction.is_deduction === false) {
              total = total + parseFloat(transaction.amount)
            } else {
              discount = discount + parseFloat(transaction.amount)
            }
          })
        }
        grandTotal = total - discount + (total - discount) * 0.12
      } else {
        if (this.state.transactions.length > 0) {
          this.state.transactions.map((transaction) => {
            if (transaction.is_deduction === false) {
              total = total + parseFloat(transaction.amount)
            } else {
              discount = discount + parseFloat(transaction.amount)
            }
          })
        }
        grandTotal = total - discount
      }
    }

    return grandTotal.toLocaleString(navigator.language, {
      minimumFractionDigits: 2
    })
  }
  getTotal() {
    var total = 0
    if (this.state.transactions.length > 0) {
      this.state.transactions.map((transaction) => {
        if (transaction.is_deduction === false) {
          total = total + parseFloat(transaction.amount)
        }
      })
    }
    return total.toLocaleString(navigator.language, {
      minimumFractionDigits: 2
    })
  }

  editTransaction(transaction) {
    var item = {}
    item['uid'] = transaction.transaction_record_id
    item['name'] = transaction.name
    item['amount'] = transaction.amount
    item['transaction_type_id'] = transaction.transaction_type_id
    item['user_id'] = transaction.user_id
    item['remarks'] = transaction.remarks
    item['duration'] = transaction.duration
    item['is_deduction'] = transaction.is_deduction
    item['filed_at'] = transaction.filed_at
    item['rate_type'] = transaction.rate_type
    item['rate'] = transaction.rate
    item['discount'] = transaction.discount
    item['initialAmount'] =
      transaction.amount / (1 - transaction.discount / 100)
    this.setState({ transaction: item })

    if (this.state.expenseTypes.length > 0) {
      this.state.expenseTypes.map((expense) => {
        if (expense.uid === transaction.transaction_type_id) {
          this.setState({ defaultTransactionType: expense })
        }
      })
    }
    if (this.state.rebates.length > 0) {
      this.state.rebates.map((expense) => {
        if (expense.uid === transaction.transaction_type_id) {
          this.setState({ defaultTransactionType: expense })
        }
      })
    }
    this.setState({ openTransactionForm: true })
    this.setState({ transactionTitle: 'Edit Transaction' })
  }

  removeTransaction(idx) {
    this.state.transactions.splice(idx, 1)
    this.setState({ transactions: [...this.state.transactions] })
  }

  addTransaction() {
    this.setState({ transactionTypePopUp: true })
    this.setState({ selectExistingArray: [] })
  }

  changeIndex() {
    this.setState({ showState: !this.state.showState })
    if (this.state.showState === false) {
      this.setState({ invoices: [] })
    } else {
      this.setState({ invoices: this.state.draftInvoices })
    }
  }

  handleCloseTransactionForms() {
    this.setState({ openTransactionForm: false })
    this.setState({ openSelectExistingTransaction: false })
    this.setState({ transactionTypePopUp: false })
    this.setState({ selectedExisting: [] })
  }

  existingTransaction() {
    this.setState({ transactionTypePopUp: false })

    var arr1 = []
    if (this.state.transactionMenu.length > 0) {
      this.state.transactionMenu.map((transMenu) => {
        var recorded = false
        this.state.transactions.map((trans) => {
          if (transMenu.transaction_record_id === trans.transaction_record_id) {
            recorded = true
          }
        })
        if (recorded === false) {
          arr1.push(transMenu)
        }
      })
    }
    this.setState({ transMenuPopUp: arr1 })
    this.setState({ openSelectExistingTransaction: true })
  }

  handleSearch(e) {
    this.setState({ filterSelection: e.target.value })
  }

  submitChecked() {
    const newData = this.state.selectExistingArray.map((item) => ({
      uid: item.uid,
      transaction_record_id: item.transaction_record_id,
      transaction_type_id: item.transaction_type_id,
      filed_at: item.filed_at,
      duration: item.duration,
      name: item.name,
      amount: item.amount,
      user_id: item.user_id,
      rate: item.rate,
      is_deduction: item.is_deduction,
      discount: item.discount,
      transaction_type: item.transaction_type
    }))
    var addedTransactions = this.state.transactions
    addedTransactions = [...addedTransactions, ...newData]
    addedTransactions = addedTransactions
      .sort(function (a, b) {
        return a.is_deduction - b.is_deduction
      })
      .sort(
        (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      )

    this.setState({ transactions: addedTransactions })
    this.setState({ openSelectExistingTransaction: false })
    this.setState({ selectedExisting: [] })
  }

  handleCheckExisting(e, item) {
    var selectedExisting = this.state.selectedExisting
    if (e.target.checked === true) {
      this.setState({
        selectExistingArray: [...this.state.selectExistingArray, { ...item }]
      })
      selectedExisting.push(item.transaction_record_id)
    } else {
      this.setState({
        selectExistingArray: this.state.selectExistingArray.filter(
          (invoice) =>
            invoice.transaction_record_id !== item.transaction_record_id
        )
      })
      selectedExisting.splice(
        selectedExisting.indexOf(item.transaction_record_id),
        1
      )
    }
    this.setState({ selectedExisting: selectedExisting })
  }

  handleSubmitTransaction() {
    const item = {}
    item['uid'] = this.state.transaction.uid
    item['name'] = this.state.transaction.name
    item['user_id'] = this.state.transaction.user_id
    item['rate_type'] = this.state.transaction.rate_type
    item['remarks'] = this.state.transaction.remarks
    item['filed_at'] = this.state.transaction.filed_at
    item['transaction_type_id'] = this.state.transaction.transaction_type_id
    item['amount'] = this.state.transaction.amount * 1
    item['discount'] =
      (this.state.transaction.discount / 100) * this.state.transaction.amount

    if (this.state.transaction.rate_type === 'fixed') {
      item['duration'] = 0
    } else {
      item['duration'] = this.state.transaction.duration * 1
    }
    var method = ''
    var url = ''
    if (item.uid === '') {
      method = 'post'
      url =
        process.env.REACT_APP_API_DOMAIN +
        '/v1/legal_cases/' +
        this.state.legalCase.uid +
        '/transactions'
    } else {
      method = 'put'
      url =
        process.env.REACT_APP_API_DOMAIN +
        '/v1/legal_cases/' +
        this.state.legalCase.uid +
        '/transactions/' +
        item.uid
    }
    axios({
      method: method,
      url: url,
      data: item,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        const newData = {}
        ;(newData['uid'] = ''),
          (newData['transaction_record_id'] = resp.data.uid)
        newData['transaction_type_id'] = resp.data.transaction_type_uid
        newData['name'] = resp.data.name
        newData['user_id'] = resp.data.user_uid
        newData['rate'] = resp.data.rate
        newData['duration'] = resp.data.duration
        newData['amount'] = parseFloat(resp.data.amount)
        newData['filed_at'] = resp.data.filed_at
        newData['rate_type'] = resp.data.rate_type
        newData['remarks'] = resp.data.remarks
        newData['is_deduction'] = resp.data.transaction_type.is_deduction
        newData['discount'] =
          (resp.data.discount / parseFloat(resp.data.amount)) * 100
        newData['initialAmount'] =
          parseFloat(resp.data.amount) /
          (1 - resp.data.discount / parseFloat(resp.data.amount))

        this.setState({
          transactions: this.state.transactions.filter(
            (transaction) => transaction.transaction_record_id !== resp.data.uid
          )
        })
        this.setState({ transactions: [...this.state.transactions, newData] })
        this.setState({ openTransactionForm: false })
      })
      .catch((error) => console.log(error.response))
  }

  transactionTestNumber(e) {
    this.setState({
      transaction: {
        ...this.state.transaction,
        amount: e.floatValue
      }
    })
  }

  handleInputTransactionChange(e) {
    var value = null

    if (e.target.name === 'amount') {
      value = e.target.value * 1
    } else if (e.target.name === 'duration') {
      value = e.target.value * 1
    } else {
      value = e.target.value
    }
    this.setState({
      transaction: {
        ...this.state.transaction,
        [e.target.name]: value
      }
    })
  }

  handleSwitch(e) {
    if (e.target.checked === true) {
      this.setState({
        transaction: {
          ...this.state.transaction,
          is_deduction: e.target.checked,
          discount: 0,
          amount: this.state.transaction.rate * this.state.transaction.duration
        }
      })
    } else {
      this.setState({
        transaction: {
          ...this.state.transaction,
          is_deduction: e.target.checked
        }
      })
    }
  }
  handleUserChange(e) {
    var userRate = 0
    if (this.state.members.length > 0) {
      this.state.members.map((user) => {
        if (user.uid === e.target.value) {
          userRate = user.rate
        }
      })
    }

    this.setState({
      transaction: {
        ...this.state.transaction,
        [e.target.name]: e.target.value,
        rate: userRate
      }
    })
  }
  userRateChange(e) {
    this.setState({
      transaction: {
        ...this.state.transaction,
        rate: e.floatValue,
        amount:
          e.floatValue *
          this.state.transaction.duration *
          (1 - this.state.transaction.discount / 100)
      }
    })
  }

  discountChange(e) {
    if (e.floatValue !== undefined) {
      if (this.state.transaction.rate_type === 'hourly') {
        this.setState({
          transaction: {
            ...this.state.transaction,
            discount: e.floatValue,
            amount:
              this.state.transaction.rate *
              this.state.transaction.duration *
              (1 - e.floatValue / 100)
          }
        })
      } else {
        this.setState({
          transaction: {
            ...this.state.transaction,
            discount: e.floatValue,
            amount:
              this.state.transaction.initialAmount * (1 - e.floatValue / 100)
          }
        })
      }
    } else {
      this.setState({
        transaction: {
          ...this.state.transaction,
          discount: 0,
          amount: this.state.transaction.rate * this.state.transaction.duration
        }
      })
    }
  }

  initialAmount(e) {
    this.setState({
      transaction: {
        ...this.state.transaction,
        initialAmount: e.floatValue,
        amount: e.floatValue
      }
    })
  }
  handleDuration(e) {
    var amt = 0
    amt = this.state.transaction.rate * parseFloat(e.target.value)
    this.setState({
      transaction: {
        ...this.state.transaction,
        [e.target.name]: e.target.value,
        amount: amt
      }
    })
  }

  handleOpenAdvanceSearch() {
    this.setState({ openSearchPopUp: true })
  }
  getDiscount() {
    var totalDiscount = 0
    var total = 0
    var discount = 0
    if (this.state.invoice.discount !== undefined) {
      if (this.state.transactions.length > 0) {
        this.state.transactions.map((transaction) => {
          if (transaction.is_deduction === false) {
            total = total + parseFloat(transaction.amount)
          } else {
            discount = discount + parseFloat(transaction.amount)
          }
        })
      }
      totalDiscount = total - discount
    }
    return totalDiscount.toLocaleString(navigator.language, {
      minimumFractionDigits: 2
    })
  }
  handleCreateBulkInvoice() {
    var date = new Date()
    var url
    this.setState({
      caseFilter: {
        starts_on_from: new Date(
          date.getFullYear(),
          date.getMonth(),
          1
        ).toLocaleDateString('fr-CA'),
        starts_on_to: new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          0
        ).toLocaleDateString('fr-CA')
      }
    })
    url =
      process.env.REACT_APP_API_DOMAIN +
      '/v1/legal_cases?starts_on_from=' +
      JSON.stringify(
        new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString(
          'fr-CA'
        )
      ) +
      '&starts_on_to=' +
      JSON.stringify(
        new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString(
          'fr-CA'
        )
      )
    axios({
      method: 'get',
      url: url,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        this.setState({ bulkLegalCases: resp.data.data })
        var selectedIds = []
        resp.data.data.map((cases) => {
          selectedIds.push(cases.uid)
        })
        this.setState({ selectedCases: selectedIds })
      })
      .catch((error) => console.log(error.response))
    this.setState({ openBulkInvoiceForm: true })
  }

  filterCases() {
    var url = ''
    if (
      this.state.caseFilter.starts_on_from !== undefined &&
      this.state.caseFilter.starts_on_from !== undefined
    ) {
      url =
        process.env.REACT_APP_API_DOMAIN +
        '/v1/legal_cases?starts_on_from=' +
        this.state.caseFilter.starts_on_from +
        '&starts_on_to=' +
        this.state.caseFilter.starts_on_to
      axios({
        method: 'get',
        url: url,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      })
        .then((resp) => {
          this.setState({ bulkLegalCases: resp.data.data })
          var selectedIds = []
          resp.data.data.map((cases) => {
            selectedIds.push(cases.uid)
          })
          this.setState({ selectedCases: selectedIds })
        })
        .catch((error) => console.log(error.response))
    } else {
      this.setState({ bulkLegalCases: this.state.legalCases })
    }
  }
  removeFilterCases() {
    this.setState({ bulkLegalCases: this.state.legalCases })
  }

  createBulkInvoice() {
    if (this.state.selectedCases.length > 0) {
      if (this.state.bulkLegalCases.length > 0) {
        this.state.selectedCases.map((selectedId, idx) => {
          this.state.bulkLegalCases.map((legalCase) => {
            if (legalCase.uid === selectedId) {
              axios({
                method: 'get',
                url:
                  process.env.REACT_APP_API_DOMAIN +
                  '/v1/legal_cases/' +
                  legalCase.uid +
                  '/transactions',
                headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
              })
                .then((resp) => {
                  var transactions = []
                  if (resp.data.length > 0) {
                    resp.data.map((item) => {
                      var trans = {}
                      if (
                        this.state.caseFilter.starts_on_to >= item.filed_at &&
                        this.state.caseFilter.starts_on_from <= item.filed_at
                      ) {
                        trans['transaction_record_id'] = item.uid
                        trans['transaction_type_id'] = item.transaction_type_uid
                        trans['amount'] = parseFloat(item.amount)
                        trans['user_id'] = item.user_id
                        trans['rate_type'] = item.rate_type
                        trans['discount'] = item.discount
                        if (trans.rate_type === 'hourly') {
                          trans['rate'] = item.rate
                        } else {
                          trans['rate'] = 0
                        }
                        transactions.push(trans)
                      }
                    })
                  }
                  var bills = this.state.invoices
                    .sort(
                      (a, b) =>
                        new Date(a.created_at).getTime() -
                        new Date(b.created_at).getTime()
                    )
                    .reverse()
                  var invoice = {
                    uid: '',
                    reference_no: (
                      parseInt(bills[0].reference_no) +
                      1 +
                      idx
                    ).toString(),
                    filed_at: new Date(
                      this.state.bulkInvoiceDate
                        ? this.state.bulkInvoiceDate
                        : ''
                    ).toLocaleDateString('sv-Se', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    }),
                    due_date: new Date(
                      new Date().setDate(
                        new Date(
                          this.state.bulkInvoiceDate
                            ? this.state.bulkInvoiceDate
                            : ''
                        ).getDate() + 30
                      )
                    ).toLocaleDateString('fr-CA')
                  }
                  axios({
                    data: invoice,
                    method: 'post',
                    url:
                      process.env.REACT_APP_API_DOMAIN +
                      '/v1/legal_cases/' +
                      legalCase.uid +
                      '/invoices',
                    headers: {
                      'X-APP-API-KEY': localStorage.getItem('api_key')
                    }
                  }).then((resp) => {
                    if (transactions.length > 0) {
                      transactions.map((transaction) => {
                        axios({
                          data: transaction,
                          method: 'post',
                          url:
                            process.env.REACT_APP_API_DOMAIN +
                            '/v1/invoices/' +
                            resp.data.uid +
                            '/invoice_details',
                          headers: {
                            'X-APP-API-KEY': localStorage.getItem('api_key')
                          }
                        })
                      })
                    }
                    const newData = resp.data
                    this.setState({
                      invoices: [...this.state.invoices, newData]
                    })
                    this.setState({ openBulkInvoiceForm: false })
                  })
                })
                .catch((error) => console.log(error.response))
            }
          })
        })
      }
    }
  }
  clearSearch() {
    const { history } = this.props

    history.push({ search: '' })
    this.setState({ invoices: this.state.draftInvoices })
    this.setState({ q: '' })
    this.setState({ searchCase: {} })
  }

  handleToggle(event, item) {
    var selectedArray = this.state.selectedCases
    if (this.state.selectedCases.indexOf(item.uid) <= -1) {
      selectedArray.push(item.uid)
    } else {
      selectedArray.splice(selectedArray.indexOf(item.uid), 1)
    }
    this.setState({ selectedCases: selectedArray })
  }

  selectAll() {
    var checkAll = []
    var unCheckAll = this.state.selectedCases
    var checkRemaining = this.state.selectedCases
    if (this.state.bulkLegalCases.length > 0) {
      if (this.state.selectedCases.length <= 0) {
        this.state.bulkLegalCases.map((legalCase) => {
          checkAll.push(legalCase.uid)
        })
        return this.setState({ selectedCases: checkAll })
      } else if (
        this.state.selectedCases.length === this.state.bulkLegalCases.length
      ) {
        this.state.bulkLegalCases.map((legalCase) => {
          unCheckAll.splice(unCheckAll.indexOf(legalCase.uid), 1)
        })
        return this.setState({ selectedCases: unCheckAll })
      } else {
        this.state.bulkLegalCases.map((legalCase) => {
          var addState = false
          this.state.selectedCases.map((selectedId) => {
            if (selectedId !== legalCase.uid) {
              addState = true
            }
          })
          if (addState === true) {
            checkRemaining.push(legalCase.uid)
          }
        })
        return this.setState({ selectedCases: checkRemaining })
      }
    }
  }

  selectAllExisting() {
    var checkAll = []
    var unCheckAll = this.state.selectedExisting
    var checkRemaining = this.state.selectedExisting
    if (this.state.transMenuPopUp.length > 0) {
      if (this.state.selectedExisting.length <= 0) {
        this.state.transMenuPopUp.map((trans) => {
          checkAll.push(trans.transaction_record_id)
        })
        this.setState({ selectedExisting: checkAll })
        this.setState({ selectExistingArray: this.state.transMenuPopUp })
      } else if (
        this.state.selectedExisting.length === this.state.transMenuPopUp.length
      ) {
        this.state.transMenuPopUp.map((trans) => {
          unCheckAll.splice(unCheckAll.indexOf(trans.transaction_record_id), 1)
        })
        this.setState({ selectedExisting: unCheckAll })
        this.setState({ selectExistingArray: [] })
      } else {
        this.state.transMenuPopUp.map((transMenu) => {
          var addState = true
          this.state.selectedExisting.map((selectedId) => {
            if (selectedId === transMenu.transaction_record_id) {
              addState = false
            }
          })
          if (addState === true) {
            checkRemaining.push(transMenu.transaction_record_id)
            this.setState({
              selectExistingArray: [
                ...this.state.selectExistingArray,
                transMenu
              ]
            })
          }
        })
        return this.setState({ selectedCases: checkRemaining })
      }
    }
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    this.setState({
      isOpen: false
    })
  }

  handleBulkInvoiceDate(e) {
    this.setState({ bulkInvoiceDate: e.target.value })
  }

  openCreateTransactionType() {
    this.setState({ transactionTypeFormPopUp: true })
  }

  closeTransactionType() {
    this.setState({
      transactionTypeFormPopUp: false,
      defaultTransactionType: {}
    })
  }

  handleCheck(e) {
    this.setState({
      expenseType: {
        ...this.state.expenseType,
        parent_name: '',
        parent_uid: ''
      }
    })
    this.setState({ newGroup: e.target.checked })
  }

  handleExpenseTypeChange(e) {
    this.setState({
      expenseType: {
        ...this.state.expenseType,
        [e.target.name]: e.target.value
      }
    })
  }

  expenseTypeSubmit() {
    var method = 'post'
    var url = process.env.REACT_APP_API_DOMAIN + '/v1/transaction_types'
    if (this.state.newGroup) {
      const item = {}
      const subItem = {}
      item['name'] = this.state.expenseType.parent_name
      subItem['name'] = this.state.expenseType.name
      subItem['is_deduction'] = this.state.expenseType.is_deduction
      item['is_deduction'] = this.state.expenseType.is_deduction

      axios({
        method: method,
        url: url,
        data: item,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        const newData = resp.data
        if (item.is_deduction) {
          this.setState({
            rebateParents: [...this.state.rebateParents, newData]
          })
        } else {
          this.setState({
            expenseParents: [...this.state.expenseParents, newData]
          })
        }
        subItem['parent_id'] = newData.uid
        axios({
          method: method,
          url: url,
          data: subItem,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then((resp) => {
          const subData = resp.data
          var transactionItem = {}
          transactionItem['name'] = subData.name
          transactionItem['parent'] = item.name
          transactionItem['is_deduction'] = subData.is_deduction
          transactionItem['uid'] = subData.uid
          transactionItem['transactionParent'] = item.name

          this.setState({ defaultTransactionType: transactionItem })
          if (item.is_deduction) {
            this.setState({ rebates: [...this.state.rebates, transactionItem] })
          } else {
            this.setState({
              expenseTypes: [...this.state.expenseTypes, transactionItem]
            })
          }
          this.closeTransactionType()
        })
      })
    } else {
      const newItem = {}
      newItem['name'] = this.state.expenseType.name
      newItem['parent_id'] = this.state.expenseType.parent_uid
        ? this.state.expenseType.parent_uid
        : ''
      newItem['is_deduction'] = this.state.expenseType.is_deduction
      axios({
        method: method,
        url: url,
        data: newItem,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        const subData = resp.data
        var transactionItem = {}
        transactionItem['name'] = subData.name
        transactionItem['parent'] = [
          ...this.state.expenseParents,
          ...this.state.rebateParents
        ].filter((item) => item.uid === subData.parent_uid)[0].name
        transactionItem['is_deduction'] = subData.is_deduction
        transactionItem['uid'] = subData.uid
        transactionItem['transactionParent'] = [
          ...this.state.expenseParents,
          ...this.state.rebateParents
        ].filter((item) => item.uid === subData.parent_uid)[0].name

        this.setState({ defaultTransactionType: transactionItem })
        if (newItem.is_deduction) {
          this.setState({ rebates: [...this.state.rebates, transactionItem] })
        } else {
          this.setState({
            expenseTypes: [...this.state.expenseTypes, transactionItem]
          })
        }
        this.closeTransactionType()
      })
    }
  }

  render() {
    const { load, promiseAllError, redirect, doneDetails } = this.state

    if (redirect)
      if (doneDetails)
        return (
          <Redirect
            to={{
              pathname: '/invoices/' + this.state.invoice.uid,
              state: {
                item: this.state.invoice,
                details: this.state.invoiceDetails,
                prevPath: '/invoices'
              }
            }}
          />
        )

    return (
      <>
        {load && !promiseAllError ? (
          <Box className={styles.mainBox}>
            <SimplePopUp
              openPopup={this.state.openSearchPopUp}
              title="Advanced Search"
              handleClose={this.handleClose}
              maxWidth="sm"
            >
              <SearchForm
                legalCases={this.state.legalCases}
                handleAutoComplete={this.handleSearchAutocomplete}
                searchInvoice={this.searchInvoice}
                searchCase={this.state.searchCase}
                handleInputChange={this.handleSearchChange}
              />
            </SimplePopUp>
            <SimplePopUp
              openPopup={this.state.openBulkInvoiceForm}
              title="Create Bulk Invoice"
              handleClose={this.handleClose}
              maxWidth="lg"
            >
              <BulkInvoiceForm
                legalCases={this.state.bulkLegalCases}
                users={this.state.members}
                clients={this.state.clients}
                filterCases={this.filterCases}
                handleInputChange={this.handleCaseFilterChange}
                createBulkInvoice={this.createBulkInvoice}
                caseFilter={this.state.caseFilter}
                handleToggle={this.handleToggle}
                selectedCases={this.state.selectedCases}
                selectAll={this.selectAll}
                handleBulkInvoiceDate={this.handleBulkInvoiceDate}
                bulkInvoiceDate={this.bulkInvoiceDate}
              />
            </SimplePopUp>

            <SimpleTable
              handleClick={this.handleCreateorUpdateItem}
              initialItem={this.state.initialInvoice}
              items={this.state.invoices}
              model={this.state.model}
              onOpenDeletePopup={this.handleOpenDeletePopup}
              headers={this.state.tableHead}
              withShow={true}
              withEdit={false}
              handleAdvanced={this.handleOpenAdvanceSearch}
              withSearch={true}
              withBulkInvoice={true}
              _handleKeyPress={this._handleKeyPress}
              handleChanges={this.handleChangeQ}
              handleCreateBulkInvoice={this.handleCreateBulkInvoice}
              clients={this.state.clients}
              clearSearch={this.clearSearch}
              q={this.state.q}
              prevPath={'/invoices'}
              withPagination={false}
              pageCount={25}
              displayedPages={1}
              displayedRange={4}
              handlePageClick={this.handlePageClick}
            />
            <SimplePopUp
              openPopup={this.state.openPopup}
              title={this.state.title}
              items={this.state.invoices}
              handleClose={this.handleClose}
              maxWidth={this.state.maxWidth}
            >
              <>
                {this.state.legalCase.uid === undefined ? (
                  <InvForm
                    item={this.state.invoice}
                    legalCases={this.state.legalCases}
                    submit={this.handleSubmit}
                    onchange={this.handleInputChange}
                    handleAutoComplete={this.handleAutoComplete}
                    transactions={this.state.transactions}
                  />
                ) : (
                  <>
                    <InvoiceForm
                      invoice={this.state.invoice}
                      transactions={this.state.transactions}
                      transactionMenu={this.state.transactionMenu}
                      transMenuPopUp={this.state.transMenuPopUp}
                      beforeDateFilter={this.state.beforeDateFilter}
                      endDateFilter={this.state.endDateFilter}
                      expenseTypes={this.state.expenseTypes}
                      rebates={this.state.rebates}
                      users={this.state.users}
                      members={this.state.members}
                      typeFilter={this.state.typeFilter}
                      userFilter={this.state.userFilter}
                      setEndDateFilter={this.setEndDateFilter}
                      setTypeFilter={this.setTypeFilter}
                      setBeforeDateFilter={this.setBeforeDateFilter}
                      addTransaction={this.addTransaction}
                      handleInputChange={this.handleInputChange}
                      editTransaction={this.editTransaction}
                      removeTransaction={this.removeTransaction}
                      getTotalAmount={this.getTotalAmount}
                      getTotal={this.getTotal}
                      getTotalDiscount={this.getTotalDiscount}
                      getVat={this.getVat}
                      getGrandTotal={this.getGrandTotal}
                      removeFilter={this.removeFilter}
                      applyFilter={this.applyFilter}
                      setUserFilter={this.setUserFilter}
                      handleSubmit={this.handleSubmit}
                      biller={this.state.biller}
                    />
                  </>
                )}
              </>
            </SimplePopUp>

            <SimpleDeletePopUp
              openDeletePopup={this.state.openDeletePopup}
              item={this.state.invoice}
              delete={this.handleDeleteItem}
              handleDeleteClose={this.handleClose}
              model={this.state.model}
            />

            <SimplePopUp
              openPopup={this.state.transactionTypePopUp}
              maxWidth="sm"
              title="Confirmation"
              handleClose={this.handleCloseTransactionForms}
            >
              <div className={styles.transactionAddbuttons}>
                <div>
                  <IconButton
                    className={styles.transactionExist}
                    name="existingTransaction"
                    variant="contained"
                    onClick={this.existingTransaction}
                  >
                    <ReceiptIcon />
                    <span>Existing Transaction</span>
                  </IconButton>
                </div>
                <div>
                  <IconButton
                    className={styles.transactionNew}
                    name="newTransaction"
                    variant="contained"
                    onClick={this.newTransaction}
                  >
                    <CreditCardIcon />
                    <br />
                    <span>New Transaction</span>
                  </IconButton>
                </div>
              </div>
            </SimplePopUp>

            <SimplePopUp
              openPopup={this.state.openSelectExistingTransaction}
              maxWidth={this.state.transMenuPopUp.length > 0 ? 'lg' : 'sm'}
              title="Select Transaction"
              handleClose={this.handleCloseTransactionForms}
            >
              <SelectExistingTransactionForm
                submitChecked={this.submitChecked}
                handleCheckExisting={this.handleCheckExisting}
                transMenuPopUp={this.state.transMenuPopUp}
                selectFilter={this.selectFilter}
                filterField={this.state.filterField}
                filterSelection={this.state.filterSelection}
                handleSearch={this.handleSearch}
                users={this.state.users}
                members={this.state.members}
                expenseTypes={this.state.expenseTypes}
                rebates={this.state.rebates}
                selectedExisting={this.state.selectedExisting}
                selectAllExisting={this.selectAllExisting}
                selectExistingArray={this.state.selectExistingArray}
              />
            </SimplePopUp>

            <SimplePopUp
              openPopup={this.state.openTransactionForm}
              maxWidth="md"
              title={this.state.transactionTitle}
              handleClose={this.handleCloseTransactionForms}
            >
              <TransactionForm
                transaction={this.state.transaction}
                members={this.state.members}
                users={this.state.users}
                expenseTypes={this.state.expenseTypes}
                rebates={this.state.rebates}
                handleSubmit={this.handleSubmitTransaction}
                testNumber={this.transactionTestNumber}
                handleInputChange={this.handleInputTransactionChange}
                handleSwitch={this.handleSwitch}
                handleUserChange={this.handleUserChange}
                handleDuration={this.handleDuration}
                handleTransactionType={this.handleTransactionType}
                defaultTransactionType={this.state.defaultTransactionType}
                userRateChange={this.userRateChange}
                discountChange={this.discountChange}
                initialAmount={this.initialAmount}
                openCreateTransactionType={this.openCreateTransactionType}
              />
            </SimplePopUp>
            <SimplePopUp
              openPopup={this.state.transactionTypeFormPopUp}
              maxWidth="xs"
              title="New Transaction Type"
              handleClose={this.closeTransactionType}
            >
              <ExpenseTypeForm
                item={this.state.expenseType}
                newGroup={this.state.newGroup}
                items={[
                  ...this.state.rebateParents,
                  ...this.state.expenseParents
                ]}
                handleCheck={this.handleCheck}
                onchange={this.handleExpenseTypeChange}
                submit={this.expenseTypeSubmit}
              />
            </SimplePopUp>
            <AlertMessage
              notify={this.state.notify}
              handleCloseAlert={this.handleCloseAlert}
              isOpen={this.state.isOpen}
              type={this.state.type}
              message={this.state.message}
            />
          </Box>
        ) : promiseAllError ? (
          <ErrorPage type={promiseAllError} />
        ) : (
          <ProgressBar />
        )}
      </>
    )
  }
}

export default withRouter(Invoices)

Invoices.propTypes = {
  location: PropTypes.string,
  history: PropTypes.string
}
