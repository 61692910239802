import React from 'react'
import ReactDOM from 'react-dom'

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from './components/App'

import * as serviceWorker from './serviceWorker'

if (process.env.REACT_APP_SENTRY_DSN) {
  var sentry_opts = {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
  }

  if (process.env.REACT_APP_SENTRY_SAMPLE_RATE) {
    sentry_opts.tracesSampleRate = parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE)
  }

  Sentry.init(sentry_opts);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
