import React from 'react'
import PropTypes from 'prop-types'
import MatterForm from './MatterForm'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  IconButton,
  Typography
} from '@material-ui/core'
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation'
import EditMatter from './EditMatter'

const useStyles = makeStyles(() => ({
  head: {
    backgroundColor: '#283593',
    color: 'white',
    paddingRight: '0px'
  },
  dialogWrapper: {
    position: 'center'
  },
  secondary: {
    color: '#b71c1c'
  }
}))

const Popup = (props) => {
  const { matter, title, openPopup, setOpenPopup, isAdd, matters, setMatters, message, setMessage, setOpenSnackbar } = props
  const classes = useStyles()
  return (
    <Dialog open={openPopup} fullWidth={true} maxWidth="sm" classes={{ paper: classes.dialogWrapper }}>
      <DialogTitle className={classes.head} >
        <div style={{ display: 'flex' }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <IconButton
            className={classes.secondary}
            onClick={() => { setOpenPopup(false) }}
            variant = "contained"

          >
            <CancelPresentationIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {
          isAdd ? (
            <MatterForm setOpenPopup={setOpenPopup} matters={matters} setMatters={setMatters} message={message} setMessage={setMessage} setOpenSnackbar={setOpenSnackbar} />

          ) : (
            <EditMatter setOpenPopup={setOpenPopup} matter={matter} matters={matters} setMatters={setMatters} setMessage={setMessage} setOpenSnackbar={setOpenSnackbar} />
          )
        }

      </DialogContent>
    </Dialog>
  )
}

export default Popup

Popup.propTypes = {
  title: PropTypes.string,
  openPopup: PropTypes.bool,
  setOpenPopup: PropTypes.func,
  isAdd: PropTypes.bool,
  matter: PropTypes.object,
  matters: PropTypes.array,
  setMatters: PropTypes.func,
  message: PropTypes.string,
  setMessage: PropTypes.func,
  setOpenSnackbar: PropTypes.func
}
