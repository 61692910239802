// eslint-disable-next-line
import React from 'react'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import styles from "../LegalCase.module.css";
import EditIcon from "@material-ui/icons/Edit";
import DescriptionIcon from '@material-ui/icons/Description';
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { Tooltip } from "@material-ui/core";
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder'
import GroupAddIcon from '@material-ui/icons/GroupAdd';

class DocumentsTable extends React.Component {
  render() {
    return (
      <div className="ParentBody">
        <div className={styles.tableTitle}>
          <div className={styles.tileBody}>
            {this.props.icon}
            <Typography variant="body1" className={styles.title}>
              {
                this.props.parentFolders.length > 0 ? (
                  <>
                    <span className={styles.cursor} onClick={() => this.props.loadRoot()}>Root</span>
                    {this.props.parentFolders.map((folder, idx) => (
                      <span className={styles.cursor} onClick={() => this.props.parentClick(folder)} key={idx}>{`/ ${folder.name}`}</span>
                    ))}
                  </>
                ) : null
              }
            </Typography>
          </div>

          <div>
            <Tooltip title="Add Document" arrow>
              <IconButton color="inherit" aria-label="menu" id="AddDocument"
                onClick={() => this.props.handleCreatorUpdateDocument(this.props.initialDocument, true)}>
                <NoteAddIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Add Folder" arrow>
              <IconButton color="inherit" aria-label="menu" id="AddFolder"
                onClick={() => this.props.handleClick(this.props.initialItem, true, this.props.model)}>
                <CreateNewFolderIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div className={styles.folderHeader}>
          <div>Name</div>
          <div className={styles.folderAction}>Action</div>
        </div>
        <div className="Folderlist">
          {this.props.items.length > 0 ? (
            this.props.items.sort(
              (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
            ).map((folder, index) => (

              <div key={folder.uid} className={styles.parent}>
                <div className={styles.folderTitle}>
                  <FolderOpenIcon className={styles.folderIcon} id={`folder-${index}`} />
                  <Typography onClick={() => this.props.getDocuments(folder.uid, folder.name, folder.parent_uid, folder)}><strong>{folder.name}</strong>
                    <div style={{ fontSize: '10px' }}>
                      {new Intl.DateTimeFormat('en-GB', {
                        month: 'long',
                        day: '2-digit',
                        year: 'numeric'
                      }).format(new Date(folder.updated_at))}
                    </div>
                  </Typography>
                </div>
                <div>
                  <Typography style={{ display: 'flex' }}>
                    <IconButton
                      size="small"
                      color="primary"
                      id={`folder-edit-${index}`}
                      onClick={() => this.props.handleClick(folder, false, this.props.model)}>
                      <EditIcon />
                    </IconButton>

                    <IconButton
                      size="small"
                      color="secondary"
                      id={`folder-delete-${index}`}
                      onClick={() => this.props.onOpenDeletePopup(folder, this.props.model)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Typography>
                </div>
              </div>
            ))) : (null)}

        </div>
        <div >
          {this.props.documents.length > 0 ? (
            this.props.documents.sort(
              (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
            ).reverse().map((document, index) => 
              <div key={document.uid} className={styles.documentBody}>
                <div className={styles.documentTitle}>  <a href={document.file ? document.file.url : document.folder} target="_blank" rel="noopener noreferrer" >
                  <DescriptionIcon fontSize="small" />{document.file_name}</a>
                </div>
                <div >
                  {/* editicon */}
                  <IconButton
                    size="small"
                    color="primary"
                    id={`document-edit-${index}`}
                    onClick={() => this.props.handleCreatorUpdateDocument(document, false)}
                    // onClick={() => this.props.handleClick(folder, false, this.props.model)}
                    >
                    <GroupAddIcon />
                  </IconButton>
                  <a href={document.file ? document.file.url : document.folder} target="_blank" rel="noopener noreferrer" download id={`document-donwload-${index}`}>
                    <IconButton aria-label="delete" color="primary" size="small">
                      <CloudDownloadIcon />
                    </IconButton>
                  </a>
                  <IconButton
                    size="small"
                    color="secondary"
                    id={`document-delete-${index}`}
                    onClick={() => this.props.handleOpenDeleteDocPopup(document, this.props.model)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            )) : null}
        </div>
      </div >
    )
  }
}

export default DocumentsTable

DocumentsTable.propTypes = {
  model: PropTypes.string.isRequired,
  onOpenDeletePopup: PropTypes.function,
  items: PropTypes.array,
  documents: PropTypes.array,
  initialItem: PropTypes.object,
  initialDocument: PropTypes.object,
  handleClick: PropTypes.func,
  handleOpenDeleteDocPopup: PropTypes.func,
  handleCreatorUpdateDocument: PropTypes.func,
  icon: PropTypes.func,
  getDocuments: PropTypes.func,
  folderLabel: PropTypes.string,
  parentFolder: PropTypes.string,
  parentFolders: PropTypes.array,
  parentClick: PropTypes.func,
  loadRoot: PropTypes.func
}
