import React from 'react'

import PropTypes from 'prop-types'

import {
  TextField,
} from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete'

class InvForm extends React.Component {
  render() {
    return (
        <Autocomplete
          id="legal_case"
          size="small"
          options={this.props.legalCases}
          getOptionLabel={(option) => option.title || ''}
          getOptionSelected={(option, value) => option.title === value.title}
          onChange={this.props.handleAutoComplete}
          fullWidth
          name="case-link"
          renderInput={(params) => <TextField {...params} name="case-link" label="Case Link" variant="outlined" />}
        />
    )
  }
}
export default InvForm

InvForm.propTypes = {
  submit: PropTypes.func,
  legalCases: PropTypes.array,
  handleAutoComplete: PropTypes.func,
  transactions: PropTypes.array
}
