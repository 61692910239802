import React from 'react';
import axios from 'axios'
import SessionsForm from './SessionsForm';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      formLabel: "Forgot Password",
      message: '',
      type: '',
      user: {username: '', email: ''}
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this._handleKeyPress = this._handleKeyPress.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
  }


  handleChange(e) {
    e.preventDefault()
    this.setState({
      user: {...this.state.user,[e.target.name]: e.target.value}
    })
  }

  handleSubmit () { 
    const item = this.state.user
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/users/passwords',
      data: (item),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      setTimeout(() => {
        location.href="/login"
      }, 1500, this.setState({ isOpen: true, message: 'Verification email sent to your email', type: 'success' }))
    }).catch(error => {
      if (error.response.status === 404) { 
        this.setState({isOpen: true,message: "Invalid Username and/or Email",type: 'error'})
      }
    })
  }

  validateForm() {
    return (
      this.state.user.username.length > 0 &&
      this.state.user.email.length > 0 
    );
  }

  _handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSubmit()
    }
  }

  handleCloseAlert (event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isOpen: false})
  }

  render() {
    return (
      <>
        <SessionsForm
          formLabel={this.state.formLabel}
          item={this.state.user}
          errors={this.state.errors}
          linkButtonOne="/login"
          linkButtonTwo="/unlock_account"
          linkLabelOne="Login"
          linkLabelTwo="Unlock Password"
          labelForSubmit="Send Reset instructions"
          handleChange={this.handleChange}
          // handleSubmit={this.handleSubmit}
          validateForm={this.validateForm()}
          _handleKeyPress={this._handleKeyPress}
          handleCloseAlert={this.handleCloseAlert}
          isOpen={this.state.isOpen}
          type={this.state.type}
          message={this.state.message}
        />
      </>
    )
  }
}

export default ForgotPassword
