import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import IconButton from '@material-ui/core/IconButton'
import Fab from '@material-ui/core/Fab'
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined'
import EditIcon from '@material-ui/icons/Edit'
import Popup from './Popup'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Snackbar from '@material-ui/core/Snackbar'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles((theme) => ({
  fab: {
    backgroundColor: '#fff',
    height: '30px',
    width: '40px'
  },
  extendedIcon: {
    fontSize: 'lg'
  },
  subhead: {
    fontWeight: 'bold',
    fontSize: '13pt'
  },
  head: {
    fontWeight: 'bold',
    fontSize: '16pt',
    backgroundColor: '#76c5c8',
    padding: 'inherit',
    paddingLeft: '10px',
    color: '#fff'
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    paddingRight: theme.spacing(10)

  },
  cell: {
    height: '20px',
    padding: '6.5px'
  }

}))

const MattersTable = () => {
  const classes = useStyles()
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [message, setMessage] = useState('')
  const [openPopup, setOpenPopup] = useState(false)
  const [isAdd, setIsAdd] = useState(false)
  const [matter, setMatter] = useState()
  const [matters, setMatters] = useState([])
  const [title, setTitle] = useState()

  useEffect(() => {
    const API_KEY = process.env.REACT_APP_API_KEY
    const url = process.env.REACT_APP_API_DOMAIN + '/v1/matters'
    axios({
      method: 'get',
      url,
      headers: { 'X-APP-API-KEY': API_KEY }
    }).then(resp => {
      console.log(resp.data)
      setMatters(resp.data)
    }).catch(resp => console.log(resp))
  }, [matters.length])

  const handleClose = () => {
    setOpenSnackbar(false)
  }
  const handleClick = (addState, matter, title) => {
    setOpenPopup(true)
    setIsAdd(addState)
    setMatter(matter)
    setTitle(title)
  }

  const handleDelete = (matter) => {
    axios.delete(process.env.REACT_APP_API_DOMAIN + '/v1/matters/' + matter.uid)
      .then(resp => {
        console.log(resp)
        setMatters({ ...matters, matter })
        setMessage('Matter deleted')
        setOpenSnackbar(true)
      })
      .catch(error => console.log(error.response.data))
  }
  return (
    <>
      <Paper>
        <Table stickyHeader aria-label="sticky table" className={classes.table}>
          <TableHead>
            <TableRow style={{ padding: '10px' }}>
              <TableCell className={classes.head} >MATTERS</TableCell>
              <TableCell className={classes.head} align="right" colSpan={3}>
                <Tooltip title="Add" arrow>
                  <Fab
                    variant="round"
                    className={classes.fab}
                    onClick={() => handleClick(true, '', 'Create Matter')}
                  >
                    <AddCircleOutlinedIcon />
                  </Fab>
                </Tooltip>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.subhead} >
                  Name
              </TableCell>
              <TableCell className={classes.subhead} align="right" colSpan={3}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {matters.length > 0 ? (
              matters
                .map((matter) => (
                  <TableRow key={matter.uid} >
                    <TableCell className={classes.cell}>{matter.name}</TableCell>
                    <TableCell align="right" className={classes.cell}>
                      <ButtonGroup variant="contained" color="primary" aria-label="outlined primary button group">
                        <IconButton
                          variant="contained"
                          color="primary"
                          onClick={() => handleClick(false, matter, 'Edit Matter')}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          variant="contained"
                          color="secondary"
                          onClick={() => { if (window.confirm('Are you sure you want to delete this matter?')) handleDelete(matter) }} className="button muted-button"
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={3}>No Data</TableCell>
              </TableRow>
            )}

          </TableBody>
        </Table>

      </Paper>
      <Popup
        title = {title}
        openPopup = {openPopup}
        setOpenPopup = {setOpenPopup}
        isAdd = {isAdd}
        matter={matter}
        matters={matters}
        setMatters={setMatters}
        setMessage={setMessage}
        setOpenSnackbar = {setOpenSnackbar}

      />
      <Snackbar
        open={openSnackbar}
        onClose={handleClose}
        message={message}
        autoHideDuration={6000}

      />
    </>
  )
}

export default MattersTable
