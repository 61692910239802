import React from 'react'
import MattersTable from './MattersTable'
const Matters = () => {
  return (
    <>
      <MattersTable />
    </>
  )
}
export default Matters
