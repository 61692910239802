import React from 'react'

import PropTypes from 'prop-types'

import axios from 'axios'

import SimpleTable from '../../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import TransactionForm from './TransactionForm'
import AlertMessage from "../../../shared/Notify/AlertMessage";
import ExpenseTypeForm from "./ExpenseTypeForm"
class Transactions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      transactions: [],
      model: "Transaction",
      headers: ["user.first_name", "name", "transaction_type.name", "rate", "duration", "filed_at", "remarks", "discount", "amount"],
      openPopUp: false,
      openDeletePopUp: false,
      withEdit: true,
      withShow: false,
      load: false,
      title: "",
      transaction: {},
      initialTransaction: {
        uid: '',
        name: '',
        rate: 0,
        rate_type: 'hourly',
        initialAmount: 0,
        amount: 0,
        transaction_type_id: '',
        user_id: '',
        remarks: '',
        duration: 0,
        is_deduction: false,
        discount: 0,
        filed_at: new Date().toLocaleDateString(('fr-CA'))
      },
      expenseTypes: [],
      rebates: [],
      users: [],
      defaultTransactionType: {},
      customClassName: 'classTransactions',
      error_messages: '',
      error_messages_transaction: '',
      error_messages_user: '',
      isOpen: false,
      message: '',
      type: '',
      transactionTypePopUp: false,
      expenseType: { is_deduction: false },
      newGroup: true,
      rebateParents: [],
      expenseParents: []
    }
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.testNumber = this.testNumber.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.handleUserChange = this.handleUserChange.bind(this)
    this.handleDuration = this.handleDuration.bind(this)

    this.handleTransactionType = this.handleTransactionType.bind(this)
    this.userRateChange = this.userRateChange.bind(this)
    this.discountChange = this.discountChange.bind(this)
    this.initialAmount = this.initialAmount.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.openCreateTransactionType = this.openCreateTransactionType.bind(this)
    this.closeTransactionType = this.closeTransactionType.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.handleExpenseTypeChange = this.handleExpenseTypeChange.bind(this)
    this.expenseTypeSubmit = this.expenseTypeSubmit.bind(this)
  }

  componentDidMount() {
    this.loadWorkflow(process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.uid + '/transactions')
  }

  loadWorkflow(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      const getRate = (amt, discount, rate_type, rate, duration) => {
        var rateAmt = 0
        if (rate_type === "fixed") {
          rateAmt = rate
        } else {
          rateAmt = (parseFloat(amt) + parseFloat(discount)) / parseFloat(duration)
        }
        return rateAmt
      }
      const newData = resp.data.map((item) => ({
        uid: item.uid,
        name: item.name,
        amount: item.amount,
        initialAmount: item.amount / (1 - (item.discount / item.amount)),
        user: item.user,
        transaction_type: item.transaction_type,
        user_id: item.user_uid,
        rate: getRate(item.amount, item.discount, item.rate_type, item.rate, item.duration),
        filed_at: item.filed_at,
        transaction_type_id: item.transaction_type_uid,
        is_deduction: item.transaction_type.is_deduction,
        duration: item.duration,
        rate_type: item.rate_type,
        remarks: item.remarks,
        discount: (item.discount / item.amount) * 100,
        can_edit: item.can_edit,
        can_delete: item.can_delete,
        task_uid: item.task_uid
      }
      ))
      this.setState({ transactions: newData })
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/members',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ users: resp.data })
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/transaction_types',
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then(resp => {
          var newSetItem = []
          var rebateItems = []
          var rebateParents = []
          var expenseParents = []
          resp.data.data.map((parent) => {
            if (parent.parent_uid === null && parent.is_deduction === false) {
              expenseParents.push(parent)
              resp.data.data.map((child) => {
                var newItem = {}
                if (parent.uid === child.parent_uid) {
                  newItem["uid"] = child.uid
                  newItem["name"] = child.name
                  newItem["parent"] = parent.name
                  newItem["is_deduction"] = child.is_deduction
                  newSetItem.push(newItem)
                }
              })
            }
            if (parent.parent_uid === null && parent.is_deduction === true) {
              rebateParents.push(parent)
              resp.data.data.map((child) => {
                var rebateItem = {}
                if (parent.uid === child.parent_uid) {
                  rebateItem["uid"] = child.uid
                  rebateItem["name"] = child.name
                  rebateItem["parent"] = parent.name
                  rebateItem["is_deduction"] = child.is_deduction
                  rebateItems.push(rebateItem)
                }
              })
            }
          })
          this.setState({ expenseTypes: newSetItem, rebates: rebateItems, rebateParents: rebateParents, expenseParents: expenseParents })
          this.setState({ load: true })
        })
      })

    }).catch(error => console.log(error.response))

  }

  handleClose() {
    this.setState({ openPopUp: false })
    this.setState({ openDeletePopUp: false })
    this.setState({ error_messages: '' })
    this.setState({ error_messages_transaction: '' })
    this.setState({ error_messages_user: '' })
  }

  handleSwitch(e) {

    if (e.target.checked === true) {
      this.setState({
        transaction: {
          ...this.state.transaction,
          is_deduction: e.target.checked,
          discount: 0,
          amount: (this.state.transaction.rate * this.state.transaction.duration)
        }
      })
    } else {
      this.setState({
        transaction: {
          ...this.state.transaction,
          is_deduction: e.target.checked
        }
      })
    }

  }

  handleCreateorUpdateItem(item, isAdd) {
    var textTitle = ''
    if (isAdd) {
      textTitle = "Add Transaction"

    } else {
      textTitle = "Edit Transaction"
      this.setState({ defaultTransactionType: item.transaction_type })
    }
    this.setState({ transaction: item })
    this.setState({ openPopUp: true })
    this.setState({ title: textTitle })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ transaction: item })
    this.setState({ openDeletePopUp: true })
  }

  handleInputChange(e) {
    var value = null

    if (e.target.name === "amount") {
      value = e.target.value * 1
    } else if (e.target.name === "duration") {
      value = e.target.value * 1
    } else {
      value = e.target.value
    }

    this.setState({
      transaction: {
        ...this.state.transaction,
        [e.target.name]: value
      }
    })
  }


  handleUserChange(e) {
    var userRate = 0;
    if (this.props.members.length > 0) {
      this.props.members.map((user) => {
        if (user.uid === e.target.value) {
          userRate = user.rate
        }
      })
    }

    this.setState({
      transaction: {
        ...this.state.transaction,
        [e.target.name]: e.target.value,
        rate: userRate
      }
    })
  }

  handleDuration(e) {
    var amt = 0
    amt = (parseFloat(e.target.value) * this.state.transaction.rate) * (1 - (this.state.transaction.discount / 100))

    this.setState({
      transaction: {
        ...this.state.transaction,
        [e.target.name]: e.target.value,
        amount: amt
      }
    })
  }


  handleSubmit() {
    const item = {}
    item["uid"] = this.state.transaction.uid
    item["name"] = this.state.transaction.name
    item["user_id"] = this.state.transaction.user_id
    item["rate_type"] = this.state.transaction.rate_type
    item["remarks"] = this.state.transaction.remarks
    item["filed_at"] = this.state.transaction.filed_at
    item["transaction_type_id"] = this.state.defaultTransactionType.uid
    item["amount"] = this.state.transaction.amount * 1

    if (this.state.transaction.is_deduction === true) {
      item["discount"] = 0
    } else {
      if (this.state.transaction.discount !== undefined && this.state.transaction.discount > 0) {
        item["discount"] = parseFloat((this.state.transaction.amount * (this.state.transaction.discount / 100)))
      }
    }
    if (this.state.transaction.rate_type === "fixed") {
      item["duration"] = 0
    } else {
      item["duration"] = this.state.transaction.duration * 1
    }
    var method = ''
    var url = ''
    if (item.uid === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.uid + '/transactions'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.uid + '/transactions/' + item.uid
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {

      const newData = {
        uid: resp.data.uid,
        name: resp.data.name,
        amount: resp.data.amount,
        user: resp.data.user,
        transaction_type: resp.data.transaction_type,
        user_id: resp.data.user_uid,
        rate: resp.data.rate,
        filed_at: resp.data.filed_at,
        transaction_type_id: resp.data.transaction_type_uid,
        is_deduction: resp.data.transaction_type.is_deduction,
        duration: resp.data.duration,
        rate_type: resp.data.rate_type,
        remarks: resp.data.remarks,
        discount: (resp.data.discount / resp.data.amount) * 100,
        initialAmount: parseFloat(resp.data.amount) / (1 - (resp.data.discount / parseFloat(resp.data.amount))),
        can_edit: resp.data.can_edit,
        can_delete: resp.data.can_delete,
        task_uid: resp.data.task_uid
      }
      this.setState({
        transactions: this.state.transactions.filter(transaction => transaction.uid !== item.uid)
      })
      this.setState({ transactions: [...this.state.transactions, newData] })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({ openPopUp: false })

    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Description ' + error.response.data.errors.name[0] })
        this.setState({ error_messages_transaction: 'Transaction type ' + error.response.data.errors.transaction_type[0] })
        this.setState({ error_messages_user: 'User ' + error.response.data.errors.user[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.transaction.uid
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.uid + '/transactions/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        transactions: this.state.transactions.filter(item => item.uid !== DeleteItemId)
      })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
      this.setState({ openDeletePopUp: false })
    }).catch(error => console.log(error.response))
  }


  testNumber(e) {
    this.setState({
      transaction: {
        ...this.state.transaction,
        amount: e.floatValue
      }
    })
  }

  initialAmount(e) {
    this.setState({
      transaction: {
        ...this.state.transaction,
        initialAmount: e.floatValue,
        amount: e.floatValue
      }
    })
  }

  userRateChange(e) {
    this.setState({
      transaction: {
        ...this.state.transaction,
        rate: e.floatValue,
        amount: ((e.floatValue * this.state.transaction.duration) * (1 - (this.state.transaction.discount / 100)))
      }
    })
  }

  discountChange(e) {
    if (e.floatValue !== undefined) {
      if (this.state.transaction.rate_type === "hourly") {
        this.setState({
          transaction: {
            ...this.state.transaction,
            discount: e.floatValue,
            amount: ((this.state.transaction.rate * this.state.transaction.duration) * (1 - (e.floatValue / 100)))
          }
        })
      } else {
        this.setState({
          transaction: {
            ...this.state.transaction,
            discount: e.floatValue,
            amount: ((this.state.transaction.initialAmount) * (1 - (e.floatValue / 100)))
          }
        })
      }


    } else {
      this.setState({
        transaction: {
          ...this.state.transaction,
          discount: 0,
          amount: (this.state.transaction.rate * this.state.transaction.duration)
        }
      })
    }

  }

  handleTransactionType(event, values) {
    
    this.setState({
      defaultTransactionType: values
    })

  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      isOpen: false
    })
  }

  openCreateTransactionType() {
    this.setState({ transactionTypePopUp: true })
  }

  closeTransactionType() {
    this.setState({ transactionTypePopUp: false, defaultTransactionType: {} })
  }

  handleCheck(e) {
    this.setState({
      expenseType: {
        ...this.state.expenseType,
        parent_name: "",
        parent_uid: ''
      }
    })
    this.setState({ newGroup: e.target.checked })
  }

  handleExpenseTypeChange(e) {
    this.setState({
      expenseType: {
        ...this.state.expenseType,
        [e.target.name]: e.target.value
      }
    })
  }

  expenseTypeSubmit() {
		var method = 'post'
		var url = process.env.REACT_APP_API_DOMAIN + '/v1/transaction_types'
		if (this.state.newGroup) {
			const item = {}
			const subItem = {}
			item["name"] = this.state.expenseType.parent_name
			subItem["name"] = this.state.expenseType.name
			subItem["is_deduction"] = this.state.expenseType.is_deduction
			item["is_deduction"] = this.state.expenseType.is_deduction

			axios({
				method: method,
				url: url,
				data: (item),
				headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
			}).then(resp => {
				const newData = resp.data
				if (item.is_deduction) {
					this.setState({ rebateParents: [...this.state.rebateParents, newData] })

				} else {
					this.setState({ expenseParents: [...this.state.expenseParents, newData] })
				}
				subItem["parent_id"] = newData.uid
				axios({
					method: method,
					url: url,
					data: (subItem),
					headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
				}).then(resp => {
					const subData = resp.data
					var transactionItem = {}
					transactionItem["name"] = subData.name
					transactionItem["parent"] = item.name
					transactionItem["is_deduction"] = subData.is_deduction
					transactionItem["uid"] = subData.uid
					transactionItem['transactionParent'] = item.name

					this.setState({ defaultTransactionType: transactionItem })
					if (item.is_deduction) {
						this.setState({ rebates: [...this.state.rebates,  transactionItem] })

					} else {
						this.setState({ expenseTypes: [...this.state.expenseTypes, transactionItem] })
					}
					this.closeTransactionType()
				})
			})

		} else {
			const newItem = {}
			newItem["name"] = this.state.expenseType.name
			newItem["parent_id"] = this.state.expenseType.parent_uid ? this.state.expenseType.parent_uid : ''
			newItem["is_deduction"] = this.state.expenseType.is_deduction
			axios({
				method: method,
				url: url,
				data: (newItem),
				headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
			}).then(resp => {
				const subData = resp.data
				var transactionItem = {}
				transactionItem["name"] = subData.name
				transactionItem["parent"] = [...this.state.expenseParents, ...this.state.rebateParents].filter(item => item.uid === subData.parent_uid)[0].name
				transactionItem["is_deduction"] = subData.is_deduction
				transactionItem["uid"] = subData.uid
				transactionItem['transactionParent'] = [...this.state.expenseParents, ...this.state.rebateParents].filter(item => item.uid === subData.parent_uid)[0].name

				this.setState({ defaultTransactionType: transactionItem })
				if (newItem.is_deduction) {
					this.setState({ rebates: [...this.state.rebates, transactionItem] })
				} else {
					this.setState({ expenseTypes: [...this.state.expenseTypes, transactionItem] })
				}
				this.closeTransactionType()
			})
		}
	}

  render() {
    return (
      <div>
        {
          this.state.load ? (
            <>
              <SimpleTable
                items={this.state.transactions}
                withEdit={this.state.withEdit}
                withShow={this.state.withShow}
                headers={this.state.headers}
                customClassName={this.state.customClassName}
                model="Transaction"
                initialItem={this.state.initialTransaction}
                handleClick={this.handleCreateorUpdateItem}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                removeLabel={true}
              />

              <SimplePopUp
                openPopup={this.state.openPopUp}
                maxWidth="md"
                title={this.state.title}
                handleClose={this.handleClose}
              >

                <>
                  <TransactionForm
                    error={this.state.error_messages}
                    error_trasaction={this.state.error_messages_transaction}
                    error_user={this.state.error_messages_user}
                    transaction={this.state.transaction}
                    handleSubmit={this.handleSubmit}
                    handleInputChange={this.handleInputChange}
                    testNumber={this.testNumber}
                    users={this.props.users}
                    members={this.props.members}
                    expenseTypes={this.state.expenseTypes}
                    handleSwitch={this.handleSwitch}
                    handleUserChange={this.handleUserChange}
                    handleDuration={this.handleDuration}

                    rebates={this.state.rebates}
                    handleTransactionType={this.handleTransactionType}
                    defaultTransactionType={this.state.defaultTransactionType}
                    userRateChange={this.userRateChange}
                    discountChange={this.discountChange}
                    initialAmount={this.initialAmount}
                    openCreateTransactionType={this.openCreateTransactionType}
                  />

                </>
              </SimplePopUp>
              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopUp}
                item={this.state.transaction}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}

              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
              <SimplePopUp
                openPopup={this.state.transactionTypePopUp}
                maxWidth="xs"
                title="New Transaction Type"
                handleClose={this.closeTransactionType}
              >
                <>
                  <ExpenseTypeForm
                    item={this.state.expenseType}
                    newGroup={this.state.newGroup}
                    items={[...this.state.rebateParents, ...this.state.expenseParents]}
                    handleCheck={this.handleCheck}
                    onchange={this.handleExpenseTypeChange}
                    submit={this.expenseTypeSubmit}
                  />
                </>
              </SimplePopUp>
            </>

          ) : (null)
        }

      </div>
    )
  }
}

export default Transactions

Transactions.propTypes = {
  uid: PropTypes.string,
  users: PropTypes.array,
  members: PropTypes.array
}
