import React from 'react'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
} from '@material-ui/core'
import styles from "./PracticeArea.module.css";

class AreaForm extends React.Component {
  render () {
    return (
      <div>
        <form>
          <TextField
            autoComplete="off"
            className={styles.textFields}
            variant="outlined"
            size="small"
            name="name"
            fullWidth
            label="Name"
            onChange={this.props.onchange}
            value={this.props.item.name}
            id="practiceArea-name"
          />
          <p className={styles.error}>{this.props.error}</p>
        </form>
        <div className={styles.actionButton}>
          <Button disabled={!this.props.item.name} variant="outlined" id="practiceArea-submit" color="primary" onClick={() => this.props.submit()}> Save</Button>
        </div>
      </div>
    )
  }
}
export default AreaForm

AreaForm.propTypes = {
  onchange: PropTypes.func,
  item: PropTypes.object,
  children: PropTypes.object,
  submit: PropTypes.func,
  error: PropTypes.string
}
