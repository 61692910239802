// eslint-disable-next-line
import React from 'react'
import SimplePopUp from '../../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import DocumentForm from './DocumentForm'
import DocumentsTable from './DocumentsTable'
import FolderForm from './FolderForm'
import axios from 'axios'
import DocumentAccessForm from './DocumentAccessForm'
import PropTypes from 'prop-types'
import AlertMessage from '../../../shared/Notify/AlertMessage'

class Documents extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Document',
      documents: [],
      openPopup: false,
      openDeletePopup: false,
      document: {},
      initialDocument: { uid: '', title: '', user_ids: [] },
      title: '',
      load: false,
      tableHead: ['file_name'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      folders: [],
      allFolders: [],
      folderLabel: '',
      parentFolder: '',
      showBack: false,
      currentFolder: {},
      initialFolder: { uid: '', name: '', parent_id: '', description: '' },
      openPopupDocument: false,
      openDeleteFolderPopup: false,
      folder: {},
      openDeleteDocumentPopup: false,
      users: [],
      parentFolders: [],
      folderFromDocsIndex: {},
      selectedUsers: [],
      selectedUserIds: [],
      openDocumentAccess: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.getDocuments = this.getDocuments.bind(this)
    this.handleCreatorUpdateDocument = this.handleCreatorUpdateDocument.bind(this)
    this.handleSubmitFolder = this.handleSubmitFolder.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
    this.handleOpenDeleteDocPopup = this.handleOpenDeleteDocPopup.bind(this)
    this.handleDeleteFolder = this.handleDeleteFolder.bind(this)
    this.handleUsersAutocomplete = this.handleUsersAutocomplete.bind(this)
    this.parentClick = this.parentClick.bind(this)
    this.loadRoot = this.loadRoot.bind(this)
    this.handleAccessChange = this.handleAccessChange.bind(this)
    this.handleSubmitUpdatedDocumentAccess = this.handleSubmitUpdatedDocumentAccess.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
  }

  componentDidMount() {
    if (this.props.folderFromDocsIndex !== undefined) {
      this.setState({ folderFromDocsIndex: this.props.folderFromDocsIndex })
    }
    this.loadDocs(process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.legalCase.uid + '/documents')
  }

  loadDocs(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ documents: resp.data })
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.legalCase.uid + '/folders',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ allFolders: resp.data })
        var newData = []
        resp.data.map((folder) => {
          if (folder.parent_uid === null) {
            newData.push(folder)
          }
        })
        this.setState({ folders: newData })

        if (this.state.folderFromDocsIndex !== undefined && this.state.folderFromDocsIndex.uid !== undefined) {
          let folder = this.props.folderFromDocsIndex
          const allFolders = resp.data
          const folders = [this.props.folderFromDocsIndex]
          while(folder.parent_uid !== null) {
            folder = allFolders.find((item) => item.uid === folder.parent_uid)
            folders.unshift(folder)
          }
          if (folders.length > 0) {
            folders.map((folder) => {
              this.parentClick(folder)
            })
          }
          this.setState({ parentFolders: folders })
        }
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/members',
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then(resp => {
          var docsUsers = []
          if (this.props.legalCase.user_uids.length >= 0) {
            this.props.legalCase.user_uids.map((caseUserId) => {
              resp.data.map((user) => {
                if (user.uid === caseUserId) {
                  docsUsers.push(user)
                }
              })
            })
          } else {
            docsUsers = resp.data
          }
          this.setState({ selectedUsers: docsUsers, users: docsUsers })
          this.setState({ load: true })
        }).catch(error => console.log(error.response))
      }).catch(error => console.log(error.response))
    }).catch(error => console.log(error.response))
  }

  handleUsersAutocomplete(event, values) {
    this.setState({ selectedUsers: values })
  }

  handleSubmit() {
    const item = this.state.document
    var method = ''
    var url = ''
    const file = item.file
    const title = item.file.name
    const formdata = new FormData()
    formdata.append('file', file)
    formdata.append('title', title)
    if (this.state.selectedUsers.length > 0) {
      this.state.selectedUsers.map((user) => {
        formdata.append('user_ids[]', user.uid);
      })
    }

    if (this.state.currentFolder !== null && this.state.currentFolder.uid !== undefined) {
      if (item.uid === '') {
        method = 'post'
        url = process.env.REACT_APP_API_DOMAIN + '/v1/folders/' + this.state.currentFolder.uid + '/documents'
      } else {
        method = 'put'
        url = process.env.REACT_APP_API_DOMAIN + '/v1/folders/' + this.state.currentFolder.uid + '/documents/' + item.uid
      }
    } else {
      if (item.uid === '') {
        method = 'post'
        url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.legalCase.uid + '/documents'
      } else {
        method = 'put'
        url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.legalCase.uid + '/documents/' + item.uid
      }
    }
    axios({
      method: method,
      url: url,
      data: (formdata),
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {

      const newData = resp.data
      this.setState({
        documents: this.state.documents.filter(document => document.uid !== item.uid)
      })
      this.setState({ documents: [...this.state.documents, newData] })
      this.setState({ openPopupDocument: false })
    }).catch(error => console.log(error.response))
  }

  handleSubmitFolder() {
    const item = this.state.folder
    if (this.state.currentFolder !== null && this.state.currentFolder.uid !== undefined) {
      item["parent_id"] = this.state.currentFolder.uid
    }
    var method = ''
    var url = ''
    if (item.uid === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.legalCase.uid + '/folders/'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.legalCase.uid + '/folders/' + item.uid
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = resp.data
      this.setState({
        folders: this.state.folders.filter(folder => folder.uid !== item.uid)
      })
      this.setState({ folders: [...this.state.folders, newData] })
      this.setState({ openPopup: false })
    }).catch(error => console.log(error.response))
  }

  handleSubmitUpdatedDocumentAccess(){
    var item = this.state.document
    var method = 'put'
    var url = process.env.REACT_APP_API_DOMAIN + '/v1/documents/' + item.uid
    var userIds = []
    if(this.state.selectedUsers.length>0){
      this.state.selectedUsers.map((user) => {
        userIds.push(user.uid)
      })
      item["user_uids"] = userIds
      item["user_ids"] = userIds
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = resp.data
      this.setState({
        documents: this.state.documents.filter(document => document.uid !== item.uid)
      })
      this.setState({ documents: [...this.state.documents, newData] })
      this.setState({ openDocumentAccess: false })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => console.log(error.response))
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.document.uid
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/documents/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        documents: this.state.documents.filter(item => item.uid !== DeleteItemId)
      })
      this.setState({ openDeleteDocumentPopup: false })
    }).catch(error => console.log(error.response))
  }

  handleDeleteFolder() {
    const DeleteItemId = this.state.document.uid
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.legalCase.uid + '/folders/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        folders: this.state.folders.filter(item => item.uid !== DeleteItemId)
      })
      this.setState({ openDeleteFolderPopup: false })
    }).catch(error => console.log(error.response))
  }

  handleClose() {
    this.setState({ openPopup: false, openDocumentAccess: false })
    this.setState({ openDeletePopup: false })
    this.setState({ openPopupDocument: false })
    this.setState({ openDeleteDocumentPopup: false })
    this.setState({ openDeleteFolderPopup: false })
  }

  handleCreateorUpdateItem(item, isAdd) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create Folder'
    } else {
      textTitle = 'Edit Folder'
    }
      this.setState({ openPopup: true })
      this.setState({ folder: item })
      this.setState({ title: textTitle })
    }

  handleInputChange(e) {
    this.setState({
      folder: {
        ...this.state.folder,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ document: item })
    this.setState({ openDeleteFolderPopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  getDocuments(uid, folderName, parent, currentFolder) {
    if(currentFolder !== undefined) {
      const indexOfCurrentFolder = this.state.parentFolders.findIndex((folder) => folder.uid === currentFolder.uid)
      if(indexOfCurrentFolder === -1){
        this.setState({ parentFolders: [...this.state.parentFolders, currentFolder] })
      } else{
        this.setState({ parentFolders: [...this.state.parentFolders.slice(0, indexOfCurrentFolder + 1)] })
      }
    }

    this.setState({ parentFolder: parent })
    var newData = []
    if (this.state.allFolders.length > 0) {
      this.state.allFolders.map((folder) => {
        if (folder.parent_uid === uid) {
          newData.push(folder)
        }
      })
    }
    this.setState({ folders: newData })
    this.setState({ folderLabel: this.state.folderLabel + folderName + '/' })

    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/folders/' + uid + '/documents',
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ documents: resp.data })
    }).catch(error => console.log(error.response))
    this.setState({ currentFolder: currentFolder })
  }

  handleCreatorUpdateDocument(item, isAdd) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create Document'
    } else {
      textTitle = 'Edit Document'
    }
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/members',
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      if(item.user_uids > 0) {
        resp.data.map((member) => {
          if(item.user_uids.includes(member.uid)) {
            this.setState({selectedUsers: [...this.state.selectedUsers, member]})
          }
        })
      }
    })
    this.setState({ document: item })
    this.setState({ title: textTitle })
    if(isAdd){
      this.setState({ openPopupDocument: true })
    }else{
      this.setState({openDocumentAccess: true})
    }
  }

  onFileChange(e) {
    e.stopPropagation()
    e.preventDefault()
    this.setState({
      document: {
        ...this.state.document,
        file: e.target.files[0],
        title: e.target.files[0].name
      }
    })
  }

  handleOpenDeleteDocPopup(item) {
    this.setState({ document: item })
    this.setState({ openDeleteDocumentPopup: true })
  }

  parentClick(item) {
    this.setState({ parentFolders: this.state.parentFolders.filter((folder) => folder.uid === item.uid ) })
    this.getDocuments(item.uid, item.name, item.parent_uid, item)
  }

  loadRoot() {
    this.setState({ parentFolders: [] })
    this.setState({ folderFromDocsIndex: {} })
    this.loadDocs(process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.legalCase.uid + '/documents')
  }

  handleAccessChange(e,selectedUser) {
    var selectedUserIds = []
    this.state.selectedUsers.map((user) => {
      selectedUserIds.push(user.uid)
    })
    var selectedUsers = [...this.state.selectedUsers]
    if(selectedUserIds.indexOf(selectedUser.uid) > -1) {
      selectedUsers = selectedUsers.filter(item => item.uid !== selectedUser.uid)
    }
    else{
      selectedUsers.push(this.state.users.find(item => item.uid === selectedUser.uid))
    }
    this.setState({ selectedUsers: selectedUsers })
  }

  handleCloseAlert() {
    this.setState({
      isOpen: false
    })
  }

  render() {
    return (
      <>
        {
          this.state.load ? (
            <div>
              <DocumentsTable
                documents={this.state.documents}
                items={this.state.folders}
                handleClick={this.handleCreateorUpdateItem}
                initialDocument={this.state.initialDocument}
                initialItem={this.state.initialDocument}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShow={this.state.withShow}
                withEdit={this.state.withEdit}
                getDocuments={this.getDocuments}
                folderLabel={this.state.folderLabel}
                parentFolder={this.state.parentFolder}
                showBack={this.state.showBack}
                handleCreatorUpdateDocument={this.handleCreatorUpdateDocument}
                handleOpenDeleteDocPopup={this.handleOpenDeleteDocPopup}
                parentFolders={this.state.parentFolders}
                parentClick={this.parentClick}
                loadRoot={this.loadRoot}
              />
              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.title}
                items={this.state.folders}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <FolderForm item={this.state.folder} items={this.state.folders} id={this.state.legalCase} currentLegalUser={this.state.currentLegalUser} submit={this.handleSubmitFolder} onchange={this.handleInputChange} />
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.openPopupDocument}
                title={this.state.title}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <DocumentForm selectedUsers={this.state.selectedUsers} error={this.state.error_messages} handleUsersAutocomplete={this.handleUsersAutocomplete} users={this.state.users} onFileChange={this.onFileChange} handleFileInputChange={this.handleFileInputChange} document={this.state.document} handleSubmitDocuments={this.handleSubmit} />
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.openDocumentAccess}
                title="Update Document Access"
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <DocumentAccessForm document={this.state.document}
                handleChange={this.handleAccessChange}
                selectedUsers={this.state.selectedUsers}
                users={this.state.users}
                handleSubmitDocuments={this.handleSubmitUpdatedDocumentAccess}
                />
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeleteDocumentPopup}
                item={this.state.document}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model="Document"
              />
              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeleteFolderPopup}
                item={this.state.folder}
                delete={this.handleDeleteFolder}
                handleDeleteClose={this.handleClose}
                model="Folder"
              />
              <AlertMessage
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            null
          )}
      </>
    )
  }
}

export default Documents

Documents.propTypes = {
  legalCase: PropTypes.object,
  folderFromDocsIndex: PropTypes.object
}
