import React from 'react'

import {
  Typography, IconButton, ButtonGroup, Table, TableHead, TableRow, TableCell, TableBody, TableContainer
} from '@material-ui/core'

import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import PropTypes from 'prop-types'
import styles from '../Client.module.css'
import Tooltip from '@material-ui/core/Tooltip'
class Addresses extends React.Component {

  render() {
    return (
      <>
        <div className={styles.addressBody}>
          <div className={styles.flex}>
            {this.props.addresses.length > 0 ? (
              this.props.addresses
                .map((address, index) => (
                  index < 1 &&
                  <div key={address.uid} className="flex-justify" name="client_addresses">
                    <div className={styles.addressList}>
                      <Typography name="client_address">{address.content} &nbsp; - &nbsp;
                        <i>{address.category_name === 'permanent' ? ('P') : ("C")}</i>
                      </Typography>
                      <Typography name="client_address_category">

                      </Typography>
                    </div>
                  </div>
                ))

            ) : (
              <>
                <div className={styles.noAddress}>
                  <Typography colSpan={3}>No Saved Address</Typography>
                </div>
              </>

            )}
            {
              this.props.noAction ? null :


                <div className={styles.buttonshoverAddress}>
                  <Tooltip title="Add Address">
                    <IconButton color="inherit" aria-label="menu"
                      name="create-address"
                      size="small"
                      onClick={() => this.props.handleClick('Add Address', 'address', this.props.initialAddress)}
                    >
                      <AddCircleOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
            }
            {this.props.addresses.length > 0 ? (
              <div className={styles.addressSeeBody}>
                <div className={styles.seeAddress} >
                  <a>See More</a>
                </div>
                <div className={styles.addressContent}>


                  <TableContainer>
                    <Table size="small" stickyHeader aria-label="customized sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell className={styles.cell}><b>Address</b></TableCell>
                          <TableCell className={styles.cell}><b>Category Name</b></TableCell>
                          <TableCell className={styles.cell} align="center"><b>Action</b></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <>
                          {this.props.addresses.length > 0 ? (
                            this.props.addresses.sort(
                              (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
                            ).reverse()
                              .map((address) => (
                                <TableRow key={address.uid} className={styles.tableRowBody}>
                                  <TableCell className={styles.cell}>
                                    {address.content}
                                  </TableCell>
                                  <TableCell className={styles.cell}> {address.category_name}</TableCell>
                                  <TableCell className={styles.cell}>
                                    {
                                      this.props.noAction ? null :
                                        <ButtonGroup aria-label="outlined primary button group">
                                          <IconButton
                                            variant="contained"
                                            color="primary"
                                            name={`address-edit`}
                                            onClick={() => this.props.handleClick('Edit Address', 'address', address)}
                                          >
                                            <EditIcon />
                                          </IconButton>
                                          <IconButton
                                            variant="contained"
                                            color="secondary"
                                            name={`address-delete`}
                                            onClick={() => this.props.handleOpenDelete('address', address)}

                                          >
                                            <DeleteForeverIcon />
                                          </IconButton>
                                        </ButtonGroup>
                                    }

                                  </TableCell>
                                </TableRow>
                              ))) : null
                          }
                        </>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </>
    )
  }
}

export default Addresses

Addresses.propTypes = {
  addresses: PropTypes.array,
  initialAddress: PropTypes.object,
  handleClick: PropTypes.func,
  handleOpenDelete: PropTypes.func,
  noAction: PropTypes.bool
}
