import React from 'react'
import axios from 'axios'

import SimpleTable from '../../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import CustomForm from './CustomForm'
import AlertMessage from '../../../shared/Notify/AlertMessage'
import ProgressBar from '../../../shared/ProgressBar/ProgressBar'
import ErrorPage from '../../../shared/ErrorPage/ErrorPage'

class CustomFieldsClient extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Client Custom Field',
      customFields: [],
      openPopup: false,
      openDeletePopup: false,
      customField: {},
      initialCustomField: { uid: '', attr_name: '', attr_type: '', klass: 0 },
      title: '',
      load: false,
      tableHead: ['attr_name', 'attr_type'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      error_messages_type: '',
      isOpen: false,
      message: '',
      type: '',
      loadClientsCustomFieldError: null
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
  }

  componentDidMount() {
    this.loadClientsCustomFields(
      process.env.REACT_APP_API_DOMAIN + '/v1/clients/custom_fields'
    )
  }

  loadClientsCustomFields(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        this.setState({ customFields: resp.data })
        this.setState({ load: true })
      })
      .catch((error) =>
        this.setState({ loadClientsCustomFieldError: error.response.status })
      )
  }

  handleSubmit() {
    this.setState({ error_messages_type: '' })
    const item = this.state.customField
    var method = ''
    var url = ''
    if (item.uid === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/custom_fields/'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/custom_fields/' + item.uid
    }
    axios({
      method: method,
      url: url,
      data: item,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        console.log(resp.data)
        const newData = resp.data
        this.setState({
          customFields: this.state.customFields.filter(
            (customField) => customField.uid !== item.uid
          )
        })
        this.setState({ customFields: [...this.state.customFields, newData] })
        this.setState({
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success'
        })
        this.setState({ openPopup: false })
      })
      .catch((error) => {
        if (error.response.status === 422) {
          if (error.response.data.errors.attr_type !== undefined) {
            this.setState({
              error_messages_type:
                'Attribute type ' + error.response.data.errors.attr_type[0]
            })
          } else {
            this.setState({
              error_messages:
                'Attribute Name ' + error.response.data.errors.attr_name[0]
            })
          }
        } else {
          this.setState({
            isOpen: true,
            message:
              error.response.status.toString() +
              ' Unexpected Error Problem Occurred',
            type: 'error'
          })
          this.setState({ openPopup: false })
        }
      })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.customField.uid
    axios({
      method: 'delete',
      url:
        process.env.REACT_APP_API_DOMAIN + '/v1/custom_fields/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then(() => {
        this.setState({
          customFields: this.state.customFields.filter(
            (item) => item.uid !== DeleteItemId
          )
        })
        this.setState({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'error'
        })
        this.setState({ openDeletePopup: false })
      })
      .catch((error) => console.log(error.response))
  }

  handleClose() {
    this.setState({ openPopup: false })
    this.setState({ openDeletePopup: false })
    this.setState({ error_messages: '' })
    this.setState({ error_messages_type: '' })
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({ openPopup: true })
    this.setState({ customField: item })
    this.setState({ title: textTitle })
  }

  handleInputChange(e) {
    console.log(e.target.name)
    console.log(e.target.value)

    this.setState({
      customField: {
        ...this.state.customField,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ customField: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  render() {
    const { load, loadClientsCustomFieldError } = this.state

    return (
      <>
        {load && !loadClientsCustomFieldError ? (
          <div>
            <SimpleTable
              handleClick={this.handleCreateorUpdateItem}
              initialItem={this.state.initialCustomField}
              items={this.state.customFields}
              model={this.state.model}
              onOpenDeletePopup={this.handleOpenDeletePopup}
              headers={this.state.tableHead}
              withShow={this.state.withShow}
              withEdit={this.state.withEdit}
            />
            <SimplePopUp
              openPopup={this.state.openPopup}
              title={this.state.title}
              items={this.state.customFields}
              handleClose={this.handleClose}
              maxWidth={this.state.maxWidth}
            >
              <CustomForm
                error={this.state.error_messages}
                errorType={this.state.error_messages_type}
                item={this.state.customField}
                submit={this.handleSubmit}
                onchange={this.handleInputChange}
              />
            </SimplePopUp>
            <AlertMessage
              notify={this.state.notify}
              handleCloseAlert={this.handleCloseAlert}
              isOpen={this.state.isOpen}
              type={this.state.type}
              message={this.state.message}
            />

            <SimpleDeletePopUp
              openDeletePopup={this.state.openDeletePopup}
              item={this.state.customField}
              delete={this.handleDeleteItem}
              handleDeleteClose={this.handleClose}
              model={this.state.model}
            />
          </div>
        ) : loadClientsCustomFieldError ? (
          <ErrorPage type={loadClientsCustomFieldError} />
        ) : (
          <ProgressBar model="Client Custom Field" />
        )}
      </>
    )
  }
}

export default CustomFieldsClient
