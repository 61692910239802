import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import PersonIcon from '@material-ui/icons/Person'

import ContactPersons from "../Client/ContactPerson/ContactPersons";
import Addresses from "../Client/Address/Addresses";
import ContactNumbers from "../Client/ContactNumber/ContactNumbers";
import EmailAddresses from "../Client/EmailAddress/EmailAddresses";
import { Link } from 'react-router-dom'

import styles from "./LegalCase.module.css";



class ClientModalShow extends React.Component {
  render() {
    return (
      <>
       
            <div className="flex">

              <div className={styles.listAvatarNameShowClient}>
                <Avatar className={styles.avatarSize}>
                  <PersonIcon className={styles.iconSize}/>
                </Avatar>

                <div className={styles.name}>
                  <Link to={'/clients/' + this.props.client.uid} target="_blank">{this.props.client.name}</Link>
                  <div className={styles.showClientName}>
                    <div>
                      {this.props.client.group.name}
                    </div>
                    {
                      this.props.client.is_company ? (
                        <span> - Company</span>
                      ) : (
                        <span> - Individual </span>
                      )
                    }
                    {
                      this.props.client.is_active ? (
                        <span> - Active </span>
                      ) : (
                        <span> - Inactive </span>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
            <hr/>
            <div className={styles.clientShowBodyLegalCaseList}>
            {
              this.props.client.is_company ? (
                <ContactPersons contactPersons={this.props.contactPersons} test={this.props.client.is_company} noAction={true} />
              ) : (
                <>
                  <Addresses addresses={this.props.addresses} noAction={true}/>
                  <ContactNumbers contactNumbers={this.props.contactNumbers}  noAction={true}/>
                  <EmailAddresses emailAddresses={this.props.emailAddresses} noAction={true} />
                </>
              )
            }
            </div>

           
      </>
    )
  }
}

export default ClientModalShow

ClientModalShow.propTypes = {
  client: PropTypes.array,
  addresses: PropTypes.array,
  contactNumbers: PropTypes.array,
  emailAddresses: PropTypes.array,
  contactPersons: PropTypes.array,
}
