import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography, IconButton, Table, TableHead, TableRow, TableCell, TableBody, TableContainer
} from '@material-ui/core'
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import RecentActorsIcon from '@material-ui/icons/RecentActors'
import ShowContactAttributes from './ShowContactAttributes'
import styles from '../Client.module.css'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

class ContactPersons extends React.Component {
  render() {
    return (
      <TableContainer style={{ maxHeight: '575px' }}>
        <Typography className="flex-justify">
          <h2 className={styles.contactTitle}>
            <RecentActorsIcon fontSize="large" />
            Contact Persons
          </h2>
          {
            this.props.noAction ? null :
              <IconButton color="inherit" aria-label="menu"
                onClick={() => this.props.handleClick('Add Contact Person', this.props.initialContactPerson, true)}
                name="create-contactPerson"
              >
                <AddCircleOutlinedIcon fontSize="medium" />
              </IconButton>
          }

        </Typography>
        <Table size="small" stickyHeader name="contact_persons_table" aria-label="customized sticky table">
          <TableHead>
            <TableRow>
              <TableCell className={styles.cell}>Name</TableCell>
              <TableCell className={styles.cell}>Designation</TableCell>
              <TableCell className={styles.cell}>Department</TableCell>
              <TableCell className={styles.cell}>Remarks</TableCell>
              <TableCell>
                <TableCell className={styles.customCell}>
                  Telephone
                </TableCell>
                <TableCell className={styles.customCellEmail}>
                  Email
                </TableCell>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {this.props.contactPersons.sort(
                (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
              ).reverse()
                .map((contactPerson) => (
                  <TableRow key={contactPerson.uid} className={styles.tableRowBody}>
                    <TableCell className={styles.cell}>
                      {contactPerson.name}
                    </TableCell>
                    <TableCell className={styles.cell}> {contactPerson.designation}</TableCell>
                    <TableCell className={styles.cell}>{contactPerson.department}</TableCell>
                    <TableCell className={styles.cell}>
                      {contactPerson.remarks}
                    </TableCell>

                    <TableCell className={styles.customCellValue}>
                      <ShowContactAttributes uid={contactPerson.uid} />
                    </TableCell>
                    {
                      this.props.noAction ? null :

                        <div className={styles.buttonsHover}>
                          <IconButton
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => this.props.handleClick('Edit Contact Person', contactPerson, false)}
                            name={'contact_person_edit'}
                          >
                            <EditIcon fontSize="medium" />
                          </IconButton>
                          <IconButton
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() => this.props.handleOpenDelete('contact person', contactPerson)}
                            name={'contact_person_delete'}
                          >
                            <DeleteIcon fontSize="medium" />
                          </IconButton>
                        </div>
                    }
                  </TableRow>
                ))
              }
            </>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}
export default ContactPersons
ContactPersons.propTypes = {
  contactPersons: PropTypes.array,
  handleClick: PropTypes.func,
  test: PropTypes.boolean,
  initialContactPerson: PropTypes.object,
  handleOpenDelete: PropTypes.func,
  noAction: PropTypes.bool
}