import React from 'react'

import PropTypes from 'prop-types'

import styles from './Invoices.module.css'

import {
  TextField,
  Button,
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  TableContainer
} from '@material-ui/core'

class BulkInvoiceForm extends React.Component {
  render() {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={2}>
            <Typography variant="body1">Filter Cases :</Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth="true"
              id="ends_at"
              type="date"
              variant="outlined"
              size="small"
              style={{width: '90%'}}
              format="yyyy-MM-dd"
              label="Starts on from"
              name="starts_on_from"
              onChange={this.props.handleInputChange}
              value={this.props.caseFilter.starts_on_from}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth="true"
              id="ends_at"
              type="date"
              variant="outlined"
              size="small"
              style={{width: '90%'}}
              format="yyyy-MM-dd"
              label="Starts on to"
              name="starts_on_to"
              value={this.props.caseFilter.starts_on_to}
              onChange={this.props.handleInputChange}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Button
              onClick={this.props.filterCases}
              className={styles.submitButton}
              color="primary" variant="outlined">Search</Button>
          </Grid>

        </Grid>
        <br/>
        <div item xs={12} sm={4} className={styles.invoiceListright}>
          <div className={styles.flex}>
            <label><b>Invoice Date :</b></label>
            <TextField
              variant="outlined"
              type="date"
              format="yyyy-MM-dd"
              onChange={this.props.handleBulkInvoiceDate}
              value={this.props.bulkInvoiceDate}
              name="bulkInvoiceDate"
              size="small"
            />
          </div>
        </div>
        <br/>
        {
          this.props.legalCases.length > 0 ? (
            <>
              <TableContainer style={{maxHeight: '575px'}}>
                <h2>Cases</h2>
                <Table size="small" stickyHeader aria-label="customized sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={styles.cell}>
                        <Checkbox
                          checked={this.props.selectedCases.length === this.props.legalCases.length}
                          onClick={(event) => this.props.selectAll(event)}
                          name="selectAll"
                        /></TableCell>
                      <TableCell className={styles.cell}>Reference no.</TableCell>
                      <TableCell className={styles.cell}>Title</TableCell>
                      <TableCell className={styles.cell}>Client Name</TableCell>
                      <TableCell className={styles.cell}>Date Filed</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>

                      {this.props.legalCases.sort(
                        (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
                      ).reverse()
                        .map((item, idx) => (

                          <TableRow key={idx}>
                            <TableCell className={styles.cell}>
                              <Checkbox
                                checked={this.props.selectedCases.indexOf(item.uid) > -1}
                                onClick={(event) => this.props.handleToggle(event, item)}
                                name={`selectCase-${idx}`}
                              /></TableCell>
                            <TableCell className={styles.cell}><strong>{item.reference_no}</strong></TableCell>
                            <TableCell className={styles.cell}><strong>{item.title}</strong></TableCell>
                            <TableCell className={styles.cell}><strong>
                              {
                                item.client_uids !== null ? (
                                  this.props.clients.length > 0 ? (
                                    this.props.clients.map((client) =>
                                      item.client_uids.map((clientId) =>
                                        clientId === client.uid ? (
                                          <ul>{client.name}</ul>
                                        ) : null
                                      )
                                    )) : null
                                ) : null
                              }
                            </strong></TableCell>
                            <TableCell
                              className={styles.cell}><strong>{new Date(item.date_filed).toLocaleDateString('en-us', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit'
                            })}</strong></TableCell>
                          </TableRow>
                        ))

                      }
                    </>

                  </TableBody>
                </Table>
              </TableContainer>
              <br/>

            </>
          ) : ("All transactions are added to invoice details")
        }
        <div className={styles.actionButton}>
          <Button
            onClick={this.props.createBulkInvoice}
            name="create-bulk-invoice"
            color="primary" variant="outlined"
            disabled={this.props.selectedCases.length <= 0}
          >Create Bulk Invoice</Button>
        </div>
      </div>
    )
  }
}

export default BulkInvoiceForm

BulkInvoiceForm.propTypes = {

  legalCases: PropTypes.array,
  handleInputChange: PropTypes.func,
  handleAutoComplete: PropTypes.func,
  users: PropTypes.array,
  clients: PropTypes.array,
  filterCases: PropTypes.func,
  createBulkInvoice: PropTypes.func,
  caseFilter: PropTypes.object,
  handleToggle: PropTypes.func,
  selectedCases: PropTypes.array,
  selectAll: PropTypes.func,
  bulkInvoiceDate: PropTypes.string,
  handleBulkInvoiceDate: PropTypes.func

}
