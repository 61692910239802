import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Redirect } from 'react-router'
import { withRouter } from 'react-router-dom'
import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import ClientForm from './ClientForm'
import GroupForm from './GroupForm'
import SearchForm from './SearchForm'
import AlertMessage from '../../shared/Notify/AlertMessage'
import ErrorPage from '../../shared/ErrorPage/ErrorPage'
import ProgressBar from '../../shared/ProgressBar/ProgressBar'
import { Box } from '@material-ui/core'
import styles from './Client.module.css'

export const Component = withRouter(() => {})

class Clients extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Client',
      clients: [],
      draftClients: [],
      openPopup: false,
      openDeletePopup: false,
      client: {},
      addresses: [{ content: '', category: 0 }],
      address: { content: '', category: 0 },
      emailAddress: { content: '', category: 0 },
      contactNumber: { content: '', category: 0 },
      contactNumbers: [{ content: '', category: 0 }],
      emailAddresses: [{ content: '', category: 0 }],
      contactPerson: { name: '', designation: '', department: '', remarks: '' },
      contactPersons: [
        { name: '', designation: '', department: '', remarks: '' }
      ],
      groups: [],
      openGroup: false,
      initialClient: {
        uid: '',
        name: '',
        group_id: '',
        is_company: false,
        is_active: true,
        is_taxable: false,
        fields: {}
      },
      cfield: {},
      expanded: false,
      title: '',
      load: false,
      tableHead: ['Name', 'group.name', 'is_company', 'is_active'],
      group: {},
      withShow: true,
      maxWidth: 'md',
      fields: [],
      defaultGroup: {},
      redirect: false,
      openAdvanceSearch: false,
      searchClient: {},
      q: '',
      notify: {},
      error_messages: '',
      isOpen: false,
      message: '',
      type: '',
      withPagination: false,
      totalPages: 1,
      currentPage: 0,
      searchUrl: process.env.REACT_APP_API_DOMAIN + '/v1/clients',
      errorMessages: {},
      promiseAllError: null
    }
    this.clearSearch = this.clearSearch.bind(this)
    this.loadClients = this.loadClients.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.addClient = this.addClient.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.removeAddress = this.removeAddress.bind(this)
    this.addAddress = this.addAddress.bind(this)
    this.handleAddressChange = this.handleAddressChange.bind(this)
    this.removeEmail = this.removeEmail.bind(this)
    this.addEmailAddress = this.addEmailAddress.bind(this)
    this.handleEmailAddressChange = this.handleEmailAddressChange.bind(this)
    this.removeContactNumber = this.removeContactNumber.bind(this)
    this.addContactNumber = this.addContactNumber.bind(this)
    this.handleContactNumberChange = this.handleContactNumberChange.bind(this)
    this.handleNumberChange = this.handleNumberChange.bind(this)
    this.removeContactPerson = this.removeContactPerson.bind(this)
    this.addContactPerson = this.addContactPerson.bind(this)
    this.handleContactPersonChange = this.handleContactPersonChange.bind(this)
    this.addGroup = this.addGroup.bind(this)
    this.handleGroupChange = this.handleGroupChange.bind(this)
    this.openGroupForm = this.openGroupForm.bind(this)
    this.handleExpandClick = this.handleExpandClick.bind(this)
    this.handleField = this.handleField.bind(this)
    this.handleCloseGroup = this.handleCloseGroup.bind(this)
    this.handleAutoComplete = this.handleAutoComplete.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleOpenAdvanceSearch = this.handleOpenAdvanceSearch.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.clientSearch = this.clientSearch.bind(this)
    this.handleChangeQ = this.handleChangeQ.bind(this)
    this.handleSearchChanges = this.handleSearchChanges.bind(this)
    this.handleGroupSearch = this.handleGroupSearch.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
  }

  componentDidMount() {
    var searchUrl = this.state.searchUrl + this.props.location.search
    var searchParams = {}
    var perPage = ''
    const params = new URLSearchParams(this.props.location.search)
    params.forEach(function (value, key) {
      if (key !== 'page' && key !== 'group_uid') {
        searchParams[key] = value.replace(/\*/g, '')
      }
      if (key === 'per_page') {
        perPage = value
      }
      if (key === 'group_uid' && value !== null) {
        axios({
          method: 'get',
          url:
            process.env.REACT_APP_API_DOMAIN +
            '/v1/groups/' +
            JSON.parse(value.replace(/\*/g, '')),
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then((resp) => {
          searchParams['group'] = resp.data
        })
      }
    })
    if (perPage) {
      this.setState({ perPage: perPage })
    }
    this.setState({ searchClient: searchParams })
    this.loadClients(searchUrl)
  }

  loadClients(url) {
    Promise.all([
      axios({
        method: 'get',
        url: url,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        this.setState({ clients: resp.data.data, openAdvanceSearch: false })
        this.setState({ currentPage: resp.data.meta.current_page })
        this.setState({ totalPages: resp.data.meta.total_pages })
      }),
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/groups',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        this.setState({ groups: resp.data.data })
      }),
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/custom_fields',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        this.setState({ fields: resp.data })
      }),
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/clients',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        this.setState({ draftClients: resp.data.data })
        this.setState({ load: true })
      })
    ]).catch((error) =>
      this.setState({ promiseAllError: error.response.status })
    )
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.client.uid
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then(() => {
        this.setState({
          clients: this.state.clients.filter(
            (item) => item.uid !== DeleteItemId
          )
        })
        this.setState({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'error'
        })
        this.setState({ openDeletePopup: false })
      })
      .catch((error) => console.log(error.response))
  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ client: item })
    this.setState({ openDeletePopup: true })
  }

  handleCreateorUpdateItem(client) {
    this.setState({ client: client })
    this.setState({ openPopup: true })
  }

  handleClose() {
    this.setState({ openPopup: false })
    this.setState({ expanded: false })
    this.setState({ openDeletePopup: false })
    this.setState({ error_messages: null })
    this.setState({ errorMessages: {} })
  }

  handleCloseGroup() {
    this.setState({ openGroup: false })
  }

  handleExpandClick() {
    this.setState({ expanded: !this.state.expanded })
  }

  addClient() {
    var item = {}
    item['name'] = this.state.client.name
    item['uid'] = this.state.client.uid
    item['group_id'] = this.state.defaultGroup.uid
    item['is_active'] = this.state.client.is_active
    item['is_company'] = this.state.client.is_company
    item['is_taxable'] = this.state.client.is_company
    item['fields'] = this.state.client.fields
    if (this.state.client.is_company) {
      item['contact_persons_attributes'] = this.state.contactPersons
      item['addresses_attributes'] = this.state.addresses
    } else {
      item['addresses_attributes'] = this.state.addresses
      item['email_addresses_attributes'] = this.state.emailAddresses
      item['contact_numbers_attributes'] = this.state.contactNumbers
    }
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/clients',
      data: item,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        const newData = resp.data
        this.setState({
          clients: this.state.clients.filter(
            (client) => client.uid !== item.uid
          )
        })
        this.setState({ clients: [...this.state.clients, newData] })
        this.setState({
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success'
        })
        this.setState({ redirect: true })
        this.setState({ client: newData })
        this.setState({ errorMessages: {} })
        this.setState({ openPopup: false })
      })
      .catch((error) => {
        if (error.response.status === 422) {
          var errors = {}
          Object.keys(error.response.data.errors).map((field) => {
            errors[field] = error.response.data.errors[field][0]
            if (field.includes('.')) {
              errors[field.split('.')[1]] = error.response.data.errors[field][0]
            } else {
              errors[field] = error.response.data.errors[field][0]
            }
          })
          this.setState({ errorMessages: errors })
        } else {
          this.setState({
            isOpen: true,
            message:
              error.response.status.toString() +
              ' Unexpected Error Problem Occurred',
            type: 'error'
          })
          this.setState({ openPopup: false })
        }
      })
  }

  handleChange(e) {
    this.setState({
      client: {
        ...this.state.client,
        [e.target.name]: e.target.value
      }
    })
  }

  handleSwitch(event) {
    const value = event.target.checked
    this.setState({
      client: {
        ...this.state.client,
        [event.target.name]: value
      }
    })
  }

  removeAddress(idx) {
    this.state.addresses.splice(idx, 1)
    this.setState({ addresses: [...this.state.addresses] })
  }

  handleAddressChange(e) {
    const updatedAddress = [...this.state.addresses]
    updatedAddress[e.target.dataset.id][e.target.dataset.fieldType] =
      e.target.value
    this.setState({ addresses: updatedAddress })
  }

  addAddress() {
    this.setState({
      addresses: [...this.state.addresses, { ...this.state.address }]
    })
  }

  removeEmail(idx) {
    this.state.emailAddresses.splice(idx, 1)
    this.setState({ emailAddresses: [...this.state.emailAddresses] })
  }

  handleEmailAddressChange(e) {
    const updatedEmailAddress = [...this.state.emailAddresses]
    updatedEmailAddress[e.target.dataset.id][e.target.dataset.fieldType] =
      e.target.value
    this.setState({ emailAddresses: updatedEmailAddress })
  }

  addEmailAddress() {
    this.setState({
      emailAddresses: [
        ...this.state.emailAddresses,
        { ...this.state.emailAddress }
      ]
    })
  }

  removeContactNumber(idx) {
    this.state.contactNumbers.splice(idx, 1)
    this.setState({ contactNumbers: [...this.state.contactNumbers] })
  }

  handleNumberChange(e) {
    const re = /^[0-9\b]+$/
    if (e.target.value === '' || re.test(e.target.value)) {
      const updatedContactNumbers = [...this.state.contactNumbers]
      updatedContactNumbers[e.target.dataset.id][e.target.dataset.fieldType] =
        e.target.value
      this.setState({ contactNumbers: updatedContactNumbers })
    }
  }

  handleContactNumberChange(e) {
    const updatedContactNumbers = [...this.state.contactNumbers]
    updatedContactNumbers[e.target.dataset.id][e.target.dataset.fieldType] =
      e.target.value
    this.setState({ contactNumbers: updatedContactNumbers })
  }

  addContactNumber() {
    this.setState({
      contactNumbers: [
        ...this.state.contactNumbers,
        { ...this.state.contactNumber }
      ]
    })
  }

  removeContactPerson(idx) {
    this.state.contactPersons.splice(idx, 1)
    this.setState({ contactPersons: [...this.state.contactPersons] })
  }

  handleContactPersonChange(e) {
    const updatedContactPersons = [...this.state.contactPersons]
    updatedContactPersons[e.target.dataset.id][e.target.dataset.fieldType] =
      e.target.value
    this.setState({ contactPersons: updatedContactPersons })
  }

  addContactPerson() {
    this.setState({
      contactPersons: [
        ...this.state.contactPersons,
        { ...this.state.contactPerson }
      ]
    })
  }

  addGroup() {
    const item = this.state.group

    axios({
      method: 'post',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/groups/',
      data: item,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        var newData = resp.data

        this.setState({
          groups: this.state.groups.filter((group) => group.uid !== item.uid)
        })
        this.setState({ groups: [...this.state.groups, newData] })
        this.setState({
          client: {
            ...this.state.client,
            group_id: resp.data.uid
          }
        })
        this.setState({ defaultGroup: resp.data })

        this.setState({ openGroup: false })
      })
      .catch((error) => console.log(error.response))
  }

  handleGroupChange(e) {
    this.setState({
      group: {
        ...this.state.group,
        [e.target.name]: e.target.value
      }
    })
  }
  openGroupForm() {
    this.setState({ group: { uid: '', name: '' } })
    this.setState({ openGroup: true })
  }

  handleField(e) {
    const value = e.target.value
    this.setState({
      cfield: {
        ...this.state.cfield,
        [e.target.name]: value
      }
    })
    this.setState({
      client: {
        ...this.state.client,
        fields: JSON.stringify({
          ...this.state.cfield,
          [e.target.name]: value
        })
      }
    })
  }

  handleAutoComplete(event, values) {
    this.setState({ defaultGroup: values })
  }

  handleOpenAdvanceSearch() {
    this.setState({ openAdvanceSearch: !this.state.openAdvanceSearch })
  }
  clearSearch() {
    const { history } = this.props
    history.push({ search: '' })
    this.setState({ searchClient: {} })
    this.setState({ q: '' })
  }
  handleKeyPress(e) {
    if (e.key === 'Enter') {
      if (this.state.q === '') {
        this.setState({ clients: this.state.draftClients })
      } else {
        axios({
          method: 'get',
          url:
            process.env.REACT_APP_API_DOMAIN +
            '/v1/clients?q=*' +
            this.state.q +
            '*',
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then((resp) => {
          this.setState({ clients: resp.data.data })
        })
      }
      const params = new URLSearchParams()
      if (this.state.q === '') {
        this.props.history.push({ search: '' })
      } else {
        params.append('q', this.state.q)
        this.props.history.push({ search: params.toString() })
      }
    }
  }
  getSearchParams() {
    var searchParams = []
    Object.entries(this.state.searchClient).map(([key, value]) => {
      if (
        value != '' &&
        value != undefined &&
        value != null &&
        key !== 'group' &&
        key !== 'group_id'
      ) {
        searchParams.push(
          [key, key !== 'page' ? '*' + value + '*' : value].join('=')
        )
      }
      if (key === 'group' && value !== null && value.uid !== undefined) {
        searchParams.push(
          'group_uid=' + JSON.stringify(this.state.searchClient.group.uid)
        )
      }
    })

    return searchParams
  }

  clientSearch() {
    const { history } = this.props
    var searchParams = this.getSearchParams().join('&')
    const params = new URLSearchParams()
    params.append('/search', searchParams)
    history.push({ search: searchParams })
    this.loadClients([this.state.searchUrl, searchParams].join('?'), false)
  }

  handleChangeQ(e) {
    this.setState({ q: e.target.value })
  }
  handleSearchChanges(e) {
    this.setState({
      searchClient: {
        ...this.state.searchClient,
        [e.target.name]: e.target.value
      }
    })
  }
  handleGroupSearch(event, values) {
    this.setState({
      searchClient: {
        ...this.state.searchClient,
        group: values
      }
    })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    this.setState({
      isOpen: false
    })
  }

  handlePageChange(event, value) {
    var searchParams = this.getSearchParams()
    searchParams.push(['page', value].join('='))
    searchParams = searchParams.join('&')
    const { history } = this.props
    const params = new URLSearchParams()
    params.append('/search', searchParams)
    history.push({ search: searchParams })
    this.setState({ currentPage: value })
    this.loadClients([this.state.searchUrl, searchParams].join('?'))
  }

  render() {
    const { redirect, load, promiseAllError } = this.state

    if (redirect)
      return (
        <Redirect
          to={{
            pathname: '/clients/' + this.state.client.uid,
            state: { item: this.state.client }
          }}
        />
      )

    return (
      <>
        {load && !promiseAllError ? (
          <>
          <Box className={styles.boxMain}>
            <SimpleTable
              initialItem={this.state.initialClient}
              items={this.state.clients}
              model={this.state.model}
              headers={this.state.tableHead}
              withShow={this.state.withShow}
              handleClick={this.handleCreateorUpdateItem}
              onOpenDeletePopup={this.handleOpenDeletePopup}
              handleAdvanced={this.handleOpenAdvanceSearch}
              _handleKeyPress={this.handleKeyPress}
              handleChanges={this.handleChangeQ}
              withSearch={true}
              clearSearch={this.clearSearch}
              q={this.state.q}
              withPagination={true}
              handlePageChange={this.handlePageChange}
              currentPage={this.state.currentPage}
              totalPages={this.state.totalPages}
            />
          </Box>

            <SimplePopUp
              openPopup={this.state.openPopup}
              title="New Client"
              items={this.state.clients}
              handleClose={this.handleClose}
              maxWidth={this.state.maxWidth}
            >
              <ClientForm
                error={this.state.errorMessages}
                submit={this.addClient}
                groups={this.state.groups}
                client={this.state.client}
                handleChange={this.handleChange}
                handleSwitch={this.handleSwitch}
                addresses={this.state.addresses}
                handleAddressChange={this.handleAddressChange}
                removeAddress={this.removeAddress}
                addAddress={this.addAddress}
                emailAddresses={this.state.emailAddresses}
                handleEmailAddressChange={this.handleEmailAddressChange}
                removeEmail={this.removeEmail}
                addEmailAddress={this.addEmailAddress}
                contactNumbers={this.state.contactNumbers}
                handleContactNumberChange={this.handleContactNumberChange}
                removeContactNumber={this.removeContactNumber}
                addContactNumber={this.addContactNumber}
                handleNumberChange={this.handleNumberChange}
                contactPersons={this.state.contactPersons}
                handleContactPersonChange={this.handleContactPersonChange}
                removeContactPerson={this.removeContactPerson}
                addContactPerson={this.addContactPerson}
                openGroupForm={this.openGroupForm}
                handleAutoComplete={this.handleAutoComplete}
                defaultGroup={this.state.defaultGroup}
                cfield={this.state.cfield}
                fields={this.state.fields}
                handleField={this.handleField}
                expanded={this.state.expanded}
                handleExpandClick={this.handleExpandClick}
              />
            </SimplePopUp>

            <SimplePopUp
              openPopup={this.state.openGroup}
              title="Add Group"
              handleClose={this.handleCloseGroup}
              maxWidth="xs"
            >
              <GroupForm
                group={this.state.group}
                handleChange={this.handleGroupChange}
                addGroup={this.addGroup}
              />
            </SimplePopUp>

            <SimpleDeletePopUp
              openDeletePopup={this.state.openDeletePopup}
              item={this.state.client}
              delete={this.handleDeleteItem}
              handleDeleteClose={this.handleClose}
              model={this.state.model}
            />

            <SimplePopUp
              openPopup={this.state.openAdvanceSearch}
              title="Search Client"
              handleClose={this.handleOpenAdvanceSearch}
              maxWidth="sm"
            >
              <SearchForm
                groups={this.state.groups}
                searchClient={this.state.searchClient}
                clientSearch={this.clientSearch}
                handleInputChange={this.handleSearchChanges}
                handleAutoComplete={this.handleGroupSearch}
              />
            </SimplePopUp>
            <AlertMessage
              notify={this.state.notify}
              handleCloseAlert={this.handleCloseAlert}
              isOpen={this.state.isOpen}
              type={this.state.type}
              message={this.state.message}
            />
          </>
        ) : promiseAllError ? (
          <ErrorPage type={promiseAllError} />
        ) : (
          <ProgressBar />
        )}
      </>
    )
  }
}
export default withRouter(Clients)

Clients.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}
