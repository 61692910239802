import React from 'react'
import axios from 'axios'

import ExpenseTable from './ExpenseTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import ExpenseTypeForm from './ExpenseTypeForm'
import TransactionType from './TransactionType'
import AlertMessage from '../../shared/Notify/AlertMessage'
import ProgressBar from '../../shared/ProgressBar/ProgressBar'
import ErrorPage from '../../shared/ErrorPage/ErrorPage'

class Income extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Transaction Category',
      expenseTypes: [],
      openPopup: false,
      openDeletePopup: false,
      expenseType: {},
      initialExpenseType: {
        uid: '',
        name: '',
        parent_uid: '',
        is_deduction: true
      },
      title: '',
      load: false,
      tableHead: ['Group Name', 'Divisions'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      nameSearch: '',
      openShowPopUp: false,
      showItems: [],
      fromShowPopUp: false,
      deleteFromShowPopUp: false,
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: '',
      loadExpenseTypesError: null
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleOpenShowPopUp = this.handleOpenShowPopUp.bind(this)
    this.handleCreateorUpdate = this.handleCreateorUpdate.bind(this)
    this.handleSubmitFromShow = this.handleSubmitFromShow.bind(this)
    this.handleDeleteFromShow = this.handleDeleteFromShow.bind(this)
    this.handleCloseFromShow = this.handleCloseFromShow.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
  }

  componentDidMount() {
    this.loadExpenseTypes(
      process.env.REACT_APP_API_DOMAIN + '/v1/transaction_types?per_page=10000'
    )
  }

  loadExpenseTypes(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        console.log(resp.data.data)
        this.setState({ expenseTypes: resp.data.data })
        this.setState({ load: true })
      })
      .catch((error) =>
        this.setState({ loadExpenseTypesError: error.response.status })
      )
  }

  handleSubmit() {
    const item = {}
    item['uid'] = this.state.expenseType.uid
    item['name'] = this.state.expenseType.name
    item['parent_id'] = this.state.expenseType.parent_uid
    item['is_deduction'] = true
    var method = ''
    var url = ''
    if (item.uid === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/transaction_types/'
    } else {
      method = 'put'
      url =
        process.env.REACT_APP_API_DOMAIN + '/v1/transaction_types/' + item.uid
    }
    axios({
      method: method,
      url: url,
      data: item,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        console.log(resp.data)
        const newData = resp.data
        this.setState({
          expenseTypes: this.state.expenseTypes.filter(
            (expenseType) => expenseType.uid !== item.uid
          )
        })
        this.setState({ expenseTypes: [...this.state.expenseTypes, newData] })
        this.setState({
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success'
        })
        this.setState({ openPopup: false })
      })
      .catch((error) => {
        if (error.response.status === 422) {
          this.setState({
            error_messages: 'Name ' + error.response.data.errors.name[0]
          })
        } else {
          this.setState({
            isOpen: true,
            message:
              error.response.status.toString() +
              ' Unexpected Error Problem Occurred',
            type: 'error'
          })
          this.setState({ openPopup: false })
        }
      })
  }
  handleSubmitFromShow() {
    const item = {}
    item['uid'] = this.state.expenseType.uid
    item['name'] = this.state.expenseType.name
    item['parent_id'] = this.state.expenseType.parent_uid
    item['is_deduction'] = true
    var method = ''
    var url = ''
    if (item.uid === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/transaction_types/'
    } else {
      method = 'put'
      url =
        process.env.REACT_APP_API_DOMAIN + '/v1/transaction_types/' + item.uid
    }
    axios({
      method: method,
      url: url,
      data: item,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        console.log(resp.data)
        const newData = resp.data
        this.setState({
          expenseTypes: this.state.expenseTypes.filter(
            (expenseType) => expenseType.uid !== item.uid
          )
        })
        this.setState({ expenseTypes: [...this.state.expenseTypes, newData] })
        this.setState({
          showItems: this.state.showItems.filter(
            (expenseType) => expenseType.uid !== item.uid
          )
        })
        this.setState({ showItems: [...this.state.showItems, newData] })
        this.setState({
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success'
        })
        this.setState({ fromShowPopUp: false })
      })
      .catch((error) => {
        if (error.response.status === 422) {
          this.setState({
            error_messages: 'Name ' + error.response.data.errors.name[0]
          })
        } else {
          this.setState({
            isOpen: true,
            message:
              error.response.status.toString() +
              ' Unexpected Error Problem Occurred',
            type: 'error'
          })
          this.setState({ openPopup: false })
        }
      })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.expenseType.uid

    axios({
      method: 'delete',
      url:
        process.env.REACT_APP_API_DOMAIN +
        '/v1/transaction_types/' +
        DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then(() => {
        this.setState({
          expenseTypes: this.state.expenseTypes.filter(
            (item) => item.uid !== DeleteItemId
          )
        })
        this.setState({
          showItems: this.state.showItems.filter(
            (item) => item.uid !== DeleteItemId
          )
        })
        this.setState({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'error'
        })
        this.setState({ openDeletePopup: false })
      })
      .catch((error) => console.log(error.response))
  }

  handleDeleteFromShow() {
    const DeleteItemId = this.state.expenseType.uid

    axios({
      method: 'delete',
      url:
        process.env.REACT_APP_API_DOMAIN +
        '/v1/transaction_types/' +
        DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then(() => {
        this.setState({
          expenseTypes: this.state.expenseTypes.filter(
            (item) => item.uid !== DeleteItemId
          )
        })
        this.setState({ openDeletePopup: false })
        this.setState({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'error'
        })
        this.setState({ openDeletePopup: false })
      })
      .catch((error) => console.log(error.response))
  }

  handleClose() {
    this.setState({ openPopup: false })
    this.setState({ openDeletePopup: false })
    this.setState({ openShowPopUp: false })
    this.setState({ error_messages: '' })
  }

  handleCreateorUpdateItem(item, isAdd) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create Category'
    } else {
      textTitle = 'Edit Category'
    }

    this.setState({ openPopup: true })
    this.setState({ expenseType: item })
    this.setState({ title: textTitle })
  }

  handleCreateorUpdate(item) {
    var textTitle = 'Edit Category'

    this.setState({ fromShowPopUp: true })
    this.setState({ expenseType: item })
    this.setState({ title: textTitle })
  }

  handleInputChange(e) {
    console.log(e.target.name)
    console.log(e.target.value)

    this.setState({
      expenseType: {
        ...this.state.expenseType,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item) {
    this.setState({ expenseType: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  handleOpenShowPopUp(item) {
    this.setState({ openShowPopUp: true })

    var expenses = [item]
    if (this.state.expenseTypes.length > 0) {
      this.state.expenseTypes.map((expense) => {
        if (expense.parent_uid === item.uid) {
          expenses.push(expense)
        }
      })
    }
    this.setState({ showItems: expenses })
  }

  handleCloseFromShow() {
    this.setState({ fromShowPopUp: false })
    this.setState({ deleteFromShowPopUp: false })
    this.setState({ openDeletePopup: false })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    this.setState({
      isOpen: false
    })
  }

  render() {
    const { load, loadExpenseTypesError } = this.state

    return (
      <>
        {load && !loadExpenseTypesError ? (
          <div>
            <ExpenseTable
              handleClick={this.handleCreateorUpdateItem}
              initialItem={this.state.initialExpenseType}
              items={this.state.expenseTypes}
              model={this.state.model}
              onOpenDeletePopup={this.handleOpenDeletePopup}
              headers={this.state.tableHead}
              withShow={this.state.withShow}
              withEdit={this.state.withEdit}
              isDeduction={true}
              handleOpenShowPopUp={this.handleOpenShowPopUp}
            />
            <SimplePopUp
              openPopup={this.state.openPopup}
              title={this.state.title}
              items={this.state.expenseTypes}
              handleClose={this.handleClose}
              maxWidth={this.state.maxWidth}
            >
              <ExpenseTypeForm
                error={this.state.error_messages}
                item={this.state.expenseType}
                items={this.state.expenseTypes}
                submit={this.handleSubmit}
                onchange={this.handleInputChange}
              />
            </SimplePopUp>

            <SimplePopUp
              openPopup={this.state.fromShowPopUp}
              title={this.state.title}
              items={this.state.expenseTypes}
              handleClose={this.handleCloseFromShow}
              maxWidth={this.state.maxWidth}
            >
              <ExpenseTypeForm
                error={this.state.error_messages}
                item={this.state.expenseType}
                items={this.state.expenseTypes}
                submit={this.handleSubmitFromShow}
                onchange={this.handleInputChange}
              />
            </SimplePopUp>

            <SimplePopUp
              openPopup={this.state.openShowPopUp}
              title="Transaction Category"
              handleClose={this.handleClose}
              maxWidth="sm"
            >
              <TransactionType
                handleClick={this.handleCreateorUpdate}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                items={this.state.showItems}
              />
            </SimplePopUp>

            <SimpleDeletePopUp
              openDeletePopup={this.state.openDeletePopup}
              item={this.state.expenseType}
              delete={this.handleDeleteItem}
              handleDeleteClose={this.handleCloseFromShow}
              model={this.state.model}
            />
            <AlertMessage
              notify={this.state.notify}
              handleCloseAlert={this.handleCloseAlert}
              isOpen={this.state.isOpen}
              type={this.state.type}
              message={this.state.message}
            />
          </div>
        ) : loadExpenseTypesError ? (
          <ErrorPage type={loadExpenseTypesError} />
        ) : (
          <ProgressBar />
        )}
      </>
    )
  }
}

export default Income
