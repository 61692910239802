import React from 'react'
import PropTypes from 'prop-types'
import { TextField, IconButton, InputLabel, Grid } from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import styles from "../../Tasks/Tasks.module.css";
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';

class EmailAddress extends React.Component{

    render(){
  const contentId = `client_email_address_${this.props.idx}`
  const categoryId = `client_email_category_${this.props.idx}`
  const deleteButton = `delete_client_email_address_${this.props.idx}`
  return (
    <form style={{ display: 'flex' }} key={`content-${this.props.idx}`} name="client_email_address_contents">
      <br />
      <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="off"
            className={styles.formTextfield}
            fullWidth
            variant="outlined" required
            name={contentId}
            id={contentId}
            size="small"
            data-idx={this.props.idx}
            value={this.props.emailAddresses[this.props.idx].content}
            onChange={this.props.handleEmailAddressChange}
            label="Email Address"
            inputProps={{ 'data-id': this.props.idx, 'data-field-type': 'content' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth className={styles.formTextfield} size="small" variant="outlined" >
            <InputLabel>Category</InputLabel>
            <Select
              native
              id={categoryId}
              fullWidth
              label="category"
              variant="outlined"
              size="small"
              data-idx={this.props.idx}
              name={categoryId}
              value = {this.props.emailAddresses[this.props.idx].category || 0}
              onChange={this.props.handleEmailAddressChange}
              inputProps={{
                name: categoryId,
                id: 'outlined-age-native-simple',
                'data-id': this.props.idx,
                'data-field-type': 'category'
              }}
            >
              <option value="" disabled selected>Select Category</option>
              <option value={0}>Personal Email</option>
              <option value={1}>Work Email</option>
              <option value={2}>Others</option>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
        <IconButton
          color="secondary"
          name={deleteButton}
          onClick={() => this.props.removeEmail(this.props.idx)}>
          <DeleteForeverIcon />
        </IconButton>

      </>

    </form>
  )
    }
}
export default EmailAddress

EmailAddress.propTypes = {
  idx: PropTypes.number,
  emailAddresses: PropTypes.array,
  handleEmailAddressChange: PropTypes.func,
  removeEmail: PropTypes.func

}
