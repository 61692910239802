import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import SimpleTable from '../../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import styles from "../LegalCase.module.css";
class Workflow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      workflows: [],
      model: "Workflow",
      headers: ["parent_workflow.name","Actions"],
      openPopUp: false,
      title: "Apply a Workflow",
      withEdit: false,
      withShow: false,
      legalCaseWorkflow: { uid: '', workflow_id: '', dateLabel: new Date().toLocaleDateString(('fr-CA')), triggers: [] },
      subs: [],
      parentWorkFlows: [],
      load: false,
      triggers: [],
      loadTriggers: false,
      legalWorkFlows: [],
      fromLegalCaseWorkflows: [],
      withShowPopUp: true,
      showPopUp: false,
      subsInShow: [],
      showSubs: false
    }
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.setParents = this.setParents.bind(this)
    this.selectChange = this.selectChange.bind(this)
    this.handleTriggers = this.handleTriggers.bind(this)
    this.submitWorkflows = this.submitWorkflows.bind(this)
    this.handleShowPopUp=this.handleShowPopUp.bind(this)
    this.handleAssign=this.handleAssign.bind(this)
  }
  componentDidMount() {
    this.loadWorkflow(process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.uid + '/workflows')
  }
  loadWorkflow() {

    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/workflows',
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      var newData = []
      var subData = []
      resp.data.data.map((item) => {
        if (item.parent_uid === null) {
          return newData.push(item)
        } else {
          return subData.push(item)
        }
      })
      this.setState({ workflows: newData })
      this.setState({ subs: subData })
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.uid + '/legal_case_workflows',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ fromLegalCaseWorkflows: resp.data })
        this.setState({ load: true })
        this.setParents(resp.data)
      }).catch(error => console.log(error.response))
    }).catch(error => console.log(error.response))
  }
  setParents(items) {
    var parents = []
    if (items.length > 0) {
      items.map((fromLegalCaseWorkflow) => {
        if(parents.length>0){
          parents.map((parent)=>{
            if(parent.parent_workflow.name===fromLegalCaseWorkflow.parent_workflow.name){
              parents.splice(parents.indexOf(parent), 1)
            }
          })
        }
        parents.push(fromLegalCaseWorkflow)
      })
    }
    this.setState({ parentWorkFlows: parents })
  }
  handleShowPopUp(item){
    this.setState({showPopUp: true})
    console.log(item)
    var subWorkFlows = []
    if(this.state.fromLegalCaseWorkflows.length>0){
      this.state.fromLegalCaseWorkflows.map((legalCaseWorkflow)=>{
        if(legalCaseWorkflow.parent_workflow.uid===item.parent_workflow.uid){
          subWorkFlows.push(legalCaseWorkflow)
        }
      })
    }
    this.setState({subsInShow: subWorkFlows})
    this.setState({showSubs: true})
  }
  handleTriggers(e) {
    const updatedTriggers = [...this.state.legalWorkFlows]
    updatedTriggers[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
    this.setState({ legalWorkFlows: updatedTriggers })
  }
  handleAssign(e) {
    const updatedTriggers = [...this.state.legalWorkFlows]
    updatedTriggers[e.target.dataset.id][e.target.dataset.fieldType] = JSON.parse(e.target.value)
    this.setState({ legalWorkFlows: updatedTriggers })
  }
  handleDeleteItem() {
    this.state.fromLegalCaseWorkflows.map((caseworkflow)=>{
      if(caseworkflow.parent_workflow.uid===this.state.workflow.parent_workflow.uid){
        console.log(caseworkflow)
        axios({
          method: 'delete',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.uid + '/legal_case_workflows/'+caseworkflow.uid,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then(() => {
          this.setState({
            fromLegalCaseWorkflows: this.state.fromLegalCaseWorkflows.filter(item => item.uid !== caseworkflow.uid)
          })
          this.setParents(this.state.fromLegalCaseWorkflows.filter(item => item.uid !== caseworkflow.uid))
          this.setState({ openDeletePopup: false })
          this.setState({ triggers: [] })
        }).catch(error => console.log(error.response))
      }
    })
  }
  handleClose() {
    this.setState({ openPopUp: false })
    this.setState({ openDeletePopup: false })
    this.setState({ loadTriggers: false })
    this.setState({ triggers: [] })
    this.setState({showPopUp: false})
  }
  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ workflow: item })
    this.setState({ openDeletePopup: true })
  }
  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }
  handleSubmit() {
    const newData = this.state.legalWorkFlows.map((item) => ({
      uid: '',
      workflow_id: item.workflow_id,
      starts_at: item.starts_at,
      assign_to_all: item.assign_to_all
    }))
    if (newData.length > 0) {
      this.submitWorkflows(newData)
    }
  }
  submitWorkflows(items) {
    console.log(items)
    items.map((item) => {
      console.log(item)
      axios({
        method: 'post',
        data: (item),
        url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.uid + '/legal_case_workflows',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then(resp => {
        console.log(resp.data)
        const newData = resp.data
        this.setState({fromLegalCaseWorkflows: [...this.state.fromLegalCaseWorkflows, newData]})
        this.setParents([...this.state.fromLegalCaseWorkflows, newData])
      }).catch(error => console.log(error.response))
    }, 3000)
    this.setState({ openPopUp: false })
    this.setState({ triggers: [] })
    this.setState({legalWorkFlows: []})
  }
  handleCreateorUpdateItem(item, isAdd) {
    var textTitle = ''
    if (isAdd) {
      textTitle = "Apply a Workflow"
    } else {
      textTitle = "Edit Workflow"
    }
    this.setState({ openPopUp: true })
    this.setState({ workflow: item })
    this.setState({ title: textTitle })
  }
  handleInputChange(e) {
    console.log(e.target.name)
    console.log(e.target.value)
    console.log(e.target.id)
    this.setState({
      legalCaseWorkflow: {
        ...this.state.legalCaseWorkflow,
        [e.target.name]: e.target.value,
        workflow_id: e.target.id
      }
    })
  }
  selectChange(e) {
    console.log(e.target.name)
    console.log(e.target.value)
    var url = e.target.value
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/workflows/' + url + '/subs',
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      console.log(resp.data)
      this.setState({ triggers: resp.data })
      this.setState({ loadTriggers: true })
      const newData = resp.data.map((item) => ({
        uid: '',
        workflow_id: item.uid,
        starts_at: new Date().toLocaleDateString(('fr-CA')),
        name: item.name,
        assign_to_all: true
      }))
      console.log("this is newdata :", newData)
      this.setState({ legalWorkFlows: newData })
    })
  }
  render() {
    return (
      <div>{
        this.state.load ? (
          <div>
            <SimpleTable
              handleClick={this.handleCreateorUpdateItem}
              initialItem={this.state.initialWorkflow}
              items={this.state.parentWorkFlows}
              model={this.state.model}
              onOpenDeletePopup={this.handleOpenDeletePopup}
              headers={this.state.headers}
              withShow={this.state.withShow}
              withEdit={this.state.withEdit}
              withShowPopUp={this.state.withShowPopUp}
              handleShowPopUp={this.handleShowPopUp}
              removeLabel={true}
            />
            <SimplePopUp
              openPopup={this.state.openPopUp}
              title={this.state.title}
              items={this.state.workflows}
              handleClose={this.handleClose}
              maxWidth="xs"
            >
              <form>
                <Typography>Choose a Workflow</Typography>
                <Select
                  fullWidth
                  variant="outlined"
                  className={styles.chooseWork}
                  name="workflow_uid"
                  onChange={this.selectChange}
                >
                  {
                    this.state.workflows.length > 0 ? (
                      this.state.workflows.map((workflow) => (
                        <MenuItem key={workflow.uid} value={workflow.uid}>{workflow.name}</MenuItem>
                      ))
                    ) : (null)
                  }
                </Select>
                <div>
                  {
                    this.state.legalWorkFlows.length > 0 ? (
                      this.state.legalWorkFlows.map((val, idx) => (
                        <>
                          <br />
                          <Typography variant="body1" style={{textTransform: 'capitalize', fontWeight: 'bold'}} key={idx}>{this.state.legalWorkFlows[idx].name}</Typography>
                          <TextField
                            fullWidth
                            className={styles.workFlowDate}
                            size="small"
                            style={{ height: '40px', width: '100%' }}
                            variant="outlined"
                            type="date"
                            format="yyyy-MM-dd"
                            onChange={this.handleTriggers}
                            value={this.state.legalWorkFlows[idx].starts_at}
                            name="starts_at"
                            inputProps={{ 'data-id': idx, 'data-field-type': 'starts_at' }}
                          />
                          <br /><br />
                          <FormControl fullWidth="true" size="small" variant="outlined" >
																<InputLabel htmlFor="outlined-age-native-simple">User</InputLabel>
																<Select
																	native
																	onChange={this.handleAssign}
																	value={this.state.legalWorkFlows[idx].assign_to_all}
																	label="User"
																	fullWidth="true"
                                  style={{ height: '40px', width: '100%' }}
																	inputProps={{
																		'data-id': idx,
																		'data-field-type': 'assign_to_all',
																		name: 'User',
																		id: 'outlined-age-native-simple',
																	}}
																>
                                  <option value={true}>Assign to all</option>
                                  <option value={false}>Assign to curent user</option>
																</Select>
															</FormControl>
                              <br />
                        </>
                      ))
                    ) : (null)
                  }
                </div>
              </form>
              <div className={styles.actionButton}>
                <Button variant="outlined" color="primary" onClick={this.handleSubmit} >Save</Button>
              </div>
            </SimplePopUp>
            <SimpleDeletePopUp
              openDeletePopup={this.state.openDeletePopup}
              item={this.state.legalCaseWorkflow}
              delete={this.handleDeleteItem}
              handleDeleteClose={this.handleClose}
              model="workflow"
            />

            <SimplePopUp
              openPopup={this.state.showPopUp}
              title="Show Date Labels"
              handleClose={this.handleClose}
              maxWidth="xs"

            >
              {
                this.state.showSubs?(
                  <>
                    {
                      this.state.subsInShow.length>0?(
                        this.state.subsInShow.map((sub)=>(
                          <div key={sub.uid} styles={{display: "flex"}}>
                            <Typography className={styles.subName} ><b>{sub.workflow.name}</b></Typography>
                            <div className={styles.subDate}>{sub.starts_at}</div>
                          </div>
                        ))
                      ):(null)
                    }
                  </>
                ):("No Subs")
              }

            </SimplePopUp>
          </div>
        ) : (null)
      }</div>
    )
  }
}
export default Workflow
Workflow.propTypes = {
  uid: PropTypes.string
}
