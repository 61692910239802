import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import SimpleTable from './../../../shared/SimpleTable/SimpleTable'
import SimplePopUp from './../../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from './../../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import TransactionForm from './../Transaction/TransactionForm'
import styles from './Invoice.module.css'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Table from '@material-ui/core/Table'
import SelectExistingTransactionForm from './SelectExistingTransactionForm'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { Tooltip } from "@material-ui/core";

class Invoice extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

      model: "Invoice Detail",
      headers: ["user.first_name", "name", "transaction_type.name", "transaction_record.filed_at", "rate", "duration", "discount", "is_deduction", "amount"],
      openPopUp: false,
      openDeletePopUp: false,
      withEdit: true,
      load: false,
      title: "",
      invoice: {},
      transactions: [],
      invoiceDetails: [],
      invoiceDetail: {},
      transaction: {},
      openInvoicePopUp: false,
      initialInvoiceDetail: { uid: '', transaction_record_uid: '', amount: 0, rate: 0 },
      initialTransaction: { transaction_record: { uid: '' }, uid: '', name: '', rate: 0, rate_type: 'hourly', initialAmount: 0, amount: 0, discount: 0, transaction_type_id: '', user_id: '', remarks: '', duration: 0, is_deduction: false, filed_at: new Date().toLocaleDateString(('fr-CA')) },
      defaultTransactionType: {},
      transMenuPopUp: [],
      selectExistingArray: [],
      filterSelection: '',
      expenseTypes: [],
      rebates: [],
      filterField: 'name',
      users: [],
      members: [],
      customClassName: 'invoiceShowHeader',
      transactionTypePopUp: false,
      openSelectExistingTransaction: false,
      openTransactionForm: false,
      transactionTitle: '',
      clients: [],
      biller: {},
      prevPath: ''

    }
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.testNumber = this.testNumber.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.getTotal = this.getTotal.bind(this)
    this.handleCreateorUpdateInvoice = this.handleCreateorUpdateInvoice.bind(this)
    this.handleInvoiceChange = this.handleInvoiceChange.bind(this)
    this.handleSubmitInvoice = this.handleSubmitInvoice.bind(this)
    this.amountChange = this.amountChange.bind(this)
    this.rateChange = this.rateChange.bind(this)
    this.submitChecked = this.submitChecked.bind(this)
    this.handleCheckExisting = this.handleCheckExisting.bind(this)
    this.selectFilter = this.selectFilter.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.getTotalExpenses = this.getTotalExpenses.bind(this)
    this.getTotalDiscounts = this.getTotalDiscounts.bind(this)
    this.getSubTotal = this.getSubTotal.bind(this)
    this.getVat = this.getVat.bind(this)
    this.getGrandTotal = this.getGrandTotal.bind(this)
    this.existingTransaction = this.existingTransaction.bind(this)
    this.newTransaction = this.newTransaction.bind(this)
    this.handleSubmitTransaction = this.handleSubmitTransaction.bind(this)
    this.transactionTestNumber = this.transactionTestNumber.bind(this)
    this.handleInputTransactionChange = this.handleInputTransactionChange.bind(this)

    this.handleSwitch = this.handleSwitch.bind(this)
    this.handleUserChange = this.handleUserChange.bind(this)
    this.handleDuration = this.handleDuration.bind(this)
    this.handleTransactionType = this.handleTransactionType.bind(this)

    this.userRateChange = this.userRateChange.bind(this)
    this.discountChange = this.discountChange.bind(this)

    this.initialAmount = this.initialAmount.bind(this)
    this.submitInvoiceDetails = this.submitInvoiceDetails.bind(this)


  }

  componentDidMount() {
    if (this.props.location.state !== undefined) {

				this.setState({ invoice: this.props.location.state.item })
				this.loadInvoiceDetails(this.props.location.state.item.uid)
      this.setState({ prevPath: this.props.location.state.prevPath })

    }
    else {
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + this.props.match.params.uid,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ invoice: resp.data })
        this.setState({ prevPath: '/' })
        this.loadInvoiceDetails(resp.data.uid)

      })
        .catch(resp => console.log(resp))
    }
  }
  loadInvoiceDetails(id) {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + id + '/invoice_details',
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then(resp => {
        const newData = resp.data.map((item) => ({
          uid: item.uid,
          transaction_record_id: item.transaction_record_uid,
          transaction_record: item.transaction_record,
          name: item.transaction_record.name,
          amount: item.amount,
          user_id: item.transaction_record.user.uid,
          user: item.transaction_record.user,
          rate: item.rate,
          discount: (item.transaction_record.discount / item.transaction_record.amount) * 100,
          filed_at: item.transaction_record.filed_at,
          transaction_type: item.transaction_record.transaction_type,
          transaction_type_id: item.transaction_record.transaction_type_uid,
          is_deduction: item.transaction_record.is_deduction,
          duration: item.transaction_record.duration,
          rate_type: item.transaction_record.rate_type,
          remarks: item.transaction_record.remarks,
          initialAmount: item.amount / (1 - (item.transaction_record.discount / item.amount)),
          can_edit: item.can_edit,
          can_delete: item.can_delete
        }
        ))
        var a = []
        var b = []
        newData.map((newItem) => {
          if (newItem.is_deduction === false) {
            a.push(newItem)
          } else {
            b.push(newItem)
          }
        })
        this.setState({ invoiceDetails: [...a, ...b] })
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.state.invoice.legal_case_uid + '/transactions',
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }, 2000).then(resp => {

          const newData = resp.data.map((item) => ({
            uid: '',
            transaction_record_id: item.uid,
            name: item.name,
            amount: item.amount,
            user_id: item.user_uid,
            rate: item.rate,
            discount: (item.discount / item.amount) * 100,
            filed_at: item.filed_at,
            transaction_type_id: item.transaction_type_uid,
            is_deduction: item.transaction_type.is_deduction,
            duration: item.duration,
            rate_type: item.rate_type,
            remarks: item.remarks,
            initialAmount: item.amount / (1 - (item.discount / item.amount))
          }
          ))
          var a = []
          var b = []
          newData.map((newItem) => {
            if (newItem.is_deduction === false) {
              a.push(newItem)
            } else {
              b.push(newItem)
            }
          })
          this.setState({ transactions: [...a, ...b] })
          axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/transaction_types',
            headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
          }).then(resp => {
            var newSetItem = []
            var rebateItems = []
            resp.data.data.map((parent) => {
              if (parent.parent_uid === null && parent.is_deduction === false) {
                resp.data.data.map((child) => {
                  var newItem = {}
                  if (parent.uid === child.parent_uid) {
                    newItem["uid"] = child.uid
                    newItem["name"] = child.name
                    newItem["parent"] = parent.name
                    newItem["is_deduction"] = child.is_deduction
                    newSetItem.push(newItem)
                  }
                })
              }
              if (parent.parent_uid === null && parent.is_deduction === true) {
                resp.data.data.map((child) => {
                  var rebateItem = {}
                  if (parent.uid === child.parent_uid) {
                    rebateItem["uid"] = child.uid
                    rebateItem["name"] = child.name
                    rebateItem["parent"] = parent.name
                    rebateItem["is_deduction"] = child.is_deduction
                    rebateItems.push(rebateItem)
                  }
                })
              }
            })
            this.setState({ expenseTypes: newSetItem })
            this.setState({ rebates: rebateItems })
            axios({
              method: 'get',
              url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.state.invoice.legal_case_uid,
              headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
            }).then(resp => {
              this.setState({ users: resp.data.user_uids })

              axios({
                method: 'get',
                url: process.env.REACT_APP_API_DOMAIN + '/v1/members',
                headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
              }).then(resp => {
                const newData = resp.data.map((item, idx) => ({
                  uid: item.uid,
                  username: item.username,
                  first_name: item.first_name,
                  email: item.email,
                  middle_name: item.middle_name,
                  last_name: item.last_name,
                  rate: parseFloat(1000 + (1000 * idx))
                }))
                this.setState({ members: newData })
                axios({
                  method: 'get',
                  url: process.env.REACT_APP_API_DOMAIN + '/v1/clients',
                  headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
                }).then(resp => {
                  this.setState({ clients: resp.data.data })
                  var clientBiller = {}
                  resp.data.data.map((client) => {
                    if (client.uid === this.state.invoice.legal_case.biller_uid) {
                      clientBiller = client
                    }
                  })
                  this.setState({ biller: clientBiller })
                  this.setState({ load: true })

                })
              })
            })

          })
        })
      })
  }

  newTransaction() {
    this.setState({ transactionTitle: "Create Transaction" })
    this.setState({ transaction: this.state.initialTransaction })
    this.setState({ transactionTypePopUp: false })
    this.setState({ openTransactionForm: true })
  }

  handleSubmitInvoice() {
    const item = this.state.invoice
    axios({
      method: 'put',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.state.invoice.legal_case_uid + '/invoices/' + item.uid,
      data: (item),
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = resp.data
      this.setState({ invoice: newData })
      this.setState({ openInvoicePopUp: false })
      localStorage.setItem('updatedInvoice', JSON.stringify(newData))
    }).catch(error => console.log(error.response))
  }

  handleClose() {
    this.setState({ openPopUp: false })
    this.setState({ openDeletePopUp: false })

    this.setState({ openInvoicePopUp: false })
    this.setState({ transactionTypePopUp: false })
    this.setState({ openSelectExistingTransaction: false })
    this.setState({ openTransactionForm: false })


  }

  handleCreateorUpdateItem(item, addState) {
    if (addState === true) {
      this.setState({ transactionTypePopUp: true })
      this.setState({ transaction: item })

    }
    else {
      this.setState({ openTransactionForm: true })
      this.setState({ invoiceDetail: item })
      this.setState({ defaultTransactionType: item.transaction_type })
      this.setState({ transactionTitle: "Edit Transaction" })
      this.setState({ transaction: item })
    }


  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ invoiceDetail: item })
    this.setState({ openDeletePopUp: true })
  }

  handleCreateorUpdateInvoice() {

    this.setState({ openInvoicePopUp: true })
    this.setState({ title: 'Edit Invoice' })

  }

  handleInvoiceChange(e) {
    var value = e.target.value
    this.setState({
      invoice: {
        ...this.state.invoice,
        [e.target.name]: value
      }
    })
  }

  handleInputChange(e) {
    var value = e.target.value
    this.setState({
      invoiceDetail: {
        ...this.state.invoiceDetail,
        [e.target.name]: value
      }
    })
  }
  handleSelectChange(e) {
    var value = e.target.value
    var amt = 0
    if (this.state.transactions.length > 0) {
      this.state.transactions.map((transaction) => {
        if (e.target.value === transaction.uid) {
          amt = transaction.amount
        }
      })
    }
    this.setState({
      invoiceDetail: {
        ...this.state.invoiceDetail,
        transaction_record_uid: value,
        amount: amt

      }
    })
  }
  getTotal() {
    var total = 0
    if (this.state.invoiceDetails.length > 0) {
      this.state.invoiceDetails.map((transaction) => {
        total = total + parseFloat(transaction.amount)
      })
    }
    return total.toLocaleString(navigator.language, { minimumFractionDigits: 2 })
  }



  testNumber() {
    const updatedTransaction = [...this.state.transactions]
    this.setState({ transactions: updatedTransaction })
  }
  amountChange(e) {
    this.setState({
      invoiceDetail: {
        ...this.state.invoiceDetail,
        amount: e.floatValue
      }
    })
  }
  rateChange(e) {
    this.setState({
      invoiceDetail: {
        ...this.state.invoiceDetail,
        rate: e.floatValue
      }
    })
  }

  handleSubmit() {
    const item = this.state.invoiceDetail
    item["transaction_record_id"] = this.state.invoiceDetail.transaction_record_uid
    item["amount"] = this.state.invoiceDetail.amount
    item["rate"] = this.state.invoiceDetail.rate
    var method = ''
    var url = ''

    if (item.uid === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + this.state.invoice.uid + '/invoice_details'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + this.state.invoice.uid + '/invoice_details/' + item.uid
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = resp.data
      this.setState({
        invoiceDetails: this.state.invoiceDetails.filter(invoiceDetail => invoiceDetail.uid !== item.uid)
      })
      this.setState({ invoiceDetails: [...this.state.invoiceDetails, newData] })
      this.setState({ openPopUp: false })
    }).catch(error => console.log(error.response))
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.invoiceDetail.uid
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + this.state.invoice.uid + '/invoice_details/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        invoiceDetails: this.state.invoiceDetails.filter(item => item.uid !== DeleteItemId)
      })
      this.setState({ openDeletePopUp: false })
    }).catch(error => console.log(error.response))
  }

  handleSearch(e) {
    this.setState({ filterSelection: e.target.value })

  }
  selectFilter(e) {
    this.setState({ filterField: e.target.value })
  }

  submitChecked() {
    this.state.selectExistingArray.map((transaction) => {
      var newItem = {}
      newItem['uid'] = transaction.uid
      newItem['transaction_record_id'] = transaction.transaction_record_id
      newItem['transaction_type_id'] = transaction.transaction_type_id
      newItem['amount'] = parseFloat(transaction.amount)

      newItem['user_id'] = transaction.user_id
      newItem['rate_type'] = transaction.rate_type
      newItem["filed_at"] = transaction.filed_at
      if (newItem.rate_type === 'hourly') {
        newItem['rate'] = transaction.rate
      } else {
        newItem['rate'] = 0
      }
      axios({
        method: 'post',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + this.state.invoice.uid + '/invoice_details',
        data: (newItem),
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then(resp => {
        const newData = resp.data
        this.setState({
          invoiceDetails: this.state.invoiceDetails.filter(invoiceDetail => invoiceDetail.uid !== newItem.uid)
        })
        this.setState({ invoiceDetails: [...this.state.invoiceDetails, newData] })
      })
    })

    this.setState({ openSelectExistingTransaction: false })
  }



  handleCheckExisting(e, item) {
    if (e.target.checked === true) {
      this.setState({ selectExistingArray: [...this.state.selectExistingArray, { ...item }] })

    }
    else {
      this.setState({
        selectExistingArray: this.state.selectExistingArray.filter(invoice => invoice.transaction_record_id !== item.transaction_record_id)
      })
    }
  }

  getTotalExpenses() {
    var totalAmount = 0
    var deduction = 0
    if (this.state.invoiceDetails.length > 0) {
      this.state.invoiceDetails.map((item) => {
        if (item["amount"] !== undefined) {
          if (item["is_deduction"] === true) {
            deduction = deduction + parseFloat(item["amount"])
          } else {
            totalAmount = totalAmount + parseFloat(item["amount"])
          }
        }
      })
    }
    return totalAmount
  }
  getTotalDiscounts() {
    var totalAmount = 0
    var deduction = 0
    if (this.state.invoiceDetails.length > 0) {
      this.state.invoiceDetails.map((item) => {
        if (item["amount"] !== undefined) {
          if (item["is_deduction"] === true) {
            deduction = deduction + parseFloat(item["amount"])
          } else {
            totalAmount = totalAmount + parseFloat(item["amount"])
          }
        }
      })
    }
    return deduction
  }
  getSubTotal() {
    var totalAmount = 0
    var deduction = 0
    if (this.state.invoiceDetails.length > 0) {
      this.state.invoiceDetails.map((item) => {
        if (item["amount"] !== undefined) {
          if (item["is_deduction"] === true) {
            deduction = deduction + parseFloat(item["amount"])
          } else {
            totalAmount = totalAmount + parseFloat(item["amount"])
          }
        }
      })
    }
    return (totalAmount - deduction)
  }
  getVat() {
		var total = 0
		var discount = 0
		var withTax = 0
		if(this.state.biller.is_taxable===true){
			if (this.state.invoiceDetails.length > 0) {
				this.state.invoiceDetails.map((transaction) => {
					if (transaction.is_deduction === false) {
						total = total + parseFloat(transaction.amount)
					}
					else {
						discount = discount + parseFloat(transaction.amount)
					}
				})
			}
			withTax = ((total - discount)* .12)
		}else{

			withTax = 0
		}


		return withTax.toLocaleString(navigator.language, { minimumFractionDigits: 2 })
	}
	getGrandTotal() {
		var total = 0
		var discount = 0
		var grandTotal = 0
		if(this.state.biller!==undefined){
			if(this.state.biller.is_taxable===true){
				if (this.state.invoiceDetails.length > 0) {
					this.state.invoiceDetails.map((transaction) => {
						if (transaction.is_deduction === false) {
							total = total + parseFloat(transaction.amount)
						}
						else {
							discount = discount + parseFloat(transaction.amount)
						}
					})
				}
				grandTotal = (total - discount) + ((total - discount) * .12)
			}else{
				if (this.state.invoiceDetails.length > 0) {
					this.state.invoiceDetails.map((transaction) => {
						if (transaction.is_deduction === false) {
							total = total + parseFloat(transaction.amount)
						}
						else {
							discount = discount + parseFloat(transaction.amount)
						}
					})
				}
				grandTotal = (total - discount)

			}
		}


		return grandTotal.toLocaleString(navigator.language, { minimumFractionDigits: 2 })
	}

  existingTransaction() {
    this.setState({ transactionTypePopUp: false })

    var arr1 = []
    if (this.state.transactions.length > 0) {
      this.state.transactions.map((transMenu) => {
        var recorded = false
        this.state.invoiceDetails.map((trans) => {
          if (transMenu.transaction_record_id === trans.transaction_record_uid) {
            recorded = true
          }
        })
        if (recorded === false) {
          arr1.push(transMenu)
        }
      })
    }
    this.setState({ transMenuPopUp: arr1 })
    this.setState({ openSelectExistingTransaction: true })
  }

  handleSubmitTransaction() {
    const item = {}
    item["uid"] = this.state.transaction.transaction_record.uid
    item["name"] = this.state.transaction.name
    item["user_id"] = this.state.transaction.user_id
    item["rate_type"] = this.state.transaction.rate_type
    item["remarks"] = this.state.transaction.remarks
    item["filed_at"] = this.state.transaction.filed_at
    item["transaction_type_id"] = this.state.transaction.transaction_type_id
    item["amount"] = this.state.transaction.amount * 1
    item["discount"] = this.state.transaction.amount * (this.state.transaction.discount / 100)

    if (this.state.transaction.rate_type === "fixed") {
      item["duration"] = 0
    } else {
      item["duration"] = this.state.transaction.duration * 1
    }
    var method = ''
    var url = ''
    if (item.uid === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.state.invoice.legal_case_uid + '/transactions'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.state.invoice.legal_case_uid + '/transactions/' + item.uid
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      var newData = {}
      if (method === 'post') {
        newData['uid'] = ""
        newData['transaction_record_id'] = resp.data.uid
        newData['transaction_type_id'] = resp.data.transaction_type_uid
        newData['amount'] = parseFloat(resp.data.amount)

        newData['user_id'] = resp.data.user_uid
        newData['rate_type'] = resp.data.rate_type
        newData["discount"] = resp.data.discount
        if (resp.data.rate_type === 'hourly') {
          newData['rate'] = resp.data.rate
        } else {
          newData['rate'] = 0
        }

      } else {
        newData['uid'] = this.state.invoiceDetail.uid
        // newData['transaction_record_id'] = resp.data.uid
        newData['transaction_type_id'] = resp.data.transaction_type_uid
        newData['transaction_type'] = resp.data.transaction_type
        newData['amount'] = parseFloat(resp.data.amount)
        newData['user_uid'] = resp.data.user_uid
        newData['rate_type'] = resp.data.rate_type
        newData["discount"] = resp.data.discount
        if (resp.data.rate_type === 'hourly') {
          newData['rate'] = resp.data.rate
        } else {
          newData['rate'] = 0
        }
      }
      this.submitInvoiceDetails(newData)
    }).catch(error => console.log(error.response))
  }

  submitInvoiceDetails(item) {
    var method = ''
    var url = ''
    if (item.uid === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + this.state.invoice.uid + '/invoice_details'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + this.state.invoice.uid + '/invoice_details/' + item.uid
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = {
        uid: resp.data.uid,
        transaction_record_id: resp.data.transaction_record_uid,
        transaction_record: resp.data.transaction_record,
        name: resp.data.transaction_record.name,
        amount: resp.data.amount,
        user_id: resp.data.transaction_record.user.uid,
        user: resp.data.transaction_record.user,
        rate: resp.data.rate,
        discount: (resp.data.transaction_record.discount / resp.data.transaction_record.amount) * 100,
        filed_at: resp.data.transaction_record.filed_at,
        transaction_type: resp.data.transaction_record.transaction_type,
        transaction_type_id: resp.data.transaction_record.transaction_type_uid,
        is_deduction: resp.data.transaction_record.is_deduction,
        duration: resp.data.transaction_record.duration,
        rate_type: resp.data.transaction_record.rate_type,
        remarks: resp.data.transaction_record.remarks,
        initialAmount: resp.data.amount / (1 - (resp.data.transaction_record.discount / resp.data.amount)),
        can_edit: resp.data.can_edit,
        can_delete: resp.data.can_delete
      }
      this.setState({
        invoiceDetails: this.state.invoiceDetails.filter(invoiceDetail => invoiceDetail.uid !== item.uid)
      })
      this.setState({ invoiceDetails: [...this.state.invoiceDetails, newData] })
      this.setState({ openTransactionForm: false })
    }).catch(error => console.log(error.response))
  }




  handleInputTransactionChange(e) {
    var value = null

    if (e.target.name === "amount") {
      value = e.target.value * 1
    } else if (e.target.name === "duration") {
      value = e.target.value * 1
    } else {
      value = e.target.value
    }
    this.setState({
      transaction: {
        ...this.state.transaction,
        [e.target.name]: value
      }
    })
  }

  handleSwitch(e) {
    if (e.target.checked === true) {
      this.setState({
        transaction: {
          ...this.state.transaction,
          is_deduction: e.target.checked,
          discount: 0,
          amount: (this.state.transaction.rate * this.state.transaction.duration)
        }
      })
    } else {
      this.setState({
        transaction: {
          ...this.state.transaction,
          is_deduction: e.target.checked
        }
      })
    }

  }

  handleDuration(e) {
    var amt = 0
    amt = this.state.transaction.rate * parseFloat(e.target.value)
    this.setState({
      transaction: {
        ...this.state.transaction,
        [e.target.name]: e.target.value,
        amount: amt
      }
    })
  }

  handleUserChange(e) {
    var userRate = 0;
    if (this.state.members.length > 0) {
      this.state.members.map((user) => {
        if (user.uid === e.target.value) {
          userRate = user.rate
        }
      })
    }

    this.setState({
      transaction: {
        ...this.state.transaction,
        [e.target.name]: e.target.value,
        rate: userRate
      }
    })
  }

  userRateChange(e) {
    this.setState({
      transaction: {
        ...this.state.transaction,
        rate: e.floatValue,
        amount: ((e.floatValue * this.state.transaction.duration) * (1 - (this.state.transaction.discount / 100)))
      }
    })
  }
  handleTransactionType(event, values) {
    if (values !== null) {
      this.setState({
        transaction: {
          ...this.state.transaction,
          transaction_type_id: values.uid
        }
      })
    }
  }
  transactionTestNumber(e) {
    this.setState({
      transaction: {
        ...this.state.transaction,
        amount: e.floatValue
      }
    })
  }

  discountChange(e) {
    if (e.floatValue !== undefined) {
      if (this.state.transaction.rate_type === "hourly") {
        this.setState({
          transaction: {
            ...this.state.transaction,
            discount: e.floatValue,
            amount: ((this.state.transaction.rate * this.state.transaction.duration) * (1 - (e.floatValue / 100)))
          }
        })
      } else {
        this.setState({
          transaction: {
            ...this.state.transaction,
            discount: e.floatValue,
            amount: ((this.state.transaction.initialAmount) * (1 - (e.floatValue / 100)))
          }
        })
      }


    } else {
      this.setState({
        transaction: {
          ...this.state.transaction,
          discount: 0,
          amount: (this.state.transaction.rate * this.state.transaction.duration)
        }
      })
    }

  }
  initialAmount(e) {
    this.setState({
      transaction: {
        ...this.state.transaction,
        initialAmount: e.floatValue,
        amount: e.floatValue
      }
    })
  }

  editTransaction(transaction) {
    var item = {}
    item["uid"] = transaction.transaction_record_id
    item["name"] = transaction.name
    item["amount"] = transaction.amount
    item["transaction_type_id"] = transaction.transaction_type_id
    item["user_id"] = transaction.user_id
    item["remarks"] = transaction.remarks
    item["duration"] = transaction.duration
    item["is_deduction"] = transaction.is_deduction
    item["filed_at"] = transaction.filed_at
    item["rate_type"] = transaction.rate_type
    item["rate"] = transaction.rate
    item["discount"] = transaction.discount
    this.setState({ transaction: item })

    if (this.state.expenseTypes.length > 0) {
      this.state.expenseTypes.map((expense) => {
        if (expense.uid === transaction.transaction_type_id) {
          this.setState({ defaultTransactionType: expense })
        }
      })
    }
    if (this.state.rebates.length > 0) {
      this.state.rebates.map((expense) => {
        if (expense.uid === transaction.transaction_type_id) {
          this.setState({ defaultTransactionType: expense })
        }
      })
    }
    this.setState({ openTransactionForm: true })
    this.setState({ transactionTitle: "Edit Invoice Detail" })
  }


  render() {
    return (
      <div>
        {
          this.state.load ? (
            <>
              <div className={styles.headerTitle} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Typography variant="h4">Invoice</Typography>
                </div>

                <div className={styles.groupHeaderButton}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={styles.editButton}
                    onClick={() => this.handleCreateorUpdateInvoice()}>
                    Edit Invoice
                  </Button>
                  <div>
                    <Tooltip title="Back to Legal case" arrow>
                      <Link to={this.state.prevPath}><b><ArrowBackIosIcon /></b></Link>
                    </Tooltip>
                  </div>
                </div>
              </div>

              <Grid container>
                <Grid item xs={6} className={styles.invoiceListLeft}>
                  <div>
                    <label>
                      Case :
                    </label>
                    <div>
                      <h3>{this.state.invoice.legal_case.title}</h3>
                    </div>
                  </div>
                  <div>
                    <label>
                      Biller :
                    </label>
                    <div>
                      <h3>{this.state.biller.name}</h3>
                    </div>
                  </div>
                  <div>
                    <label>
                      Clasification :
                    </label>
                    <div>
                      <h3>{this.state.biller.is_company ? ("Company") : ("Individual")}</h3>
                    </div>
                  </div>
                  <div>
                    <label>
                      Group :
                    </label>
                    <div>
                      <h4>{this.state.biller.group ? (this.state.biller.group.name) : ("n/a")}</h4>
                    </div>
                  </div>
                  <div>
                    <label>
                      Tax-Exempt :
                    </label>
                    <div>
                      <b>{this.state.biller.is_taxable ? ("No") : ("Yes")}</b>
                    </div>
                  </div>

                </Grid>

                <Grid item xs={6} className={styles.invoiceListright}>

                  <div>
                    <label><b>Reference No. :</b></label>
                    <div className={styles.invoiceValue}>{this.state.invoice.reference_no}</div>
                  </div>
                  <div>
                    <label><b>Date Filed :</b></label>
                    <div className={styles.invoiceValue}> {this.state.invoice.filed_at ? (new Date(this.state.invoice.filed_at).toLocaleString('en-Us', { year: 'numeric', month: 'short', day: '2-digit' })) : ('n/a')}</div>
                  </div>

                  <div>
                    <label><b>Due Date :</b></label>
                    <div className={styles.invoiceValue}>  {this.state.invoice.due_date ? (new Date(this.state.invoice.due_date).toLocaleString('en-Us', { year: 'numeric', month: 'short', day: '2-digit' })) : ('n/a')}</div>
                  </div>

                </Grid>
              </Grid>
              <Divider />
              <SimpleTable
                items={this.state.invoiceDetails}
                withEdit={this.state.withEdit}
                headers={this.state.headers}
                model="Invoice Detail"
                customClassName={this.state.customClassName}
                initialItem={this.state.initialTransaction}
                handleClick={this.handleCreateorUpdateItem}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                withTotalAmount={true}
                removeLabel={true}
              />

              <Table size="small" className={styles.grandInvoiceBody}>
                <TableRow>
                  <TableCell className={styles.celldesc}><i>Remarks/Payments Intructions</i></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.toltaLabel}>Total Expenses :</TableCell>
                  <TableCell className={styles.totalValue}>{this.getTotalExpenses().toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles.celldesc}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.toltaLabel}>Total Deductions :</TableCell>
                  <TableCell className={styles.totalValue}>{this.getTotalDiscounts().toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles.celldesc}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.toltaLabel}> Sub Total :</TableCell>
                  <TableCell className={styles.totalValue}>{this.getSubTotal().toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles.celldesc}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.toltaVat}>V.A.T :</TableCell>
                  <TableCell className={styles.totalValueVat}>{this.getVat().toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={styles.celldesc}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.cell}></TableCell>
                  <TableCell className={styles.totalLabelGrand}>Grand Total :</TableCell>
                  <TableCell className={styles.totalValueGrand}>{this.getGrandTotal().toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell>
                </TableRow>
              </Table>
              <SimplePopUp
                openPopup={this.state.openSelectExistingTransaction}
                maxWidth="lg"
                title="Add Invoice Detail from Transactions"
                handleClose={this.handleClose}
              >
                <SelectExistingTransactionForm
                  submitChecked={this.submitChecked}
                  handleCheckExisting={this.handleCheckExisting}
                  transMenuPopUp={this.state.transMenuPopUp}
                  selectFilter={this.selectFilter}
                  filterField={this.state.filterField}
                  filterSelection={this.state.filterSelection}
                  handleSearch={this.handleSearch}
                  users={this.state.users}
                  members={this.state.members}
                  expenseTypes={this.state.expenseTypes}
                  rebates={this.state.rebates}
                />
              </SimplePopUp>
              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopUp}
                item={this.state.invoiceDetail}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />

              <SimplePopUp
                openPopup={this.state.openInvoicePopUp}
                maxWidth="lg"
                title={this.state.title}
                handleClose={this.handleClose}
              >
                <>
                 <>
              <Grid container>
                <Grid item xs={6} className={styles.invoiceListLeft}>
                  <div>
                    <label>
                      Biller :
                    </label>
                    <div>
                      <h3>{this.state.biller.name ? (this.state.biller.name) : ('n/a')}</h3>
                    </div>
                  </div>

                  <div>
                    <label>
                      Clasification :
                    </label>
                    <div>
                      <h3>{this.state.biller.is_company ? (<div>Company</div>) : (<div>Individual</div>)}</h3>
                    </div>
                  </div>

                  <div>
                    <label>
                      Group :
                    </label>
                    <div>
                      <h4>{this.state.biller.group ? (this.state.biller.group.name) : ('n/a')}</h4>
                    </div>
                  </div>
                  <div>
                    <label>
                      Tax-Exempt :
                    </label>
                    <div>
                      <b>{this.state.biller.name ? (this.state.biller.is_taxable ? ('No') : ('Yes')) : ('n/a')}</b>
                    </div>
                  </div>

                </Grid>

                <Grid item xs={6} className={styles.invoiceListright}>
                  <div>
                    <label><b>Invoice No. :</b></label>
                    <TextField
                      name="reference_no"
                      className={styles.invoiceListrightInput}
                      fullWidth="true"
                      autoComplete="off"
                      onChange={this.handleInvoiceChange}
                      value={this.state.invoice.reference_no}
                      variant="outlined"
                      size="small"
                    />
                  </div>
                  <div>
                    <label><b>Invoice Date :</b></label>
                    <TextField
                      fullWidth
                      variant="outlined"
                      className={styles.invoiceListrightInput}
                      type="date"
                      format="yyyy-MM-dd"
                      onChange={this.handleInvoiceChange}
                      value={this.state.invoice.filed_at}
                      name="filed_at"
                      size="small"
                    />
                  </div>
                  <div>
                    <label><b>Due Date :</b></label>
                    <TextField
                      fullWidth
                      className={styles.invoiceListrightInput}
                      variant="outlined"
                      type="date"
                      format="yyyy-MM-dd"
                      onChange={this.handleInvoiceChange}
                      value={this.state.invoice.due_date}
                      name="due_date"
                      size="small"
                    />
                  </div>


                </Grid>

              </Grid>

            </>


                  <Button variant="contained" color="primary" style={{ float: 'right' }} onClick={this.handleSubmitInvoice}>SUBMIT</Button>

                </>
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.transactionTypePopUp}
                maxWidth="sm"
                title="Confirmation"
                handleClose={this.handleClose}
              >

                <div className={styles.transactionAddbuttons}>
                  <div>
                    <IconButton className={styles.transactionExist} variant="contained" onClick={this.existingTransaction}>
                      <ReceiptIcon />
                      <span>Existing Transaction</span>
                    </IconButton>
                  </div>
                  <div>
                    <IconButton className={styles.transactionNew} variant="contained" onClick={this.newTransaction}>
                      <CreditCardIcon /><br />
                      <span>New Transaction</span>
                    </IconButton>

                  </div>
                </div>
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.openTransactionForm}
                maxWidth="sm"
                title={this.state.transactionTitle}
                handleClose={this.handleClose}
              >
                <TransactionForm
                  transaction={this.state.transaction}
                  members={this.state.members}
                  users={this.state.users}
                  handleSubmit={this.handleSubmitTransaction}
                  testNumber={this.transactionTestNumber}
                  handleInputChange={this.handleInputTransactionChange}
                  expenseTypes={this.state.expenseTypes}
                  handleSwitch={this.handleSwitch}
                  handleUserChange={this.handleUserChange}
                  handleDuration={this.handleDuration}
                  rebates={this.state.rebates}
                  handleTransactionType={this.handleTransactionType}
                  defaultTransactionType={this.state.defaultTransactionType}
                  userRateChange={this.userRateChange}
                  discountChange={this.discountChange}

                />

              </SimplePopUp>

            </>
          ) : (null)
        }
      </div>
    )
  }
}
export default Invoice
Invoice.propTypes = {
  uid: PropTypes.string,

  location: PropTypes.shape({
    state: PropTypes.object.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      uid: PropTypes.string.isRequired
    })
  })
}
