import React from 'react'

import styles from './Workflow.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  Grid,
  MenuItem,
  Typography,
  Select,
  Card,
  IconButton,
} from '@material-ui/core'

import AddBoxIcon from '@material-ui/icons/AddBox'
import DeleteIcon from '@material-ui/icons/Delete'

class TaskForm extends React.Component {
  render () {
    return (
      <div>
        <div>
          <form className={styles.formControl}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>

                <TextField
                  autoComplete="off"
                  autoFocus={true}
                  className={styles.textfield}
                  variant="outlined"
                  required
                  size="small"
                  name="name"
                  id="task-template-start"
                  value={this.props.task.name}
                  onChange={this.props.handleTaskChange}
                  label="Task Name"/>
                <Typography variant="body1" className={styles.typographyMargin}><strong>Sub Tasks</strong></Typography>
                {
                  this.props.items.length>0?(
                  this.props.items.map((val, idx) =>{
                  return (
                    <>
                    <div className={styles.formControl}>
                      <TextField
                        autoComplete="off"
                        size="small"
                        fullWidth
                        className={styles.textfield}
                        variant="outlined" required
                        name={'name-' + idx}
                        id={'name-' + idx}
                        data-idx={idx}
                        value={this.props.items[idx]}
                        onChange={this.props.handleSubs}
                        label={`Checklist ${idx + 1}`}
                        inputProps={{ 'data-id': idx, 'data-field-type': 'name' }}
                      />

                      <div>

                            <IconButton
                              color="secondary"
                              onClick={() => this.props.removeSub(idx)}>
                              <DeleteIcon />
                            </IconButton>

                      </div>

                    </div>
                  </>
                  )
                  })
                  ):(null)
                }
                <IconButton
                  className={styles.taskSubButton}
                  variant="addSubTask"
                  onClick={this.props.addSubs}
                >
                  <AddBoxIcon/><Typography variant="body2" className={styles.taskSubButton}>Add Sub Task</Typography>
                </IconButton>
                <TextField
                  autoComplete="off"
                  multiline={true}
                  rows={10}
                  className={styles.textfield}
                  variant="outlined"
                  required
                  id="task-template-description"
                  name="description"
                    value={this.props.task.description}
                    onChange={this.props.handleTaskChange}
                  label="Task Description"/>
                <Typography variant="body1" className={styles.typographyMargin}>
                  Priority Level
                  <Select
                    variant="outlined"
                    className={styles.taskPrioritySelect}
                    value={this.props.task.priority_id || ''}
                    onChange={this.props.handleTaskChange}
                    name="priority_id"
                    style={{textTransform:'capitalize'}}
                    id="task-template-tag_list"
                  >
                    {
                      this.props.priorities.length>0?(
                      this.props.priorities.map((val, idx) => {
                        return (
                          <MenuItem key={idx} value={val.uid} style={{textTransform:'capitalize'}}>{val.name}</MenuItem>
                        )
                      })
                      ):null
                    }
                  </Select>
                </Typography><br />
              </Grid>
              <Grid xs={12} sm={4} style={{marginBottom: '10px'}}>
                <Card className={styles.card}>
                <div className={styles.taskTitleDue}>
                  <Typography variant="body1"><b>Task Due</b></Typography></div>
                <div />
                  <div className={styles.tasksDue}>
                    <TextField
                        InputProps={{ inputProps: { min: "0", step: "1" } }}
                        type="number"
                        variant="outlined"
                        size="small"
                        id="task-template-interval"
                        className={styles.taskInterval}
                        value={this.props.task.interval}
                        onChange={this.props.handleTaskChange}
                        name="interval"

                        />
                    <Select
                        variant="outlined"
                        className={styles.taskIntervalSelect}
                        value={this.props.task.interval_type}
                        onChange={this.props.handleTaskChange}
                        name="interval_type"
                        id="task-template-interval_type"
                    >
                        <MenuItem value="weekdays"><Typography variant="body1" >Weekdays</Typography></MenuItem>
                        <MenuItem value="calendar_days"><Typography variant="body1" >Calendar Days</Typography></MenuItem>
                    </Select>
                    <Select
                        variant="outlined"
                        className={styles.taskIntervalType}
                        value={this.props.task.interval_event}
                        onChange={this.props.handleTaskChange}
                        name="interval_event"
                        id="task-template-interval_event"
                    >
                        <MenuItem value="before"><Typography variant="body1" >Before</Typography></MenuItem>
                        <MenuItem value="after"><Typography variant="body1" >After</Typography></MenuItem>
                    </Select>
                  </div>
                <div />
                  <div className={styles.taskTitleDue}>
                    <Typography variant="body1"><b>Trigger Event</b></Typography>
                  </div>
                <div>
                  <Select
                    fullWidth
                    variant="outlined"
                    className={styles.triggerSelect}
                    name="workflow_uid"
                    id="task-template-workflow"
                    onChange={this.props.handleTaskChange}
                    value={this.props.task.workflow_uid || ''}

                    >
                    {
                        this.props.triggers.length> 0?(
                            this.props.triggers.map((trigger,idx)=>(
                                <MenuItem name={`trigger-${idx}`} key={trigger.uid} value={trigger.uid}><Typography variant="body1" >{trigger.name}</Typography></MenuItem>
                            ))
                        ):(
                            <div>No Triggers Loaded</div>
                        )
                    }
                    </Select>
                </div>
                </Card>
              </Grid>
            </Grid>
          </form>
        </div>
        <div className={styles.actionButton}>
          <Button variant="outlined" id="task-template-submit" name="submit" color="primary" onClick={() => this.props.submit()} ><div>SAVE</div></Button>
        </div>
      </div>
    )
  }
}
export default TaskForm

TaskForm.propTypes = {
  onchange: PropTypes.func,
  item: PropTypes.object,
  children: PropTypes.object,
  submit: PropTypes.func,
  items: PropTypes.array,
  subs: PropTypes.array,
  addSubs: PropTypes.func,
  triggers: PropTypes.array,
  task: PropTypes.object,
  handleTaskChange: PropTypes.func,
  handleSubs: PropTypes.func,
  removeSub: PropTypes.func,
  handleLabel: PropTypes.func,
  priorities: PropTypes.array,
}
