import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Public404 from './Public404'
import Error401 from './Error401'
import Error500 from './Error500'
import Maintenance from './Maintenance'

class ErrorPage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { type } = this.props

    switch (type) {
      case 404:
        return <Public404 />
      case 401:
        return <Error401 />
      case 500:
        return <Error500 />
      case 'maintenance':
          return <Maintenance />
      default:
        return <kbd>Error occured with status code: {type}</kbd>
    }
  }
}

export default ErrorPage

ErrorPage.propTypes = {
  type: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ])
}
