import React from 'react'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  Grid,
  MenuItem
} from '@material-ui/core'
import styles from "./ClientCustom.module.css";

class CustomForm extends React.Component {
  render () {
    return (
      <>
         <Grid container spacing={1}>
           <Grid xs={12} sm={8}>
             <TextField
               variant="outlined"
               required
               fullWidth
              //  className={styles.textFieldsAttr}
               label="Attribute name"
               size="small"
               name="attr_name"
               value={this.props.item.attr_name}
               onChange={this.props.onchange}
             />
             <p className={styles.error}>{this.props.error}</p>
           </Grid>
           <Grid  xs={12} sm={4}>
             <TextField
                variant="outlined"
                required
                fullWidth
                label="Attribute type"
                size="small"
                select
                style={{marginLeft: '8px'}}
                name="attr_type"
                value={this.props.item.attr_type}
                onChange={this.props.onchange}
              >
                 <MenuItem value=""></MenuItem>
                 <MenuItem value="text">Text</MenuItem>
                 <MenuItem value="number">Number</MenuItem>
                 <MenuItem value="date">Date</MenuItem>
              </TextField>
             <p className={styles.error}>{this.props.errorType}</p>
           </Grid>
         </Grid>
        <div className={styles.actionButton}>
          <Button variant="outlined" name="client_custom_field-submit" disabled={!this.props.item.attr_name} color="primary" onClick={() => this.props.submit()} >Save</Button>
        </div>
      </>
    )
  }
}
export default CustomForm

CustomForm.propTypes = {
  onchange: PropTypes.func,
  item: PropTypes.object,
  children: PropTypes.object,
  error: PropTypes.object,
  errorType: PropTypes.func,
  submit: PropTypes.func,
}
