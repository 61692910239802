import React from 'react'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  Grid, InputLabel
} from '@material-ui/core'
import styles from "./CustomLegalCase.module.css";
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';

class CustomFieldsForm extends React.Component {
  render () {
    return (
      <div>
        <Grid container>
          <Grid xs={12} sm={8}>
            <TextField
              variant="outlined"
              required
              fullWidth
              className={styles.textFieldsAttr}
              label="Attribute name"
              size="small"
              name="attr_name"
              value={this.props.item.attr_name}
              onChange={this.props.onchange}
            />
            <p className={styles.error}>{this.props.error}</p>
          </Grid>
          <Grid xs={12} sm={4}>
            <FormControl fullWidth className={styles.textFieldsAttr} size="small" variant="outlined" >
              <InputLabel htmlFor="outlined-age-native-simple">Attribute type</InputLabel>
              <Select
                native
                label="Attribute type"
                fullWidth="true"
                size="small"
                value={this.props.item.attr_type || ''}
                onChange={this.props.onchange}
                inputProps={{
                  name: 'attr_type',
                  id: 'outlined-age-native-simple',
                }}
              >
                <option value=""></option>
                <option value="text">Text</option>
                <option value="number">Number</option>
                <option value="date">Date</option>
              </Select>
            </FormControl>
            <p className={styles.error}>{this.props.errorType}</p>
          </Grid>
        </Grid>
        <div className={styles.actionButton}>
          <Button variant="outlined" name="case_custom_field-submit" disabled={!this.props.item.attr_name} color="primary" onClick={() => this.props.submit()} >Save</Button>
        </div>
      </div>
    )
  }
}
export default CustomFieldsForm

CustomFieldsForm.propTypes = {
  classes: PropTypes.object,
  onchange: PropTypes.func,
  item: PropTypes.object,
  error: PropTypes.object,
  children: PropTypes.object,
  errorType: PropTypes.func,
  submit: PropTypes.func,
}
