import React from 'react'

import PropTypes from 'prop-types'

import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import styles from './NestedForm.module.css'

class GroupForm extends React.Component{
    render(){
        return(
            <>
          <Paper>
            <TextField
              variant="outlined"
              autoComplete="off"
              required
              size="small"
              fullWidth
              label="Name"
              name="name"
              value={this.props.group.name}
              onChange={this.props.handleChange}
            />
          </Paper>

          <div className={styles.actionButton}>
          <Button variant="outlined" onClick={this.props.addGroup} color="primary">Add</Button>
          </div>
        </>
        )
    }
}
export default GroupForm

GroupForm.propTypes={
    group: PropTypes.object,
    handleChange: PropTypes.func,
    addGroup: PropTypes.func
}