import React from 'react'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,Grid
} from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete'
import styles from "../Client/Client.module.css";

class SearchForm extends React.Component {
  render() {
    return (
      <div>
        <div>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              id="legal_case"
              size="small"
              options={this.props.legalCases}
              getOptionLabel={(option) => option.title || ''}
              getOptionSelected={(option, value) => option.title === value.title }
              onChange={this.props.handleAutoComplete}
              fullWidth
              value={this.props.searchCase.legalCase}
              renderInput={(params) => <TextField {...params} label="Case Link" variant="outlined" />}
            />

          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth="true"
              id="ends_at"
              type="date"
              variant="outlined"
              size="small"
              format="yyyy-MM-dd"
              label="Starts on from"
              name="starts_on_from"
              value={this.props.searchCase.starts_on_from}
              onChange={this.props.handleInputChange}
              InputLabelProps={{
                shrink: true
              }}

            />
            
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth="true"
              id="ends_at"
              type="date"
              variant="outlined"
              size="small"
              format="yyyy-MM-dd"
              label="Starts on to"
              name="starts_on_to"
              style={{ marginBottom: '10px' }}
              value={this.props.searchCase.starts_on_to}
              onChange={this.props.handleInputChange}
                InputLabelProps={{
                shrink: true
              }}

            />
          </Grid>
        </Grid>
        </div>
        <Button onClick={this.props.searchInvoice} className={styles.searchButton} color="primary" variant="outlined">Search</Button>

        </div>
    )
  }
}
export default SearchForm

SearchForm.propTypes = {
  submit: PropTypes.func,
  legalCases: PropTypes.array,
  handleAutoComplete: PropTypes.func,
  transactions: PropTypes.array,
  searchInvoice: PropTypes.func,
  handleInputChange: PropTypes.func,
  searchCase: PropTypes.object,
  createBulkInvoice: PropTypes.func
}
