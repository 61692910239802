// eslint-disable-next-line
import styles from './Home.module.css'

import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import ListItemText from '@material-ui/core/ListItemText'
import axios from 'axios'
import PropTypes from 'prop-types'
import WorkOutlineIcon from '@material-ui/icons/WorkOutline'

import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import DateRangeIcon from '@material-ui/icons/DateRange'
import ListAltIcon from '@material-ui/icons/ListAlt'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import SupervisedUserCircleSharpIcon from '@material-ui/icons/SupervisedUserCircleSharp'
import { Tooltip } from '@material-ui/core'
import EventForm from '../../pages/Event/EventForm'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import ProgressBar from '../../shared/ProgressBar/ProgressBar'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import AlertMessage from '../../shared/Notify/AlertMessage'
class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Home_Case',
      events: [],
      event: {},
      initialEvent: {
        uid: '',
        name: '',
        description: '',
        starts_at: new Date(),
        ends_at: new Date(),
        user_uids: []
      },
      openEventForm: false,
      todayEvents: [],
      tasks: [],
      unReads: [],
      date: new Date(),
      todayEvent: {},
      title: '',
      load: false,
      maxWidth: 'sm',
      noEvents: true,
      noTasks: true,
      notifyPopUp: false,
      users: [],
      legalCases: [],
      caseHeaders: [
        'Reference_no',
        'Title',
        'user_uids',
        'client_uids',
        'practice_area_uid',
        'date_filed'
      ],
      clients: [],
      practiceAreas: [],
      isOpen: false,
      message: '',
      type: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.markAsDone = this.markAsDone.bind(this)
    this.handleCreateOrUpdateEvent = this.handleCreateOrUpdateEvent.bind(this)
  }

  componentDidMount() {
    if (localStorage.getItem('api_key') !== null) {
      if (localStorage.getItem('current_user') !== null) {
        this.loadTasks(process.env.REACT_APP_API_DOMAIN + '/v1/events')
      }
    }
  }

  loadTasks(url) {
    var eventsToday = []

    axios({
      method: 'get',
      url: url,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        this.setState({ events: resp.data })
        if (resp.data.length > 0) {
          var noevent = true

          resp.data.map((event) => {
            if (
              new Date(event.starts_at).toLocaleDateString('sv-SE') ===
              new Date(this.state.date).toLocaleDateString('sv-SE')
            ) {
              noevent = false
            }
          })
        }
        this.setState({ noEvents: noevent })
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/users/me/tasks/unread',
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        })
          .then((resp) => {
            var counter = 0
            resp.data.map((item) => {
              if (item.parent_uid === null) {
                counter = counter + 1
              }
              return counter
            })
            this.setState({ tasks: counter })
            this.setState({ unReads: resp.data })
            if (eventsToday.length > 0) {
              this.setState({ notifyPopUp: true })
            }
            axios({
              method: 'get',
              url: process.env.REACT_APP_API_DOMAIN + '/v1/members',
              headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
            })
              .then((resp) => {
                this.setState({ users: resp.data })

                axios({
                  method: 'get',
                  url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases',
                  headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
                })
                  .then((resp) => {
                    this.setState({ legalCases: resp.data.data })

                    axios({
                      method: 'get',
                      url: process.env.REACT_APP_API_DOMAIN + '/v1/clients',
                      headers: {
                        'X-APP-API-KEY': localStorage.getItem('api_key')
                      }
                    })
                      .then((resp) => {
                        this.setState({ clients: resp.data.data })
                      })
                      .catch((error) => console.log(error.response))
                  })
                  .catch((error) => console.log(error.response))
              })
              .catch((error) => console.log(error.response))
          })
          .catch((error) => console.log(error.response))
      })
      .catch((error) => console.log(error.response))
    this.setState({ load: true })
  }

  handleChange(e) {
    this.setState({ date: e })
    var noevent = true
    var notask = true

    if (this.state.events.length > 0) {
      this.state.events.map((event) => {
        if (
          new Date(event.starts_at).toLocaleDateString('sv-SE') ===
          new Date(e).toLocaleDateString('sv-SE')
        ) {
          noevent = false
        }
      })
    }

    if (this.state.unReads.length > 0) {
      this.state.unReads.map((task) => {
        if (
          new Date(task.ends_at).toLocaleDateString('sv-SE') ===
          new Date(e).toLocaleDateString('sv-SE')
        ) {
          notask = false
        }
      })
    }
    this.setState({ noEvents: noevent })
    this.setState({ noTasks: notask })
  }

  handleClose() {
    this.setState({ notifyPopUp: false })
    this.setState({ openEventForm: false })
  }

  handleCreateOrUpdateEvent(item) {
    var textTitle = ''
    var newItem = {
      uid: item.uid,
      can_delete: item.can_delete,
      can_edit: item.can_edit,
      legal_case: item.legal_case,
      user_ids: item.user_uids,
      name: item.name,
      description: item.description,
      tag_list: item.tag_list,
      state: item.state,
      from_workflow: item.from_workflow,
      created_at: item.created_at,
      updated_at: item.updated_at
    }
    if (item.uid === '') {
      textTitle = ' Create Event '
      newItem['starts_at'] = new Date()
        .toLocaleString('sv-Se', { timeZone: 'Asia/Manila' })
        .replace(' ', 'T')
      newItem['ends_at'] = new Date()
        .toLocaleString('sv-Se', { timeZone: 'Asia/Manila' })
        .replace(' ', 'T')
    } else {
      newItem['starts_at'] = item.starts_at
        ? new Date(item.starts_at)
            .toLocaleString('sv-Se', { timeZone: 'Asia/Manila' })
            .replace(' ', 'T')
        : null
      newItem['ends_at'] = item.ends_at
        ? new Date(item.ends_at)
            .toLocaleString('sv-Se', { timeZone: 'Asia/Manila' })
            .replace(' ', 'T')
        : null

      textTitle = ' Update Event '
    }
    if (item.legal_case !== undefined) {
      this.setState({ defaultCase: item.legal_case })
      this.setState({ legalCase: item.legal_case })
      this.setState({ show: false })
      var users = []
      if (this.state.users.length > 0) {
        this.state.users.map((user) => {
          item.legal_case.user_uids.map((userId) => {
            if (user.uid === userId) {
              users.push(user)
            }
          })
        })
      }
      this.setState({ eventCaseUsers: users })
    }

    this.setState({ title: textTitle })
    this.setState({ checkedUsers: item.user_uids })
    this.setState({ event: newItem })
    this.setState({ openEventForm: true })
  }

  markAsDone(task) {
    axios({
      method: 'put',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/tasks/' + task.uid + '/done',
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        unReads: this.state.unReads.filter((read) => read.uid !== task.uid)
      })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.props.countTasks()
    })
  }

  render() {
    const tasksToDisplay = this.state.unReads
      .filter(
        (filter) =>
          filter.state === 'ongoing' &&
          new Date(filter.ends_at).toLocaleDateString('sv-SE') <=
            (new Date(this.state.date).toLocaleDateString('sv-SE') ||
              'Date_today')
      )
      .sort(
        (a, b) =>
          new Date(a.created_at).getTime() > new Date(b.ends_at).getTime()
      )
      .reverse()
    const eventsToday = this.state.events.filter(
      (filter) =>
        new Date(filter.starts_at).toLocaleDateString('sv-SE') ==
        new Date(this.state.date).toLocaleDateString('sv-SE')
    )
    const upcomingEvents = this.state.events
      .filter(
        (filter) =>
          new Date(filter.starts_at).toLocaleDateString('sv-SE') >
          new Date(this.state.date).toLocaleDateString('sv-SE')
      )
      .sort((a, b) => new Date(b.starts_at) < new Date(a.starts_at))
    const eventsToDisplay = eventsToday.length ? eventsToday : upcomingEvents

    return (
      <>
        {this.state.load ? (
          <div>
            <Grid container spacing={1} gutterBottom>
              <Grid item xs={12} md={9}>
                <Card
                  style={{
                    minHeight: '405px'
                  }}
                >
                  <CardContent>
                    <div className={styles.headerTitle}>
                      <WorkOutlineIcon />
                      <Typography variant="h5" color="text.secondary">
                        Cases
                      </Typography>
                    </div>
                    <hr className={styles.homeLine} />
                    {this.state.legalCases.length > 0
                      ? this.state.legalCases.map(
                          (legalCase, index) =>
                            index < 3 && (
                              <div
                                key={legalCase.uid}
                                className={styles.caseList}
                              >
                                <div className={styles.titleAndIcon}>
                                  <Typography
                                    variant="h6"
                                    color="text.secondary"
                                  >
                                    <a
                                      href={`/cases/${legalCase.uid}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className={styles.homeCasesLinks}
                                    >
                                      {legalCase.title}
                                    </a>
                                  </Typography>

                                  <div className={styles.userBody}>
                                    <div className={styles.eventFoot}>
                                      <div>
                                        {legalCase.user_uids !== null
                                          ? legalCase.user_uids.length
                                          : null}{' '}
                                        Assigned Lawyer
                                      </div>
                                      <div>
                                        <SupervisedUserCircleSharpIcon />
                                      </div>
                                    </div>
                                    <div className={styles.userContent}>
                                      {legalCase.user_uids !== null ? (
                                        legalCase.user_uids.map((clientName) =>
                                          this.state.users.length > 0
                                            ? this.state.users.map((user) =>
                                                clientName === user.uid ? (
                                                  <li>
                                                    {user.last_name},{' '}
                                                    {user.first_name}
                                                  </li>
                                                ) : null
                                              )
                                            : null
                                        )
                                      ) : (
                                        <div className="value-field">
                                          No client assign
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <Typography
                                  variant="body2"
                                  className={styles.homeDesc}
                                  gutterBottom
                                >
                                  {legalCase.description}
                                </Typography>
                                <div className={styles.homeCasesfooter}>
                                  <i>
                                    <span>{legalCase.reference_no}</span> &nbsp;
                                    &nbsp;
                                    <span>{legalCase.date_filed}</span> &nbsp;
                                    &nbsp;
                                  </i>

                                  {legalCase.client_uids !== null ? (
                                    legalCase.client_uids.map((clientName) =>
                                      this.state.clients.length > 0
                                        ? this.state.clients.map((client) =>
                                            clientName === client.uid ? (
                                              <Tooltip title="Client Name">
                                                <a
                                                  href={`/clients/${client.uid}`}
                                                >
                                                  <div className={styles.list}>
                                                    <div>
                                                      <PermIdentityIcon />
                                                    </div>
                                                    <div>{client.name}</div>
                                                  </div>
                                                </a>
                                              </Tooltip>
                                            ) : null
                                          )
                                        : null
                                    )
                                  ) : (
                                    <div className="value-field">
                                      No client assign
                                    </div>
                                  )}
                                </div>
                              </div>
                            )
                        )
                      : 'No Cases Yet'}
                  </CardContent>
                  <CardActions className={styles.homeLink}>
                    <a href="/legal_cases" size="small">
                      See more
                    </a>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <div className={styles.calendarBox}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      autoOk
                      color="secondary"
                      variant="static"
                      inputVariant="outlined"
                      value={this.state.date}
                      onChange={this.handleChange}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <Card className={styles.tasksBody}>
                  <CardContent>
                    <Typography variant="h5" gutterBottom>
                      <ListAltIcon /> Tasks
                    </Typography>
                    <hr className={styles.homeLine} />

                    <div className={styles.homeTaskContent}>
                      {tasksToDisplay.length > 0 ? (
                        tasksToDisplay.map(
                          (task, index) =>
                            index < 3 && (
                              <div key={task.uid}>
                                <div className={styles.taskList}>
                                  <div className={styles.titleButton}>
                                    <Typography variant="h6" gutterBottom>
                                      {task.name}
                                    </Typography>
                                    <div className={styles.buttonList}>
                                      <div className={styles.userBody}>
                                        <div className={styles.eventFoot}>
                                          <div>
                                            {task.user_uids !== null
                                              ? task.user_uids.length
                                              : null}{' '}
                                            Assigned
                                          </div>
                                          <div>
                                            <SupervisedUserCircleSharpIcon />
                                          </div>
                                        </div>
                                        <div className={styles.userContent}>
                                          {task.user_uids !== null ? (
                                            task.user_uids.map((taskName) =>
                                              this.state.users.length > 0
                                                ? this.state.users.map((user) =>
                                                    taskName === user.uid ? (
                                                      <li>
                                                        {user.last_name},{' '}
                                                        {user.first_name}
                                                      </li>
                                                    ) : null
                                                  )
                                                : null
                                            )
                                          ) : (
                                            <div className="value-field">
                                              No client assign
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div>
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              size="small"
                                              onChange={() =>
                                                this.markAsDone(task)
                                              }
                                              name="antoine"
                                            />
                                          }
                                          labelPlacement="bottom"
                                          label="Mark as done"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <Typography
                                    gutterBottom
                                    className={
                                      'dateList ' +
                                      (new Date(
                                        task.ends_at
                                      ).toLocaleDateString('sv-SE') <
                                      new Date(
                                        this.state.date
                                      ).toLocaleDateString('sv-SE')
                                        ? styles.colorRed
                                        : styles.colorNone)
                                    }
                                  >
                                    {new Date(task.ends_at).toLocaleDateString(
                                      'en-En',
                                      {
                                        weekday: 'long',
                                        month: 'long',
                                        day: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                      }
                                    )}
                                  </Typography>
                                </div>
                              </div>
                            )
                        )
                      ) : (
                        <Typography>
                          <ListItemText>No task for today</ListItemText>
                        </Typography>
                      )}
                    </div>
                  </CardContent>
                  <CardActions className={styles.homeLink}>
                    <a href="/tasks" size="small">
                      See more tasks
                    </a>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card className={styles.eventsBody}>
                  <CardContent>
                    <Typography
                      variant="h5"
                      color="text.secondary"
                      gutterBottom
                    >
                      <DateRangeIcon /> Events
                    </Typography>
                    <hr className={styles.homeLine} />
                    <div className={styles.eventContents}>
                      <div>
                        {upcomingEvents.length && !eventsToday.length ? (
                          <Typography>
                            Upcoming Events ({upcomingEvents.length})
                          </Typography>
                        ) : null}
                        {eventsToDisplay.map(
                          (event, index) =>
                            index < 3 && (
                              <div key={event.uid} className={styles.eventBody}>
                                <div className={styles.eventList}>
                                  <div className={styles.eventContent}>
                                    <Typography variant="h6" gutterBottom>
                                      {event.name}
                                    </Typography>
                                    <div>
                                      {new Date(
                                        event.starts_at
                                      ).toLocaleDateString('en-En', {
                                        weekday: 'long',
                                        month: 'long',
                                        day: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                      })}{' '}
                                      &nbsp; - &nbsp;
                                      {new Date(
                                        event.ends_at
                                      ).toLocaleDateString('en-En', {
                                        weekday: 'long',
                                        month: 'long',
                                        day: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                      })}
                                    </div>
                                  </div>

                                  <div className={styles.userBody}>
                                    <div className={styles.eventFoot}>
                                      <div>
                                        {event.user_uids !== null
                                          ? event.user_uids.length.toString()
                                          : null}{' '}
                                        Invited
                                      </div>
                                      <div>
                                        <SupervisedUserCircleSharpIcon />
                                      </div>
                                    </div>
                                    <div className={styles.userContent}>
                                      {event.user_uids !== null ? (
                                        event.user_uids.map((eventName) =>
                                          this.state.users.length > 0
                                            ? this.state.users.map((user) =>
                                                eventName === user.uid ? (
                                                  <li>
                                                    {user.last_name},{' '}
                                                    {user.first_name}
                                                  </li>
                                                ) : null
                                              )
                                            : null
                                        )
                                      ) : (
                                        <div className="value-field">
                                          No users assign
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                        {this.state.noEvents && !upcomingEvents.length ? (
                          <Typography>
                            <ListItemText>No event for today</ListItemText>
                          </Typography>
                        ) : null}
                      </div>
                    </div>
                  </CardContent>
                  <CardActions className={styles.homeLink}>
                    <a href="/events" size="small">
                      See more events
                    </a>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
            <SimplePopUp
              openPopup={this.state.openEventForm}
              handleClose={this.handleClose}
              maxWidth="md"
              title={this.state.title}
            >
              <EventForm
                event={this.state.event}
                legalCase={this.state.legalCase}
                defaultCase={this.state.defaultCase}
                cases={this.state.cases}
                users={this.state.users}
                getDefaultValue={this.getDefaultValue}
                handleEventAutoComplete={this.handleEventAutoComplete}
                submit={this.handleEventSubmit}
                handleCheck={this.handleCheck}
                show={this.state.show}
                handleEventChange={this.handleEventChange}
                handleEndChange={this.handleEndChange}
                handleStartChange={this.handleStartChange}
                eventCaseUsers={this.state.eventCaseUsers}
                handleEventUsersAutocomplete={this.handleEventUsersAutocomplete}
                withCase={true}
              />
            </SimplePopUp>
            <AlertMessage
              notify={this.state.notify}
              handleCloseAlert={this.handleCloseAlert}
              isOpen={this.state.isOpen}
              type={this.state.type}
              message={this.state.message}
            />
          </div>
        ) : (
          <ProgressBar model={'Home'} />
        )}
      </>
    )
  }
}

export default Home

Home.propTypes = {
  countTasks: PropTypes.func
}
