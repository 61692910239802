import React from 'react'

import PropTypes from 'prop-types'

import { TextField, IconButton, TableCell, TableRow } from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import styles from './Onboarding.module.css'

class NestedForm extends React.Component {

    render() {
        

        const deleteButton = `delete_item${this.props.idx}`
        return (
            <>
                <TableRow key={`group-${this.props.idx}`}>
                    <TableCell className={styles.cell}>
                        <TextField
                            required
                            autoComplete="off"
                            className={styles.textfield}
                            variant="outlined"
                            size="small"
                            name="name"
                            fullWidth={true}
                            onChange={(event) => this.props.handleChange(event, this.props.idx)}
                            value={this.props.groups[this.props.idx].name}
                        />
                    </TableCell>
                    <TableCell className={styles.cell} align="right">
                        {
                            this.props.defaultLength -1 < this.props.idx ?
                            <IconButton
                            color="secondary"
                            name={deleteButton}
                            onClick={() => this.props.removegroup(this.props.idx)}>
                            <DeleteForeverIcon />
                            </IconButton> :null
                        }
                   
                    </TableCell>
                </TableRow>
                
            </>
        )
    }
}

export default NestedForm

NestedForm.propTypes = {
    idx: PropTypes.number,
    groups: PropTypes.array,
    handleAutocomplete: PropTypes.func,
    handleChange: PropTypes.func,
    removegroup: PropTypes.func,
    defaultLength: PropTypes.number

}
