import React from 'react'

import PropTypes from 'prop-types'

import {Typography, Button, Grid} from '@material-ui/core'
import { Link } from 'react-router-dom'
import styles from "./Events.module.css";
import PersonIcon from '@material-ui/icons/Person'
import Avatar from '@material-ui/core/Avatar'
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import TodayIcon from '@material-ui/icons/Today';

class Event extends React.Component{
    render(){
        return(
        <>
          <Grid container spacing={1} gutterBottom>
            <Grid item xs={12} md={12}>
              <div className={styles.eventTitle} gutterBottom>
                <div className={styles.eventTitleList}>
                  <Typography variant="h5">
                    <TodayIcon/> {this.props.event.name}
                  </Typography>
                  <span>
                  {this.props.event.starts_at ? (new Date(this.props.event.starts_at).toLocaleDateString('en-US', {
                    weekday: 'long',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit'
                  })) : ("n/a")}
                </span>
                  &nbsp; - &nbsp;
                  <span>
                  {this.props.event.ends_at ? (new Date(this.props.event.ends_at).toLocaleDateString('en-US', {
                    month: 'long',
                    weekday: 'long',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit'
                  })) : ("n/a")}
                </span>
                </div>
              </div>
              <div className={styles.eventDescBody}>
                <div className={styles.eventDesc}>
                  <Typography variant="body2" gutterBottom>
                    {this.props.event.description ? (this.props.event.description) : ('None')}
                  </Typography>
                </div>
              </div>

              <div>
                  <div className={styles.eventLink}>
                    <WorkOutlineIcon/> {this.props.event.legal_case ? (<Link to={`/legal_cases/${this.props.event.legal_case.uid}`} target="_blank" title="Go to assigned case">{this.props.event.legal_case.title}</Link>) : ('No Linked Case')}
                  </div>
              </div>


              <hr className={styles.eventHrLineButton}/>
              <div>
                <Typography variant="body1" className={styles.eventLabelShared}>
                  <b>Shared/Attending</b>
                </Typography>
                <div className={styles.eventUserBody} >
                  {
                    this.props.event.user_uids ? (
                      this.props.event.user_uids.map((eventUser) => (
                        this.props.users.map((user) => (
                          eventUser === user.uid ? (
                            <div className={styles.eventUserList} >
                              <div className={styles.eventAvatarIcon}>
                                <Avatar>
                                  <PersonIcon />
                                </Avatar>
                                <Typography variant="body1" className={styles.eventAvatarName}>
                                  {user.last_name}, {user.first_name}
                                </Typography>
                              </div>
                            </div>                            ) : (
                            null
                          )
                        ))
                      ))
                    ) : (
                      <div className={styles.eventNoUser}>No users assigned to this event</div>
                    )
                  }
                </div>
              </div>

            </Grid>
          </Grid>

                <div className={styles.eventActionButton}>
                  <Button variant="outlined"
                    onClick={() => this.props.handleClose()}
                    >
                    Cancel
                  </Button>
                  <Button variant="outlined"
                    onClick={() => this.props.handleCreateOrUpdateEvent(this.props.event)}
                     color="primary"
                   >
                    Update
                  </Button>
                  <Button variant="outlined"
                    onClick={() => this.props.onOpenDeletePopup(this.props.event, "Event")}
                    color="secondary"
                   >
                    Delete
                  </Button>
                </div>
        </>
        )
    }

}

export default Event

Event.propTypes={
    event: PropTypes.object,
    users: PropTypes.array,
    handleCreateOrUpdateEvent: PropTypes.func,
    handleClose: PropTypes.func,
    onOpenDeletePopup: PropTypes.func

}
