import React from 'react'
import PropTypes from "prop-types"
import { Redirect } from 'react-router'
import WorkOutlineIcon from '@material-ui/icons/WorkOutline'
import styles from './LegalCase.module.css'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"

import ReceiptIcon from '@material-ui/icons/Receipt';
import ListIcon from '@material-ui/icons/List'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import axios from "axios"

import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'

import Events from './Event/Events'
import Tasks from './Task/Tasks'
import Invoices from './Invoice/Invoices'
import Transactions from './Transaction/Transactions'
import ClientModalShow from "./ClientModalShow"
import PersonIcon from "@material-ui/icons/Person"
import Workflows from './WorkFlows/Workflows'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import NewAndEditForm from './NewAndEditForm'
import SimplePopUp from "../../shared/SimplePopUp/SimplePopUp";
import Comments from './Comment/Comments'
import {
  Button,
  IconButton,
  Tooltip,
  ButtonGroup,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table
} from "@material-ui/core";
import Badge from '@material-ui/core/Badge';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import ShowContactAttributes from "../Client/ContactPerson/ShowContactAttributes";

import Documents from './Documents/Documents'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import AlertMessage from "../../shared/Notify/AlertMessage";

import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import SimpleTable from "../../shared/SimpleTable/SimpleTable";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ReferenceNumberForm from "./RefernceNumber/ReferenceNumberForm";
import RecentActorsIcon from '@material-ui/icons/RecentActors'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ClientForm from '../Client/ClientForm'

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
  }
}

class LegalCaseShow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
      model: 'Case',
      legalCase: {},
      events: [],
      isOpenStatus: false,
      anchorEl: null,
      practiceAreas: [],
      clients: [],
      users: [],
      tasks: [],
      title: '',
      defaultBiller: [],
      referenceNumbers: [],
      contactNumbers: [],
      emailAddresses: [],
      contactPersons: [],
      addresses: [],
      cfield: {},
      references: [],
      expanded: true,
      fields: [],
      customFields: {},
      load: false,
      withEdit: true,
      error_messages: '',
      isOpen: false,
      message: '',
      type: '',
      openPopUp: false,
      openCaseDeletePopup: false,
      folderFromDocsIndex: {},
      redirectToIndex: false,
      maxWidth: 'md',
      reference_nos: [],
      openRefPopup: false,
      openRefDeletePopup: false,
      reference_no: {},
      refcfield: {},
      initialRef: { uid: '', reference_no: '', fields: { court_name: '', date_file: '', judge_name: '' } },
      tableRefHead: ['reference_no', 'fields.court_name', 'fields.date_file', 'fields.judge_name'],
      withShow: false,
      titleRef: '',
      withShowPopUp: true,
      maxRefWidth: 'sm',
      showing: false,
      taskBadge: null,
      opposingCounsel: [],
      selectedCounsel: [],
      errorMessages: {},
      openClientform: false,
      // client: {},
      counseladdresses: [{ content: '', category: 0 }],
      address: { content: '', category: 0 },
      emailAddress: { content: '', category: 0 },
      contactNumber: { content: '', category: 0 },
      counselcontactNumbers: [{ content: '', category: 0 }],
      counselemailAddresses: [{ content: '', category: 0 }],
      contactPerson: { name: '', designation: '', department: '', remarks: '' },
      counselcontactPersons: [{ name: '', designation: '', department: '', remarks: '' }],
      groups: [],
      counsel: {},
      defaultGroup: {},
      clientcfield: {},
      clientfields: [],
      clientExpanded: false,
      clientErrorMessages: {},
      clientToShow: {},
      openClientPopup: false,
      clientAddresses: [],
      clientContactPersons: [],
      clientContactNumbers: [],
      clientEmailAddresses: [],
      legalCases: [],
    }
    this.handleChange = this.handleChange.bind(this)
    this.setVal = this.setVal.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleExpandClick = this.handleExpandClick.bind(this)
    this.handleAutoComplete = this.handleAutoComplete.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleField = this.handleField.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.onOpenDeletePopUp = this.onOpenDeletePopUp.bind(this)
    this.handleRefSubmit = this.handleRefSubmit.bind(this)
    this.handleRefDeleteItem = this.handleRefDeleteItem.bind(this)
    this.handleRefCreateorUpdateItem = this.handleRefCreateorUpdateItem.bind(this)
    this.handleRefInputChange = this.handleRefInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleRefDeleteClose = this.handleRefDeleteClose.bind(this)
    this.handleClickOpen = this.handleClickOpen.bind(this)
    this.handleClickClose = this.handleClickClose.bind(this)
    this.handleRefFields = this.handleRefFields.bind(this)
    this.handleCloseCaseHistory = this.handleCloseCaseHistory.bind(this)
    this.isFormValid = this.isFormValid.bind(this)
    this.countTaskBadge = this.countTaskBadge.bind(this)
    this.handleOpposingCounsel = this.handleOpposingCounsel.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.handleChangeSave = this.handleChangeSave.bind(this)
    this.handleClosePopUp = this.handleClosePopUp.bind(this)
    this.handleClientPopup = this.handleClientPopup.bind(this)
    this.handleClientChange = this.handleClientChange.bind(this)
    this.handleSubmitClient = this.handleSubmitClient.bind(this)
    this.handleAddressChange = this.handleAddressChange.bind(this)
    this.handleEmailAddressChange = this.handleEmailAddressChange.bind(this)
    this.handleContactNumberChange = this.handleContactNumberChange.bind(this)
    this.addAddress = this.addAddress.bind(this)
    this.addEmailAddress = this.addEmailAddress.bind(this)
    this.addContactNumber = this.addContactNumber.bind(this)
    this.removeAddress = this.removeAddress.bind(this)
    this.removeEmail = this.removeEmail.bind(this)
    this.removeContactNumber = this.removeContactNumber.bind(this)
    this.handleNumberChange = this.handleNumberChange.bind(this)
    this.handleClientAutoComplete = this.handleClientAutoComplete.bind(this)
    this.handleContactPersonChange = this.handleContactPersonChange.bind(this)
    this.addContactPerson = this.addContactPerson.bind(this)
    this.removeContactPerson = this.removeContactPerson.bind(this)

    this.handleClientField = this.handleClientField.bind(this)
    this.handleClientSwitch = this.handleClientSwitch.bind(this)
    this.handleClientClose = this.handleClientClose.bind(this)
    this.handleClientExpanded = this.handleClientExpanded.bind(this)
    this.openClientDialog = this.openClientDialog.bind(this)

  }

  componentDidMount() {
    if (this.props.location.tabNumber !== undefined) {
      this.setState({ value: this.props.location.tabNumber })
      if (this.props.location.folder !== undefined) {
        this.setState({ folderFromDocsIndex: this.props.location.folder })
      }
      this.setState({ expanded: false })
    }
    if (this.props.location.state !== undefined) {
      if (localStorage.getItem('newCase') !== null && this.props.match.params.uid === JSON.parse(localStorage.getItem('newCase')).uid) {
        this.setState({ legalCase: JSON.parse(localStorage.getItem('newCase')) })
        this.setState({ cfield: JSON.parse(localStorage.getItem('newCase')).fields })
        this.loadLegalCases(JSON.parse(localStorage.getItem('newCase')).uid)
        this.setState({ load: true })
      } else {
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.match.params.uid,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then(resp => {
          this.setState({ legalCase: resp.data })
          this.setState({ cfield: this.props.location.state.item.fields })
          this.loadLegalCases(this.props.location.state.item.uid)
          this.setState({ load: true })
        })
        // this.setState({ legalCase: this.props.location.state.item })
      }
    } else {
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.match.params.uid,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ legalCase: resp.data })
        this.setState({ cfield: resp.data.fields })
        this.loadLegalCases(resp.data.uid)
        this.setState({ load: true })
      })
        .catch(resp => console.log(resp))
    }
  }

  loadLegalCases(uid) {

    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/members',
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      // this.setState({ users: resp.data })
      //code below for ui just to show rate of users
      const newData = resp.data.map((item, idx) => ({
        uid: item.uid,
        username: item.username,
        first_name: item.first_name,
        email: item.email,
        middle_name: item.middle_name,
        last_name: item.last_name,
        rate: parseFloat(1000 + (1000 * idx))
      }))
      this.setState({ users: newData })
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/practice_areas?per_page=10000',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ practiceAreas: resp.data.data })
        var areaName = {}
        if (this.state.legalCase.practice_area_uid !== null) {
          if (resp.data.data.length > 0) {
            resp.data.data.map((area) => {
              if (area.uid === this.state.legalCase.practice_area_uid) {
                areaName = area
              }
            })
          }
        }
        this.setState({ defaultArea: areaName })
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/clients',
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then(resp => {
          this.setState({ clients: resp.data.data })
          var counsel = resp.data.data.filter(item => item.group.name === 'Opposing Party')
          this.setState({ opposingCounsel: counsel })
          axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/custom_fields',
            headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
          }).then(resp => {
            this.setState({ fields: resp.data })
            axios({
              method: 'get',
              url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + uid + '/events',
              headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
            }).then(resp => {
              this.setState({ events: resp.data })
              axios({
                method: 'get',
                url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + uid + '/tasks',
                headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
              }).then(resp => {
                const newData = []
                resp.data.map((task) => {
                  if (task.state === "ongoing") {
                    newData.push(task)
                  }
                })
                var taskCount = 0
                if (resp.data.length > 0) {
                  resp.data.map((task) => {
                    if (task.state === 'ongoing' && new Date(task.ends_at) <= new Date()) {
                      taskCount = taskCount + 1
                    }
                  })
                }
                this.setState({ taskBadge: taskCount })
                this.setState({ tasks: newData })

                axios({
                  method: 'get',
                  url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.state.legalCase.biller_uid + '/addresses',
                  headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
                }).then(resp => {

                  this.setState({ addresses: resp.data })
                  this.setState({ load: true })
                  axios({
                    method: 'get',
                    url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.state.legalCase.biller_uid + '/contact_numbers',
                    headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
                  }).then(resp => {

                    this.setState({ contactNumbers: resp.data })
                    this.setState({ load: true })
                    axios({
                      method: 'get',
                      url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.state.legalCase.biller_uid + '/email_addresses',
                      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
                    }).then(resp => {
                      this.setState({ emailAddresses: resp.data })
                      this.setState({ load: true })
                      axios({
                        method: 'get',
                        url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.state.legalCase.biller_uid + '/contact_persons',
                        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
                      }).then(resp => {
                        this.setState({ contactPersons: resp.data })
                        axios({
                          method: 'get',
                          url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + uid + '/reference_numbers',
                          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
                        }).then(resp => {
                          this.setState({ referenceNumbers: resp.data })
                          this.setState({ load: true })
                        }).catch(resp => console.log(resp))
                      }).catch(resp => console.log(resp))
                    }).catch(resp => console.log(resp))
                  }).catch(resp => console.log(resp))
                }).catch(resp => console.log(resp))
              }).catch(error => console.log(error.response))
            }).catch(error => console.log(error.response))
          }).catch(error => console.log(error.response))
        }).catch(error => console.log(error.response))
      }).catch(error => console.log(error.response))
    }).catch(error => console.log(error.response))
  }
  handleClientExpanded() {
    this.setState({ clientExpanded: !this.state.clientExpanded })
  }

  handleClientClose() {
    this.setState({ openClientform: false })
  }

  handleClientPopup() {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/groups',
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      if (resp.data.filter(item => item.name === 'Opposing Party').length > 0) {
        this.setState({ defaultGroup: resp.data.filter(item => item.name === 'Opposing Party')[0] })
      }
      this.setState({ groups: resp.data })
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/custom_fields',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ clientfields: resp.data })
      
      this.setState({
        openClientform: true,
        counsel: {
          uid: '',
          name: '',
          group_id: '',
          is_company: false,
          is_active: true,
          is_taxable: false,
          fields: {}
        }
      })
    })
    })

   
  }

  handleClientSwitch(event) {
    const value = event.target.checked
    this.setState({
      counsel: {
        ...this.state.counsel,
        [event.target.name]: value
      }
    })
  }
  removeAddress(idx) {
    this.state.counseladdresses.splice(idx, 1)
    this.setState({ counseladdresses: [...this.state.counseladdresses] })

  }

  handleAddressChange(e) {
    const updatedAddress = [...this.state.counseladdresses]
    updatedAddress[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
    this.setState({ counseladdresses: updatedAddress })
  }

  addAddress() {
    this.setState({ counseladdresses: [...this.state.counseladdresses, { ...this.state.address }] })
  }

  removeEmail(idx) {
    this.state.counselemailAddresses.splice(idx, 1)
    this.setState({ counselemailAddresses: [...this.state.counselemailAddresses] })

  }

  handleEmailAddressChange(e) {
    const updatedEmailAddress = [...this.state.counselemailAddresses]
    updatedEmailAddress[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
    this.setState({ counselemailAddresses: updatedEmailAddress })

  }

  addEmailAddress() {
    this.setState({ counselemailAddresses: [...this.state.counselemailAddresses, { ...this.state.emailAddress }] })
  }

  removeContactNumber(idx) {
    this.state.counselcontactNumbers.splice(idx, 1)
    this.setState({ counselcontactNumbers: [...this.state.counselcontactNumbers] })

  }

  handleNumberChange(e) {
    const re = /^[0-9\b]+$/
    if (e.target.value === '' || re.test(e.target.value)) {
      const updatedContactNumbers = [...this.state.counselcontactNumbers]
      updatedContactNumbers[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
      this.setState({ counselcontactNumbers: updatedContactNumbers })

    }
  }

  handleContactNumberChange(e) {
    const updatedContactNumbers = [...this.state.counselcontactNumbers]
    updatedContactNumbers[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
    this.setState({ counselcontactNumbers: updatedContactNumbers })

  }

  addContactNumber() {
    this.setState({ counselcontactNumbers: [...this.state.counselcontactNumbers, { ...this.state.contactNumber }] })
  }

  removeContactPerson(idx) {
    this.state.counselcontactPersons.splice(idx, 1)
    this.setState({ counselcontactPersons: [...this.state.counselcontactPersons] })

  }

  handleContactPersonChange(e) {
    const updatedContactPersons = [...this.state.counselcontactPersons]
    updatedContactPersons[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
    this.setState({ counselcontactPersons: updatedContactPersons })
  }

  addContactPerson() {
    this.setState({ counselcontactPersons: [...this.state.counselcontactPersons, { ...this.state.contactPerson }] })
  }

  handleSubmitClient() {
    var item = {}
    item['name'] = this.state.counsel.name
    item['uid'] = this.state.counsel.uid
    item['group_id'] = this.state.defaultGroup.uid
    item['is_active'] = this.state.counsel.is_active
    item['is_company'] = this.state.counsel.is_company
    item['is_taxable'] = this.state.counsel.is_company
    item['fields'] = this.state.counsel.fields
    if (this.state.counsel.is_company) {
      item['contact_persons_attributes'] = this.state.counselcontactPersons
      item['addresses_attributes'] = this.state.counseladdresses
    }
    else {
      item['addresses_attributes'] = this.state.counseladdresses
      item['email_addresses_attributes'] = this.state.counselemailAddresses
      item['contact_numbers_attributes'] = this.state.counselcontactNumbers
    }
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/clients',
      data: (item),
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = resp.data

      this.setState({ opposingCounsel: [...this.state.opposingCounsel, newData], selectedCounsel: [...this.state.selectedCounsel, newData] })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })

      this.setState({ clientErrorMessages: {} })
      this.setState({ errorMessages: {} })
      this.setState({ openClientform: false })
    }).catch(error => {
      if (error.response.status === 422) {
        var errors = {}
        Object.keys(error.response.data.errors).map((field) => {
          errors[field] = error.response.data.errors[field][0]
          if (field.includes('.')) {
            errors[field.split(".")[1]] = error.response.data.errors[field][0]
          } else {
            errors[field] = error.response.data.errors[field][0]
          }
        })
        this.setState({ clientErrorMessages: errors })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
  }

  handleClientChange(e) {
    this.setState({
      counsel: {
        ...this.state.counsel,
        [e.target.name]: e.target.value
      }
    })
  }


  handleClientAutoComplete(event, values) {
    this.setState({ defaultGroup: values })
  }

  handleClientField(e) {
    const value = e.target.value
    this.setState({
      cfield: {
        ...this.state.cfield,
        [e.target.name]: value
      }
    })
    this.setState({
      counsel: {
        ...this.state.counsel,
        fields: JSON.stringify({
          ...this.state.cfield,
          [e.target.name]: value
        })
      }
    })
  }

  handleExpandClick() {
    this.setState({ expanded: !this.state.expanded })
  }


  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    var selectedCounsel = []
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
      if (item.counsel_uids !== null && item.counsel_uids !== undefined) {
        item.counsel_uids.map((counsel) => {
          axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + counsel,
            headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
          }).then(resp => {
            selectedCounsel.push(resp.data)
            this.setState({ selectedCounsel: selectedCounsel })
          })
        })
      }
    }
    this.setState({ openPopUp: true })
    this.setState({ legalCase: item })
    this.setState({ title: textTitle })
  }

  handleClose() {
    this.setState({ openPopUp: false })
    this.setState({ openDeletePopup: false })
    this.setState({ openCaseDeletePopup: false })
    this.setState({ openRefPopup: false })
    this.setState({ openRefDeletePopup: false })
    this.setState({ error_messages: '', openClientPopup: false })
  }

  handleSubmit() {
    const item = this.state.legalCase
    if (this.state.selectedCounsel.length > 0) {
      var ids = []
      this.state.selectedCounsel.map((item) => {
        ids.push(item.uid)
      })
      item['counsel_ids'] = ids
    }
    var method = ''
    var url = ''
    if (item.uid === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + item.uid
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ legalCase: resp.data })
      localStorage.setItem('newCase', JSON.stringify(resp.data))
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({ error_messages: '' })
      this.setState({ openPopUp: false })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Reference Number ' + error.response.data.errors.reference_no[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
  }

  onOpenDeletePopUp() {
    this.setState({ model: this.state.model })
    this.setState({ legalCase: this.state.legalCase })
    this.setState({ openCaseDeletePopup: true })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.legalCase.uid
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        legalCases: this.state.legalCases.filter(item => item.uid !== DeleteItemId)
      })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
      this.setState({ openCaseDeletePopup: false })
      this.setState({ redirectToIndex: true })
    }).catch(error => console.log(error.response))
  }


  handleAutoComplete(event, values) {
    if (event.target.id.includes("user_name")) {

      this.setState(values)
      const user_ids = []
      values.map((value) => {
        user_ids.push(value.uid)
      })
      this.setState({
        legalCase: {
          ...this.state.legalCase,
          user_ids: user_ids
        }
      })
    } else if (event.target.id.includes("client_name")) {
      const client_ids = []
      values.map((value) => {
        client_ids.push(value.uid)
      }
      )
      this.setState({ defaultBiller: values })
      this.setState({
        legalCase: {
          ...this.state.legalCase,
          client_ids: client_ids
        }
      })
    } else if (event.target.id.includes("biller_name")) {
      this.setState({
        legalCase: {
          ...this.state.legalCase,
          biller_id: values.uid
        }
      })
    } else {
      if (values === null) {
        return this.state.practiceAreas
      } else {
        this.setState({ defaultArea: values })
        this.setState({
          legalCase: {
            ...this.state.legalCase,
            practice_area_id: values.uid
          }
        })
      }
    }

  }

  setVal(name) {
    const attrName = name.toLowerCase().replace(' ', '_')
    const value = 'N/A'
    if (this.state.cfield[attrName] === undefined) {
      return value
    } else if (this.state.cfield[attrName] === null) {
      return value
    } else if (this.state.cfield[attrName] === '') {
      return value
    } else {
      return this.state.cfield[attrName]
    }
  }

  handleChange(event, newValue) {
    this.setState({ value: newValue })
    if (newValue !== 0) {
      this.setState({ expanded: false })
    }
  }

  handleInputChange(e) {
    this.setState({
      legalCase: {
        ...this.state.legalCase,
        [e.target.name]: e.target.value
      }
    })
  }

  handleField(e) {
    const value = e.target.value
    this.setState({
      cfield: {
        ...this.state.cfield,
        [e.target.name]: value
      }
    })
    this.setState({
      legalCase: {
        ...this.state.legalCase,
        fields: JSON.stringify({
          ...this.state.cfield,
          [e.target.name]: value
        })
      }
    })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      isOpen: false
    })
  }


  handleRefSubmit() {
    const item = this.state.reference_no
    var method = ''
    var url = ''
    if (item.uid === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.state.legalCase.uid + '/reference_numbers'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.state.legalCase.uid + '/reference_numbers/' + item.uid
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = resp.data
      this.setState({
        referenceNumbers: this.state.referenceNumbers.filter(reference_no => reference_no.uid !== item.uid)
      })
      this.setState({ referenceNumbers: [...this.state.referenceNumbers, newData] })
      this.setState({ openRefPopup: false })
    }).catch(error => console.log(error.response))
  }

  handleRefDeleteItem() {
    const DeleteItemId = this.state.reference_no.uid
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.state.legalCase.uid + '/reference_numbers/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        referenceNumbers: this.state.referenceNumbers.filter(item => item.uid !== DeleteItemId)
      })
      this.setState({ openRefDeletePopup: false })
    }).catch(error => console.log(error.response))
  }

  handleRefCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({ openRefPopup: true })
    this.setState({ reference_no: item })
    this.setState({ titleRef: textTitle })
  }

  handleRefInputChange(e) {
    this.setState({
      reference_no: {
        ...this.state.reference_no,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ reference_no: item })
    this.setState({ openRefDeletePopup: true })
  }

  handleRefDeleteClose() {
    this.setState({ openRefDeletePopup: false })
  }

  handleClickOpen(e) {
    e.stopPropagation()
    this.setState({ showing: true })
    this.setState({ openCaseHistory: true })
  }

  handleClickClose(e) {
    e.stopPropagation()
    this.setState({ showing: false })
  }

  handleRefFields(e) {
    const value = e.target.value
    this.setState({
      refcfield: {
        ...this.state.refcfield,
        [e.target.name]: value
      }
    })
    this.setState({
      reference_no: {
        ...this.state.reference_no,
        fields: JSON.stringify({
          ...this.state.refcfield,
          [e.target.name]: value
        })
      }
    })
  }

  handleCloseCaseHistory() {
    this.setState({ openCaseHistory: false })
  }

  isFormValid() {
    const { user_uids, client_uids } = this.state.legalCase
    if (user_uids.length > 0 && client_uids.length > 0) {
      return user_uids && client_uids
    }
  }

  handleOpposingCounsel(event, value) {
    this.setState({ selectedCounsel: value })
  }

  countTaskBadge() {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.state.legalCase.uid + '/tasks',
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      var taskCount = 0
      if (resp.data.length > 0) {
        resp.data.map((task) => {
          if (task.state === 'ongoing' && new Date(task.ends_at) <= new Date()) {
            taskCount = taskCount + 1
          }
        })
      }
      this.setState({ taskBadge: taskCount })
    })
  }

  handleToggle(event) {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleChangeSave(name) {
    axios({
      method: 'put',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.match.params.uid + '/' + name,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then((resp) => {
      this.setState({ legalCase: resp.data, anchorEl: null })
    })
  }

  handleClosePopUp() {
    this.setState({ anchorEl: null })
  }

  openClientDialog(client) {
    if (client.is_company) {
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + client.uid + '/contact_persons',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        this.setState({ clientContactPersons: resp.data })
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + client.uid + '/addresses',
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then((resp) => {
          this.setState({ clientAddresses: resp.data, clientToShow: client, openClientPopup: true })
        })

      })
    } else {
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + client.uid + '/email_addresses',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        this.setState({ clientEmailAddresses: resp.data })
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + client.uid + '/contact_numbers',
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then((resp) => {
          this.setState({ clientContactNumbers: resp.data })
          axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + client.uid + '/addresses',
            headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
          }).then((resp) => {
            this.setState({ clientAddresses: resp.data, clientToShow: client, openClientPopup: true })
          })
        })
      })
    }

  }


  render() {
    const { redirectToIndex } = this.state

    if (redirectToIndex)
      return (<Redirect to={'/legal_cases'} />)

    const { anchorEl } = this.state
    return (
      <>
        {this.state.load ? (
          <div className={styles.body}>
            <Grid container spacing={1} className={styles.headerTitleBody}>

              <Grid item xs={12} sm={8}  md={6}>
              <div className={styles.caseTitle}>
                <WorkOutlineIcon fontSize="large" />
                <div>
                  <div className={styles.title}>
                    {this.state.legalCase.title}
                  </div>
                  <div className={styles.subtitle}>

                    <div><i>{this.state.legalCase.reference_no}</i></div>
                    <div>{(new Date(this.state.legalCase.date_filed).toLocaleDateString('en-US'))} - Date Retained</div>
                    <div>
                      <Button
                        ref={anchorEl}
                        id="composition-button"
                        aria-controls={this.state.isOpenStatus ? 'composition-menu' : undefined}
                        aria-expanded={this.state.isOpenStatus ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleToggle}
                        className={this.state.legalCase.state === "active" ? styles.activeState : this.state.legalCase.state === "pending" ? styles.pendingState : styles.doneState}
                      >
                        {this.state.legalCase.state}  {anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </Button>
                      <Menu
                        id="fade-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={this.handleClosePopUp}
                        TransitionComponent={Fade}
                      >


                        {['active', 'pending', 'done'].filter(a => a !== this.state.legalCase.state).map((option) => (
                          <MenuItem key={option} selected={option === this.state.legalCase.state} onClick={() => this.handleChangeSave(option)} className={styles.optionName}>
                            {option}
                          </MenuItem>
                        ))}

                      </Menu>

                    </div>
                  </div>
                </div>
              </div>
              </Grid>

              <Grid item xs={12} md={4} sm={4}  className={styles.buttonGroup}>
                <ButtonGroup className={styles.groupButton}>
                  {

                    this.state.withEdit ? (
                      <Tooltip title="Edit Case">
                        <Button
                          variant="outlined"
                          color="primary"
                          className={styles.editButton}
                          onClick={() => this.handleCreateorUpdateItem(this.state.legalCase, false, this.state.model)}>
                          <EditIcon /> Edit
                        </Button>
                      </Tooltip>
                    ) : (null)
                  }
                  <Tooltip title="Delete Case">
                    <Button
                      variant="outlined"
                      color="secondary"
                      name="delete_case_button"
                      onClick={() => this.onOpenDeletePopUp()}
                    >
                      <DeleteIcon />Delete
                    </Button>
                  </Tooltip>

                </ButtonGroup>
              </Grid>

            </Grid>

            <div className={styles.contentBody}>
              <div className={styles.LeftBody}>
                <Tabs value={this.state.value} onChange={this.handleChange} textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  className={styles.tabBody}>
                  <Tab className={styles.tabList}
                    label={<div className={styles.tabListTitle}><ImportContactsIcon />
                      <div
                        className={styles.tabLabel}>Info
                      </div>
                    </div>}{...a11yProps(0)} name="infoTab" />
                  <Tab className={styles.tabList}
                    label={<div className={styles.tabListTitle}><ListIcon />
                      <div className={styles.tabLabel}>
                        <Badge badgeContent={this.state.tasks.length} color="secondary">
                          Tasks
                        </Badge>
                      </div>
                    </div>}{...a11yProps(1)} />
                  <Tab className={styles.tabList} label={<div className={styles.tabListTitle}><CalendarTodayIcon />
                    <div
                      className={styles.tabLabel}>
                      <Badge badgeContent={this.state.events.length} color="secondary">
                        Events
                      </Badge>
                    </div>
                  </div>} {...a11yProps(2)} />
                  <Tab className={styles.tabList} label={<div className={styles.tabListTitle}><FolderOpenIcon />
                    <div
                      className={styles.tabLabel}>Documents
                    </div>
                  </div>} {...a11yProps(3)} name="documentTab" />
                  <Tab className={styles.tabList}
                    label={<div className={styles.tabListTitle}><ChatBubbleOutlineIcon />
                      <div
                        className={styles.tabLabel}>Comments
                      </div>
                    </div>} {...a11yProps(4)} />
                  <Tab className={styles.tabList} label={<div className={styles.tabListTitle}><AccountTreeIcon />
                    <div
                      className={styles.tabLabel}>WorkFlows
                    </div>
                  </div>} {...a11yProps(5)} />
                  <Tab className={styles.tabList}
                    label={<div className={styles.tabListTitle}><ReceiptIcon />
                      <div
                        className={styles.tabLabel}>Transactions
                      </div>
                    </div>} {...a11yProps(6)} name="transactionTab" />
                  <Tab className={styles.tabList} label={<div className={styles.tabListTitle}><FileCopyIcon />
                    <div
                      className={styles.tabLabel}>Invoices
                    </div>
                  </div>} {...a11yProps(7)} />
                </Tabs>
                <TabPanel value={this.state.value} index={0}>
                  <Grid container>
                    <Grid item xs={12}>
                      <div className={styles.caseInfoBody}>
                        <div>
                          <div className={styles.fieldBody}>
                            <div className={styles.fieldTitle}>Practice Area <span>:</span></div>
                            <div className={styles.fieldValue}>
                              {this.state.legalCase.practice_area_uid !== null
                                ? (
                                  this.state.practiceAreas.length > 0
                                    ? (
                                      this.state.practiceAreas.map((practicename) => (
                                        this.state.legalCase.practice_area_uid === practicename.uid
                                          ? <div key={practicename.uid}>{practicename.name}</div>
                                          : null
                                      ))
                                    ) : null
                                ) : <div>No Practice Area linked to this case.</div>
                              }
                            </div>
                          </div>
                          <div className={styles.fieldBody}>
                            <div className={styles.fieldTitle}>Assigned Lawyer <span>:</span></div>
                            <div className={styles.fieldValue}>
                              {
                                this.state.legalCase.user_uids !== null
                                  ? (
                                    this.state.legalCase.user_uids.map(userName => (
                                      this.state.users.length > 0
                                        ? (
                                          this.state.users.map((user) => (
                                            userName === user.uid?
                                             <div>{( user.first_name? user.first_name:'') + " " + (user.last_name? user.last_name: '')}</div>
                                            : null
                                          ))
                                        ) : null
                                    ))
                                  ) : <div className="value-field">No Assigned Lawyer</div>
                              }</div>
                          </div>
                          <div className={styles.fieldBody}>
                            {this.state.clients.length > 0 ? (
                              <div className={styles.fieldTitle}>Client <span>:</span></div>
                            ) : <div className={styles.fieldTitle}>Client <span>:</span></div>
                            }
                            <div className={styles.fieldValue}>
                              {
                                this.state.legalCase.client_uids !== null
                                  ? (
                                    this.state.legalCase.client_uids.map(clientName => (
                                      this.state.clients.length > 0
                                        ? (
                                          this.state.clients.map((client) => (
                                            clientName === client.uid
                                              ? <div onClick={() => this.openClientDialog(client)} className={styles.cursor}>{client.name}</div>
                                              : null
                                          ))
                                        ) : null
                                    ))
                                  ) : <div className="value-field">No Assigned Client</div>
                              }
                            </div>
                          </div>
                          <div className={styles.fieldBody}>
                            <div className={styles.fieldTitle}>Opposing Party <span>:</span></div>
                            <div className={styles.fieldValue}>
                              {
                                this.state.legalCase.counsel_uids !== null
                                  ? (
                                    this.state.legalCase.counsel_uids.map(counselName => (
                                      this.state.opposingCounsel.length > 0
                                        ? (
                                          this.state.opposingCounsel.map((counsel) => (
                                            counselName === counsel.uid
                                              ? <div onClick={() => this.openClientDialog(counsel)} className={styles.cursor}>{counsel.name}</div>
                                              : null
                                          ))
                                        ) : null
                                    ))
                                  ) : <div className="value-field">No Assigned Opposing Party</div>
                              }</div>
                          </div>

                          <div className={`${styles.fieldBody} ${styles.fieldBody1}`}>
                            <div className={styles.fieldTitle}> Court/Agency <span>:</span></div>
                            <div className={styles.refValue}>
                            {this.state.referenceNumbers.length > 0
                              ? (
                                this.state.referenceNumbers.sort(
                                  (a, b) => new Date(a.fields.date_file).getTime() > new Date(b.fields.date_file).getTime()
                                ).map((ref, index) => (
                                  index < 1 &&
                                  <div key={ref.uid}>

                                    <div className={styles.fieldBody}>
                                      <div className={styles.fieldLabel}> Case Number <span>:</span></div>
                                      <div className={styles.fieldValue}>{ref.reference_no}</div>
                                    </div>
                                    <div className={styles.fieldBody}>
                                      <div className={styles.fieldLabel}> Court Name <span>:</span></div>
                                      <div
                                        className={styles.fieldValue}>{ref.fields.court_name ? (ref.fields.court_name) : ('n/a')}</div>
                                    </div>
                                    <div className={styles.fieldBody}>
                                      <div className={styles.fieldLabel}> Date filed <span>:</span></div>
                                      <div
                                        className={styles.fieldValue}>{ref.fields.date_file ? (ref.fields.date_file) : ('n/a')}</div>
                                    </div>

                                    <div className={styles.fieldBody}>
                                      <div className={styles.fieldLabel}> Judge name <span>:</span></div>
                                      <div
                                        className={styles.fieldValue}>{ref.fields.judge_name ? (ref.fields.judge_name) : ('n/a')}</div>
                                    </div>
                                  </div>
                                )))
                              : (null)
                            }
                            <div className={styles.fieldValue}>
                              <div className={styles.historyIcon}>
                                <IconButton
                                  color="primary"
                                  variant="outlined"
                                  onClick={this.handleClickOpen} className={styles.caseCourtbutton}>
                                  Show case court history
                                </IconButton>
                              </div>
                              <SimplePopUp
                                openPopup={this.state.openCaseHistory}
                                title="Case Court history"
                                handleClose={this.handleCloseCaseHistory}
                                maxWidth='md'
                              >
                                <SimpleTable
                                  handleClick={this.handleRefCreateorUpdateItem}
                                  initialItem={this.state.initialRef}
                                  items={this.state.referenceNumbers}
                                  model="Reference Number"
                                  onOpenDeletePopup={this.handleOpenDeletePopup}
                                  headers={this.state.tableRefHead}
                                  withEdit={this.state.withEdit}
                                  icon={<AccountBalanceIcon fontSize="large" />}
                                />
                              </SimplePopUp>

                              <SimplePopUp
                                openPopup={this.state.openRefPopup}
                                title={this.state.titleRef}
                                items={this.state.referenceNumbers}
                                handleClose={this.handleClose}
                                maxWidth={this.state.mdWidth}
                              >
                                <ReferenceNumberForm
                                  handleRefFields={this.handleRefFields} item={this.state.reference_no}
                                  submit={this.handleRefSubmit} onchange={this.handleRefInputChange} />
                              </SimplePopUp>
                              <SimpleDeletePopUp
                                openDeletePopup={this.state.openRefDeletePopup}
                                item={this.state.reference_no}
                                delete={this.handleRefDeleteItem}
                                handleDeleteClose={this.handleClose}
                                model={this.state.model}
                              />
                            </div>
                            </div>
                          </div>

                          <div className={`${styles.fieldBody} ${styles.fieldBodyCus}`}>
                            <div className={styles.fieldTitle}>Custom Fields <span>:</span></div>
                            <div  className={styles.refValue}>
                              {
                                this.state.cfield === null ? (<div style={{ margin: '14px' }}>No Saved Data</div>) : (
                                  this.state.fields.length > 0 ? (
                                    this.state.fields.map((field) => (
                                      <div key={field.uid} className={styles.fieldBody}>
                                        <div className={styles.fieldLabel}>{field.attr_name} <span>:</span></div>
                                        <div className={styles.fieldValue}>{this.setVal(field.attr_name)} </div>
                                      </div>
                                    ))
                                  ) : null
                                )
                              }
                            </div>
                          </div>

                        </div>

                      </div>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                  <Tasks legalCase={this.state.legalCase} legalCaseId={this.state.legalCase.uid}
                    legalcaseUserId={this.state.legalCase.user_uids} />
                </TabPanel>

                <TabPanel value={this.state.value} index={2}>
                  <Events legalCase={this.state.legalCase} legalCaseId={this.state.legalCase.uid}
                    legalcaseUserId={this.state.legalCase.user_uids} />
                </TabPanel>

                <TabPanel value={this.state.value} index={3}>
                  <Documents legalCase={this.state.legalCase} folderFromDocsIndex={this.state.folderFromDocsIndex} />
                </TabPanel>
                <TabPanel value={this.state.value} index={4}>
                  <Comments uid={this.state.legalCase.uid} />
                </TabPanel>

                <TabPanel value={this.state.value} index={5}>
                  <Workflows uid={this.state.legalCase.uid} />
                </TabPanel>

                <TabPanel value={this.state.value} index={6}>
                  <Transactions uid={this.state.legalCase.uid} users={this.state.legalCase.user_uids}
                    members={this.state.users} />
                </TabPanel>
                <TabPanel value={this.state.value} index={7}>
                  <Invoices uid={this.state.legalCase.uid} legalCase={this.state.legalCase}
                    biller_uid={this.state.legalCase.biller_uid} users={this.state.legalCase.user_uids}
                    members={this.state.users} clients={this.state.clients} />
                </TabPanel>

              </div>
              {
                this.state.value === 0 &&
                <div className={styles.rightBody}>
                  <div className={styles.rightlistBody}>
                    {
                      this.state.legalCase.biller_uid !== null
                        ? (
                          this.state.clients.length > 0
                            ? (
                              this.state.clients.map((client) => (
                                this.state.legalCase.biller_uid === client.uid
                                  ? <div className={styles.listAvatarName}>
                                    <Avatar className={styles.avatarSize}>
                                      <PersonIcon className={styles.iconSize} />
                                    </Avatar>
                                    <div>
                                      <div className={styles.name}>
                                        <a href={`/clients/${client.uid}`} target="_blank" rel="noopener noreferrer" >{client.name}</a>
                                      </div>
                                      <div className={styles.billerSub}>
                                        <sub><i>Client</i></sub>
                                      </div>
                                    </div>
                                  </div>
                                  : null
                              ))
                            ) : null
                        ) : <div>No Client</div>
                    }


                    {
                      this.state.legalCase.biller_uid !== null
                        ? (
                          this.state.clients.length > 0
                            ? (
                              this.state.clients.map((client) => (
                                this.state.legalCase.biller_uid === client.uid
                                  ? (client.is_company ? (
                                    <div className={styles.clientBodyDetails}>

                                      <div className={styles.listBody}>
                                        <div className={styles.contactPersonTitle}>
                                          <RecentActorsIcon fontSize="small" /> Contact Person :
                                        </div>
                                        <div className={styles.clientBoxContent}>
                                          <Table size="small" stickyHeader aria-label="customized sticky table">
                                            <TableHead>
                                              <TableRow>
                                                <TableCell className={styles.cell}>Name</TableCell>
                                                <TableCell>
                                                  <TableCell className={styles.customCell}>
                                                    Telephone
                                                  </TableCell>
                                                  <TableCell className={styles.customCellEmail}>
                                                    Email
                                                  </TableCell>
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>

                                            <TableBody>
                                              {this.state.legalCase.biller_uid !== null
                                                ? (
                                                  this.state.contactPersons.length > 0
                                                    ? (
                                                      this.state.contactPersons.map((contact) => (
                                                        <TableRow key={contact.uid}>
                                                          <TableCell>
                                                            {contact.name}
                                                          </TableCell>
                                                          <TableCell className={styles.customTdCell}>
                                                            <ShowContactAttributes uid={contact.uid} primaryOnly={true} />
                                                          </TableCell>
                                                        </TableRow>
                                                      ))) : null) : null}
                                            </TableBody>
                                          </Table>
                                        </div>

                                      </div>

                                    </div>
                                  ) :
                                    <div className={styles.clientBodyDetails}>
                                      <div className={styles.listBody}>
                                        <div className={styles.addressTitle}>
                                          <PersonPinCircleIcon fontSize="small" />Address :
                                        </div>
                                        <div className={styles.clientDetailsValue}>

                                          {this.state.legalCase.biller_uid !== null
                                            ? (
                                              this.state.addresses.length > 0
                                                ? (
                                                  this.state.addresses.filter((filter) => filter.category_name != 'permanent').map((address) => (
                                                    <div key={address.uid}>
                                                      {address.content} <br /><i>{address.category_name}</i>
                                                    </div>
                                                  ))
                                                ) : null
                                            ) : <div>No address saved</div>
                                          }
                                        </div>
                                      </div>

                                      <div className={styles.listBody}>
                                        <div className={styles.contactTitle}>
                                          <ContactPhoneIcon fontSize="small" /> Contact Number :
                                        </div>
                                        <div className={styles.clientDetailsValue}>
                                          {this.state.legalCase.biller_uid !== null
                                            ? (
                                              this.state.contactNumbers.length > 0
                                                ? (
                                                  this.state.contactNumbers.filter((filter) => filter.category_name == 'primary').map((contact) => (
                                                    <div key={contact.uid}>{contact.content} <br /><i>{contact.category_name}</i>
                                                    </div>
                                                  ))
                                                ) : null
                                            ) : <div>No Practice Area assign</div>
                                          }
                                        </div>
                                      </div>

                                      <div className={styles.listBody}>
                                        <div className={styles.emailTitle}>
                                          <AlternateEmailIcon fontSize="small" /> Email :
                                        </div>
                                        <div className={styles.clientDetailsValue}>
                                          {this.state.legalCase.biller_uid !== null
                                            ? (
                                              this.state.emailAddresses.length > 0
                                                ? (
                                                  this.state.emailAddresses.map((email) => (
                                                    <div key={email.uid}>{email.content} <br /><i>{email.category_name} </i>
                                                    </div>
                                                  ))
                                                ) : null
                                            ) : <div>No Practice Area assign</div>
                                          }
                                        </div>
                                      </div>

                                    </div>)
                                  : null
                              ))
                            ) : null
                        ) : <div>No Biller</div>
                    }
                  </div>
                </div>
              }
            </div>

            <SimplePopUp
              openPopup={this.state.openPopUp}
              title={this.state.title}
              items={this.state.legalCase}
              handleClose={this.handleClose}
              maxWidth={this.state.maxWidth}
            >
              <NewAndEditForm
                item={this.state.legalCase}
                selectedUsers={this.state.selectedUsers}
                defaultBiller={this.state.defaultBiller}
                defaultArea={this.state.defaultArea}
                handleAutoComplete={this.handleAutoComplete}
                clients={this.state.clients}
                practiceAreas={this.state.practiceAreas}
                error={this.state.error_messages}
                users={this.state.users}
                submit={this.handleSubmit}
                fields={this.state.fields}
                expanded={this.state.expanded}
                handleExpandClick={this.handleExpandClick}
                handleField={this.handleField}
                cfield={this.state.cfield}
                onchange={this.handleInputChange}
                isFormValid={this.isFormValid}
                handleOpposingCounsel={this.handleOpposingCounsel}
                opposingCounsel={this.state.opposingCounsel}
                selectedCounsel={this.state.selectedCounsel}
                handleClientPopup={this.handleClientPopup}
              />
            </SimplePopUp>
            <SimplePopUp
              openPopup={this.state.openClientform}
              handleClose={this.handleClientClose}
              title="Create Opposing Party"
              maxWidth="md"
            >
              <ClientForm
                error={this.state.clientErrorMessages}
                groups={this.state.groups}
                client={this.state.counsel}
                addresses={this.state.counseladdresses}
                emailAddresses={this.state.counselemailAddresses}
                contactNumbers={this.state.counselcontactNumbers}
                contactPersons={this.state.counselcontactPersons}
                defaultGroup={this.state.defaultGroup}
                cfield={this.state.clientcfield}
                fields={this.state.clientfields}

                expanded={this.state.clientExpanded}

                submit={this.handleSubmitClient}

                handleChange={this.handleClientChange}
                handleSwitch={this.handleClientSwitch}

                handleAddressChange={this.handleAddressChange}
                removeAddress={this.removeAddress}
                addAddress={this.addAddress}

                handleEmailAddressChange={this.handleEmailAddressChange}
                removeEmail={this.removeEmail}
                addEmailAddress={this.addEmailAddress}

                handleContactNumberChange={this.handleContactNumberChange}
                removeContactNumber={this.removeContactNumber}
                addContactNumber={this.addContactNumber}
                handleNumberChange={this.handleNumberChange}

                handleContactPersonChange={this.handleContactPersonChange}
                removeContactPerson={this.removeContactPerson}
                addContactPerson={this.addContactPerson}
                // openGroupForm={this.openGroupForm}
                handleAutoComplete={this.handleClientAutoComplete}

                handleExpandClick={this.handleClientExpanded}
                handleField={this.handleClientField}
              />
            </SimplePopUp>
            <SimplePopUp
              openPopup={this.state.openClientPopup}
              handleClose={this.handleClose}
              title="Contact Information"
              maxWidth="sm"
            >
              <ClientModalShow
                addresses={this.state.clientAddresses}
                emailAddresses={this.state.clientEmailAddresses}
                contactNumbers={this.state.clientContactNumbers}
                contactPersons={this.state.clientContactPersons}
                client={this.state.clientToShow}

              />
            </SimplePopUp>

            <SimpleDeletePopUp
              openDeletePopup={this.state.openCaseDeletePopup}
              item={this.state.legalCase}
              delete={this.handleDeleteItem}
              handleDeleteClose={this.handleClose}
              model={this.state.model}
            />
            <AlertMessage
              notify={this.state.notify}
              handleCloseAlert={this.handleCloseAlert}
              isOpen={this.state.isOpen}
              type={this.state.type}
              message={this.state.message}
            />
          </div>
        ) : null}
      </>
    )
  }
}

export default LegalCaseShow


LegalCaseShow.propTypes = {
  id: PropTypes.number,
  handleCheckChieldElement: PropTypes.func,
  isChecked: PropTypes.func,
  handleCheck: PropTypes.func,
  fields: PropTypes.array,
  location: PropTypes.object,
  item: PropTypes.object,
  tabNumber: PropTypes.number,
  match: PropTypes.shape({
    params: PropTypes.shape({
      uid: PropTypes.string.isRequired
    })
  })
}
