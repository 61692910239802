import React from 'react'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

import logo from '../../../assets/brand/cms-logo.png'
import AlertMessage from '../../Notify/AlertMessage'
import styles from './Login.module.css'

class SessionsForm extends React.Component {
  render() {
    return (
      <div className={styles.sessionBody}>
        <div className={styles.sessionList}>
          <Grid container style={{ height: '100%' }}>
            <Grid item xs={12}>
              <div className={styles.logoHeader}>
                <img src={logo} alt="Website Logo" />
                <div className={styles.logoTitle}>Case Management System </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Container component="main" maxWidth="xs">
                <div className={styles.sessionBox}>
                  <CssBaseline />
                  <div className={styles.paper}>
                    <Avatar className={styles.avatar}>
                      <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                      {this.props.formLabel}
                    </Typography>
                    <form className={styles.form} noValidate>
                      <TextField
                        autoComplete="off"
                        autoFocus
                        variant="outlined"
                        margin="normal"
                        size="small"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        onChange={this.props.handleChange}
                        onKeyPress={this.props._handleKeyPress}
                        value={this.props.item.username}
                      />
                      {this.props.isLogin ? (
                        <>
                          <TextField
                            autoComplete="off"
                            variant="outlined"
                            margin="normal"
                            required
                            size="small"
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            value={this.props.item.password}
                            onChange={this.props.handleChange}
                            onKeyPress={this.props._handleKeyPress}
                          />
                          <div className={styles.errorText}>
                            {this.props.errors}
                          </div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={this.props.isRemember}
                                name="isRemember"
                                onChange={this.props.handleRemember}
                                color="primary"
                              />
                            }
                            label="Remember me"
                          />
                        </>
                      ) : (
                        <TextField
                          variant="outlined"
                          margin="normal"
                          autoComplete="off"
                          className={styles.textField}
                          required
                          size="small"
                          onChange={this.props.handleChange}
                          value={this.props.item.email}
                          onKeyPress={this.props._handleKeyPress}
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                        />
                      )}
                      <div
                        className={styles.reCaptCha}
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'space-around'
                        }}
                      >
                        <ReCAPTCHA
                          sitekey={
                            process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY
                          }
                          secretkey={
                            process.env.REACT_APP_GOOGLE_RECAPTCHA_SECRET_KEY
                          }
                          onChange={this.props.onChange}
                        />
                      </div>
                      <br />
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={styles.submit}
                        onClick={this.props.handleSubmit}
                        disabled={
                          !this.props.item.username ||
                          !this.props.openCaptchaPopup
                        }
                      >
                        {this.props.labelForSubmit}
                      </Button>
                      <div className={styles.linkBody}>
                        <div className={styles.forgot}>
                          <Link href={this.props.linkButtonOne} variant="body2">
                            {this.props.linkLabelOne}
                          </Link>
                        </div>
                        <div className={styles.signUp}>
                          <Link href={this.props.linkButtonTwo} variant="body2">
                            {this.props.linkLabelTwo}
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </Container>
            </Grid>
          </Grid>
          <AlertMessage
            handleCloseAlert={this.props.handleCloseAlert}
            isOpen={this.props.isOpen}
            type={this.props.type}
            message={this.props.message}
          />
        </div>
      </div>
    )
  }
}

export default SessionsForm

SessionsForm.propTypes = {
  item: PropTypes.object,
  handleChange: PropTypes.func,
  _handleKeyPress: PropTypes.func,
  formLabel: PropTypes.string,
  errors: PropTypes.string,
  linkButtonOne: PropTypes.string,
  linkButtonTwo: PropTypes.string,
  linkLabelOne: PropTypes.string,
  linkLabelTwo: PropTypes.string,
  labelForSubmit: PropTypes.string,
  setLoad: PropTypes.func,
  isLogin: PropTypes.func,
  isRemember: PropTypes.func,
  handleRemember: PropTypes.func,
  handleCloseAlert: PropTypes.func,
  type: PropTypes.string,
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  toggleCaptchaPopup: PropTypes.func,
  onChange: PropTypes.func,
  openCaptchaPopup: PropTypes.bool,
  handleSubmit: PropTypes.func
}
