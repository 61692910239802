import React from 'react'

import PropTypes from 'prop-types'

import {
  TextField,
  Button
} from '@material-ui/core'

import styles from '../LegalCase.module.css'

class FolderForm extends React.Component {
  render () {
    return (
      <div>
        <form>
          <TextField
            autoComplete="off"
            id="standard-basic"
            className={styles.formTextfield}
            variant="outlined"
            size="small"
            name="name"
            fullWidth
            label="Name"
            onChange={this.props.onchange}
            value={this.props.item.name}
          />
          <TextField
            autoComplete="off"
            className={styles.formTextfield}
            id="standard-basic"
            variant="outlined"
            size="small"
            name="description"
            fullWidth
            label="Description"
            onChange={this.props.onchange}
            value={this.props.item.description}
          />
        </form>
        
        <div className={styles.actionButton}>
          <Button disabled={!this.props.item.name} variant="outlined" color="primary" onClick={() => this.props.submit()}> Save</Button>
        </div>
      </div>
    )
  }
}
export default FolderForm

FolderForm.propTypes = {
  onchange: PropTypes.func,
  item: PropTypes.object,
  submit: PropTypes.func
}
