import React from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import { Box, Typography } from '@material-ui/core'
import TasksTable from './TasksTable'
import Checklist from './Checklist'
import Task from './Task'
import SimplePopUp from './../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import TaskForm from './TaskForm'
import TransactionForm from '../LegalCases/Transaction/TransactionForm'
import AlertMessage from '../../shared/Notify/AlertMessage'
import SearchForm from '../Tasks/SearchForm'
import ProgressBar from '../../shared/ProgressBar/ProgressBar'
import ExpenseTypeForm from '../LegalCases/Transaction/ExpenseTypeForm'
import ErrorPage from '../../shared/ErrorPage/ErrorPage'
import styles from './Tasks.module.css'
import TaskInvoice from './TaskInvoice'

export const Component = withRouter(() => {})

class Tasks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tasks: [],
      task: {},
      unreadTasks: [],
      load: false,
      showState: true,
      subs: [],
      openChecklist: false,
      checkedItems: [],
      users: [],
      removeAllSearchField: [],
      showPopUp: false,
      openTaskForm: false,
      defaultCase: {},
      legalCase: {},
      cases: [],
      initialTask: {
        uid: '',
        name: '',
        is_time_base: false,
        description: '',
        tag_list: '',
        parent_uid: '',
        user_uids: [localStorage.getItem('current_user_id')],
        ends_at: moment().format('YYYY-MM-DD')
      },
      checkedUsers: [localStorage.getItem('current_user_id')],
      show: true,
      subTasks: [],
      title: '',
      doneTask: {},
      openDeletePopup: false,
      headers: [
        'Task',
        'Assigned To',
        'Due Date',
        'Description',
        'Case',
        'Priority Level',
        'Done',
        'Status'
      ],
      searchFormField: {
        name: '',
        due_on_to: '',
        due_on_from: '',
        state: 'ongoing'
      },
      caseUsers: [],
      usersAutocomplete: [],
      openTransactionForm: false,
      expenseTypes: [],
      rebates: [],
      transaction: {},
      dateToday: new Date(),
      defaultTransactionType: {},
      members: [],
      openTransactionConfirmation: false,
      withSearch: true,
      q: '',
      error_messages: '',
      isOpen: false,
      message: '',
      openPopupSearch: false,
      type: '',
      currentPage: 1,
      totalPages: 1,
      searchUrl: process.env.REACT_APP_API_DOMAIN + '/v1/users/me/tasks',
      priorities: [],
      transactionTypePopUp: false,
      expenseType: { is_deduction: false },
      newGroup: true,
      rebateParents: [],
      expenseParents: [],
      stateButton: false,
      doneTasks: [],
      loadTasksError: null,
      openPopupTaskInvoice: false
    }
    this.getParents = this.getParents.bind(this)
    this.changeIndex = this.changeIndex.bind(this)
    this.handleShowForm = this.handleShowForm.bind(this)
    this.handleUnreadShowForm = this.handleUnreadShowForm.bind(this)
    this.getLabel = this.getLabel.bind(this)
    this.showCheckList = this.showCheckList.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.getProgressValue = this.getProgressValue.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.handleCreateOrUpdateTask = this.handleCreateOrUpdateTask.bind(this)
    this.getDefaultValue = this.getDefaultValue.bind(this)
    this.handleAutoComplete = this.handleAutoComplete.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.handleTaskChange = this.handleTaskChange.bind(this)
    this.addSubTask = this.addSubTask.bind(this)
    this.removeSubTask = this.removeSubTask.bind(this)
    this.handleSubTasks = this.handleSubTasks.bind(this)
    this.handleSubmitSubTasks = this.handleSubmitSubTasks.bind(this)
    this.markAsDone = this.markAsDone.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleUsersAutocomplete = this.handleUsersAutocomplete.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.testNumber = this.testNumber.bind(this)
    this.userRateChange = this.userRateChange.bind(this)
    this.discountChange = this.discountChange.bind(this)
    this.initialAmount = this.initialAmount.bind(this)
    this.handleTransactionType = this.handleTransactionType.bind(this)
    this.handleDuration = this.handleDuration.bind(this)
    this.handleUserChange = this.handleUserChange.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.handleSubmitTransaction = this.handleSubmitTransaction.bind(this)
    this.openTransactionConfirmation =
      this.openTransactionConfirmation.bind(this)
    this.clearDate = this.clearDate.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleDeleteUnreadItem = this.handleDeleteUnreadItem.bind(this)
    this.handleOpenDeleteUnreadPopup =
      this.handleOpenDeleteUnreadPopup.bind(this)
    this.handleSwitchTimeBase = this.handleSwitchTimeBase.bind(this)
    this._handleKeyPress = this._handleKeyPress.bind(this)
    this.handleChanges = this.handleChanges.bind(this)
    this.handleAdvanced = this.handleAdvanced.bind(this)
    this.handleCloseSearch = this.handleCloseSearch.bind(this)
    this.handleChangeSearch = this.handleChangeSearch.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.openCreateTransactionType = this.openCreateTransactionType.bind(this)
    this.closeTransactionType = this.closeTransactionType.bind(this)
    this.handleExpenseCheck = this.handleExpenseCheck.bind(this)
    this.handleExpenseTypeChange = this.handleExpenseTypeChange.bind(this)
    this.expenseTypeSubmit = this.expenseTypeSubmit.bind(this)
    this.togglePopupTaskInvoice = this.togglePopupTaskInvoice.bind(this)
    this.handleOpenAlert = this.handleOpenAlert.bind(this)
  }
  componentDidMount() {
    var searchParams = {}
    var searchUrl = this.state.searchUrl + this.props.location.search

    var perPage = ''
    const params = new URLSearchParams(this.props.location.search)
    params.forEach(function (value, key) {
      if (key !== 'page') {
        searchParams[key] = value.replace(/\*/g, '')
      }
      if (key === 'per_page') {
        perPage = value
      }
    })
    if (perPage) {
      this.setState({ perPage: perPage })
    }
    if (searchParams.state === '') {
      searchParams.state = 'ongoing'
      this.setState({ showState: false })
    } else {
      this.setState({ showState: true })
    }

    this.setState({ searchFormField: searchParams })
    this.loadTasks([searchUrl].join('?'), false)
  }

  handleCheck(e) {
    var isDisable
    if (e.target.checked === true) {
      isDisable = false
    } else {
      isDisable = true
      this.setState({ caseUsers: [] })
      this.setState({ legalCase: {} })
    }
    this.setState({ show: isDisable })
  }

  loadTasks(url) {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/priorities',
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        this.setState({ priorities: resp.data })
      })
      .catch((error) =>
        this.setState({ loadTasksError: error.response.status })
      )
    axios({
      method: 'get',
      url: url,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        this.setState({ tasks: resp.data.data.filter((task) => this.state.showState ? task.state === 'ongoing'  : task.state === 'done' ), openPopupSearch: false })
        this.setState({ removeAllSearchField: resp.data.data })
        this.setState({ totalPages: resp.data.meta.total_pages })
        this.setState({ currentPage: resp.data.meta.current_page })
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/members',
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        })
          .then((resp) => {
            const newData = resp.data.map((item) => ({
              uid: item.uid,
              username: item.username,
              first_name: item.first_name,
              email: item.email,
              middle_name: item.middle_name,
              last_name: item.last_name,
              rate: parseFloat(item.rate)
            }))
            this.setState({ users: newData })
            this.setState({ members: newData })
            axios({
              method: 'get',
              url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases',
              headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
            })
              .then((resp) => {
                this.setState({ cases: resp.data.data })
                axios({
                  method: 'get',
                  url:
                    process.env.REACT_APP_API_DOMAIN + '/v1/transaction_types',
                  headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
                })
                  .then((resp) => {
                    var rebateParents = []
                    var expenseParents = []
                    var newSetItem = []
                    var rebateItems = []
                    resp.data.data.map((parent) => {
                      if (
                        parent.parent_uid === null &&
                        parent.is_deduction === false
                      ) {
                        rebateParents.push(parent)
                        resp.data.data.map((child) => {
                          var newItem = {}
                          if (parent.uid === child.parent_uid) {
                            newItem['uid'] = child.uid
                            newItem['name'] = child.name
                            newItem['parent'] = parent.name
                            newItem['is_deduction'] = child.is_deduction
                            newSetItem.push(newItem)
                          }
                        })
                      }
                      if (
                        parent.parent_uid === null &&
                        parent.is_deduction === true
                      ) {
                        expenseParents.push(parent)
                        resp.data.data.map((child) => {
                          var rebateItem = {}
                          if (parent.uid === child.parent_uid) {
                            rebateItem['uid'] = child.uid
                            rebateItem['name'] = child.name
                            rebateItem['parent'] = parent.name
                            rebateItem['is_deduction'] = child.is_deduction
                            rebateItems.push(rebateItem)
                          }
                        })
                      }
                    })
                    this.setState({
                      expenseTypes: newSetItem,
                      expenseParents: expenseParents,
                      rebateParents: rebateParents
                    })
                    this.setState({ rebates: rebateItems })
                    this.setState({ load: true })
                  })
                  .catch((error) =>
                    this.setState({ loadTasksError: error.response.status })
                  )
              })
              .catch((error) =>
                this.setState({ loadTasksError: error.response.status })
              )
          })
          .catch((error) =>
            this.setState({ loadTasksError: error.response.status })
          )
      })
      .catch((error) =>
        this.setState({ loadTasksError: error.response.status })
      )
  }

  getParents(items) {
    var parents = []
    items.map((item) => {
      if (item.parent_uid === null) {
        parents.push(item)
      }
    })
    return parents
  }

  changeIndex(state) {
    this.setState({ load: false })
    this.setState({ showState: state })
    var searchParams = this.getSearchParams()
    searchParams.push(['state', state ? 'ongoing' : 'done'].join('='))
    searchParams = searchParams.join('&')
    const { history } = this.props
    const params = new URLSearchParams()
    params.append('/search', searchParams)
    history.push({ search: searchParams })
    this.loadTasks([this.state.searchUrl, searchParams].join('?'), false)
  }

  handleShowForm(task) {
    this.setState({ task: task })
    this.setState({ showPopUp: true })
  }
  handleUnreadShowForm(task) {
    this.setState({ task: task })
    axios({
      method: 'put',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/tasks/' + task.uid + '/read',
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then((resp) => {
      const newData = resp.data
      this.setState({
        unreadTasks: this.state.unreadTasks.filter(
          (unreadTask) => unreadTask.uid !== newData.uid
        )
      })
      this.setState({ tasks: [...this.state.tasks, newData] })
    })
    this.setState({ showPopUp: true })
  }

  handleSubmit() {
    var item = {}
    var userIds = []
    if (this.state.usersAutocomplete.length > 0) {
      this.state.usersAutocomplete.map((user) => {
        userIds.push(user.uid)
      })
    }
    item['uid'] = this.state.task.uid
    item['name'] = this.state.task.name
    item['description'] = this.state.task.description
    item['user_ids'] = userIds
    item['tag_list'] = this.state.task.tag_list
    item['ends_at'] = this.state.task.ends_at
    item['is_time_base'] = this.state.task.is_time_base
    item['priority_id'] = this.state.task.priority_id
    var method = ''
    var url = ''
    if (this.state.show === true) {
      if (item.uid === '') {
        method = 'post'
        url = process.env.REACT_APP_API_DOMAIN + '/v1/tasks'
      } else {
        method = 'put'
        url = process.env.REACT_APP_API_DOMAIN + '/v1/tasks/' + item.uid
      }
      axios({
        method: method,
        url: url,
        data: item,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      })
        .then((resp) => {
          const newData = resp.data
          this.setState({
            tasks: this.state.tasks.filter((task) => task.uid !== item.uid)
          })
          this.setState({ tasks: [...this.state.tasks, newData] })
          this.handleSubmitSubTasks(resp.data.uid, resp.data.user_uids)

          this.setState({
            isOpen: true,
            message: 'Submitted Successfully',
            type: 'success'
          })
          this.setState({ openTaskForm: false })
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.setState({
              error_messages: 'Name ' + error.response.data.errors.name[0]
            })
          } else {
            this.setState({
              isOpen: true,
              message:
                error.response.status.toString() +
                ' Unexpected Error Problem Occurred',
              type: 'error'
            })
            this.setState({ openTaskForm: false })
          }
        })
    } else {
      if (
        this.state.defaultCase.uid !== undefined &&
        this.state.legalCase.uid !== undefined
      ) {
        if (this.state.defaultCase.uid === this.state.legalCase.uid) {
          if (item.uid === '') {
            method = 'post'
            url =
              process.env.REACT_APP_API_DOMAIN +
              '/v1/legal_cases/' +
              this.state.defaultCase.uid +
              '/tasks/'
          } else {
            method = 'put'
            url = process.env.REACT_APP_API_DOMAIN + '/v1/tasks/' + item.uid
          }
          axios({
            method: method,
            url: url,
            data: item,
            headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
          })
            .then((resp) => {
              const newData = resp.data
              this.setState({
                tasks: this.state.tasks.filter(
                  (tasks) => tasks.uid !== item.uid
                )
              })
              this.setState({ tasks: [...this.state.tasks, newData] })
              this.handleSubmitSubTasks(resp.data.uid, resp.data.user_uids)

              this.setState({
                isOpen: true,
                message: 'Submitted Successfully',
                type: 'success'
              })
              this.setState({ openTaskForm: false })
            })
            .catch((resp) => {
              console.log(resp)
            })
        } else {
          axios({
            method: 'delete',
            url:
              process.env.REACT_APP_API_DOMAIN +
              '/v1/legal_cases/' +
              this.state.defaultCase.uid +
              '/tasks/' +
              item.uid,
            headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
          })
            .then(() => {
              this.setState({
                tasks: this.state.tasks.filter(
                  (tasks) => tasks.uid !== item.uid
                )
              })
              axios({
                method: 'post',
                url:
                  process.env.REACT_APP_API_DOMAIN +
                  '/v1/legal_cases/' +
                  this.state.legalCase.uid +
                  '/tasks/',
                data: item,
                headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
              })
                .then((resp) => {
                  const newData = resp.data
                  this.setState({
                    tasks: this.state.tasks.filter(
                      (tasks) => tasks.uid !== item.uid
                    )
                  })
                  this.setState({ tasks: [...this.state.tasks, newData] })
                  this.handleSubmitSubTasks(resp.data.uid, resp.data.user_uids)

                  this.setState({
                    isOpen: true,
                    message: 'Submitted Successfully',
                    type: 'success'
                  })
                  this.setState({ openTaskForm: false })
                })
                .catch((resp) => {
                  console.log(resp)
                })
              this.setState({ openTaskForm: false })
            })
            .catch((resp) => {
              console.log(resp)
            })
        }
      } else if (
        this.state.defaultCase.uid === undefined &&
        this.state.legalCase.uid !== undefined
      ) {
        axios({
          method: 'post',
          url:
            process.env.REACT_APP_API_DOMAIN +
            '/v1/legal_cases/' +
            this.state.legalCase.uid +
            '/tasks/',
          data: item,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        })
          .then((resp) => {
            const newData = resp.data
            this.setState({
              tasks: this.state.tasks.filter(
                (unreadTask) => unreadTask.uid !== item.uid
              )
            })
            this.setState({ tasks: [...this.state.tasks, newData] })
            this.handleSubmitSubTasks(resp.data.uid, resp.data.user_uids)

            this.setState({
              isOpen: true,
              message: 'Submitted Successfully',
              type: 'success'
            })
            this.setState({ openTaskForm: false })
          })
          .catch((resp) => {
            console.log(resp)
          })
      } else if (
        this.state.defaultCase.uid !== undefined &&
        this.state.legalCase.uid === undefined
      ) {
        if (item.uid === '') {
          method = 'post'
          url =
            process.env.REACT_APP_API_DOMAIN +
            '/v1/legal_cases/' +
            this.state.defaultCase.uid +
            '/tasks/'
        } else {
          method = 'put'
          url = process.env.REACT_APP_API_DOMAIN + '/v1/tasks/' + item.uid
        }
        axios({
          method: method,
          url: url,
          data: item,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        })
          .then((resp) => {
            const newData = resp.data
            this.setState({
              tasks: this.state.tasks.filter(
                (unreadTask) => unreadTask.uid !== item.uid
              )
            })
            this.setState({ tasks: [...this.state.tasks, newData] })
            this.handleSubmitSubTasks(resp.data.uid, resp.data.user_uids)

            this.setState({
              isOpen: true,
              message: 'Submitted Successfully',
              type: 'success'
            })
            this.setState({ openTaskForm: false })
          })
          .catch((resp) => {
            console.log(resp)
          })
      } else {
        if (item.uid === '') {
          method = 'post'
          url =
            process.env.REACT_APP_API_DOMAIN +
            '/v1/legal_cases/' +
            this.state.defaultCase.uid +
            '/tasks/'
        } else {
          method = 'put'
          url = process.env.REACT_APP_API_DOMAIN + '/v1/tasks/' + item.uid
        }
        axios({
          method: method,
          url: url,
          data: item,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        })
          .then((resp) => {
            const newData = resp.data
            this.setState({
              tasks: this.state.tasks.filter((task) => task.uid !== item.uid)
            })
            this.setState({ tasks: [...this.state.tasks, newData] })
            this.handleSubmitSubTasks(resp.data.uid, resp.data.user_uids)

            this.setState({
              isOpen: true,
              message: 'Submitted Successfully',
              type: 'success'
            })
            this.setState({ openTaskForm: false })
          })
          .catch((resp) => {
            console.log(resp)
          })
      }
    }
    this.setState({ caseUsers: [] })
    this.setState({ legalCase: {} })
    this.setState({ usersAutocomplete: [] })
  }

  handleSubmitSubTasks(id, users) {
    if (this.state.subTasks.length > 0) {
      this.state.subTasks.map((subTask) => {
        var item = {}
        var method = ''
        var url = ''
        item['uid'] = subTask.uid
        item['name'] = subTask.name
        item['description'] = subTask.description
        item['user_ids'] = users
        item['tag_list'] = subTask.tag_list
        item['ends_at'] = subTask.ends_at
        item['is_time_base'] = subTask.is_time_base
        item['parent_id'] = id
        if (item.uid === '') {
          method = 'post'
          url = process.env.REACT_APP_API_DOMAIN + '/v1/tasks/'
        } else {
          method = 'put'
          url = process.env.REACT_APP_API_DOMAIN + '/v1/tasks/' + item.uid
        }
        axios({
          method: method,
          url: url,
          data: item,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        })
          .then((resp) => {
            const newData = resp.data
            this.setState({
              tasks: this.state.tasks.filter(
                (unreadTask) => unreadTask.uid !== item.uid
              )
            })
            this.setState({ tasks: [...this.state.tasks, newData] })
            this.setState({
              isOpen: true,
              message: 'Submitted Successfully',
              type: 'success'
            })
          })
          .catch((error) => console.log(error.response))
      })
    }
  }

  getLabel(id, show) {
    var totalCount = 0
    var doneCount = 0
    const allTasks = this.state.tasks
    if (show) {
      allTasks.map((task) => {
        if (task.parent_uid === id) {
          totalCount = totalCount + 1
          if (task.state === 'done') {
            doneCount = doneCount + 1
          }
        }
      })
      if (totalCount < 1) {
        totalCount = totalCount + 1
      }
      return doneCount.toString() + '/' + totalCount.toString()
    } else {
      allTasks.map((task) => {
        if (task.parent_uid === id) {
          totalCount = totalCount + 1
          if (task.state === 'done') {
            doneCount = doneCount + 1
          }
        }
      })
      if (totalCount === 0) {
        return 1
      }

      return <b> {totalCount.toString()} </b>
    }
  }

  showCheckList(doneTask) {
    this.setState({ doneTask: doneTask })
    var children = []
    var checked = []
    var allTasks = this.state.tasks
    allTasks.map((task) => {
      if (task.parent_uid === doneTask.uid) {
        children.push(task)
        if (task.state === 'done') {
          checked.push(task.uid)
        }
      }
    })

    this.setState({ subs: children })
    this.setState({ checkedItems: checked })
    this.setState({ openChecklist: true })
  }

  markAsDone() {
    var status = ''
    if (this.state.doneTask.state === 'ongoing') {
      status = '/done'
    } else {
      status = '/undone'
    }
    axios({
      method: 'put',
      url:
        process.env.REACT_APP_API_DOMAIN +
        '/v1/tasks/' +
        this.state.doneTask.uid +
        status,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then((resp) => {
      const tasks = this.state.tasks.filter(
        (task) => task.uid !== this.state.doneTask.uid
      )
      this.setState({
        tasks: tasks
      })
      if (resp.data.state === 'done') {
        if (resp.data.legal_case !== undefined) {
          this.setState({ openTransactionConfirmation: true })
        }
      }
      var item = {}
      item['uid'] = ''
      item['rate'] = 0
      item['initialAmount'] = 0
      item['is_deduction'] = false
      item['discount'] = 0
      item['name'] = this.state.doneTask.name
      item['user_id'] = ''
      item['amount'] = 0
      item['remarks'] = ''
      item['duration'] = 0
      item['filed_at'] = new Date().toLocaleDateString('fr-CA')

      if (this.state.doneTask.is_time_base === true) {
        item['rate_type'] = 'hourly'
      } else {
        item['rate_type'] = 'fixed'
      }
      item['transaction_type_id'] = ''
      item['task_id'] = this.state.doneTask.uid
      this.setState({ transaction: item })

      this.setState({ openChecklist: false })
    })
  }

  handleClose() {
    this.setState({ openTransactionForm: false })
    this.setState({ openChecklist: false })
    this.setState({ showPopUp: false })
    this.setState({ subTasks: [] })

    this.setState({ show: true })
    this.setState({ openTaskForm: false })
    this.setState({ openDeletePopup: false })
    this.setState({ caseUsers: [] })
    this.setState({ openTransactionConfirmation: false })
    this.setState({ error_messages: '' })
    this.setState({ legalCase: {} })
    this.setState({ usersAutocomplete: [] })
    this.setState({ openDeleteUnreadPopup: false })
  }
  getProgressValue() {
    var doneValue = 0
    if (this.state.checkedItems.length === this.state.subs.length) {
      doneValue = 100
    } else {
      doneValue =
        (this.state.checkedItems.length / this.state.subs.length) * 100
    }

    return doneValue
  }

  handleToggle(id) {
    var currentIndex = this.state.checkedItems.indexOf(id)
    this.setState({
      tasks: this.state.tasks.filter((unreadTask) => unreadTask.uid !== id)
    })
    if (currentIndex === -1) {
      axios({
        method: 'put',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/tasks/' + id + '/done',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        const newData = resp.data
        this.setState({
          checkedItems: this.state.checkedItems.filter(
            (checkedItem) => checkedItem !== newData.uid
          )
        })
        this.setState({
          checkedItems: [...this.state.checkedItems, newData.uid]
        })

        this.setState({ tasks: [...this.state.tasks, newData] })
      })
    } else {
      axios({
        method: 'put',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/tasks/' + id + '/undone',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        const newData = resp.data
        this.setState({
          checkedItems: this.state.checkedItems.filter(
            (checkedItem) => checkedItem !== newData.uid
          )
        })

        this.setState({ tasks: [...this.state.tasks, newData] })
      })
    }
  }

  handleCreateOrUpdateTask(item) {
    var textTitle = ''
    var taskUsers = []
    var newItem = {
      uid: item.uid,
      can_delete: item.can_delete,
      can_edit: item.can_edit,
      can_done: item.can_done,
      can_read: item.can_read,
      can_undone: item.can_undone,
      legal_case: item.legal_case,
      parent_uid: item.parent_uid,
      name: item.name,
      description: item.description,
      tag_list: item.tag_list,
      state: item.state,
      from_workflow: item.from_workflow,
      created_at: item.created_at,
      updated_at: item.updated_at,
      is_time_base: item.is_time_base,
      priority_id: item.priority_uid
    }

    if (item.uid === '') {
      textTitle = ' New Task '
      newItem['ends_at'] = moment().format('YYYY-MM-DD')
      newItem['user_ids'] = [localStorage.getItem('current_user_id')]
      taskUsers = []
    } else {
      newItem['ends_at'] = item.ends_at
        ? moment(item.ends_at).format('YYYY-MM-DD')
        : null
      newItem['user_ids'] = item.user_uids
      textTitle = ' Update Task '
    }
    if (this.state.users.length > 0) {
      this.state.users.map((user) => {
        if (item.user_uids !== null) {
          item.user_uids.map((userId) => {
            if (user.uid === userId) {
              taskUsers.push(user)
            }
          })
        }
      })
    }

    this.setState({ usersAutocomplete: taskUsers })
    if (item.legal_case !== undefined) {
      this.setState({ defaultCase: item.legal_case })
      this.setState({ legalCase: item.legal_case })
      this.setState({ show: false })
      var users = []
      if (this.state.users.length > 0) {
        this.state.users.map((user) => {
          item.legal_case.user_uids.map((userId) => {
            if (user.uid === userId) {
              users.push(user)
            }
          })
        })
      }
      this.setState({ caseUsers: users })
    }

    if (item.uid !== '') {
      const allTasks = [...this.state.tasks]
      var subs = []
      if (allTasks.length > 0) {
        allTasks.map((task) => {
          if (task.parent_uid === item.uid) {
            subs.push(task)
          }
        })
      }
      this.setState({ subTasks: subs })
    }
    this.setState({ title: textTitle })
    this.setState({ checkedUsers: newItem.user_ids })
    this.setState({ task: newItem })
    this.setState({ openTaskForm: true })
  }

  getDefaultValue() {
    var newArray = []
    if (this.state.users.length > 0) {
      if (this.state.checkedUsers !== null) {
        if (this.state.checkedUsers.length > 0) {
          this.state.users.map((user) => {
            this.state.checkedUsers.map((current) => {
              if (current === user.uid) {
                newArray.push(user)
              }
            })
          })
        }
      } else {
        this.state.users.map((user) => {
          if (user.uid === localStorage.getItem('current_user_id')) {
            newArray.push(user)
            this.setState({
              task: {
                ...this.state.task,
                user_ids: [localStorage.getItem('current_user_id')]
              }
            })
          }
        })
      }
    }
    return newArray
  }
  handleAutoComplete(event, values) {
    if (values !== null) {
      this.setState({ legalCase: values })
      var users = []
      var initialUser = []
      var isUserInCase = 0
      if (this.state.users.length > 0) {
        this.state.users.map((user) => {
          if (values.user_uids !== undefined) {
            values.user_uids.map((userId) => {
              if (user.uid === userId) {
                users.push(user)
                if (user.uid === localStorage.getItem('current_user_id')) {
                  isUserInCase = isUserInCase + 1
                  initialUser = [user]
                }
              }
            })
          }
        })
      }
      this.setState({ caseUsers: users })
      if (isUserInCase === 0) {
        initialUser = []
      }
      this.setState({ usersAutocomplete: initialUser })
    }
  }

  handleUsersAutocomplete(event, values) {
    if (values !== null) {
      this.setState({ usersAutocomplete: values })
    }
  }

  handleTaskChange(e) {
    this.setState({
      task: {
        ...this.state.task,
        [e.target.name]: e.target.value
      }
    })
  }

  removeSubTask(idx) {
    if (this.state.subTasks[idx].uid !== '') {
      const DeleteItemId = this.state.subTasks[idx].uid
      axios({
        method: 'delete',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/tasks/' + DeleteItemId,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      })
        .then(() => {})
        .catch((error) => console.log(error.response))
    }
    this.state.subTasks.splice(idx, 1)

    this.setState({ subTasks: [...this.state.subTasks] })
  }

  handleSubTasks(e) {
    const updatedSubTasks = [...this.state.subTasks]
    updatedSubTasks[e.target.dataset.id][e.target.dataset.fieldType] =
      e.target.value
    this.setState({ subTasks: updatedSubTasks })
  }

  addSubTask() {
    this.setState({
      subTasks: [...this.state.subTasks, { ...this.state.initialTask }]
    })
  }

  handleOpenDeletePopup(item, model) {
    if (item.uid !== '') {
      const allTasks = this.state.tasks
      var subs = []
      if (allTasks.length > 0) {
        allTasks.map((task) => {
          if (task.parent_uid === item.uid) {
            subs.push(task)
          }
        })
      }
      this.setState({ subTasks: subs })
    }
    this.setState({ model: model })
    this.setState({ task: item })
    this.setState({ openDeletePopup: true })
  }

  handleOpenDeleteUnreadPopup(item, model) {
    if (item.uid !== '') {
      const allTasks = this.state.tasks
      var subs = []
      if (allTasks.length > 0) {
        allTasks.map((task) => {
          if (task.parent_uid === item.uid) {
            subs.push(task)
          }
        })
      }
      this.setState({ subTasks: subs })
    }
    this.setState({ model: model })
    this.setState({ task: item })
    this.setState({ openDeleteUnreadPopup: true })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.task.uid
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/tasks/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then(() => {
        this.setState({
          tasks: this.state.tasks.filter((item) => item.uid !== DeleteItemId)
        })
        this.setState({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'error'
        })

        this.setState({ openDeletePopup: false })
      })
      .catch((error) => console.log(error.response))
  }

  handleDeleteUnreadItem() {
    const DeleteItemId = this.state.task.uid
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/tasks/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then(() => {
        this.setState({
          unreadTasks: this.state.unreadTasks.filter(
            (item) => item.uid !== DeleteItemId
          )
        })

        this.setState({ openDeleteUnreadPopup: false })
      })
      .catch((error) => console.log(error.response))
  }

  handleChange(e) {
    this.setState({
      task: {
        ...this.state.task,
        ends_at: e.target.value
      }
    })
  }

  handleInputChange(e) {
    var value = null

    if (e.target.name === 'amount') {
      value = e.target.value * 1
    } else if (e.target.name === 'duration') {
      value = e.target.value * 1
    } else {
      value = e.target.value
    }

    this.setState({
      transaction: {
        ...this.state.transaction,
        [e.target.name]: value
      }
    })
  }

  testNumber(e) {
    this.setState({
      transaction: {
        ...this.state.transaction,
        amount: e.floatValue
      }
    })
  }

  initialAmount(e) {
    this.setState({
      transaction: {
        ...this.state.transaction,
        initialAmount: e.floatValue,
        amount: e.floatValue
      }
    })
  }

  userRateChange(e) {
    this.setState({
      transaction: {
        ...this.state.transaction,
        rate: e.floatValue,
        amount:
          e.floatValue *
          this.state.transaction.duration *
          (1 - this.state.transaction.discount / 100)
      }
    })
  }

  discountChange(e) {
    if (e.floatValue !== undefined) {
      if (this.state.transaction.rate_type === 'hourly') {
        this.setState({
          transaction: {
            ...this.state.transaction,
            discount: e.floatValue,
            amount:
              this.state.transaction.rate *
              this.state.transaction.duration *
              (1 - e.floatValue / 100)
          }
        })
      } else {
        this.setState({
          transaction: {
            ...this.state.transaction,
            discount: e.floatValue,
            amount:
              this.state.transaction.initialAmount * (1 - e.floatValue / 100)
          }
        })
      }
    } else {
      this.setState({
        transaction: {
          ...this.state.transaction,
          discount: 0,
          amount: this.state.transaction.rate * this.state.transaction.duration
        }
      })
    }
  }

  handleTransactionType(event, values) {
    this.setState({ defaultTransactionType: values })
    if (values !== null) {
      this.setState({
        transaction: {
          ...this.state.transaction,
          transaction_type_id: values.uid
        }
      })
    }
  }

  handleDuration(e) {
    var amt = 0
    amt =
      parseFloat(e.target.value) *
      this.state.transaction.rate *
      (1 - this.state.transaction.discount / 100)

    this.setState({
      transaction: {
        ...this.state.transaction,
        [e.target.name]: e.target.value,
        amount: amt
      }
    })
  }
  handleUserChange(e) {
    var userRate = 0
    if (this.state.members.length > 0) {
      this.state.members.map((user) => {
        if (user.uid === e.target.value) {
          userRate = user.rate
        }
      })
    }

    this.setState({
      transaction: {
        ...this.state.transaction,
        [e.target.name]: e.target.value,
        rate: userRate
      }
    })
  }

  handleSwitch(e) {
    if (e.target.checked === true) {
      this.setState({
        transaction: {
          ...this.state.transaction,
          is_deduction: e.target.checked,
          discount: 0,
          amount: this.state.transaction.rate * this.state.transaction.duration
        }
      })
    } else {
      this.setState({
        transaction: {
          ...this.state.transaction,
          is_deduction: e.target.checked
        }
      })
    }
  }

  openTransactionConfirmation() {
    this.setState({ openTransactionConfirmation: true })
  }

  handleSubmitTransaction() {
    const item = {}
    item['uid'] = this.state.transaction.uid
    item['name'] = this.state.transaction.name
    item['user_id'] = this.state.transaction.user_id
    item['rate_type'] = this.state.transaction.rate_type
    item['remarks'] = this.state.transaction.remarks
    item['filed_at'] = this.state.transaction.filed_at
    item['transaction_type_id'] = this.state.transaction.transaction_type_id
    item['amount'] = this.state.transaction.amount * 1
    item['task_id'] = this.state.transaction.task_id

    if (this.state.transaction.is_deduction === true) {
      item['discount'] = 0
    } else {
      if (
        this.state.transaction.discount !== undefined &&
        this.state.transaction.discount > 0
      ) {
        item['discount'] = parseFloat(
          this.state.transaction.amount *
            (this.state.transaction.discount / 100)
        )
      }
    }
    if (this.state.transaction.rate_type === 'fixed') {
      item['duration'] = 0
    } else {
      item['duration'] = this.state.transaction.duration * 1
    }
    var method = ''
    var url = ''
    if (item.uid === '') {
      method = 'post'
      url =
        process.env.REACT_APP_API_DOMAIN +
        '/v1/legal_cases/' +
        this.state.doneTask.legal_case.uid +
        '/transactions'
    } else {
      method = 'put'
      url =
        process.env.REACT_APP_API_DOMAIN +
        '/v1/legal_cases/' +
        this.state.doneTask.legal_case.uid +
        '/transactions/' +
        item.uid
    }
    axios({
      method: method,
      url: url,
      data: item,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then(() => {
        this.setState({ openTransactionForm: false })
        this.setState({ openTransactionConfirmation: false })
      })
      .catch((error) => console.log(error.response))
  }

  clearDate() {
    this.setState({
      task: {
        ...this.state.task,
        ends_at: ''
      }
    })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    this.setState({
      isOpen: false
    })
  }
  handleSwitchTimeBase(e) {
    this.setState({
      task: {
        ...this.state.task,
        is_time_base: e.target.checked
      }
    })
  }

  _handleKeyPress(e) {
    if (e.key === 'Enter') {
      if (this.props.location.search === '') {
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/users/me/tasks',
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then((resp) => {
          this.setState({ tasks: resp.data.data })
        })
      } else {
        axios({
          method: 'get',
          url:
            process.env.REACT_APP_API_DOMAIN +
            '/v1/users/me/tasks' +
            '?q=' +
            this.state.q,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then((resp) => {
          this.setState({ tasks: resp.data.data })
        })
      }
    }
    const { history } = this.props
    const params = new URLSearchParams()
    if (this.state.q !== '') {
      params.append('q', this.state.q)
      history.push({ search: params.toString() })
    } else {
      history.push({ search: '' })
    }
  }

  handleChanges(event) {
    this.setState({ q: event.target.value })
  }

  handleAdvanced() {
    this.setState({ openPopupSearch: true })
  }

  handleCloseSearch() {
    this.setState({ openPopupSearch: false })
  }

  handleChangeSearch(e) {
    this.setState({
      searchFormField: {
        ...this.state.searchFormField,
        [e.target.name]: e.target.value
      }
    })
  }
  handlePageChange(event, value) {
    var searchParams = this.getSearchParams()
    searchParams.push(['page', value].join('='))
    searchParams = searchParams.join('&')
    const { history } = this.props
    const params = new URLSearchParams()
    params.append('/search', searchParams)
    history.push({ search: searchParams })
    this.setState({ currentPage: value })
    this.loadTasks([this.state.searchUrl, searchParams].join('?'), false)
  }

  getSearchParams() {
    var searchParams = []
    Object.entries(this.state.searchFormField).map(([key, value]) => {
      if (
        key !== 'state' &&
        value !== undefined &&
        value !== null &&
        value !== '' &&
        key !== 'group'
      ) {
        searchParams.push([key, value].join('='))
      }
    })

    return searchParams
  }

  handleSearch(e) {
    e.preventDefault()
    const { history } = this.props
    var searchParams = this.getSearchParams().join('&')
    var val = ''
    if (this.state.showState === true) {
      val = 'done'
    } else {
      val = 'ongoing'
    }
    const params = new URLSearchParams()
    params.append('/search', searchParams)
    history.push({ search: searchParams })
    this.loadTasks(
      [this.state.searchUrl, searchParams, '&state=' + val].join('?'),
      false
    )
  }

  clearSearch() {
    const { history } = this.props

    history.push({ search: '' })
    this.loadTasks([this.state.searchUrl].join('?'), false)
    this.setState({ q: '' })
    this.setState({
      searchFormField: { name: '', state: '', due_on_from: '', due_on_to: '' }
    })
  }

  openCreateTransactionType() {
    this.setState({ transactionTypePopUp: true })
  }

  closeTransactionType() {
    this.setState({ transactionTypePopUp: false, defaultTransactionType: {} })
  }

  handleExpenseCheck(e) {
    this.setState({
      expenseType: {
        ...this.state.expenseType,
        parent_name: '',
        parent_uid: ''
      }
    })
    this.setState({ newGroup: e.target.checked })
  }

  handleExpenseTypeChange(e) {
    this.setState({
      expenseType: {
        ...this.state.expenseType,
        [e.target.name]: e.target.value
      }
    })
  }

  expenseTypeSubmit() {
    var method = 'post'
    var url = process.env.REACT_APP_API_DOMAIN + '/v1/transaction_types'
    if (this.state.newGroup) {
      // console.log(item)
      console.log(this.state.expenseType)
      // const item = {}
      // const subItem = {}
      // item["name"] = this.state.expenseType.parent_name
      // subItem["name"] = this.state.expenseType.name
      // subItem["is_deduction"] = this.state.expenseType.is_deduction
      // item["is_deduction"] = this.state.expenseType.is_deduction

      // axios({
      // 	method: method,
      // 	url: url,
      // 	data: (item),
      // 	headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      // }).then(resp => {
      // 	const newData = resp.data
      // 	if (item.is_deduction) {
      // 		this.setState({ rebateParents: [...this.state.rebateParents, newData] })

      // 	} else {
      // 		this.setState({ expenseParents: [...this.state.expenseParents, newData] })
      // 	}
      // 	subItem["parent_id"] = newData.uid
      // 	axios({
      // 		method: method,
      // 		url: url,
      // 		data: (subItem),
      // 		headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      // 	}).then(resp => {
      // 		const subData = resp.data
      // 		var transactionItem = {}
      // 		transactionItem["name"] = subData.name
      // 		transactionItem["parent"] = item.name
      // 		transactionItem["is_deduction"] = subData.is_deduction
      // 		transactionItem["uid"] = subData.uid
      // 		transactionItem['transactionParent'] = item.name

      // 		this.setState({ defaultTransactionType: transactionItem })
      // 		if (item.is_deduction) {
      // 			this.setState({ rebates: [...this.state.rebates, transactionItem] })

      // 		} else {
      // 			this.setState({ expenseTypes: [...this.state.expenseTypes, transactionItem] })
      // 		}
      // 		this.closeTransactionType()
      // 	})
      // })
    } else {
      const newItem = {}
      newItem['name'] = this.state.expenseType.name
      newItem['parent_id'] = this.state.expenseType.parent_uid
        ? this.state.expenseType.parent_uid
        : ''
      newItem['is_deduction'] = this.state.expenseType.is_deduction
      axios({
        method: method,
        url: url,
        data: newItem,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        const subData = resp.data
        var transactionItem = {}
        transactionItem['name'] = subData.name
        transactionItem['parent'] = [
          ...this.state.expenseParents,
          ...this.state.rebateParents
        ].filter((item) => item.uid === subData.parent_uid)[0].name
        transactionItem['is_deduction'] = subData.is_deduction
        transactionItem['uid'] = subData.uid
        transactionItem['transactionParent'] = [
          ...this.state.expenseParents,
          ...this.state.rebateParents
        ].filter((item) => item.uid === subData.parent_uid)[0].name

        this.setState({ defaultTransactionType: transactionItem })
        if (newItem.is_deduction) {
          this.setState({ rebates: [...this.state.rebates, transactionItem] })
        } else {
          this.setState({
            expenseTypes: [...this.state.expenseTypes, transactionItem]
          })
        }
        this.closeTransactionType()
      })
    }
  }

  togglePopupTaskInvoice() {
    this.setState((prevState) => ({
      openPopupTaskInvoice: !prevState.openPopupTaskInvoice
    }))
  }

  handleOpenAlert(type, message) {
    this.setState({
      isOpen: true,
      type,
      message
    })
  }

  render() {
    const { load, loadTasksError, openPopupTaskInvoice } = this.state

    return (
      <>
        {load && !loadTasksError ? (
          <>
          <Box className={styles.boxMain}>
            <TasksTable
              headers={this.state.headers}
              unreadItems={[]}
              items={this.getParents(this.state.tasks)}
              model={'Task'}
              handleShowForm={this.handleShowForm}
              handleUnreadShowForm={this.handleUnreadShowForm}
              getLabel={this.getLabel}
              showCheckList={this.showCheckList}
              handleCreateOrUpdateTask={this.handleCreateOrUpdateTask}
              onOpenDeletePopup={this.handleOpenDeletePopup}
              onOpenDeleteUnreadPopup={this.handleOpenDeleteUnreadPopup}
              users={this.state.users}
              withStateButton={true}
              changeIndex={this.changeIndex}
              initialTask={this.state.initialTask}
              showState={this.state.showState}
              withCaseLink={true}
              dateToday={this.state.dateToday}
              q={this.state.q}
              clearSearch={this.clearSearch}
              withSearch={this.state.withSearch}
              _handleKeyPress={this._handleKeyPress}
              handleChanges={this.handleChanges}
              handleAdvanced={this.handleAdvanced}
              withPagination={false}
              totalPages={this.state.totalPages}
              currentPage={this.state.currentPage}
              handlePageChange={this.handlePageChange}
              priorities={this.state.priorities}
              stateButton={this.state.stateButton}
              togglePopupTaskInvoice={this.togglePopupTaskInvoice}
            />
          </Box>

            <SimplePopUp
              openPopup={this.state.openChecklist}
              title="Checklist"
              handleClose={this.handleClose}
              maxWidth="sm"
            >
              <Checklist
                taskChildren={this.state.subs}
                checkedItems={this.state.checkedItems}
                getProgressValue={this.getProgressValue}
                handleToggle={this.handleToggle}
                markAsDone={this.markAsDone}
                doneTask={this.state.doneTask}
              />
            </SimplePopUp>

            <SimplePopUp
              openPopup={this.state.openTransactionConfirmation}
              title="Create Transaction"
              handleClose={this.handleClose}
              maxWidth="sm"
            >
              <>
                <Typography
                  variant="h4"
                  style={{ textTransform: 'capitalize' }}
                >
                  {this.state.doneTask.name}
                </Typography>
                <Typography>
                  Do you want to create a transaction for this task?
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    float: 'right',
                    justifyContent: 'space-between'
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ borderRadius: '50px', marginRight: '10px' }}
                    onClick={() => this.setState({ openTransactionForm: true })}
                  >
                    Yes
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{ borderRadius: '50px' }}
                    onClick={() => {
                      this.setState({ openTransactionConfirmation: false })
                    }}
                  >
                    No
                  </Button>
                </div>
              </>
            </SimplePopUp>

            <SimplePopUp
              openPopup={this.state.showPopUp}
              title="Task Information"
              handleClose={this.handleClose}
              maxWidth="sm"
            >
              <Task
                task={this.state.task}
                users={this.state.users}
                handleCreateOrUpdateTask={this.handleCreateOrUpdateTask}
                handleClose={this.handleClose}
                priorities={this.state.priorities}
              />
            </SimplePopUp>

            <SimplePopUp
              openPopup={this.state.openTaskForm}
              handleClose={this.handleClose}
              maxWidth="md"
              title={this.state.title}
            >
              <TaskForm
                task={this.state.task}
                legalCase={this.state.legalCase}
                defaultCase={this.state.defaultCase}
                cases={this.state.cases}
                users={this.state.users}
                getDefaultValue={this.getDefaultValue}
                error={this.state.error_messages}
                handleAutoComplete={this.handleAutoComplete}
                submit={this.handleSubmit}
                handleCheck={this.handleCheck}
                show={this.state.show}
                subTasks={this.state.subTasks}
                initialTask={this.state.initialTask}
                handleTaskChange={this.handleTaskChange}
                addSubTask={this.addSubTask}
                removeSubTask={this.removeSubTask}
                handleSubTasks={this.handleSubTasks}
                handleChange={this.handleChange}
                caseUsers={this.state.caseUsers}
                handleUsersAutocomplete={this.handleUsersAutocomplete}
                selectedValues={this.state.usersAutocomplete}
                withCaseLink={true}
                clearDate={this.clearDate}
                handleSwitch={this.handleSwitchTimeBase}
                priorities={this.state.priorities}
              />
            </SimplePopUp>

            <SimpleDeletePopUp
              openDeletePopup={this.state.openDeletePopup}
              item={this.state.task}
              delete={this.handleDeleteItem}
              handleDeleteClose={this.handleClose}
              model="Task"
            />

            <SimplePopUp
              openPopup={this.state.openPopupSearch}
              handleClose={this.handleCloseSearch}
              title="Advanced Search"
            >
              <SearchForm
                handleSearch={this.handleSearch}
                handleFormReset={this.handleFormReset}
                handleChangeSearch={this.handleChangeSearch}
                item={this.state.searchFormField}
              />
            </SimplePopUp>

            <SimpleDeletePopUp
              openDeletePopup={this.state.openDeleteUnreadPopup}
              item={this.state.task}
              delete={this.handleDeleteUnreadItem}
              handleDeleteClose={this.handleClose}
              model="Task"
            />

            <SimplePopUp
              openPopup={this.state.openTransactionForm}
              maxWidth="md"
              title="Create Transaction"
              handleClose={this.handleClose}
            >
              <>
                <TransactionForm
                  transaction={this.state.transaction}
                  handleSubmit={this.handleSubmitTransaction}
                  handleInputChange={this.handleInputChange}
                  testNumber={this.testNumber}
                  users={
                    this.state.doneTask ? this.state.doneTask.user_uids : []
                  }
                  members={this.state.users}
                  expenseTypes={this.state.expenseTypes}
                  handleSwitch={this.handleSwitch}
                  handleUserChange={this.handleUserChange}
                  handleDuration={this.handleDuration}
                  rebates={this.state.rebates}
                  handleTransactionType={this.handleTransactionType}
                  defaultTransactionType={this.state.defaultTransactionType}
                  userRateChange={this.userRateChange}
                  discountChange={this.discountChange}
                  initialAmount={this.initialAmount}
                  openCreateTransactionType={this.openCreateTransactionType}
                />
              </>
            </SimplePopUp>

            <SimplePopUp
              openPopup={this.state.transactionTypePopUp}
              maxWidth="xs"
              title="New Transaction Type"
              handleClose={this.closeTransactionType}
            >
              <>
                <ExpenseTypeForm
                  item={this.state.expenseType}
                  newGroup={this.state.newGroup}
                  items={[
                    ...this.state.rebateParents,
                    ...this.state.expenseParents
                  ]}
                  handleCheck={this.handleExpenseCheck}
                  onchange={this.handleExpenseTypeChange}
                  submit={this.expenseTypeSubmit}
                />
              </>
            </SimplePopUp>

            <SimplePopUp
              openPopup={openPopupTaskInvoice}
              maxWidth="lg"
              title="Create Invoice"
              handleClose={this.togglePopupTaskInvoice}
            >
              <TaskInvoice 
                users={this.state.users}
                togglePopupTaskInvoice={this.togglePopupTaskInvoice}
                handleOpenAlert={this.handleOpenAlert}
              />
            </SimplePopUp>

            <AlertMessage
              notify={this.state.notify}
              handleCloseAlert={this.handleCloseAlert}
              isOpen={this.state.isOpen}
              type={this.state.type}
              message={this.state.message}
            />
          </>
        ) : loadTasksError ? (
          <ErrorPage type={loadTasksError} />
        ) : (
          <ProgressBar />
        )}
      </>
    )
  }
}
export default withRouter(Tasks)

Tasks.propTypes = {
  history: PropTypes.string,
  location: PropTypes.string,
  countTasks: PropTypes.func
}
