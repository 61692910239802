import { DataGrid } from '@material-ui/data-grid'
import React, { useState, useEffect } from 'react'
import axios from 'axios'

const DataGridDemo = () => {
  const [rows, setRows] = useState([])

  const columns = [
    { field: 'id', headerName: 'id', headerAlign: 'center', hide: true },
    { field: 'name', headerName: 'Name', headerAlign: 'center', width: 170 },
    { field: 'created_at', headerName: 'Date Created', headerAlign: 'center', width: 170 },
    { field: 'updated_at', headerName: 'Date Updated', headerAlign: 'center', width: 170 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 300,
      headerAlign: 'center'
    }
  ]
  useEffect(() => {
    axios.get(process.env.REACT_APP_API_DOMAIN + '/v1/practice_areas')
      .then(response => {
        const data = response.data
        const lists = data.map(element => {
          return {
            id: element.uid,
            name: element.name,
            created_at: element.created_at,
            updated_at: element.updated_at

          }
        })
        setRows(lists)
      })
  }, [])
  return (
    <div style={{ height: 400, width: '100%' }}>
      <h2>Practice Areas</h2>
      <DataGrid rows={rows} columns={columns} pageSize={5} />
    </div>
  )
}
export default DataGridDemo
