import React, { Component } from "react";
import { Container, Typography } from "@material-ui/core";
import styles from "./Maintenance.module.css";
import MaintenanceImg from "../../assets/images/maintenance.svg";

class Maintenance extends Component {
  render() {
    return (
      <Container className={styles.mainContainer}>
        <Container className={styles.mainContentContainer}>
          <Typography variant="h1" className={styles.maintenanceTitle}>
            Page under maintenance
          </Typography>
          <div>
            <Typography variant="body1" className={styles.maintenanceSubtitle}>
              Sorry, this page is inaccessible right now.
            </Typography>
            <Typography variant="body1" className={styles.maintenanceSubtitle}>
              Thank you for your patience.
            </Typography>
          </div>
          <img
            src={MaintenanceImg}
            alt="Maintenance image"
            className={styles.maintenanceImage}
          />
        </Container>
      </Container>
    );
  }
}

export default Maintenance;
