// eslint-disable-next-line

import React from 'react'

import PropTypes from 'prop-types'

import Autocomplete from '@material-ui/lab/Autocomplete'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { IconButton, Tooltip } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ContactNumber from './ContactNumber/ContactNumber'
import Address from './Address/Address'
import EmailAddress from './EmailAddress/EmailAddress'
import ContactPerson from './ContactPerson/ContactPerson'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import AddBoxIcon from '@material-ui/icons/AddBox'
import styles from './Client.module.css'

class ClientForm extends React.Component {
  render() {
    const setVal = (name) => {
      const attrName = name.toLowerCase().replace(' ', '_')
      if (this.props.cfield === null) {
        return null
      } else {
        if (this.props.cfield[attrName] !== undefined) {
          const value = this.props.cfield[attrName]

          return value
        }
      }
    }
    const setCustom = (field) => {
      if (field.attr_type === 'text') {
        return <><TextField
          variant="outlined"
          autoFocus={true}
          style={{ margin: '8px', width: '95%', textTransform: 'capitalize' }}
          required
          fullWidth
          size="small"
          label={field.attr_name}
          value={setVal(field.attr_name)}
          name={field.attr_name.toLowerCase().replace(' ', '_')}
          onChange={this.props.handleField}
        /><br /></>
      } else if (field.attr_type === 'number') {
        return <><TextField
          style={{ margin: '8px', width: '95%', textTransform: 'capitalize' }}
          variant="outlined"
          autoFocus={true}
          required
          fullWidth
          size="small"
          label={field.attr_name}
          value={setVal(field.attr_name)}
          name={field.attr_name.toLowerCase().replace(' ', '_')}
          onChange={this.props.handleField}
        /><br /></>
      } else if (field.attr_type === 'date') {
        return <>
          <TextField
            fullWidth="true"
            autoFocus={true}
            id="ends_at"
            type="date"
            size="small"
            variant="outlined"
            style={{ margin: '8px', width: '95%', textTransform: 'capitalize' }}
            format="yyyy-MM-dd"
            label={field.attr_name}
            value={setVal(field.attr_name)}
            name={field.attr_name.toLowerCase().replace(' ', '_')}
            onChange={this.props.handleField}
            InputLabelProps={{
              shrink: true
            }}
          />
          <br /></>
      } else {
        return null
      }
    }


    return (
      <>
        <Typography variant="h6" noWrap>
          Contact Information
        </Typography>
        <form>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="off"
                style={{ width: '100%', marginTop: '15px' }}
                variant="outlined"
                required={true}
                fullWidth
                label="Name"
                name="name"
                size="small"
                value={this.props.client.name}
                onChange={this.props.handleChange}
              />
              
              <p className={styles.error}>{this.props.error.name?( 'Name ' + this.props.error.name ): null}</p>

              <FormControlLabel
                control={<Switch checked={this.props.client.is_taxable} onChange={this.props.handleSwitch}
                  name="is_taxable" />}
                label="Tax-Exempt" style={{marginRight: 8 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={3}>
                <Grid item xs={11} sm={11}>
                  <div>
                    <Autocomplete
                      value={this.props.defaultGroup}
                      name="groups_dropdown"
                      options={this.props.groups.sort(function (a, b) {
                        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                        return 0;
                      })}
                      fullWidth
                      getOptionLabel={(option) => option.name || ''}
                      getOptionSelected={(option, value) => option.name === value.name}
                      onChange={this.props.handleAutoComplete}
                      style={{marginTop: '15px', padding: '14px !important' }}
                      renderInput={(params) => <TextField {...params} size="small" label="Group" variant="outlined" />}
                    />
                    <p className={styles.error}>{this.props.error.group ?( 'Group ' + this.props.error.group ): null}</p>
                  </div>
                </Grid>
                <Grid item xs={1} sm={1} style={{padding: '0'}}>
                  <Tooltip title="Add Group" arrow>
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={this.props.openGroupForm}
                      style={{marginTop: '30px'}}
                      name="add_group_button"
                    >
                      <GroupAddIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <FormControlLabel
                control={<Switch checked={this.props.client.is_company} onChange={this.props.handleSwitch} />}
                label="Group/Company" style={{ marginLeft: 8 }} name="is_company"
                />
              
                <FormControlLabel
                  control={<Switch checked={this.props.client.is_active} onChange={this.props.handleSwitch} />}
                  label="Active" style={{marginLeft: 8 }} name="is_active"
                />
              </Grid>

              
            </Grid>
          </Grid>
        </form>
        {this.props.client.is_company ? (

          <>
            {
              this.props.addresses !== undefined ? (
                <div elevation={0}>
                  <div className="flex-align-center">
                    <h4 className="set-label-width">Address</h4>
                    <IconButton
                      color="primary"
                      variant="addAddress"
                      onClick={this.props.addAddress}>
                      <AddBoxIcon />
                    </IconButton>
                  </div>
                  {
                    this.props.addresses.map((val, idx) => (
                      <>
                        <Address
                          key={`content-${idx}`}
                          idx={idx}
                          name={`client_address_content_${idx}`}
                          addresses={this.props.addresses}
                          handleAddressChange={this.props.handleAddressChange}
                          removeAddress={this.props.removeAddress}
                        />

                      </>
                    ))
                  }

                </div>
              ) : (null)
            }
            {
              this.props.contactPersons !== undefined ? (
                <div>
                  <div className="flex-align-center">
                    <h4 className="set-label-width">Contact Persons</h4>
                    <IconButton
                      color="primary"
                      variant="addContactPerson"
                      name="add_client_contact_person"
                      onClick={this.props.addContactPerson}>
                      <AddBoxIcon />
                    </IconButton>
                  </div>
                  {
                    this.props.contactPersons.map((val, idx) => (
                      <ContactPerson
                        key={`content-${idx}`}
                        idx={idx}
                        contactPersons={this.props.contactPersons}
                        handleContactPersonChange={this.props.handleContactPersonChange}
                        removeContactPerson={this.props.removeContactPerson}
                      />
                    ))
                  }
                </div>
              ) : (null)
            }
          </>
        ) : (
          <>
            {
              this.props.addresses !== undefined ? (
                <div elevation={0}>
                  <div className="flex-align-center">
                    <h4 className="set-label-width">Address</h4>
                    <IconButton
                      color="primary"
                      variant="addAddress"
                      name="add_client_address"
                      onClick={this.props.addAddress}>
                      <AddBoxIcon />
                    </IconButton>
                  </div>
                  {
                    this.props.addresses.map((val, idx) => (
                      <>
                        <Address
                          key={`content-${idx}`}
                          idx={idx}
                          addresses={this.props.addresses}
                          handleAddressChange={this.props.handleAddressChange}
                          removeAddress={this.props.removeAddress}
                        />

                      </>
                    ))
                  }

                </div>
              ) : (null)
            }
            <Grid container>
              <Grid item xs={12} sm={6}>
                {this.props.emailAddresses !== undefined ? (
                  <div elevation={0}>
                    <div className="flex-align-center">
                      <h4 className="set-label-width">Email Address</h4>
                      <IconButton
                        color="primary"
                        variant="outlined"
                        name="add_client_email_address"
                        onClick={this.props.addEmailAddress}>
                        <AddBoxIcon />
                      </IconButton>
                    </div>
                    {
                      this.props.emailAddresses.map((val, idx) => (
                        <EmailAddress
                          key={`content-${idx}`}
                          idx={idx}
                          emailAddresses={this.props.emailAddresses}
                          handleEmailAddressChange={this.props.handleEmailAddressChange}
                          removeEmail={this.props.removeEmail}
                        />
                      ))

                    }
                  </div>
                ) : (null)
                }
              </Grid>
              <Grid item xs={12} sm={6}>
                {this.props.contactNumbers !== undefined ? (
                  <div elevation={0}>
                    <div className="flex-align-center">
                      <h4 className="set-label-width">Contact Number</h4>
                      <IconButton
                        color="primary"
                        variant="addContactNumber"
                        name="add_client_contact_number"
                        onClick={this.props.addContactNumber}>
                        <AddBoxIcon />
                      </IconButton>
                    </div>
                    {
                      this.props.contactNumbers.map((val, idx) => (
                        <ContactNumber
                          key={`content-${idx}`}
                          idx={idx}
                          contactNumbers={this.props.contactNumbers}
                          handleContactNumberChange={this.props.handleContactNumberChange}
                          handleNumberChange={this.props.handleNumberChange}
                          removeContactNumber={this.props.removeContactNumber}
                        />
                      ))

                    }
                  </div>
                ) : (null)
                }
              </Grid>
            </Grid>
          </>
        )
        }
        <div className="flex">
          <h4 style={{ marginTop: '15px' }}>Custom Fields</h4>
          <IconButton
            className={styles.expandedButton}
            onClick={this.props.handleExpandClick}
            aria-expanded={this.props.expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
        <Collapse in={this.props.expanded} timeout="auto" unmountOnExit>
          {
            this.props.fields.length > 0 ? (
              this.props.fields.map((field) => (
                <>
                  {setCustom(field)}
                  <br />
                </>
              )
              )

            ) : (
              null
            )
          }
        </Collapse>

        <div className={styles.actionButton}>
          <Button variant="outlined" color="primary"
            disabled={!this.props.client.name || !this.props.defaultGroup}
            name="client_submit"
            onClick={() => this.props.submit()}>Save</Button>
        </div>
      </>

    )
  }

}

export default ClientForm

ClientForm.propTypes = {
  submit: PropTypes.func,
  handleChange: PropTypes.func,
  handleSwitch: PropTypes.func,
  client: PropTypes.object,
  groups: PropTypes.array,
  addAddress: PropTypes.func,
  handleAddressChange: PropTypes.func,
  removeAddress: PropTypes.func,
  addresses: PropTypes.array,
  addEmailAddress: PropTypes.func,
  handleEmailAddressChange: PropTypes.func,
  removeEmail: PropTypes.func,
  emailAddresses: PropTypes.array,
  contactNumbers: PropTypes.array,
  addContactNumber: PropTypes.func,
  handleContactNumberChange: PropTypes.func,
  removeContactNumber: PropTypes.func,
  handleNumberChange: PropTypes.func,
  addContactPerson: PropTypes.func,
  handleContactPersonChange: PropTypes.func,
  removeContactPerson: PropTypes.func,
  contactPersons: PropTypes.array,
  openGroupForm: PropTypes.func,
  expanded: PropTypes.bool,
  handleExpandClick: PropTypes.func,
  fields: PropTypes.array,
  handleField: PropTypes.func,
  handleAutoComplete: PropTypes.func,
  error: PropTypes.object,
  defaultGroup: PropTypes.object,
  cfield: PropTypes.object
}


