import React from 'react'

import PropTypes from 'prop-types'

import {Link} from 'react-router-dom'

import {Typography, Button} from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import styles from './Tasks.module.css'
import PersonIcon from '@material-ui/icons/Person'
import Grid from '@material-ui/core/Grid'
import ListAltIcon from '@material-ui/icons/ListAlt';
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import FlagIcon from '@material-ui/icons/Flag';
class Task extends React.Component {
  getPriorityLabel(){
    var label = ' No Priority'

    if(!this.props.priorities) return label

    if(this.props.priorities.length>0){
      label = this.props.priorities.find((priority) => priority.uid === this.props.task.priority_uid)? this.props.priorities.find((priority) => priority.uid === this.props.task.priority_uid).name : label
    }
    return label
   }

   getPriorityColor(){
    var color = '#3788d9'

    if(!this.props.priorities) return color

    if(this.props.priorities.length>0){
      color = this.props.priorities.find((priority) => priority.uid === this.props.task.priority_uid)? this.props.priorities.find((priority) => priority.uid === this.props.task.priority_uid).color : color
    }
    return color
   }
  render() {

    return (
      <>
        <Grid container spacing={1} gutterBottom>
          <Grid item xs={12} md={12}>
            <div className={styles.taskTitle} gutterBottom>
              <div className={styles.taskTitleList}>
                <Typography variant="h5">
                  <ListAltIcon/> {this.props.task.name}
                </Typography>
                <div>
                  <FlagIcon size="large" style={{color:this.getPriorityColor()}} /> {this.getPriorityLabel()}
                </div>
                <span>Due at</span>
                &nbsp; - &nbsp;
                  <span>
                    {this.props.task.ends_at ? (new Date(this.props.task.ends_at).toLocaleDateString('en-US', {
                      month: 'long',
                      weekday: 'long',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit'
                    })) : ("n/a")}
                  </span>
              </div>
            </div>
            <div className={styles.taskDescBody}>
              <div className={styles.taskDesc}>
                <Typography variant="body2" gutterBottom>
                  {this.props.task.description ? (this.props.task.description) : ('None')}
                </Typography>
              </div>
            </div>

            <div>
              <div className={styles.taskLink}>
                <WorkOutlineIcon/> {this.props.task.legal_case ? (<Link to={`/legal_cases/${this.props.task.legal_case.uid}`} target="_blank" title="Go to assigned case">{this.props.task.legal_case.title}</Link>) : ('No Linked Case')}
              </div>
            </div>

            <hr className={styles.taskHrLineButton}/>
            <div>
              <Typography variant="body1" className={styles.taskLabelShared}>
                <b>Shared/Attending</b>
              </Typography>
              <div className={styles.taskUserBody} >
                {
                  this.props.task.user_uids ? (
                    this.props.task.user_uids.map((taskUser) => (
                      this.props.users.map((user) => (
                        taskUser === user.uid ? (
                          <div className={styles.taskUserList} >
                            <div className={styles.taskAvatarIcon}>
                              <Avatar>
                                <PersonIcon />
                              </Avatar>
                              <Typography variant="body1" className={styles.taskAvatarName}>
                                {user.last_name}, {user.first_name}
                              </Typography>
                            </div>
                          </div>                            ) : (
                          null
                        )
                      ))
                    ))
                  ) : (
                    <div className={styles.taskNoUser}>No users assigned to this task</div>
                  )
                }
              </div>
            </div>

          </Grid>
        </Grid>
        <div className={styles.taskActionButton}>
          <Button
            variant="outlined"
            onClick={() => this.props.handleClose()}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={() => this.props.handleCreateOrUpdateTask(this.props.task)}
            color="primary"
          >
            Update
          </Button>
        </div>
      </>
    )
  }

}

export default Task

Task.propTypes = {
  task: PropTypes.object,
  users: PropTypes.array,
  handleClose: PropTypes.func,
  handleCreateOrUpdateTask: PropTypes.func,
  priorities: PropTypes.array
}
