import React from 'react'
import PropTypes from 'prop-types'
import styles from './SimpleTable.module.css'
import {Link} from 'react-router-dom'
import {Redirect} from 'react-router'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import TableContainer from "@material-ui/core/TableContainer"
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import PersonIcon from '@material-ui/icons/Person'
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import FlagIcon from '@material-ui/icons/Flag'
import {Button, TextField, Tooltip} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'
import ActionButtons from "../TableActionButtons/ActionButtons";
import moment from 'moment'

class SimpleTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      pathItem: {}
    }
    this.getUserNames = this.getUserNames.bind(this)
    this.getClientNames = this.getClientNames.bind(this)
    this.getPracticeName = this.getPracticeName.bind(this)
    this.getTotal = this.getTotal.bind(this)
    this.getBillerName = this.getBillerName.bind(this)
  }

  getUserNames(item) {
    var usernames = []
    if (item.user_uids !== undefined) {
      if (item.user_uids !== null) {
        if (item.user_uids.length > 0) {
          item.user_uids.map((currentId) => {
            if (this.props.users.length > 0) {
              this.props.users.map((user) => {
                var fullname = ""
                if (currentId === user.uid) {
                  fullname =( user.first_name? user.first_name:'') + " " + (user.last_name? user.last_name: '')
                  usernames.push(<div>{fullname}</div>)
                }
              })
            }
          })
        }
      }
    }
    return usernames
  }

  getPracticeName(item) {
    var practice_name = []
    if (item.practice_area_uid !== null) {
      if (item.practice_area_uid.length > 0) {
        if (this.props.practiceAreas.length > 0) {
          this.props.practiceAreas.map((name) => {
            if (item.practice_area_uid === name.uid) {
              practice_name.push(<div>{name.name}</div>)
            }
          })
        }
      }
    }
    return practice_name
  }

  getClientNames(item) {
    var clientnames = []
    if (item.client_uids !== null) {
      if (item.client_uids.length > 0) {
        item.client_uids.map((currentId) => {
          if (this.props.clients.length > 0) {
            this.props.clients.map((client) => {
              if (currentId === client.uid) {
                clientnames.push(<div>{client.name}</div>)
              }
            })
          }
        })
      }
    }
    return clientnames
  }

  getTotal() {
    var totalAmount = 0
    var deduction = 0
    if (this.props.items.length > 0) {
      this.props.items.map((item) => {
        if (item["amount"] !== undefined) {
          if (item["is_deduction"] === true) {
            deduction = deduction + parseFloat(item["amount"])
          } else {
            totalAmount = totalAmount + parseFloat(item["amount"])
          }
        }
      })
    }
    return (totalAmount - deduction)
  }

  getBillerName(billerId) {
    var billerName = ''
    if (this.props.clients.length > 0) {
      this.props.clients.map((client) => {
        if (billerId === client.uid) {
          billerName = client.name
        }
      })
    }


    return billerName
  }

  redirectTo(item) {
    this.setState({redirect: true})
    this.setState({pathItem: item})
  }

  handleChildClick(e) {
    e.stopPropagation();
  }

  render() {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    }
    const pathName = '/' + this.props.model.toLowerCase() + 's/'
    if (this.state.redirect) {
      if (this.state.pathItem !== null) {
        return (<Redirect
          to={this.props.model.toLowerCase() === 'client' ||
          this.props.model.toLowerCase() === 'case' ||
          this.props.model.toLowerCase() === 'workflow' ||
          this.props.model.toLowerCase() === 'invoice' ? (
            {
              pathname: pathName + this.state.pathItem.uid,
              state: {
                id: this.state.pathItem.uid,
                item: this.state.pathItem,
                prevPath: this.props.prevPath
              }
            }) : ('#')
          }
        />)
      }
    }
    var tableItems = []
    if(this.props.model.toLowerCase() !== "group" && this.props.model.toLowerCase() !== "practice area"){
    tableItems = this.props.items.sort(
                      (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
                    ).reverse()
    }else{
      tableItems = this.props.items
    }
    return (
      <>
          <Grid container spacing={0}>
          <Grid item xs={this.props.withSearch ? 12 : 6} sm={8}
            justify="flex-start"
            direction="row"
            alignItems="center"
          >
            <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <div className={this.props.withSearch ? styles.downTitle : styles.tileBody}>
                {this.props.removeLabel ? (null) : (this.props.icon)}
                <h2 className={styles.title}>
                  <div className={styles.head}>
                    {
                      this.props.removeLabel ? (
                        null
                      ) : (
                        this.props.model.replace('_', ' ').toLowerCase() === "home task" ? (

                          <Link className={styles.link} to={'/tasks'}>Tasks</Link>
                        ) : (
                          this.props.model.replace('_', ' ').toLowerCase() === "home case" ? (
                            <Link className={styles.link} to={'/legal_cases'}>Cases</Link>
                          ) : (
                            <div>{this.props.model.replace('_', ' ') + 's'}</div>
                          )
                        )
                      )


                    }
                    {
                      this.props.withCalendar ? (
                        <Button size="small" name="calendarView" variant="contained" className={styles.toggleButton}
                                onClick={this.props.toggleView}><b>Calendar View</b></Button>
                      ) : (null)
                    }
                  </div>
                </h2>
              </div>
            </Grid>
            <Grid item xs={12} sm={8}>
              {
                  this.props.withStateButton ? (
                    <div className={styles.showButtonTasksBody} onClick={this.props.changeIndex}>{this.props.showState ? (
                      <Button variant="outlined" color="secondary">Show On Going Tasks</Button>) : (
                      <Button variant="outlined" color="primary">Show Finished Tasks</Button>)}</div>
                  ) : (
                    null
                  )
                }
                {this.props.withSearch ? (
                  <div>
                    <div className={styles.searchBody}>
                      <div className={styles.searchList}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label={<div className={styles.searchBar}><SearchIcon/>
                            <div>Search</div>
                          </div>}
                          size="small"
                          value={this.props.q}
                          className={styles.searchField}
                          onKeyPress={this.props._handleKeyPress}
                          onChange={this.props.handleChanges}
                          name="global_search"
                        >
                        </TextField>

                        <div className={styles.groupSearchButton}>
                          {
                            this.props.model.toLowerCase() !== "document" && this.props.model.toLowerCase() !== "workflow" && this.props.model.toLowerCase() !== "practice area" ? (
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className={styles.advancedSearch}
                                onClick={this.props.handleAdvanced}
                              >
                                Advanced Search
                              </Button>
                            ) : null
                          }

                          <IconButton
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={this.props.clearSearch}
                          >
                            <SettingsBackupRestoreIcon/>
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
            </Grid>
            </Grid>
          </Grid>

          <Grid item xs={this.props.withSearch ? 12 : 6} sm={4} className={styles.rightAction}>
          {
              this.props.model.toLowerCase() === "task" || this.props.model.replace('_', ' ').toLowerCase() === "home task" ? (
                <div className={styles.taskLegend}>
                  <div className={styles.circle}/>
                  <Typography variant="caption">Assigned from workflow</Typography>
                </div>
              ) : (null)
            }
            {
              this.props.model.toLowerCase() === "event" ? (
                <div className={styles.taskLegend}>
                  <div className={styles.circle}/>
                  <Typography variant="caption">Assigned from workflow</Typography>

                </div>
              ) : (null)
            }
            {
              this.props.model.toLowerCase() === "transaction" ? (
                <div className={styles.taskLegend}>
                  <div className={styles.circle}/>
                  <Typography variant="caption">Created from tasks</Typography>

                </div>
              ) : (null)
            }
            <div style={{display: 'contents'}}>
              {
                this.props.withBulkInvoice ? (
                  <div style={{display: 'flex', alignItems: 'center'}}>

                    <Tooltip title="Create Bulk Invoice" arrow>
                      <IconButton color="inherit" aria-label="menu"
                                  onClick={() => this.props.handleCreateBulkInvoice(this.props.initialItem, true, this.props.model)}
                                  name="create_bulk_invoice"
                      >
                        <CreditCardIcon fontSize="large"/>
                      </IconButton>
                    </Tooltip>
                  </div>

                ) : (null)
              }
              {this.props.removeButtonAdd !== true ? (
              <div>
                <IconButton color="inherit" aria-label="menu"
                            id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_add`}
                            name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_add`}
                            onClick={() => this.props.handleClick(this.props.initialItem, true, this.props.model)}>
                  <AddCircleOutlineIcon fontSize={this.props.removeLabel ? ("medium") : ("large")}/>
                </IconButton>
              </div>
              ):null}
            </div>
          </Grid>
        </Grid>
          <TableContainer className={this.props.customClassName}>
              <Table size="small" stickyHeader aria-label="customized sticky table"
                    name={this.props.model.toLowerCase().replace(/ /g, '_') + "_table"}>
                <TableHead className={styles.tableHeadBody}>
                  <TableRow>
                    {
                      this.props.headers.length > 0 ? (
                        this.props.headers.map((header) => (
                          header.toLowerCase() === 'actions' ? (
                            <TableCell key={header} className={styles.actions}
                                      align="center"><strong>{header}</strong></TableCell>
                          ) : (
                            header.includes('.') ? (
                                header === 'group.name' ? (
                                  <TableCell key={header}
                                            className={styles.cell}><strong>Group</strong></TableCell>
                                ) : (
                                  header === "transaction_type.name" ?
                                    (<TableCell key={header}
                                                className={styles.cell}><strong>{header.split('.')[0].replace('_', ' ').replace('.', ' ')}</strong></TableCell>)
                                    : (
                                      header === "transaction_record.name" ? (
                                        <TableCell key={header}
                                                  className={styles.cell}><strong>Activity</strong></TableCell>) : (
                                        header === "parent_workflow.name" ? (
                                          <TableCell key={header}
                                                    className={styles.cell}><strong>Process</strong></TableCell>) : (
                                          header === "user.first_name" ? (
                                              <TableCell key={header}
                                                        className={styles.cell}><strong>Lawyer/Staff</strong></TableCell>) :
                                            (
                                              header === "legal_case.client_uids" ? (
                                                  <TableCell key={header}
                                                            className={styles.cell}><strong>Client(s)</strong></TableCell>) :
                                                (
                                                  header === "legal_case.title" ? (
                                                      <TableCell key={header}
                                                                className={styles.cell}><strong>Case</strong></TableCell>) :
                                                    (
                                                      header === "transaction_record.filed_at" ? (
                                                          <TableCell key={header}
                                                                    className={styles.cell}><strong>Date
                                                            Filed</strong></TableCell>) :
                                                        (
                                                          header === "fields.date_file" ? (
                                                            <TableCell key={header}
                                                                      className={styles.cell}><strong>Date
                                                              Filed</strong></TableCell>
                                                          ) : (

                                                            header === "legal_case.biller_uid" ? (
                                                                <TableCell key={header}
                                                                          className={styles.cell}><strong>Billed
                                                                  To</strong></TableCell>) :
                                                              (
                                                                <TableCell key={header}
                                                                          className={styles.cell}><strong>{header.split('.')[1].replace('_', ' ').replace('.', ' ')}</strong></TableCell>
                                                              )
                                                          )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                      )
                                    )
                                )
                              )
                              : (header === 'starts_at' && this.props.model === 'Event' ? (
                                  <TableCell key={header}
                                            className={styles.cell}><strong>Date</strong></TableCell>
                                ) : (
                                  header === 'starts_at' && this.props.model === 'Event' ? (
                                    <TableCell key={header} className={styles.cell}><strong>Time</strong></TableCell>
                                  ) : (
                                    header === 'ends_at' && this.props.model === 'Event' ? (
                                      <TableCell key={header} className={styles.cell}><strong>Time</strong></TableCell>
                                    ) : (
                                      header === 'ends_at' ? (
                                        <TableCell key={header} className={styles.cell}><strong>Due
                                          Date</strong></TableCell>
                                      ) : (
                                        header.includes('attr') ? (
                                          <TableCell key={header}
                                                    className={styles.cell}><strong>{header.replace('_', ' ').replace('attr', 'Atribute')}</strong></TableCell>
                                        ) : (
                                          header === 'is_company' ? (
                                            <TableCell key={header}
                                                      className={styles.cell}><strong>Classification</strong></TableCell>
                                          ) : (
                                            header === "user_uids" && this.props.model === "Event" ? (
                                              <TableCell key={header}
                                                        className={styles.eventCell}><strong>Attendee/s</strong></TableCell>
                                            ) : (
                                              header === "user_uids" && this.props.model === "Task" ? (
                                                <TableCell key={header}
                                                          className={styles.cell}><strong>Assigned To</strong></TableCell>
                                              ) : (
                                                header.includes('user_uids') && this.props.model.toLowerCase() === "case" ? (
                                                  <TableCell key={header}
                                                            className={styles.cell}><strong>{header.replace('user_uids', ' Assigned Lawyer')}</strong></TableCell>
                                                ) : (
                                                  header.includes('_uids') ? (
                                                    <TableCell key={header}
                                                              className={styles.capitalizedCell}><strong>{header.replace('_uids', ' Name')}</strong></TableCell>
                                                  ) : (

                                                    header.includes('_uid') ? (
                                                      <TableCell key={header}
                                                                className={styles.capitalizedCell}><strong>{header.replace('_uid', ' ').replace(/_/g, ' ')}</strong></TableCell>
                                                    ) : (

                                                      header === 'is_active' ? (
                                                        <TableCell key={header}
                                                                  className={styles.cell}><strong>Status</strong></TableCell>

                                                      ) : (
                                                        header.toLowerCase() === 'reference_no' ? (
                                                          this.props.model.toLowerCase() === "invoice" ? (
                                                            <TableCell key={header}
                                                                      className={styles.invoice}><strong>Invoice
                                                              No.</strong></TableCell>
                                                          ) : (
                                                            <TableCell key={header}
                                                                      className={styles.cellRef}><strong>Reference
                                                              No.</strong></TableCell>
                                                          )
                                                        ) : (
                                                          header.toLowerCase() === "amount" || header.toLowerCase() === "discount" ? (
                                                            <TableCell key={header}
                                                                      className={styles.amount}><strong>{header.toLowerCase()}</strong></TableCell>
                                                          ) : (
                                                            header.toLowerCase() === "rate" ? (
                                                              <TableCell key={header}
                                                                        className={styles.amount}><strong>Rate</strong></TableCell>

                                                            ) : (
                                                              header.toLowerCase() === "name" && this.props.model.toLowerCase() === "transaction" || header.toLowerCase() === "name" && this.props.model === "Invoice Detail" ? (
                                                                <TableCell key={header}
                                                                          className={styles.tansDescription}><strong>Activity</strong></TableCell>
                                                              ) : (
                                                                header.toLowerCase() === "is_deduction" ? (
                                                                  <TableCell key={header}
                                                                            className={styles.cell}><strong>Category</strong></TableCell>
                                                                ) : (
                                                                  header.toLowerCase() === "filed_at" ? (
                                                                    this.props.model.toLowerCase() === "invoice" ? (
                                                                      <TableCell key={header}
                                                                                className={styles.cell}><strong>Invoice
                                                                        Date</strong></TableCell>
                                                                    ) : (
                                                                      <TableCell key={header}
                                                                                className={styles.cell}><strong>Date
                                                                        Filed</strong></TableCell>
                                                                    )
                                                                  ) : (
                                                                    header.toLowerCase() === "duration" ? (
                                                                      <TableCell key={header}
                                                                                className={styles.amount}><strong>No.
                                                                        of Hours</strong></TableCell>
                                                                    ) : (
                                                                      header.toLowerCase() === "name" && this.props.model === "Task" ? (
                                                                        <TableCell key={header}
                                                                                  className={styles.cell}><strong>Task</strong></TableCell>
                                                                      ) : (
                                                                        header === "State" && this.props.model === "Task" ? (
                                                                          <TableCell key={header}
                                                                                    className={styles.cell}><strong>Status</strong></TableCell>
                                                                        ) : (
                                                                          header === "Name" && this.props.model === "Event" ? (
                                                                            <TableCell key={header}
                                                                                      className={styles.cell}><strong>Event</strong></TableCell>
                                                                          ) : (
                                                                            header.toLowerCase() === "grand_total" ? (
                                                                              <TableCell key={header}
                                                                                        className={styles.amount}><strong>Total
                                                                                Amount</strong></TableCell>
                                                                            ) : (
                                                                              header.toLowerCase() ===  "state" ? (
                                                                                <TableCell key={header}
                                                                                          className={styles.cell}><strong>Status</strong></TableCell>
                                                                              ) : (
                                                                                header.toLowerCase() ===  "permisions_user" ? (
                                                                                <TableCell key={header}
                                                                                          className={styles.cell}><strong>User</strong></TableCell>
                                                                              ) : (
                                                                              <TableCell key={header}
                                                                                        className={styles.cell}><strong>{header.replace('_', ' ').replace('.', ' ')}</strong></TableCell>
                                                                              )
                                                                              )
                                                                            )
                                                                          )
                                                                        )
                                                                      )
                                                                    )
                                                                  )
                                                                )
                                                              )
                                                            )
                                                          )
                                                        )
                                                      )
                                                    )
                                                  )
                                                )
                                              )
                                            )
                                          )
                                        )
                                      )
                                    )

                                  )
                                )
                              )

                          )
                        ))
                      ) : (
                        null
                      )
                    }
                  </TableRow>
                </TableHead>

                <TableBody className={styles.tableBody}>
                  {
                    tableItems.map((item, idx) => (
                        <>
                          <TableRow key={`${item.uid}-${idx}`}  onClick={this.props.model.toLowerCase() === 'client' ||
                                    this.props.model.toLowerCase() === 'case' ||
                                    this.props.model.toLowerCase() === 'workflow' ||
                                    this.props.model.toLowerCase() === 'invoice'
                                      ? () => this.redirectTo(item):null}
                                    className={this.props.model.toLowerCase() === 'client' ||
                                    this.props.model.toLowerCase() === 'case' ||
                                    this.props.model.toLowerCase() === 'workflow' ||
                                    this.props.model.toLowerCase() === 'invoice'
                                      ? styles.linkable : styles.notLinkable}>
                            {
                              this.props.headers.length > 0 ? (
                                this.props.headers.map((header, idx) => (
                                  header.toLowerCase() === 'actions' ? (
                                    <TableCell key={header + idx.toString} className={styles.cell} align="right">

                                    </TableCell>
                                  ) : (

                                    header.includes(".") ? (
                                      header === "legal_case.client_uids" ? (
                                          <TableCell key={header}
                                                    className={styles.cell}>
                                            {this.getClientNames(item.legal_case)}
                                          </TableCell>) :
                                        (
                                          header === "transaction_record.filed_at" ? (
                                              <TableCell key={header}
                                                        className={styles.cell}>
                                                {item[header.split(".")[0]] ? (new Date(item[header.split(".")[0]][header.split(".")[1]]).toLocaleString('en-Us', {
                                                  year: 'numeric',
                                                  month: '2-digit',
                                                  day: '2-digit'
                                                })) : ("n/a")}
                                              </TableCell>) :
                                            (
                                              header === "legal_case.biller_uid" ? (
                                                  <TableCell key={header}
                                                            className={styles.cell}>
                                                    <Link className={styles.link}
                                                          to={`/clients/${item.legal_case.biller_uid}`}>{this.getBillerName(item.legal_case.biller_uid)}</Link>
                                                  </TableCell>) :
                                                (
                                                  header === "legal_case.title" && this.props.model.toLowerCase() === "invoice" ? (
                                                      <TableCell key={header}
                                                                className={styles.cell}>
                                                        <Link className={styles.link}
                                                              to={`/cases/${item.legal_case.uid}`}>{item.legal_case.title}</Link>
                                                      </TableCell>) :
                                                    (
                                                      <TableCell key={header} className={styles.cell}>
                                                        <>{item[header.split(".")[0]] ? (item[header.split(".")[0]][header.split(".")[1]]) : ("n/a")}</>
                                                      </TableCell>
                                                    )
                                                )
                                            )
                                        )


                                    ) : (
                                      header.toLowerCase() === "amount" ? (
                                        <TableCell key={header} className={styles.amount}>
                                          {item[header.toLowerCase()] ? (parseFloat(item[header.toLowerCase()])).toLocaleString(navigator.language, {minimumFractionDigits: 2}) : ("n/a")}
                                        </TableCell>
                                      ) : (header.toLowerCase() === 'date_filed' ? (
                                          <TableCell key={header} className={styles.cell}>
                                            {item[header.toLowerCase()] ? (moment(item[header.toLowerCase()]).format('L')) : ("n/a")}
                                          </TableCell>
                                        ) : (header.includes("starts_at") ? (
                                            this.props.model.toLowerCase() === "event" ? (
                                              <TableCell key={header} className={styles.cell}>
                                                {item[header.toLowerCase()] ? (new Date(item[header.toLowerCase()]).toLocaleDateString('en-US', {
                                                  month: '2-digit',
                                                  day: '2-digit',
                                                  year: 'numeric'
                                                })) : ("n/a")}
                                              </TableCell>
                                            ) : (
                                              <TableCell key={header} className={styles.cell}>
                                                {item[header.toLowerCase()] ? (new Date(item[header.toLowerCase()]).toLocaleDateString('en-US', options)) : ("n/a")}
                                              </TableCell>
                                            )
                                          ) : (
                                            header === 'is_company' ? (
                                              <TableCell key={header} className={styles.cell}>
                                                {item[header.toLowerCase()] ? (<HomeWorkIcon/>) : (<PersonIcon/>)}
                                              </TableCell>
                                            ) : (
                                              header === 'is_active' ? (
                                                <TableCell key={header} className={styles.cell}>
                                                  {item[header.toLowerCase()] ? (
                                                    <CheckIcon className={styles.checkColor}/>
                                                  ) : (
                                                    <ClearIcon className={styles.clearColor}/>
                                                  )}
                                                </TableCell>
                                              ) : (
                                                header.includes("ends_at") && this.props.model === 'Event' ? (
                                                  <TableCell key={header} className={styles.cell}>
                                                    {item[header.toLowerCase()] ? (new Date(item["starts_at"]).toLocaleTimeString('en-US', {
                                                      hour: '2-digit',
                                                      minute: '2-digit'
                                                    })) : ("n/a")}
                                                  </TableCell>
                                                ) : (
                                                  header.includes("ends_at") ? (
                                                    <TableCell key={header} className={styles.cell}>
                                                      {item[header.toLowerCase()] ? (new Date(item[header.toLowerCase()]).toLocaleDateString('en-US')) : ("n/a")}
                                                    </TableCell>

                                                  ) : (header.includes('user_uids') ? (
                                                      <TableCell key={header} className={styles.capitalizedCell}>
                                                        {this.getUserNames(item) ? (this.getUserNames(item)) : ("n/a")}
                                                      </TableCell>
                                                    ) : (header.includes('client_uids') ? (
                                                        <TableCell key={header} className={styles.cell}>
                                                          {this.getClientNames(item)}
                                                        </TableCell>
                                                      ) : (header.includes('practice_area_uid') ? (
                                                          <TableCell key={header} className={styles.cell}>
                                                            {this.getPracticeName(item)}
                                                          </TableCell>
                                                        ) : (
                                                          header.toLowerCase() === "rate" ? (
                                                              <TableCell key={header} className={styles.amount}>
                                                                {item[header.toLowerCase()] && item["rate"] > 0 ? (parseFloat(item[header.toLowerCase()])).toLocaleString(navigator.language, {minimumFractionDigits: 2}) : ("-")}
                                                              </TableCell>) :
                                                            (header.includes('Description') ? (
                                                                <TableCell key={header} className={styles.tableDesc}>
                                                                  {item[header.toLowerCase()] ? (item[header.toLowerCase()]) : ("n/a")}
                                                                </TableCell>

                                                              ) : (
                                                                header.toLowerCase() === "name" ? (
                                                                  item["from_workflow"] !== undefined ? (
                                                                    <TableCell key={header} className={styles.cell}>
                                                                      {
                                                                        item["from_workflow"] ? (
                                                                          <div className={styles.header}>
                                                                            <div className={styles.itemMargin}>
                                                                              {item[header.toLowerCase()] ? (item[header.toLowerCase()]) : ("n/a")}
                                                                            </div>
                                                                            <div className={styles.circle}/>
                                                                          </div>
                                                                        ) : (
                                                                          <>
                                                                            {item[header.toLowerCase()] ? (item[header.toLowerCase()]) : ("n/a")}
                                                                          </>
                                                                        )


                                                                      }
                                                                    </TableCell>
                                                                  ) : (

                                                                    item["task_uid"] !== null & this.props.model.toLowerCase() === "transaction" ? (
                                                                      <TableCell key={header} className={styles.cell}>
                                                                        <div className={styles.header}>
                                                                          <div>
                                                                            {item[header.toLowerCase()] ? (item[header.toLowerCase()]) : ("n/a")}
                                                                          </div>
                                                                          <div className={styles.circle}/>
                                                                        </div>
                                                                      </TableCell>
                                                                    ) : (
                                                                      <TableCell key={header} className={styles.cell}>
                                                                        {item[header.toLowerCase()] ? (item[header.toLowerCase()]) : ("n/a")}
                                                                      </TableCell>
                                                                    )
                                                                  )
                                                                ) : (
                                                                  header.toLowerCase() === "is_deduction" ? (
                                                                    <TableCell key={header} className={styles.cell}>
                                                                      {item[header.toLowerCase()] ? ("Revenue") : ("Expense")}
                                                                    </TableCell>
                                                                  ) : (
                                                                    header.toLowerCase() === "duration" ? (
                                                                      <TableCell key={header} className={styles.amount}>
                                                                        {item[header.toLowerCase()] && item[header.toLowerCase()] > 0 ? (parseFloat(item[header.toLowerCase()]).toLocaleString(navigator.language, {minimumFractionDigits: 1})) : ("-")}
                                                                      </TableCell>
                                                                    ) : (
                                                                      header.toLowerCase() === "filed_at" || header.toLowerCase() === "paid_at" || header.toLowerCase() === "due_date" ? (
                                                                        <TableCell key={header}
                                                                                  className={styles.filedAt}>
                                                                          {item[header.toLowerCase()] ? (new Date(item[header.toLowerCase()]).toLocaleDateString('en-us', {
                                                                            year: 'numeric',
                                                                            month: '2-digit',
                                                                            day: '2-digit'
                                                                          })) : ("n/a")}
                                                                        </TableCell>
                                                                      ) : (
                                                                        header.toLowerCase() === "name" && this.props.model.toLowerCase() === "transaction" ? (
                                                                          <TableCell key={header}
                                                                                    className="transaction-name">
                                                                            {item[header.toLowerCase()] ? (item[header.toLowerCase()]) : ("n/a")}
                                                                          </TableCell>

                                                                        ) : (
                                                                          header.toLowerCase() === "discount" ? (
                                                                            <TableCell key={header}
                                                                                      className={styles.amount}>
                                                                              {item[header.toLowerCase()] ? (parseFloat(item[header.toLowerCase()]) + '%') : ("n/a")}
                                                                            </TableCell>
                                                                          ) : (
                                                                            header.toLowerCase() === "remarks" ? (
                                                                              <TableCell key={header}
                                                                                        className={styles.remarks}>
                                                                                {item[header.toLowerCase()] ? (item[header.toLowerCase()]) : ("n/a")}
                                                                              </TableCell>

                                                                            ) : (
                                                                              header.toLowerCase() === "rate_type" ? (
                                                                                <TableCell key={header}
                                                                                          className={styles.rateType}>
                                                                                  {item[header.toLowerCase()] ? (item[header.toLowerCase()]) : ("n/a")}
                                                                                </TableCell>

                                                                              ) : (
                                                                                header.toLowerCase() === "grand_total" ? (
                                                                                  <TableCell key={header}
                                                                                            className={styles.amount}>
                                                                                    {item[header.toLowerCase()] ? (parseFloat(item[header.toLowerCase()]).toLocaleString(navigator.language, {minimumFractionDigits: 2})) : ("-")}
                                                                                  </TableCell>

                                                                                ) :
                                                                                header.toLowerCase() === "title" ? (
                                                                                  <TableCell key={header}>
                                                                                    {
                                                                                      item.file ?
                                                                                      <a href={item.file.url} target="_blank" rel="noreferrer">
                                                                                        {item[header.toLowerCase()] ? item[header.toLowerCase()] : ("-")}
                                                                                      </a> :
                                                                                      item[header.toLowerCase()] ? item[header.toLowerCase()] : ("-")
                                                                                    }
                                                                                  </TableCell>

                                                                                ) : (
                                                                                  header.toLowerCase() === "case" && this.props.model.toLowerCase() === 'document' ? (

                                                                                    item.legal_case ? (
                                                                                      <TableCell key={header}
                                                                                                className={styles.cellCase}>
                                                                                        {item.legal_case.title ? (
                                                                                          <Link
                                                                                            id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_caselink`}
                                                                                            name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_caselink`}
                                                                                            to={{
                                                                                              pathname: `cases/${item.legal_case.uid}`,
                                                                                            }}>{item.legal_case.title}</Link>
                                                                                        ) : ("-")}
                                                                                      </TableCell>

                                                                                    ) : (
                                                                                      item.folder ? (
                                                                                        <TableCell key={header}
                                                                                                  className={styles.cell}>
                                                                                          {item.folder.legal_case ? (
                                                                                            <Link
                                                                                              id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_caselink`}
                                                                                              name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_caselink`}
                                                                                              to={{
                                                                                                pathname: `cases/${item.folder.legal_case.uid}`,
                                                                                              }}>{item.folder.legal_case.title}</Link>
                                                                                          ) : (item.folder.name)}
                                                                                        </TableCell>

                                                                                      ) : (
                                                                                        <TableCell key={header}
                                                                                                  className={styles.cell}>
                                                                                          -
                                                                                        </TableCell>

                                                                                      )

                                                                                    )

                                                                                  ) : (
                                                                                    header.toLowerCase() === "folder" ? (
                                                                                      item.folder ? (
                                                                                        <TableCell key={header}
                                                                                                  className={styles.cell}>
                                                                                          {(
                                                                                            <Link
                                                                                              id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_caselink`}
                                                                                              name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_caselink`}
                                                                                              to={{
                                                                                                pathname: `cases/${item.folder.legal_case.uid}`,
                                                                                                tabNumber: 3,
                                                                                                folder: item.folder
                                                                                              }}>{item.folder.path_name ? item.folder.path_name : item.folder.name}</Link>
                                                                                          )}
                                                                                        </TableCell>

                                                                                      ) : (
                                                                                        <TableCell key={header}
                                                                                                  className={styles.cell}>
                                                                                          -
                                                                                        </TableCell>

                                                                                      )
                                                                                    ) : (
                                                                                      header === "permissions_user" ? (
                                                                                        item.employee_name.length > 0 ? (
                                                                                          <TableCell key={header} className={styles.employeeNameWidth}>
                                                                                            {item.employee_name.map((name) => (
                                                                                                <li key={name.uid}> {name.last_name} {name.first_name}</li>
                                                                                              )
                                                                                            )}
                                                                                          </TableCell>
                                                                                        ):<TableCell> </TableCell>
                                                                                      ) : (
                                                                                        header.toLowerCase() === "color" ? (
                                                                                            <TableCell key={header} className={styles.cell}>
                                                                                              <div style={{display:'flex',alignItems:'center'}}><FlagIcon style={{color: item[header.toLowerCase()] ? (item[header.toLowerCase()]) : ("black") }} />{item[header.toLowerCase()] ? (item[header.toLowerCase()]) : ("n/a")}</div>
                                                                                            </TableCell>
                                                                                        ) : (
                                                                                          header.toLowerCase() === "email" || header.toLowerCase() === "username" ? (
                                                                                            <TableCell key={header}
                                                                                                  className={styles.emailCell}>
                                                                                          {item[header.toLowerCase()] ? (item[header.toLowerCase()]) : ("n/a")}
                                                                                        </TableCell>
                                                                                        ) : (
                                                                                        <TableCell key={header}
                                                                                                  className={styles.cell}>
                                                                                          {item[header.toLowerCase()] ? (item[header.toLowerCase()]) : ("n/a")}
                                                                                        </TableCell>

                                                                                      )

                                                                                      )
                                                                                    )
                                                                                    )

                                                                                  )

                                                                                )
                                                                              )
                                                                            )
                                                                          )
                                                                        )
                                                                      )
                                                                    )
                                                                  )
                                                                )

                                                              )
                                                            )
                                                        )


                                                      )
                                                    )
                                                  )
                                                )
                                              )
                                            )
                                          )
                                        )
                                      )
                                    )

                                  )
                                ))
                              ) : (
                                null
                              )
                            }
                            <ActionButtons
                              item={item}
                              model={this.props.model}
                              onOpenDeletePopup={this.props.onOpenDeletePopup}
                              handleClick={this.props.handleClick}
                              onclick={this.handleChildClick}
                              withEdit={this.props.withEdit}
                              withShow={this.props.withShow}
                              withShowPopUp={this.props.withShowPopUp}
                              handleShowPopUp={this.props.handleShowPopUp}
                            />
                          </TableRow>
                        </>
                      ))
                  }
                </TableBody>
              </Table>
          </TableContainer>
      </>
    )
  }
}

export default SimpleTable

SimpleTable.propTypes = {
  model: PropTypes.string.isRequired,
  headers: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  onOpenDeletePopup: PropTypes.func,
  handleClick: PropTypes.func,
  initialItem: PropTypes.object,
  item: PropTypes.object,
  users: PropTypes.array,
  clients: PropTypes.array,
  practiceAreas: PropTypes.array,
  username: PropTypes.array,
  withShow: PropTypes.bool,
  icon: PropTypes.object,
  withEdit: PropTypes.bool,
  withShowPopUp: PropTypes.bool,
  withSearch: PropTypes.bool,
  customClassName: PropTypes.string,
  location: PropTypes.string,
  handleShowPopUp: PropTypes.func,
  handleAdvanced: PropTypes.func,
  handleChanges: PropTypes.func,
  _handleKeyPress: PropTypes.func,
  withTotalAmount: PropTypes.bool,
  withBulkInvoice: PropTypes.bool,
  handleCreateBulkInvoice: PropTypes.func,
  clearSearch: PropTypes.func,
  q: PropTypes.string,
  prevPath: PropTypes.string,
  withPagination: PropTypes.bool,
  handlePageClick: PropTypes.func,
  pageCount: PropTypes.number,
  displayedPages: PropTypes.number,
  displayedRange: PropTypes.number,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  page: PropTypes.number,
  changeIndex: PropTypes.func,
  showState: PropTypes.bool,
  withStateButton: PropTypes.bool,
  withCalendar: PropTypes.bool,
  handlePageChange: PropTypes.func,
  toggleView: PropTypes.func,
  removeButtonAdd: PropTypes.func,
  removeLabel: PropTypes.bool
}
