import React from 'react'

import PropTypes from 'prop-types'
import styles from './Department.module.css'

import {
  TextField,
  Button,
} from '@material-ui/core'

class DepartmentForm extends React.Component {
  render () {
    return (
      <div>
        <form>
          <TextField
            autoComplete="off"
            variant="outlined"
            className={styles.textFields}
            size="small"
            name="name"
            fullWidth
            label="Name"
            onChange={this.props.onchange}
            value={this.props.item.name}
            id="department-name"
          />
          <p className={styles.error}>{this.props.error}</p>
        </form>
        <div className={styles.actionButton}>
          <Button variant="outlined" disabled={!this.props.item.name} id="department-submit" color="primary" onClick={() => this.props.submit()} >Save</Button>
        </div>
      </div>
    )
  }
}
export default DepartmentForm

DepartmentForm.propTypes = {
  classes: PropTypes.object,
  onchange: PropTypes.func,
  item: PropTypes.object,
  children: PropTypes.object,
  error: PropTypes.string,
  submit: PropTypes.func
}
