import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { TextField, Button, ButtonGroup, IconButton, Typography } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import moment from 'moment'
import styles from './Comments.module.css'
import SimpleDeletePopUp from "../../../shared/SimpleDeletePopUp/SimpleDeletePopUp";
class Comments extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            comments: [],
            comment: {},
            load: false
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleDeleteItem = this.handleDeleteItem.bind(this)
        this.handleItem = this.handleItem.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    }


    componentDidMount() {
        if (this.props.uid) {
            this.loadComments(this.props.uid)
        }
    }

    handleSubmit() {
        const item = this.state.comment
        var method = ''
        var url = ''
        if (item.uid === undefined) {
            method = 'post'
            url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.uid + '/remarks'
        } else {
            method = 'put'
            url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.uid + '/remarks/' + item.uid
        }
        axios({
            method: method,
            url: url,
            data: (item),
            headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then(resp => {
            const newData = resp.data
            this.setState({
                comments: this.state.comments.filter(comment => comment.uid !== item.uid)
            })
            this.setState({ comments: [...this.state.comments, newData] })
            this.setState({ comment: { content: '' } })
            this.setState({
                isOpen: true,
                message: 'Submitted Successfully',
                type: 'success'
            })
        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
    }

    handleDeleteItem() {
        const DeleteItemId = this.state.comment.uid
        axios({
            method: 'delete',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.uid + '/remarks/' + DeleteItemId,
            headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then(() => {
            this.setState({
                comments: this.state.comments.filter(item => item.uid !== DeleteItemId)
            })
            this.setState({ comment: { content: '' } })
            this.setState({ openDeletePopup: false })
            this.setState({
                isOpen: true,
                message: 'Deleted Successfully',
                type: 'error'
            })
        }).catch(error => console.log(error.response))
    }

    handleChange(e) {
        this.setState({ comment: { ...this.state.comment, content: e.target.value } })
    }


    loadComments(uid) {
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + uid + '/remarks',
            headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ comments: resp.data, load: true })
        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({ isOpen: true, message: " Unexpected Error Occurred", type: 'error' })
            }
        })
    }

    handleItem(item) {
        this.setState({ comment: item })
    }

    handleClose() {
        this.setState({ openDeletePopup: false })
    }

    handleOpenDeletePopup(item) {
        this.setState({ comment: item })
        this.setState({ openDeletePopup: true })
    }

    render() {

        return (
            this.state.load ?
                <>
                    {
                        this.state.comments.length > 0 ?
                            this.state.comments.sort((a, b) => { return moment(b.created_at) - moment(a.created_at) }).reverse().map((comment) => (
                                <ul key={comment.uid} className={styles.index}>

                                    <div className={styles.parent}>
                                        <div style={{ maxWidth: '80%' }}>
                                            <Typography variant="body1" style={{ fontWeight: 'bold' }}>{comment.content}</Typography>
                                            <Typography variant="caption" style={{ fontStyle: 'italic' }}>{comment.commenter}-{moment(comment.created_at).format('L')}</Typography>
                                        </div>
                                        <div className={styles.button}>
                                            <ButtonGroup >
                                                <IconButton color="primary" onClick={() => this.handleItem(comment)}><EditIcon /></IconButton>
                                                <IconButton color="secondary" onClick={() => this.handleOpenDeletePopup(comment)}><DeleteIcon /></IconButton>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                    <hr />
                                </ul>
                            ))
                            : <div className={styles.emptyBody}>No comments yet for this case.</div>}
                    <TextField multiline onChange={this.handleChange} className={styles.textfield} value={this.state.comment.content} row={5} type="text" variant="outlined" fullWidth placeholder="Add comment" />
                    <Button style={{ float: 'right', borderRadius: '20px', marginTop: '2px' }} onClick={this.handleSubmit} variant="contained" color="primary">Save</Button>

                    <SimpleDeletePopUp
                        openDeletePopup={this.state.openDeletePopup}
                        item={this.state.comment}
                        delete={this.handleDeleteItem}
                        handleDeleteClose={this.handleClose}
                        model='Comment'
                    />
                </>
                : null
        )
    }
}

export default Comments

Comments.propTypes = {
    uid: PropTypes.string
}
