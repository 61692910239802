import React from 'react'
import PropTypes from 'prop-types'
import {
  TextField,
  Button,
} from '@material-ui/core'
import styles from "./Document.module.css";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import style from "../LegalCases/LegalCase.module.css";
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';

class DocumentForm extends React.Component {
  render () {
    const { file } = this.props.document;
    let filename = null;
    filename = file
      ? ( <span><b>File Selected</b> - {this.props.isUpdateForm ? this.props.document.file.name ? this.props.document.file.name : this.props.document.title : file.name}</span>)
      : ( <span>Drag your files here or click in this area.</span> );
    return (
      <div>
        <form>
          <div>
            <Autocomplete
              {
                ...(this.props.isUpdateForm && { defaultValue: this.props.legalCaseDefaultValue})
              }
              id="legal_case"
              size="small"
              options={this.props.legalCase}
              getOptionLabel={(option) => option.title || ''}
              getOptionSelected={(option, value) => option.title === value.title}
              onChange={this.props.handleCasesAutoComplete}
              fullWidth
              name="case"
              renderInput={(params) => <TextField {...params} label="Cases" name="case" variant="outlined" />}
            />

          </div>
          <br />
          <div>
            <Autocomplete
              {
                ...(this.props.isUpdateForm && { defaultValue: this.props.caseUsersDefaultValue })
              }
              multiple
              size="small"
              options={this.props.caseUsers.length>0?(this.props.caseUsers):(this.props.users)}
              getOptionLabel={(option) => option.username || ''}
              getOptionSelected={(option, value) => option.username === value.username}
              onChange={this.props.handleUsersAutocomplete}
              fullWidth
              name="case"
              renderInput={(params) => <TextField {...params} label="Users" name="user" variant="outlined" />}
            />
          </div>

          {this.props.legalCaseId !== '' ? (
          <div style={{ marginTop: "10px" }}>
            <Autocomplete
              {
                ...(this.props.isUpdateForm && { defaultValue: this.props.folderDocumentsDefaultValue })
              }
              id="folders"
              size="small"
              name="folder"
              options={this.props.folderDocuments}
              getOptionLabel={(option) => option.path_name ||  option.name || ''}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={this.props.handleFolderAutoComplete}
              fullWidth
              renderInput={(params) => <TextField {...params} label="Folders" name="folder" variant="outlined" />}
            />

          </div>
          ): null}
          <div className={styles.formBody}>
            <input
              id="file"
              type="file"
              name="file"
              className={style.input}
              onChange={(e) => this.props.onFileChange(e)}
            />
            <label
              className={style.filelabel}
              htmlFor="file">
              <VerticalAlignBottomIcon fontSize="large"/>
              <br/>
              {filename}
            </label>
            <p className={styles.fileError}><b>{this.props.error}</b></p>
          </div>
        </form>
        <div className={styles.actionButton}>
          <Button variant="outlined" name="submit-document" color="primary" onClick={() => this.props.submit()} >Save</Button>
        </div>
      </div>
    )
  }
}
export default DocumentForm

DocumentForm.propTypes = {
  onchange: PropTypes.func,
  item: PropTypes.object,
  legalCase: PropTypes.array,
  folderDocuments: PropTypes.array,
  children: PropTypes.object,
  document: PropTypes.object,
  handleCasesAutoComplete: PropTypes.func,
  handleFolderAutoComplete: PropTypes.func,
  error: PropTypes.func,
  legalCaseId: PropTypes.func,
  onFileChange: PropTypes.func,
  submit: PropTypes.func,
  handleUsersAutocomplete: PropTypes.func,
  users: PropTypes.array,
  caseUsers: PropTypes.array,
  members: PropTypes.array,
  documentUsers: PropTypes.array,
  isUpdateForm: PropTypes.bool,
  legalCaseDefaultValue: PropTypes.object,
  caseUsersDefaultValue: PropTypes.object,
  folderDocumentsDefaultValue: PropTypes.object
}
