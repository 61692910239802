import { Container } from '@material-ui/core'
import React, { Component } from 'react'
import Error500Image from '../../assets/images/500_error.svg'
import styles from './Error500.module.css'

class Error500 extends Component {
  render() {
    return (
      <Container className={styles.mainContainer}>
        <Container className={styles.errorContentContainer}>
          <div className={styles.errorHeaderContainer}>
            <img
              src={Error500Image}
              alt="Error 500"
              className={styles.errorImage}
            />
            <div className={styles.errorMessageContainer}>
              <h1 className={styles.errorTitle}>500</h1>
              <h2 className={styles.errorSubtitle}>Internal Server Error</h2>
            </div>
          </div>

          <div className={styles.errorBodyContainer}>
            <p>Oops, looks like something went wrong.</p>
            <p>
              Try to refresh the page or feel free to <span>contact us</span> if
              the problem persists.
            </p>
          </div>
        </Container>
      </Container>
    )
  }
}

export default Error500
