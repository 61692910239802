import React from 'react'

import axios from 'axios'

import PropTypes from 'prop-types'

import SimpleTable from '../../../shared/SimpleTable/SimpleTable'

class Tasks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tasks: [],
      model: "Task",
      headers: ["name", "description", "starts_at", "ends_at"],
      openPopUp: false,
      title: "Tasks",

    }
    this.loadWorkflow = this.loadWorkflow.bind(this)

  }
  componentDidMount() {
    this.loadWorkflow(process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.props.uid + '/tasks')
  }

  loadWorkflow(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ tasks: resp.data })
      this.setState({ load: true })
    }).catch(error => console.log(error.response))
  }

  render() {
    return (
      <div>{
        this.state.load ? (
          <div>
            <SimpleTable
              removeButtonAdd={this.props.addButtonNone}
              removeLabel={true}
              items={this.state.tasks}
              model={this.state.model}
              headers={this.state.headers}
            />

          </div>
        ) : (null)
      }</div>
    )
  }
}
export default Tasks
Tasks.propTypes = {
  uid: PropTypes.string,
  addButtonNone: PropTypes.bool,
}
