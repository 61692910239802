import React from 'react'

import PropTypes from 'prop-types'

import styles from './Workflow.module.css'


import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import TableContainer from "@material-ui/core/TableContainer";

class EventTable extends React.Component {
  constructor(props) {
    super(props)
    this.getName = this.getName.bind(this)
  }

  getName(uid) {
    var labelName
    if (this.props.triggers.length > 0) {
      this.props.triggers.map((trigger) => {
        if (trigger.uid === uid) {
          labelName = trigger.name
        }
      }
      )
      return labelName
    }
  }

  render() {

    return (
      <>
        <div className={styles.tableTitle}>
          <div className={styles.tileBody}>
            {this.props.icon}
            <h2 className={styles.title} >
              {this.props.model}
            </h2>
          </div>
          {this.props.headers.map((header) => (
            header.toLowerCase().includes('actions') ? (
              <div key={header}>
                <IconButton
                  color="inherit"
                  aria-label="menu"
                  name="event-template-add"
                  id="event-template-add"
                  onClick={() => this.props.onclick(this.props.initialItem, true, this.props.model)}>
                  <AddCircleOutlineIcon fontSize="large" />
                </IconButton>
              </div>
            ) : null))}
        </div>
        <TableContainer style={{ maxHeight: '575px' }}>
          <Table size="small" stickyHeader aria-label="customized sticky table" name="event_template-table">
            <TableHead>
              <TableRow>
                {
                  this.props.headers.length > 0 ? (
                    this.props.headers.map((header) => (
                      header === 'Actions' ? (
                        <TableCell key={header} className={styles.cell} align="right"><strong>{header}</strong></TableCell>
                      ) : (
                        <TableCell key={header} className={styles.cell}><strong>{header}</strong></TableCell>
                      )
                    ))
                  ) : (
                    null
                  )
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.props.items.sort(
                  (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
                ).reverse()
                  .map((item) => (
                    <TableRow key={item.name}>
                      <TableCell className={styles.cell} >{item.name}</TableCell>
                      <TableCell className={styles.cell} ><span>{item.interval}</span> <span>
                        {item.interval > 1 ?
                          (<>{item.interval_type === 'weekdays' ? ("weekdays") : ("calendar days")}</>) : (<>{item.interval_type === 'weekdays' ? ("weekday") : ("calendar day")}</>)
                        }</span> <span>{item.interval_event}</span> <span>{this.getName(item.workflow_uid)}</span>
                      </TableCell>
                      <TableCell className={styles.cell}>{item.duration ? (<><span>{item.duration} </span> <span> {item.duration > 1 ? (item.duration_type) : (item.duration_type.slice(0, -1))}</span></>) : (<>no duration</>)}</TableCell>
                      <TableCell className={styles.cell} align="right">
                        <ButtonGroup>
                          <IconButton
                            size="small"
                            color="secondary"
                            name={`event-template-delete`}
                            id={`event-template-delete`}
                            onClick={() => this.props.onOpenDeletePopup(item, this.props.model)}
                          >
                            <DeleteIcon />
                          </IconButton>
                          {
                            this.props.withEdit ? (
                              <IconButton
                                size="small"
                                color="primary"
                                name={`event-template-edit`}
                                id={`event-template-edit`}
                                onClick={() => this.props.onclick(item, false, this.props.model)}>
                                <EditIcon />
                              </IconButton>

                            ) : (
                              null
                            )
                          }

                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </>

    )
  }
}
export default EventTable

EventTable.propTypes = {
  model: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  headers: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  onOpenDeletePopup: PropTypes.func,
  onclick: PropTypes.func,
  initialItem: PropTypes.object,
  withShow: PropTypes.bool,
  withEdit: PropTypes.bool,
  icon: PropTypes.func,
  triggers: PropTypes.array.isRequired
}


