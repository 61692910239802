import React from 'react'
import PropTypes from 'prop-types'
import styles from './Workflow.module.css'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import AddBoxIcon from '@material-ui/icons/AddBox'
import DeleteIcon from '@material-ui/icons/Delete'

class WorkflowForm extends React.Component {
  render() {
    return (
      <div>
        <TextField
          autoComplete="off"
          className="formTextfield"
          id="workflow-name"
          variant="outlined"
          size="small"
          name="name"
          fullWidth
          label="Name"
          onChange={this.props.handleInputChange}
          value={this.props.workflow.name}
        />
        {
          this.props.createOrUpdateWorkflow === 'create' ?
          <>
          <div className={styles.formControl}>
            <Typography variant="body1" className={styles.typographyMargin}>
              Date Label Triggers
            </Typography>
            <IconButton
              color="primary"
              onClick={this.props.addTrigger}
              id="add-event-trigger"
            >
              <AddBoxIcon/>
            </IconButton>
          </div>
          <div>
            {
              this.props.triggers.map((val, idx) => (
                <div key={idx} className={styles.formControl}>
                  <TextField
                    autoComplete="off"
                    size="small"
                    fullWidth
                    className={styles.textfield}
                    variant="outlined" required
                    name={'name-' + idx}
                    id={'workflow-name-' + idx}
                    data-idx={idx}
                    value={this.props.triggers[idx].name}
                    onChange={this.props.handleTriggers}
                    label={`Event Trigger ${idx + 1}`}
                    inputProps={{'data-id': idx, 'data-field-type': 'name'}}
                  />
                  <div>
                    {
                      idx > 0 ? 
                      (<IconButton
                        color="secondary"
                        onClick={() => this.props.removeTrigger(idx)}>
                        <DeleteIcon/>
                      </IconButton>) : 
                      (null)
                    }
                  </div>
                </div>
              ))
            }
          </div>
          </>
          : null
        }
        
        <div className={styles.actionButton}>
          <Button disabled={!this.props.workflow.name} variant="outlined" color="primary" name="submit"
                  id='workflow-submit' onClick={this.props.handleSubmit}>
            <div>SAVE</div>
          </Button>
        </div>
      </div>
    )
  }
}

export default WorkflowForm

WorkflowForm.propTypes = {
  handleInputChange: PropTypes.func,
  workflow: PropTypes.object,
  triggers: PropTypes.array,
  addTrigger: PropTypes.func,
  handleTriggers: PropTypes.func,
  removeTrigger: PropTypes.func,
  handleSubmit: PropTypes.func,
  createOrUpdateWorkflow: PropTypes.string
}
