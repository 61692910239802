import React from 'react'

import PropTypes from 'prop-types'

import styles from './ExpenseType.module.css'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import ButtonGroup from '@material-ui/core/ButtonGroup'

import VisibilityIcon from '@material-ui/icons/Visibility'
import DeleteIcon from '@material-ui/icons/Delete'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import EditIcon from '@material-ui/icons/Edit'
import TableContainer from "@material-ui/core/TableContainer";


class ExpenseTable extends React.Component {

  render() {

    return (
      <>
        <>
          <div className={styles.tableTitle}>
            <div className={styles.tileBody}>

              <h2 className={styles.title}>
                <div className={styles.head}>
                  Transaction Categories
                </div>
              </h2>
            </div>

            {
              <IconButton color="inherit" aria-label="menu"
                          name="transaction_type-add"
                          onClick={() => this.props.handleClick(this.props.initialItem, true, this.props.model)}>
                <AddCircleOutlineIcon fontSize="large"/>
              </IconButton>
            }
          </div>
          <TableContainer style={{maxHeight: '575px', position: 'relative'}}>
            <Table size="small" name="transaction_type-table">
              <TableHead>
                <TableRow>
                  {
                    this.props.headers.length > 0 ? (
                      this.props.headers.map((header) => (
                        header.toLowerCase() === 'actions' ? (
                          <TableCell key={header} className={styles.actionCell} align="center"><strong>{header}</strong></TableCell>
                        ) : (
                          <TableCell key={header} className={styles.cell}><strong>{header}</strong></TableCell>
                        )
                      ))
                    ) : (
                      null
                    )
                  }
                </TableRow>
              </TableHead>
              <TableBody className={styles.tableBody}>

                {this.props.items.length > 0 ? (
                  this.props.items.sort(
                    (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
                  ).reverse()
                    .map((expense) => (
                      expense.parent_uid === null &&
                      expense.is_deduction === this.props.isDeduction &&
                      <TableRow key={expense.uid}>
                        <TableCell className={styles.cell}>{expense.name}</TableCell>
                        <TableCell>

                          {
                            this.props.items.length > 0 ? (
                              this.props.items
                                .map((child) => (
                                  child.parent_uid === expense.uid &&
                                  <li>{child.name}</li>
                                ))
                            ) : (null)

                          }
                        </TableCell>
                        <TableCell className={styles.actionButtonHoverable}>
                          <ButtonGroup edge="end">

                            <IconButton
                              color="primary"
                              size="small"
                              name={`transaction_type-showpopup`}
                              onClick={() => this.props.handleOpenShowPopUp(expense)} className="button muted-button"
                            >
                              <VisibilityIcon/>
                            </IconButton>
                            <IconButton
                              color="primary"
                              size="small"
                              name={`transaction_type-edit`}
                              onClick={() => this.props.handleClick(expense, true, this.props.model)}
                            >
                              <EditIcon/>
                            </IconButton>

                            <IconButton
                              color="secondary"
                              size="small"
                              name={`transaction_type-delete`}
                              id={`transaction_type-delete`}
                              onClick={() => this.props.onOpenDeletePopup(expense)} className="button muted-button"
                            >
                              <DeleteIcon/>
                            </IconButton>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  null
                )}

              </TableBody>
            </Table>
          </TableContainer>

        </>
      </>

    )
  }
}

export default ExpenseTable

ExpenseTable.propTypes = {
  onOpenDeletePopup: PropTypes.func,
  handleClick: PropTypes.func,
  headers: PropTypes.array,
  items: PropTypes.array,
  initialItem: PropTypes.object,
  model: PropTypes.string,
  isDeduction: PropTypes.bool,
  handleOpenShowPopUp: PropTypes.func
}


