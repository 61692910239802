import React from 'react'
import axios from 'axios'
import { Redirect } from 'react-router-dom'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import PermissionForm from './PermissionForm'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import AlertMessage from '../../shared/Notify/AlertMessage'
import ProgressBar from '../../shared/ProgressBar/ProgressBar'
import ErrorPage from '../../shared/ErrorPage/ErrorPage'

class Permissions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Permission',
      permissions: [],
      users: [],
      roles: [],
      checkedUsers: [],
      updateUsers: [],
      updateRoles: [],
      openPopup: false,
      openDeletePopup: false,
      permission: {},
      initialPermission: {
        uid: '',
        name: '',
        description: '',
        user_ids: [],
        role_ids: []
      },
      title: '',
      load: false,
      redirectToAdd: false,
      redirectToAddOrEdit: false,
      tableHead: ['Name', 'Description', 'permissions_user'],
      withShow: true,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      isOpen: false,
      message: '',
      type: '',
      loadPermissionsError: null
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleUsersAutocomplete = this.handleUsersAutocomplete.bind(this)
  }

  componentDidMount() {
    this.loadPermissions(process.env.REACT_APP_API_DOMAIN + '/v1/permissions')
  }

  async loadPermissions(url) {
    await axios({
      method: 'get',
      url: url,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        var newArray = []
        this.setState({ permissions: resp.data })
        resp.data.map((account) => {
          var item = account
          axios({
            method: 'get',
            url:
              process.env.REACT_APP_API_DOMAIN +
              '/v1/permissions/' +
              account.uid +
              '/users',
            headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
          })
            .then((resp) => {
              item['employee_name'] = resp.data
              newArray.push(item)
              this.setState({
                permissions: newArray.sort(function (a, b) {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
                  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
                  return 0
                }),
                load: true
              })
            })
            .catch((error) =>
              this.setState({ loadPermissionsError: error.response.status })
            )
        })
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.setState({
            isOpen: true,
            message: 'Unauthorized Access',
            type: 'error'
          })
        }

        this.setState({ loadPermissionsError: error.response.status })
      })
  }

  handleSubmit() {
    const item = {
      uid: this.state.permission.uid,
      name: this.state.permission.name,
      description: this.state.permission.description
    }
    var userIds = []
    if (this.state.updateUsers.length > 0) {
      this.state.updateUsers.map((user) => {
        userIds.push(user.uid)
      })
    }
    item['user_ids'] = userIds

    var roleIds = []
    if (this.state.updateRoles.length > 0) {
      this.state.updateRoles.map((role) => {
        roleIds.push(role.uid)
      })
    }
    item['role_ids'] = roleIds
    console.log(item)
    var method = ''
    var url = ''
    if (item.uid === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/permissions/'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/permissions/' + item.uid
    }
    axios({
      method: method,
      url: url,
      data: item,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        const newData = resp.data
        this.setState({
          permissions: this.state.permissions.filter(
            (permission) => permission.uid !== item.uid
          )
        })
        this.setState({ permissions: [...this.state.permissions, newData] })
        this.setState({
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success'
        })
        this.setState({ openPopup: false })
      })
      .catch((error) => {
        if (error.response.status === 422) {
          this.setState({
            error_messages: 'Name ' + error.response.data.errors.name[0]
          })
        } else {
          this.setState({
            isOpen: true,
            message:
              error.response.status.toString() +
              ' Unexpected Error Problem Occurred',
            type: 'error'
          })
          this.setState({ openPopup: false })
        }
      })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.permission.uid
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/permissions/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then(() => {
        this.setState({
          permissions: this.state.permissions.filter(
            (item) => item.uid !== DeleteItemId
          )
        })
        this.setState({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'error'
        })
        this.setState({ openDeletePopup: false })
      })
      .catch((error) => console.log(error.response))
  }

  handleClose() {
    this.setState({ openPopup: false })
    this.setState({ openDeletePopup: false })
    this.setState({ error_messages: '' })
  }

  handleCreateorUpdateItem(item, isAdd) {
    if (isAdd) {
      this.setState({ redirectToAdd: true, redirectToAddOrEdit: isAdd })
    } else {
      this.setState({ redirectToAdd: true, permission: item })
    }
  }

  handleInputChange(e) {
    this.setState({
      permission: {
        ...this.state.permission,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ permission: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    this.setState({
      isOpen: false
    })
  }

  handleUsersAutocomplete(event, values) {
    if (values !== null) {
      if (event.target.id.includes('user_ids')) {
        this.setState({ updateUsers: values })
      } else {
        this.setState({ updateRoles: values })
      }
    }
  }

  render() {
    const { load, loadPermissionsError } = this.state

    if (this.state.redirectToAdd) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirectToAddOrEdit
              ? '/permissions/new'
              : `/permissions/${this.state.permission.uid}`
          }}
        />
      )
    }
    return (
      <>
        {load && !loadPermissionsError ? (
          <div>
            <SimpleTable
              handleClick={this.handleCreateorUpdateItem}
              initialItem={this.state.initialPermission}
              items={this.state.permissions}
              model={this.state.model}
              onOpenDeletePopup={this.handleOpenDeletePopup}
              headers={this.state.tableHead}
              withShow={this.state.withShow}
              withEdit={this.state.withEdit}
            />
            <SimplePopUp
              openPopup={this.state.openPopup}
              title={this.state.title}
              items={this.state.permissions}
              handleClose={this.handleClose}
              maxWidth={this.state.maxWidth}
            >
              <PermissionForm
                users={this.state.users}
                updateUsers={this.state.updateUsers}
                roles={this.state.roles}
                updateRoles={this.state.updateRoles}
                handleUsersAutocomplete={this.handleUsersAutocomplete}
                error={this.state.error_messages}
                item={this.state.permission}
                submit={this.handleSubmit}
                onchange={this.handleInputChange}
              />
            </SimplePopUp>

            <SimpleDeletePopUp
              openDeletePopup={this.state.openDeletePopup}
              item={this.state.permission}
              delete={this.handleDeleteItem}
              handleDeleteClose={this.handleClose}
              model={this.state.model}
            />
            <AlertMessage
              notify={this.state.notify}
              handleCloseAlert={this.handleCloseAlert}
              isOpen={this.state.isOpen}
              type={this.state.type}
              message={this.state.message}
            />
          </div>
        ) : loadPermissionsError ? (
          <ErrorPage type={loadPermissionsError} />
        ) : (
          <ProgressBar model={this.state.model} />
        )}
      </>
    )
  }
}

export default Permissions
