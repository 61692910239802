// eslint-disable-next-line
import styles from './Contact.css'

import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography'

class Contact extends Component {
  render () {
    return (
      <Typography paragraph>
        Contact
      </Typography>
    )
  }
}

export default Contact
