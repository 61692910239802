import React from 'react'

import PropTypes from 'prop-types'
import styles from './Permision.module.css'

import {
  TextField,
  Button,
} from '@material-ui/core'
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";

class PermissionForm extends React.Component {
  render() {
    return (
      <div>
        <form>
          <TextField
            autoComplete="off"
            variant="outlined"
            className={styles.textFields}
            size="small"
            name="name"
            fullWidth
            label="Name"
            onChange={this.props.onchange}
            value={this.props.item.name}
            id="department-name"
          />
          <p className={styles.error}>{this.props.error}</p>

          <TextField
            autoComplete="off"
            variant="outlined"
            className={styles.textFields}
            size="small"
            name="description"
            fullWidth
            label="Description"
            onChange={this.props.onchange}
            value={this.props.item.description}
            id="department-name"
          />

          <Autocomplete
            multiple
            value={this.props.updateUsers}
            id="user_ids"
            size="small"
            fullWidth
            name="user_ids"
            className={styles.formTextfield}
            options={this.props.users.sort(function (a, b) {
              if (a.username.toLowerCase() < b.username.toLowerCase()) return -1;
              if (a.username.toLowerCase() > b.username.toLowerCase()) return 1;
              return 0;
            })}
            getOptionLabel={(option) => option.username || ''}
            getOptionSelected={(option, value) => option.username === value.username}
            onChange={this.props.handleUsersAutocomplete}
            disableCloseOnSelect
            renderOption={(option, {selected}) => {
              return (
                <React.Fragment>
                  <Checkbox
                    style={{marginRight: 8}}
                    checked={selected}
                  />
                  {option.username}
                </React.Fragment>
              )
            }}
            renderInput={(params) => (
              <TextField {...params} name="user_ids" variant="outlined" label="Users"/>
            )}
          />

          <Autocomplete
            multiple
            value={this.props.updateRoles}
            id="roles_ids"
            size="small"
            fullWidth
            name="roles_ids"
            className={styles.formTextfield}
            options={this.props.roles.sort(function (a, b) {
              if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
              if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
              return 0;
            })}
            getOptionLabel={(option) => option.name + ' '+ option.resource_type || ''}
            getOptionSelected={(option, value) => option.uid === value.uid}
            onChange={this.props.handleUsersAutocomplete}
            disableCloseOnSelect
            renderOption={(option, {selected}) => {
              return (
                <React.Fragment>
                  <Checkbox
                    style={{marginRight: 8}}
                    checked={selected}
                  />
                  {option.name} {option.resource_type}
                </React.Fragment>
              )
            }}
            renderInput={(params) => (
              <TextField {...params} name="role_ids" variant="outlined" label="Roles"/>
            )}
          />

        </form>
        <div className={styles.actionButton}>
          <Button variant="outlined" disabled={!this.props.item.name || !this.props.updateUsers.length>0 } id="department-submit" color="primary"
                  onClick={() => this.props.submit()}>Save</Button>
        </div>
      </div>
    )
  }
}

export default PermissionForm

PermissionForm.propTypes = {
  classes: PropTypes.object,
  onchange: PropTypes.func,
  item: PropTypes.object,
  children: PropTypes.object,
  error: PropTypes.object,
  getDefaultValue: PropTypes.func,
  users: PropTypes.array,
  roles: PropTypes.array,
  updateRoles: PropTypes.array,
  updateUsers: PropTypes.array,
  handleUsersAutocomplete: PropTypes.func,
  submit: PropTypes.func
}
