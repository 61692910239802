import React from 'react'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  IconButton, Tooltip,Grid
} from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Collapse from '@material-ui/core/Collapse/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import styles from './LegalCase.module.css'
import AddBoxIcon from '@material-ui/icons/AddBox'

class SimpleForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      checkedUsers: this.props.item.user_uids,
      checkedClients: this.props.item.client_uids,
      selectedArea: this.props.item.practice_area_uid,
      checkedBiller: this.props.item.biller_uid,
    }
    this.getDefaultValue = this.getDefaultValue.bind(this)
    this.getClients = this.getClients.bind(this)
    this.getDefaultArea = this.getDefaultArea.bind(this)
    this.getBiller = this.getBiller.bind(this)
  }

  getDefaultValue() {
    var newArray = []
    if (this.props.item.user_uids === undefined) {
      if (this.props.users !== null) {
        this.props.users.map((user) => {
          this.props.item.user_ids.map((current) => {
            if (current === user.uid) {
              newArray.push(user)
            }
          })
        })
      }
    } else {
      this.props.users.map((user) => {
        this.state.checkedUsers.map((current) => {
          if (current === user.uid) {
            newArray.push(user)
          }
        })
      })
    }
    return newArray
  }

  getClients() {
    var newArray = []
    if (this.props.clients!== null) {
      if (this.state.checkedClients === undefined) {
        this.props.users.map((client) => {
          return client
        })
      } else {
        this.props.clients.map((client) => {
          this.state.checkedClients.map((current) => {
            if (current === client.uid) {
              newArray.push(client)
            }
          })
        })
      }
    }
    return newArray
  }

  getBiller() {
    var newArray = {}
    if (this.props.clients !== null) {
      if (this.state.checkedBiller === null) {
        this.props.users.map((client) => {
          return client
        })
      } else {
        this.props.clients.map((client) => {
          if (this.state.checkedBiller === client.uid) {
            newArray = client
          }
        })
      }
    }
    return newArray
  }

  getDefaultArea() {

    var newArea = {}
    if (this.props.practiceAreas !== null) {
      if (this.state.selectedArea === undefined) {
        this.props.practiceAreas.map((area) => {
          return area
        })
      } else {
        this.props.practiceAreas.map((area) => {
          if (this.state.selectedArea === area.uid) {
            newArea = area
          }
        })
      }

    }
    return newArea
  }

  render() {
    console.log(this.props.users)
    const setVal = (name) => {
      const attrName = name.toLowerCase().replace(' ', '_')
      if (this.props.cfield === null) {
        return null
      } else {
        if (this.props.cfield[attrName] !== undefined) {
          const value = this.props.cfield[attrName]
          return value
        }
      }
    }
    const setCustom = (field) => {
      if (field.attr_type === 'text') {
        return <><TextField
          variant="outlined"
          autoComplete={true}
          style={{textTransform: 'capitalize'}}
          required
          fullWidth
          size="small"
          label={field.attr_name}
          name={field.attr_name.toLowerCase().replace(' ', '_')}
          value={setVal(field.attr_name)}
          onChange={this.props.handleField}
          InputLabelProps={{classes:{asterisk: styles.asterisk}}}
        /><br/></>
      } else if (field.attr_type === 'number') {

        return <>
          <TextField
            style={{textTransform: 'capitalize'}}
            variant="outlined"
            required
            fullWidth
            size="small"
            label={field.attr_name}
            name={field.attr_name.toLowerCase().replace(' ', '_')}
            value={setVal(field.attr_name)}
            onChange={this.props.handleField}
            InputLabelProps={{classes:{asterisk: styles.asterisk}}}
          /><br/></>
      } else if (field.attr_type === 'date') {
        return <>
          <TextField
            fullWidth="true"
            id="ends_at"
            type="date"
            size="small"
            variant="outlined"
            style={{textTransform: 'capitalize'}}
            format="yyyy-MM-dd"
            label={field.attr_name}
            value={setVal(field.attr_name)}
            name={field.attr_name.toLowerCase().replace(' ', '_')}
            onChange={this.props.handleField}
            InputLabelProps={{
              shrink: true
            }}
          />
          <br/></>
      } else {
        return null
      }
    }

    return (
      <div className={styles.caeseformBody}>
        <form>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <TextField
                autoComplete="off"
                className={styles.formTextfield}
                id="standard-basic"
                variant="outlined"
                required
                size="small"
                name="title"
                fullWidth
                label="Case Title"
                onChange={this.props.onchange}
                value={this.props.item.title}
                InputLabelProps={{classes:{asterisk: styles.asterisk}}}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div>
                <TextField
                  autoComplete="off"
                  className={styles.formTextfield}
                  id="standard-basic"
                  variant="outlined"
                  size="small"
                  required={true}
                  name="reference_no"
                  fullWidth
                  label="Reference Number"
                  onChange={this.props.onchange}
                  value={this.props.item.reference_no}
                  InputLabelProps={{classes:{asterisk: styles.asterisk}}}
                />
                <p className={styles.error}>{this.props.error.reference_no? ("Reference No "+ this.props.error.reference_no): ''}</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                className={styles.formTextfield}
                id="standard-basic"
                variant="outlined"
                size="small"
                name="date_filed"
                fullWidth
                type="date"
                label="Date Filed"
                onChange={this.props.onchange}
                value={this.props.item.date_filed}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
              autoComplete="off"
              multiline={true}
              rows={5}
              className={styles.formTextfield}
              variant="outlined"
              fullWidth
              style={{ margin: 8 }}
              name="description"
              value={this.props.item.description}
              onChange={this.props.onchange}
              label="Description" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                id="user_name"
                size="small"
                fullWidth
                name="user_ids"
                options={this.props.users.sort(function(a, b) {
                  if(a.last_name.toLowerCase() < b.last_name.toLowerCase()) return -1;
                  if(a.last_name.toLowerCase() > b.last_name.toLowerCase()) return 1;
                  return 0;
                })}
                onChange={this.props.handleAutoComplete}
                disableCloseOnSelect
                getOptionLabel={(option) => option.last_name +", "+ option.first_name}
                defaultValue={this.getDefaultValue}
                renderOption={(option, { selected }) => {
                  return (
                    <React.Fragment>
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.last_name +", "+ option.first_name}
                    </React.Fragment>
                  )
                }}
                renderInput={(params) => (
                  <TextField {...params} name="user_ids" variant="outlined"  required label="Users" InputLabelProps={{classes:{asterisk: styles.asterisk}}} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={styles.practiceAreaBody}>
                <Autocomplete
                  value={this.props.defaultArea}
                  id="controllable-states-demo"
                  options={this.props.practiceAreas}
                  getOptionLabel={(option) => option.name || ''}
                  getOptionSelected={(option, value) => option.name === value.name}
                  onChange={this.props.handleAutoComplete}
                  renderInput={(params) => <TextField {...params} size="small" label="Practice Area" required  variant="outlined" InputLabelProps={{classes:{asterisk: styles.asterisk}}} />}
                />

              <Tooltip title="Add Practice Area" arrow>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={this.props.openPracticeAreaForm}
                  style={{padding: '14px'}}
                >
                  <AddBoxIcon/>
                </IconButton>
              </Tooltip>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                  multiple
                  id="client_name"
                  size="small"
                  options={this.props.clients}
                  onChange={this.props.handleAutoComplete}
                  getOptionLabel={(option) => option.name}
                  defaultValue={this.getClients}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Clients"
                      required
                      InputLabelProps={{classes:{asterisk: styles.asterisk}}}
                    />
                  )}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={styles.practiceAreaBody}>
                <div className={styles.practiceField}>
                <Autocomplete
                  multiple
                  id="counsel"
                  size="small"
                  options={this.props.opposingCounsel}
                  onChange={this.props.handleOpposingCounsel}
                  getOptionLabel={(option) => option.name}
                  value={this.props.selectedCounsel}
                  filterSelectedOptions
                  filterOptions={(options, state) => {
                    const inputValue = state.inputValue.toLowerCase();
                    const filtered = options.filter(option => option.name.toLowerCase().includes(inputValue));
                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Opposing Party"
                    />
                  )}
                />

                </div>
                <Tooltip title="Add Opposing Party" arrow>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={this.props.handleClientPopup}
                    style={{padding: '14px'}}
                  >
                    <AddBoxIcon/>
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Autocomplete
                  id="biller_name"
                  size="small"
                  style={{marginTop: '12px'}}
                  options={this.props.defaultBiller}
                  onChange={this.props.handleAutoComplete}
                  getOptionLabel={(option) => option.name}
                  defaultValue={this.getBiller}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Biller Name"
                      required
                      InputLabelProps={{classes:{asterisk: styles.asterisk}}}
                    />
                  )}
                />
            </Grid>
          </Grid>
          <div className="flex">
            <h4 style={{marginTop: '15px'}}>Custom Fields</h4>
            <IconButton
              className={styles.expandedButton}
              onClick={this.props.handleExpandClick}
              aria-expanded={this.props.expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon/>
            </IconButton>
          </div>
          <Collapse in={this.props.expanded} timeout="auto" unmountOnExit>
            {
              this.props.fields!== null ? (
                this.props.fields.map((field) => (
                    <>
                      {setCustom(field)}
                      <br/>
                    </>
                  )
                )

              ) : (
                null
              )
            }
          </Collapse>
        </form>

        {this.props.children}
        <div className={styles.actionButton}>
          <Button
            variant="outlined"
            color="primary"
            disabled={!this.props.item.title || !this.props.item.reference_no || !this.props.isFormValid()}
            onClick={() => this.props.submit()}>
            Save
          </Button>
        </div>
      </div>
    )
  }
}

export default SimpleForm


SimpleForm.propTypes = {
  onchange: PropTypes.func,
  isFormValid: PropTypes.func,
  onSelectAll: PropTypes.func,
  handleCheck: PropTypes.func,
  item: PropTypes.object,
  children: PropTypes.object,
  clients: PropTypes.array,
  defaultBiller: PropTypes.array,
  checkedUsers: PropTypes.array,
  selectedUsers: PropTypes.array,
  practiceAreas: PropTypes.array,
  users: PropTypes.array,
  fields: PropTypes.array,
  onchangeCheck: PropTypes.func,
  getClients: PropTypes.func,
  getDefaultValue: PropTypes.func,
  getDefaultArea: PropTypes.func,
  defaultArea: PropTypes.func,
  cfield: PropTypes.object,
  handleAutoComplete: PropTypes.func,
  handleExpandClick: PropTypes.func,
  handleField: PropTypes.func,
  error: PropTypes.func,
  expanded: PropTypes.func,
  setCurrentUser: PropTypes.func,
  defaultGroup: PropTypes.func,
  openPracticeAreaForm: PropTypes.func,
  submit: PropTypes.func,
  opposingCounsel: PropTypes.array,
  handleOpposingCounsel: PropTypes.func,
  selectedCounsel: PropTypes.array,
  handleClientPopup: PropTypes.func,
}
