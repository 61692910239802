import React from 'react'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
} from '@material-ui/core'

import styles from '../LegalCase.module.css'

class ReferenceNumberForm extends React.Component {
  render () {
    console.log(this.props.item.fields)
    return (
      <div>
        <form>
          <TextField
            autoComplete="on"
            className={styles.formTextfield}
            id="standard-basic"
            variant="outlined"
            size="small"
            name="reference_no"
            fullWidth
            label="Case Number"
            onChange={this.props.onchange}
            value={this.props.item.reference_no}
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            autoComplete="on"
            className={styles.formTextfield}
            id="standard-basic"
            variant="outlined"
            size="small"
            name="court_name"
            fullWidth
            label="Court Name"
            onChange={this.props.handleRefFields}
            value={this.props.item.fields.court_name}
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            autoComplete="on"
            className={styles.formTextfield}
            id="standard-basic"
            variant="outlined"
            size="small"
            name="date_file"
            fullWidth
            type="date"
            label="Date Filed"
            onChange={this.props.handleRefFields}
            value={this.props.item.fields.date_file}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              min: new Date().toDateString()
            }}
          />
          <TextField
            autoComplete="on"
            className={styles.formTextfield}
            id="standard-basic"
            variant="outlined"
            size="small"
            name="judge_name"
            fullWidth
            label="Judge Name"
            onChange={this.props.handleRefFields}
            value={this.props.item.fields.judge_name}
            InputLabelProps={{
              shrink: true
            }}
          />
        </form>
        {this.props.children}
        <div className={styles.actionButton}>
          <Button
            variant="outlined"
            color="primary"
            disabled={!this.props.item.reference_no}
            onClick={() => this.props.submit()}>Save</Button>
        </div>
      </div>
    )
  }
}
export default ReferenceNumberForm

ReferenceNumberForm.propTypes = {
  classes: PropTypes.object,
  onchange: PropTypes.func,
  item: PropTypes.object,
  children: PropTypes.object,
  submit: PropTypes.func,
  handleRefFields: PropTypes.func
}
