import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import axios from 'axios'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({

  textfield: {
    width: '80%',
    right: 'auto',
    left: 'auto'
  },
  button: {
    float: 'right'
  }
}))

const MatterForm = (props) => {
  const { setOpenPopup, matters, setMatters, setMessage, setOpenSnackbar } = props
  const [matter, setMatter] = useState()
  const handleChange = (e) => {
    e.preventDefault()
    setMatter({ name: e.target.value })
  }
  const addMatter = () => {
    console.log(matter)
    axios.post(process.env.REACT_APP_API_DOMAIN + '/v1/matters', (matter))
      .then(resp => {
        console.log(resp.data)
        setMatters({ ...matters, matter })
        setOpenPopup(false)
        setMessage('New Matter added')
        setOpenSnackbar(true)
      })
      .catch(error => console.log(error.response.data))
  }
  const classes = useStyles()
  return (
    <>
      <form autoComplete="off" style={{ display: 'flex' }}>
        <TextField variant="outlined" required fullWidth name="Name" onChange={handleChange} placeholder="NAME"/>
      </form>
      <br />
      <Button className={classes.button} variant="contained" onClick={addMatter}>Submit</Button>
    </>
  )
}
export default MatterForm

MatterForm.propTypes = {
  setOpenPopup: PropTypes.func,
  matters: PropTypes.array,
  setMatters: PropTypes.func,
  setMessage: PropTypes.func,
  setOpenSnackbar: PropTypes.func
}
