import React from 'react'

import axios from 'axios'

import PropTypes from 'prop-types'

import ContactNumbers from '../ContactNumber/ContactNumbers'
import EmailAddresses from '../EmailAddress/EmailAddresses'
import SimplePopUp from '../../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import NestedForm from '../NestedForm'
import {TableContainer, Table, TableBody, TableRow, TableCell} from "@material-ui/core";
import styles from "../Client.module.css";

class ShowContactAttributes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contactPersonEmails: [],
      contactPersonNumbers: [],
      initialNested: { uid: '',content: '', category: 0 },
      nestedItem: {},
      openNestedForm: false,
      openDeletePopup: false,
      nestedTitle: '',
      options: [],
      nestedModel: '',
      isCompany: true
    }
    this.loadAttributes = this.loadAttributes.bind(this)
    this.handleCreateOrUpdateNested = this.handleCreateOrUpdateNested.bind(this)
    this.handleNestedChange = this.handleNestedChange.bind(this)
    this.submitNested = this.submitNested.bind(this)
    this.handleOpenDelete=this.handleOpenDelete.bind(this)
    this.handleClose=this.handleClose.bind(this)
    this.handleDeleteItem=this.handleDeleteItem.bind(this)
  }
  
  componentDidMount() {
    this.loadAttributes(this.props.uid)
  }
  handleCreateOrUpdateNested(title, model, item) {
    this.setState({ nestedTitle: title })
    this.setState({ nestedItem: item })
    this.setState({ nestedModel: model })
    this.setState({ openNestedForm: true })
    if (model.toLowerCase() === 'address') {
      this.setState({ options: ["Current Address", "Permanent Address"] })
    } else if (model.toLowerCase() === 'contact number') {
      this.setState({ options: ["Primary", "Home Phone Number", "Phone Number", "Mobile Number", "Fax Number", "Others"] })
    } else {
      this.setState({ options: ["Personal", "Work", "Others"] })
    }
  }
  handleNestedChange(e) {
    this.setState({
      nestedItem: {
        ...this.state.nestedItem,
        [e.target.name]: e.target.value
      }
    })
  }
  submitNested() {
    var method = ''
    var url = ''
    var item = this.state.nestedItem
    if (this.state.nestedModel.toLowerCase() === 'contact number') {
      if (item.uid === '') {
        method = 'post'
        url = process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + this.props.uid + '/contact_numbers'
      } else {
        method = 'put'
        url = process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + this.props.uid + '/contact_numbers/' + item.uid
      }
      axios({
        method: method,
        url: url,
        data: (item),
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then(resp => {
        const newData = resp.data
        this.setState({
          contactPersonNumbers: this.state.contactPersonNumbers.filter(contactPersonNumber => contactPersonNumber.uid !== item.uid)
        })
        this.setState({ contactPersonNumbers: [...this.state.contactPersonNumbers, newData] })
        this.setState({ openNestedForm: false })
      }).catch(error => console.log(error.response))
    } else {
      if (item.uid === '') {
        method = 'post'
        url = process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + this.props.uid + '/email_addresses'
      } else {
        method = 'put'
        url = process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + this.props.uid + '/email_addresses/' + item.uid
      }
      axios({
        method: method,
        url: url,
        data: (item),
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then(resp => {
        const newData = resp.data
        this.setState({
          contactPersonEmails: this.state.contactPersonEmails.filter(contactPersonEmail => contactPersonEmail.uid !== item.uid)
        })
        this.setState({ contactPersonEmails: [...this.state.contactPersonEmails, newData] })
        this.setState({ openNestedForm: false })
      }).catch(error => console.log(error.response))
    }
  }
  loadAttributes(uid) {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + uid + '/contact_numbers',
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(async resp => {
      this.setState({ contactPersonNumbers: resp.data })
      const resp_1 = await axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + uid + '/email_addresses',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      })
      this.setState({ contactPersonEmails: resp_1.data })
    })
  }
  handleClose() {
    this.setState({ openNestedForm: false })
    this.setState({ openDeletePopup: false })
  }
  handleOpenDelete(model, item) {
    this.setState({ nestedItem: item })
    this.setState({ nestedModel: model })
    this.setState({ openDeletePopup: true })
  }
  handleDeleteItem() {
    const DeleteItemId = this.state.nestedItem.uid
    if (this.state.nestedModel.toLowerCase() === 'contact number') {
      axios({
        method: 'delete',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + this.props.uid + '/contact_numbers/' + DeleteItemId,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then(() => {
        this.setState({
          contactPersonNumbers: this.state.contactPersonNumbers.filter(contactPersonNumber => contactPersonNumber.uid !== DeleteItemId)
        })
        this.setState({ openDeletePopup: false })
      }).catch(error => console.log(error.response))
    } else {
      axios({
        method: 'delete',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + this.props.uid + '/email_addresses/' + DeleteItemId,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then(() => {
        this.setState({
          contactPersonEmails: this.state.contactPersonEmails.filter(contactPersonEmail => contactPersonEmail.uid !== DeleteItemId)
        })
        this.setState({ openDeletePopup: false })
      }).catch(error => console.log(error.response))
    }
  }
  render() {
    return (
      <>
        {this.props.primaryOnly ? (
            <Table size="small" stickyHeader aria-label="customized sticky table">
              <TableBody>
                <TableCell  className={styles.cellWidth}>
                  {this.state.contactPersonNumbers.length > 0 ? (
                    this.state.contactPersonNumbers.filter((filter) => filter.category_name == 'primary')
                      .map((contactNumber) => (
                        <div key={contactNumber.uid}>
                          {contactNumber.content}  -
                          {contactNumber.category_name === 'primary' ? (<i> P</i>) :null}
                        </div>
                      ))):null}
                </TableCell>

                <TableCell className={styles.emailCellWidth}>
                {this.state.contactPersonEmails.length > 0 ? (
                  this.state.contactPersonEmails.filter((filter) => filter.category_name == 'work')
                    .map((email) => (
                      <div key={email.uid}  className={styles.emailCell} >
                        {email.content}
                      </div>
                    ))):null}
                </TableCell>
              </TableBody>
            </Table>
          ) :
        <TableContainer style={{ maxHeight: '575px' }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={styles.cell} >
                  <ContactNumbers contactNumbers={this.state.contactPersonNumbers} isCompany={this.state.isCompany} initialContactNumber={this.state.initialNested} handleClick={this.handleCreateOrUpdateNested} handleOpenDelete={this.handleOpenDelete} />
                </TableCell>
                <TableCell className={styles.cell} >
                  <EmailAddresses emailAddresses={this.state.contactPersonEmails} isCompany={this.state.isCompany} initialEmailAddress={this.state.initialNested} handleClick={this.handleCreateOrUpdateNested} handleOpenDelete={this.handleOpenDelete} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        }
        <div className="contactPersonAddMail">
        </div>
        <SimplePopUp
          openPopup={this.state.openNestedForm}
          title={this.state.nestedTitle}
          handleClose={this.handleClose}
          maxWidth='sm'
        >
          <NestedForm handleSubmit={this.submitNested} handleChange={this.handleNestedChange} item={this.state.nestedItem} options={this.state.options} nestedModel={this.state.nestedModel} />
        </SimplePopUp>
        <SimpleDeletePopUp
          openDeletePopup={this.state.openDeletePopup}
          item={this.state.nestedItem}
          delete={this.handleDeleteItem}
          handleDeleteClose={this.handleClose}
          model={this.state.nestedModel}
        />
      </>
    )
  }
}

export default ShowContactAttributes
ShowContactAttributes.propTypes = {
  uid: PropTypes.string,
  contactNumbers: PropTypes.array,
  emailAddresses: PropTypes.array,
  contactName: PropTypes.object,
  primaryOnly: PropTypes.bool
}
