import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'
import styles from './Tasks.module.css'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import { Box, TextField, Tooltip, Typography  } from '@material-ui/core'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import ListAltIcon from '@material-ui/icons/ListAlt'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import SearchIcon from '@material-ui/icons/Search'
import TableContainer from "@material-ui/core/TableContainer";
import CreditCardIcon from '@material-ui/icons/CreditCard';

class TasksTable extends React.Component {

	getPriorityLAbel(priority_uid) {
		var label = ''
		if (this.props.priorities.length > 0) {
			label = this.props.priorities.find((priority) => priority.uid === priority_uid)
				? this.props.priorities.find((priority) => priority.uid === priority_uid).name
				: label
		}
		return label
	}

	render() {
		return (
			<>
				<Box className={styles.boxHeader}>
					<Box className={styles.boxHeaderChild}>
						{
							this.props.removeLabel ? null :
							<>
								<ListAltIcon />
								<Typography variant='h2' style={{
									fontWeight: 500,
									fontSize: '21px'
								}}>Tasks</Typography>
							</>
						}
					</Box>
					{
						this.props.withSearch ? (
							<Box className={styles.boxHeaderChild}>
								<TextField
									style={{ flexGrow: 1 }}
									variant="outlined"
									label={
										<span style={{
											display: 'flex',
											flexDirection: 'row',
											flexWrap: 'wrap',
											alignItems: 'center',
										}}>
											<SearchIcon fontSize='small' />
											Search
										</span>
									}
									size="small"
									value={this.props.q}
									onKeyPress={this.props._handleKeyPress}
									onChange={this.props.handleChanges}
								>
								</TextField>
								<Button
									variant="contained"
									color="primary"
									size="small"
									className={styles.advancedSearch}
									onClick={this.props.handleAdvanced}
								>
									Advanced Search
								</Button>
								<IconButton
									variant="contained"
									color="secondary"
									size="small"
									onClick={this.props.clearSearch}
								>
									<SettingsBackupRestoreIcon />
								</IconButton>
							</Box>
						) : (
							null
						)
					}
					<Box className={`${styles.boxHeaderChild} ${styles.boxHeaderChildSm}`}>
						{
							this.props.withStateButton ? (
								<ButtonGroup disableElevation variant="contained" color="primary" className={styles.buttonGroupTasks}>
									<Button color={this.props.showState ? 'primary' : 'default'} onClick={() => this.props.changeIndex(true)}>On Going Tasks</Button>
									<Button color={this.props.showState ? 'default' : 'primary'} onClick={() => this.props.changeIndex(false)}>Finished Tasks</Button>
								</ButtonGroup>
							) : (
								null
							)
						}
						<Tooltip title="Create Task Invoice" arrow>
							<IconButton
								size="small"
								color="inherit" 
								aria-label="create bulk invoice"
								onClick={this.props.togglePopupTaskInvoice}
							>
								<CreditCardIcon fontSize="medium"/>
							</IconButton>
						</Tooltip>
						<IconButton
							color="inherit"
							onClick={() => this.props.handleCreateOrUpdateTask(this.props.initialTask)}
							name="create-task"
							id="create-task"
						>
							<AddCircleOutlineIcon fontSize={this.props.removeLabel?("medium"):("large")} />
						</IconButton>
					</Box>
				</Box>
				<Box style={{ flexGrow: 1, height: '300px', maxHeight: '100%' }}>
				<TableContainer style={{maxHeight: '100%', height: '100%', position: 'relative'}}>
				<Table size="small">
					<TableHead>
						<TableRow>
							{
								this.props.headers.length > 0 ? (
									this.props.headers.map((header) => (
										header.toLowerCase() === 'actions' ? (
											<TableCell key={header} className={styles.actionCell} align="center"><strong>{header}</strong></TableCell>
										) : (
											<TableCell key={header} className={styles.cell}><strong>{header}</strong></TableCell>
										)
									))
								) : (
									null
								)
							}
						</TableRow>
					</TableHead>
					<TableBody className={styles.tableBody}>
						{this.props.items.length > 0 ? (
							this.props.items.sort(
								(a, b) => new Date(a.ends_at).getTime() - new Date(b.ends_at).getTime()
							).map((task) => (
								<TableRow key={task.uid} >
									{
										this.props.withWorkflowLabel ? (
											task["from_workflow"] !== undefined ? (
												<TableCell key={task.uid} className={new Date() > new Date(task.ends_at) && task.state === "ongoing" ? (styles.overDueCell) : (styles.cell)}>
													{
														task["from_workflow"] ? (
															<div className={styles.header}>
																<div className={styles.itemMargin}>
																	{task.name ? (task.name) : ("n/a")}
																</div>
																<div className={styles.circle} />
															</div>
														) : (<>{task.name ? (task.name) : ("n/a")}</>)

													}
												</TableCell>
											) : (

												<TableCell key={task.uid} className={new Date() > new Date(task.ends_at) && task.state === "ongoing" ? (styles.overDueCell) : (styles.cell)}>
													{task.name ? (task.name) : ("n/a")}
												</TableCell>
											)
										) : (
											<TableCell className={new Date() > new Date(task.ends_at) && task.state === "ongoing" ? (styles.overDueCell) : (styles.cell)}>{task.name}</TableCell>
										)
									}
									<TableCell className={new Date() > new Date(task.ends_at) && task.state === "ongoing" ? (styles.overDueCell) : (styles.cell)} >
										{
											task.user_uids !== null ? (

												this.props.users.length > 0 ? (
													this.props.users.map((user) => (
														task.user_uids.map((userId) => (
															user.uid === userId ? (
																<div>{user.first_name + ' ' + user.last_name}</div>
															) : (null)
														))

													))
												) : (null)
											) : ("n/a")
										}
									</TableCell>
									<TableCell className={new Date() > new Date(task.ends_at) && task.state === "ongoing" ? (styles.overDueCell) : (styles.cell)} >
										{task.ends_at ? moment(task.ends_at).format('L') : ("n/a")}
									</TableCell>
									<TableCell className={new Date() > new Date(task.ends_at) && task.state === "ongoing" ? (styles.overDueTableDesc) : (styles.tableDesc)} wrap="nowrap">{task.description ? (task.description) : ('-')}</TableCell>
									{
										this.props.withCaseLink ?
											(<TableCell className={new Date() > new Date(task.ends_at) && task.state === "ongoing" ? (styles.overDueCell) : (styles.cell)} >
												{task.legal_case ? (<Link className={new Date() > new Date(task.ends_at) && task.state === "ongoing" ? (styles.overDueLinkCell) : (styles.linkCell)} to={`/cases/${task.legal_case.uid}`} target="_blank">{task.legal_case.title}</Link>) : ('No Linked Case')}
											</TableCell>) : (null)
									}
									{
										task.priority_uid ? (
											<TableCell className={new Date() > new Date(task.ends_at) && task.state === "ongoing" ? (styles.overDueCell) : (styles.cell)}>{this.getPriorityLAbel(task.priority_uid)}</TableCell>
										) : (
											<TableCell className={new Date() > new Date(task.ends_at) && task.state === "ongoing" ? (styles.overDueCell) : (styles.cell)}>No Priority</TableCell>
										)
									}
									<TableCell className={new Date() > new Date(task.ends_at) && task.state === "ongoing" ? (styles.overDueCell) : (styles.cell)} align="left">
										<Button
											onClick={() => this.props.showCheckList(task)}
											name={`checklist`}

											style={{ color: '#006064' }}
										>
											{this.props.getLabel(task.uid, false) === 1 ? (<AssignmentTurnedInIcon />) : (this.props.getLabel(task.uid))}
										</Button>
									</TableCell>
									<TableCell className={new Date() > new Date(task.ends_at) && task.state === "ongoing" ? (styles.overDueCell) : (styles.cell)} align="left">{task.state}</TableCell>
									<TableCell className={styles.actionButtonHoverable}>
										<ButtonGroup edge="end">
											<IconButton
												variant="contained"
												color="primary"
												size="small"
												onClick={() => this.props.handleShowForm(task)}
												name={`task-showPopUp`}
											>
												<RemoveRedEyeIcon />
											</IconButton>
											<IconButton
												variant="contained"
												color="primary"
												size="small"
												onClick={() => this.props.handleCreateOrUpdateTask(task)}
												name={`task-edit`}
											>
												<EditIcon />
											</IconButton>
											<IconButton
												color="secondary"
												size="small"
												onClick={() => this.props.onOpenDeletePopup(task)} className="button muted-button"
												name={`task-delete`}
											>
												<DeleteIcon />
											</IconButton>
										</ButtonGroup>
									</TableCell>
								</TableRow>
							))
						) : (
							null
						)}
					</TableBody>
				</Table>
				</TableContainer>
				</Box>
			</>
		)
	}
}
export default TasksTable

TasksTable.propTypes = {
	headers: PropTypes.array.isRequired,
	items: PropTypes.array.isRequired,
	handleShowForm: PropTypes.func,
	getLabel: PropTypes.func,
	showCheckList: PropTypes.func,
	handleCreateOrUpdateTask: PropTypes.func,
	initialTask: PropTypes.object,
	onOpenDeletePopup: PropTypes.func,
	users: PropTypes.array,
	changeIndex: PropTypes.func,
	showState: PropTypes.bool,
	withStateButton: PropTypes.bool,
	withCaseLink: PropTypes.bool,
	withWorkflowLabel: PropTypes.bool,
	handleAdvanced: PropTypes.func,
	handleChanges: PropTypes.func,
	_handleKeyPress: PropTypes.func,
	clearSearch: PropTypes.func,
	q: PropTypes.func,
	withSearch: PropTypes.bool,
	removeLabel: PropTypes.bool,
	withPagination: PropTypes.bool,
	totalPages: PropTypes.number,
	currentPage: PropTypes.number,
	handlePageChange: PropTypes.func,
	priorities: PropTypes.array,
	togglePopupTaskInvoice: PropTypes.func
}
