import React from 'react'

import styles from './Workflow.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  Grid,
  MenuItem,
  Typography,
  Select,
  Card,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Divider,
} from '@material-ui/core'

class EventForm extends React.Component {

  render () {
    return (
      <>
      <div>
        <div>
          <form className={styles.formControl}>
            <Grid container spacing={3}>
              <Grid xs={12} sm={7}>
              <div>
                <TextField
                  autoComplete="off"
                  autoFocus={true}
                  className={styles.textfield}
                  variant="outlined"
                  required
                  size="small"
                  name="name"
                  value={this.props.event.name}
                  onChange={this.props.handleEventChange}
                  label="Event Name"/>
              </div>
                <div>
                  <TextField
                  autoComplete="off"
                  multiline={true}
                  rows={10}
                  className={styles.textfield}
                  variant="outlined"
                  required
                  name="description"
                    value={this.props.event.description}
                    onChange={this.props.handleEventChange}
                  label="Event Description"/></div>
               <br />
              </Grid>
              <Grid xs={12} sm={5}>
                <Card className={styles.card}>
                <div><Typography variant="body1"><strong>Workflow Calendar Date Calculation</strong></Typography></div><br />
                <div><Typography variant="body1">Event Due</Typography></div>
                <div className={styles.eventDue}>
                    <TextField
                        InputProps={{ inputProps: { min: "0", step: "1" } }}
                        type="number"
                        variant="outlined"
                        size="small"
                        className={styles.textHeight}
                        value={this.props.event.interval}
                        onChange={this.props.handleEventChange}
                        name="interval"
                        autofocus={true}
                        />

                    <Select
                        variant="outlined"
                        className={styles.intervalSelect}
                        value={this.props.event.interval_type}
                        onChange={this.props.handleEventChange}
                        name="interval_type"
                    >
                        <MenuItem value="weekdays"><Typography variant="body1" >Weekdays</Typography></MenuItem>
                        <MenuItem value="calendar_days"><Typography variant="body1" >Calendar Days</Typography></MenuItem>
                    </Select>
                  </div>

                  <FormControl component="fieldset">
                  <div className={styles.formControl}><Typography><span>Trigger Queue: </span></Typography>
                  <div className={styles.typographyMargin}>
                    <RadioGroup aria-label="interval_event" name="interval_event" value={this.props.event.interval_event} onChange={this.props.handleEventChange}>
                      <div><FormControlLabel value="before" control={<Radio />} label="before" />
                      <FormControlLabel value="after" control={<Radio />} label="after" /></div>
                    </RadioGroup></div>
                  </div>
                  </FormControl>
              <div />
                <div><Typography variant="body1">Trigger Event</Typography></div>
                <div><Select
                fullWidth
                variant="outlined"
                className={styles.triggerSelect}
                name="workflow_uid"
                onChange={this.props.handleEventChange}
                value={this.props.event.workflow_uid || ''}

                >
                        {
                            this.props.triggers.length> 0?(
                                this.props.triggers.map((trigger)=>(
                                    <MenuItem key={trigger.uid} value={trigger.uid}><Typography variant="body1" >{trigger.name}</Typography></MenuItem>
                                ))
                            ):(
                                <div>No Triggers Loaded</div>
                            )
                        }
                    </Select>
                </div><br />
                <Divider light /><br />
                <div className={styles.formControl}>
                <Typography variant="body1" className={styles.typographyMargin}>Start Time :</Typography>
                <TextField
                        InputProps={{ inputProps: { min: "0", step: "1" } }}
                        type="time"
                        format="HH:mm:ss"
                        variant="outlined"
                        size="small"
                        className={styles.startTimeText}
                        value={this.props.event.duration_start_time}
                        onChange={this.props.handleEventChange}
                        name="duration_start_time"
                        autofocus={true}
                        />
                </div><br />

                <div className={styles.formControl}>
                <Typography variant="body1" className={styles.typographyMargin}>Duration :</Typography>

                    <TextField
                        InputProps={{ inputProps: { min: "0", step: "1" } }}
                        type="number"
                        variant="outlined"
                        size="small"
                        className={styles.duration}
                        value={this.props.event.duration}
                        onChange={this.props.handleEventChange}
                        name="duration"
                        autofocus={true}
                        />
                    <Select
                        variant="outlined"
                        className={styles.durationType}
                        value={this.props.event.duration_type}
                        onChange={this.props.handleEventChange}
                        name="duration_type"
                    >
                        <MenuItem value="hours"><Typography variant="body1" >Hours</Typography></MenuItem>
                        <MenuItem value="minutes"><Typography variant="body1" >Minutes</Typography></MenuItem>
                    </Select>
                  </div><br />
                </Card>
              </Grid>
            </Grid>
          </form>
        <div className={styles.actionButton}>
          <Button variant="outlined" color="primary" onClick={this.props.submit} >Save</Button>
        </div>
        </div>
      </div>
     </>
    )
  }
}
export default EventForm

EventForm.propTypes = {
  onchange: PropTypes.func,
  item: PropTypes.object,
  children: PropTypes.object,
  submit: PropTypes.func,
  items: PropTypes.array,
  subs: PropTypes.array,
  addSubs: PropTypes.func,
  triggers: PropTypes.array,
  event: PropTypes.object,
  handleEventChange: PropTypes.func,
  handleSubs: PropTypes.func,
  removeSub: PropTypes.func,
  handleLabel: PropTypes.func,
  duration_start_time: PropTypes.string
}
