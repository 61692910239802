import React from 'react'
import PropTypes from 'prop-types'
import {
	TextField,
	Table, TableBody, TableRow, TableHead, TableCell, IconButton,
	Grid, Stepper, Step, StepLabel, Button, 
} from '@material-ui/core'
import styles from './Onboarding.module.css'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import NestedForm from './Nestedform'
import CustomFieldForms from './CustomFieldForms'
import ReviewForm from './ReviewForm'
import Priorities from './Priorities'
import MembersForm from './MembersForm'
class OnBoardingForm extends React.Component {
	render() {
		const steps = this.props.steps
		return (
			<>
				<div>
					<div>
						<Stepper activeStep={this.props.activeStep} alternativeLabel className={styles.stepperBody}>
							{steps.map((label) => {
								const stepProps = {};
								const labelProps = {};
								return (
									<Step key={label} {...stepProps}>
										<StepLabel

											{...labelProps}>{label}</StepLabel>
									</Step>
								);
							})
							}
						</Stepper>
						<div>
							{
								this.props.activeStep === 0 ?
									<>
										<Grid container spacing={4} >
											<Grid item lg={4} >
												<TextField
													label="Company Name"
													fullWidth={true}
													name="name"
													required
													onChange={this.props.handleChange}
													value={this.props.item.name}
												/>
											</Grid>
											<Grid item lg={4} >
												<TextField
													label="Trade Name"
													fullWidth={true}
													name="trade_name"
													required
													onChange={this.props.handleChange}
													value={this.props.item.trade_name}
												/>
											</Grid>
											<Grid item xs={12}><h4>Contact Person</h4></Grid>
											<Grid item lg={3} >
												<TextField
													label="First name"
													fullWidth={true}
													name="firstname"
													onChange={this.props.handleChange}
													value={this.props.item.firstname}
													required
												/>
											</Grid>
											<Grid item lg={2} >
												<TextField
													label="Middle name"
													fullWidth={true}
													name="middlename"
													onChange={this.props.handleChange}
													value={this.props.item.middlename}
												/>
											</Grid>
											<Grid item lg={3} >
												<TextField
													label="Last name"
													fullWidth={true}
													name="lastname"
													onChange={this.props.handleChange}
													value={this.props.item.lastname}
													required
												/>
											</Grid>

											<Grid item lg={4} >
												<TextField
													label="Email"
													fullWidth={true}
													name="email"
													required
													onChange={this.props.handleChange}
													value={this.props.item.email}
												/>
											</Grid>
											<Grid item lg={8} >
												<TextField
													label="Address"
													fullWidth={true}
													name="address"
													required
													onChange={this.props.handleChange}
													value={this.props.item.address}
												/>
											</Grid>

											<Grid item lg={2} >
												<TextField
													label="Contact Number"
													fullWidth={true}
													name="cellphone"
													onChange={this.props.handleChange}
													value={this.props.item.cellphone}
												/>
											</Grid>



										</Grid>
									</> : null
							}
						</div>
						{
							this.props.activeStep === 1 ?
								<>
									<div className={styles.headerContent}>

										<div>Users</div>
										<IconButton
											name="add_member"
											style={{ color: this.props.members.length === 3 ? '#3f51b5' : 'black' }}
											disabled={this.props.members.length === 3}
											onClick={this.props.addMember}>
											<AddCircleOutlineIcon fontSize="medium" />
										</IconButton>
									</div>

									<Table>
										<TableHead>
											<TableRow>
												<TableCell>
													<b>Username</b>
												</TableCell>
												<TableCell>
													<b>Email</b>
												</TableCell>
												<TableCell>
													<b>First Name</b>
												</TableCell>
												<TableCell>
													<b>Middle Name</b>
												</TableCell>
												<TableCell>
													<b>Last Name</b>
												</TableCell>
												<TableCell>

												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody className={styles.tableBody}>
											{
													this.props.members.map((val, idx) => (
														<MembersForm
															idx={idx}
															key={`member-${idx}`}
															members={this.props.members}
															removemember={this.props.removeMember}
															handleChange={this.props.handleMemberChange}
															defaultLength={3}
														/>
													))
													
											}
										</TableBody>
									</Table>
								</>
								: null
						}
						{
							this.props.activeStep === 2 || this.props.activeStep === 3 ?
								<>
									<div className={styles.headerContent}>

										<div> {this.props.activeStep === 2 ? 'Groups' : 'Practice Areas'}</div>
										{
											this.props.activeStep === 2 ?
												<IconButton
													name="add_group"
													onClick={this.props.addgroup}>
													<AddCircleOutlineIcon fontSize="medium" />
												</IconButton> :
												<IconButton
													name="add_practice_area"
													onClick={this.props.addPracticeArea}>
													<AddCircleOutlineIcon fontSize="medium" />
												</IconButton>
										}
									</div>

									<Table>
										<TableHead>
											<TableRow>
												<TableCell>
													<b>Name</b>
												</TableCell>

												<TableCell>

												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody className={styles.tableBody}>
											{
												this.props.activeStep === 2 ?
													this.props.groups.map((val, idx) => (
														<NestedForm
															idx={idx}
															key={`groups-${idx}`}
															groups={this.props.groups}
															removegroup={this.props.removegroup}
															handleChange={this.props.handleGroupChange}
															model="groups"
															defaultLength={10}
														/>
													))
													:
													this.props.practiceAreas.map((val, idx) => (
														<NestedForm
															idx={idx}
															key={`groups-${idx}`}
															groups={this.props.practiceAreas}
															removegroup={this.props.removePracticeArea}
															handleChange={this.props.handlePracticeAreaChange}
															defaultLength={15}
														/>
													))
											}
										</TableBody>
									</Table>
								</>
								: null
						}







						{
							this.props.activeStep === 4 || this.props.activeStep === 5 ?
								<>
									<div className={styles.headerContent}>
										<div> {this.props.activeStep === 4 ? 'Clients Custom Fields' : 'Legal Cases Custom Fields'}</div>
										{
											this.props.activeStep === 4 ?

												<IconButton
													name="add_client_custom_field"
													style={{ color: this.props.clientCustomFields.length === 3 ? '#3f51b5' : 'black' }}
													disabled={this.props.clientCustomFields.length === 3}
													onClick={this.props.addClientCustomField}>
													<AddCircleOutlineIcon fontSize="medium" />
												</IconButton> :

												<IconButton
													name="add_legal_case_custom_field"
													style={{ color: this.props.caseCustomFields.length === 3 ? '#3f51b5' : 'black' }}
													disabled={this.props.caseCustomFields.length === 3}
													onClick={this.props.addLegalCaseCustomField}>
													<AddCircleOutlineIcon fontSize="medium" />
												</IconButton>
										}
									</div>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>
													<b>Name</b>
												</TableCell>
												<TableCell>
													<b>Type</b>
												</TableCell>
												<TableCell>

												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody className={styles.tableBody}>


											{
												this.props.activeStep === 4 ?
													this.props.clientCustomFields.map((val, idx) => (
														<CustomFieldForms
															idx={idx}
															key={`client-${idx}`}
															items={this.props.clientCustomFields}
															removeItem={this.props.removeClientCustomField}
															handleChange={this.props.handleClientCustomFieldChange}
														/>
													)) :
													this.props.caseCustomFields.map((val, idx) => (
														<CustomFieldForms
															idx={idx}
															key={`legal-case-${idx}`}
															items={this.props.caseCustomFields}
															removeItem={this.props.removeLegalCaseCustomField}
															handleChange={this.props.handleLegalCaseCustomFieldChange}
														/>
													))
											}


										</TableBody>
									</Table>
								</>
								: null
						}
						{
							this.props.activeStep === 6 ?
								<>
									<div className={styles.headerContent}>
										<div>Priorities</div>

										<IconButton
											name="add_priority"
											onClick={this.props.addPriority}>
											<AddCircleOutlineIcon fontSize="medium" />
										</IconButton>
									</div>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>
													<b>Name</b>
												</TableCell>
												<TableCell>
													<b>Color</b>
												</TableCell>
												<TableCell>
													<b>Rank</b>
												</TableCell>
												<TableCell>

												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody className={styles.tableBody}>
											{
												this.props.priorities.map((val, idx) => (
													<Priorities
														idx={idx}
														key={`priorities-${idx}`}
														groups={this.props.priorities}
														defaultLength={0}
														removegroup={this.props.removePriority}
														handleChange={this.props.handlePriorityChange}
													/>
												))
											}
										</TableBody>
									</Table>
								</>
								: null
						}
						{
							this.props.activeStep === steps.length ?


								<ReviewForm
									info={this.props.item}
									members={this.props.members}
									groups={this.props.groups}
									practiceAreas={this.props.practiceAreas}
									clientCustomFields={this.props.clientCustomFields}
									caseCustomFields={this.props.caseCustomFields}
									priorities={this.props.priorities}
									handleRedirectTo={this.props.handleRedirectTo}
								/> : null
						}
						<div className={styles.actionButton} style={{ marginTop: '20px' }}>
							{this.props.activeStep === steps.length ? (
								<div>
									<Button variant="outlined" color="primary" onClick={this.props.saveData}> Save </Button>
									<Button onClick={this.props.handleReset}>Reset</Button>
								</div>

							) : (
								<div>
									<div>
										<Button
											disabled={this.props.activeStep === 0}
											onClick={this.props.handleBack}
											variant="contained"
											className={styles.backButton}
										>
											Back
										</Button>&nbsp;&nbsp;
										<Button variant="contained" color="primary" onClick={this.props.handleNext}
											disabled={this.props.validateForm(this.props.activeStep)}
										>
											{this.props.activeStep === steps.length - 1 ? 'Finish' : 'Next'}
										</Button>
									</div>
								</div>
							)}
						</div>

					</div>
				</div>
			</>
		)
	}
}
export default OnBoardingForm

OnBoardingForm.propTypes = {
	activeStep: PropTypes.number.isRequired,
	handleNext: PropTypes.func.isRequired,
	handleBack: PropTypes.func.isRequired,
	handleReset: PropTypes.func.isRequired,
	steps: PropTypes.array.isRequired,
	addgroup: PropTypes.func.isRequired,
	groups: PropTypes.array.isRequired,
	removegroup: PropTypes.func.isRequired,
	handleGroupChange: PropTypes.func.isRequired,
	practiceAreas: PropTypes.array.isRequired,
	addPracticeArea: PropTypes.func.isRequired,
	removePracticeArea: PropTypes.func.isRequired,
	handlePracticeAreaChange: PropTypes.func.isRequired,
	addClientCustomField: PropTypes.func,
	addLegalCaseCustomField: PropTypes.func,
	clientCustomFields: PropTypes.array,
	handleClientCustomFieldChange: PropTypes.func,
	handleLegalCaseCustomFieldChange: PropTypes.func,
	removeClientCustomField: PropTypes.func,
	removeLegalCaseCustomField: PropTypes.func,
	caseCustomFields: PropTypes.array,
	saveData: PropTypes.func.isRequired,
	handleChange: PropTypes.func,
	departments: PropTypes.array,
	addDepartment: PropTypes.func,
	removeDepartment: PropTypes.func,
	handleDepartmentChange: PropTypes.func,
	item: PropTypes.object,
	handleCreateOrUpdate: PropTypes.func,
	priorities: PropTypes.array,
	addPriority: PropTypes.func,
	removePriority: PropTypes.func,
	handlePriorityChange: PropTypes.func,
	validateForm: PropTypes.func,
	addMember: PropTypes.func,
	removeMember: PropTypes.func,
	members: PropTypes.array,
	handleMemberChange: PropTypes.func,
	handleRedirectTo: PropTypes.func,

}

