import React from 'react'
import moment from 'moment'
import axios from 'axios'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import Calendar from './Calendar.js'
import Task from './../Tasks/Task'
import TaskForm from './../Tasks/TaskForm'
import Event from './Event'
import EventForm from './EventForm'
import AlertMessage from '../../shared/Notify/AlertMessage'
import ProgressBar from '../../shared/ProgressBar/ProgressBar'
import ErrorPage from '../../shared/ErrorPage/ErrorPage'
import { Box } from '@material-ui/core'
import styles from './Events.module.css'

class Events extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Event',
      tableEvents: [],
      events: [],
      openPopup: false,
      openDeletePopup: false,
      event: {},
      initialEvent: {
        uid: '',
        name: '',
        description: '',
        starts_at: new Date(),
        ends_at: new Date(),
        user_uids: [localStorage.getItem('current_user_id')]
      },
      title: '',
      load: false,
      tableHead: ['Name', 'Description', 'starts_at', 'ends_at'],
      withShow: false,
      withEdit: true,
      maxWidth: 'md',
      tasks: [],
      cases: [],
      users: [],
      legalCase: {},
      show: true,
      openEventPopUp: false,
      task: {},
      openTaskPopUp: false,
      defaultCase: {},
      openTaskForm: false,
      checkedUsers: [],
      subTasks: [],
      openEventForm: false,
      caseUsers: [],
      eventCaseUsers: [],
      tableView: false,
      taskUsersAutocomplete: [],
      eventUsersAutocomplete: [],
      priorityLevels: [],
      loadEventSourcesError: null
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleEventSubmit = this.handleEventSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.getColor = this.getColor.bind(this)
    this.handleEventClick = this.handleEventClick.bind(this)
    this.handleTaskChange = this.handleTaskChange.bind(this)
    this.handleEventChange = this.handleEventChange.bind(this)
    this.handleCreateOrUpdateTask = this.handleCreateOrUpdateTask.bind(this)
    this.handleCreateOrUpdateEvent = this.handleCreateOrUpdateEvent.bind(this)
    this.getDefaultValue = this.getDefaultValue.bind(this)
    this.handleTaskAutoComplete = this.handleTaskAutoComplete.bind(this)
    this.handleEventAutoComplete = this.handleEventAutoComplete.bind(this)
    this.addSubTask = this.addSubTask.bind(this)
    this.removeSubTask = this.removeSubTask.bind(this)
    this.handleSubTasks = this.handleSubTasks.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.handleSubmitSubTasks = this.handleSubmitSubTasks.bind(this)
    this.handleEventDateChange = this.handleEventDateChange.bind(this)
    this.handleStartChange = this.handleStartChange.bind(this)
    this.handleEndChange = this.handleEndChange.bind(this)
    this.handleUsersAutocomplete = this.handleUsersAutocomplete.bind(this)
    this.handleEventUsersAutocomplete =
      this.handleEventUsersAutocomplete.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.toggleView = this.toggleView.bind(this)
    this.handleSwitchTimeBase = this.handleSwitchTimeBase.bind(this)
    this.handleDragEvent = this.handleDragEvent.bind(this)
  }

  componentDidMount() {
    this.loadEventSources()
  }

  handleCheck(e) {
    var isDisable
    if (e.target.checked === true) {
      isDisable = false
    } else {
      isDisable = true
      this.setState({ legalCase: {} })
    }
    this.setState({ show: isDisable })
  }

  getColor(priority_id, items) {
    var color = '#3788d9'
    if (items.length > 0) {
      if (priority_id !== null) {
        color = items.filter((item) => item.uid === priority_id)[0]
          ? items.filter((item) => item.uid === priority_id)[0].color
          : '#3788d9'
      }
    }
    return color
  }

  loadEventSources() {
    var priorities = []
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/priorities',
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        this.setState({ priorityLevels: resp.data })
        priorities = resp.data
      })
      .catch((error) =>
        this.setState({ loadEventSourcesError: error.response.status })
      )
    axios
      .get(process.env.REACT_APP_API_DOMAIN + '/v1/users/me/events', {
        headers: {
          'Content-Type': 'application/json',
          'X-APP-API-KEY': localStorage.getItem('api_key')
        }
      })
      .then((resp) => {
        this.setState({ tableEvents: resp.data.data })
        const newData = resp.data.data.map((item) => ({
          id: item.uid,
          title: item.name,
          start: item.starts_at,
          end: item.ends_at
        }))
        this.setState({ events: newData })
        this.setState({ load: true })
        axios
          .get(
            process.env.REACT_APP_API_DOMAIN +
              '/v1/users/me/tasks?state=ongoing',
            {
              headers: {
                'Content-Type': 'application/json',
                'X-APP-API-KEY': localStorage.getItem('api_key')
              }
            }
          )
          .then((resp) => {
            const newData = resp.data.data.map((item) => ({
              id: item.uid,
              title: item.name,
              start: item.ends_at,
              color: this.getColor(item.priority_uid, priorities),
              allDay: true
            }))
            this.setState({ tasks: newData })
            axios({
              method: 'get',
              url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases',
              headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
            })
              .then((resp) => {
                this.setState({ cases: resp.data.data })
                axios({
                  method: 'get',
                  url: process.env.REACT_APP_API_DOMAIN + '/v1/members',
                  headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
                })
                  .then((resp) => {
                    this.setState({ users: resp.data })
                    this.setState({ load: true })
                  })
                  .catch((error) =>
                    this.setState({
                      loadEventSourcesError: error.response.status
                    })
                  )
              })
              .catch((error) =>
                this.setState({ loadEventSourcesError: error.response.status })
              )
          })
          .catch((error) =>
            this.setState({ loadEventSourcesError: error.response.status })
          )
      })
      .catch((error) =>
        this.setState({ loadEventSourcesError: error.response.status })
      )
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.event.uid
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/events/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then(() => {
        this.setState({
          events: this.state.events.filter((item) => item.id !== DeleteItemId)
        })
        this.setState({
          tableEvents: this.state.tableEvents.filter(
            (item) => item.uid !== DeleteItemId
          )
        })
        this.setState({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'error'
        })
        this.setState({ openDeletePopup: false })
        this.setState({ openEventPopUp: false })
      })
      .catch((error) => console.log(error.response))
  }

  handleClose() {
    this.setState({ openPopup: false })
    this.setState({ openDeletePopup: false })
    this.setState({ openEventPopUp: false })
    this.setState({ openTaskPopUp: false })
    this.setState({ openTaskForm: false })
    this.setState({ openEventForm: false })
    this.setState({ caseUsers: [] })
    this.setState({ eventCaseUsers: [] })
    this.setState({ taskUsersAutocomplete: [] })
    this.setState({ eventUsersAutocomplete: [] })
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({ openPopup: true })
    this.setState({ event: item })
    this.setState({ title: textTitle })
  }

  handleInputChange(e) {
    this.setState({
      event: {
        ...this.state.event,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ event: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  handleEventClick(e) {
    this.state.events.map((event) => {
      if (e.event.id === event.id) {
        return axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/events/' + e.event.id,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        })
          .then((resp) => {
            this.setState({ event: resp.data })
            this.setState({ openEventPopUp: true })
          })
          .catch((error) => console.log(error.response))
      }
    })
    this.state.tasks.map((task) => {
      if (e.event.id === task.id) {
        return axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/tasks/' + e.event.id,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        })
          .then((resp) => {
            this.setState({ task: resp.data })
            this.setState({ openTaskPopUp: true })
          })
          .catch((error) => console.log(error.response))
      }
    })
  }

  handleTaskChange(e) {
    this.setState({
      task: {
        ...this.state.task,
        [e.target.name]: e.target.value
      }
    })
  }

  handleEventChange(e) {
    var value = e.target.value
    if (e.target.name === 'starts_at') {
      //date value plus 1 day
      var newDate = moment(value).add(1, 'days').format('YYYY-MM-DDTHH:mm')
      this.setState({
        event: {
          ...this.state.event,
          starts_at: value,
          ends_at: newDate
        }
      })
    } else {
      this.setState({
        event: {
          ...this.state.event,
          [e.target.name]: value
        }
      })
    }
  }

  handleCreateOrUpdateTask(item) {
    var textTitle = ''
    var taskUsers = []
    var newItem = {
      uid: item.uid,
      can_delete: item.can_delete,
      can_edit: item.can_edit,
      can_done: item.can_done,
      can_read: item.can_read,
      can_undone: item.can_undone,
      legal_case: item.legal_case,
      parent_uid: item.parent_uid,
      name: item.name,
      description: item.description,
      tag_list: item.tag_list,
      state: item.state,
      from_workflow: item.from_workflow,
      created_at: item.created_at,
      updated_at: item.updated_at,
      is_time_base: item.is_time_base,
      priority_id: item.priority_uid ? item.priority_uid : null
    }
    if (item.id === '') {
      textTitle = ' Create Task '
      newItem['ends_at'] = moment().format('YYYY-MM-DDTHH:mm')
      newItem['user_ids'] = [localStorage.getItem('current_user_id')]
      taskUsers = []
    } else {
      newItem['ends_at'] = item.ends_at
        ? moment(item.ends_at).format('YYYY-MM-DDTHH:mm')
        : null
      newItem['user_ids'] = item.user_uids
      textTitle = ' Update Task '
      if (this.state.users.length > 0) {
        this.state.users.map((user) => {
          if (item.user_uids !== null) {
            item.user_uids.map((userId) => {
              if (user.uid === userId) {
                taskUsers.push(user)
              }
            })
          }
        })
      }
    }
    this.setState({ taskUsersAutocomplete: taskUsers })
    if (item.legal_case !== undefined) {
      this.setState({ defaultCase: item.legal_case })
      this.setState({ legalCase: item.legal_case })
      this.setState({ show: false })
      var users = []
      if (this.state.users.length > 0) {
        this.state.users.map((user) => {
          item.legal_case.user_uids.map((userId) => {
            if (user.uid === userId) {
              users.push(user)
            }
          })
        })
      }
      this.setState({ caseUsers: users })
    }

    if (item.uid !== '') {
      const allTasks = [...this.state.tasks]
      var subs = []
      if (allTasks.length > 0) {
        allTasks.map((task) => {
          if (task.parent_uid === item.uid) {
            subs.push(task)
          }
        })
      }
      this.setState({ subTasks: subs })
    }
    this.setState({ title: textTitle })
    this.setState({ checkedUsers: newItem.user_ids })
    this.setState({ task: newItem })
    this.setState({ openTaskForm: true })
  }

  handleCreateOrUpdateEvent(item) {
    var textTitle = ''
    var eventUsers = []
    var newItem = {
      uid: item.uid,
      can_delete: item.can_delete,
      can_edit: item.can_edit,
      legal_case: item.legal_case,
      user_ids: item.user_uids,
      name: item.name,
      description: item.description,
      tag_list: item.tag_list,
      state: item.state,
      from_workflow: item.from_workflow,
      created_at: item.created_at,
      updated_at: item.updated_at
    }
    if (item.uid === '') {
      textTitle = ' Create Event '
      newItem['starts_at'] = moment().format('YYYY-MM-DDTHH:mm')
      newItem['ends_at'] = moment().format('YYYY-MM-DDTHH:mm')
    } else {
      newItem['starts_at'] = item.starts_at
        ? new Date(item.starts_at)
            .toLocaleString('sv-Se', { timeZone: 'Asia/Manila' })
            .replace(' ', 'T')
        : null
      newItem['ends_at'] = item.ends_at
        ? moment(item.ends_at).format('YYYY-MM-DDTHH:mm')
        : null
      textTitle = ' Update Event '
    }
    if (this.state.users.length > 0) {
      this.state.users.map((user) => {
        if (item.user_uids !== null) {
          item.user_uids.map((userId) => {
            if (user.uid === userId) {
              eventUsers.push(user)
            }
          })
        }
      })
    }
    this.setState({ eventUsersAutocomplete: eventUsers })
    if (item.legal_case !== undefined) {
      this.setState({ defaultCase: item.legal_case })
      this.setState({ legalCase: item.legal_case })
      this.setState({ show: false })
      var users = []
      if (this.state.users.length > 0) {
        this.state.users.map((user) => {
          item.legal_case.user_uids.map((userId) => {
            if (user.uid === userId) {
              users.push(user)
            }
          })
        })
      }
      this.setState({ eventCaseUsers: users })
    }

    this.setState({ title: textTitle })
    this.setState({ checkedUsers: item.user_uids })
    this.setState({ event: newItem })
    this.setState({ openEventForm: true })
  }

  getDefaultValue() {
    var newArray = []
    if (this.state.users.length > 0) {
      if (this.state.checkedUsers !== null) {
        this.state.users.map((user) => {
          this.state.checkedUsers.map((current) => {
            if (current === user.uid) {
              newArray.push(user)
            }
          })
        })
      }
    }
    return newArray
  }

  handleTaskAutoComplete(event, values) {
    if (values !== null) {
      if (event.target.id.includes('user_ids')) {
        this.setState({ taskUsersAutocomplete: values })
      } else {
        this.setState({ legalCase: values })
        var users = []
        if (this.state.users.length > 0) {
          this.state.users.map((user) => {
            if (values.user_uids !== undefined) {
              values.user_uids.map((userId) => {
                if (user.uid === userId) {
                  users.push(user)
                }
              })
            }
          })
        }
        this.setState({ caseUsers: users })
      }
    }
  }

  handleEventAutoComplete(event, values) {
    if (values !== null) {
      if (event.target.id.includes('user_ids')) {
        this.setState({ eventUsersAutocomplete: values })
      } else {
        this.setState({ legalCase: values })

        var users = []
        var initialUser = []
        var isUserInCase = 0
        if (this.state.users.length > 0) {
          this.state.users.map((user) => {
            if (values.user_uids !== undefined) {
              values.user_uids.map((userId) => {
                if (user.uid === userId) {
                  users.push(user)
                  if (user.uid === localStorage.getItem('current_user_id')) {
                    isUserInCase = isUserInCase + 1
                    initialUser = [user]
                  }
                }
              })
            }
          })
        }
        this.setState({ eventCaseUsers: users })
        if (isUserInCase === 0) {
          initialUser = []
        }
        this.setState({ eventUsersAutocomplete: initialUser })
      }
    }
  }

  removeSubTask(idx) {
    if (this.state.subTasks[idx].uid !== '') {
      const DeleteItemId = this.state.subTasks[idx].uid
      axios({
        method: 'delete',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/tasks/' + DeleteItemId,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      })
        .then(() => {})
        .catch((error) => console.log(error.response))
    }
    this.state.subTasks.splice(idx, 1)
    this.setState({ subTasks: [...this.state.subTasks] })
  }

  handleSubTasks(e) {
    const updatedSubTasks = [...this.state.subTasks]
    updatedSubTasks[e.target.dataset.id][e.target.dataset.fieldType] =
      e.target.value
    this.setState({ subTasks: updatedSubTasks })
  }

  addSubTask() {
    this.setState({
      subTasks: [...this.state.subTasks, { ...this.state.initialTask }]
    })
  }
  handleSubmit() {
    var item = {}
    var userIds = []
    if (this.state.taskUsersAutocomplete.length > 0) {
      this.state.taskUsersAutocomplete.map((user) => {
        userIds.push(user.uid)
      })
    }
    item['uid'] = this.state.task.uid
    item['name'] = this.state.task.name
    item['description'] = this.state.task.description
    item['user_ids'] = userIds
    item['tag_list'] = this.state.task.tag_list
    item['priority_id'] = this.state.task.priority_id
    item['ends_at'] = this.state.task.ends_at
    item['is_time_base'] = this.state.task.is_time_base
    var method = ''
    var url = ''
    if (this.state.show === true) {
      if (item.uid === '') {
        method = 'post'
        url = process.env.REACT_APP_API_DOMAIN + '/v1/tasks'
      } else {
        method = 'put'
        url = process.env.REACT_APP_API_DOMAIN + '/v1/tasks/' + item.uid
      }
      axios({
        method: method,
        url: url,
        data: item,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      })
        .then((resp) => {
          const newData = {}
          newData['id'] = resp.data.uid
          newData['title'] = resp.data.name
          newData['start'] = resp.data.ends_at
          ;(newData['color'] = this.getColor(
            item.priority_id,
            this.state.priorityLevels
          )),
            (newData['allDay'] = true)
          this.setState({
            tasks: this.state.tasks.filter((task) => task.id !== item.uid)
          })
          this.setState({ tasks: [...this.state.tasks, newData] })
          this.handleSubmitSubTasks(resp.data.uid, resp.data.user_uids)
          this.setState({
            isOpen: true,
            message: 'Submitted Successfully',
            type: 'success'
          })
          this.setState({ openTaskForm: false })
          this.setState({ openTaskPopUp: false })
        })
        .catch((error) => {
          if (error.response && error.response.status) {
            if (error.response.status === 422) {
              this.setState({
                error_messages: 'Name ' + error.response.data.errors.name[0]
              })
            } else {
              this.setState({
                isOpen: true,
                message:
                  error.response.status.toString() +
                  ' Unexpected Error Problem Occurred',
                type: 'error'
              })
              this.setState({ openTaskForm: false })
              this.setState({ openTaskPopUp: false })
            }
          } else {
            console.log(error)
          }
        })
    } else {
      if (
        this.state.defaultCase.uid !== undefined &&
        this.state.legalCase.uid !== undefined
      ) {
        if (this.state.defaultCase.uid === this.state.legalCase.uid) {
          if (item.uid === '') {
            method = 'post'
            url =
              process.env.REACT_APP_API_DOMAIN +
              '/v1/legal_cases/' +
              this.state.defaultCase.uid +
              '/tasks/'
          } else {
            method = 'put'
            url = process.env.REACT_APP_API_DOMAIN + '/v1/tasks/' + item.uid
          }
          axios({
            method: method,
            url: url,
            data: item,
            headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
          })
            .then((resp) => {
              const newData = {}
              newData['id'] = resp.data.uid
              newData['title'] = resp.data.name
              newData['start'] = resp.data.ends_at
              ;(newData['color'] = this.getColor(
                item.priority_id,
                this.state.priorityLevels
              )),
                (newData['allDay'] = true)
              this.setState({
                tasks: this.state.tasks.filter((tasks) => tasks.id !== item.uid)
              })
              this.setState({ tasks: [...this.state.tasks, newData] })
              this.handleSubmitSubTasks(resp.data.uid, resp.data.user_uids)
              this.setState({
                isOpen: true,
                message: 'Submitted Successfully',
                type: 'success'
              })
              this.setState({ openTaskForm: false })
              this.setState({ openTaskPopUp: false })
            })
            .catch((resp) => {
              console.log(resp)
            })
        } else {
          axios({
            method: 'delete',
            url:
              process.env.REACT_APP_API_DOMAIN +
              '/v1/legal_cases/' +
              this.state.defaultCase.uid +
              '/tasks/' +
              item.uid,
            headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
          })
            .then(() => {
              this.setState({
                tasks: this.state.tasks.filter((tasks) => tasks.id !== item.uid)
              })
              axios({
                method: 'post',
                url:
                  process.env.REACT_APP_API_DOMAIN +
                  '/v1/legal_cases/' +
                  this.state.legalCase.uid +
                  '/tasks/',
                data: item,
                headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
              })
                .then((resp) => {
                  const newData = {}
                  newData['id'] = resp.data.uid
                  newData['title'] = resp.data.name
                  newData['start'] = resp.data.ends_at
                  ;(newData['color'] = this.getColor(
                    item.priority_id,
                    this.state.priorityLevels
                  )),
                    (newData['allDay'] = true)
                  this.setState({
                    tasks: this.state.tasks.filter(
                      (tasks) => tasks.id !== item.uid
                    )
                  })
                  this.setState({ tasks: [...this.state.tasks, newData] })
                  this.handleSubmitSubTasks(resp.data.uid, resp.data.user_uids)
                  this.setState({
                    isOpen: true,
                    message: 'Submitted Successfully',
                    type: 'success'
                  })
                  this.setState({ openTaskForm: false })
                  this.setState({ openTaskPopUp: false })
                })
                .catch((resp) => {
                  console.log(resp)
                })
              this.setState({ openTaskForm: false })
              this.setState({ openTaskPopUp: false })
            })
            .catch((resp) => {
              console.log(resp)
            })
        }
      } else if (
        this.state.defaultCase.uid === undefined &&
        this.state.legalCase.uid !== undefined
      ) {
        axios({
          method: 'post',
          url:
            process.env.REACT_APP_API_DOMAIN +
            '/v1/legal_cases/' +
            this.state.legalCase.uid +
            '/tasks/',
          data: item,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        })
          .then((resp) => {
            const newData = {}
            newData['id'] = resp.data.uid
            newData['title'] = resp.data.name
            newData['start'] = resp.data.ends_at
            ;(newData['color'] = this.getColor(
              item.priority_id,
              this.state.priorityLevels
            )),
              (newData['allDay'] = true)
            this.setState({
              tasks: this.state.tasks.filter((task) => task.id !== item.uid)
            })
            this.setState({ tasks: [...this.state.tasks, newData] })
            this.handleSubmitSubTasks(resp.data.uid, resp.data.user_uids)
            this.setState({
              isOpen: true,
              message: 'Submitted Successfully',
              type: 'success'
            })
            this.setState({ openTaskForm: false })
            this.setState({ openTaskPopUp: false })
          })
          .catch((resp) => {
            console.log(resp)
          })
      } else if (
        this.state.defaultCase.uid !== undefined &&
        this.state.legalCase.uid === undefined
      ) {
        if (item.uid === '') {
          method = 'post'
          url =
            process.env.REACT_APP_API_DOMAIN +
            '/v1/legal_cases/' +
            this.state.defaultCase.uid +
            '/tasks/'
        } else {
          method = 'put'
          url = process.env.REACT_APP_API_DOMAIN + '/v1/tasks/' + item.uid
        }
        axios({
          method: method,
          url: url,
          data: item,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        })
          .then((resp) => {
            const newData = {}
            newData['id'] = resp.data.uid
            newData['title'] = resp.data.name
            newData['start'] = resp.data.ends_at
            ;(newData['color'] = this.getColor(
              item.priority_id,
              this.state.priorityLevels
            )),
              (newData['allDay'] = true)
            this.setState({
              tasks: this.state.tasks.filter((tasks) => tasks.id !== item.uid)
            })
            this.setState({ tasks: [...this.state.tasks, newData] })
            this.handleSubmitSubTasks(resp.data.uid, resp.data.user_uids)
            this.setState({
              isOpen: true,
              message: 'Submitted Successfully',
              type: 'success'
            })
            this.setState({ openTaskForm: false })
            this.setState({ openTaskPopUp: false })
          })
          .catch((resp) => {
            console.log(resp)
          })
      } else {
        if (item.uid === '') {
          method = 'post'
          url =
            process.env.REACT_APP_API_DOMAIN +
            '/v1/legal_cases/' +
            this.state.defaultCase.uid +
            '/tasks/'
        } else {
          method = 'put'
          url = process.env.REACT_APP_API_DOMAIN + '/v1/tasks/' + item.uid
        }
        axios({
          method: method,
          url: url,
          data: item,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        })
          .then((resp) => {
            const newData = {}
            newData['id'] = resp.data.uid
            newData['title'] = resp.data.name
            newData['start'] = resp.data.ends_at
            ;(newData['color'] = this.getColor(
              item.priority_id,
              this.state.priorityLevels
            )),
              (newData['allDay'] = true)
            this.setState({
              tasks: this.state.tasks.filter((task) => task.id !== item.uid)
            })
            this.setState({ tasks: [...this.state.tasks, newData] })
            this.handleSubmitSubTasks(resp.data.uid, resp.data.user_uids)
            this.setState({
              isOpen: true,
              message: 'Submitted Successfully',
              type: 'success'
            })
            this.setState({ openTaskForm: false })
            this.setState({ openTaskPopUp: false })
          })
          .catch((resp) => {
            console.log(resp)
          })
      }
    }
    this.setState({ caseUsers: [] })
  }

  handleSubmitSubTasks(id, userIds) {
    if (this.state.subTasks.length > 0) {
      this.state.subTasks.map((subTask) => {
        var item = {}
        var method = ''
        var url = ''
        item['uid'] = subTask.uid
        item['name'] = subTask.name
        item['description'] = subTask.description
        item['user_ids'] = userIds
        item['tag_list'] = subTask.tag_list
        item['ends_at'] = subTask.ends_at
        item['parent_id'] = id
        if (item.uid === '') {
          method = 'post'
          url = process.env.REACT_APP_API_DOMAIN + '/v1/tasks/'
        } else {
          method = 'put'
          url = process.env.REACT_APP_API_DOMAIN + '/v1/tasks/' + item.uid
        }
        axios({
          method: method,
          url: url,
          data: item,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        })
          .then((resp) => {
            const newData = resp.data
            this.setState({
              tasks: this.state.tasks.filter((tasks) => tasks.id !== item.uid)
            })
            this.setState({ tasks: [...this.state.tasks, newData] })
          })
          .catch((error) => console.log(error.response))
      })
    }
  }

  handleDragEvent(EventDropArg) {
    const {
      event: {
        _def: { publicId },
        _instance: { range }
      }
    } = EventDropArg

    let selectedEvent = this.state.events.find((event) => event.id === publicId)
    let selectedTask = this.state.tasks.find((task) => task.id === publicId)

    const isEventSelected = selectedEvent ? true : false
    const startTime = isEventSelected
      ? new Date(selectedEvent.start)
      : new Date(selectedTask.start)
    const endTime = isEventSelected ? new Date(selectedEvent.end) : null

    if (isEventSelected) {
      range.end.setHours(endTime.getHours())
      range.end.setMinutes(endTime.getMinutes())
    }
    range.start.setHours(startTime.getHours())
    range.start.setMinutes(startTime.getMinutes())
    if (range.end.getHours() >= 16) {
      range.end.setDate(range.end.getDate() - 1)
    }

    const item = isEventSelected
      ? {
          uid: publicId,
          starts_at: range.start,
          ends_at: range.end
        }
      : {
          uid: publicId,
          ends_at: range.start
        }

    axios({
      method: 'PUT',
      url:
        process.env.REACT_APP_API_DOMAIN +
        `/v1/${isEventSelected ? 'events' : 'tasks'}/` +
        publicId,
      data: item,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        const newItem = resp.data

        this.setState({
          tableEvents: this.state.tableEvents.filter(
            (event) => event.uid !== item.uid
          )
        })
        this.setState({ tableEvents: [...this.state.tableEvents, newItem] })

        const newData = isEventSelected
          ? {
              id: resp.data.uid,
              title: resp.data.name,
              start: resp.data.starts_at,
              end: resp.data.ends_at,
              color: '#00695c'
            }
          : {
              id: resp.data.uid,
              title: resp.data.name,
              color: selectedTask.color,
              allDay: selectedTask.allDay,
              start: resp.data.ends_at
            }

        const stateToUpdate = isEventSelected ? 'events' : 'tasks'

        this.setState({
          [stateToUpdate]: this.state[stateToUpdate].filter(
            (element) => element.id !== item.uid
          )
        })
        this.setState({
          [stateToUpdate]: [...this.state[stateToUpdate], newData]
        })

        this.setState({
          isOpen: true,
          message: 'Drop Successfully',
          type: 'success'
        })
      })
      .catch((error) => {
        this.setState({
          isOpen: true,
          message:
            error.response.status.toString() +
            ' Unexpected Error Problem Occurred',
          type: 'error'
        })
      })
  }

  handleEventSubmit() {
    var item = {}
    var userIds = []
    if (this.state.eventUsersAutocomplete.length > 0) {
      this.state.eventUsersAutocomplete.map((user) => {
        userIds.push(user.uid)
      })
    }
    item['uid'] = this.state.event.uid
    item['name'] = this.state.event.name
    item['description'] = this.state.event.description
    item['user_ids'] = userIds
    item['starts_at'] = this.state.event.starts_at
    item['ends_at'] = this.state.event.ends_at

    var method = ''
    var url = ''
    if (this.state.show === true) {
      if (item.uid === '') {
        method = 'post'
        url = process.env.REACT_APP_API_DOMAIN + '/v1/events/'
      } else {
        method = 'put'
        url = process.env.REACT_APP_API_DOMAIN + '/v1/events/' + item.uid
      }
      axios({
        method: method,
        url: url,
        data: item,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      })
        .then((resp) => {
          const newItem = resp.data
          this.setState({
            tableEvents: this.state.tableEvents.filter(
              (event) => event.uid !== item.uid
            )
          })
          this.setState({ tableEvents: [...this.state.tableEvents, newItem] })

          const newData = {}
          newData['id'] = resp.data.uid
          newData['title'] = resp.data.name
          newData['start'] = resp.data.starts_at
          newData['end'] = resp.data.ends_at
          newData['color'] = '#00695c'

          this.setState({
            events: this.state.events.filter((event) => event.id !== item.uid)
          })
          this.setState({ events: [...this.state.events, newData] })
          this.setState({
            isOpen: true,
            message: 'Submitted Successfully',
            type: 'success'
          })
          this.setState({ openEventForm: false })
          this.setState({ openEventPopUp: false })
        })
        .catch((error) => {
          this.setState({
            isOpen: true,
            message:
              error.response.status.toString() +
              ' Unexpected Error Problem Occurred',
            type: 'error'
          })
          this.setState({ openPopup: false })
        })
    } else {
      if (this.state.defaultCase.uid === this.state.legalCase.uid) {
        if (item.uid === '') {
          method = 'post'
          url =
            process.env.REACT_APP_API_DOMAIN +
            '/v1/legal_cases/' +
            this.state.defaultCase.uid +
            '/events/'
        } else {
          method = 'put'
          url = process.env.REACT_APP_API_DOMAIN + '/v1/events/' + item.uid
        }
        axios({
          method: method,
          url: url,
          data: item,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        })
          .then((resp) => {
            const newItem = resp.data
            this.setState({
              tableEvents: this.state.tableEvents.filter(
                (event) => event.uid !== item.uid
              )
            })
            this.setState({ tableEvents: [...this.state.tableEvents, newItem] })

            const newData = {}
            newData['id'] = resp.data.uid
            newData['title'] = resp.data.name
            newData['start'] = resp.data.starts_at
            newData['end'] = resp.data.ends_at
            newData['color'] = '#00695c'

            this.setState({
              events: this.state.events.filter((event) => event.id !== item.uid)
            })
            this.setState({ events: [...this.state.events, newData] })
            this.setState({
              isOpen: true,
              message: 'Submitted Successfully',
              type: 'success'
            })
            this.setState({ openEventForm: false })
            this.setState({ openEventPopUp: false })
          })
          .catch((resp) => {
            console.log(resp)
          })
      } else if (
        this.state.defaultCase.uid === undefined &&
        this.state.legalCase.uid !== undefined
      ) {
        axios({
          method: 'post',
          url:
            process.env.REACT_APP_API_DOMAIN +
            '/v1/legal_cases/' +
            this.state.legalCase.uid +
            '/events/',
          data: item,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        })
          .then((resp) => {
            const newItem = resp.data
            this.setState({
              tableEvents: this.state.tableEvents.filter(
                (event) => event.uid !== item.uid
              )
            })
            this.setState({ tableEvents: [...this.state.tableEvents, newItem] })

            const newData = {}
            newData['id'] = resp.data.uid
            newData['title'] = resp.data.name
            newData['start'] = resp.data.starts_at
            newData['end'] = resp.data.ends_at
            newData['color'] = '#00695c'
            this.setState({
              events: this.state.events.filter((event) => event.id !== item.uid)
            })
            this.setState({ events: [...this.state.events, newData] })
            this.setState({
              isOpen: true,
              message: 'Submitted Successfully',
              type: 'success'
            })
            this.setState({ openEventForm: false })
            this.setState({ openEventPopUp: false })
          })
          .catch((resp) => {
            console.log(resp)
          })
      } else if (
        this.state.defaultCase.uid !== undefined &&
        this.state.legalCase.uid === undefined
      ) {
        if (item.uid === '') {
          method = 'post'
          url =
            process.env.REACT_APP_API_DOMAIN +
            '/v1/legal_cases/' +
            this.state.defaultCase.uid +
            '/events/'
        } else {
          method = 'put'
          url = process.env.REACT_APP_API_DOMAIN + '/v1/events/' + item.uid
        }
        axios({
          method: method,
          url: url,
          data: item,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        })
          .then((resp) => {
            const newItem = resp.data
            this.setState({
              tableEvents: this.state.tableEvents.filter(
                (event) => event.uid !== item.uid
              )
            })
            this.setState({ tableEvents: [...this.state.tableEvents, newItem] })

            const newData = {}
            newData['id'] = resp.data.uid
            newData['title'] = resp.data.name
            newData['start'] = resp.data.starts_at
            newData['end'] = resp.data.ends_at
            newData['color'] = '#00695c'
            this.setState({
              events: this.state.events.filter((event) => event.id !== item.uid)
            })
            this.setState({ events: [...this.state.events, newData] })
            this.setState({
              isOpen: true,
              message: 'Submitted Successfully',
              type: 'success'
            })
            this.setState({ openEventForm: false })
            this.setState({ openEventPopUp: false })
          })
          .catch((resp) => {
            console.log(resp)
          })
      } else if (
        this.state.defaultCase.uid !== undefined &&
        this.state.legalCase.uid !== undefined &&
        this.state.defaultCase.uid !== this.state.legalCase.uid
      ) {
        axios({
          method: 'delete',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/events/' + item.uid,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        })
          .then(() => {
            this.setState({
              events: this.state.events.filter((event) => event.id !== item.uid)
            })
            this.setState({
              tableEvents: this.state.tableEvents.filter(
                (event) => event.uid !== item.uid
              )
            })

            axios({
              method: 'post',
              url:
                process.env.REACT_APP_API_DOMAIN +
                '/v1/legal_cases/' +
                this.state.legalCase.uid +
                '/events/',
              data: item,
              headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
            })
              .then((resp) => {
                const newData = {}
                newData['id'] = resp.data.uid
                newData['title'] = resp.data.name
                newData['start'] = resp.data.starts_at
                newData['end'] = resp.data.ends_at
                newData['color'] = '#00695c'
                const newItem = resp.data
                this.setState({
                  tableEvents: this.state.tableEvents.filter(
                    (event) => event.uid !== item.uid
                  )
                })
                this.setState({
                  tableEvents: [...this.state.tableEvents, newItem]
                })

                this.setState({
                  events: this.state.events.filter(
                    (event) => event.id !== item.uid
                  )
                })
                this.setState({ events: [...this.state.events, newData] })
                this.setState({
                  isOpen: true,
                  message: 'Submitted Successfully',
                  type: 'success'
                })
                this.setState({ openEventForm: false })
                this.setState({ openEventPopUp: false })
              })
              .catch((resp) => {
                console.log(resp)
              })
          })
          .catch((resp) => {
            console.log(resp)
          })
      } else {
        if (item.uid === '') {
          method = 'post'
          url =
            process.env.REACT_APP_API_DOMAIN +
            '/v1/legal_cases/' +
            this.state.defaultCase.uid +
            '/events/'
        } else {
          method = 'put'
          url = process.env.REACT_APP_API_DOMAIN + '/v1/events/' + item.uid
        }
        axios({
          method: method,
          url: url,
          data: item,
          headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
        })
          .then((resp) => {
            const newItem = resp.data
            this.setState({
              tableEvents: this.state.tableEvents.filter(
                (event) => event.uid !== item.uid
              )
            })
            this.setState({ tableEvents: [...this.state.tableEvents, newItem] })

            const newData = {}
            newData['id'] = resp.data.uid
            newData['title'] = resp.data.name
            newData['start'] = resp.data.starts_at
            newData['end'] = resp.data.ends_at
            newData['color'] = '#00695c'

            this.setState({
              events: this.state.events.filter((event) => event.id !== item.uid)
            })
            this.setState({ events: [...this.state.events, newData] })
            this.setState({
              isOpen: true,
              message: 'Submitted Successfully',
              type: 'success'
            })
            this.setState({ openEventForm: false })
            this.setState({ openEventPopUp: false })
          })
          .catch((resp) => {
            console.log(resp)
          })
      }
    }
    this.setState({ eventCaseUsers: [] })
  }

  handleEventDateChange(e) {
    const value = e.toLocaleDateString('fr-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
    this.setState({
      task: {
        ...this.state.task,
        ends_at: value
      }
    })
  }

  handleStartChange(e) {
    const value = e
    this.setState({
      event: {
        ...this.state.event,
        starts_at: value
      }
    })
  }

  handleEndChange(e) {
    this.setState({
      event: {
        ...this.state.event,
        ends_at: e
      }
    })
  }
  handleUsersAutocomplete(event, values) {
    if (values !== null) {
      this.setState({ taskUsersAutocomplete: values })
    }
  }

  handleEventUsersAutocomplete(event, values) {
    if (values !== null) {
      this.setState({ eventUsersAutocomplete: values })
    }
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    this.setState({
      isOpen: false
    })
  }

  toggleView() {
    this.setState({ tableView: !this.state.tableView })
  }

  handleSwitchTimeBase(e) {
    this.setState({
      task: {
        ...this.state.task,
        is_time_base: e.target.checked
      }
    })
  }

  render() {
    const { load, loadEventSourcesError } = this.state
    var eventCalendar = [
      {
        events: this.state.events,
        color: '#00695c',
        textColor: 'white'
      },
      {
        events: this.state.tasks,
        textColor: 'white'
      }
    ]
    return (
      <>
        {load && !loadEventSourcesError ? (
          <>
            {this.state.tableView ? (
              <Box className={styles.boxMain}>
                <SimpleTable
                  handleClick={this.handleCreateOrUpdateEvent}
                  initialItem={this.state.initialEvent}
                  items={this.state.tableEvents}
                  model="Event"
                  withEdit={true}
                  onOpenDeletePopup={this.handleOpenDeletePopup}
                  headers={this.state.tableHead}
                  users={this.state.users}
                  toggleView={this.toggleView}
                  withCalendar={true}
                />
              </Box>
              
            ) : (
              <Calendar
                eventSources={eventCalendar}
                handleEventClick={this.handleEventClick}
                initialEvent={this.state.initialEvent}
                handleCreateOrUpdateEvent={this.handleCreateOrUpdateEvent}
                toggleView={this.toggleView}
                tableView={this.state.tableView}
                priorityLevels={this.state.priorityLevels}
                handleDragEvent={this.handleDragEvent}
              />
            )}

            <SimplePopUp
              openPopup={this.state.openEventPopUp}
              title="Event Details"
              handleClose={this.handleClose}
              maxWidth="sm"
            >
              <Event
                event={this.state.event}
                users={this.state.users}
                handleClose={this.handleClose}
                handleCreateOrUpdateEvent={this.handleCreateOrUpdateEvent}
                onOpenDeletePopup={this.handleOpenDeletePopup}
              />
            </SimplePopUp>

            <SimplePopUp
              openPopup={this.state.openTaskPopUp}
              title="Task Details"
              handleClose={this.handleClose}
              maxWidth="sm"
            >
              <Task
                task={this.state.task}
                users={this.state.users}
                handleCreateOrUpdateTask={this.handleCreateOrUpdateTask}
                handleClose={this.handleClose}
                priorities={this.state.priorityLevels}
              />
            </SimplePopUp>

            <SimplePopUp
              openPopup={this.state.openTaskForm}
              handleClose={this.handleClose}
              maxWidth="md"
              title={this.state.title}
            >
              <TaskForm
                task={this.state.task}
                legalCase={this.state.legalCase}
                defaultCase={this.state.defaultCase}
                cases={this.state.cases}
                users={this.state.users}
                getDefaultValue={this.getDefaultValue}
                handleAutoComplete={this.handleTaskAutoComplete}
                submit={this.handleSubmit}
                handleCheck={this.handleCheck}
                show={this.state.show}
                subTasks={this.state.subTasks}
                initialTask={this.state.initialTask}
                handleTaskChange={this.handleTaskChange}
                addSubTask={this.addSubTask}
                removeSubTask={this.removeSubTask}
                handleSubTasks={this.handleSubTasks}
                handleChange={this.handleEventDateChange}
                caseUsers={this.state.caseUsers}
                handleUsersAutocomplete={this.handleUsersAutocomplete}
                withCaseLink={true}
                selectedValues={this.state.taskUsersAutocomplete}
                handleSwitch={this.handleSwitchTimeBase}
                priorities={this.state.priorityLevels}
              />
            </SimplePopUp>

            <SimplePopUp
              openPopup={this.state.openEventForm}
              handleClose={this.handleClose}
              maxWidth="md"
              title={this.state.title}
            >
              <EventForm
                event={this.state.event}
                legalCase={this.state.legalCase}
                defaultCase={this.state.defaultCase}
                cases={this.state.cases}
                users={this.state.users}
                getDefaultValue={this.getDefaultValue}
                handleEventAutoComplete={this.handleEventAutoComplete}
                submit={this.handleEventSubmit}
                handleCheck={this.handleCheck}
                show={this.state.show}
                handleEventChange={this.handleEventChange}
                handleEndChange={this.handleEndChange}
                handleStartChange={this.handleStartChange}
                eventCaseUsers={this.state.eventCaseUsers}
                handleEventUsersAutocomplete={this.handleEventUsersAutocomplete}
                withCase={true}
                selectedValues={this.state.eventUsersAutocomplete}
              />
            </SimplePopUp>

            <SimpleDeletePopUp
              openDeletePopup={this.state.openDeletePopup}
              item={this.state.event}
              delete={this.handleDeleteItem}
              handleDeleteClose={this.handleClose}
              model={this.state.model}
            />
            <AlertMessage
              notify={this.state.notify}
              handleCloseAlert={this.handleCloseAlert}
              isOpen={this.state.isOpen}
              type={this.state.type}
              message={this.state.message}
            />
          </>
        ) : loadEventSourcesError ? (
          <ErrorPage type={loadEventSourcesError} />
        ) : (
          <ProgressBar />
        )}
      </>
    )
  }
}

export default Events
