import React from 'react'
import axios from 'axios'
import { TextField, Button, Grid } from '@material-ui/core'
import { HexColorPicker } from 'react-colorful'

import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import AlertMessage from '../../shared/Notify/AlertMessage'
import ProgressBar from '../../shared/ProgressBar/ProgressBar'
import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import ErrorPage from '../../shared/ErrorPage/ErrorPage'

class Priority extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Prioritie',
      priorities: [],
      openPopup: false,
      openDeletePopup: false,
      priority: {},
      initialpriority: { uid: '', name: '', color: '#fff' },
      title: '',
      load: false,
      tableHead: ['Name', 'Color', 'Rank'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: '',
      loadPrioritiesError: null
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleColorChange = this.handleColorChange.bind(this)
    this.handleCloseFromShow = this.handleCloseFromShow.bind(this)
  }

  componentDidMount() {
    this.loadpriorities(process.env.REACT_APP_API_DOMAIN + '/v1/priorities')
  }

  loadpriorities(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        this.setState({ priorities: resp.data })
        this.setState({ load: true })
      })
      .catch((error) => {
        if (error.response.status === 422) {
          this.setState({
            error_messages: 'Name ' + error.response.data.errors.name[0]
          })
        } else {
          this.setState({
            isOpen: true,
            message:
              error.response.status.toString() +
              ' Unexpected Error Problem Occurred',
            type: 'error'
          })
        }

        this.setState({ loadPrioritiesError: error.response.status })
      })
  }

  handleSubmit() {
    const item = this.state.priority

    var method = ''
    var url = ''
    if (item.uid === '' || item.uid === undefined) {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/priorities'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/priorities/' + item.uid
    }
    axios({
      method: method,
      url: url,
      data: item,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        console.log(resp.data)
        const newData = resp.data
        this.setState({
          priorities: this.state.priorities.filter(
            (priority) => priority.uid !== item.uid
          )
        })
        this.setState({ priorities: [...this.state.priorities, newData] })
        this.setState({
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success'
        })
        this.setState({ openPopup: false })
      })
      .catch((error) => {
        if (error.response.status === 422) {
          this.setState({
            error_messages: 'Name ' + error.response.data.errors.name[0]
          })
        } else {
          this.setState({
            isOpen: true,
            message:
              error.response.status.toString() +
              ' Unexpected Error Problem Occurred',
            type: 'error'
          })
          this.setState({ openPopup: false })
        }
      })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.priority.uid

    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/priorities/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then(() => {
        this.setState({
          priorities: this.state.priorities.filter(
            (item) => item.uid !== DeleteItemId
          )
        })
        this.setState({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'error'
        })
        this.setState({ openDeletePopup: false })
      })
      .catch((error) => console.log(error.response))
  }

  handleClose() {
    this.setState({ openPopup: false })
    this.setState({ openDeletePopup: false })
    this.setState({ openShowPopUp: false })
    this.setState({ error_messages: '' })
  }

  handleCreateorUpdateItem(item, isAdd) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create Priority'
    } else {
      textTitle = 'Edit Priority'
    }

    this.setState({ openPopup: true })
    this.setState({ priority: item })
    this.setState({ title: textTitle })
  }

  handleInputChange(e) {
    this.setState({
      priority: {
        ...this.state.priority,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item) {
    this.setState({ priority: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  handleCloseFromShow() {
    this.setState({ openDeletePopup: false })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    this.setState({
      isOpen: false
    })
  }

  handleColorChange(color) {
    console.log(color)
    this.setState({
      priority: {
        ...this.state.priority,
        color: color
      }
    })
  }

  render() {
    const { load, loadPrioritiesError } = this.state

    return (
      <>
        {load && !loadPrioritiesError ? (
          <div>
            <SimpleTable
              handleClick={this.handleCreateorUpdateItem}
              initialItem={this.state.initialpriority}
              items={this.state.priorities}
              model={this.state.model}
              onOpenDeletePopup={this.handleOpenDeletePopup}
              headers={this.state.tableHead}
              withShow={this.state.withShow}
              withEdit={this.state.withEdit}
              isDeduction={true}
              handleOpenShowPopUp={this.handleOpenShowPopUp}
            />
            <SimplePopUp
              openPopup={this.state.openPopup}
              title={this.state.title}
              items={this.state.priorities}
              handleClose={this.handleClose}
              maxWidth="xs"
            >
              <form>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="name"
                      style={{ margin: '12px 0' }}
                      name="name"
                      label="Name"
                      size="small"
                      variant="outlined"
                      fullWidth
                      value={this.state.priority.name}
                      onChange={this.handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="rank"
                      type="number"
                      style={{ margin: '12px 0' }}
                      name="rank"
                      label="Rank"
                      size="small"
                      variant="outlined"
                      fullWidth
                      value={this.state.priority.rank}
                      onChange={this.handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id="color"
                      style={{ margin: '12px 0' }}
                      name="color"
                      label="Hex Color"
                      size="small"
                      variant="outlined"
                      fullWidth
                      value={this.state.priority.color}
                      onChange={this.handleInputChange}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div style={{ height: '210px' }}>
                      Pick Color Here:
                      <HexColorPicker
                        style={{ width: '100%', marginTop: '10px' }}
                        color={this.state.priority.color}
                        onChange={this.handleColorChange}
                      />
                    </div>
                  </Grid>
                </Grid>
              </form>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '30px'
                }}
              >
                <Button
                  onClick={this.handleClose}
                  variant="outlined"
                  color="secondary"
                  style={{ marginRight: '10px' }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.handleSubmit}
                  variant="outlined"
                  color="primary"
                >
                  Save
                </Button>
              </div>
            </SimplePopUp>
            <SimpleDeletePopUp
              openDeletePopup={this.state.openDeletePopup}
              item={this.state.priority}
              delete={this.handleDeleteItem}
              handleDeleteClose={this.handleCloseFromShow}
              model={this.state.model}
            />
            <AlertMessage
              notify={this.state.notify}
              handleCloseAlert={this.handleCloseAlert}
              isOpen={this.state.isOpen}
              type={this.state.type}
              message={this.state.message}
            />
          </div>
        ) : loadPrioritiesError ? (
          <ErrorPage type={loadPrioritiesError} />
        ) : (
          <ProgressBar />
        )}
      </>
    )
  }
}

export default Priority
