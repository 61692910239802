import React, { useState } from 'react'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import { TextField, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({

  textfield: {
    width: '80%',
    right: 'auto',
    left: 'auto'
  },
  button: {
    float: 'right',
    fontWeight: 'bold'
  }
}))
const EditMatter = (props) => {
  const classes = useStyles()
  const { matters, setMatters, setOpenPopup, setMessage, setOpenSnackbar } = props
  const [matter, setMatter] = useState({ id: props.matter.uid, name: props.matter.name })
  const handleChange = (e) => {
    e.preventDefault()
    const name = e.target.value
    setMatter({ ...matter, name })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    axios.put(process.env.REACT_APP_API_DOMAIN + '/v1/matters/' + matter.id, matter)
      .then(resp => {
        console.log(resp.data)
        setMatters({ ...matters, matter })
        setOpenPopup(false)
        setMessage('Matter Edited')
        setOpenSnackbar(true)
      })
      .catch(error => console.log(error.response.data))
  }
  return (
    <>
      <form style={{ display: 'flex' }}>
        <TextField variant="outlined" required fullWidth name="Name" value={matter.name} onChange={handleChange}/>
      </form><br />
      <Button className={classes.button} variant="contained" onClick={handleSubmit}>Update</Button>

    </>

  )
}
export default EditMatter

EditMatter.propTypes = {
  matter: PropTypes.object,
  matters: PropTypes.array,
  setMatters: PropTypes.func,
  setOpenPopup: PropTypes.func,
  setOpenSnackbar: PropTypes.func,
  setMessage: PropTypes.func
}
