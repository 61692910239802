import React from 'react'

import axios from 'axios'

import PropTypes from 'prop-types'
import SessionsForm from './SessionsForm'
class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {username: '', password: ''},
      redirect: false,
      apiKey: '',
      formLabel: 'Sign in',
      errors: '',
      openCaptchaPopup: false

    }
    this.saveToken = this.saveToken.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this._handleKeyPress = this._handleKeyPress.bind(this)
    this.toggleCaptchaPopup = this.toggleCaptchaPopup.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  saveToken(token) {
    localStorage.setItem('api_key', token)
  }

  handleChange(e) {
    e.preventDefault()
    this.setState({
      user: {
        ...this.state.user,
        [e.target.name]: e.target.value
      }
    })
  }

  _handleKeyPress(e) {
    if (e.key === 'Enter') {
      axios.post(process.env.REACT_APP_API_DOMAIN + '/v1/users/login',
        {username: this.state.user.username, password: this.state.user.password}
      ).then(response => {

        this.props.setToken(response.data.api_key)
        this.saveToken(response.data.api_key)
        localStorage.setItem('current_user', response.data.profile.first_name)
        localStorage.setItem('current_user_id', response.data.uid)
        this.props.setLoad(true)
        this.setState({apiKey: response.data.api_key})

      }).catch(error => {
        if (error.response) {
          if (error.response.status === 404) this.setState({errors: 'Invalid Username and/or Password'})
        } else {
          this.setState({ errors: error.message })
        }
      })
    }
  }

  handleLogin() {
    axios.post(process.env.REACT_APP_API_DOMAIN + '/v1/users/login',
      {username: this.state.user.username, password: this.state.user.password}
    ).then(response => {

      this.props.setToken(response.data.api_key)
      this.saveToken(response.data.api_key)
      localStorage.setItem('current_user', response.data.profile.first_name)
      localStorage.setItem('current_user_id', response.data.uid)
      this.props.setLoad(true)
      this.setState({apiKey: response.data.api_key})

    }).catch(error => {
      if (error.response) {
        if (error.response.status === 404) this.setState({errors: 'Invalid Username and/or Password'})
      } else {
        this.setState({ errors: error.message })
      }
    })
  }

  toggleCaptchaPopup(val) {
    this.setState({ openCaptchaPopup: val })
  }

  onChange(value) {
    if(value){
      this.toggleCaptchaPopup(true)
    }
  }

  render() {
    return (
      <>
      <SessionsForm
        formLabel={this.state.formLabel}
        item={this.state.user}
        errors={this.state.errors}
        linkButtonOne="/forgot_password"
        linkButtonTwo="/unlock_account"
        linkLabelOne="Forgot password?"
        linkLabelTwo="Unlock Password"
        labelForSubmit="Login"
        handleChange={this.handleChange}
        handleSubmit={this.handleLogin}
        _handleKeyPress={this._handleKeyPress}
        isLogin={true}
        toggleCaptchaPopup={this.toggleCaptchaPopup}
        openCaptchaPopup={this.state.openCaptchaPopup}
        onChange={this.onChange}
      />
       
      </>
    )
  }

}

export default Login

Login.propTypes = {
  setToken: PropTypes.func,
  setLoad: PropTypes.func
}
