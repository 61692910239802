import React from 'react'
import TextField from '@material-ui/core/TextField'
import { Grid, IconButton } from '@material-ui/core'
import PropTypes from 'prop-types'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

class ContactPerson extends React.Component {

render(){
const nameId = `contact_person-name-${this.props.idx}`
  const designationId = `contact_person-designation-${this.props.idx}`
  const departmentId = `contact_person-department-${this.props.idx}`
  const remarksId = `contact_person-remarks-${this.props.idx}`

  return (
    <>
      <div elevation={5}>
        <IconButton
          color="secondary"
          edge="end"
          style={{ float: 'right' }}
          onClick={() => this.props.removeContactPerson(this.props.idx)}>
          <DeleteForeverIcon />
        </IconButton>
        <div style={{ display: 'flex' }} >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="off"
                style={{ margin: 8, width: '100%' }}
                name={nameId}
                id={nameId}
                className="name"
                data-idx={this.props.idx}
                size="small"
                variant="outlined"
                value={this.props.contactPersons[this.props.idx].name}
                onChange={this.props.handleContactPersonChange}
                label="Name"
                inputProps={{ 'data-id': this.props.idx, 'data-field-type': 'name' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="off"
                style={{ margin: 8, width: '100%' }}
                name={designationId}
                size="small"
                id={designationId}
                className="position"
                data-idx={this.props.idx}
                variant="outlined"
                value={this.props.contactPersons[this.props.idx].designation}
                onChange={this.props.handleContactPersonChange}
                label="Designation"
                inputProps={{ 'data-id': this.props.idx, 'data-field-type': 'designation' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                autoComplete="off"
                size="small"
                style={{ margin: 8, width: '100%' }}
                name={departmentId}
                id={departmentId}
                className="department"
                data-idx={this.props.idx}
                value={this.props.contactPersons[this.props.idx].department}
                onChange={this.props.handleContactPersonChange}
                label="Department"
                inputProps={{ 'data-id': this.props.idx, 'data-field-type': 'department' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                autoComplete="off"
                style={{ margin: 8, width: '100%' }}
                name={remarksId}
                id={remarksId}
                size="small"
                className="remarks"
                data-idx={this.props.idx}
                value={this.props.contactPersons[this.props.idx].remarks}
                onChange={this.props.handleContactPersonChange}
                label="Remarks"
                inputProps={{ 'data-id': this.props.idx, 'data-field-type': 'remarks' }}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
    }
}
export default ContactPerson

ContactPerson.propTypes = {
  idx: PropTypes.number,
  contactPersons: PropTypes.array,
  setContactPersons: PropTypes.func,
  handleContactPersonChange: PropTypes.func,
  setClient: PropTypes.func,
  client: PropTypes.object,
  removeContactPerson: PropTypes.func
}
