import React from 'react'
import axios from 'axios'
import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import AlertMessage from '../../shared/Notify/AlertMessage'
import AreaForm from './AreaForm'
import ProgressBar from '../../shared/ProgressBar/ProgressBar'
import ErrorPage from '../../shared/ErrorPage/ErrorPage'
import { Box } from '@material-ui/core'
import styles from "./PracticeArea.module.css"

class Areas extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Practice Area',
      areas: [],
      openPopup: false,
      openDeletePopup: false,
      area: {},
      initialArea: { uid: '', name: '' },
      title: '',
      load: false,
      tableHead: ['Name'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: '',
      draftAreas: [],
      queryString: '',
      loadPracticeAreasError: null,
      rowsPerPage: 1000,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleChangeQueryField = this.handleChangeQueryField.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
  }

  componentDidMount() {
    this.loadPracticeAreas(
      process.env.REACT_APP_API_DOMAIN + `/v1/practice_areas?per_page=${this.state.rowsPerPage}`
    )
  }

  loadPracticeAreas(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        this.setState({ areas: resp.data.data })
        this.setState({ load: true })
        this.setState({ draftAreas: resp.data.data })
      })
      .catch((error) =>
        this.setState({ loadPracticeAreasError: error.response.status })
      )
  }

  handleSubmit() {
    const item = this.state.area
    var method = ''
    var url = ''
    if (item.uid === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/practice_areas/'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/practice_areas/' + item.uid
    }
    axios({
      method: method,
      url: url,
      data: item,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        const newData = resp.data
        this.setState({
          areas: this.state.areas.filter((area) => area.uid !== item.uid)
        })
        this.setState({ areas: [...this.state.areas, newData] })
        this.setState({ isOpen: true })
        this.setState({
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success'
        })
        this.setState({ openPopup: false })
      })
      .catch((error) => {
        if (error.response.status === 422) {
          this.setState({
            error_messages: 'Name ' + error.response.data.errors.name[0]
          })
        } else {
          this.setState({
            isOpen: true,
            message:
              error.response.status.toString() +
              ' Unexpected Error Problem Occurred',
            type: 'error'
          })
          this.setState({ openPopup: false })
        }
      })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.area.uid
    axios({
      method: 'delete',
      url:
        process.env.REACT_APP_API_DOMAIN + '/v1/practice_areas/' + DeleteItemId,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then(() => {
        this.setState({
          areas: this.state.areas.filter((item) => item.uid !== DeleteItemId)
        })
        this.setState({ openDeletePopup: false })
        this.setState({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'error'
        })
      })
      .catch((error) => console.log(error.response))
  }

  handleClose() {
    this.setState({ openPopup: false })
    this.setState({ openDeletePopup: false })
    this.setState({ error_messages: '' })
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({ openPopup: true })
    this.setState({ area: item })
    this.setState({ title: textTitle })
  }

  handleInputChange(e) {
    this.setState({
      area: {
        ...this.state.area,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ area: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    this.setState({
      isOpen: false
    })
  }

  handleChangeQueryField(event) {
    this.setState({ queryString: event.target.value })
  }

  handleKeyPress(event) {
    const { queryString, draftAreas } = this.state

    if (event.key !== 'Enter') return

    if (queryString === '') {
      this.setState({ areas: draftAreas })
    } else {
      axios({
        method: 'get',
        url:
          process.env.REACT_APP_API_DOMAIN +
          `/v1/practice_areas?q=*${queryString}*`,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      }).then((resp) => {
        this.setState({ areas: resp.data.data })
      })
    }
  }

  clearSearch() {
    this.setState({ queryString: '' })
  }

  render() {
    const { load, loadPracticeAreasError } = this.state

    return (
      <>
        {load && !loadPracticeAreasError ? (
          <>
            <Box className={styles.boxMain}>
              <Box className={styles.boxTable}>
                <SimpleTable
                  handleClick={this.handleCreateorUpdateItem}
                  initialItem={this.state.initialArea}
                  items={this.state.areas.sort((a, b) =>
                    a.name > b.name ? 1 : -1
                  )}
                  model={this.state.model}
                  onOpenDeletePopup={this.handleOpenDeletePopup}
                  headers={this.state.tableHead}
                  withShow={this.state.withShow}
                  withEdit={this.state.withEdit}
                  withSearch={true}
                  _handleKeyPress={this.handleKeyPress}
                  handleChanges={this.handleChangeQueryField}
                  clearSearch={this.clearSearch}
                  q={this.state.queryString}
                />
              </Box>
            </Box>
            <SimplePopUp
              openPopup={this.state.openPopup}
              title={this.state.title}
              items={this.state.areas}
              handleClose={this.handleClose}
              maxWidth={this.state.maxWidth}
            >
              <AreaForm
                error={this.state.error_messages}
                item={this.state.area}
                submit={this.handleSubmit}
                onchange={this.handleInputChange}
              />
            </SimplePopUp>

            <SimpleDeletePopUp
              openDeletePopup={this.state.openDeletePopup}
              item={this.state.area}
              delete={this.handleDeleteItem}
              handleDeleteClose={this.handleClose}
              model={this.state.model}
            />
            <AlertMessage
              notify={this.state.notify}
              handleCloseAlert={this.handleCloseAlert}
              isOpen={this.state.isOpen}
              type={this.state.type}
              message={this.state.message}
            />
          </>
        ) : loadPracticeAreasError ? (
          <ErrorPage type={loadPracticeAreasError} />
        ) : (
          <ProgressBar model={this.state.model} />
        )}
      </>
    )
  }
}

export default Areas
