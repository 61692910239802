import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import PermissionNewForm from './PermissionNewForm'
import { Redirect } from 'react-router-dom'

class PermissionNewUpdate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOptions: [],
      selectedRoles: [],
      roles: [],
      users: [],
      load: false,
      updateUsers: [],
      redirect: false,
      permission: { uid: '', name: '' },
      selectAllLabel: 'Select all',
      checked: false
    }
    this.handleToggle = this.handleToggle.bind(this)
    this.handleChangeParentCheck = this.handleChangeParentCheck.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleUsersAutocomplete = this.handleUsersAutocomplete.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  componentDidMount() {
    if (this.props.match.params.permission_id !== undefined) {
      axios({
        method: 'get',
        url:
          process.env.REACT_APP_API_DOMAIN +
          '/v1/permissions/' +
          this.props.match.params.permission_id,
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      })
        .then((resp) => {
          this.setState({ permission: resp.data, load: true })
        })
        .catch((error) => console.log(error.response))
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/members',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      })
        .then((resp) => {
          this.setState({ users: resp.data })
        })
        .catch((error) => console.log(error.response))
      axios({
        method: 'get',
        url:
          process.env.REACT_APP_API_DOMAIN +
          '/v1/permissions/' +
          this.props.match.params.permission_id +
          '/roles',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      })
        .then((resp) => {
          var selectedArrays = resp.data
          axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/roles',
            headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
          })
            .then((resp) => {
              this.setState({ roles: resp.data })
              var rolesArray = resp.data
              var selectedArray = []
              rolesArray.map((pair) => {
                selectedArrays.map((selected) => {
                  if (selected.uid === pair.uid) selectedArray.push(pair)
                })
              })
              this.setState({ selectedRoles: selectedArray })
            })
            .catch((error) => console.log(error.response))
        })
        .catch((error) => console.log(error.response))
      axios({
        method: 'get',
        url:
          process.env.REACT_APP_API_DOMAIN +
          '/v1/permissions/' +
          this.props.match.params.permission_id +
          '/users',
        headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
      })
        .then((resp) => {
          this.setState({ updateUsers: resp.data })
        })
        .catch((error) => console.log(error.response))
    } else {
      this.loadPermissions(process.env.REACT_APP_API_DOMAIN + '/v1/permissions')
    }
  }

  loadPermissions(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        this.setState({ permissions: resp.data, load: true })
      })
      .catch((error) => console.log(error.response))
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/members',
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        this.setState({ users: resp.data })
      })
      .catch((error) => console.log(error.response))
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/roles',
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    })
      .then((resp) => {
        this.setState({ roles: resp.data })
      })
      .catch((error) => console.log(error.response))
  }

  handleChangeParentCheck(event, item) {
    var checkAllByModel = this.state.selectedRoles
    if (event.target.checked === true) {
      if (this.state.roles.length > 0) {
        this.state.roles.map((list) => {
          if (item === list.resource_type) {
            checkAllByModel.push(list)
          }
        })
        return this.setState({ selectedRoles: checkAllByModel })
      }
    } else {
      if (this.state.roles.length > 0) {
        this.state.roles.map((list) => {
          if (item === list.resource_type) {
            checkAllByModel.splice(checkAllByModel.indexOf(list), 1)
          }
        })
        return this.setState({ selectedRoles: checkAllByModel })
      }
    }
  }

  handleToggle(event, item) {
    var selectedArray = this.state.selectedRoles
    if (this.state.selectedRoles.indexOf(item) <= -1) {
      selectedArray.push(item)
    } else {
      selectedArray.splice(selectedArray.indexOf(item), 1)
    }
    this.setState({ selectedRoles: selectedArray })
  }

  handleSubmit() {
    const item = {
      uid: this.state.permission.uid,
      name: this.state.permission.name,
      description: this.state.permission.description
    }
    var userIds = []
    if (this.state.updateUsers.length > 0) {
      this.state.updateUsers.map((user) => {
        userIds.push(user.uid)
      })
    }
    item['user_ids'] = userIds

    var roleIds = []
    if (this.state.selectedRoles.length > 0) {
      this.state.selectedRoles.map((role) => {
        roleIds.push(role.uid)
      })
    }
    item['role_ids'] = roleIds
    var method = ''
    var url = ''
    if (item.uid === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/permissions/'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/permissions/' + item.uid
    }
    axios({
      method: method,
      url: url,
      data: item,
      headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({ redirect: true })
    })
  }

  handleUsersAutocomplete(event, values) {
    this.setState({ updateUsers: values })
  }

  handleInputChange(e) {
    this.setState({
      permission: {
        ...this.state.permission,
        [e.target.name]: e.target.value
      }
    })
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: '/permissions'
          }}
        />
      )
    }

    function groupArrayOfObjects(list, key) {
      return list.reduce(function (rv, x) {
        rv[x[key]] = rv[x[key]] || []
        rv[x[key]].push(x)
        return rv
      }, {})
    }
    var dataArray = this.state.roles !== undefined ? this.state.roles : []
    var nameModel = groupArrayOfObjects(dataArray, 'resource_type')

    return (
      <>
        {this.state.load ? (
          <div>
            <PermissionNewForm
              nameModel={nameModel}
              handleInputChange={this.handleInputChange}
              handleUsersAutocomplete={this.handleUsersAutocomplete}
              handleSubmit={this.handleSubmit}
              handleToggle={this.handleToggle}
              handleChangeParentCheck={this.handleChangeParentCheck}
              permissions={this.state.permissions}
              item={this.state.permission}
              users={this.state.users}
              roles={this.state.roles}
              updateUsers={this.state.updateUsers}
              selectedRoles={this.state.selectedRoles}
            />
          </div>
        ) : null}
      </>
    )
  }
}

export default PermissionNewUpdate

PermissionNewUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      permission_id: PropTypes.string
    })
  })
}
