import React from 'react'

import PropTypes from 'prop-types'

import axios from 'axios'

import styles from './Invoice.module.css'

import { Redirect } from 'react-router'

import SimpleTable from './../../../shared/SimpleTable/SimpleTable'
import SimplePopUp from './../../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../../shared/SimpleDeletePopUp/SimpleDeletePopUp'

import InvoiceForm from './InvoiceForm'
import SelectExistingTransactionForm from './SelectExistingTransactionForm'
import TransactionForm from '../Transaction/TransactionForm'

import ReceiptIcon from '@material-ui/icons/Receipt';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import IconButton from '@material-ui/core/IconButton'
import ExpenseTypeForm from '../Transaction/ExpenseTypeForm'
class Invoices extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			invoices: [],
			model: "Invoice",
			headers: ['reference_no', 'filed_at', 'due_date', 'grand_total'],
			openPopUp: false,
			openDeletePopUp: false,
			withEdit: true,
			withShow: true,
			load: false,
			title: "",
			invoice: {},
			doneDetails: false,
			initialInvoice: { uid: '', reference_no: '', discount: 0, due_date: new Date(new Date().setDate(new Date().getDate() + 30)).toLocaleDateString(('fr-CA')), filed_at: new Date().toLocaleDateString(('fr-CA')), paid_at: new Date().toLocaleDateString(('fr-CA')) },
			initialTransaction: { uid: '', name: '', rate: 0, rate_type: 'hourly', initialAmount: 0, amount: 0, discount: 0, transaction_type_id: '', user_id: '', remarks: '', duration: 0, is_deduction: false, filed_at: new Date().toLocaleDateString(('fr-CA')) },
			transactions: [],
			invoiceDetails: [],
			transactionMenu: [],
			initialInvoiceDetail: { uid: '', name: '', user_id: '', note: '', rate: 0, amount: 0 },
			transactionTypePopUp: false,
			openTransactionForm: false,
			openSelectExistingTransaction: false,
			transaction: {},
			transId: '',
			selectedExpenses: [],
			transMenuPopUp: [],
			selectExistingArray: [],
			filterSelection: '',
			expenseTypes: [],
			rebates: [],
			filterField: 'name',
			beforeDateFilter: '',
			endDateFilter: '',
			userFilter: '',
			typeFilter: '',
			defaultTransactionType: {},
			transactionTitle: '',
			biller: {},
			selectedExisting: [],
			transactionTypeFormPopUp: false,
			expenseType: { is_deduction: false },
			newGroup: true,
			rebateParents: [],
			expenseParents: []

		}
		this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
		this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
		this.handleClose = this.handleClose.bind(this)
		this.handleDeleteItem = this.handleDeleteItem.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
		this.handleInputChange = this.handleInputChange.bind(this)
		this.addTransaction = this.addTransaction.bind(this)
		this.removeTransaction = this.removeTransaction.bind(this)
		this.handleTransactionChange = this.handleTransactionChange.bind(this)
		this.transactionTestNumber = this.transactionTestNumber.bind(this)
		this.getTotal = this.getTotal.bind(this)
		this.getTotalDiscount = this.getTotalDiscount.bind(this)
		this.getTotalAmount = this.getTotalAmount.bind(this)
		this.handleTransactionRecord = this.handleTransactionRecord.bind(this)
		this.newTransaction = this.newTransaction.bind(this)
		this.editTransaction = this.editTransaction.bind(this)
		this.existingTransaction = this.existingTransaction.bind(this)
		this.handleCloseTransactionForms = this.handleCloseTransactionForms.bind(this)
		this.handleInputTransactionChange = this.handleInputTransactionChange.bind(this)
		this.handleSubmitTransaction = this.handleSubmitTransaction.bind(this)
		this.handleSelectTransaction = this.handleSelectTransaction.bind(this)
		this.handleSubmitSelectedTransaction = this.handleSubmitSelectedTransaction.bind(this)
		this.handleAutoComplete = this.handleAutoComplete.bind(this)
		this.handleSwitch = this.handleSwitch.bind(this)
		this.handleCheckExisting = this.handleCheckExisting.bind(this)
		this.submitChecked = this.submitChecked.bind(this)
		this.handleSearch = this.handleSearch.bind(this)
		this.selectFilter = this.selectFilter.bind(this)
		this.setUserFilter = this.setUserFilter.bind(this)
		this.setBeforeDateFilter = this.setBeforeDateFilter.bind(this)
		this.setEndDateFilter = this.setEndDateFilter.bind(this)
		this.setTransMenu = this.setTransMenu.bind(this)
		this.applyDateFilter = this.applyDateFilter.bind(this)
		this.removeDateFilter = this.removeDateFilter.bind(this)
		this.removeUserFilter = this.removeUserFilter.bind(this)
		this.handleUserChange = this.handleUserChange.bind(this)
		this.handleDuration = this.handleDuration.bind(this)
		this.handleDurationChange = this.handleDurationChange.bind(this)
		this.handleRateChange = this.handleRateChange.bind(this)
		this.applyTypeFilter = this.applyTypeFilter.bind(this)
		this.removeTypeFilter = this.removeTypeFilter.bind(this)
		this.setTypeFilter = this.setTypeFilter.bind(this)
		this.getVat = this.getVat.bind(this)
		this.getGrandTotal = this.getGrandTotal.bind(this)
		this.handleTransactionType = this.handleTransactionType.bind(this)
		this.removeFilter = this.removeFilter.bind(this)
		this.applyFilter = this.applyFilter.bind(this)
		this.userRateChange = this.userRateChange.bind(this)
		this.discountChange = this.discountChange.bind(this)
		this.initialAmount = this.initialAmount.bind(this)
		this.getDiscount = this.getDiscount.bind(this)
		this.handleDiscount = this.handleDiscount.bind(this)
		this.selectAllExisting = this.selectAllExisting.bind(this)
		this.openCreateTransactionType = this.openCreateTransactionType.bind(this)
		this.closeTransactionType = this.closeTransactionType.bind(this)
		this.handleCheck = this.handleCheck.bind(this)
		this.handleExpenseTypeChange = this.handleExpenseTypeChange.bind(this)
		this.expenseTypeSubmit = this.expenseTypeSubmit.bind(this)
	}

	componentDidMount() {
		this.loadInvoices(process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.uid + '/invoices')

	}

	loadInvoices(url) {
		axios({
			method: 'get',
			url: url,
			headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
		}).then(resp => {
			this.setState({ invoices: resp.data })
			axios({
				method: 'get',
				url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.uid + '/transactions',
				headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
			}).then(resp => {
				const getRate = (amt, discount, rate_type, rate, duration) => {
					var rateAmt = 0
					if (rate_type === "fixed") {
						rateAmt = rate
					} else {
						rateAmt = (parseFloat(amt) + parseFloat(discount)) / parseFloat(duration)
					}
					return rateAmt
				}
				const newData = resp.data.map((item) => ({
					uid: '',
					transaction_record_id: item.uid,
					name: item.name,
					amount: item.amount,
					user_id: item.user_uid,
					rate: getRate(item.amount, item.discount, item.rate_type, item.rate, item.duration),
					filed_at: item.filed_at,
					transaction_type_id: item.transaction_type_uid,
					is_deduction: item.transaction_type.is_deduction,
					duration: item.duration,
					rate_type: item.rate_type,
					remarks: item.remarks,
					discount: (item.discount / item.amount) * 100,
					initialAmount: item.amount / (1 - (item.discount / item.amount)),
					transaction_type: item.transaction_type
				}
				))
				var a = []
				var b = []
				newData.map((newItem) => {
					if (newItem.is_deduction === false) {
						a.push(newItem)
					} else {
						b.push(newItem)
					}
				})
				this.setState({ transactions: [...a, ...b] })
				this.setState({ transactionMenu: [...a, ...b] })

				axios({
					method: 'get',
					url: process.env.REACT_APP_API_DOMAIN + '/v1/transaction_types',
					headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
				}).then(resp => {
					var newSetItem = []
					var rebateItems = []
					var rebateParents = []
					var expenseParents = []
					resp.data.data.map((parent) => {
						if (parent.parent_uid === null && parent.is_deduction === false) {
							expenseParents.push(parent)
							resp.data.data.map((child) => {
								var newItem = {}
								if (parent.uid === child.parent_uid) {
									newItem["uid"] = child.uid
									newItem["name"] = child.name
									newItem["parent"] = parent.name
									newItem["is_deduction"] = child.is_deduction
									newSetItem.push(newItem)
								}
							})
						}
						if (parent.parent_uid === null && parent.is_deduction === true) {
							rebateParents.push(parent)
							resp.data.data.map((child) => {
								var rebateItem = {}
								if (parent.uid === child.parent_uid) {
									rebateItem["uid"] = child.uid
									rebateItem["name"] = child.name
									rebateItem["parent"] = parent.name
									rebateItem["is_deduction"] = child.is_deduction
									rebateItems.push(rebateItem)
								}
							})
						}
					})
					this.setState({ expenseTypes: newSetItem, rebates: rebateItems, rebateParents: rebateParents, expenseParents: expenseParents })
					var clientBiller = {}
					if (this.props.biller_uid !== null) {
						if (this.props.clients.length > 0) {
							this.props.clients.map((client) => {
								if (client.uid === this.props.legalCase.biller_uid) {
									clientBiller = client
								}
							})
						}
					}
					this.setState({ biller: clientBiller })
					this.setState({ load: true })
				})




			}).catch(error => console.log(error.response))

		}).catch(error => console.log(error.response))

	}

	handleDuration(e) {
		var amt = 0
		amt = this.state.transaction.rate * parseFloat(e.target.value)
		this.setState({
			transaction: {
				...this.state.transaction,
				[e.target.name]: e.target.value,
				amount: amt
			}
		})
	}

	handleUserChange(e) {
		var userRate = 0;
		if (this.props.members.length > 0) {
			this.props.members.map((user) => {
				if (user.uid === e.target.value) {
					userRate = user.rate
				}
			})
		}

		this.setState({
			transaction: {
				...this.state.transaction,
				[e.target.name]: e.target.value,
				rate: userRate
			}
		})
	}

	userRateChange(e) {
		this.setState({
			transaction: {
				...this.state.transaction,
				rate: e.floatValue,
				amount: ((e.floatValue * this.state.transaction.duration) * (1 - (this.state.transaction.discount / 100)))
			}
		})
	}

	handleClose() {
		this.setState({ openPopUp: false })
		this.setState({ transactionTypePopUp: false })
		this.setState({ openDeletePopUp: false })
		this.setState({ transactions: [...this.state.transactionMenu] })
		this.setState({ openTransactionForm: false })
	}

	handleCreateorUpdateItem(item, isAdd) {
		var textTitle = ''
		if (isAdd) {
			textTitle = "Add Invoice"
			this.setState({ invoice: item })
		} else {
			textTitle = "Edit Invoice"
			this.setState({
				invoice: item
			})
		}
		this.setState({ openPopUp: true })
		this.setState({ title: textTitle })
	}

	handleOpenDeletePopup(item, model) {
		this.setState({ model: model })
		this.setState({ invoice: item })
		this.setState({ openDeletePopUp: true })
	}
	handleSwitch(e) {
		if (e.target.checked === true) {
			this.setState({
				transaction: {
					...this.state.transaction,
					is_deduction: e.target.checked,
					discount: 0,
					amount: (this.state.transaction.rate * this.state.transaction.duration)
				}
			})
		} else {
			this.setState({
				transaction: {
					...this.state.transaction,
					is_deduction: e.target.checked
				}
			})
		}

	}

	handleInputChange(e) {
		var value = e.target.value


		this.setState({
			invoice: {
				...this.state.invoice,
				[e.target.name]: value
			}
		})
	}

	removeTransaction(idx) {
		this.state.transactions.splice(idx, 1)
		this.setState({ transactions: [...this.state.transactions] })
	}

	handleTransactionChange(e) {

		const updatedTransaction = [...this.state.transactions]
		updatedTransaction[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
		this.setState({ transactions: updatedTransaction })

	}
	handleDurationChange(e) {

		const updatedTransaction = [...this.state.transactions]
		updatedTransaction[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
		updatedTransaction[e.target.dataset.id]["amount"] = (e.target.value * this.state.transactions[e.target.dataset.id].rate)
		this.setState({ transactions: updatedTransaction })
	}

	handleRateChange(e) {

		const updatedTransaction = [...this.state.transactions]
		updatedTransaction[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
		updatedTransaction[e.target.dataset.id]["amount"] = (e.target.value * this.state.transactions[e.target.dataset.id].duration)
		this.setState({ transactions: updatedTransaction })
	}


	addTransaction() {
		this.setState({ transactionTypePopUp: true })
		this.setState({ selectExistingArray: [] })
	}


	testNumber() {
		const updatedTransaction = [...this.state.transactions]
		this.setState({ transactions: updatedTransaction })
	}

	handleTransactionRecord(e) {
		var transactionId = ''
		var userId = ''
		var amt = 0

		this.state.transactionMenu.map((transaction) => {
			if (transaction.name === e.target.value) {
				transactionId = transaction.transaction_record_id
				userId = transaction.user_id
				amt = parseFloat(transaction.amount)

			}
		})
		const updatedTransaction = [...this.state.transactions]
		updatedTransaction[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
		updatedTransaction[e.target.dataset.id]["transaction_record_id"] = transactionId
		updatedTransaction[e.target.dataset.id]["user_id"] = userId
		updatedTransaction[e.target.dataset.id]["amount"] = amt
		this.setState({ transactions: updatedTransaction })
	}

	handleSubmit() {

		const item = this.state.invoice
		var method = ''
		var url = ''
		if (item.uid === '') {
			method = 'post'
			url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.uid + '/invoices'
		} else {
			method = 'put'
			url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.uid + '/invoices/' + item.uid
		}
		axios({
			method: method,
			url: url,
			data: (item),
			headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
		}).then(resp => {
			this.setState({ invoice: resp.data })
			const newData = resp.data
			this.setState({
				invoices: this.state.invoices.filter(invoice => invoice.uid !== item.uid)
			})
			this.setState({ invoices: [...this.state.invoices, newData] })
			this.submitInvoiceDetails(resp.data.uid)
		}).catch(error => console.log(error.response))
	}


	submitInvoiceDetails(id) {
		var item = {}
		var respArray = []
		this.state.transactions.map((transaction) => {
			item['uid'] = transaction.uid
			item['transaction_record_id'] = transaction.transaction_record_id
			item['transaction_type_id'] = transaction.transaction_type_id
			item['amount'] = parseFloat(transaction.amount)
			item['user_id'] = transaction.user_id
			item['rate_type'] = transaction.rate_type
			item["discount"] = transaction.amount * transaction.discount / 100
			if (item.rate_type === 'hourly') {
				item['rate'] = transaction.rate
			} else {
				item['rate'] = 0
			}
			var method = ''
			var url = ''
			if (item.uid === '') {
				method = 'post'
				url = process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + id + '/invoice_details'
			} else {
				method = 'put'
				url = process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + id + '/invoice_details/' + item.uid
			}
			axios({
				method: method,
				url: url,
				data: (item),
				headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
			}).then(resp => {
				var newData = resp.data
				respArray.push(newData)
				if (respArray.length === this.state.transactions.length) {
					this.setState({ invoiceDetails: respArray })
					this.setState({ doneDetails: true })
					this.setState({ openPopUp: false })

					this.setState({ redirect: true })
				}
			}).catch(error => console.log(error.response))
		})

	}


	handleDeleteItem() {
		const DeleteItemId = this.state.invoice.uid
		axios({
			method: 'delete',
			url: process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.uid + '/invoices/' + DeleteItemId,
			headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
		}).then(() => {
			this.setState({
				invoices: this.state.invoices.filter(item => item.uid !== DeleteItemId)
			})
			this.setState({ openDeletePopUp: false })
		}).catch(error => console.log(error.response))
	}

	getTotal() {
		var total = 0
		if (this.state.transactions.length > 0) {
			this.state.transactions.map((transaction) => {
				if (transaction.is_deduction === false) {
					total = total + parseFloat(transaction.amount)
				}
			})
		}
		return total.toLocaleString(navigator.language, { minimumFractionDigits: 2 })
	}
	getTotalDiscount() {
		var total = 0
		if (this.state.transactions.length > 0) {
			this.state.transactions.map((transaction) => {
				if (transaction.is_deduction === true) {
					total = total + parseFloat(transaction.amount)
				}
			})
		}
		return total.toLocaleString(navigator.language, { minimumFractionDigits: 2 })
	}
	getTotalAmount() {
		var total = 0
		var discount = 0
		if (this.state.transactions.length > 0) {
			this.state.transactions.map((transaction) => {
				if (transaction.is_deduction === false) {
					total = total + parseFloat(transaction.amount)
				}
				else {
					discount = discount + parseFloat(transaction.amount)
				}
			})
		}

		return (total - discount).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
	}

	getVat() {
		var total = 0
		var discount = 0
		var withTax = 0
		if (this.state.biller.is_taxable === false) {
			if (this.state.transactions.length > 0) {
				this.state.transactions.map((transaction) => {
					if (transaction.is_deduction === false) {
						total = total + parseFloat(transaction.amount)
					}
					else {
						discount = discount + parseFloat(transaction.amount)
					}
				})
			}
			withTax = ((total - discount) * .12)
		} else {

			withTax = 0
		}


		return withTax.toLocaleString(navigator.language, { minimumFractionDigits: 2 })
	}
	getGrandTotal() {
		var total = 0
		var discount = 0
		var grandTotal = 0
		if (this.state.biller !== undefined) {
			if (this.state.biller.is_taxable === true) {
				if (this.state.transactions.length > 0) {
					this.state.transactions.map((transaction) => {
						if (transaction.is_deduction === false) {
							total = total + parseFloat(transaction.amount)
						}
						else {
							discount = discount + parseFloat(transaction.amount)
						}
					})
				}
				grandTotal = (total - discount) + ((total - discount) * .12)
			} else {
				if (this.state.transactions.length > 0) {
					this.state.transactions.map((transaction) => {
						if (transaction.is_deduction === false) {
							total = total + parseFloat(transaction.amount)
						}
						else {
							discount = discount + parseFloat(transaction.amount)
						}
					})
				}
				grandTotal = (total - discount)

			}
		}


		return grandTotal.toLocaleString(navigator.language, { minimumFractionDigits: 2 })
	}


	handleCloseTransactionForms() {
		this.setState({ openTransactionForm: false })
		this.setState({ openSelectExistingTransaction: false })
		this.setState({ transactionTypePopUp: false })
		this.setState({ selectedExisting: [] })

	}

	newTransaction() {
		this.setState({ transactionTitle: "Create Transaction" })
		this.setState({ transaction: this.state.initialTransaction })
		this.setState({ transactionTypePopUp: false })
		this.setState({ openTransactionForm: true })
	}

	editTransaction(transaction) {
		var item = {}
		item["uid"] = transaction.transaction_record_id
		item["name"] = transaction.name
		item["amount"] = transaction.amount
		item["transaction_type_id"] = transaction.transaction_type_id
		item["user_id"] = transaction.user_id
		item["remarks"] = transaction.remarks
		item["duration"] = transaction.duration
		item["is_deduction"] = transaction.is_deduction
		item["filed_at"] = transaction.filed_at
		item["rate_type"] = transaction.rate_type
		item["rate"] = transaction.rate
		item["initialAmount"] = transaction.initialAmount
		item["discount"] = transaction.discount
		this.setState({ transaction: item })

		if (this.state.expenseTypes.length > 0) {
			this.state.expenseTypes.map((expense) => {
				if (expense.uid === transaction.transaction_type_id) {
					this.setState({ defaultTransactionType: expense })
				}
			})
		}
		if (this.state.rebates.length > 0) {
			this.state.rebates.map((expense) => {
				if (expense.uid === transaction.transaction_type_id) {
					this.setState({ defaultTransactionType: expense })
				}
			})
		}
		this.setState({ openTransactionForm: true })
		this.setState({ transactionTitle: "Edit Transaction" })
	}

	existingTransaction() {
		this.setState({ transactionTypePopUp: false })

		var arr1 = []
		if (this.state.transactionMenu.length > 0) {
			this.state.transactionMenu.map((transMenu) => {
				var recorded = false
				this.state.transactions.map((trans) => {
					if (transMenu.transaction_record_id === trans.transaction_record_id) {
						recorded = true
					}
				})
				if (recorded === false) {
					arr1.push(transMenu)
				}
			})
		}
		this.setState({ transMenuPopUp: arr1 })
		this.setState({ openSelectExistingTransaction: true })

	}

	transactionTestNumber(e) {
		this.setState({
			transaction: {
				...this.state.transaction,
				amount: e.floatValue
			}
		})
	}
	handleInputTransactionChange(e) {
		var value = null

		if (e.target.name === "amount") {
			value = e.target.value * 1
		} else if (e.target.name === "duration") {
			value = e.target.value * 1
		} else {
			value = e.target.value
		}
		this.setState({
			transaction: {
				...this.state.transaction,
				[e.target.name]: value
			}
		})
	}
	handleSubmitTransaction() {
		const item = {}
		item["uid"] = this.state.transaction.uid
		item["name"] = this.state.transaction.name
		item["user_id"] = this.state.transaction.user_id
		item["rate_type"] = this.state.transaction.rate_type
		item["remarks"] = this.state.transaction.remarks
		item["filed_at"] = this.state.transaction.filed_at
		item["transaction_type_id"] = this.state.defaultTransactionType.uid
		item["amount"] = this.state.transaction.amount * 1
		item["discount"] = (this.state.transaction.discount / 100) * this.state.transaction.amount

		if (this.state.transaction.rate_type === "fixed") {
			item["duration"] = 0
		} else {
			item["duration"] = this.state.transaction.duration * 1
		}
		var method = ''
		var url = ''
		if (item.uid === '') {
			method = 'post'
			url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.uid + '/transactions'
		} else {
			method = 'put'
			url = process.env.REACT_APP_API_DOMAIN + '/v1/legal_cases/' + this.props.uid + '/transactions/' + item.uid
		}
		axios({
			method: method,
			url: url,
			data: (item),
			headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
		}).then(resp => {
			const newData = {}
			newData["uid"] = "",
				newData["transaction_record_id"] = resp.data.uid
			newData["transaction_type_id"] = resp.data.transaction_type_uid
			newData["name"] = resp.data.name
			newData["user_id"] = resp.data.user_uid
			newData["rate"] = resp.data.rate
			newData["duration"] = resp.data.duration
			newData["amount"] = parseFloat(resp.data.amount)
			newData["filed_at"] = resp.data.filed_at
			newData["rate_type"] = resp.data.rate_type
			newData["remarks"] = resp.data.remarks
			newData["discount"] = (resp.data.discount / parseFloat(resp.data.amount)) * 100
			newData["is_deduction"] = resp.data.transaction_type.is_deduction
			newData["initialAmount"] = parseFloat(resp.data.amount) / (1 - (resp.data.discount / parseFloat(resp.data.amount)))

			this.setState({
				transactions: this.state.transactions.filter(transaction => transaction.transaction_record_id !== resp.data.uid)
			})
			this.setState({ transactions: [...this.state.transactions, newData] })
			this.setState({ openTransactionForm: false })

		}).catch(error => console.log(error.response))
	}

	handleSelectTransaction(e) {
		this.setState({ transId: e.target.value })
	}

	handleSubmitSelectedTransaction() {
		this.setState({ transactions: [...this.state.transactions, ...this.state.selectedExpenses] })
		this.setState({ openSelectExistingTransaction: false })
	}

	handleAutoComplete(event, values) {
		const expenses = []
		if (values !== null) {
			values.map((value) => {
				expenses.push(value)

			}
			)
		}
		this.setState({ selectedExpenses: expenses })
	}

	handleCheckExisting(e, item) {
		var selectedExisting = this.state.selectedExisting
		if (e.target.checked === true) {
			this.setState({ selectExistingArray: [...this.state.selectExistingArray, { ...item }] })
			selectedExisting.push(item.transaction_record_id)
		}
		else {
			this.setState({
				selectExistingArray: this.state.selectExistingArray.filter(invoice => invoice.transaction_record_id !== item.transaction_record_id)
			})
			selectedExisting.splice(selectedExisting.indexOf(item.transaction_record_id), 1)
		}
		this.setState({ selectedExisting: selectedExisting })
	}


	submitChecked() {
		const newData = this.state.selectExistingArray.map((item) => ({
			uid: item.uid,
			transaction_record_id: item.transaction_record_id,
			transaction_type_id: item.transaction_type_id,
			filed_at: item.filed_at,
			duration: item.duration,
			name: item.name,
			amount: item.amount,
			user_id: item.user_id,
			rate: item.rate,
			is_deduction: item.is_deduction,
			discount: item.discount,

		}))
		var addedTransactions = this.state.transactions
		addedTransactions = [...addedTransactions, ...newData]
		addedTransactions = addedTransactions.sort(function (a, b) { return a.is_deduction - b.is_deduction }).sort(
			(a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
		)

		this.setState({ transactions: addedTransactions })
		this.setState({ openSelectExistingTransaction: false })
		this.setState({ selectedExisting: [] })

	}

	handleSearch(e) {
		this.setState({ filterSelection: e.target.value })

	}

	selectFilter(e) {
		this.setState({ filterField: e.target.value })
	}

	setUserFilter(e) {
		this.setState({ userFilter: e.target.value })
	}
	setEndDateFilter(e) {
		this.setState({ endDateFilter: e.target.value })

	}
	setBeforeDateFilter(e) {
		this.setState({ beforeDateFilter: e.target.value })

	}
	setTransMenu() {
		this.setState({ transactions: this.state.transactionMenu })
	}

	applyDateFilter() {
		if (this.state.endDateFilter !== '' && this.state.beforeDateFilter !== '') {
			this.setState({
				transactions: this.state.transactions.filter(item => item.filed_at >= this.state.beforeDateFilter && item.filed_at <= this.state.endDateFilter)
			})
		} else if (this.state.endDateFilter === '' && this.state.beforeDateFilter !== '') {
			this.setState({
				transactions: this.state.transactions.filter(item => item.filed_at >= this.state.beforeDateFilter)
			})
		} else if (this.state.endDateFilter !== '' && this.state.beforeDateFilter === '') {
			this.setState({
				transactions: this.state.transactions.filter(item => item.filed_at <= this.state.endDateFilter)
			})
		} else {
			this.setState({
				transactions: [...this.state.transactions]
			})
		}

	}
	removeDateFilter() {
		this.setState({ transactions: this.state.transactionMenu })
		this.setState({ beforeDateFilter: '' })
		this.setState({ endDateFilter: '' })
	}



	removeUserFilter() {
		this.setState({ transactions: this.state.transactionMenu })
		this.setState({ userFilter: '' })

	}


	setTypeFilter(e) {
		this.setState({ typeFilter: e.target.value })

	}

	applyTypeFilter() {
		this.setState({
			transactions: this.state.transactions.filter(item => item.is_deduction === this.state.typeFilter)
		})
	}

	removeTypeFilter() {
		this.setState({ transactions: this.state.transactionMenu })
		this.setState({ typeFilter: '' })

	}

	handleTransactionType(event, values) {
		this.setState({ defaultTransactionType: values })
	}

	applyFilter() {
		var updatedTransactions = this.state.transactions
		if (this.state.endDateFilter !== '' && this.state.beforeDateFilter !== '') {
			updatedTransactions = updatedTransactions.filter(item => item.filed_at >= this.state.beforeDateFilter && item.filed_at <= this.state.endDateFilter)

		} else if (this.state.endDateFilter === '' && this.state.beforeDateFilter !== '') {
			updatedTransactions = updatedTransactions.filter(item => item.filed_at >= this.state.beforeDateFilter)

		} else if (this.state.endDateFilter !== '' && this.state.beforeDateFilter === '') {
			updatedTransactions = updatedTransactions.filter(item => item.filed_at <= this.state.endDateFilter)

		} else {
			updatedTransactions = [...this.state.transactions]
		}
		if (this.state.typeFilter !== "") {
			updatedTransactions = updatedTransactions.filter(item => item.is_deduction === this.state.typeFilter)
		}
		if (this.state.userFilter !== "") {
			updatedTransactions = updatedTransactions.filter(item => item.user_id === this.state.userFilter)
		}
		this.setState({ transactions: updatedTransactions })


	}
	removeFilter() {
		this.setState({ userFilter: '' })
		this.setState({ typeFilter: '' })
		this.setState({ beforeDateFilter: '' })
		this.setState({ endDateFilter: '' })
		this.setState({ transactions: this.state.transactionMenu })
	}

	discountChange(e) {
		if (e.floatValue !== undefined) {
			if (this.state.transaction.rate_type === "hourly") {
				this.setState({
					transaction: {
						...this.state.transaction,
						discount: e.floatValue,
						amount: ((this.state.transaction.rate * this.state.transaction.duration) * (1 - (e.floatValue / 100)))
					}
				})
			} else {
				this.setState({
					transaction: {
						...this.state.transaction,
						discount: e.floatValue,
						amount: ((this.state.transaction.initialAmount) * (1 - (e.floatValue / 100)))
					}
				})
			}


		} else {
			this.setState({
				transaction: {
					...this.state.transaction,
					discount: 0,
					amount: (this.state.transaction.rate * this.state.transaction.duration)
				}
			})
		}

	}
	handleDiscount(e) {

		this.setState({
			invoice: {
				...this.state.invoice,
				discount: e.floatValue
			}
		})
	}

	initialAmount(e) {
		this.setState({
			transaction: {
				...this.state.transaction,
				initialAmount: e.floatValue,
				amount: e.floatValue
			}
		})
	}

	getDiscount() {
		var totalDiscount = 0
		var total = 0
		var discount = 0
		if (this.state.invoice.discount !== undefined) {

			if (this.state.transactions.length > 0) {
				this.state.transactions.map((transaction) => {
					if (transaction.is_deduction === false) {
						total = total + parseFloat(transaction.amount)
					}
					else {
						discount = discount + parseFloat(transaction.amount)
					}
				})
			}
			totalDiscount = (total - discount)

		}
		return totalDiscount.toLocaleString(navigator.language, { minimumFractionDigits: 2 })

	}

	selectAllExisting() {
		var checkAll = []
		var unCheckAll = this.state.selectedExisting
		var checkRemaining = this.state.selectedExisting
		if (this.state.transMenuPopUp.length > 0) {
			if (this.state.selectedExisting.length <= 0) {
				this.state.transMenuPopUp.map((trans) => {
					checkAll.push(trans.transaction_record_id)

				})
				this.setState({ selectedExisting: checkAll })
				this.setState({ selectExistingArray: this.state.transMenuPopUp })
			} else if (this.state.selectedExisting.length === this.state.transMenuPopUp.length) {
				this.state.transMenuPopUp.map((trans) => {
					unCheckAll.splice(unCheckAll.indexOf(trans.transaction_record_id), 1)
				})
				this.setState({ selectedExisting: unCheckAll })
				this.setState({ selectExistingArray: [] })
			} else {
				this.state.transMenuPopUp.map((transMenu) => {
					var addState = true
					this.state.selectedExisting.map((selectedId) => {
						if (selectedId === transMenu.transaction_record_id) {
							addState = false
						}
					})
					if (addState === true) {
						checkRemaining.push(transMenu.transaction_record_id)
						this.setState({ selectExistingArray: [...this.state.selectExistingArray, transMenu] })
					}
				})
				return this.setState({ selectedCases: checkRemaining })
			}
		}
	}

	openCreateTransactionType() {
    this.setState({ transactionTypeFormPopUp: true })
  }

  closeTransactionType() {
    this.setState({ transactionTypeFormPopUp: false, defaultTransactionType: {} })
  }

  handleCheck(e) {
    this.setState({
      expenseType: {
        ...this.state.expenseType,
        parent_name: "",
        parent_uid: ''
      }
    })
    this.setState({ newGroup: e.target.checked })
  }

  handleExpenseTypeChange(e) {
    this.setState({
      expenseType: {
        ...this.state.expenseType,
        [e.target.name]: e.target.value
      }
    })
  }

  expenseTypeSubmit() {
		var method = 'post'
		var url = process.env.REACT_APP_API_DOMAIN + '/v1/transaction_types'
		if (this.state.newGroup) {
			const item = {}
			const subItem = {}
			item["name"] = this.state.expenseType.parent_name
			subItem["name"] = this.state.expenseType.name
			subItem["is_deduction"] = this.state.expenseType.is_deduction
			item["is_deduction"] = this.state.expenseType.is_deduction

			axios({
				method: method,
				url: url,
				data: (item),
				headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
			}).then(resp => {
				const newData = resp.data
				if (item.is_deduction) {
					this.setState({ rebateParents: [...this.state.rebateParents, newData] })

				} else {
					this.setState({ expenseParents: [...this.state.expenseParents, newData] })
				}
				subItem["parent_id"] = newData.uid
				axios({
					method: method,
					url: url,
					data: (subItem),
					headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
				}).then(resp => {
					const subData = resp.data
					var transactionItem = {}
					transactionItem["name"] = subData.name
					transactionItem["parent"] = item.name
					transactionItem["is_deduction"] = subData.is_deduction
					transactionItem["uid"] = subData.uid
					transactionItem['transactionParent'] = item.name

					this.setState({ defaultTransactionType: transactionItem })
					if (item.is_deduction) {
						this.setState({ rebates: [...this.state.rebates,  transactionItem] })

					} else {
						this.setState({ expenseTypes: [...this.state.expenseTypes, transactionItem] })
					}
					this.closeTransactionType()
				})
			})

		} else {
			const newItem = {}
			newItem["name"] = this.state.expenseType.name
			newItem["parent_id"] = this.state.expenseType.parent_uid ? this.state.expenseType.parent_uid : ''
			newItem["is_deduction"] = this.state.expenseType.is_deduction
			axios({
				method: method,
				url: url,
				data: (newItem),
				headers: { 'X-APP-API-KEY': localStorage.getItem('api_key') }
			}).then(resp => {
				const subData = resp.data
				var transactionItem = {}
				transactionItem["name"] = subData.name
				transactionItem["parent"] = [...this.state.expenseParents, ...this.state.rebateParents].filter(item => item.uid === subData.parent_uid)[0].name
				transactionItem["is_deduction"] = subData.is_deduction
				transactionItem["uid"] = subData.uid
				transactionItem['transactionParent'] = [...this.state.expenseParents, ...this.state.rebateParents].filter(item => item.uid === subData.parent_uid)[0].name

				this.setState({ defaultTransactionType: transactionItem })
				if (newItem.is_deduction) {
					this.setState({ rebates: [...this.state.rebates, transactionItem] })
				} else {
					this.setState({ expenseTypes: [...this.state.expenseTypes, transactionItem] })
				}
				this.closeTransactionType()
			})
		}
	}
	render() {
		const { redirect, doneDetails } = this.state

		if (redirect)
			if (doneDetails)
				return (<Redirect to={{
					pathname: '/invoices/' + this.state.invoice.uid,
					state: {
						item: this.state.invoice,
						details: this.state.invoiceDetails,
						prevPath: '/cases/' + this.state.invoice.legal_case_uid
					}
				}} />)


		return (
			<div>
				{
					this.state.load ? (
						<>

							<SimpleTable
								items={this.state.invoices}
								withShow={this.state.withShow}
								headers={this.state.headers}
								model={this.state.model}
								initialItem={this.state.initialInvoice}
								handleClick={this.handleCreateorUpdateItem}
								onOpenDeletePopup={this.handleOpenDeletePopup}
								prevPath={'/cases/' + this.props.uid}
								removeLabel={true}
							/>

							<SimplePopUp
								openPopup={this.state.openPopUp}
								maxWidth="xl"
								title={this.state.title}
								handleClose={this.handleClose}
							>
								<InvoiceForm
									biller={this.state.biller}
									invoice={this.state.invoice}
									transactions={this.state.transactions}
									transactionMenu={this.state.transactionMenu}
									transMenuPopUp={this.state.transMenuPopUp}
									beforeDateFilter={this.state.beforeDateFilter}
									endDateFilter={this.state.endDateFilter}
									expenseTypes={this.state.expenseTypes}
									rebates={this.state.rebates}
									users={this.props.users}
									members={this.props.members}
									typeFilter={this.state.typeFilter}
									userFilter={this.state.userFilter}
									setEndDateFilter={this.setEndDateFilter}
									setTypeFilter={this.setTypeFilter}
									setBeforeDateFilter={this.setBeforeDateFilter}
									addTransaction={this.addTransaction}
									handleInputChange={this.handleInputChange}
									editTransaction={this.editTransaction}
									removeTransaction={this.removeTransaction}
									getTotalAmount={this.getTotalAmount}
									getTotal={this.getTotal}
									getTotalDiscount={this.getTotalDiscount}
									getVat={this.getVat}
									getGrandTotal={this.getGrandTotal}
									removeFilter={this.removeFilter}
									applyFilter={this.applyFilter}
									setUserFilter={this.setUserFilter}
									handleSubmit={this.handleSubmit}
									getDiscount={this.getDiscount}
									handleDiscount={this.handleDiscount}

								/>
							</SimplePopUp>
							<SimpleDeletePopUp
								openDeletePopup={this.state.openDeletePopUp}
								item={this.state.invoice}
								delete={this.handleDeleteItem}
								handleDeleteClose={this.handleClose}
								model={this.state.model}
							/>

							<SimplePopUp
								openPopup={this.state.transactionTypePopUp}
								maxWidth="sm"
								title="Confirmation"
								handleClose={this.handleCloseTransactionForms}
							>
								<div className={styles.transactionAddbuttons}>
									<div>
										<IconButton className={styles.transactionExist} variant="contained" onClick={this.existingTransaction}>
											<ReceiptIcon />
											<span>Existing Transaction</span>
										</IconButton>
									</div>
									<div>
										<IconButton className={styles.transactionNew} variant="contained" onClick={this.newTransaction}>
											<CreditCardIcon /><br />
											<span>New Transaction</span>
										</IconButton>

									</div>
								</div>
							</SimplePopUp>

							<SimplePopUp
								openPopup={this.state.openTransactionForm}
								maxWidth="md"
								title={this.state.transactionTitle}
								handleClose={this.handleCloseTransactionForms}
							>
								<TransactionForm
									transaction={this.state.transaction}
									members={this.props.members}
									users={this.props.users}
									handleSubmit={this.handleSubmitTransaction}
									testNumber={this.transactionTestNumber}
									handleInputChange={this.handleInputTransactionChange}
									expenseTypes={this.state.expenseTypes}
									handleSwitch={this.handleSwitch}
									handleUserChange={this.handleUserChange}
									handleDuration={this.handleDuration}
									rebates={this.state.rebates}
									handleTransactionType={this.handleTransactionType}
									defaultTransactionType={this.state.defaultTransactionType}
									userRateChange={this.userRateChange}
									discountChange={this.discountChange}
									initialAmount={this.initialAmount}
									openCreateTransactionType={this.openCreateTransactionType}
								/>

							</SimplePopUp>
							<SimplePopUp
                openPopup={this.state.transactionTypeFormPopUp}
                maxWidth="xs"
                title="New Transaction Type"
                handleClose={this.closeTransactionType}
              >
                  <ExpenseTypeForm
                    item={this.state.expenseType}
                    newGroup={this.state.newGroup}
                    items={[...this.state.rebateParents, ...this.state.expenseParents]}
                    handleCheck={this.handleCheck}
                    onchange={this.handleExpenseTypeChange}
                    submit={this.expenseTypeSubmit}
                  />
              </SimplePopUp>
							<SimplePopUp
								openPopup={this.state.openSelectExistingTransaction}
								maxWidth={this.state.transMenuPopUp.length > 0 ? ("lg") : ("sm")}
								title="Select Transaction"
								handleClose={this.handleCloseTransactionForms}
							>
								<SelectExistingTransactionForm
									submitChecked={this.submitChecked}
									handleCheckExisting={this.handleCheckExisting}
									transMenuPopUp={this.state.transMenuPopUp}
									selectFilter={this.selectFilter}
									filterField={this.state.filterField}
									filterSelection={this.state.filterSelection}
									handleSearch={this.handleSearch}
									users={this.props.users}
									members={this.props.members}
									expenseTypes={this.state.expenseTypes}
									rebates={this.state.rebates}
									selectedExisting={this.state.selectedExisting}
									selectExistingArray={this.state.selectExistingArray}
									selectAllExisting={this.selectAllExisting}
								/>

							</SimplePopUp>
						</>

					) : (null)
				}

			</div>
		)
	}
}

export default Invoices

Invoices.propTypes = {
	uid: PropTypes.string,
	users: PropTypes.array,
	members: PropTypes.array,
	clients: PropTypes.array,
	biller_uid: PropTypes.string,
	legalCase: PropTypes.object
}
