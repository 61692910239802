import React from 'react'
import PropTypes from 'prop-types'
import styles from './Onboarding.module.css'
import {Grid, IconButton} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import SimpleTable from '../SimpleTable/SimpleTable'
class ReviewForm extends React.Component {
	render() {
		return (
			<>
				<div style={{display:'flex',alignItems:'center'}}>
				<h3><strong><span className={styles.dot} /> Company Information Setup</strong></h3>
                <IconButton
                    onClick={()=>this.props.handleRedirectTo(0)}
                    color="primary"
                    >
                        <EditIcon />
                    </IconButton>
                </div>
				<hr />
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<div className={styles.detailList}>
							<div><b> Name: </b></div>
							<div>:&nbsp; {this.props.info.name}</div>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div className={styles.detailList}>
							<div><b> Trademark: </b></div>
							<div>:&nbsp; {this.props.info.trade_name}</div>
						</div>
					</Grid>
                    <Grid item xs={6}>
						<div className={styles.detailList}>
							<div><b> Contact Person: </b></div>
							<div>:&nbsp; {this.props.info.firstname}  {this.props.info.lastname}</div>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div className={styles.detailList}>
							<div><b> Address: </b></div>
							<div>:&nbsp; {this.props.info.address}</div>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div className={styles.detailList}>
							<div><b> Phone : </b></div>
							<div>:&nbsp; {this.props.info.cellphone}</div>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div className={styles.detailList}>
							<div><b> Mobile: </b></div>
							<div>:&nbsp; {this.props.info.workphone}</div>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div className={styles.detailList}>
							<div><b> Email: </b></div>
							<div>:&nbsp; {this.props.info.email}</div>
						</div>
					</Grid>
				</Grid>
                <br/>
				<div style={{display:'flex',alignItems:'center'}}>
				<h3><strong><span className={styles.dot} /> Users Setup</strong></h3>
                <IconButton
                    onClick={()=>this.props.handleRedirectTo(1)}
                    color="primary"
                    >
                        <EditIcon />
                    </IconButton>
                </div>
                <hr />
				<SimpleTable
					items={this.props.members}
					model={"User"}
					onOpenDeletePopup={this.handleOpenDeletePopup}
					headers={['username','email','first_name','middle_name','last_name']}
					withShow={false}
					withEdit={false}
					noHeader={true}
                    noActionHeader={true}
                    removeButtonAdd={true}
				/>
                <br/>
                <div style={{display:'flex',alignItems:'center'}}>
				<h3><strong><span className={styles.dot} /> Groups Setup</strong></h3>
                <IconButton
                    onClick={()=>this.props.handleRedirectTo(2)}
                    color="primary"
                    >
                        <EditIcon />
                    </IconButton>
                </div>
                <hr />
				<SimpleTable
					items={this.props.groups}
					model={"Group"}
					onOpenDeletePopup={this.handleOpenDeletePopup}
					headers={['name']}
					withShow={false}
					withEdit={false}
					noHeader={true}
                    noActionHeader={true}
                    removeButtonAdd={true}
				/>
                <br/>
                
                
                <div style={{display:'flex',alignItems:'center'}}>
				<h3><strong><span className={styles.dot} /> Practice Areas Setup</strong></h3>
                <IconButton
                    onClick={()=>this.props.handleRedirectTo(3)}
                    color="primary"
                    >
                        <EditIcon />
                    </IconButton>
                </div>
                <hr />
				<SimpleTable
					items={this.props.practiceAreas}
					model={"Practice Area"}
					onOpenDeletePopup={this.handleOpenDeletePopup}
					headers={['name']}
					withShow={false}
					withEdit={false}
					noHeader={true}
                    removeButtonAdd={true}
				/>
                <br/>
				
                <div style={{display:'flex',alignItems:'center'}}>
				<h3><strong><span className={styles.dot} /> Clients Custom Fields Setup</strong></h3>
                <IconButton
                    onClick={()=>this.props.handleRedirectTo(4)}
                    color="primary"
                    >
                        <EditIcon />
                    </IconButton>
                </div>
				<hr />
				<SimpleTable
					items={[...this.props.clientCustomFields]}
					model={"Custom Field"}
					onOpenDeletePopup={this.handleOpenDeletePopup}
					headers={['name','type']}
					withShow={false}
					withEdit={false}
					noHeader={true}
                    removeButtonAdd={true}
				/>
                <br/>
				
                <div style={{display:'flex',alignItems:'center'}}>
				<h3><strong><span className={styles.dot} /> Cases Custom Fields Setup</strong></h3>
                <IconButton
                    onClick={()=>this.props.handleRedirectTo(5)}
                    color="primary"
                    >
                        <EditIcon />
                    </IconButton>
                </div>
				<hr />
				<SimpleTable
					items={[...this.props.caseCustomFields]}
					model={"Custom Field"}
					onOpenDeletePopup={this.handleOpenDeletePopup}
					headers={['name','type']}
					withShow={false}
					withEdit={false}
					noHeader={true}
                    removeButtonAdd={true}
				/>
                <br/>
                <div style={{display:'flex',alignItems:'center'}}>
				<h3><strong><span className={styles.dot} /> Priorities Setup </strong></h3>
                <IconButton
                    onClick={()=>this.props.handleRedirectTo(6)}
                    color="primary"
                    >
                        <EditIcon />
                    </IconButton>
                </div>
				<hr />
				<SimpleTable
					items={this.props.priorities}
					model={"Prioritie"}
					onOpenDeletePopup={this.handleOpenDeletePopup}
					headers={['name','color','rank']}
					withShow={false}
					withEdit={false}
					noHeader={true}
                    removeButtonAdd={true}
				/>

			</>
		)
	}
}
export default ReviewForm

ReviewForm.propTypes = {
	info: PropTypes.object,
	clientCustomFields: PropTypes.array,
    caseCustomFields: PropTypes.array,
    practiceAreas: PropTypes.array,
    groups: PropTypes.array,
    priorities: PropTypes.array,
    handleRedirectTo: PropTypes.func,
    members: PropTypes.array,
}
