import React from 'react'

import { withRouter } from 'react-router-dom'
import styles from './Error.module.css'
import { Container } from '@material-ui/core'
import Error404Image from '../../assets/images/404_error.svg'

class Public404 extends React.Component {
  render() {
    return (
      <Container className={styles.mainContainer}>
        <Container className={styles.errorContentContainer}>
          <div className={styles.errorMessageContainer}>
            <h1 className={styles.errorTitle}>404</h1>
            <h2 className={styles.errorSubtitle}>Oops!</h2>
            <p className={styles.errorMessage}>
              We can&apos;t seem to find the page you are looking for.
            </p>
          </div>
          <img
            src={Error404Image}
            alt="Error 404"
            className={styles.errorImage}
          />
        </Container>
      </Container>
    )
  }
}

export default withRouter(Public404)
