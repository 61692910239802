import React from 'react'

import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import styles from "./LegalCase.module.css"

class PracticeAreaForm extends React.Component {
  render() {
    return (
      <>
        <form>
          <TextField
            variant="outlined"
            size="small"
            required
            fullWidth
            label="Name"
            name="name"
            value={this.props.practice.name}
            onChange={this.props.handleChange}
          />
        </form>
        <div className={styles.actionButton}>
          <Button
            variant="outlined"
            className={styles.actionAddButton}
            onClick={this.props.addPracticeArea}
            disabled={!this.props.practice.name}
            color="primary">Save</Button>
        </div>
      </>
    )
  }
}

export default PracticeAreaForm

PracticeAreaForm.propTypes = {
  practice: PropTypes.object,
  handleChange: PropTypes.func,
  addPracticeArea: PropTypes.func
}
