import React from 'react'

import PropTypes from 'prop-types'

import styles from './NestedForm.module.css'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {InputLabel} from '@material-ui/core'
import Select from "@material-ui/core/Select/Select";
import FormControl from "@material-ui/core/FormControl";

class NestedForm extends React.Component {
render(){
    return(
        <>
      <form style={{ display: 'flex' }} >
        <TextField
          autoComplete="off"
          fullWidth
          className={styles.textfield}
          variant="outlined"
          required
          name="content"
          size="small"
          value={this.props.item.content}
          onChange={this.props.handleChange}
          label={this.props.nestedModel}
        />
        <FormControl fullWidth className={styles.textfield} size="small" variant="outlined" >
          <InputLabel htmlFor="outlined-age-native-simple">Category</InputLabel>
          <Select
            native
            fullWidth
            label="category"
            variant="outlined"
            size="small"
            value = {this.props.item.category || 0}
            onChange={this.props.handleChange}
            inputProps={{
              name: 'category',
              id: 'outlined-age-native-simple'
            }}
          >
            {
              this.props.options.length>0?(
                this.props.options.map((option,idx)=>(
                  <option key={idx} value={idx}>{option}</option>
                ))):(null)
            }
          </Select>
        </FormControl>


      </form>
          <div className={styles.actionButton}>
            <Button variant="outlined" name="submit" color="primary" onClick={this.props.handleSubmit} >Save</Button>
          </div>
    </>
    )
}

}
export default NestedForm

NestedForm.propTypes={
    handleSubmit: PropTypes.func,
    handleChange: PropTypes.func,
    item: PropTypes.object,
    nestedModel: PropTypes.string,
    options: PropTypes.array
}
