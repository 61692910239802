import React from 'react'

import PropTypes from 'prop-types'

import Autocomplete from '@material-ui/lab/Autocomplete'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import NumberFormat from 'react-number-format'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {Typography, Tooltip, IconButton} from '@material-ui/core'
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import styles from '../Invoice/Invoice.module.css'


class TransactionForm extends React.Component {
  render() {
    const options = this.props.expenseTypes.map((option) => {
      return {
        transactionParent: option.parent,
        ...option,
      }
    })
    const newOptions = this.props.rebates.map((option) => {
      return {
        transactionParent: option.parent,
        ...option,
      }
    })


    return (
      <>
        <div className={styles.grid}>
          <div>
            <div className={styles.description}>
              <label><b>Description<span>*</span> :</b></label>
              <TextField
                name="name"
                fullWidth="true"
                multiline={true}
                rows={3}
                onChange={this.props.handleInputChange}
                value={this.props.transaction.name}
                variant="outlined"
                size="small"
              />
            </div>
            <p className={styles.errorDes}>{this.props.error}</p>
            <div className={styles.user}>
              <label><b>User<span>*</span> :</b></label>
              <TextField
                select
                autoComplete="off"
                name="user_id"
                fullWidth="true"
                size="small"
                onChange={this.props.handleUserChange}
                value={this.props.transaction.user_id || ''}
                variant="outlined"
                id="user-id"
              >
                {
                  this.props.users.length > 0 ? (
                    this.props.users.map((user,idx) => (
                      this.props.members.map((apiUser) => (
                        user === apiUser.uid ? (<MenuItem name={`user-${idx}`} value={apiUser.uid}>{apiUser.username}</MenuItem>) : (null)
                      ))
                    ))
                  ) : (null)
                }
              </TextField>
            </div>
            <p className={styles.errorDes}>{this.props.error_user}</p>
            <div className={styles.description}>
              <label><b>Remarks :</b></label>
              <TextField
                autoComplete="off"
                multiline={true}
                rows={5}
                size="small"
                variant="outlined"
                required
                fullWidth="true"
                name="remarks"
                value={this.props.transaction.remarks}
                onChange={this.props.handleInputChange}
              />
            </div>
            <br/>
            <div style={{float: 'right'}}>
              <FormControlLabel
                control={
                  <Switch
                    // checked={state.checkedB}
                    onChange={this.props.handleSwitch}
                    checked={this.props.transaction.is_deduction}
                    name="checkedB"
                    color="secondary"
                  />
                }
                label={<Typography>For Deduction</Typography>}
              />
            </div>
          </div>


          <div>
            {
              this.props.transaction.is_deduction ? (
                <div style={{display: 'flex'}}>
                <Autocomplete
                  onChange={this.props.handleTransactionType}
                  values={this.props.defaultTransactionType}
                  id="transaction_type_id"
                  size="small"
                  options={newOptions}
                  name="transaction_type"
                  getOptionLabel={(option) => option.name || ''}
                  groupBy={(option) => option.transactionParent}
                  getOptionSelected={(option, value) => option.name === value.name}
                  fullWidth
                  renderInput={(params) => <TextField {...params} name="transaction_type" label="Transaction Type" variant="outlined"/>}
                />
                <Tooltip title="Add Transaction Type" arrow>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={this.props.openCreateTransactionType}
                    // style={{ padding: '14px' }}
                    name="add_transaction_type_button"
                  >
                    <AddToPhotosIcon />
                  </IconButton>
                </Tooltip>
                </div>
              ) : (
                <div style={{display: 'flex'}}>
                <Autocomplete
                  values={this.props.defaultTransactionType}
                  onChange={this.props.handleTransactionType}
                  id="transaction_type_id"
                  size="small"
                  name="transaction_type"
                  options={options}
                  getOptionLabel={(option) => option.name || ''}
                  groupBy={(option) => option.transactionParent}
                  getOptionSelected={(option, value) => option.uid === value.uid}
                  fullWidth
                  renderInput={(params) => <TextField {...params} required InputLabelProps={{classes:{asterisk: styles.asterisk}}} name="transaction_type" label="Transaction Type" variant="outlined"/>}
                />
                  <Tooltip title="Add Transaction Type" arrow>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={this.props.openCreateTransactionType}
                    // onClick={this.props.openGroupForm}
                    // style={{ padding: '14px' }}
                    name="add_group_button"
                  >
                    <AddToPhotosIcon />
                  </IconButton>
                </Tooltip>
                </div>
              )
            }
            <br/>
            <TextField
              type="date"
              value={this.props.transaction.filed_at}
              format="MM/dd/YYYY"
              fullWidth="true"
              label="Date Filed"
              size="small"
              name="filed_at"
              variant="outlined"
              onChange={this.props.handleInputChange}
            />
            <br/><br/>
            <TextField
              select
              size="small"
              name="rate_type"
              fullWidth="true"
              label="Rate Type"
              onChange={this.props.handleInputChange}
              value={this.props.transaction.rate_type}
              variant="outlined"
            >
              <MenuItem value="hourly">Hourly</MenuItem>
              <MenuItem value="fixed">Fixed</MenuItem>
            </TextField>
            <br/><br/>
            {
              this.props.transaction.rate_type === "hourly" ? (
                <>
                  <NumberFormat
                    customInput={TextField}
                    variant="outlined"
                    required
                    size="small"
                    label="User Rate"
                    autoComplete="off"
                    fullWidth="true"
                    name="rate"
                    className={styles.numberField}
                    thousandSeparator={true}
                    value={parseFloat(this.props.transaction.rate).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                    onValueChange={this.props.userRateChange}
                    decimalSeparator="."
                    fixedDecimalScale={true}
                    prefix={'₱'}
                    InputLabelProps={{classes:{asterisk: styles.asterisk}}}
                  />
                  <br/><br/>
                  <TextField
                    type="number"
                    name="duration"
                    fullWidth="true"
                    label="Duration"
                    size="small"
                    className={styles.numberField}
                    onChange={this.props.handleDuration}
                    value={parseFloat(this.props.transaction.duration).toLocaleString(navigator.language, {minimumFractionDigits: 1})}
                    variant="outlined"
                  />
                  <br/><br/>
                </>
              ) : (
                <>
                <NumberFormat
                customInput={TextField}
                variant="outlined"
                required
                size="small"
                label="Amount"
                autoComplete="off"
                fullWidth="true"
                name="initialAmount"
                className={styles.numberField}
                thousandSeparator={true}
                value={parseFloat(this.props.transaction.initialAmount).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                onValueChange={this.props.initialAmount}
                decimalSeparator="."
                fixedDecimalScale={true}
                prefix={'₱'}
                />
                <br/><br/>
                </>
              )
            }
            {
              !this.props.transaction.is_deduction ? (
                <>
                  <NumberFormat
                    customInput={TextField}
                    variant="outlined"
                    className={styles.numberField}
                    required
                    size="small"
                    label="Discount"
                    onValueChange={this.props.discountChange}
                    fullWidth="true"
                    name="discount"
                    thousandSeparator={true}
                    value={this.props.transaction.discount}
                    decimalSeparator="."
                    fixedDecimalScale={true}
                    suffix={'%'}
                  />
                </>
              ) : (
                null
              )
            }

          </div>

        </div>
        <div className={styles.line}></div>
        <div className={styles.amountflex}>
          <label><b>Net Amount :</b></label>
          <NumberFormat
            customInput={TextField}
            variant="outlined"
            required
            size="small"
            disabled={true}
            onValueChange={this.props.testNumber}
            name="amount"
            className={styles.numberField}
            thousandSeparator={true}
            value={parseFloat(this.props.transaction.amount).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
            decimalSeparator="."
            fixedDecimalScale={true}
            prefix={'₱'}
          />

        </div>
        <br/>
        <div className={styles.actionButton}>
          <Button
            variant="outlined"
            color="primary"
            style={{float: 'right'}}
            onClick={this.props.handleSubmit}
            disabled={!this.props.transaction.name || !this.props.transaction.user_id /*|| !this.props.defaultTransactionType.uid*/ }
            name="submit-transaction"
          >Save</Button>
        </div>

      </>
    )
  }
}


export default TransactionForm

TransactionForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleInputChange: PropTypes.func,
  transaction: PropTypes.object,
  users: PropTypes.array,
  members: PropTypes.array,
  testNumber: PropTypes.func,
  expenseTypes: PropTypes.array,
  handleSwitch: PropTypes.func,
  handleUserChange: PropTypes.func,
  handleDuration: PropTypes.func,
  rebates: PropTypes.array,
  handleTransactionType: PropTypes.func,
  defaultTransactionType: PropTypes.object,
  userRateChange: PropTypes.func,
  discountChange: PropTypes.func,
  error: PropTypes.func,
  error_trasaction: PropTypes.func,
  error_user: PropTypes.func,
  initialAmount: PropTypes.func,
  openCreateTransactionType: PropTypes.func

}
